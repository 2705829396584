import clsx from 'clsx'
import {useFormik} from 'formik'
import {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import * as Yup from 'yup'
import {currencyData} from '../../../../../_metronic/helpers'
import CustomSelect from '../../../../components/CustomSelect'
import {findSym} from '../../../../helpers/func'
import {getFormattedUnit} from '../../../../utils/funcs'
import {useSimulationContext} from '../SimulationContext'

interface Props {
  show: boolean
  closeModal: () => void
  data: any
}

const unitOptions = [
  {value: 'billion', label: 'Billion'},
  {value: 'million', label: 'Million'},
  {value: 'thousand', label: 'Thousand'},
  {value: 'unit', label: 'Unit'},
]

export default function TotalPriceModal({data, show, closeModal}: Props) {
  const {formik: globalFormik, setChartUnit} = useSimulationContext()
  const [currency, setCurrency] = useState<any>([])
  useEffect(() => {
    const localCurrency: any = []
    Object.values(currencyData[0]).forEach((item) => {
      localCurrency.push({label: `${item.name_plural} (${item.code})`, value: item.code})
    })
    setCurrency(localCurrency)
  }, [])

  const formik = useFormik({
    initialValues: {
      budgetAtCompletion: globalFormik?.values.budget_at_completion?.amount || 0,
      currency: globalFormik?.values.budget_at_completion?.currency.toUpperCase() || 'USD',
      unit: globalFormik?.values.budget_at_completion?.unit || 'thousand',
      hours: globalFormik?.values.total_hour || 0,
      resources_per_month: globalFormik?.values.resources_per_month || 0,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      globalFormik?.setFieldValue('budget_at_completion.currency', values.currency)
      globalFormik?.setFieldValue('budget_at_completion.unit', values.unit)
      globalFormik?.setFieldValue('budget_at_completion.amount', values.budgetAtCompletion)
      globalFormik?.setFieldValue('total_hour', values.hours)
      globalFormik?.setFieldValue('resources_per_month', values.resources_per_month)
      setChartUnit((prev) =>
        prev.suffix === '%'
          ? {ratio: 1, suffix: '%'}
          : prev.suffix === 'hours'
          ? {ratio: values.hours / 100, suffix: 'hours'}
          : prev.suffix === 'FTE'
          ? {suffix: prev.suffix, ratio: values.hours / 100 / +values.resources_per_month}
          : {
              ratio: values.budgetAtCompletion / 100,
              suffix: getFormattedUnit(values.unit) + findSym(values.currency),
            }
      )
      closeModal()
    },
    validationSchema: Yup.object().shape({
      budgetAtCompletion: Yup.number()
        .required('Budget Required')
        .min(1, 'Budget must be positive!'),
      hours: Yup.number().required('Hour Number Required').min(0, 'Hour Number must be positive!'),
      resources_per_month: Yup.number()
        .required('Resources per month required')
        .min(0, 'Resources per month must be positive!'),
    }),
  })
  return (
    <Modal aria-labelledby='contained-modal-title-vcenter' centered show={show} onHide={closeModal}>
      <Modal.Header>
        <Modal.Title>Adjust Chart Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body className='mx-10'>
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
            <span className='required'>Currency</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title=''
            ></i>
          </label>
          {currency.length && (
            <CustomSelect
              name='currency'
              maxHeight='200px'
              value={currency.find((item: any) => item.value === formik.values.currency)}
              options={currency}
              onChange={(d: any) => {
                formik.setFieldValue('currency', d.value)
              }}
            />
          )}
          {formik.touched.currency && formik.errors.currency && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.currency.toString()}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
            <span className='required'>Unit</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title=''
            ></i>
          </label>

          <CustomSelect
            name='unit'
            options={unitOptions}
            value={unitOptions.find((item) => item.value === formik.values.unit)}
            onChange={(d: any) => {
              formik.setFieldValue('unit', d.value)
            }}
          />
          {formik.touched.unit && formik.errors.unit && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.unit.toString()}</span>
              </div>
            </div>
          )}
        </div>
        <div className='input-group'>
          <span className='input-group-text'>
            {getFormattedUnit(formik.values.unit)} {findSym(formik.values.currency || '')}
          </span>
          {/*end::Svg Icon*/}

          <input
            type='number'
            placeholder=''
            className={clsx(
              'form-control form-control-lg ',
              {
                'is-invalid': formik.touched.budgetAtCompletion && formik.errors.budgetAtCompletion,
              },
              {
                'is-valid': formik.touched.budgetAtCompletion && !formik.errors.budgetAtCompletion,
              }
            )}
            {...formik.getFieldProps('budgetAtCompletion')}
          />
        </div>
        {formik.touched.budgetAtCompletion && formik.errors.budgetAtCompletion && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.budgetAtCompletion.toString()}</span>
            </div>
          </div>
        )}
        <div className='fv-row my-10'>
          <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
            <span className='required'>Number of Hours</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Specify your unique app name'
            ></i>
          </label>
          <input
            type='text'
            {...formik.getFieldProps('hours')}
            placeholder=''
            className={clsx(
              'form-control ',
              {'is-invalid': formik.touched.hours && formik.errors.hours},
              {
                'is-valid': formik.touched.hours && !formik.errors.hours,
              }
            )}
          />
          {formik.touched.hours && formik.errors.hours && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.hours.toString()}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row my-10'>
          <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
            <span className='required'>Full time capacity for 1 ressource per month</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Specify your unique app name'
            ></i>
          </label>
          <div className='input-group'>
            <span className='input-group-text'>Hours/Month</span>
            {/*end::Svg Icon*/}
            <input
              type='text'
              placeholder=''
              className={clsx(
                'form-control form-control-lg ',
                {
                  'is-invalid':
                    formik.touched.resources_per_month && formik.errors.resources_per_month,
                },
                {
                  'is-valid':
                    formik.touched.resources_per_month && !formik.errors.resources_per_month,
                }
              )}
              {...formik.getFieldProps('resources_per_month')}
            />
          </div>
          {formik.touched.resources_per_month && formik.errors.resources_per_month && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.resources_per_month.toString()}</span>
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type='button'
          className='btn btn-primary'
          id='kt_project_settings_submit'
          onClick={formik.submitForm}
          disabled={!formik.isValid}
        >
          <span className='indicator-label'>Save</span>
        </button>
      </Modal.Footer>
    </Modal>
  )
}
