import { useEffect, useMemo, useState } from 'react';
import { useSortBy, useTable } from 'react-table';
import { useDispatch, useSelector } from 'react-redux';
import { useProject } from '../../core/ProjectContext';
import { CreateRebaseLine } from './CreateRebaseLine';
import ActionPlanHeader from '../action-plan/ActionPlanHeader';
import { RebaseLine, RebaseLineHeaderList } from './_models';
import { DateUnit } from '../pagination/header/ProjectDetailsHeader';
import { getFormattedDate } from '../../../../helpers/DateFormatter';
import { sweetAlert } from '../../../../utils/funcs';
import { patchVersion } from '../../core/_requests';
import { patchWbs } from '../../../wbs/_requests';
import { addWbsToStore, updateIsWpChanged, updateWpChangedId } from '../../../wbs/treedata';
import { useTree } from '../../../wbs/components/TreeView/TreeContext';
import { buildFullPath } from '../../../wbs/components/TreeView/_helpers';
import type { DataNode, EventDataNode } from 'antd/es/tree';
import { RootState } from '../../../../store';
import { setVersionData } from '../../../wbs/treeVersionData';
import { updateShow } from '../../../wbs/treeLoader';

type Props = {
  plannedValuesObject: any,
  isWbs: boolean,
  wpId: string,
};

export default function RebaseLineTable({ plannedValuesObject, isWbs, wpId }: Props) {

  const { originalVersion, setVersion, setOriginalVersion, setPatchedWpData, project: projectData, setProject, treeData } = useProject();
  const [showModalRebaseLine, setShowModalRebaseLine] = useState(false);
  const [id, setRebaseLineId] = useState<RebaseLine | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const knownWbs = useSelector((state: RootState) => state.treedata.knownWbs);
  const projectversiondata = useSelector((state: RootState) => state.versiondata.data);
  const dispatch = useDispatch();

  const columns = useMemo(() => RebaseLineHeaderList, []);
  const data = useMemo(() => originalVersion?.rebase_line || [], [originalVersion]);
  const { getTableProps, headerGroups, rows, getTableBodyProps, prepareRow } = useTable(
    { columns, data },
    useSortBy
  );

  // Helper Functions
  const switchValues = (items: any, id: any) => {
    const index = items.findIndex((item: any) => item?.id == id);
    if (index !== -1 && index < items.length - 1) {
      const nextItem = items[index + 1];
      Object.keys(items[index]).forEach(key => {
        if (key !== 'id' && key !== 'data_date') {
          nextItem[key] = items[index][key];
        }
      });
      return items.splice(index + 1, 1);
    }
    return items.filter((item: any) => item.id !== id);
  };

  const deleteRebaseLine = (id: string) => {
    sweetAlert({
      title: 'Are you sure?',
      text: 'You cannot recover this later',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      icon: 'warning',
    }).then(async (res) => {
      if (!res.isConfirmed) return;
      setLoading(true);
      let newVersion: any;
      if (originalVersion?.rebase_line.length! > 1) {
        newVersion = { ...originalVersion, rebase_line: switchValues(originalVersion?.rebase_line, id) };
      } else if (originalVersion?.rebase_line.length === 1) {
        const firstRebaseLine = originalVersion?.rebase_line.filter((item) => item.id == id);
        newVersion = {
          ...originalVersion,
          end_date: new Date(firstRebaseLine[0]?.end_date),
          budget_at_completion: firstRebaseLine[0]?.budget_at_completion,
          reserve: firstRebaseLine[0]?.reserve,
          delay_tolerance: firstRebaseLine[0]?.delay_tolerance,
          custom_curve: firstRebaseLine[0]?.custom_curve,
          rebase_line: originalVersion?.rebase_line.filter((item) => item.id !== id),
        };
      }

      const versionData = { ...newVersion, end_date: newVersion.end_date?.$date ? newVersion.end_date : { $date: newVersion.end_date.getTime() } }
      const calculatedVersionData = await setPatchedWpData(
        originalVersion?.name ? originalVersion?.name : 'WBS',
        versionData,
        new Date(originalVersion?.data_date?.$date || 0),
      );
      console.log("calculatedVersionData ==>")
      console.log(calculatedVersionData)
      setLoading(false)
      dispatch(updateShow(false));

      newVersion = calculatedVersionData ? { ...calculatedVersionData } : { ...newVersion, output: {} }
      const { _id, project, user, created_at, updated_at, start_date, data_date, forecast_settings, ...payload } = newVersion
      const patched_version = await patchVersion(
        {
          ...payload,
          end_date: new Date(payload?.end_date?.$date)
        },
        originalVersion?._id.$oid
      )
      dispatch(setVersionData({ id: patched_version._id.$oid, data: patched_version }));
      setVersion(patched_version);
      setOriginalVersion(patched_version);
      sweetAlert({
        text: 'Rebase Line Deleted Successfully',
        title: 'Deleted',
        icon: 'success',
      }).then(() => {
        if (wpId && wpId !== '') {
          dispatch(updateWpChangedId(wpId))
          dispatch(updateIsWpChanged(true));
        }
      }
      );
    });
  };

  const findKnownWbsId = (wp_data_id: string) => {
    for (const key in knownWbs) {
      if (knownWbs[key]?.data?.$oid === wp_data_id) {
        return key;
      }
    }
    return null;
  };

  const handleShowWpClick = (wp_data_id: string, rowName: string) => {
    const wbsId = findKnownWbsId(wp_data_id);
    if (wbsId) {
      patchWbs(projectData?.current_wbs, { last_opened_wbs: wbsId }).then((res) => dispatch(addWbsToStore(res)));
      const nodesMap = new Map<string, DataNode>();
      buildMap(treeData, nodesMap);
      const targetNode = nodesMap.get(wbsId);
      const folderName = targetNode ? buildFullPath(targetNode as EventDataNode<DataNode>, nodesMap) : rowName;
      setProject((prev) => {
        if (prev) {
          const [baseName, existingFolderName] = prev.name.split('/');
          const newName = existingFolderName ? `${baseName}/${folderName}` : `${prev.name}/${folderName}`;
          return { ...prev, name: newName };
        }
        return prev;
      });
      setOriginalVersion(projectversiondata[wp_data_id]);
      setVersion(projectversiondata[wp_data_id]);
    }
  };

  const buildMap = (nodes: DataNode[], map: Map<string, DataNode>) => {
    nodes.forEach((node) => {
      map.set(node.key as string, node);
      if (node.children) {
        buildMap(node.children, map);
      }
    });
  };

  // Rendering
  return (
    <>
      {!isWbs && (
        <CreateRebaseLine
          show={showModalRebaseLine}
          handleClose={() => {
            setShowModalRebaseLine(false)
            setRebaseLineId(null)
          }}
          rebase_line_id={id}
          plannedValuesObject={plannedValuesObject}
          wpId={wpId}
        />
      )}
      <div className='card'>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bold dataTable no-footer' {...getTableProps()}>
              <thead className='fs-7 text-gray-400 text-uppercase'>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, key) => (
                      <ActionPlanHeader
                        key={key}
                        isSorted={column.isSorted}
                        isSortedDesc={column.isSortedDesc}
                        title={column.Header as string}
                        width={(column.Header as string) === 'Comment' ? '250' : '90'}
                        props={column.getHeaderProps(column.getSortByToggleProps)}
                      />
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row, index) => {
                  prepareRow(row);
                  return (
                    <tr key={index}>
                      <td className='fw-bold'>{row.original.name}</td>
                      <td className='fw-bold'>
                        {getFormattedDate(
                          new Date(row.original.data_date),
                          originalVersion?.period_count?.type as DateUnit,
                          originalVersion?.date_format
                        )}
                      </td>
                      <td className='fw-bold'>
                        {getFormattedDate(
                          new Date(originalVersion?.start_date?.$date || 0),
                          originalVersion?.period_count?.type as DateUnit,
                          originalVersion?.date_format
                        )}
                      </td>
                      <td className='fw-bold'>
                        {getFormattedDate(
                          new Date(row.original.end_date),
                          originalVersion?.period_count?.type as DateUnit,
                          originalVersion?.date_format
                        )}
                      </td>
                      <td className='fw-bold'>{row.original.reserve?.amount}</td>
                      <td className='fw-bold'>{row.original.delay_tolerance?.count}</td>
                      <td className='fw-bold'>{row.original.budget_at_completion.amount}</td>
                      {isWbs ? (
                        <a
                          className='btn m-3 btn-bg-light btn-active-color-primary btn-sm'
                          onClick={() => handleShowWpClick(row.original?.wp_data_id, row.original.name)}
                        >
                          Show WP
                        </a>
                      ) : (
                        <>
                          {loading ? (
                            <a className='btn btn-danger btn-sm'>...Loading</a>
                          ) : (
                            <a className='btn btn-danger btn-sm' onClick={() => deleteRebaseLine(row.original.id)}>
                              Delete
                            </a>
                          )}
                          <a
                            onClick={() => {
                              setShowModalRebaseLine(true);
                              setRebaseLineId(row.original);
                            }}
                            className='btn m-3 btn-bg-light btn-active-color-primary btn-sm'
                          >
                            Edit
                          </a>
                        </>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
