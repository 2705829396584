import { DataNode } from 'antd/es/tree';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { KTSVG } from '../../../../../_metronic/helpers';
import { showError } from '../../../../utils/funcs';
import { Wbs } from '../../_models';
import { getWbs, getWbss, patchWbs } from '../../_requests';
import { FolderDropdown } from './FolderDropdown';
import { useTree } from './TreeContext';
import TreeCreateFolderItem from './TreeCreateFolderItem';
import { appendTreeData, deleteTreeNode, findNodeById } from './_helpers';
import { useProject } from '../../../projects/core/ProjectContext';
import { sweetAlert } from '../../../../utils/funcs';
import { useSelector, useDispatch } from 'react-redux';
import { isrename, isntrename } from '../../../wbs/treeStates';
import { getVersionById } from '../../../projects/core/_requests';
import { fetchDataById, setVersionData } from '../../treeVersionData';
import { RootState, AppDispatch } from '../../../../store';
import { addWbsToStore } from '../../treedata';
import { addCheckedWbsToStore, removeCheckedWbsFromStore } from '../../treeShare';

type Props = {
  folder: Wbs;
  isShare?: boolean;
};

interface RootStatee {
  treeisheader: {
    value: boolean;
  };
  maxdatadate: {
    value: string;
  };
}

export default function TreeFolderItem({ folder: folderData, isShare = false }: Props) {
  const [folder, setFolder] = useState<Wbs>(folderData);
  const { setExpandedKeys, setTreeData, treeData, displayNodeNumber } = useTree();
  const [hovered, setHovered] = useState<boolean>(false);
  const [isRenaming, setIsRenaming] = useState(false);
  const inputRef = useRef(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [upDisabled, setUpDisabled] = useState<boolean>(false);
  const [downDisabled, setDownDisabled] = useState<boolean>(false);
  const isheader = useSelector((state: RootStatee) => state.treeisheader.value);
  const maxdatadate = useSelector((state: RootStatee) => state.maxdatadate.value);
  const dispatch = useDispatch();
  const knownWbs = useSelector((state: RootState) => state.treedata.knownWbs);
  const projectversiondata = useSelector((state: RootState) => state.versiondata.data)
  const checkedWbs = useSelector((state: RootState) => state.treeShare.checkedWbs);
  const [behind, setBehind] = useState<Boolean>();
  const [missing, setMissing] = useState<Boolean>();
  const data = useSelector((state: RootState) => state.versiondata.data);
  const loadingg = useSelector((state: RootState) => state.versiondata.loading);
  const error = useSelector((state: RootState) => state.versiondata.error);
  const { project } = useProject();
  const [isChecked, setIsChecked] = useState<boolean>(checkedWbs.includes(folder._id.$oid));

  useEffect(() => {
    if (knownWbs[folder._id.$oid])
      setFolder(knownWbs[folder._id.$oid]);
  }, [knownWbs]);

  const collectLeafData = async (node: string): Promise<any[]> => {
    const leafData: any[] = []
    const traverse = async (currentNode: string) => {
      const nodeValue = knownWbs[currentNode]

      if (!nodeValue?.subwbs || nodeValue?.subwbs?.length === 0) {
        if (nodeValue?.data) {
          let versionData = projectversiondata[nodeValue.data.$oid]
          if (!versionData) {
            let fetchedData = await getVersionById(nodeValue.data.$oid)
            fetchedData = { ...fetchedData, name: nodeValue.name }
            dispatch(setVersionData({ id: nodeValue.data.$oid, data: fetchedData }))
            versionData = fetchedData
          }
          leafData.push(versionData)
        }
      } else {
        for (const child of nodeValue.subwbs) {

          const childWbsData = knownWbs[child.$oid] || (await getWbs(child?.$oid))
          if (!knownWbs[child?.$oid]) {
            dispatch(addWbsToStore(childWbsData))
          }

          await traverse(childWbsData._id.$oid)
        }
      }

    }
    await traverse(node as string)
    return leafData
  }

  useEffect(() => {

    if (!folder?.subwbs || folder?.subwbs?.length === 0) {
      const data_date = async () => {
        if (folder.data && !loadingg) {
          const object = data[folder?.data?.$oid];
          if (object && !loadingg) {
            const pv = object?.output?.plannedValues?.original?.cumulative[object?.output.plannedValues.original.cumulative.length - 1];
            const ev = object?.output?.earnedValues?.original?.cumulative[object?.output.earnedValues.original.cumulative.length - 1];
            if (pv === ev) {
              setMissing(false);
            } else {
              setMissing(true);
            }
            if (object?.data_date.$date < maxdatadate) {
              setBehind(true);
            } else {
              setBehind(false);
            }
          }
        }
      };

      data_date();
    } else {
      const checkedWbs = async () => {

        const leafData = await collectLeafData(folder._id.$oid)

        const missing = leafData.some((leaf) => leaf?.output?.plannedValues?.original?.cumulative[leaf?.output?.plannedValues?.original?.cumulative?.length - 1] !== leaf?.output?.earnedValues?.original?.cumulative[leaf?.output?.earnedValues?.original?.cumulative?.length - 1])
        setMissing(missing);

        const behind = leafData.some((leaf) => leaf.data_date.$date < maxdatadate)
        setBehind(behind)

      }
      if (!isShare)
        checkedWbs()
    }
  }, [data, loadingg]);


  const formik = useFormik({
    initialValues: { name: folder.name },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required('Name is required')
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols'),
    }),
    onSubmit: (values) => {
      patchWbs(folder._id.$oid, values)
        .then((res) => {
          setTreeData((origin) =>
            appendTreeData(
              deleteTreeNode(origin, folder._id.$oid),
              folder.parent_wbs?.$oid || 'root',
              [{ key: res._id.$oid, title: <TreeFolderItem folder={res} isShare={isShare} /> }]
            )
          );
          setIsRenaming(false);
        })
        .catch(showError)
        .finally(() => {
          dispatch(isntrename());
        });
    },
  });

  const handleNewFolder = async (createTwo: boolean) => {

    if (findNodeById(treeData, 'new_child' + folder?._id?.$oid)) return;
    if (
      folder.subwbs.length &&
      !findNodeById(treeData, folder.subwbs[0]?.$oid || folder.subwbs[0] as any)
    ) {
      let res = knownWbs[folder._id?.$oid];
      if (!res) {
        res = await getWbs(folder._id?.$oid);
        dispatch(addWbsToStore(res));
      }

      const subwbsData = await Promise.all(
        res.subwbs.map(async (item: { $oid: string }) => {
          let subWbsItem = knownWbs[item.$oid];
          if (!subWbsItem) {
            subWbsItem = await getWbs(item.$oid);
            dispatch(addWbsToStore(subWbsItem));
          }

          return {
            key: subWbsItem._id.$oid,
            title: <TreeFolderItem folder={subWbsItem} isShare={isShare} />,
          };
        })
      );

      setTreeData((origin) =>
        appendTreeData(origin, folder._id?.$oid, subwbsData)
      );
    }
    setExpandedKeys((prev) => {
      const found = prev.find((item) => item === folder?._id?.$oid);
      if (found) return prev;
      return [...prev, folder._id?.$oid || 'root'];
    });
    setTreeData((origin) => {
      const filteredOrigin = deleteTreeNode(origin, 'new folder');
      return appendTreeData(filteredOrigin, folder?._id?.$oid || 'root', [
        {
          key: 'new_child' + folder?._id?.$oid || 'root',
          title: <TreeCreateFolderItem parent={folder || 'root'} createTwo={createTwo} />,
          switcherIcon: <></>,
        },
      ]);
    });
  };

  const handleReorder = (rank: -1 | 1) => {
    setLoading(true);
    patchWbs(folder._id.$oid, { rank })
      .then(async () => {
        const folders = await getWbss();
        const rootWbss: Wbs[] = folders.filter((item) => item.parent_wbs?.$oid === project?.current_wbs);

        const getChildren = (folder: Wbs): DataNode => {
          if (folder.subwbs.length === 0)
            return {
              key: folder._id?.$oid ? folder._id.$oid : project?.current_wbs,
              title: <TreeFolderItem folder={folder} isShare={isShare} />,
              children: [],
            };
          return {
            key: folder._id?.$oid ? folder._id.$oid : project?.current_wbs,
            title: <TreeFolderItem folder={folder} isShare={isShare} />,
            children: folders
              .filter((item) => item.parent_wbs?.$oid === (folder._id?.$oid ? folder._id.$oid : project?.current_wbs))
              .map((item) => getChildren(item)),
          };
        };

        setTreeData([
          { key: project?.current_wbs, title: treeData[0].title, children: rootWbss.map(getChildren) },
        ]);
        setLoading(false);
      })
      .catch((err) => {
        showError(err);
        setLoading(false);
      });
  };

  const handleWbClick = () => {
    // handle WB click 
  };

  const isReactElement = (
    element: any
  ): element is React.ReactElement => {
    return element !== null && typeof element === 'object' && 'props' in element;
  };

  useEffect(() => {
    if (!folder._id) return;

    const foundNode = findNodeById(treeData, folder.parent_wbs?.$oid ? folder.parent_wbs?.$oid : project?.current_wbs);

    const ranks = foundNode?.children
      ?.filter(item => typeof item.key === 'string' && !item.key.startsWith('new_child'))
      ?.map((item) => {
        if (isReactElement(item.title)) {
          return item.title.props.folder.rank;
        }
        return undefined;
      }).filter(rank => rank !== undefined) as number[] || [];

    const maxRank = Math.max(...(ranks));
    const minRank = Math.min(...(ranks));

    if (folder.rank === undefined) return;

    setUpDisabled(folder.rank === minRank);
    setDownDisabled(folder.rank === maxRank);
  }, [treeData, folder._id, folder.rank]);

  const doubleFolderCheck = () => {
    sweetAlert({
      title: 'Attention',
      text: 'Curve will push the current data into new subfolder beside the one you wish to create, press no if you wish to create only subfolder',
      icon: 'warning',
      cancelButtonText: 'no',
      confirmButtonText: 'yes',
      showCancelButton: true,
    }).then((res) => {
      if (!res.isConfirmed) {
        return handleNewFolder(false);
      } else {
        return handleNewFolder(true);
      }
    });
  };

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setIsChecked(checked);


    if (checked) {
      // Add checked key
      dispatch(addCheckedWbsToStore(folder._id.$oid));
    } else {
      // Remove unchecked key
      dispatch(removeCheckedWbsFromStore(folder._id.$oid));
    }

  };

  function treerenaming(): any {
    throw new Error('Function not implemented.');
  }

  return (
    <FolderDropdown
      folder={folder}
      formik={formik}
      setRenaming={() => {
        setIsRenaming(true);
        dispatch(isrename());
        const node = inputRef.current;
        if (node) (node as any).focus();
      }}
    >
      <div
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        className={clsx('d-inline-flex justify-content-start align-items-center', {
          'text-muted fst-italic': !folder.data,
        })}
      >
        {missing === false ? (
          <KTSVG
            className={clsx('svg-icon-success svg-icon-2 me-2', {
              'opacity-50 text-muted': !folder.data,
            })}
            path={folder.subwbs && folder.subwbs.length > 0 ? '/media/icons/duotune/graphs/git04.svg' : '/media/icons/duotune/files/fil003.svg'}
          />
        ) : (behind ? (
          <KTSVG
            className={clsx('svg-icon-warning svg-icon-2 me-2', {
              'opacity-50 text-muted': !folder.data,
            })}
            path={folder.subwbs && folder.subwbs.length > 0 ? '/media/icons/duotune/graphs/git04.svg' : '/media/icons/duotune/files/fil003.svg'}
          />
        ) : (
          <KTSVG
            className={clsx('svg-icon-primary svg-icon-2 me-2', {
              'opacity-50 text-muted': !folder.data,
            })}
            path={folder.subwbs && folder.subwbs.length > 0 ? '/media/icons/duotune/graphs/git04.svg' : '/media/icons/duotune/files/fil003.svg'}
          />
        ))}
        {isRenaming ? (
          <input
            ref={inputRef}
            type='text'
            placeholder='New Folder'
            className='form-control form-control-flush ps-0'
            onBlurCapture={() => {
              setIsRenaming(false);
              dispatch(isntrename());
            }}
            {...formik.getFieldProps('name')}
          />
        ) : (
          <span className='fw-bold me-2'>
            {displayNodeNumber ? folder.nodePosition : folder.name}
          </span>
        )}

        {!isRenaming && !isheader && (
          <>
            <button
              onClick={(e) => {
                e.stopPropagation();
                doubleFolderCheck();
                dispatch(isrename())
              }}
              type='button'
              style={{ transition: 'all 0.2s ease-in-out' }}
              className={clsx('btn btn-icon btn-sm', {
                'opacity-0': !hovered,
              })}
            >
              {!isShare && (
                <KTSVG
                  path='/media/icons/duotune/files/fil013.svg'
                  className='svg-icon-muted text-hover-primary svg-icon-3'
                />
              )}
            </button>
            {(!!folder._id?.$oid && !isShare) && (
              <>
                <button
                  disabled={loading || upDisabled}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleReorder(1);
                  }}
                  id='up_folder'
                  type='button'
                  style={{ transition: 'all 0.2s ease-in-out' }}
                  className={clsx('btn btn-icon btn-sm', {
                    'opacity-0': !hovered,
                  })}
                >
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr003.svg'
                    className='svg-icon-muted text-hover-primary svg-icon-3'
                  />
                </button>
                <button
                  disabled={loading || downDisabled}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleReorder(-1);
                  }}
                  id='down_folder'
                  type='button'
                  style={{ transition: 'all 0.2s ease-in-out' }}
                  className={clsx('btn btn-icon btn-sm', {
                    'opacity-0': !hovered,
                  })}
                >
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr004.svg'
                    className='svg-icon-muted text-hover-primary svg-icon-3'
                  />
                </button>
                <button
                  disabled={loading}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsRenaming(true);
                    dispatch(isrename());
                    setTimeout(() => {
                      const node = inputRef.current;
                      if (node) (node as any).focus();
                    }, 0);
                  }}
                  id='wb_folder'
                  type='button'
                  style={{ transition: 'all 0.2s ease-in-out' }}
                  className={clsx('btn btn-icon btn-sm', {
                    'opacity-0': !hovered,
                  })}
                >
                  <i className="bi bi-pencil-fill fs-7 text-hover-primary"></i>
                </button>
              </>
            )}
          </>
        )}
      </div>
    </FolderDropdown>
  );
}
