import ApexCharts, {ApexOptions} from 'apexcharts'
import {useEffect, useRef} from 'react'
import {getCSSVariableValue} from '../../../_metronic/assets/ts/_utils'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {getCustomCurve} from '../../modules/projects/components/overview/Project'
import {useProject} from '../../modules/projects/core/ProjectContext'
import {getDiffBetweenTwoDates} from '../../utils/forecast'
import {formatNumber} from '../../utils/formatter'

type Props = {}

export default function SummaryChart({}: Props) {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const colors = ['#776BFF', '#FFE39D', '#A7FAFF']
  const {displayVersion} = useProject()

  const dataDateIndex = getDiffBetweenTwoDates(
    displayVersion?.start_date.$date || 0,
    displayVersion?.data_date.$date || 0,
    'month'
  )
  let plannedValue: any

  let earnedValue: any

  let actualCost: any
  useEffect(() => {
    if (displayVersion) {
      plannedValue =
        ((getCustomCurve('cumulativePlannedValue', displayVersion)[dataDateIndex] ??
          getCustomCurve('cumulativePlannedValue', displayVersion).at(-1)) /
          (displayVersion?.budget_at_completion.amount || 1)) *
        100
      earnedValue =
        (getCustomCurve('cumulativeEarnedValue', displayVersion)[dataDateIndex] /
          (displayVersion?.budget_at_completion.amount || 1)) *
        100
      actualCost =
        (getCustomCurve('cumulativeActualCost', displayVersion)[dataDateIndex] /
          (displayVersion?.budget_at_completion.amount || 1)) *
        100
    }
  }, [displayVersion])
  const costRef = useRef(null)
  const getChartOptions: () => ApexOptions = () => ({
    series: [
      {
        name: 'Values at data date',
        data: [plannedValue, earnedValue, actualCost],
      },
    ],
    chart: {
      height: 350,
      type: 'bar',
      toolbar: {show: false},
    },
    colors: colors,
    plotOptions: {
      bar: {
        distributed: true,
      },
    },
    fill: {
      opacity: 1,
    },
    grid: {
      borderColor: '#2B2B3D',
    },
    dataLabels: {enabled: false},
    legend: {show: false},
    xaxis: {
      categories: [
        `PV ${formatNumber(plannedValue, displayVersion?.float_formatter || 0, '.')}%`,
        `EV ${formatNumber(earnedValue, displayVersion?.float_formatter || 0, '.')}%`,
        `AC ${formatNumber(actualCost, displayVersion?.float_formatter || 0, '.')}%`,
      ],
      labels: {
        style: {colors: colors},
      },
      axisTicks: {show: false},
      axisBorder: {color: '#2B2B3D'},
    },
    yaxis: {
      labels: {
        style: {colors: labelColor},
        formatter: (val: number, opts) => val.toFixed(displayVersion?.float_formatter),
      },
      max: Math.max(plannedValue, earnedValue, actualCost, 100),
    },
  })

  useEffect(() => {
    const chart = new ApexCharts(costRef.current, getChartOptions())
    chart.render()
    return () => chart.destroy()
  }, [displayVersion])

  return (
    <div
      className='h-100 g-1 card'
      style={{
        paddingTop: '16px',
      }}
    >
      <div className='card-header '>
        <div className='card-title d-flex justify-content-between w-100'>
          <div className='card-title mr-auto p-2'>
            <span
              className=' fw-bold text-dark  d-flex align-items-start gap-2'
              style={{fontSize: '16px'}}
            >
              <img
                className='w-24px h-24px '
                src={toAbsoluteUrl('/media/svg/card-logos/projectSelection.svg')}
                alt='Summary logo'
              />
              <div>
                <div>Summary</div>
                <div className='overview-description'>
                  Gets a global overview over you favourite projects
                </div>
              </div>
            </span>
          </div>
          <div className='card-title ml-auto p-2 gap-2 summary-right-icon'>
            <img
              className='w-24px h-24px '
              src={toAbsoluteUrl('/media/svg/card-logos/summary.svg')}
              alt='Summary group logo'
            />
          </div>
        </div>
      </div>
      <div className='card-body'>
        <div ref={costRef}></div>
      </div>
    </div>
  )
}
