import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TreeData {
  checkedWbs: string[];
}

const initialTreeData: TreeData = {
  checkedWbs: [],
};

const wbstreedata = createSlice({
  name: 'wbstreedata',
  initialState: initialTreeData,
  reducers: {
    addCheckedWbsToStore: (state: TreeData, action: PayloadAction<string>) => {
      state.checkedWbs.push(action.payload);
    },
    removeCheckedWbsFromStore: (state: TreeData, action: PayloadAction<string>) => {
      state.checkedWbs = state.checkedWbs.filter(id => id !== action.payload);
    },
  },
});

export const { addCheckedWbsToStore, removeCheckedWbsFromStore } = wbstreedata.actions;

export default {
  updatedata: wbstreedata.reducer,
};
