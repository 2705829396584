import {useEffect, useState} from 'react'
import {useLocation} from 'react-router'
import clsx from 'clsx'
import {useLayout} from '../../core'
import {DrawerComponent} from '../../../assets/ts/components'
import {WithChildren} from '../../../helpers'
import {useAuth} from '../../../../app/modules/auth'
import FreeTrialAdd from '../../../../app/modules/apps/user-management/FreeTrialAdd'
import {sweetAlert} from '../../../../app/utils/funcs'

const Content = ({children}: WithChildren) => {
  const {config, classes} = useLayout()
  const location = useLocation()
  const {currentUser} = useAuth()
  const [showAddTrial, setShowAddTrial] = useState(false)
  useEffect(() => {
    if (!currentUser?.current_subscription) {
      setShowAddTrial(true)	   
    }
  }, [])
  useEffect(() => {
    DrawerComponent.hideAll()
  }, [location])
  const appContentContainer = config.app?.content?.container
  return (
    <div
      id='kt_app_content'
      className={clsx(
        'app-content flex-column-fluid',
        classes.content.join(' '),
        config?.app?.content?.class,
        location.pathname == '/dashboard' ? 'p-0' : ''
      )}
    >
      {appContentContainer ? (
        <div
          id='kt_app_content_container'
          className={clsx(
            location.pathname != '/dashboard' ? 'app-container' : 'gx-0',
            classes.contentContainer.join(' '),
            {
              'container-xxl': appContentContainer === 'fixed',
              'container-fluid': appContentContainer === 'fluid',
            }
          )}
        >
          {children}
          {/* TODO : Remove this */}
          {/* {currentUser && (
            <FreeTrialAdd
              currentUser={currentUser}
              show={showAddTrial}
              handleClose={() => {
                setShowAddTrial(false)
              }}
              sweetAlert={sweetAlert}
            />
          )} */}
        </div>
      ) : (
        <>{children}</>
      )}
    </div>
  )
}

export {Content}
