import React from 'react';
import FreeTrialAdd from '../modules/apps/user-management/FreeTrialAdd';
import { UserModel } from '../modules/auth';


const useFreeTrialAdd = (currentUser: UserModel | undefined, sweetAlert: any) => {
  const [showAddTrial, setShowAddTrial] = React.useState(false);

  const FreeTrialAddComponent = React.useMemo(() => (
    <FreeTrialAdd
      currentUser={currentUser}
      show={showAddTrial}
      handleClose={() => setShowAddTrial(false)}
      sweetAlert={sweetAlert}
    />
  ), [currentUser, showAddTrial, sweetAlert]);

  return {
    FreeTrialAddComponent,
    showAddTrial,
    setShowAddTrial
  };
};

export default useFreeTrialAdd;