import {ApexOptions} from 'apexcharts'
import {format} from 'date-fns'
import moment from 'moment'
import 'moment/locale/fr'
import {getCSSVariableValue} from '../../../../../_metronic/assets/ts/_utils'
import {getFNSDateFormat} from '../../../../helpers/DateFormatter'
import {Milestone} from '../../core/_models'
import {prepareMilestoneForDisplay} from '../milestones/_utils'

const getMilestonesOptions = (
  length: number,
  labels: string[],
  milestones: any[],
  data: any,
  lang: string
): ApexOptions => {
  moment.locale(lang)

  prepareMilestoneForDisplay(milestones, data?.data_date?.$date)

  let preparedMilestones = prepareMilestoneForDisplay(milestones, data?.data_date?.$date)
  preparedMilestones = preparedMilestones.filter((item) => !item.hidden)

  const baselineMilestones = preparedMilestones.map((item) =>
    moment(item.baseline_date).format('MMM YY')
  )

  const notAchievedMilestones = preparedMilestones
    .filter((item) => item.real_date && !item.achieved)
    .map((item) => moment(item.real_date).format('MMM YY'))

  const achievedMilestones = preparedMilestones
    .filter((item) => item.real_date && item.achieved)
    .map((item) => moment(item.real_date).format('MMM YY'))

  const getMilestoneSeries = (milestone: string[], value: number) =>
    labels.slice(0, length).map((label) => (milestone.includes(label) ? value : null))

  return {
    grid: {
      show: false,
      padding: {
        top: 0,
        left: 60,
      },
    },
    markers: {shape: 'square', width: 15, height: 15},
    chart: {
      id: 'milestones',
      group: 'overview',
      type: 'scatter',
      height: 300,
      width: '95%',
      animations: {enabled: false},
    },
    dataLabels: {
      enabled: true,
      offsetY: 10,
      formatter: (value, options) => {
        if (value === null) return ''
        if (options.seriesIndex === 0) {
          return preparedMilestones
            .filter(
              (item) =>
                moment(item.real_date).format('MMM YY') === labels[options.dataPointIndex] &&
                !item.achieved
            )
            .map((item) => item.id)
            .join(' ')
        }

        if (options.seriesIndex === 1) {
          return preparedMilestones
            .filter(
              (item) =>
                moment(item.real_date).format('MMM YY') === labels[options.dataPointIndex] &&
                item.achieved
            )
            .map((item) => item.id)
            .join(' ')
        }

        if (options.seriesIndex === 2) {
          return preparedMilestones
            .filter(
              (item) =>
                moment(item.baseline_date).format('MMM YY') === labels[options.dataPointIndex]
            )
            .map((item) => item.id)
            .join(' ')
        }

        return ''
      },
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: (val, opts) => {
          if (!val) return ''

          if (opts.seriesIndex === 0)
            return preparedMilestones
              .filter(
                (item) =>
                  moment(item.real_date).format('MMM YY') === labels[opts.dataPointIndex] &&
                  !item.achieved
              )
              .map(
                (item) =>
                  item.name +
                  ' - ' +
                  format(new Date(item.real_date || ''), getFNSDateFormat(data.date_format))
              )
              .join(', ')

          if (opts.seriesIndex === 1)
            return preparedMilestones
              .filter(
                (item) =>
                  moment(item.real_date).format('MMM YY') === labels[opts.dataPointIndex] &&
                  item.achieved
              )
              .map(
                (item) =>
                  item.name +
                  ' - ' +
                  format(new Date(item.real_date || ''), getFNSDateFormat(data.date_format))
              )
              .join(', ')

          if (opts.seriesIndex === 2)
            return preparedMilestones
              .filter(
                (item) =>
                  moment(item.baseline_date).format('MMM YY') === labels[opts.dataPointIndex]
              )
              .map(
                (item) =>
                  item.name +
                  ' - ' +
                  format(new Date(item.baseline_date || ''), getFNSDateFormat(data.date_format))
              )
              .join(', ')

          return ''
        },
      },
    },
    xaxis: {
      labels: {
        rotateAlways: true,
        rotate: -45,
        show: true,
        style: {
          colors: getCSSVariableValue('--kt-dark'),
        },
      },
    },
    colors:
      data?.colors?.milestone_colors && data?.colors?.milestone_colors?.length
        ? data?.colors?.milestone_colors
        : undefined,
    yaxis: [
      {
        showAlways: true,
        tickAmount: 4,
        max: 2,
        labels: {
          show: true,
          style: {
            colors: getCSSVariableValue('--kt-dark'),
            fontSize: '13px',
            fontWeight: 'bold',
          },
          formatter: (val, opts) => {
            if (val === 1.5) return 'planned'
            else if (val === 0.5) return 'achieved/forecasted'
            return ''
          },
          offsetX: 30,
          minWidth: 70,
        },
      },
      {
        showAlways: true,
        tickAmount: 4,
        max: 2,
        opposite: true,
        labels: {
          show: true,
          style: {
            fontSize: '13px',
            fontWeight: 'bold',
            colors: 'transparent',
          },
          formatter: (val, opts) => '',
          maxWidth: 100,
          minWidth: 70,
        },
      },
      {
        tickAmount: 4,
        max: 2,
        labels: {
          show: false,
          style: {
            fontSize: '13px',
            fontWeight: 'bold',
            colors: 'transparent',
          },
          formatter: (val, opts) => '',
          maxWidth: 100,
          minWidth: 70,
        },
      },
    ],
    annotations: {
      yaxis: [{y: 1}, {y: 2}],
      xaxis: [
        {
          x: moment(data.data_date.$date).format('MMM YY'),
          strokeDashArray: 0,
          opacity: 0.5,
          borderColor: '#775DD0',
          label: {
            borderColor: '#775DD0',
            orientation: 'horizontal',
            style: {
              color: '#fff',
              background: 'rgba(119, 93, 208, 0.5)',
              cssClass: 'border-0',
            },
          },
        },
      ],
    },
    legend: {
      show: false,
    },
    labels,
    series: [
      {
        name: 'Forecasted',
        data: getMilestoneSeries(notAchievedMilestones, 0.5),
      },
      {
        name: 'Achieved',
        data: getMilestoneSeries(achievedMilestones, 0.5),
      },
      {
        name: 'Baseline',
        data: getMilestoneSeries(baselineMilestones, 1.5),
      },
    ],
  }
}

const getTransparentColor = (color: string, transparency: number): string => {
  // Remove any leading '#' from hex color
  color = color.replace('#', '')

  if (color.length === 3) {
    // Convert short hex format to full hex format
    color = color
      .split('')
      .map((c) => c + c)
      .join('')
  }

  let red: number, green: number, blue: number, alpha: number

  if (color.length === 6) {
    // Parse hex color
    red = parseInt(color.slice(0, 2), 16)
    green = parseInt(color.slice(2, 4), 16)
    blue = parseInt(color.slice(4, 6), 16)
    alpha = transparency
  } else if (color.length === 8) {
    // Parse hex color with alpha
    red = parseInt(color.slice(0, 2), 16)
    green = parseInt(color.slice(2, 4), 16)
    blue = parseInt(color.slice(4, 6), 16)
    alpha = (transparency * parseInt(color.slice(6, 8), 16)) / 255
  } else {
    throw new Error('Invalid color format')
  }

  return `rgba(${red}, ${green}, ${blue}, ${alpha.toFixed(2)})`
}

const getSCurvesColors = (colors: string[]) => {
  const [firstColor, secondColor, thirdColor] = colors
  return [
    firstColor,
    firstColor,
    secondColor,
    getTransparentColor(secondColor, 0.7),
    secondColor,
    getTransparentColor(secondColor, 0.7),
    thirdColor,
    getTransparentColor(thirdColor, 0.7),
    thirdColor,
    getTransparentColor(thirdColor, 0.7),
    getTransparentColor("FF0000", 1),
  ]
}

const WEEK_PER_MONTH = 4.34524

const formatScheduleVariance = (value: number) => {
  const absoluteValue = Math.abs(value)
  const weekNumber = Math.floor(absoluteValue * WEEK_PER_MONTH)
  const dayNumber = Math.round((absoluteValue * WEEK_PER_MONTH - weekNumber) * 7)
  return `${value < 0 ? '-' : ''} ${weekNumber || ''}${weekNumber ? ' W, ' : ''}${dayNumber} D`
}

export {formatScheduleVariance, getMilestonesOptions, getSCurvesColors}
