import {useLang} from '../_metronic/i18n/Metronici18n'
import moment from 'moment'
import 'moment/locale/fr'

export default function useFormatter() {
  const lang = useLang()

  const getFormattedDate = (date: Date) => {
    moment.locale(lang)
    return moment(date).format('MMM YY')
  }

  return getFormattedDate
}
