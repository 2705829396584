/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { DatePicker, TreeSelect, TreeSelectProps } from 'antd'
import { useState } from 'react'
import { DataNode } from 'antd/es/tree'
import { getFormattedUnit } from '../../../../../utils/funcs'
import { findSym } from '../../../../../helpers/func'
import dayjs, { Dayjs } from 'dayjs'
import { normalizeToFirstOfMonthUTC } from '../../../../../utils/data-transformarion/date-utils'

const FirstStep = ({ formik, project, rebase_line_id }: any) => {
  return (
    <div className='current' data-kt-stepper-element='content'>
      <div className='w-100'>
        <div className='fv-row'>
          <label className='fs-6 fw-bolder text-dark mb-7d-flex align-items-center fs-5 fw-semibold mb-4'>
            <span className='required'>Start Date</span>
          </label>
          <DatePicker
            inputReadOnly={true}
            picker={project?.default_version?.period_count?.type === 'daily' ? 'date' : 'month'}
            value={dayjs(formik.values.start_date)}
            popupClassName={'dark-mode-datepicker'}
            className='form-control text-dark form-control-solid my-datepicker'
            placeholder={''}
            open={false}
          />
        </div>
        <div className='fv-row mb-10 mt-10'>
          <label className='fs-6 fw-bolder text-dark mb-7d-flex align-items-center fs-5 fw-semibold mb-4'>
            <span className='required'>New End Date</span>
          </label>
          <DatePicker
            picker={project?.default_version?.period_count?.type === 'daily' ? 'date' : 'month'}
            value={dayjs(formik.values.end_date)}
            popupClassName={'dark-mode-datepicker'}
            className='form-control form-control-solid my-datepicker fw-bold'
            cellRender={(current: Dayjs, info: any) => {
              if (current < dayjs(formik.values?.start_date.$date)) {
                return formik.values.date_unit === 'monthly' ? '--' : 'NS'
              }
              return (
                <div
                >
                  {info.originNode}
                </div>
              )
            }}

            onChange={(newDate) => {
              if (newDate?.isBefore(formik.values.start_date.$date)) {
                return
              }
              if (newDate)
                formik.setFieldValue('end_date', normalizeToFirstOfMonthUTC(newDate.toDate()))
            }}
            placeholder={''}
          />
          {formik.touched.end_date && formik?.errors?.end_date && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik?.errors?.end_date}</span>
              </div>
            </div>)}
        </div>

        <div className='fv-row mb-10 mt-10'>
          <label className='fs-6 fw-bolder text-dark mb-7d-flex align-items-center fs-5 fw-semibold mb-4'>
            <span className='required'>Rebaseline Date</span>
          </label>
          <DatePicker
            disabled={rebase_line_id ? true : false}

            picker={project?.default_version?.period_count?.type === 'daily' ? 'date' : 'month'}
            value={dayjs(formik.values.rebaseLine_dataDate)}
            cellRender={(current: Dayjs, info: any) => {
              if (current <= dayjs(formik.values?.rebase_line[formik.values?.rebase_line?.length - 1]?.data_date || formik.values?.end_date.$date)) {
                return formik.values.date_unit === 'monthly' ? '--' : 'NS'
              }
              return (
                <div
                >
                  {info.originNode}
                </div>
              )
            }}
            popupClassName={'dark-mode-datepicker'}
            className='form-control form-control-solid my-datepicker fw-bold'
            onChange={(newDate) => {
              formik.setFieldTouched('rebaseLine_dataDate', true)
              if (newDate! <= dayjs(formik.values?.rebase_line[0]?.data_date || formik.values?.end_date.$date)) {
                return
              }
              if (newDate)
                formik.setFieldValue('rebaseLine_dataDate', normalizeToFirstOfMonthUTC(newDate.toDate()))
            }}
            placeholder={''}
          />
          {formik.touched.rebaseLine_dataDate && formik?.errors?.rebaseLine_dataDate && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik?.errors?.rebaseLine_dataDate}</span>
              </div>
            </div>)}
        </div>

        <div className='fv-row mb-10 mt-10'>
          <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
            <span className='required'>New Budget Reserve</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title=''
            ></i>
          </label>
          <div className='input-group mb-3'>
            <span className='input-group-text'>
              {getFormattedUnit(formik.values.unit)} {findSym(formik.values.currency.value || '')}
            </span>

            <input
              type='number'
              placeholder=''
              className={clsx(
                'form-control ',
                { 'is-invalid': formik.touched.budgetReserve && formik.errors.budgetReserve },
                {
                  'is-valid': formik.touched.budgetReserve && !formik.errors.budgetReserve,
                }
              )}
              {...formik.getFieldProps('budgetReserve')}
            />
          </div>
          {formik.touched.budgetReserve && formik.errors.budgetReserve && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.budgetReserve}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
            <span className='required'>New Delay Tolerance</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title=''
            ></i>
          </label>

          <div className='input-group'>
            <span className='input-group-text'>{formik.values.date_unit}</span>

            <input
              type='number'
              placeholder=''
              className={clsx(
                'form-control ',
                { 'is-invalid': formik.touched.delayTolerance && formik.errors.delayTolerance },
                {
                  'is-valid': formik.touched.delayTolerance && !formik.errors.delayTolerance,
                }
              )}
              {...formik.getFieldProps('delayTolerance')}
            />
          </div>
          {formik.touched.delayTolerance && formik.errors.delayTolerance && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.delayTolerance}</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export { FirstStep }
