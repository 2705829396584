import {Skeleton} from 'antd'
import SkeletonAvatar from 'antd/es/skeleton/Avatar'

export default function ProjectSkeleton() {
  return (
    <div className='col-md-6 col-xl-6 mb-5 d-flex'>
      <div className='card border border-2 w-100'>
        <div className='card-header border-0 pt-9'>
          <SkeletonAvatar active shape='square' size='large' className='rounded' />
          <div className='card-toolbar'>
            <Skeleton active title={{width: '60px'}} paragraph={{rows: 0, width: '50px'}} />
          </div>
        </div>
        <div className='card-body'>
          <Skeleton active paragraph={{rows: 2}} />
        </div>
      </div>
    </div>
  )
}
