import { useEffect, useState } from 'react'
import Toast from 'react-bootstrap/Toast';
import { Modal } from 'react-bootstrap'
import { patchProject, getSnapshotById, deleteSnapshot } from '../../../core/_requests'
import { showError, sweetAlert } from '../../../../../utils/funcs'
import { Card } from 'react-bootstrap'
import { useProject } from '../../../core/ProjectContext'
import SnapshotSetting from './SnapshotSettings'
import SnapshotsSkeleton from '../../../core/SnapshotsSkeleton'



export default function SnapshotShareProjectModal({

}) {
  const [dataLoaded, setDataLoaded] = useState<boolean>(false)
  const [snapshotEdit, setSnapshotEdit] = useState<boolean>(false);
  const [snapshotAdd, setSnapshotAdd] = useState<boolean>(false);
  const [nbSnapshots, setNbSnapshots] = useState<number>(0);
  const [snapshotsExist, setSnapshotsExist] = useState<boolean>(true);
  const [snapshots, setSnapshots] = useState<any[]>([]);
  const [snapshot, setSnapshot] = useState<any>();
  const [snapshotIds, setSnapshotIds] = useState<string[]>([])
  const { project,setProject,} = useProject()
  const [enableToast, setEnableToast] = useState<boolean>(false);

  /**
   * Check the response from the child element to enable the toast
   * @param enable
   */
  const handleEnableToast = (enable: boolean) => {
    setEnableToast(enable);
  };

  function renderSnapshotSkeletons(times: number){
    const rows = [];
    for (let i = 0; i < times; i++) {
      rows.push(<SnapshotsSkeleton />)
    }
    return rows;
  }

  function renderSnapshotCard(data: any, index: any){
    return (
      <Card className="mt-2" key={index}>
            <Card.Body className="py-0">
              <div className="mt-3">
                <div className="fv-row mb-10">
                  <div className="input-group mb-3">
                    <span className="input-group-text" id="basic-addon1">
                      <i
                        onClick={(e) => copyShareLink(e, snapshots[index])}
                        className="bi bi-clipboard2-check"
                        onMouseOver={(e) => (e.currentTarget.style.opacity = '0.75')}
                        onMouseOut={(e) => (e.currentTarget.style.opacity = '1')}
                        style={{ fontSize: '1.2rem', transition: 'all 0.2s ease-in-out' }}></i>
                    </span>
                    <input type="text" className="form-control disabled" value={data.snapshot_name} aria-label="Username" aria-describedby="basic-addon1" disabled />
                    <span className="input-group-text" id="basic-addon1">

                      <i
                        onClick={(e) => handleEditSnapshot(e, snapshots[index])}
                        className='bi bi-gear d-flex text-hover-primary justify-content-end cursor-pointer ms-2'
                        onMouseOver={(e) => (e.currentTarget.style.opacity = '0.75')}
                        onMouseOut={(e) => (e.currentTarget.style.opacity = '1')}
                        style={{ fontSize: '1.2rem', transition: 'all 0.2s ease-in-out' }}
                      ></i>
                      <i
                        onClick={(e) => handleDeleteSnapshot(e, snapshots[index])}
                        className='bi bi-trash3 d-flex text-danger justify-content-end cursor-pointer ms-2'
                        style={{ fontSize: '1.2rem', transition: 'all 0.2s ease-in-out' }}
                      ></i>
                    </span>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
    )
  }

  function disableAddButton(dataLoaded: boolean, snapshotLen: number): boolean{
    if(snapshotLen > 2){
      return true
    }
    if(!dataLoaded && snapshotLen == 0){
      return false
    }
    if(!dataLoaded){
      return true
    }
    return false
  }

  const fetchSnapshots = async () => {
    setNbSnapshots(project?.snapshots.length)
    setSnapshots([])
    setSnapshotIds([])
    setSnapshotsExist(project?.snapshots.length >= 1)
    project?.snapshots.map((snapshot: { $oid: string }) => {
      getSnapshotById(snapshot.$oid)
      .then((data) => {
        setSnapshots((elem) => [...elem, data])
        setSnapshotIds((id) => [...id, data?._id.$oid])
      }).then(() => setDataLoaded(true))
    })
  }


  useEffect(() => {
    fetchSnapshots()
  }, [project])

  const copyShareLink = (e: React.MouseEvent<HTMLElement, MouseEvent>, snapshot: any) => {
    e.preventDefault()
    e.stopPropagation()
    e.currentTarget.style.color = '#009ef7';
    const shareLink = window.location.origin + '/snapshotshare/' + snapshot._id.$oid;
    navigator.clipboard.writeText(shareLink).then(() => {
      setTimeout(() => {
        e.currentTarget.style.color = '#a1a5b7';
      }, 1500);
    });
  };

  const handleEditSnapshot = (e: React.MouseEvent<HTMLElement, MouseEvent>, snapshot: any) => {
    e.preventDefault()
    e.stopPropagation()
    setSnapshot(snapshot);
    setSnapshotEdit(true);
  };

  const handleDeleteSnapshot = (e: React.MouseEvent<HTMLElement, MouseEvent>, snapshot: any) => {
    e.preventDefault()
    e.stopPropagation()
    sweetAlert({
      title: 'Are you sure?',
      text: 'Are you sure you want to remove the snapshot?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Remove',
    })
    .then((res) => {
      if (res.isConfirmed) {
        snapshotIds.splice(snapshotIds.indexOf(snapshot._id.$oid),1)

        patchProject(
          {
            snapshots: snapshotIds,
          },
          project?._id.$oid || ''
        )
          .then((res) => {
      
            setProject((prev) =>
              prev ? { ...prev, snapshots: res?.snapshots || [] } : prev
            )      
              try{
                deleteSnapshot(snapshot._id.$oid.toString()).catch((err) => err)
              }catch(err){
                console.log("error on delete : ",err)
              }
          })
          .catch(showError)
      }
    })
  };

  const handleAddSnapshot= (e: React.MouseEvent<HTMLButtonElement, MouseEvent>,) => {
    e.preventDefault()
    e.stopPropagation()
    setSnapshotAdd(true);
  };

  if (snapshotEdit === true) {
    return (
      <SnapshotSetting show={true} onHide={() => setSnapshotEdit(false)} snapshot={snapshot} snapshotIds={undefined} nbSnapshots={undefined} sendEnableToast={handleEnableToast} add={false}/>
    )
  }
  if (snapshotAdd === true) {
    return (
      <SnapshotSetting show={true} onHide={() => setSnapshotAdd(false)} snapshot={undefined} snapshotIds={snapshotIds} nbSnapshots={nbSnapshots} sendEnableToast={handleEnableToast} add={true}/>
    )
  }

  

  return (
    <>
      <Modal.Body>
        {!dataLoaded && renderSnapshotSkeletons(nbSnapshots)}
        {!snapshotsExist && 
          <div className="mt-3 d-flex justify-content-center">
            <h3>This project has no snapshots yet</h3>
            <br></br>
          </div>
        }
        {snapshots.map((item, index) => (
          renderSnapshotCard(item, index)
        ))} 
        <div className="mt-3 d-flex justify-content-center">
          <button
            className="btn btn-primary w-100"
            onClick={handleAddSnapshot}
            disabled={disableAddButton(dataLoaded, snapshots.length)}
          >
            + Add Snapshot
          </button>
        </div>
      </Modal.Body>
      {enableToast &&
      <div style={{position: 'fixed', bottom: '1%', right: '1%'}}>
        <Toast bg={'primary'} onClose={() => setEnableToast(false)} show={enableToast} delay={3000} autohide>
          <Toast.Header>
            <strong className="me-auto">Success</strong>
          </Toast.Header>
          <Toast.Body>
            <div className='justify-content-center'>
              <p style={{textAlign: 'center', fontWeight: '800', fontSize: '1.5em', color: 'white'}}>
                Snapshot Link Copied!
              </p>
            </div>
          </Toast.Body>
        </Toast>
      </div>
      }
    </>
  )
}