import {useEffect, useMemo, useState} from 'react'
import {useProject} from '../../core/ProjectContext'
import {MilestoneHeaderList} from './_models'
import {useSortBy, useTable} from 'react-table'
import MilestoneHeader from './MilestoneHeader'
import EditMilestone from './EditMilestone'
import EmptyValuesCard from '../../../../components/emptyValuesCard'
import { getMilestonebywbs } from './requests'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../store'
import { done } from '../comments/CommentsState'
import { Milestone } from './_models'

type Props = {
  milestones:Milestone[]
}

export default function MilestoneTable({milestones}: Props) {
  const { originalVersion, project } = useProject();
  const currentwbs=useSelector((state:RootState)=>state.currentwbs.value)
  const added=useSelector((state:RootState)=>state.added.value)
  const dispatch=useDispatch()
  const columns = useMemo(() => MilestoneHeaderList, [])
  const data = useMemo(() => milestones || [], [milestones])
  const {getTableProps, headerGroups, rows, getTableBodyProps, prepareRow} = useTable(
    {
      columns,
      data,
    },
    useSortBy
  )
  
   
  return (
    <div className='card'>
      <div className='card-body py-3'>
        <table
          className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bold dataTable no-footer'
          {...getTableProps()}
        >
          <thead className='fs-7 text-gray-400 text-uppercase'>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <MilestoneHeader
                    key={column.id}
                    isSorted={column.isSorted}
                    isSortedDesc={column.isSortedDesc}
                    title={column.Header as string}
                    width={(column.Header as string) === 'Comment' ? '250' : '90'}
                    props={column.getHeaderProps(column.getSortByToggleProps)}
                  />
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row)
              return (
                <EditMilestone
                  milestones={data}
                  key={row.original._id.$oid}
                  dateFormat={'mm/dd/yyyy'}
                  milestone={row.original}
                />
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
