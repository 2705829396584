/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { StepProps } from '../IProjectModels'
import UploadProjectImage from '../../../../../app/modules/projects/components/settings/UploadProjectImage'
import { TreeSelect, TreeSelectProps } from 'antd'
import { useState } from 'react'
import { DataNode } from 'antd/es/tree'
import { getFolder } from '../../../../../app/modules/eps/_requests'
import ViewTreeItem from '../../../../../app/modules/projects/core/ProjectsWithFolders/tree/ViewTreeItem'
import { updateTreeData } from '../../../../../app/modules/eps/components/TreeView/_helpers'
import { useFolder } from '../../../../../app/modules/eps/_queries'
import CreatableSelect from '../../../../../app/components/CreatableSelect/CreatableSelect'
import { Sectors } from '../../../../../app/modules/projects/core/_models'
import InputWithLabel from '../../../../../app/components/inputWithLabel/InputWithLabel'

const Step1 = ({ formik }: StepProps) => {
  const [treeData, setTreeData] = useState<DataNode[]>([])

  const { isLoading } = useFolder(undefined, (data) =>
    setTreeData([
      {
        title: <ViewTreeItem refetch={() => { }} folder={data} />,
        key: data._id?.$oid || 'root',
        value: data._id?.$oid || 'root',
      },
    ] as any)
  )
  const loadData: TreeSelectProps['loadData'] = async (treeNode) => {
    if (!treeNode) return
    const childFolder = await getFolder(
      treeNode.key !== 'root' ? treeNode.key?.toString() : undefined
    )
    const childData: DataNode[] = childFolder.subfolders.map((item) => ({
      key: item._id.$oid,
      title: <ViewTreeItem refetch={() => { }} folder={item} />,
      switcherIcon: item.subfolders.length ? undefined : <></>,
      value: item._id.$oid,
    }))
    setTreeData((origin) => {
      return updateTreeData(origin, treeNode.key?.toString() || 'root', childData)
    })
  }
  return (
    <div className='current' data-kt-stepper-element='content'>
      <div className='w-100'>
        {/*begin::Form Group */}
        {/*begin::Form Group */}
        <div className='fv-row'>
          <label className='fs-6 fw-bolder text-dark mb-7d-flex align-items-center fs-5 fw-semibold mb-4'>
            <span className='required'>Project Photo</span>
          </label>
          <div className='fv-row mb-8'>
            <UploadProjectImage
              image={formik.values.pic}
              setImage={(pic) => formik.setFieldValue('pic', pic)}
            />
          </div>
        </div>
        {/*end::Form Group */}
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
            <span className='required'>Project Name</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Specify your unique app name'
            ></i>
          </label>
          <input
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('name')}
            placeholder=''
            className={clsx(
              'form-control ',
              { 'is-invalid': formik.touched.name && formik.errors.name },
              {
                'is-valid': formik.touched.name && !formik.errors.name,
              }
            )}
          />
          {formik.touched.name && formik.errors.name && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.name}</span>
              </div>
            </div>
          )}
        </div>
        {/*end::Form Group */}
        {/*begin::Form Group */}
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
            <span className='required'>Reference Number</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title=''
            ></i>
          </label>
          <input
            autoComplete='off'
            type='text'
            name='reference_number'
            placeholder=''
            className={clsx(
              'form-control ',
              { 'is-invalid': formik.touched.reference_number && formik.errors.reference_number },
              {
                'is-valid': formik.touched.reference_number && !formik.errors.reference_number,
              }
            )}
            {...formik.getFieldProps('reference_number')}
          />
          {formik.touched.reference_number && formik.errors.reference_number && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.reference_number}</span>
              </div>
            </div>
          )}
        </div>
        {/*end::Form Group */}
        {/*begin::Form Group */}
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
            <span>Description</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title=''
            ></i>
          </label>
          <textarea
            autoComplete='off'
            rows={2}
            placeholder=''
            className='form-control'
            {...formik.getFieldProps('description')}
          />
        </div>
        {/*end::Form Group */}

        {/*begin::Form Group */}
        <div className='fv-row mb-5'>
          <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
            <span>EPS</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title=''
            ></i>
          </label>
          <TreeSelect
            value={formik.values.parent_folder}
            onChange={(value) => formik.setFieldValue('parent_folder', value)}
            className='form-control'
            dropdownStyle={{ zIndex: 10000 }}
            popupClassName='eps-dropdown'
            loadData={loadData}
            treeData={treeData}
          />
        </div>
        {/*end::Form Group */}

        {/*begin::Form Group */}
        <div className='fv-row mb-5'>
          <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
            <span>Sector</span>
          </label>
          <CreatableSelect
            options={Sectors}
            value={{ label: formik.values.sector, value: formik.values.sector }}
            onChange={(value) => formik.setFieldValue('sector', value?.value)}
            error={formik.touched.sector && formik.errors.sector}
          />
          {formik.touched.sector && formik.errors.sector && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.sector}</span>
              </div>
            </div>
          )}
        </div>
        {/*end::Form Group */}

        {formik.values.sector === 'Other' && (
          <InputWithLabel
            error={formik.errors.custom_sector}
            formikProps={formik.getFieldProps('custom_sector')}
            label='Custom Sector'
            touched={formik.touched.custom_sector}
            required
          />
        )}
      </div>
    </div>
  )
}

export { Step1 }
