import axios, {AxiosResponse} from 'axios'
import { Milestone } from './_models'
const API_URL_MILESTONE = `${process.env.REACT_APP_API_URL}/milestones`   
const API_URL_MILESTONE_WBS=`${process.env.REACT_APP_API_URL}/milestones-by-wbs`   



export const getMilestones = () =>
    axios.get(`${API_URL_MILESTONE}`).then((res: AxiosResponse<Milestone[]>) => res.data)
  
export const getMilestone = (milestoneId?: string) =>
    axios
      .get(`${API_URL_MILESTONE}${milestoneId ? `/${milestoneId}` : ''}`)
      .then((res: AxiosResponse<Milestone>) => res.data)

export const getMilestonebywbs = (wbsId?: string) =>
  axios
    .get(`${API_URL_MILESTONE_WBS}${wbsId ? `/${wbsId}` : ''}`)
    .then((res: AxiosResponse<Milestone[]>) => res.data)
      
export const postMilestone = (milestoneParams: {subject: string; name?: string,achieved?:string ,wbs?:string,baseline_date?:Date,shared_with_wbs:string[],real_dates?:Date|null,forcast_date?:Date|null,hidden?:boolean;}) =>
    axios.post(`${API_URL_MILESTONE}`, milestoneParams).then((res: AxiosResponse<Milestone>) => res.data)


export const patchMilestone = (
    milestoneId: string,
    milestoneParams: {subject?: string; name?: string,achieved?:string ,wbs?:string,baseline_date?:Date,shared_with_wbs?:string[],real_dates?:Date|null,forcast_date?:any|null,hidden?:boolean}
  ) =>
    axios
      .patch(`${API_URL_MILESTONE}/${milestoneId}`, milestoneParams)
      .then((res: AxiosResponse<Milestone>) => res.data)

export const deleteMilestone = (milestoneId: string) => axios.delete(`${API_URL_MILESTONE}/${milestoneId}`)

