import { useEffect, useState } from 'react'
import { Modal, ProgressBar } from 'react-bootstrap'
import { getVersionById, patchProject, patchSnapshot } from '../../../core/_requests'
import { showError } from '../../../../../utils/funcs'
import clsx from 'clsx'
import dayjs, { Dayjs } from 'dayjs'
import { useFormik } from 'formik'
import { Card } from 'react-bootstrap'
import { useProject } from '../../../core/ProjectContext'
import { mockWidgetOrder } from '../../settings/WidgetOrder/_models'
import CustomSelect from '../../../../../components/CustomSelect'
import { sweetAlert } from '../../../../../utils/funcs'
import { DatePicker } from 'antd'
import { WidgetOrder, widgetOrderNames } from '../../settings/ShareWidgetOrder/_models'
import { KTSVG } from '../../../../../../_metronic/helpers'
import ObjectId from 'bson-objectid';
import { useDispatch, useSelector } from 'react-redux'
import { DataNode } from 'antd/es/tree'
import { getWbs } from '../../../../wbs/_requests'
import { addWbsToStore } from '../../../../wbs/treedata'
import TreeView, { flattenTree } from 'react-accessible-treeview';
import { IoMdArrowDropright } from 'react-icons/io';
import { FaSquare, FaCheckSquare, FaMinusSquare } from 'react-icons/fa';
import cx from 'classnames';
import { AppDispatch, RootState } from '../../../../../store';
import { fetchDataById, setVersionData } from '../../../../wbs/treeVersionData'
import { transformToFolder } from '../../comments/request'
import { incrementLoaded, updateLabel, updateLoaded, updateShow, updateTotal } from '../../../../wbs/treeLoader'
import { combineVersionsV2 } from '../../../../wbs/components/TreeView/_helpers'




type Props = {
  setShareVersionId?: any
  show: boolean
  onHide: () => void
  snapshot: any
  snapshotIds: any
  nbSnapshots: any
  sendEnableToast: any
  add: boolean
}

export default function SnapshotSetting({
  onHide,
  snapshot,
  snapshotIds,
  nbSnapshots,
  sendEnableToast,
  add
}: Props) {
  const [loading, setLoading] = useState<boolean>(false)
  const [toggleShare, setToggleShare] = useState<boolean>(add ? true : snapshot.shareable)
  const [togglePassword, setTogglePassword] = useState<boolean>(false)
  const [expirationDate, setExpirationDate] = useState(dayjs().add(1, 'month'))
  const { project, originalVersion: data, setProject, setWbsDataDateFirstStepAsync, setWbsDataDateSecondStepAsync } = useProject()
  const [name, setName] = useState<string>("")
  const [snapshotDate, setSnapshotDate] = useState(dayjs(data?.data_date.$date));
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [snapshotName, setSnapshotName] = useState(snapshot === undefined ? '' : snapshot.snapshot_name)
  const [snapshotNumber, setSnapshotNumber] = useState<any>(nbSnapshots)
  const [treedata, setTreedata] = useState<any>()
  const dispatch = useDispatch();
  const [checkedWbs, setCheckedWbs] = useState<string[]>(snapshot?.list_wbs_checked ? snapshot?.list_wbs_checked.map((wbs: any) => wbs?._id.$oid) : []);
  const [progress, setProgress] = useState(0);
  const [overlayVisible, setOverlayVisible] = useState<boolean>(true);
  const [processedNodes, setProcessedNodes] = useState(0);
  const [treeData, setTreeData] = useState<DataNode[]>([]);
  const [totalNodes, setTotalNodes] = useState(0);
  const [selectedWbs, setSelectedWbs] = useState<string>(snapshot?.share_wbs ? snapshot?.share_wbs.$oid : project?.default_wbs._id.$oid);  const knownWbs = useSelector((state: RootState) => state.treedata.knownWbs);
  const projectversiondata = useSelector((state: RootState) => state.versiondata.data)
  const dispatchh: AppDispatch = useDispatch();
  const order : WidgetOrder[]= snapshot?.widget_order.length > 0 ? snapshot?.widget_order : mockWidgetOrder

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const formik = useFormik({
    initialValues: {
      name: name,
      password: '',
      data_date: snapshotDate,
      widgetOrder: order,
      date: expirationDate,
    },

    enableReinitialize: true,
    onSubmit: async (values) => {
      setLoading(true)
      //changing date input format to match the others
      if (snapshotNumber > 2) {
        sweetAlert({
          title: 'Snapshot limit exceeded',
          text: 'You cannot add more than 3 snapshots',
          icon: 'info',
          confirmButtonText: 'OK',
        })
      } else {
        const date_value = dayjs(values.data_date).startOf('month').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        const list_wbs_data = await calculateCombinedVersionForEachCheckedWbs(new Date(date_value));

        const list_wbs_checked = checkedWbs.map(wbs => knownWbs[wbs])

        patchSnapshot(
          {
            protection: togglePassword,
            snapshot_name: values.name,
            password: togglePassword ? values.password : null,
            project: project?._id.$oid,
            widget_order: values.widgetOrder,
            shareable: toggleShare,
            expiration_date: values.date,
            data_date: date_value,
            //parent wbs (selected in the drop down)
            share_wbs: selectedWbs,
            //Redux : knowWbs is an object that contains wbs infos
            list_wbs_checked: [...list_wbs_checked, knownWbs[selectedWbs]],
            list_wbs_data: list_wbs_data,

          }, snapshot === undefined ? new ObjectId().toString() : snapshot._id.$oid)
          .then((res) => {
            if (snapshot === undefined) {
              const newSnapshots = [...snapshotIds];
              newSnapshots.push(res._id.$oid)
              patchProject(
                {
                  snapshots: newSnapshots,
                },
                project?._id.$oid || ''
              )
                .then((res) => {
                  setProject((prev) =>
                    prev ? { ...prev, snapshots: res?.snapshots || [] } : prev
                  )
                })
              setLoading(false);
            }
            if (toggleShare) {
              const shareLink = window.location.origin + '/snapshotshare/' + res._id.$oid;
              navigator.clipboard.writeText(shareLink)
            }
          })
          .catch(showError)
          .finally(() => {
            if (nbSnapshots != undefined) {
              setSnapshotNumber(snapshotNumber + 1)
            }
            setLoading(false)
          })
      }

      setTimeout(() => {
        sendEnableToast(toggleShare)
        onHide()
      }, 500)

    },
  })

  const collectLeafData = async (node: string): Promise<any[]> => {
    const leafData: any[] = []
    const traverse = async (currentNode: string) => {
      const nodeValue = knownWbs[currentNode]
      if (checkedWbs.includes(currentNode)){
      if (!nodeValue?.subwbs || nodeValue?.subwbs?.length === 0) {
        if(nodeValue.data){
        let versionData = projectversiondata[nodeValue.data.$oid]
        if (!versionData) {
          let fetchedData = await getVersionById(nodeValue.data.$oid)
          fetchedData = { ...fetchedData, name: nodeValue.name }
          dispatch(setVersionData({ id: nodeValue.data.$oid, data: fetchedData }))
          versionData = fetchedData
        }
        leafData.push(versionData)
      }
      } else {
        for (const child of nodeValue.subwbs) {
  
          const childWbsData = knownWbs[child.$oid] || (await getWbs(child?.$oid))
          if (!knownWbs[child?.$oid]) {
            dispatch(addWbsToStore(childWbsData))
          }
  
          await traverse(childWbsData._id.$oid)
        }
      }
    }
  
    }
    await traverse(node as string)
    return leafData
  } 

  const calculateCombinedVersionForEachCheckedWbs = async (data_date: Date): Promise<any[]> => {
  const wbsListData: any[] = [];
  dispatch(updateLoaded(0));
  dispatch(updateTotal(checkedWbs.length));
  dispatch(updateShow(checkedWbs.length !== 0));
  dispatch(updateLabel('Calculating Dashboard Data...'));

  await Promise.all(
    checkedWbs.map(async (node, index) => {
      try {
        dispatch(updateLabel('Calculating Dashboard Data ' + (index + 1) + ' of ' + checkedWbs.length));

        const leafData = await collectLeafData(node);
        console.log('Leaf data collected for node', node, leafData);

        if (!leafData || leafData.length === 0) {
          console.warn('No leaf data found for node:', node);
          return; // Skip to the next iteration
        }

        let combinedVersion = await combineVersionsV2(
          knownWbs[node].name,
          leafData,
          true,
          setWbsDataDateFirstStepAsync,
          setWbsDataDateSecondStepAsync,
          data_date,
        );

        console.log('Combined version for node', node, combinedVersion);

        combinedVersion = { ...combinedVersion, _id: { $oid: knownWbs[node]?.data?.$oid } };
        wbsListData.push(combinedVersion);
        dispatch(incrementLoaded());
      } catch (error) {
        console.error('Error processing node:', node, error);
      }
    })
  );

  dispatch(updateShow(false));
  return wbsListData;
};

  


  useEffect(() => {
    const loadAllWbs = async (wbsId: string): Promise<DataNode> => {
      let knownWbsItem = knownWbs[wbsId];
      if (!knownWbsItem) {
        const response = await getWbs(wbsId);
        dispatch(addWbsToStore(response));
        knownWbsItem = response;
      }

      const childrenData: DataNode[] = await Promise.all(
        knownWbsItem.subwbs.map(async (subWbs: any) => {
          return await loadAllWbs(subWbs.$oid);
        })
      );

      return {
        key: knownWbsItem._id.$oid,
        children: childrenData,
      };
    };

    const initializeTree = async () => {
      const rootNode = await loadAllWbs(selectedWbs);
      setTreeData([rootNode]);
    };
    setCheckedWbs([]);
    setOverlayVisible(true);
    setProcessedNodes(0)
    setProgress(0)
    setTotalNodes(0)
    initializeTree();



  }, [selectedWbs]);


  useEffect(() => {
    const traverseTree = async (node: DataNode) => {
      const vers = knownWbs[node?.key];
      setTotalNodes(prev => prev + 1)
      if (vers.subwbs.length === 0 && vers.data) {
        await dispatchh(fetchDataById(vers.data.$oid));
      }
      setProcessedNodes(prev => prev + 1);
      if (node.children && node.children.length > 0) {
        await Promise.all(node.children.map((childNode: DataNode) => traverseTree(childNode)));
      }
    };

    if (treeData && treeData.length > 0) {
      traverseTree(treeData[0]);
    }
  }, [treeData]);


  useEffect(() => {
    if (totalNodes > 0) {
      setProgress((processedNodes / totalNodes) * 100);

      // Hide overlay when progress reaches 100%
      if ((processedNodes / totalNodes) * 100 >= 100) {
        setOverlayVisible(false);

      }
    }
  }, [processedNodes, totalNodes]);


  //load tree data after loading the data of wbs
  //if overlay = true -> data is still loading (progress bar) else loading data is completed
  useEffect(() => {
    if (!overlayVisible) {
      const folder = transformToFolder(knownWbs[selectedWbs], knownWbs);
      setTreedata(flattenTree(folder))
      if (selectedWbs === snapshot?.share_wbs?.$oid) {
        if (snapshot && snapshot?.list_wbs_checked) {
          setCheckedWbs(snapshot.list_wbs_checked.map((wbs: any) => wbs?._id.$oid));
        }
      } else {
        setCheckedWbs([]);
      }
    }
  }, [overlayVisible, selectedWbs]);







  // Getting the shareable and expiration date fields from the backend and update the state variables
  useEffect(() => {
    if (snapshot !== undefined) {
      setTogglePassword(snapshot.protection);
      setToggleShare(snapshot.shareable);
      setExpirationDate(dayjs(snapshot.expiration_date.$date));
      setName(snapshot.snapshot_name)
      setSnapshotDate(dayjs(snapshot.data_date.$date))
    }
  }, []);



  const handleNodeSelect = (node: any) => {
    const selectedIds = node.treeState?.selectedIds || [];
    let idsArray: string[] = Array.from(selectedIds);
  
    // Function to find parent nodes recursively
    const findParentNodes = (currentNode: string): string[] => {
      const parents: string[] = [];
      let parentNode = knownWbs[currentNode]?.parent_wbs?.$oid; // Assuming each node has a parentId
  
      while (parentNode) {
        parents.push(parentNode);
        parentNode = knownWbs[parentNode]?.parent_wbs?.$oid;
      }
      return parents;
    };
  
    // Function to find child nodes recursively
    const findChildNodes = (currentNode: string): string[] => {
      const children: string[] = [];
      const nodeValue = knownWbs[currentNode];
  
      // Ensure nodeValue exists and subwbs is an array before attempting to iterate
      if (nodeValue?.subwbs && Array.isArray(nodeValue.subwbs)) {
        nodeValue.subwbs.forEach((child: any) => {
          const childId = child.$oid;
          children.push(childId);
          children.push(...findChildNodes(childId)); // Recurse for each child
        });
      }
  
      return children;
    };
  
    // Loop through the selected nodes and get their parents
    idsArray.forEach((nodeId) => {
      const parentNodes = findParentNodes(nodeId);
      idsArray = [...new Set([...idsArray, ...parentNodes])]; // Add parents and remove duplicates
    });
  
    // Check for deselected nodes manually if `deselectedIds` is not available
    const previousCheckedWbs = checkedWbs || [];
    const deselectedIds = previousCheckedWbs.filter((id) => !idsArray.includes(id));
  
    // Handle deselection: remove deselected node and all its children
    deselectedIds.forEach((deselectedId: string) => {
      const childNodes = findChildNodes(deselectedId);
      idsArray = idsArray.filter((id) => !childNodes.includes(id) && id !== deselectedId); // Remove deselected node and its children
    });
  
    // Update the checked nodes state
    setCheckedWbs(idsArray);
  };
  

  return (
    <>
      {overlayVisible && (
        <div
          className="progress-overlay"
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(128, 128, 128, 0.7)',
            zIndex: 1050,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            pointerEvents: 'none',
          }}
        >
          <ProgressBar now={progress} style={{ width: '50%' }} />
        </div>
      )}
      <form onSubmit={formik.handleSubmit}>

        <Modal.Body>

          {/* Share toggle */}

          <Card className='mt-2'>
            <Card.Header>
              <div></div>
              <div className='d-flex align-items-center justify-content-between'>
                {/* Toggle button for share */}
                <div className='form-check form-switch'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    id='shareToggle'
                    checked={toggleShare}
                    onChange={() => setToggleShare(!toggleShare)}
                  />
                  <label className='form-check-label' htmlFor='shareToggle'>
                    Share
                  </label>
                </div>
              </div>
            </Card.Header>

            {/* Name input */}

            <Card.Body>
              <div className='mt-3'>
                <div className='fv-row mb-10'>
                  <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                    <span className='required'>Snapshot name</span>
                  </label>
                  <input
                    name="name"
                    type="text"
                    className='form-control'
                    onChange={formik.handleChange}
                    value={formik.values.name === '' ? snapshotName : formik.values.name}
                    onBlur={(e: any) => setSnapshotName(e.target.value)}
                    required
                  />
                </div>
              </div>
            </Card.Body>
          </Card>

          {/* Version Dropdown*/}

          <Card className='mt-2'>
            <Card.Body>
              <div className='mt-3'>
                <div className='fv-row mb-10'>
                  <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                    <span>Select Version</span>
                  </label>
                  {project?.wbs_list && project.wbs_list.length > 0 && (
                    <div>
                      <CustomSelect
                        name='perioda'
                        options={project.wbs_list.map((item: any, index: number) => ({
                          label: 'Version ' + index,
                          value: item.$oid,
                        }))}
                        value={project.wbs_list.map((item: any, index: number) => ({
                          label: 'Version ' + index,
                          value: item.$oid,
                        })).find((item: any) => selectedWbs === item.value)}
                        onChange={(e: any) => {
                          setOverlayVisible(true);
                          setCheckedWbs([])
                          setSelectedWbs(e.value);
                        }}
                        padding={true}
                      />
                    </div>
                  )}
                </div>
              </div>
            </Card.Body>
          </Card>

          {/* Tree view*/}

          {(treedata && !overlayVisible) && (
            <TreeView
              data={treedata}
              aria-label='Single select'
              multiSelect={true}
              propagateSelectUpwards={false}
              propagateSelect={false}
              propagateCollapse={false}
              togglableSelect={true}
              selectedIds={Array.from(new Set(checkedWbs))}
              defaultDisabledIds={[selectedWbs]}
              nodeAction='check'
              onSelect={(node) => handleNodeSelect(node)}
              nodeRenderer={({
                element,
                isBranch,
                isExpanded,
                isSelected,
                isHalfSelected,
                isDisabled,
                getNodeProps,
                level,
                handleSelect,
                handleExpand,
              }) => (
                <div
                {...getNodeProps({ onClick: handleExpand })}
                style={{ marginLeft: 30 * (level - 1),
                  opacity: isDisabled ? 0.3 : 1,
                 }}
              >
                  {isBranch && <ArrowIcon isOpen={isExpanded} />}
                  <CheckBoxIcon
                    onClick={(e: any) => {
                      handleSelect(e);
                      e.stopPropagation();
                    }}
                    variant={isHalfSelected ? 'some' : isSelected ? 'all' : 'none'}
                  />
                 {isBranch?
              <KTSVG className='svg-icon-primary svg-icon-2 me-2'
              path='/media/icons/duotune/graphs/git04.svg' />:
              <KTSVG
              path='/media/icons/duotune/files/fil003.svg'
              className='svg-icon-primary svg-icon-2 me-2'
            /> }
              <span className='name h5'>{element.name}</span>
            </div>
              )}
            />
          )}





          {/* Date Select */}

          <Card className='mt-2'>
            <Card.Body className="py-0">

              <div className="mt-3">
                <div className="fv-row mb-10">
                  <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
                    <span>Data Date</span>
                  </label>
                  <div className="d-flex">
                    <DatePicker
                      disabledDate={(current) => {
                        // Disable dates after end_date or before start_date
                        return (
                          !(current.isAfter(dayjs(data?.start_date?.$date).subtract(1, 'month')) &&
                            current.isBefore(dayjs(data?.data_date?.$date).add(1, 'month')))
                        );
                      }}
                      cellRender={(current: Dayjs, info: any) => {
                        if (current.isBefore(dayjs(data?.start_date.$date)))
                          return data?.period_count.type === 'monthly' ? 'Not Started' : 'NS';
                        return (
                          <div
                            className={clsx(
                              current.isBefore(dayjs(data?.data_date?.$date).add(1, 'month')) ? 'highlighted' : ''
                            )}
                          >
                            {info.originNode}
                          </div>
                        );
                      }}
                      className={clsx(
                        'form-control w-75',
                        { 'is-invalid': formik.touched.data_date && formik.errors.data_date },
                        { 'is-valid': formik.touched.data_date && !formik.errors.data_date }
                      )}
                      {...formik.getFieldProps('date1')}
                      suffixIcon={null}
                      picker={data?.period_count?.type === 'daily' ? 'date' : 'month'}
                      format="YYYY-MM"
                      value={formik.values.data_date ? dayjs(formik.values.data_date) : undefined}
                      onChange={(date) => {
                        formik.setFieldValue('data_date', date?.toDate());
                      }}
                      allowClear={false}
                    />


                  </div>
                  {formik.touched.data_date && formik.errors.data_date && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{String(formik.errors.data_date)}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Card.Body>
          </Card>

          {/* Expiration Date select */}

          <Card className='mt-2'>
            <Card.Body>
              <div className='mt-3'>
                <div className='fv-row mb-10'>

                  <label className='fs-5 fw-semibold mb-2 '>
                    <span>Expiration Date </span>


                  </label>
                  <DatePicker
                    className={clsx(
                      'form-control',
                      { 'is-invalid': formik.touched.date && formik.errors.date },
                      { 'is-valid': formik.touched.date && !formik.errors.date }
                    )}
                    {...formik.getFieldProps('date')}
                    suffixIcon={null}
                    format='YYYY-MM-DD'
                    value={formik.values.date ? dayjs(formik.values.date) : undefined}
                    onChange={(date, dateString) => {
                      formik.setFieldValue('date', date?.toDate())
                    }}
                    allowClear={false}
                  />
                  {formik.touched.date && formik.errors.date && (
                    <div className='fv-plugins-message-container'>

                      <div className='fv-help-block'>

                        <span role='alert'>{String(formik.errors.date)}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Card.Body>
          </Card>

          {/* Protection toggle & Password */}

          <Card className='mt-2'>
            <Card.Body>
              <div className='mt-3'>
                <div className='fv-row mb-10'>



                  <label className='d-flex align-items-center fs-5 fw-semibold mb-2 justify-content-between'>

                    <span className=''>Activate Protection </span>
                    {/* Toggle button for share */}
                    <div className='form-check form-switch'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id='shareToggle'
                        checked={togglePassword}
                        onChange={() => setTogglePassword(!togglePassword)}
                      />
                      <label className='form-check-label' htmlFor='shareToggle'>


                      </label>
                    </div>
                  </label>
                  {togglePassword ? (
                    <div className="input-group">
                      <input
                        pattern='.{5,}'
                        required
                        title='5 characters required'
                        name="password"
                        type={passwordVisible ? 'text' : 'password'}
                        className='form-control'
                        onChange={formik.handleChange}
                        value={formik.values.password}
                      />
                      <div className="input-group-append">
                        <button
                          className="btn btn-outline-secondary"
                          type="button"
                          onClick={togglePasswordVisibility}
                        >
                          <i className={passwordVisible ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </button>
                      </div>
                    </div>
                  ) : (
                    <input
                      name="password"
                      type="password"
                      className='form-control'
                      onChange={formik.handleChange}
                      value={formik.values.password}
                    />
                  )}


                </div>
              </div>
            </Card.Body>
          </Card>

          {/* Overview Selection */}

          <Card className='mt-2'>
            <Card.Header>
              <Card.Title>Select Overview</Card.Title>
            </Card.Header>
            <Card.Body>


              <div className='d-flex flex-column gap-2'>
                {formik.values.widgetOrder.map((item) => {
                    const handleMoveUp = () => {
                      const index = formik.values.widgetOrder.findIndex((i) => i.widget === item.widget)
                      if (index === 0) return
                      let temp = formik.values.widgetOrder[index - 1]
                      const tempArray = formik.values.widgetOrder
                      tempArray[index - 1] = item
                      tempArray[index] = temp
                      formik.setFieldValue('widgetOrder', tempArray)
                    }

                    const handleMoveDown = () => {
                      const index = formik.values.widgetOrder.findIndex((i) => i.widget === item.widget)
                      if (index === formik.values.widgetOrder.length - 1) return
                      let temp = formik.values.widgetOrder[index + 1]
                      const tempArray = formik.values.widgetOrder
                      tempArray[index + 1] = item
                      tempArray[index] = temp
                      formik.setFieldValue('widgetOrder', tempArray)
                    }

                    const handleToggleShow = () => {
                      const newArray = formik.values.widgetOrder.map((i) =>
                        i.widget === item.widget ? { ...i, show: !i.show } : i
                      )
                      formik.setFieldValue('widgetOrder', newArray)
                    }
                    return (
                      <div key={item.widget} className='d-flex align-items-center py-1 px-2 rounded bg-hover-light-primary'>
                        {!!widgetOrderNames[item.widget]?.icon && (
                          <KTSVG path={widgetOrderNames[item.widget].icon} className='me-3 svg-icon-1' />
                        )}
                        <input
                          className='form-check-input'
                          type='checkbox'
                          checked={item.show}
                          id='flexCheckDefault'
                          onChange={handleToggleShow}
                        />
                        <button
                          disabled={formik.values.widgetOrder[0].widget === item.widget}
                          id='up_folder'
                          type='button'
                          style={{ transition: 'all 0.2s ease-in-out' }}
                          className='btn btn-icon btn-sm'
                          onClick={handleMoveUp}
                        >
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr003.svg'
                            className='svg-icon-muted text-hover-primary svg-icon-3'
                          />
                        </button>
                        <button
                          disabled={formik.values.widgetOrder.at(-1)?.widget === item.widget}
                          id='down_folder'
                          type='button'
                          style={{ transition: 'all 0.2s ease-in-out' }}
                          className='btn btn-icon btn-sm'
                          onClick={handleMoveDown}
                        >
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr004.svg'
                            className='svg-icon-muted text-hover-primary svg-icon-3'
                          />
                        </button>
                        <span className='fs-5 fw-bold'>{widgetOrderNames[item.widget]?.name}</span>
                      </div>
                    )
                  }
                  )}
              </div>

            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <button disabled={loading} onClick={onHide} className='btn btn-light-primary'>
            Cancel
          </button>
          <button disabled={loading} type='submit' className='btn btn-primary'>
            {loading ? (
              <span className='indicator-progress' style={{ display: 'block' }}>
                <span className='spinner-border spinner-border-sm align-middle'></span>
              </span>
            ) : (
              'Submit'
            )}
          </button>
        </Modal.Footer>
      </form>
    </>
  )
}

const ArrowIcon = ({ isOpen, className }: { isOpen: boolean; className?: string }) => {
  const baseClass = 'arrow';
  const classes = cx(baseClass, { [`${baseClass}--closed`]: !isOpen }, { [`${baseClass}--open`]: isOpen }, className);
  return <IoMdArrowDropright className={classes} />;
};

interface CheckBoxIconProps {
  variant: 'all' | 'none' | 'some';
  [key: string]: any;
}

const CheckBoxIcon: React.FC<CheckBoxIconProps> = ({ variant, isDisabled, ...rest }) => {
  switch (variant) {
    case 'all':
      return <FaCheckSquare {...rest} />;
    case 'none':
      return <FaSquare {...rest} />;
    case 'some':
      return <FaMinusSquare {...rest} />;
    default:
      return null;
  }
};
