import {Calendar} from '../modules/calendars/_models'
import moment from 'moment'
import {forecastEvFitting} from './forecast/ev-fitting'
import {forecastBaselineFitting} from './forecast/baseline-fitting'
import {forecastSpit} from './forecast/spit-fitting'
import {forecastMaxFte} from './forecast/max-fte-fitting'
import {ForecastFormula, ProjectModel, ProjectObject} from '../modules/projects/core/_models'
import {forecastFinishDate} from './forecast/fixed-date-fitting'
import {forecastCustomDate} from './forecast/custom-date-fitting'
import {getProjectData} from './project-data'
import {getDisplayableValues} from '../helpers/func'
import {getPeriodicFromCumulative} from '../modules/projects/components/data/prepareDataTable'
import {
  ForecastSettingsFormType,
  ForecastSettingsFormWithName,
} from '../modules/projects/components/overview/_models'
import {patchVersion} from '../modules/projects/core/_requests'
import {showError} from './funcs'
import {sectorForecast} from './forecast/sector-forecast'
import {forecastAuto} from './forecast/auto'
import { getDiffrentBettwenDate } from './data-transformarion/date-utils'

export const getDiffBetweenTwoDates = (
  startTimestamp: number,
  endTimestamp: number,
  durationUnit: 'year' | 'month' | 'day'
) =>
  Math.round(
    moment(new Date(endTimestamp)).diff(new Date(startTimestamp), `${durationUnit}s`, true)
  )

const getForecast = async (
  inputs: any,
  data: any,
  cumulativeEarnedValuesAsPercentages: any,
  SPIt: number[],
  EarnedSchedule: number[],
  calendar?: Calendar,
  sector?: string
): Promise<{
  tableData: (string[] | moment.Moment[])[]
  nbOfRemainingMonths: number
}> => {
  if ((+cumulativeEarnedValuesAsPercentages.at(-1)).toFixed(2) === '100.00')
    return {
      tableData: [
        Array.from({length: cumulativeEarnedValuesAsPercentages.length}).map((_el, idx) =>
          moment(inputs.inputDataDate).add(idx + 1, 'month')
        ),
        cumulativeEarnedValuesAsPercentages.map((item: any) => item + ''),
        getPeriodicFromCumulative(cumulativeEarnedValuesAsPercentages).map((item) => item + ''),
      ],
      nbOfRemainingMonths: 0,
    }
  const projectLength = getDiffrentBettwenDate(
    data.data_date.$date,
    data.start_date.$date,
    data.period_count.type
  )
  // if (projectLength < 2) {
  //   return getDisplayableValues(
  //     {
  //       ...inputs,
  //       nbOfMonthsPassed: inputs.nbOfTimeUnitPassed + 1,
  //       nbOfTimeUnitPassed: inputs.nbOfTimeUnitPassed + 1,
  //     },
  //     cumulativeEarnedValuesAsPercentages.map((item: any) => item / 100),
  //     getPeriodicFromCumulative(cumulativeEarnedValuesAsPercentages).map((item) => item / 100),
  //     false
  //   )
  // }

  if (!data.forecast_settings?.forecast_formula)
    return forecastEvFitting(inputs, data, cumulativeEarnedValuesAsPercentages, calendar)
  if (
    data.forecast_settings?.forecast_formula ===
    ForecastFormula['EV Fitting correlated to baseline profile']
  )
    return forecastBaselineFitting(inputs, data, cumulativeEarnedValuesAsPercentages, calendar)
  if (
    data.forecast_settings?.forecast_formula ===
    ForecastFormula['Estimated End Date using Earned Schedule']
  )
    return forecastSpit(
      inputs,
      data,
      cumulativeEarnedValuesAsPercentages,
      SPIt,
      EarnedSchedule,
      calendar
    )
  if (data.forecast_settings?.forecast_formula === ForecastFormula['Max FTE'])
    return forecastMaxFte(inputs, data, cumulativeEarnedValuesAsPercentages, calendar)
  if (data.forecast_settings?.forecast_formula === ForecastFormula['Project Finish Date'])
    return forecastFinishDate(
      inputs,
      data,
      cumulativeEarnedValuesAsPercentages,
      SPIt,
      EarnedSchedule,
      calendar
    )
  if (data.forecast_settings?.forecast_formula === ForecastFormula['Custom Finish Date'])
    return forecastCustomDate(inputs, data, cumulativeEarnedValuesAsPercentages, calendar)

  if (
    data.forecast_settings?.forecast_formula ===
    ForecastFormula['EV Fitting correlated to similar projects']
  ) {
    return sectorForecast(inputs, data, cumulativeEarnedValuesAsPercentages, calendar, sector)
  }
  if (data.forecast_settings?.forecast_formula === ForecastFormula['Auto']) {
    return forecastAuto(
      inputs,
      data,
      cumulativeEarnedValuesAsPercentages,
      SPIt,
      EarnedSchedule,
      calendar,
      sector
    )
  }
  return forecastEvFitting(inputs, data, cumulativeEarnedValuesAsPercentages, calendar)
}

const changeForecastType = async (
  values: ForecastSettingsFormType,
  displayVersion: ProjectObject | undefined,
  originalVersion: ProjectObject | undefined,
  project: ProjectModel | undefined,
  setVersion: (value: React.SetStateAction<ProjectObject | undefined>) => void,
  setOriginalVersion: (value: React.SetStateAction<ProjectObject | undefined>) => void,
  setLoading: (value: React.SetStateAction<boolean>) => void,
  onHide: any,
  updateVersionState: any
) => {
  setLoading(true)
  const bottom_up_ec = [
    ...(displayVersion?.forecast_settings?.bottom_up_ec
      ? displayVersion?.forecast_settings?.bottom_up_ec.filter(
          (item) => item.label !== displayVersion.output?.dataDateLabel
        )
      : []),
    {label: displayVersion?.output?.dataDateLabel || '', value: +values.bottom_up_ec},
  ]
  const updates = {
    total_hours: +values.total_hours,
    full_capacity: +values.full_capacity,
    forecast_settings: {
      bottom_up_ec,
      eac_formula: values.eacFormula,
      forecast_formula: values.forecastFormula,
      custom_eac: +values.custom_eac,
      max_capacity: +values.max_capacity,
      custom_end_date: values.custom_end_date
        ? {$date: values.custom_end_date.getTime()}
        : undefined,
    },
  }

  if (displayVersion?.data_date.$date !== originalVersion?.data_date.$date) {
    const newDisplayVersion = {...displayVersion, ...updates}
    await getProjectData(newDisplayVersion, project?.associated_calendar, project?.sector).then(
      (res) =>
        setVersion((prev) =>
          prev
            ? {
                ...prev,
                output: res,
                ...updates,
                colors: {
                  ...displayVersion?.colors,
                  s_cruve_colors: [
                    values.plannedColor,
                    values.earnedValueColor,
                    values.actualCostColor,
                  ],
                },
                forecast_settings: {
                  bottom_up_ec,
                  eac_formula: values.eacFormula,
                  forecast_formula: values.forecastFormula,
                  custom_eac: +values.custom_eac,
                  max_capacity: +values.max_capacity,
                  custom_end_date: values.custom_end_date?.toISOString(),
                },
              }
            : undefined
        )
    )
  }

  const newOriginalVersion = {...originalVersion, ...updates}
  getProjectData(newOriginalVersion, project?.associated_calendar, project?.sector)
    .then((res) =>
      patchVersion(
        {
          output: res,
          total_hours: +values.total_hours,
          full_capacity: +values.full_capacity,
          forecast_settings: {
            bottom_up_ec,
            eac_formula: values.eacFormula,
            forecast_formula: values.forecastFormula,
            custom_eac: +values.custom_eac,
            max_capacity: +values.max_capacity,
            custom_end_date: values.custom_end_date?.toISOString(),
          },
          colors: {
            ...displayVersion?.colors,
            s_cruve_colors: [values.plannedColor, values.earnedValueColor, values.actualCostColor],
          },
        },
        displayVersion?._id.$oid
      )
        .then((res) => {
          updateVersionState(res)
          setOriginalVersion(res)
          setVersion(res)
        })
        .catch(showError)
        .finally(() => {
          setLoading(false)
          onHide()
        })
    )
    .catch((err) => showError(err || 'Something went wrong!'))
}

const changeForecastTypeFromSettings = async (
  values: ForecastSettingsFormWithName,
  displayVersion: ProjectObject | undefined,
  originalVersion: ProjectObject | undefined,
  project: ProjectModel | undefined,
  setVersion: (value: React.SetStateAction<ProjectObject | undefined>) => void,
  setOriginalVersion: (value: React.SetStateAction<ProjectObject | undefined>) => void,
  setLoading: (value: React.SetStateAction<boolean>) => void,
  onHide: any
) => {
  setLoading(true)
  const bottom_up_ec = [
    ...(displayVersion?.forecast_settings?.bottom_up_ec
      ? displayVersion?.forecast_settings?.bottom_up_ec.filter(
          (item) => item.label !== displayVersion.output?.dataDateLabel
        )
      : []),
    {label: displayVersion?.output?.dataDateLabel || '', value: +values.bottom_up_ec},
  ]
  const updates = {
    date_format: values.dateUnit,
    total_hours: +values.total_hours,
    full_capacity: +values.full_capacity,
    float_formatter: values.floatFormatter,
    forecast_settings: {
      bottom_up_ec,
      eac_formula: values.eacFormula,
      forecast_formula: values.forecastFormula,
      custom_eac: +values.custom_eac,
      max_capacity: +values.max_capacity,
      custom_end_date: values.custom_end_date
        ? {$date: values.custom_end_date.getTime()}
        : undefined,
    },
  }

  if (displayVersion?.data_date.$date !== originalVersion?.data_date.$date) {
    const newDisplayVersion = {...displayVersion, ...updates}
    await getProjectData(newDisplayVersion, project?.associated_calendar, project?.sector).then(
      (res) =>
        setVersion((prev) =>
          prev
            ? {
                ...prev,
                output: res,
                ...updates,
              }
            : undefined
        )
    )
  }

  const newOriginalVersion = {...originalVersion, ...updates}
  getProjectData(newOriginalVersion, project?.associated_calendar, project?.sector)
    .then((res) =>
      patchVersion(
        {
          name: values.name,
          date_format: updates.date_format,
          float_formatter: updates.float_formatter,
          output: res,
          total_hours: +values.total_hours,
          full_capacity: +values.full_capacity,
          forecast_settings: {
            bottom_up_ec,
            eac_formula: values.eacFormula,
            forecast_formula: values.forecastFormula,
            custom_eac: +values.custom_eac,
            max_capacity: +values.max_capacity,
            custom_end_date: values.custom_end_date?.toISOString(),
          },
          colors: {
            ...displayVersion?.colors,
            s_cruve_colors: [values.plannedColor, values.earnedValueColor, values.actualCostColor],
          },
        },
        displayVersion?._id.$oid
      )
        .then((res) => setOriginalVersion(res))
        .catch(showError)
        .finally(() => {
          setLoading(false)
          onHide()
        })
    )
    .catch((err) => showError(err || 'Something went wrong!'))
}

export {getForecast, changeForecastType, changeForecastTypeFromSettings}
