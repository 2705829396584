import {Calendar} from '../../../../app/modules/calendars/_models'
import {
  PeriodCount,
  CurveSettings,
  CustomCurve,
  BudgetAtCompletion,
} from './../../../../app/modules/simulations/core/_models'
import * as Yup from 'yup'
export interface IAppBasic {
  appName: string
  appType: 'Quick Online Courses' | 'Face to Face Discussions' | 'Full Intro Training'
}

export type TAppFramework = 'HTML5' | 'ReactJS' | 'Angular' | 'Vue'

export interface IAppDatabase {
  databaseName: string
  databaseSolution: 'MySQL' | 'Firebase' | 'DynamoDB'
}

export type TAppStorage = 'Basic Server' | 'AWS' | 'Google'
export interface Reserve {
  amount: number
  unit: string
}
export interface Annotations {
  name: string
  type: string
  value: string
}

export interface ICreateAppData {
  name: string
  reference_number: string
  description: string
  date_format: string
  date_unit: string
  display_type: string[]
  data_date: string
  float_formatter: number | undefined
  budget_at_completion?: BudgetAtCompletion
  curve_settings?: CurveSettings
  custom_curve?: CustomCurve[]
  period_count?: PeriodCount
  reserve?: Reserve
  annotations?: Annotations[]
  delay_tolerance?: Reserve
  action_plan?: any
  status?: string
  updated_at?: any
  unit?: string
  achieved_percentage?: number
  currency?: string | {label: string; value: string}
  budgetAtCompletion?: number
  budgetReserve?: number
  delayTolerance?: number
  savedSimulation?: any
  unitBudgetReserve?: string
  unitDelayTolerance?: string
  imageProject?: File
  cumulativePlannedValue?: any
  cumulativeEarnedValue?: any
  cumulativeActualCost?: any
  worstCaseBaseline?: any
  pic?: any
  associated_calendar: Calendar | undefined
  parent_folder: string
  sector: string
  custom_sector: string
}

export const defaultCreateAppData: ICreateAppData = {
  name: '',
  reference_number: '',
  description: '',
  date_format: 'mm/dd/yyyy',
  date_unit: 'monthly',
  display_type: ['name'],
  data_date: '',
  float_formatter: 2,
  unit: 'thousand',
  currency: {label: 'US dollars (USD)', value: 'USD'},
  budgetAtCompletion: 1000,
  budgetReserve: 0,
  delayTolerance: 0,
  action_plan: '',
  status: '',
  updated_at: '',
  achieved_percentage: 0,
  savedSimulation: {},
  unitBudgetReserve: '',
  unitDelayTolerance: '',
  cumulativeActualCost: null,
  cumulativePlannedValue: [],
  cumulativeEarnedValue: null,
  worstCaseBaseline: null,
  pic: null,
  associated_calendar: undefined,
  parent_folder: 'root',
  sector: 'Construction (civil -industrial -institutional)',
  custom_sector: '',
}
export const initialTable = [
  [/*'Date', */ '–', '–', '–', '–', '–', '–', '–', '–', '–'],
  [
    // "Pourcentage d'avancement cumulé",
    '–',
    '–',
    '–',
    '–',
    '–',
    '–',
    '–',
    '–',
    '–',
  ],
  [
    // "Pourcentage d'avancement par mois",
    '–',
    '–',
    '–',
    '–',
    '–',
    '–',
    '–',
    '–',
    '–',
  ],
]

export const createProjectSchema = Yup.object().shape({
  name: Yup.string().required('name is required'),
  reference_number: Yup.string()
    .matches(/^[^\s]+$/, 'Reference number cannot contain spaces')
    .required('Reference number is required'),
  date_format: Yup.string().required('date format is required'),
  date_unit: Yup.string().required('date unit is required'),
  data_date: Yup.date().required('data date is required'),
  sector: Yup.string().required('Sector is required'),
})

export type StepProps = {
  formik: any
  setCumulativePlannedValue?: any
  setCumulativeEarnedValue?: any
  setCumulativeActualCost?: any
  setProfilPhoto?: (value: any) => void
  outputs?: any
  cumulativePlannedValue?: any
  currentStep?: number
}
