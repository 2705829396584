import clsx from 'clsx'
import {useFormik} from 'formik'
import _ from 'lodash'
import {Modal} from 'react-bootstrap'
import * as Yup from 'yup'
import UploadField from '../../../../components/uploadField'
import {downloadCustomCurveXlsxTemplate} from '../../../../helpers/func'
import {greaterThanOrEqual} from '../../../projects/core/ProjectHelpers'
import {CustomCurve} from '../../core/_models'
import {useSimulationContext} from '../SimulationContext'
import {getLabels} from '../modals/CustomCurveHelpers'

type Props = {
  sigmoid: boolean
  show: CustomCurve | undefined
  onHide: () => void
  showParent: () => void
  timeUnit: string
  outputs: any
}
const RECORD_TO_IGNORE = 'IGNORE_THIS_RECORD_6DBE8ADAD7F9'

export default function UpdateCustomCurveModal({
  show,
  onHide,
  showParent,
  timeUnit,
  outputs,
  sigmoid,
}: Props) {
  const {formik: globalFormik} = useSimulationContext()
  const formik = useFormik({
    initialValues: {
      name: show?.name || '',
      curve: show?.values || [],
      color: show?.color || '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Setting name is required'),
      curve: Yup.array()
        .test('numberOrNull', 'Values should be numbers', (values) => {
          let error: boolean = false
          values?.forEach((element) => {
            if (element !== null && isNaN(element)) error = true
          })
          return !error
        })
        .min(2)
        .test('cumulative', 'Values should be cumulative', (values) => {
          return values?.filter((item) => item !== null).every(greaterThanOrEqual) || false
        }),
    }),
    onSubmit: (values) => {
      let custom_curve = globalFormik?.values.custom_curve || []
      custom_curve = custom_curve.filter((item) => !_.isEqual(item, show))
      custom_curve = custom_curve?.filter((item) => item.name !== RECORD_TO_IGNORE)
      custom_curve.push({
        name: values.name,
        color: values.color,
        values: values.curve,
      })
      globalFormik?.setFieldValue('custom_curve', custom_curve)
      handleHide()
    },
  })
  const handleHide = () => {
    onHide()
    showParent()
  }
  const handleDownloadTemplate = () => {
    let downloadData
    let labels = getLabels(timeUnit, outputs, sigmoid)
    if (labels[0] === '–') labels = Array.from({length: 17}, () => '–')

    downloadData = Object.fromEntries(
      labels.map((el: string, idx: number) => [el === '–' ? `Column ${idx + 1}` : el, ''])
    )
    downloadCustomCurveXlsxTemplate(downloadData)
  }
  return (
    <Modal centered show={!!show} onHide={handleHide}>
      <Modal.Header>
        <Modal.Title>Update Custom Curve</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
            <span className='required'>Name</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Specify your unique setting name'
            ></i>
          </label>
          <input
            type='text'
            {...formik.getFieldProps('name')}
            placeholder='Enter a name for the custom curve'
            className={clsx(
              'form-control ',
              {'is-invalid': formik.touched.name && formik.errors.name},
              {
                'is-valid': formik.touched.name && !formik.errors.name,
              }
            )}
          />
          {formik.touched.name && formik.errors.name && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.name}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-10'>
          <label className='fs-6 fw-bolder text-dark mb-7d-flex align-items-center fs-5 fw-semibold mb-4'>
            <span className='required'>Values</span>
            <div className='text-muted fw-semibold '>
              you can download the generated XLSX file
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href='#' type='button' className='link-primary' onClick={handleDownloadTemplate}>
                {' '}
                Download Template
              </a>
            </div>
          </label>
          <UploadField
            index={0}
            durationUnit=''
            setFileContent={(value: any) => {
              formik.setFieldTouched('curve', true)
              formik.setFieldValue('curve', value)
            }}
            // disabled={!isEmpty(formik?.values.savedSimulation)}
          />
        </div>
        {formik.errors.curve && formik.touched.curve && (
          <div className='row mt-2'>
            <h4 className='text-danger'>{formik.errors.curve.toString()}</h4>
          </div>
        )}
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
            <span className='required'>Color</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Color of your custom curve'
            ></i>
          </label>
          <div className='d-flex gap-5 align-items-center'>
            <label htmlFor='color' className=''>
              <i className='bi bi-eyedropper fs-1 cursor-pointer'></i>
            </label>
            <label
              htmlFor='color'
              className='rounded cursor-pointer'
              style={{width: '30px', height: '30px', backgroundColor: formik.values.color}}
            ></label>
            <p className='fs-5 m-0'>{formik.values.color}</p>
          </div>
          <input
            hidden
            id='color'
            type='color'
            {...formik.getFieldProps('color')}
            placeholder='Enter a name for the custom curve'
          />
        </div>
      </Modal.Body>
      <Modal.Footer className='justify-content-center'>
        <button
          onClick={formik.submitForm}
          disabled={!formik.isValid || !formik.dirty}
          className='btn btn-primary'
          style={{width: '60%'}}
        >
          Update
        </button>
      </Modal.Footer>
    </Modal>
  )
}
