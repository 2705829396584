/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import { CreateActionPlan } from './CreateActionPlan'
import { ActionPlanTable } from './ActionPlanTable'
import EmptyValuesCard from '../../../../components/emptyValuesCard'
import { useProject } from '../../core/ProjectContext'
import { IActionPlan, Status } from './IActionPlan'
import MultiOptionFilter from '../../../../lib/filters/MultiOptionFilter'
import ExportCustomData from '../../../../lib/export/ExportCustomData'
import SearchBar from '../../../../components/SearchBar/SearchBar'
import { useLocation } from 'react-router-dom'
import { ActionPlan as ActionPlanModel } from '../../core/_models'
import { format } from 'date-fns'
import { getFNSDateFormat } from '../../../../helpers/DateFormatter'
import { getActionPlanbywbs, getActionPlans } from './requests'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../store'
import { getWbs } from '../../../wbs/_requests'
import { addWbsToStore } from '../../../wbs/treedata'

type Props = {
  showActions?: boolean
  isShared?: boolean;
}
export function ActionPlan({ showActions, isShared }: Props) {
  const [filter, setFilter] = useState<string[]>([])
  const { originalVersion: project, project: projectObject } = useProject()
  const [showModal, setShowModal] = useState(false)
  const { state } = useLocation()
  const [searchKeyword, setSearchKeyword] = useState<string>('')
  const [comment, setComment] = useState<any>()
  const [actionplans, setActionplans] = useState<IActionPlan[]>()
  const currentwbs = useSelector((state: RootState) => state.currentwbs.value)
  const added = useSelector((state: RootState) => state.added.value)
  const knownWbs = useSelector((state: RootState) => state.treedata.knownWbs);

  const dispatch = useDispatch();

  useEffect(() => {
    if ((state as any)?.comment) setComment((state as any).comment)
    window.history.replaceState({}, document.title)
  }, [state])

  useEffect(() => {
    const getdata = async () => {
      let currentWbs = knownWbs[projectObject?.current_wbs]
      if (!knownWbs[projectObject?.current_wbs]) {
        currentWbs = await getWbs(projectObject?.current_wbs)
        dispatch(addWbsToStore(currentWbs))
      }
      const last_opened_wbs = currentWbs?.last_opened_wbs?.$oid ?
        currentWbs?.last_opened_wbs?.$oid
        : projectObject?.current_wbs
      if (last_opened_wbs) {
        const data = await getActionPlanbywbs(last_opened_wbs)
        setActionplans(data)
      }
    }

    getdata()
  }, [added, projectObject?.current_wbs])
  const getExportData = () =>
    actionplans?.map((item: IActionPlan) => {
      const parseDate = (date: any) => {
        if (!date) return '-';
        try {
          const dateObj = new Date(date);
          if (isNaN(dateObj.getTime())) {
            // Handle invalid date
            return '-';
          }
          return format(dateObj, getFNSDateFormat(dateObj.toISOString()));
        } catch {
          return '-';
        }
      };

      return {
        'N°': item?.subject,
        Action: item.action,
        Responsible: item.responsible,
        'Start Date': parseDate(item.start_date),
        Deadline: parseDate(item.deadline),
        'Report Date': parseDate(item.report_date),
        'Close Date': parseDate(item.close_date),
        Status: item.status,
      };
    });


  if (!project) return null
  return (
    <>
      {(project.action_plan.length > 0 && !!Object.keys(project.action_plan[0]).length || !isShared) && (
        <>
          <h2>Action Plan</h2>
          <div className='d-flex flex-wrap flex-stack pb-7'>
            <div className='d-flex flex-wrap align-items-center my-1 gap-3'>
              <SearchBar
                placeholder='Search Action N°'
                setKeyword={setSearchKeyword}
                keyword={searchKeyword}
              />
              {comment && (
                <div className='badge badge-primary d-flex gap-2'>
                  {comment?.subject}
                  <button className='btn p-0' onClick={() => setComment('')}>
                    <i className='bi bi-x text-white p-1 fs-3'></i>
                  </button>
                </div>
              )}
            </div>
            <div className='d-flex flex-wrap my-1 gap-2'>
              <ExportCustomData name={`action_plan_${projectObject?.name}`} data={getExportData()} />
              <MultiOptionFilter
                filter={filter}
                setFilter={setFilter}
                filters={[
                  ...Object.keys(Status).map((item) => ({
                    label: item?.toLowerCase(),
                    value: Status[item as keyof typeof Status],
                  })),
                ]}
              />
              {!isShared && (<div className='d-flex my-0'>
                <a
                  href='#'
                  className='btn btn-sm btn-primary me-3'
                  onClick={() => {
                    setShowModal(true)
                  }}
                >
                  Add Action
                </a>
              </div>)}
            </div>
          </div>
          {!actionplans ? (
            <div className='justify-content-center'>
              <p className="placeholder-glow">
                <span className="placeholder col-12"></span>
              </p>
              <p className="placeholder-glow">
                <span className="placeholder col-12"></span>
              </p>
              <p className="placeholder-glow">
                <span className="placeholder col-12"></span>
              </p>
            </div>
          ) : actionplans.length > 0 ? (
            <div className='tab-content'>
              <ActionPlanTable
                keyword={searchKeyword}
                comment={comment}
                className={''}
                actionplans={actionplans}
                project={project}
                statusFilter={filter}
                showActions={showActions}
              />
            </div>
          ) : (
            <EmptyValuesCard
              title='No Action Plans'
              text='Add An Action Plan'
              buttonText='Add Action Plan'
              setShowCreateAppModal={setShowModal}
            />
          )}
          <CreateActionPlan
            project={project}
            handleClose={() => {
              setShowModal(false)
            }}
            show={showModal}
          />
        </>)}
    </>
  )
}

