/* eslint-disable jsx-a11y/anchor-is-valid */
import { DatePicker } from 'antd'
import dayjs from 'dayjs'
import { StepProps } from '../IProjectModels'
import { getDateFormat } from '../../../../../app/modules/simulations/core/SimulationHelpers'
import TreeDataView from '../../../../../app/modules/wbs_structure/components/TreeView/TreeDataView'

const Step6 = ({ formik }: StepProps) => {
  const { RangePicker } = DatePicker
  return (
    <>
      <div data-kt-stepper-element='content'>
        <div className='w-100'>
          {/* begin::Heading */}
          <h1 className='fw-bold text-dark mb-3 text-center'>Release!</h1>
          {/* end::Heading */}

          {/* begin::Description */}
          <div className='text-muted fw-semibold fs-3 text-center mb-10'>
            Review your project before submitting.
          </div>
          {/* end::Description */}
          {/* begin::Illustration */}
          <div className=''>
            <div className='row my-5 align-items-center'>
              <label htmlFor='' className='col-4 fw-bolder text-muted fs-6'>
                Project Name
              </label>
              <div className='col-8 fw-bold text-dark'>{formik.values.name}</div>
            </div>

            <div className='row my-5 align-items-center'>
              <label htmlFor='' className='col-4 fw-bolder text-muted fs-6'>
                Description
              </label>
              <div className='col-8 fw-bold text-dark'>{formik.values.description}</div>
            </div>

            <div className='row my-5 align-items-center'>
              <label htmlFor='' className='col-4 fw-bolder text-muted fs-6'>
                Reference
              </label>
              <div className='col-8 fw-bold text-dark'>{formik.values.reference_number}</div>
            </div>

            <div className='row my-5 align-items-center'>
              <label htmlFor='' className='col-4 fw-bolder text-muted fs-6'>
                Date Unit
              </label>
              <div className='col-8 fw-bold text-dark'>
                {formik.values.date_unit === 'monthly'
                  ? 'month'
                  : formik.values.date_unit === 'daily'
                    ? 'day'
                    : 'year'}
              </div>
            </div>

            <div className='row my-5 align-items-center'>
              <label htmlFor='' className='col-4 fw-bolder text-muted fs-6'>
                Date Format
              </label>
              <div className='col-8 fw-bold text-dark'>{formik.values.date_format}</div>
            </div>

            <div className='row my-5 align-items-center'>
              <label htmlFor='' className='col-4 fw-bolder text-muted fs-6'>
                Currency
              </label>
              <div className='col-8 fw-bold text-dark'>{formik.values.currency.label}</div>
            </div>

            <div className='row my-5 align-items-center'>
              <label htmlFor='' className='col-4 fw-bolder text-muted fs-6'>
                Unit
              </label>
              <div className='col-8 fw-bold text-dark'>{formik.values.unit}</div>
            </div>

            <div className='row my-5 align-items-center'>
              <label htmlFor='' className='col-4 fw-bolder text-muted fs-6'>
                Float Formatter
              </label>
              <div className='col-8 fw-bold text-dark'>{formik.values.float_formatter}</div>
            </div>

            <div className='row my-5 align-items-center'>
              <label htmlFor='' className='col-4 fw-bolder text-muted fs-6'>
                WBS Display Type
              </label>
              <div className='col-8 fw-bold text-dark'>
                {formik.values.display_type === 'name'
                  ? 'Name'
                  : formik.values.display_type === 'index'
                    ? 'Index'
                    : 'Name and Index'}</div>
            </div>

            <div className='row my-5 align-items-start'>
              <label htmlFor='' className='col-4 fw-bolder text-muted fs-6 mt-5'>
                WBS Tree
              </label>
              <div className='col-8 fw-bold text-dark'>
                <TreeDataView />
              </div>
            </div>

          </div>
          {/* end::Illustration */}
        </div>
      </div>
    </>
  )
}

export { Step6 }
