import { useFormik } from 'formik'
import { KTSVG } from '../../../../_metronic/helpers'
import { createWbs } from '../_requests'
import * as Yup from 'yup'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { showError } from '../../../utils/funcs'

type Props = { setNewFolder: Dispatch<SetStateAction<boolean>>; parentId?: string; refetch: any }

export default function CreateFolderItem({ parentId, setNewFolder, refetch }: Props) {
  const [loading, setLoading] = useState<boolean>(false)
  const inputRef = useRef(null)
  const formik = useFormik({
    initialValues: { name: '' },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required('Name is required')
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols'),
    }),
    onSubmit: (values) => {
      setLoading(true)
      createWbs({
        name: values.name,
        parent_wbs: parentId,
      })
        .then(() => {
          refetch()
          setNewFolder(false)
        })
        .catch(showError)
        .finally(() => setLoading(false))
    },
  })
  useEffect(() => {
    const node = inputRef.current
    if (node) (node as any).focus()
  }, [])

  return (
    <form onSubmit={formik.handleSubmit} className='col-md-4 col-xl-3'>
      <div
        style={{ height: '200px' }}
        className='card border border-2 border-gray-300 border-hover cursor-pointer d-flex justify-content-center align-items-center pt-10'
      >
        <div className='symbol symbol-50px w-50px bg-light'>
          <KTSVG
            path='/media/icons/duotune/files/fil012.svg'
            className='svg-icon-muted svg-icon-3hx text-primary'
          />
        </div>
        <input
          autoComplete='off'
          ref={inputRef}
          placeholder='New Wbs'
          id='name'
          {...formik.getFieldProps('name')}
          className='form-control mt-3 py-1 px-2 border  w-75'
        />
        <div className='me-3 my-3' style={{ justifySelf: 'flex-end', alignSelf: 'flex-end' }}>
          <button disabled={loading} type='submit' className='btn btn-primary btn-icon btn-sm'>
            {loading ? (
              <span className='indicator-progress' style={{ display: 'block' }}>
                <span className='spinner-border spinner-border-sm align-middle'></span>
              </span>
            ) : (
              <KTSVG
                className='svg-icon-muted svg-icon-3'
                path='/media/icons/duotune/arrows/arr012.svg'
              />
            )}
          </button>
        </div>
      </div>
    </form>
  )
}
