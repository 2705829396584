import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../store';

interface TreeLoader {
  label: string;
  show: boolean;
  loaded: number;
  total: number;
}

const initialTreeLoader: TreeLoader = {
  label: 'Loading...',
  show: false,
  loaded: 0,
  total: 0,
};

const treeLoader = createSlice({
  name: 'treeLoader',
  initialState: initialTreeLoader,
  reducers: {
    updateLabel: (state, action: PayloadAction<string>) => {
      state.label = action.payload;
    },
    updateShow: (state, action: PayloadAction<boolean>) => {
      state.show = action.payload;
    },
    updateLoaded: (state, action: PayloadAction<number>) => {
      state.loaded = action.payload;
    },
    updateTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    incrementLoaded: (state) => {
      state.loaded += 1;
    },
    incrementTotal: (state) => {
      state.total += 1;
    },
  },
});

export const {
  updateLabel,
  updateShow,
  updateLoaded,
  updateTotal,
  incrementLoaded,
  incrementTotal,
} = treeLoader.actions;

// Default delay of 300 milliseconds for all thunk actions
const DEFAULT_DELAY = 300;

// Thunk actions with default delay
export const updateLabelWithDelay = (label: string, delay: number = DEFAULT_DELAY): AppThunk => async (dispatch) => {
  await new Promise(resolve => setTimeout(resolve, delay));
  dispatch(updateLabel(label));
};

export const updateShowWithDelay = (show: boolean, delay: number = DEFAULT_DELAY): AppThunk => async (dispatch) => {
  await new Promise(resolve => setTimeout(resolve, delay));
  dispatch(updateShow(show));
};

export const updateLoadedWithDelay = (loaded: number, delay: number = DEFAULT_DELAY): AppThunk => async (dispatch) => {
  await new Promise(resolve => setTimeout(resolve, delay));
  dispatch(updateLoaded(loaded));
};

export const updateTotalWithDelay = (total: number, delay: number = DEFAULT_DELAY): AppThunk => async (dispatch) => {
  await new Promise(resolve => setTimeout(resolve, delay));
  dispatch(updateTotal(total));
};

export const incrementLoadedWithDelay = (delay: number = DEFAULT_DELAY): AppThunk => async (dispatch) => {
  await new Promise(resolve => setTimeout(resolve, delay));
  dispatch(incrementLoaded());
};

export const incrementTotalWithDelay = (delay: number = DEFAULT_DELAY): AppThunk => async (dispatch) => {
  await new Promise(resolve => setTimeout(resolve, delay));
  dispatch(incrementTotal());
};

export default {
  updateTreeLoader: treeLoader.reducer,
};
