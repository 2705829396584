import {getSubscriptionStatus} from '../../../../helpers/func'
import {CurrentSubscriptionModel, SubscriptionSlot, UserModel} from '../../../auth'
import {Price, Product, ProductType, Range} from './_models'
import moment from 'moment'

//month and year in milliseconds
const thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000
const oneYearInMs = thirtyDaysInMs * 12

export const formatDate = (timestamp: number | undefined): string => {
  if (!timestamp) return ''
  const date = new Date(timestamp * 1000)
  return `${date.toLocaleDateString('en-us', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  })}`
}

export const getProjectCount = (subscription: CurrentSubscriptionModel | undefined, is_free_plan: boolean = true) => {
  if (is_free_plan) return 3
  if (!subscription) return 0
  if (!subscription.slots || !subscription.slots[0]) return 0
  return (subscription.slots[0] as SubscriptionSlot).limit
}

export const getSimulationCount = (subscription: CurrentSubscriptionModel | undefined) => {
  if (!subscription) return 0
  if (!subscription.slots || !subscription.slots[1]) return 0
  return (subscription.slots[1] as SubscriptionSlot).limit
}
export const getUserCount = (subscription: CurrentSubscriptionModel | undefined) => {
  if (!subscription) return 0
  if (!subscription.slots || !subscription.slots[2]) return 0
  return (subscription.slots[2] as SubscriptionSlot).limit
}
export const getUsedUsers = (subscription: CurrentSubscriptionModel | undefined) => {
  if (!subscription) return 0
  if (!subscription.slots || !subscription.slots[2]) return 0
  return (subscription.slots[2] as SubscriptionSlot).usage
}

export const getUsedProjects = (subscription: CurrentSubscriptionModel | undefined, user: UserModel | undefined) => {
  if(user?.is_free_plan){
    return 3 - user.slots!
  }else{
    if (!subscription) return 0
    if (!subscription.slots || !subscription.slots[0]) return 0
    return (subscription.slots[0] as SubscriptionSlot).usage
  }
}

export const getUsedSimulations = (subscription: CurrentSubscriptionModel | undefined) => {
  if (!subscription) return 0
  if (!subscription.slots || !subscription.slots[1]) return 0
  return (subscription.slots[1] as SubscriptionSlot).usage
}

export const checkTimePassed = (timestamp: number, timeRange: Range): boolean => {
  const invoiceDate = new Date(timestamp * 1000)
  const currentDate = new Date()
  const timeDiff = currentDate.getTime() - invoiceDate.getTime()
  if (timeRange === Range.MONTH) return timeDiff <= thirtyDaysInMs
  else if (timeRange === Range.YEAR) return timeDiff <= oneYearInMs
  return true
}

export const filterProducts = (products: Product[], type: ProductType): Product[] => {
  if (type === ProductType.SLOT)
    return products.filter((product) => product.prices[0].type === 'one_time')
  if (type === ProductType.SUB)
    return products.filter((product) => product.prices[0].type === 'recurring')
  return []
}
export const getLowestPrice = (product: Product) => {
  return product.prices.reduce((acc, current) => {
    return acc.amount < current.amount ? acc : current
  }).amount
}
export const capitalizeFirstLetter = (text: string): string => {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

export const isSlot = (price: Price | undefined): boolean => {
  if (!price) return false
  return price.type === 'one_time'
}

export const getUserSlot = (subscription: CurrentSubscriptionModel | undefined) => {
  if (!subscription)
    return {
      usage: 0,
    }
  return subscription.slots.find((value) => value.key === 'slot-user')
}

export const isDowngrading = (
  userSubscription: CurrentSubscriptionModel | undefined,
  price: Price | undefined
): boolean => {
  if (!userSubscription || !price) return false
  if (userSubscription.price_id === price.price_id) return false
  if (userSubscription.new_amount_due > price.amount) return true
  return false
}

export const isDowngradingFromEnterprise = (
  userSubscription: CurrentSubscriptionModel | undefined,
  product: Product | undefined
): boolean => {
  if (!userSubscription || !product) return false
  return userSubscription.product_name === 'Enterprise' && product.name === 'Professional'
}

export const isCurrentSubscription = (
  userSubscription: CurrentSubscriptionModel | undefined,
  price: Price | undefined
) => {
  if (!userSubscription || !price) return false
  return userSubscription.price_id === price.price_id
}

export const getSubscriptionMessage = (
  currentUser: UserModel | undefined,
  styles: any,
  handleUpgrade: () => void
) => {
  const subscriptionStatus = getSubscriptionStatus(currentUser)

  const remainingDays = currentUser?.current_subscription
    ? moment(moment(new Date(currentUser.current_subscription.period_end * 1000))).diff(
        new Date(),
        'days'
      ) + 1
    : null

  switch (subscriptionStatus) {
    case 'Free Plan':
        return [styles.freePlan, `You are using the free plan`, 'Upgrade', handleUpgrade]
    case 'Trial mode':
      return [styles.trialMode, `Expires in ${remainingDays} days`, 'Upgrade', handleUpgrade]
    case 'Trial mode ended with no active subscription':
      return [
        styles.trialModeEndedWithNoActiveSubscription,
        'Free trial expired',
        'Upgrade',
        handleUpgrade,
      ]
    case 'Subscription ended':
      return [styles.subscriptionEnded, 'Subscription expired', 'Renew', handleUpgrade]
    case 'Subscription is active but will expire in less than 14 days':
      return [
        styles.subscriptionIsActiveButWillExpireInLessThan14Days,
        `Expires in ${remainingDays} days`,
        'Enable auto-renewal',
        handleUpgrade,
      ]
    case 'Auto-renew deactivated':
      return [styles.autoRenewDeactivated, 'Auto-renewal disabled', 'Activate', handleUpgrade]
    default:
      return [styles.hidden, undefined, undefined, undefined]
  }
}

export const getOccuppiedFreeSlots = (user: UserModel | undefined) => {
  if(user?.is_free_plan){
    return user.slots!
  }
}