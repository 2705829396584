import * as Yup from 'yup'
import {ProjectObject} from '../../core/_models'
import dayjs from 'dayjs'

export type Milestone = {
  _id: {$oid: string}
  subject: string
  name: string
  achieved: any
  baseline_date: any
  real_dates: any
  forcast_date:any
  wbs:{$oid:string}
  shared_with_wbs:{$oid:string}[]
  hidden?: boolean
}
export enum achieved {
  NOT_ACHIEVED = 'no_achieved',
  NOT_ACHIEVED_FORCAST = 'no_achieved_forcast',
  ACHIEVED = 'achieved',
}
export const InitMilestone:Omit<Milestone, '_id'> ={
  subject: '',
  name: '',
  achieved: achieved.NOT_ACHIEVED,
  baseline_date: new Date(),
  real_dates: null,
  forcast_date:null,
  wbs:{$oid:""},
  shared_with_wbs:[]
}

export const getMilestoneValidationSchema = (project: ProjectObject) =>
  Yup.object().shape({
    id: Yup.string()
      .max(5, 'ID must be less or equal than 5 characters')
      .required('ID is required')
      .test(
        'unique',
        'ID must be unique',
        (value) => !project.milestones.find((item) => item.id === value)
      ),
    name: Yup.string().required('Name is required'),
    baseline_date: Yup.date()
      .required('Baseline date is required')
      .min(new Date(project.start_date.$date), 'Date must be after project start date')
      .max(new Date(project.end_date.$date), 'Date must be before project end date'),
    real_date: Yup.date()
      .test(
        'achieved_in_future',
        'Real date cannot be in the future for an achieved milestone',
        (value, context) => {
          if (!value) return true
          if (
            context.parent?.achieved &&
            dayjs(value).isAfter(dayjs(project.data_date.$date).endOf('month'))
          )
            return false
          return true
        }
      )
      .test(
        'not_achieved_in_past',
        'Real date cannot be in the past for a not achieved milestone',
        (value, context) => {
          if (!value) return true
          if (
            !context.parent?.achieved &&
            dayjs(value).isBefore(dayjs(project.data_date.$date).endOf('month'))
          )
            return false
          return true
        }
      ),
  })

export const MilestoneHeaderList: any[] = [
  {
    Header: 'N°',
    width: '90',
    accessor: 'id',
  },
  {Header: 'Name', width: '200', accessor: 'name'},
  {
    Header: 'Baseline Date',
    width: '100',
    accessor: 'baseline_date',
    sortType: (a: any, b: any) => {
      return (
        new Date(b.original.baseline_date.$date).getTime() -
        new Date(a.original.baseline_date.$date).getTime()
      )
    },
  },
  {Header: 'Achieved', width: '100', accessor: 'achieved'},
  {
    Header: 'Real Date ',
    width: '100',
    accessor: 'real_date',
  },
  {
    Header: ' Forecast',
    width: '100',
    accessor: 'forcast_date',
  },
  {
    Header: 'Show/Hide',
    width: '40',
    accessor: 'hidden',
    disableSortBy: true,
  },
  {
    Header: 'Shared Wbs',
    width: '80',
    accessor: 'shared_with_wbs',
    disableSortBy: true,
  },
  {Header: '', width: '50', accessor: 'button', disableSortBy: true},
]

export const MilestoneValidationSchema = Yup.object().shape({
  subject: Yup.string().required("Comment subject is required"),
  name: Yup.string().required('Name is required'),
  baseline_date: Yup.string().required('Baseline Date is required'),
  // wbs:Yup.string().required("Related Wbs is required")
})
