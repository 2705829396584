import axios, {AxiosResponse} from 'axios'
import * as authHelper from '../../../auth/core/AuthHelpers'
import {Invoice, Product} from './_models'
const API_URL = process.env.REACT_APP_API_URL
authHelper.setupAxios(axios)

export const GET_INVOICES = `${API_URL}/invoices`
export const GET_PRODUCTS = `${API_URL}/stripe/products`
export const CHECKOUT = `${API_URL}/stripe/checkout`
export const PORTAL = `${API_URL}/stripe/portal`
export const API_SUBSCRIPTION = `${API_URL}/subscriptions`
export const FREE_SUBSCRIPTION = `${API_URL}/free-subscription`

const getInvoices = () => {
  return axios.get<Invoice[]>(GET_INVOICES)
}

const getProducts = () => {
  return axios
    .get<Product[]>(GET_PRODUCTS)
    .then((response: AxiosResponse<Product[]>) => response.data)
}

const freeSubscription = (email: string) => {
  return axios.post(FREE_SUBSCRIPTION, {email})
  .then((response: AxiosResponse<any>) => response.data)

}

const checkout = (price_id: string, quantity: number = 1) => {
  if (quantity > 20) quantity = 20
  if (quantity < 1) quantity = 1
  return axios.post(CHECKOUT + '/' + price_id, {
    success_url: window.location.href,
    error_url: window.location.href,
    quantity,
  })
}

const goPortal = () => {
  return axios.post(PORTAL, {return_url: window.location.href})
}

const getCurrentSubscription = () => {
  return axios.get(API_SUBSCRIPTION).then((response: AxiosResponse) => response.data)
}
export {getInvoices, getProducts, checkout, goPortal, getCurrentSubscription, freeSubscription}
