import {useEffect, useRef} from 'react'
import {useProject} from '../../../core/ProjectContext'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../../../../_metronic/assets/ts/_utils'
import {getCustomCurve} from '../Project'
import {getDiffBetweenTwoDates} from '../../../../../utils/forecast'
import {formatNumber} from '../../../../../utils/formatter'
import {useThemeMode} from '../../../../../../_metronic/partials'

type Props = {}

export default function CostChart({}: Props) {
  const labelColor = getCSSVariableValue('--kt-dark')
  const warningColor = getCSSVariableValue('--kt-warning')
  const dangerColor = getCSSVariableValue('--kt-danger')
  const primaryColor = getCSSVariableValue('--kt-primary')
  const successColor = getCSSVariableValue('--kt-success')
  const colors = [primaryColor, warningColor, successColor]
  const {displayVersion} = useProject()

  const dataDateIndex = getDiffBetweenTwoDates(
    displayVersion?.start_date.$date || 0,
    displayVersion?.data_date.$date || 0,
    'month'
  )
  const plannedValue =
    ((getCustomCurve('cumulativePlannedValue', displayVersion)[dataDateIndex] ??
      getCustomCurve('cumulativePlannedValue', displayVersion).at(-1)) /
      (displayVersion?.budget_at_completion.amount || 1)) *
    100

  const earnedValue =
    (getCustomCurve('cumulativeEarnedValue', displayVersion)[dataDateIndex] /
      (displayVersion?.budget_at_completion.amount || 1)) *
    100

  const actualCost =
    (getCustomCurve('cumulativeActualCost', displayVersion)[dataDateIndex] /
      (displayVersion?.budget_at_completion.amount || 1)) *
    100

  const costRef = useRef(null)
  const getChartOptions: () => ApexOptions = () => ({
    series: [
      {
        name: 'Values at data date',
        data: [plannedValue, earnedValue, actualCost],
      },
    ],
    chart: {
      height: 350,
      type: 'bar',
      toolbar: {show: false},
    },
    colors: displayVersion?.colors?.s_cruve_colors || colors,
    plotOptions: {
      bar: {
        distributed: true,
      },
    },
    fill: {
      opacity: 1,
    },
    dataLabels: {enabled: false},
    legend: {show: false},
    xaxis: {
      categories: [
        `PV ${formatNumber(plannedValue, displayVersion?.float_formatter || 0, '.')}%`,
        `EV ${formatNumber(earnedValue, displayVersion?.float_formatter || 0, '.')}%`,
        `AC ${formatNumber(actualCost, displayVersion?.float_formatter || 0, '.')}%`,
      ],
      labels: {
        style: {colors: displayVersion?.colors?.s_cruve_colors || colors},
      },
    },
    yaxis: {
      labels: {
        style: {colors: labelColor},
        formatter: (val: number, opts) => val.toFixed(displayVersion?.float_formatter),
      },
      max: Math.max(plannedValue, earnedValue, actualCost, 100),
    },
    annotations: {
      yaxis: [
        {
          y: 100,
          borderColor: dangerColor,
          strokeDashArray: 0,
          label: {
            text: 'BAC',
            style: {background: dangerColor, color: '#fff'},
          },
        },
      ],
    },
  })

  const {mode} = useThemeMode()
  useEffect(() => {
    const chart = new ApexCharts(costRef.current, getChartOptions())
    chart.render()
    return () => chart.destroy()
  }, [displayVersion, mode])

  return <div ref={costRef}></div>
}
