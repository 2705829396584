import {Modal} from 'react-bootstrap'
import CommentForm from './CommentForm'

type Props = {
  show: boolean
  onClose: () => void
}

export default function CreateComment({show, onClose}: Props) {
  return (
    <Modal onHide={onClose} size='lg' show={show}>
      <Modal.Header closeButton={true}>
        <Modal.Title>Add Comment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CommentForm handleClose={onClose} />
      </Modal.Body>
    </Modal>
  )
}
