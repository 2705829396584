/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../modules/auth'
import {getTeam} from './_requests'

type Props = {
  className: string
  description: string
  icon: boolean
  stats?: number
  labelColor: string
  textColor: string
}
interface Member {
  member_name: string
}
const TeamCount = ({className, description, icon, stats, labelColor, textColor}: Props) => {
  const {currentUser} = useAuth()
  const [users, setUsers] = useState<Member[]>([])
  useEffect(() => {
    getTeam().then((res) => setUsers([...res.members, {member_name: currentUser?.full_name}]))
  }, [currentUser])
  return (
    <div className={`card card-flush ${className}`}>
      <div className='card-header pt-5'>
        <div className='card-title d-flex flex-column'>
          <div className='card-title d-flex flex-column'>
            <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>
              {stats || users.length}
            </span>
            <span className='text-gray-400 pt-1 fw-semibold fs-6'>{description}</span>
          </div>
        </div>
      </div>
      <div className='card-body d-flex flex-column justify-content-end pe-0'>
        <span className='fs-6 fw-bolder text-gray-800 d-block mb-2'>Today’s Heroes</span>
        <div className='symbol-group symbol-hover flex-nowrap'>
          {users.map((item, index) => (
            <div
              className='symbol symbol-35px symbol-circle'
              data-bs-toggle='tooltip'
              title={item.member_name}
              key={`cw7-item-${index}`}
            >
              <span className={clsx('symbol-label fw-bold')}>
                {item.member_name
                  ?.split(' ')
                  .reduce((acc, val) => acc.charAt(0) + val.charAt(0))
                  .toUpperCase()}
              </span>
            </div>
          ))}

          {users.length > 7 && (
            <Link to='/team/users' className='symbol symbol-35px symbol-circle'>
              <span
                className={clsx(
                  'symbol-label fs-8 fw-bold',
                  'bg-' + labelColor,
                  'text-' + textColor
                )}
              >
                +{users.length - 7}
              </span>
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}
export {TeamCount}
