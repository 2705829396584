export const resourceLegendLabels: string[] = [
  'Planned FTE',
  'Average Planned FTE',
  'Real FTE',
  'Average Real FTE',
  'FTE to complete',
  'Average FTE to complete',
  'Forecast FTE',
  'Average Forecast FTE',
]

export const resourceLabels: {id: string; dashed: boolean; line: boolean}[] = [
  {id: 'Planned FTE', dashed: false, line: false},
  {id: 'Average Planned FTE', dashed: false, line: true},
  {id: 'Real FTE', dashed: false, line: false},
  {id: 'Average Real FTE', dashed: false, line: true},
  {id: 'FTE To Complete', dashed: true, line: false},
  {id: 'Average To Complete FTE', dashed: true, line: true},
  {id: 'Forecast FTE', dashed: true, line: false},
  {id: 'Average Forecast FTE', dashed: true, line: true},
]

export const getResourceColors = (colors: string[]) => {
  const [firstColor, secondColor, thirdColor, fourthColor] = colors
  return [
    firstColor,
    firstColor,
    secondColor,
    secondColor,
    fourthColor,
    fourthColor,
    thirdColor,
    thirdColor,
  ]
}
