import {ErrorMessage, useFormik} from 'formik'
import {CommentValidationSchema, defaultComment} from './models'
import {useProject} from '../../core/ProjectContext'
import clsx from 'clsx'
import CustomSelect from '../../../../components/CustomSelect'
import {Children, useEffect, useState} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import {add, addDays, addMonths} from 'date-fns'
import {DateUnit} from '../pagination/header/ProjectDetailsHeader'
import {getFormattedDate} from '../../../../helpers/DateFormatter'
import {getNextCommentId} from '../action-plan/ActionPlanHelpers'
import {fetchComments, patchComment, transformToFolder} from './request'
import {sweetAlert} from '../../../../utils/funcs'
import { FaSquare, FaCheckSquare, FaMinusSquare } from "react-icons/fa";
import { IoMdArrowDropdown, IoMdArrowDropright } from "react-icons/io";
import TreeView, { flattenTree } from "react-accessible-treeview";
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../store'
import cx from "classnames";
import "./commentTreeStyles/Styles.css";
import { done,adding } from './CommentsState'
import { patchWbs } from '../../../wbs/_requests'
import { DatePicker, DatePickerProps } from 'antd'
import dayjs from 'dayjs'

type Props = {
  handleClose: () => void
}

export default function CommentForm({handleClose}: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const knownWbs = useSelector((state: RootState) => state.treedata.knownWbs);
  const [data, setData] = useState<any>();
  const added=useSelector((state:RootState)=>state.added.value)
  const [selectednodes,setSelectednodes]=useState<string[]>([])
  const [cleanedids,setCleanedids]=useState<string[]>()
  const currentwbs=useSelector((state:RootState)=>state.currentwbs.value)
  const dipatch=useDispatch()
  
  useEffect(() => {
    setData(knownWbs[project?.current_wbs]); 
  }, [knownWbs]); 

  

  const folder = transformToFolder(data,knownWbs)  ;
  const treedata =  flattenTree(folder) ;
  const { setProject, setOriginalVersion, project } = useProject();
  
  
  const formik = useFormik({
    initialValues: {...defaultComment},
    validationSchema: CommentValidationSchema,
    onSubmit: (values) => {
      setLoading(true);
      dipatch(adding())
      const commentdate=new Date()
      patchComment({subject:values.subject,comment:values.comment,commenter:values.commenter,wbs:currentwbs.toString(),shared_with_wbs:[currentwbs.toString(),...selectednodes],concerned_data_date:formik.values.concerned_data_date,comment_date:commentdate })
        .then((res: any) => {
          sweetAlert({
            text: 'Comment added successfully',
            icon: 'success',
            title: 'Success',
          });
          handleClose();
          formik.resetForm();
        })
        .catch((err: any) => 
          sweetAlert({
            text: err.response?.data?.message || 'Something went wrong',
            icon: 'error',
            title: 'Error',
          })
        )
        .finally(() => {
          setLoading(false)});
    },
  });

  // const getDataDates = () => {
  //   let dates: any[] = [];
  //   const unit: 'month' | 'day' = originalProject?.period_count.type === 'monthly' ? 'month' : 'day';
  //   let date = new Date(originalProject?.start_date.$date || 0);
  //   while (date.getTime() <= (originalProject?.end_date.$date as number)) {
  //     dates.push({
  //       label: getFormattedDate(
  //         date,
  //         project?.period_count?.type as DateUnit,
  //         project?.date_format
  //       ),
  //       value: date,
  //     });
  //     if (unit === 'month') date = addMonths(date, 1);
  //     else date = addDays(date, 1);
  //   }
  //   return dates;
  // };
  const onChangeStart: DatePickerProps['onChange'] = (date, dateString) =>{
    if (date) {
      const newDate =  date.toDate()    // Convert back to your data format if needed
      formik.setFieldValue('concerned_data_date', newDate);
    } else {
      formik.setFieldValue('concerned_data_date', null);
    }
}
 
  const handleNodeSelect = (node: any) => {
    const selectedIds = node.treeState.selectedIds;
    let idsArray:string[] = Array.from(selectedIds)
    setSelectednodes(idsArray);
  };

  return (
    <form className='form' onSubmit={formik.handleSubmit}>
      {/* Subject field */}
      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
          <span className='required'>Subject</span>
        </label>
        <input
          type='text'
          className={clsx(
            'form-control',
            {'is-invalid': formik.touched?.subject && formik.errors?.subject},
            {'is-valid': formik.touched?.subject && !formik.errors?.subject}
          )}
          {...formik.getFieldProps('subject')}
        />
      </div>

      {/* Commenter field */}
      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
          <span className='required'>Commenter</span>
        </label>
        <input
          type='text'
          className={clsx(
            'form-control',
            {'is-invalid': formik.touched.commenter && formik.errors.commenter},
            {'is-valid': formik.touched.commenter && !formik.errors.commenter}
          )}
          {...formik.getFieldProps('commenter')}
        />
      </div>

      {/* Comment field */}
      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
          <span className='required'>Comment</span>
        </label>
        <textarea
          className={clsx(
            'form-control',
            {'is-invalid': formik.touched.comment && formik.errors.comment},
            {'is-valid': formik.touched.comment && !formik.errors.comment}
          )}
          {...formik.getFieldProps('comment')}
        />
      </div>
      <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span className='required'>Concerned Data Date</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title=''
              ></i>
            </label>
            <DatePicker
              className={clsx(
                'form-control ',
                {'is-invalid': formik.touched.concerned_data_date && formik.errors.concerned_data_date},
                {
                  'is-valid': formik.touched.concerned_data_date && !formik.errors.concerned_data_date,
                }
              )}
              {...formik.getFieldProps('concerned_data_date')}
              value={formik.values.concerned_data_date ? dayjs(formik.values.concerned_data_date) : null}
              onChange={onChangeStart}
              allowClear={false}
              suffixIcon={null}
            />
            {formik.touched.concerned_data_date && formik.errors.concerned_data_date && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{String(formik.errors.concerned_data_date)}</span>
                </div>
              </div>
            )}
            </div>
      {/* Related WBS field */}
      <div className='fv-row '>
        <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
          <span className='required'>Related WBS</span>
        </label>
        <div className='checkbox'>
        <TreeView
          data={treedata}
          aria-label='Single select'
          multiSelect={true}
          propagateSelectUpwards={false}
          propagateSelect={true}
          propagateCollapse={false}
          togglableSelect={true}
          defaultDisabledIds={[currentwbs]}
          nodeAction='check'
          onSelect={(node) => handleNodeSelect(node)}
          nodeRenderer={({
            element,
            isBranch,
            isExpanded,
            isSelected,
            isHalfSelected,
            isDisabled,
            getNodeProps,
            level,
            handleSelect,
            handleExpand,
          }) => (
            <div
              {...getNodeProps({ onClick: handleExpand })}
              style={{ marginLeft: 30 * (level - 1),
                opacity: isDisabled ? 0.3 : 1,
               }}
            >
              {isBranch && <ArrowIcon isOpen={isExpanded} />
              }
              <CheckBoxIcon
                onClick={(e: any) => {
                  handleSelect(e);
                  e.stopPropagation();
     
                }}
                
                variant={isHalfSelected ? 'some' : isSelected ? 'all' : 'none'}
              />
               {isBranch?
              <KTSVG className='svg-icon-primary svg-icon-2 me-2'
              path='/media/icons/duotune/graphs/git04.svg' />:
              <KTSVG
              path='/media/icons/duotune/files/fil003.svg'
              className='svg-icon-primary svg-icon-2 me-2'
            /> }
              <span className='name h5'>{element.name}</span>
            </div>
          )}
        />
        </div>
      </div>

      {/* Submit button */}
      <div className='d-flex flex-stack '>
        <button
          type='submit'
          className='btn btn-lg btn-primary'
          disabled={!formik.isValid || !formik.dirty || loading}
        >
          {!loading && (
            <span className='indicator-label'>
              Create
              <KTSVG
                path='/media/icons/duotune/arrows/arr064.svg'
                className='svg-icon-3 ms-2 me-0'
              />
            </span>
          )}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2' />
            </span>
          )}
        </button>
      </div>
    </form>
  )
}

const ArrowIcon = ({ isOpen, className }: { isOpen: boolean; className?: string }) => {
  const baseClass = "arrow";
  const classes = cx(
    baseClass,
    { [`${baseClass}--closed`]: !isOpen },
    { [`${baseClass}--open`]: isOpen },
    className
  );
      return <IoMdArrowDropright className={classes} />
};

interface CheckBoxIconProps {
  variant: 'all' | 'none' | 'some';
  [key: string]: any;
}

const CheckBoxIcon: React.FC<CheckBoxIconProps> = ({ variant,isDisabled, ...rest }) => {
  switch (variant) {
    case 'all':
      return <FaCheckSquare {...rest} />;
    case 'none':
      return <FaSquare {...rest} />;
    case 'some':
      return <FaMinusSquare {...rest} />;
    default:
      return null;
  }
};
