import clsx from 'clsx'
import {useState} from 'react'
import {Collapse} from 'react-bootstrap'
import {KTSVG} from '../../../../../_metronic/helpers'
import {useProject} from '../../core/ProjectContext'
import {ProjectDetailsTypes} from './Project'
import {ProjectWidget} from './ProjectWidget'
import GaugeParamsModal from './gauge/GaugeParamsModal/GaugeParamsModal'
import GaugeSection from './gauge/GaugeSection'
import { sameMonthAndYear } from '../../../../utils/data-transformarion/date-utils'

type Props = {
  isShared?: boolean;
}

export default function Summary({isShared}: Props) {
  const [summaryExpanded, setSummaryExpanded] = useState(true)
  const [showParams, setShowParams] = useState(false)
  const {displayVersion: data, project: projectData , isRebaseLined} = useProject()
  return (
    <>
      <div className='my-3 d-flex justify-content-between px-5'>
        <div className='d-inline-flex gap-2'>
          <span>
            <button
              onClick={() => setSummaryExpanded((prev) => !prev)}
              className='btn btn-light p-2 d-inline-flex align-items-center justify-content-center rotate active z-index-2'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr021.svg'
                className={clsx('svg-icon-muted svg-icon svg-icon-sm me-0', {
                  'rotate-270': !summaryExpanded,
                  'rotate-90': summaryExpanded,
                })}
              />
            </button>
          </span>
          <span className='card-label fw-bold fs-3'>Summary</span>
        </div>
        {!isShared && (
        <div className='d-flex align-items-center gap-2'>
          <i
            onClick={() => setShowParams(true)}
            className='bi bi-gear d-flex text-hover-primary justify-content-end cursor-pointer ms-2'
            onMouseOver={(e) => (e.currentTarget.style.opacity = '0.75')}
            onMouseOut={(e) => (e.currentTarget.style.opacity = '1')}
            style={{fontSize: '2rem', transition: 'all 0.2s ease-in-out'}}
          ></i>
          <GaugeParamsModal show={showParams} onHide={() => setShowParams(false)} />
        </div>
        )}
      </div>
      <Collapse in={summaryExpanded}>
        <div>
          <GaugeSection />
          <div className='row g-5 gy-2 g-xl-8 gy-xl-2 pt-5'>
            <div className='col-xl-4'>
              <ProjectWidget
                data={data}
                widgetTitle={'Actual Project Data'}
                isRebaseLined={ isRebaseLined ? sameMonthAndYear(data?.data_date?.$date!, data?.rebase_line[data?.rebase_line?.length - 1]?.data_date!):false}
                projectData={projectData}
                widgetType={ProjectDetailsTypes.ACTUAL_PROJECT_DATA}
                className='card-xl-stretch mb-xl-8'
                color='primary'
                collapsed={false}
                toggleCollapse={() => {}}
              />
            </div>
            <div className='col-xl-4'>
              <ProjectWidget
                data={data}
                projectData={projectData}
                widgetType={ProjectDetailsTypes.COST_PERFORMENCE}
                widgetTitle={'Cost Performance'}
                className='card-xl-stretch mb-xl-8'
                color='warning-darker'
                collapsed={false}
                toggleCollapse={() => {}}
              />
            </div>
            <div className='col-xl-4'>
              <ProjectWidget
                data={data}
                widgetTitle={'Schedule Performance'}
                projectData={projectData}
                widgetType={ProjectDetailsTypes.SCHEDULE_PERFORMANCE}
                className='card-xl-stretch mb-5 mb-xl-8'
                color='info'
                isRebaseLined={ isRebaseLined? sameMonthAndYear(data?.data_date?.$date!, data?.rebase_line[data?.rebase_line?.length - 1]?.data_date!):false}
                collapsed={false}
                toggleCollapse={() => {}}
              />
            </div>
          </div>
        </div>
      </Collapse>
    </>
  )
}
