import clsx from 'clsx'
import {useLayout} from '../../../_metronic/layout/core'
import {KTSVG} from '../../../_metronic/helpers'
import {MultiOptionDropdown} from '../../../_metronic/partials/content/dropdown/MultiOptionDropdown'
type Props = {
  filter: string[]
  setFilter: any
  filters: any[]
  title?: string
}

export default function MultiOptionFilter({filter, setFilter, filters, title = 'Status'}: Props) {
  const {config} = useLayout()
  const daterangepickerButtonClass = config.app?.toolbar?.fixed?.desktop
    ? 'btn-light'
    : 'bg-body btn-color-gray-700 btn-active-color-primary'

  return (
    <div className='m-0'>
      <a
        href='#'
        className={clsx('btn btn-sm btn-flex fw-bold', daterangepickerButtonClass)}
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG
          path='/media/icons/duotune/general/gen031.svg'
          className={`svg-icon-6 svg-icon-muted me-1 ${filter.length && 'text-success'}`}
        />
        Filter
      </a>
      <MultiOptionDropdown title={title} filters={filters} filter={filter} setFilter={setFilter} />
    </div>
  )
}
