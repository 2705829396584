/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useRef, useMemo, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { ICreateAppData, createProjectSchema, defaultCreateAppData, initialTable } from './IProjectModels'
import { StepperComponent } from '../../../assets/ts/components'
import { KTSVG } from '../../../helpers'
import { Step1 } from './steps/Step1'
import { Step2 } from './steps/Step2'
import { Step3 } from './steps/Step3'
import { Step4 } from './steps/Step4'
import { useFormik } from 'formik'
import { Step5 } from './steps/Step5'
import {
  addPhotoProject,
  createProject,
  createVersion,
  deleteProject,
  deleteVersion,
  getVersionById,
  patchProject,
  setDefaultVersion,
} from '../../../../app/modules/projects/core/_requests'
import { useNavigate } from 'react-router-dom'
import { sweetAlert } from '../../../../app/utils/funcs'
import { useAuth } from '../../../../app/modules/auth'
import { promptReload } from '../../../../app/modules/projects/core/ProjectHelpers'
import { createWbs, deleteWbs, getWbs, patchWbs } from '../../../../app/modules/wbs/_requests'
import { useProject } from '../../../../app/modules/projects/core/ProjectContext'
import { useDispatch, useSelector } from 'react-redux'
import { TreeProvider } from '../../../../app/modules/wbs_structure/components/TreeView/TreeContext'
import { Step6 } from './steps/Step6'
import { DataNode } from 'antd/es/tree'
import { addWbsToStore, updateWbsCalculatedData } from '../../../../app/modules/wbs/treedata'
import { RootState } from '../../../../app/store'
import TreeWbsItem from '../../../../app/modules/wbs_structure/components/TreeView/TreeWbsItem'
import { combineVersionsV2 } from '../../../../app/modules/wbs/components/TreeView/_helpers'
import { updateLabel, updateLoaded, updateShow, updateTotal } from '../../../../app/modules/wbs/treeLoader'
import { setVersionData } from '../../../../app/modules/wbs/treeVersionData'
import { data } from '../../../../app/utils/constants'

type Props = {
  show: boolean
  handleClose: () => void
  parentFolder?: string
}
const modalsRoot = document.getElementById('root-modals') || document.body

const ProjectStepper = ({ show, handleClose, parentFolder }: Props) => {
  const navigate = useNavigate()
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const { currentUser } = useAuth()
  const [width, setWidth] = useState<number>(window.innerWidth)
  const [tempBoolean, setTempBoolean] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [isSubmitButton, setSubmitButton] = useState(false)
  const [initialValues, setInitialValues] = useState<ICreateAppData | null>(null)
  const [wbsData, setWbsData] = useState<any>(undefined)
  const [versionId, setVersionId] = useState<any>(undefined)
  const knownWbs = useSelector((state: RootState) => state.treedata.knownWbs)
  const projectversiondata = useSelector((state: RootState) => state.versiondata.data)
  const dispatch = useDispatch()
  const [currentproject, setCurrentproject] = useState<any>(undefined)
  const [outputs, setOutputs] = useState({
    isInitialState: true,
    nbOfRemainingMonths: '',
    estimatedEndDate: '',
    enteredEndDate: '',
    tableData: initialTable,
    isCalculatingRemainingTodo: false,
    highlightIdx: -2, // BCZ I AM ADDING 1 WHEN SHOWING IT
  })

  const [currentStep, setCurrentStep] = useState(1)
  const isMobile = useMemo(() => width <= 768, [width])
  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }

  const [isNextStepDisabled, setIsNextStepDisabled] = useState(false);

  const {
    setProject,
    setOriginalVersion,
    setVersion,
    setWbsDataDateFirstStepAsync,
    setWbsDataDateSecondStepAsync,
    project,
    originalVersion,
  } = useProject()
  useEffect(() => {
    console.log(project)
  }, [project])
  useEffect(() => {
    const initializeForm = async () => {
      const initialValues: ICreateAppData = {
        ...defaultCreateAppData,
        data_date: new Date(new Date().getFullYear(), new Date().getMonth()).toDateString(),
        date_format: currentUser?.date_format || 'dd/mm/yyyy',
      }
      setInitialValues(initialValues)
    }

    initializeForm()
  }, [currentUser])

  console.log(project)
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  useEffect(() => {
    const checkDataFieldInTree = async () => {
      if (currentStep === 5) {
        // Fetch the entire project tree
        const projectTree = await getNewProjectTree();

        // Flatten the tree to a list of nodes and check if there's at least one node with a data field
        const flattenTree = (nodes: any[]) => {
          return nodes.reduce((acc, node) => {
            acc.push(node);
            if (node.children) {
              acc = acc.concat(flattenTree(node.children));
            }
            return acc;
          }, []);
        };

        const flatNodes = flattenTree(projectTree);

        // Check if any node has the data field
        const hasDataField = flatNodes.some((node: { key: string | number }) => {
          const knownWbsNode = knownWbs[node.key];
          return knownWbsNode && knownWbsNode.data;
        });

        setIsNextStepDisabled(!hasDataField);
      } else {
        setIsNextStepDisabled(false)
      }
    };

    checkDataFieldInTree();
  }, [currentStep, knownWbs]);


  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }
  const clearAndClose = () => {
    sweetAlert({
      title: 'Are you sure?',
      text: 'Are you sure you want to leave?',
      icon: 'warning',
      confirmButtonText: 'Close',
      showCancelButton: true,
    }).then((res) => {
      if (!res.isConfirmed) return
      formik.resetForm()
      handleClose()
      currentproject !== undefined && deleteProject(currentproject._id.$oid)
      setCurrentproject(undefined)
      wbsData !== undefined && deleteWbs(wbsData._id.$oid)
      setWbsData(undefined)
      versionId !== undefined && deleteVersion(versionId._id.$oid)
      setVersionId(undefined)
    })
  }
  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    setSubmitButton(false)
    stepper.current.goPrev()
    setCurrentStep(stepper.current.currentStepIndex)
  }

  const submitStep = () => {
    if (!stepper.current) {
      return
    }
    setCurrentStep(stepper.current.currentStepIndex + 1)
    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      nextStep()
    } else {
      formik.handleSubmit()
    }
  }
  const formik = useFormik<ICreateAppData>({
    initialValues: {
      ...defaultCreateAppData,
      data_date: new Date(new Date().getFullYear(), new Date().getMonth()).toDateString(),
      date_format: currentUser?.date_format || 'dd/mm/yyyy',
    },
    validationSchema: createProjectSchema,
    onSubmit: async (values) => {
      if (stepper?.current?.currentStepIndex !== 6) return

      try {
        if (project)
          await patchProject({ is_create_completed: true }, project?._id.$oid)
        const wbsListData = await calculateCombinedVersionForEachWbs()
        dispatch(updateWbsCalculatedData(wbsListData));
        window.addEventListener('beforeunload', promptReload)
        handleClose()
        showAlert(`/project/${project?._id?.$oid}/overview`)
      } catch (error: any) {
        console.log(JSON.stringify(error.response.data.message))
        showError(error.response.data.message)
      } finally {
        window.removeEventListener('beforeunload', promptReload)
        setLoading(false)
      }
    },
  })
  const showAlert = (data: any) => {
    sweetAlert({
      title: 'Success',
      text: 'Creation successful',
      icon: 'success',
      confirmButtonText: 'OK',
    }).then(() => navigate(data))
  }
  const showError = (error: any) => {
    sweetAlert({
      title: 'Error',
      text: error || 'error',
      icon: 'error',
      confirmButtonText: 'OK',
    })
  }
  const nextStep = async () => {
    setTimeout(() => {
      setTempBoolean((tempBoolean) => !tempBoolean)
    }, 0)

    if (!stepper.current) {
      return
    }

    switch (stepper.current.currentStepIndex) {
      case 1:
        if (!formik.touched.name || !formik.touched.reference_number || !formik.touched.sector) {
          formik.setTouched({
            ...formik.touched,
            name: true,
            description: true,
            reference_number: true,
            sector: true,
            custom_sector: true,
          })
          formik.validateForm()
        }
        if (
          !formik.errors.name &&
          !formik.errors.description &&
          !formik.errors.reference_number &&
          !formik.errors.sector &&
          !formik.errors.custom_sector
        ) {
          stepper.current.goNext()
        }
        break
      case 2:
        if (!formik.values.data_date) {
          formik.setTouched({
            ...formik.touched,
            data_date: true,
          })
          formik.validateForm()
          break
        }
        if (!formik.errors.date_format && !formik.errors.date_unit && !formik.errors.data_date) {
          stepper.current.goNext()
        }
        break
      case 3:
        if (!formik.values.currency || !formik.values.unit || !formik.values.float_formatter) {
          formik.setTouched({
            ...formik.touched,
            currency: true,
            unit: true,
            float_formatter: true,
          })
          formik.validateForm()
          break
        }
        if (!formik.errors.currency && !formik.errors.unit && !formik.errors.float_formatter) {
          try {
            window.addEventListener('beforeunload', promptReload)
            if (currentproject === undefined) {
              const selectedDisplayType = formik.values.display_type.length === 2 ? 'both' : formik.values.display_type[0]
              setLoading(true)
              createProject({
                is_create_completed: false,
                description: formik.values.description,
                name: formik.values.name,
                wbs_display_type: selectedDisplayType,
                reference_number: formik.values.reference_number,
                status: 'pending',
                associated_calendar: formik.values.associated_calendar?._id.$oid,
                parent_folder:
                  formik.values.parent_folder === 'root' ? undefined : formik.values.parent_folder,
                sector:
                  formik.values.sector === 'Other'
                    ? formik.values.custom_sector
                    : formik.values.sector,
              }).then((res: any) => {
                console.log('works')
                setProject(res)
                setCurrentproject(res)
                const dataDate = new Date(formik.values.data_date)
                const startDate = new Date(dataDate)
                startDate.setMonth(startDate.getMonth() - 4)

                const endDate = new Date(dataDate)
                endDate.setMonth(endDate.getMonth() + 8)


                createWbs({
                  name: formik.values.name,
                  parent_project: res._id.$oid,
                }).then((wbs) => {
                  setWbsData(wbs)
                  createWbs({
                    name: 'sub ' + formik.values.name,
                    parent_wbs: wbs._id.$oid,
                    parent_project: res._id.$oid,
                  });
                  patchProject(
                    { default_wbs: wbs._id.$oid, wbs_list: [wbs._id.$oid] },
                    res._id.$oid
                  ).then((patchedproject) => {
                    setProject((prev) =>
                      prev && patchedproject
                        ? {
                          ...prev,
                          default_wbs: patchedproject?.default_wbs,
                          wbs_list: patchedproject.wbs_list,
                          current_wbs: patchedproject.default_wbs._id.$oid,
                        }
                        : undefined
                    )

                    setLoading(false)
                    if (formik.values.pic) {
                      addPhotoProject({ image: formik.values.pic, project_id: res._id.$oid })
                    }
                  })
                })

              })
            }
            stepper.current.goNext()
          } catch (error: any) {
            console.log(JSON.stringify(error.response.data.message))
            showError(error.response.data.message)
          }
        }
        break
      case 4:
        stepper.current.goNext()
        break
      case 5:
        stepper.current.goNext()
        setSubmitButton(true)
        break
      case 6:
        stepper.current.goNext()
        break

      default:
        break
    }
  }

  const collectLeafData = async (node: string): Promise<any[]> => {
    const leafData: any[] = []
    const traverse = async (currentNode: string) => {
      let nodeValue = knownWbs[currentNode]
      if (!nodeValue) {
        nodeValue = await getWbs(currentNode)
        dispatch(addWbsToStore(nodeValue))
      }
      if (!nodeValue?.subwbs || nodeValue?.subwbs?.length === 0) {
        if (nodeValue?.data) {
          let versionData = projectversiondata[nodeValue.data.$oid]
          if (!versionData) {
            let fetchedData = await getVersionById(nodeValue.data.$oid)
            fetchedData = { ...fetchedData, name: nodeValue.name }
            dispatch(setVersionData({ id: nodeValue.data.$oid, data: fetchedData }))
            versionData = fetchedData
          }
          leafData.push(versionData)
        }
      } else {
        for (const child of nodeValue.subwbs) {

          const childWbsData = knownWbs[child.$oid] || (await getWbs(child?.$oid))
          if (!knownWbs[child?.$oid]) {
            dispatch(addWbsToStore(childWbsData))
          }

          await traverse(childWbsData._id.$oid)
        }
      }

    }
    await traverse(node as string)
    return leafData
  }


  const getNewProjectTree = async (): Promise<any[]> => {
    const allKeys: string[] = [];

    const fetchWbsRecursively = async (wbsId: string): Promise<DataNode> => {
      let knownWbsItem = knownWbs[wbsId];
      if (!knownWbsItem) {
        const response = await getWbs(wbsId);
        dispatch(addWbsToStore(response));
        knownWbsItem = response;
      }

      allKeys.push(knownWbsItem._id.$oid);

      const childrenData: DataNode[] = await Promise.all(
        knownWbsItem.subwbs.map(async (subWbs) => {
          return await fetchWbsRecursively(subWbs.$oid);
        })
      );

      return {
        key: knownWbsItem._id.$oid,
        title: <TreeWbsItem wbs={knownWbsItem} />,
        children: childrenData,
      };
    };

    const default_wbs = project?.default_wbs?._id?.$oid

    if (default_wbs) {
      const rootNode = await fetchWbsRecursively(default_wbs);

      return [rootNode];
    }
    return []
  };

  const calculateCombinedVersionForEachWbs = async (): Promise<any[]> => {

    const wbsList: string[] = [];
    const wbsListData: any[] = [];

    const newProjectTree = await getNewProjectTree()

    const findParent = (node: DataNode): void => {
      if (node.children) {
        for (const child of node.children) {
          if (child.children && child.children.length > 0) {
            wbsList.push(child.key as string);
          }
          findParent(child);
        }
      }
    };

    wbsList.push(newProjectTree[0].key as string);
    findParent(newProjectTree[0]);

    dispatch(updateLoaded(0));
    dispatch(updateTotal(0));
    dispatch(updateShow(wbsList.length !== 0));

    for (const [index, node] of wbsList.entries()) {

      const leafData = await collectLeafData(node);
      if (leafData.length > 0) {
        let combinedVersion = await combineVersionsV2(knownWbs[node].name, leafData, true, setWbsDataDateFirstStepAsync, setWbsDataDateSecondStepAsync);
        const { _id, associated_calendar, project: projectData, user, updated_at, created_at, ...rest } = combinedVersion
        if (project?._id.$oid) {
          const version = await createVersion(
            {
              project_version: {
                ...rest,
                start_date: new Date(rest.start_date.$date),
                end_date: new Date(rest.end_date.$date),
                data_date: new Date(rest.data_date.$date),
                forecast_settings: {
                  ...rest.forecast_settings,
                  custom_end_date: rest.forecast_settings?.custom_end_date
                    ? new Date(rest.forecast_settings.custom_end_date.$date)
                    : null,
                },
              },
              timestamp_period: 1
            },
            project?._id.$oid
          );
          const patchedWbs = await patchWbs(node, { data: version._id.$oid })
          dispatch(addWbsToStore(patchedWbs));
          wbsListData.push(version);
          if (project && node === project.default_wbs._id.$oid)
            setDefaultVersion(version._id.$oid).then((res) => {
              setProject((prev) =>
                prev && res
                  ? {
                    ...prev,
                    default_version: res.default_version,
                  }
                  : undefined
              )
            })
        }
      }
    }

    dispatch(updateShow(false));
    return wbsListData;
  };




  return createPortal(
    <TreeProvider>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered modal-fullscreen'
        show={show}
        onHide={clearAndClose}
        onEntered={loadStepper}
      // backdrop={loading ? 'static' : true}
      >
        <div className='modal-header'>
          <h2>Create Project</h2>
          {/* begin::Close */}
          <div
            className='btn btn-sm btn-icon btn-active-color-primary'
            onClick={() => {
              if (!loading) clearAndClose()
            }}
          >
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
          {/* end::Close */}
        </div>

        <div className='modal-body py-lg-10 px-lg-10'>
          {/*begin::Stepper */}
          <div
            ref={stepperRef}
            className='stepper stepper-pills d-flex flex-column'
            id='kt_modal_create_app_stepper'
          >
            {/* begin::Aside*/}
            <div className='d-flex flex-column flex-xl-row justify-content-xl-start w-100'>
              {/* begin::Nav */}
              <div className={`stepper-nav ps-lg-10 d-flex w-100 ${isMobile && 'd-none'}`}>
                {/* begin::Step 1*/}
                <div
                  className='stepper-item current d-flex flex-row w-100'
                  style={{ padding: '0 20px' }}
                  data-kt-stepper-element='nav'
                >
                  {/* begin::Wrapper*/}
                  <div className='stepper-wrapper  d-flex align-items-center'>
                    {/* begin::Icon*/}
                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>1</span>
                    </div>
                    {/* end::Icon*/}

                    {/* begin::Label*/}
                    <div className='stepper-label'>
                      <h3 className='stepper-title'>Settings</h3>

                      <div className='stepper-desc'>General Details</div>
                    </div>
                    {/* end::Label*/}
                  </div>
                  {/* end::Wrapper*/}

                  {/* begin::Line*/}
                  <div className='stepper-line h-40px'></div>
                  {/* end::Line*/}
                </div>
                {/* end::Step 1*/}

                {/* begin::Step 2*/}
                <div
                  className='stepper-item current d-flex flex-row w-100'
                  style={{ padding: '0 20px' }}
                  data-kt-stepper-element='nav'
                >
                  {/* begin::Wrapper*/}
                  <div className='stepper-wrapper  d-flex align-items-center'>
                    {/* begin::Icon*/}
                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>2</span>
                    </div>
                    {/* begin::Icon*/}

                    {/* begin::Label*/}
                    <div className='stepper-label'>
                      <h3 className='stepper-title'>Schedule</h3>

                      <div className='stepper-desc'>Provide Schedule Information</div>
                    </div>
                    {/* begin::Label*/}
                  </div>
                  {/* end::Wrapper*/}

                  {/* begin::Line*/}
                  <div className='stepper-line h-40px'></div>
                  {/* end::Line*/}
                </div>
                {/* end::Step 2*/}

                {/* begin::Step 3*/}
                <div
                  className='stepper-item current d-flex flex-row w-100'
                  style={{ padding: '0 20px' }}
                  data-kt-stepper-element='nav'
                >
                  {/* begin::Wrapper*/}
                  <div className='stepper-wrapper  d-flex align-items-center'>
                    {/* begin::Icon*/}
                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>3</span>
                    </div>
                    {/* end::Icon*/}

                    {/* begin::Label*/}
                    <div className='stepper-label'>
                      <h3 className='stepper-title'>Budget</h3>

                      <div className='stepper-desc'>Your budget configuration</div>
                    </div>
                    {/* end::Label*/}
                  </div>
                  {/* end::Wrapper*/}

                  {/* begin::Line*/}
                  <div className='stepper-line h-40px'></div>
                  {/* end::Line*/}
                </div>
                {/* end::Step 3*/}

                {/* begin::Step 4*/}
                <div
                  className='stepper-item current d-flex flex-row w-100'
                  style={{ padding: '0 20px' }}
                  data-kt-stepper-element='nav'
                >
                  {/* begin::Wrapper*/}
                  <div className='stepper-wrapper  d-flex align-items-center'>
                    {/* begin::Icon*/}
                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>4</span>
                    </div>
                    {/* end::Icon*/}

                    {/* begin::Label*/}
                    <div className='stepper-label'>
                      <h3 className='stepper-title'>Structure</h3>

                      <div className='stepper-desc'>Configure your project structure</div>
                    </div>
                    {/* end::Label*/}
                  </div>
                  {/* end::Wrapper*/}

                  {/* begin::Line*/}
                  <div className='stepper-line h-40px'></div>
                  {/* end::Line*/}
                </div>
                {/* end::Step 4*/}

                {/* begin::Step 5*/}
                <div
                  className='stepper-item current d-flex flex-row w-100'
                  style={{ padding: '0 20px' }}
                  data-kt-stepper-element='nav'
                >
                  {/* begin::Wrapper*/}
                  <div className='stepper-wrapper  d-flex align-items-center'>
                    {/* begin::Icon*/}
                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>5</span>
                    </div>
                    {/* end::Icon*/}

                    {/* begin::Label*/}
                    <div className='stepper-label'>
                      <h3 className='stepper-title'>Data</h3>

                      <div className='stepper-desc'>Fill your project with data</div>
                    </div>
                    {/* end::Label*/}
                  </div>
                  {/* end::Wrapper*/}

                  {/* begin::Line*/}
                  <div className='stepper-line h-40px'></div>
                  {/* end::Line*/}
                </div>
                {/* end::Step 5*/}

                {/* begin::Step 6*/}
                <div
                  className='stepper-item current d-flex flex-row w-100'
                  style={{ padding: '0 20px' }}
                  data-kt-stepper-element='nav'
                >
                  {/* begin::Wrapper*/}
                  <div className='stepper-wrapper  d-flex align-items-center'>
                    {/* begin::Icon*/}
                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>6</span>
                    </div>
                    {/* end::Icon*/}
                    {/* begin::Label*/}
                    <div className='stepper-label'>
                      <h3 className='stepper-title'>Completed</h3>
                    </div>
                    {/* end::Label*/}
                  </div>
                  {/* end::Wrapper*/}
                </div>
                {/* end::Step 6*/}
              </div>
              {/* end::Nav*/}
            </div>
            {/* begin::Aside*/}

            {/*begin::Content */}
            <div className='flex-row-fluid py-lg-5 px-lg-15'>
              {/*begin::Form */}
              <div
                id='kt_modal_create_app_form'
                className='form'
                onSubmit={(e) => {
                  e.preventDefault()
                  formik.handleSubmit()
                }}
              >
                <Step1 formik={formik} />
                <Step2 formik={formik} />
                <Step3 formik={formik} />
                <Step4 formik={formik} currentStep={currentStep} />
                <Step5 formik={formik} currentStep={currentStep} />
                <Step6 formik={formik} />

                {/*begin::Actions */}
                <div className='d-flex flex-stack pt-10'>
                  <div className='me-2'>
                    <button
                      type='button'
                      className='btn btn-lg btn-light-primary me-3'
                      data-kt-stepper-action='previous'
                      onClick={prevStep}
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr063.svg'
                        className='svg-icon-3 me-1'
                      />{' '}
                      Previous
                    </button>
                  </div>
                  <div>
                    <button
                      onClick={submitStep}
                      className='btn btn-lg btn-primary me-3'
                      disabled={isNextStepDisabled || loading}
                      type='button'
                    >
                      <span className='indicator-label'>
                        {!isSubmitButton &&
                          (loading ? (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                              Please wait...
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          ) : (
                            <span>
                              {isNextStepDisabled ? (
                                "Fill at least one WP's data"
                              ) : (
                                <>
                                  Next Step
                                  <KTSVG
                                    path='/media/icons/duotune/arrows/arr064.svg'
                                    className='svg-icon-3 ms-1 me-0'
                                  />
                                </>
                              )}
                            </span>
                          ))}
                        {isSubmitButton && (
                          <span>
                            Submit
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr064.svg'
                              className='svg-icon-3 ms-1 me-0'
                            />
                          </span>
                        )}
                      </span>
                    </button>
                  </div>
                </div>
                {/*end::Actions */}
              </div>
              {/*end::Form */}
            </div>
            {/*end::Content */}
          </div>
          {/* end::Stepper */}
        </div>
      </Modal>
    </TreeProvider>,
    modalsRoot
  )
}

export { ProjectStepper }
