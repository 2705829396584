import {option} from '../model/option'
import styles from './input.module.scss'
import {KTSVG} from '../../../_metronic/helpers'

interface InputProps {
  min?: number
  max?: number
  labelText: string
  name: string
  type: string
  value?: number
  placeholder: string
  options?: option[]
  nameOfSelectList?: string
  formikProps: any
  error: string
  isTouched: boolean
  formikSelectProps?: any
}

const Input = ({
  labelText,
  value,
  placeholder,
  type,
  max,
  min,
  options,
  name,
  nameOfSelectList,
  formikProps,
  formikSelectProps,
  error,
  isTouched,
}: InputProps) => {
  return (
    <div className={`row mb-6 ${styles.inputContent}`}>
      {options && nameOfSelectList ? (
        <div className='d-flex justify-content-between'>
          <label className='col-form-label required fw-bold fs-6'>{labelText}</label>
          {options && nameOfSelectList && (
            <div style={{width: '30%'}}>
              <select
                title={''}
                name={nameOfSelectList}
                className='form-select form-select-solid form-select-sm fw-bold'
                value={formikSelectProps.values[nameOfSelectList]}
                onChange={(e) => {
                  formikSelectProps.setFieldValue(nameOfSelectList, e.target.value)
                }}
              >
                <option value='1'>----</option>
                {options.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
      ) : (
        labelText.length > 0 && (
          <label className='col-lg-12 col-form-label required fw-bold fs-6'>{labelText}</label>
        )
      )}

      <div className='col-lg-12 fv-row'>
        {type === 'range' ? (
          <div className='row'>
            <div className='d-flex flex-row align-items-center justify-content-between gap-5 '>
              <button
                onClick={(e) => {
                  e.preventDefault()
                  if (min && +formikProps.values[name] - 0.1 < min)
                    formikProps.setFieldValue(name, min)
                  else formikProps.setFieldValue(name, +formikProps.values[name] - 0.1)
                }}
                className='btn btn-icon'
              >
                <KTSVG
                  path='/media/icons/duotune/general/gen036.svg'
                  className='svg-icon svg-icon-2x'
                />
              </button>
              <input
                type={'range'}
                min={min}
                max={max}
                step='0.01'
                className='form-range'
                placeholder={placeholder}
                value={value}
                onChange={(e) => {
                  formikProps.setFieldValue(name, e.target.value)
                }}
              />
              <button
                type='button'
                onClick={() => {
                  if (max && +formikProps.values[name] + 0.1 > max)
                    formikProps.setFieldValue(name, max)
                  else formikProps.setFieldValue(name, +formikProps.values[name] + 0.1)
                }}
                className='btn btn-icon'
              >
                <KTSVG
                  path='/media/icons/duotune/general/gen035.svg'
                  className='svg-icon svg-icon-2x'
                />
              </button>
              <input
                className='form-control mw-70px'
                {...formikProps.getFieldProps(name)}
                type='text'
              />
            </div>
          </div>
        ) : (
          <input
            type={type || 'text'}
            className='form-control form-control-lg form-control-solid'
            placeholder={placeholder}
            min={0}
            value={formikProps.values[name]}
            onChange={(e) => {
              formikProps.setFieldValue(name, e.target.value)
            }}
            onBlur={formikProps.handleBlur(name)}
          />
        )}
        {error && isTouched && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>{error}</div>
          </div>
        )}
      </div>
    </div>
  )
}
export default Input
