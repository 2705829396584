import * as Yup from 'yup'
import { getDiffrentBettwenDate } from '../utils/data-transformarion/date-utils'

export const createProjectSchema = Yup.object().shape({
  name: Yup.string().required('name is required'),
  reference_number: Yup.string().required('reference number is required'),
  date_format: Yup.string().required('date format is required'),
  date_unit: Yup.string().required('date unit is required'),
  data_date: Yup.date().required('data date is required'),
  end_date: Yup.date()
    .required('end date is required')
    .when('start_date', (start_date, schema) => start_date && schema.min(start_date)),
  start_date: Yup.date().required('start date unit is required'),
  float_formatter: Yup.number().required('float formatter is required'),
  currency: Yup.object().shape({
    value: Yup.string().required('currency is required'),
  }),
  unitBudgetReserve: Yup.string().notRequired(),
  unit: Yup.string().required('unit is required'),
  unitDelayTolerance: Yup.string().notRequired(),
  budgetAtCompletion: Yup.number()
    .typeError('Must be a number')
    .required('Budget At Completion is required')
    .min(0, 'Must be positive'),
  budgetReserve: Yup.number().required('budget Reserve is required'),
  delayTolerance: Yup.number().required('delay Tolerance is required'),
  savedSimulation: Yup.object().notRequired(),
  cumulativePlannedValue: Yup.array()
    .of(Yup.number().typeError('Cumulative Planned Values must be numbers'))
    .test(
      'pvTest',
      'Planned Value Length should be equal to period number',
      (value: any, context) => {
        return (
          value.length ===
          getDiffrentBettwenDate(
            context.parent.end_date,
            context.parent.start_date,
            context.parent.date_unit
          ) +
            1
        )
      }
    ),
  cumulativeEarnedValue: Yup.array()
    .nullable()
    .of(Yup.number())
    .typeError('Earned Value elements must be numbers')
    .test(
      'evTest',
      'Eraned Value Length should be equal to period number',
      (value: any, context) => {
        if (!value) return true
        return (
          value.length ===
          getDiffrentBettwenDate(
            context.parent.data_date,
            context.parent.start_date,
            context.parent.date_unit
          ) +
            1
        )
      }
    ),
  cumulativeActualCost: Yup.array()
    .nullable()
    .of(Yup.number())
    .typeError('Earned Value elements must be numbers')
    .test(
      'evTest',
      'Actual Cost Length should be equal to period number',
      (value: any, context) => {
        if (!value) return true
        return (
          value.length ===
          getDiffrentBettwenDate(
            context.parent.data_date,
            context.parent.start_date,
            context.parent.date_unit
          ) +
            1
        )
      }
    ),
  worstCaseBaseline: Yup.array()
    .nullable()
    .of(Yup.number().typeError('Baseline values must be numbers'))
    .test('pvTest', 'Baseline length should be equal to period number', (value: any, context) => {
      if (!value) return true
      return (
        value.length ===
        getDiffrentBettwenDate(
          context.parent.end_date,
          context.parent.start_date,
          context.parent.date_unit
        ) +
          1
      )
    }),
  sector: Yup.string().required('sector is required'),
  custom_sector: Yup.string().when('sector', {
    is: 'Other',
    then: (schema) =>
      schema
        .required('custom sector is required')
        .min(2, 'Custom Sector must be at least 2 characters'),
  }),
})

export const addActionPlanSchema = Yup.object().shape({
  start_date: Yup.date().required('Start Date is required'),
  responsible: Yup.string().required('Responsible is required'),
  // associated_comments:Yup.array().required('associated comment is required'),
  action: Yup.string().required('Action is required'),
  deadline: Yup.date().required('Planned Deadline is required'),
  
})
