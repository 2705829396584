import {Modal} from 'react-bootstrap'
import MilestoneForm from './MilestoneForm'

type Props = {onHide: () => void; show: boolean}

export default function CreateMilestoneModal({show, onHide}: Props) {
  return (
    <Modal size='lg' show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Add Milestone</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <MilestoneForm onHide={onHide} />
      </Modal.Body>
    </Modal>
  )
}
