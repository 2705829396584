import axios, {AxiosResponse} from 'axios'
import {Category, Curve, ManageCurveType} from './_models'
import {Pagination} from '../calendars/_models'

const API_URL_CURVES = `${process.env.REACT_APP_API_URL}/curves`
const API_URL_CURVE = `${process.env.REACT_APP_API_URL}/curves`
const API_URL_CATEGORIES = `${process.env.REACT_APP_API_URL}/categories`
const API_URL_CATEGORY = `${process.env.REACT_APP_API_URL}/category`

export type CurvePagination = {
  page: number
  per_page: number
  category_id?: string
}

const createCurve = (curve: ManageCurveType) =>
  axios.post(API_URL_CURVES, curve).then((res: AxiosResponse<Curve>) => res.data)

const getCurves = (pagination: CurvePagination) =>
  axios
    .get(
      `${API_URL_CURVES}?page=${pagination.page}&per_page=${pagination.per_page}${
        pagination.category_id ? '&category_id=' + pagination.category_id : ''
      }`
    )
    .then((res: AxiosResponse<Pagination<Curve>>) => res.data)

const getCurve = (curveId: string) =>
  axios.get(`${API_URL_CURVE}/${curveId}`).then((res: AxiosResponse<Curve>) => res.data)

const patchCurve = (curveId: string, curve: Partial<ManageCurveType>) =>
  axios.patch(`${API_URL_CURVE}/${curveId}`, curve).then((res: AxiosResponse<Curve>) => res.data)

const deleteCurve = (curveId: string) => axios.delete(`${API_URL_CURVE}/${curveId}`)

const sendCurve = (curveId: string, email: string) =>
  axios.post(`${API_URL_CURVE}/${curveId}`, {email})

const getCategories = () =>
  axios.get(API_URL_CATEGORIES).then((res: AxiosResponse<Category[]>) => res.data)

const createCategory = (name: string) =>
  axios.post(API_URL_CATEGORIES, {name}).then((res: AxiosResponse<Category>) => res.data)

const deleteCategory = (categoryId: string) => axios.delete(API_URL_CATEGORY + '/' + categoryId)

export {
  createCurve,
  getCurves,
  deleteCurve,
  getCurve,
  patchCurve,
  sendCurve,
  getCategories,
  createCategory,
  deleteCategory,
}
