import {useEffect, useMemo, useState} from 'react'
import {Comment, CommentHeaderList} from './models'
import {useSortBy, useTable} from 'react-table'
import ActionPlanHeader from '../action-plan/ActionPlanHeader'
import EditComment from './EditComment'
import {useProject} from '../../core/ProjectContext'
import { fetchComments, getComment, getCommentbywbs, getComments } from './request'
import { UseSelector,useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../store'
import { done } from './CommentsState'
import { getWbs, getWbss } from '../../../wbs/_requests'
import { Wbs } from '../../../wbs/_models'
type Props = {keyword: string; action: any; commenterFilter: string[]; showActions?: boolean;comments:any}

export default function CommentTable({keyword, action, commenterFilter, showActions,comments}: Props) {
  // const {originalVersion: project} = useProject()
  const { originalVersion, project } = useProject();
  const currentwbs=useSelector((state:RootState)=>state.currentwbs.value)
  const columns = useMemo(() => CommentHeaderList, [])
  const data = useMemo((): Comment[] => comments||[], [comments])
  const added=useSelector((state:RootState)=>state.added.value)
  const dispatch=useDispatch()
  const {getTableProps, headerGroups, rows, getTableBodyProps, prepareRow} = useTable(
    {
      columns,
      data,
    },
    useSortBy
  )
 
  return (
    <div className='card'>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table
            className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bold dataTable no-footer'
            {...getTableProps()}
          >
            <thead className='fs-7 text-gray-400 text-uppercase'>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <ActionPlanHeader
                      key={column.id}
                      isSorted={column.isSorted}
                      isSortedDesc={column.isSortedDesc}
                      title={column.Header as string}
                      width={(column.Header as string) === 'Comment' ? '250' : '90'}
                      props={column.getHeaderProps(column.getSortByToggleProps)}
                    />
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows
                .filter(
                  (item) =>
                    !commenterFilter.length || commenterFilter.includes(item.original.commenter)
                )
                .filter((item: any) => {
                  if (action === '') {
                    return true;
                  }
                  return item.original.associated_actions.some((associatedAction: any) => associatedAction.$oid === action._id.$oid);
                })
                .filter((item) => item.original?.subject?.toLowerCase().includes(keyword.toLowerCase()))
                .map((row) => {
                  prepareRow(row)
                  return (
                    <EditComment
                      comments={data}
                      key={row.original?.subject}
                      dateFormat={/*project?.date_format ||*/  'mm/dd/yyyy'}
                      comment={row.original}
                      showActions={showActions}
                    />
                  )
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
