import * as Yup from 'yup'
import { Wbs } from '../../../wbs/_models'
import { User } from '../../../apps/user-management/users-list/core/_models'
 


export type Comment = { 
  _id: {$oid: string}
  subject:string
  comment_date: any
  comment: string
  commenter: string
  concerned_data_date: any | undefined
  associated_actions: string[]
  wbs:{$oid:string}
  shared_with_wbs:{$oid:string}[]
 
}


export const defaultComment:Omit<Comment, '_id'> ={ 
  subject:"",
  comment_date: new Date(),
  comment: '',
  commenter: '',
  concerned_data_date: undefined,
  associated_actions: [],
  wbs:{$oid:""},
  shared_with_wbs:[]
 
}

export const CommentHeaderList: any[] = [
  {
    Header: 'subject',
    width: '90',
    accessor: 'subject',
  },
  {
    Header: 'Comment Date',
    width: '100',
    accessor: 'comment_date',
    sortType: (a: any, b: any) => {
      return new Date(b.values.comment_date).getTime() - new Date(a.values.comment_date).getTime()
    },
  },
  {Header: 'Comment', width: '200', accessor: 'comment'},
  {Header: 'Commenter', width: '100', accessor: 'commenter'},
  {Header: 'Concerned Data Date', width: '100', accessor: 'data_date'},
  {Header: 'Associated Actions', width: '90', accessor: 'associated_actions'},
  {Header: 'Shared Wbs', width: '90', accessor: 'wbs'},
  {Header: '', width: '50', accessor: 'button', disableSortBy: true},
]

export const CommentValidationSchema = Yup.object().shape({
  subject: Yup.string().required("Comment subject is required"),
  // comment_date: Yup.date().required('Comment Date is required'),
  comment: Yup.string().required('Comment is required'),
  commenter: Yup.string().required('Commenter is required'),
  // data_date: Yup.string().required('Concerned Data Date is required'),
  // associated_actions: Yup.array(),
  // wbs:Yup.string().required("Related Wbs is required")
})
