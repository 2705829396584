import moment from 'moment'

export const getLabels = (timeUnit: string, outputs: any, isForSigmoid: boolean) => {
  const timeUnitTranlation = timeUnit === 'months' ? 'Month' : 'Day'
  return outputs?.isInitialState
    ? outputs?.tableData[0]
    : isForSigmoid
    ? outputs?.tableData[0].map((el: number) => `${timeUnitTranlation} ${el}`)
    : outputs?.tableData[0]
        .map((el: any) =>
          el === '–' ? el : moment(el).format(timeUnit === 'months' ? 'MMM YYYY' : 'D MMM YYYY')
        )
        .map((str: string) => str.charAt(0).toUpperCase() + str.slice(1))
}
