import { DataNode } from 'antd/es/tree';
import { useTree } from './ShareTreeContext';
import TreeFolderItem from './TreeFolderItem';
import { KTSVG } from '../../../../../_metronic/helpers';
import { useProject } from '../../../projects/core/ProjectContext';
import { useEffect } from 'react';



export default function ShareTreeViewHeader() {
  const { setExpandedKeys, setTreeData, treeData } = useTree();
  const { project } = useProject();


  useEffect(() => {

    loadExpandAll();

  }, []);

  const loadExpandAll = async () => {
    const allKeys: string[] = [];

    const fetchWbsRecursively = async (wbsId: string): Promise<DataNode | null> => {

      let wbsItem = project?.generated_wbs_list?.find(wbs => wbs._id.$oid === wbsId);

      if (project?.checked_wbs) {
        const includeNode = project.checked_wbs.some(wbs => wbs.$oid === wbsId);
        if (!includeNode && wbsId !== project?.share_wbs.$oid) {
          return null;
        }
      }
      if (!wbsItem) {
        throw new Error(`WBS item with id ${wbsId} not found`);
      }

      allKeys.push(wbsItem._id.$oid);

      const childrenData: DataNode[] = (await Promise.all(
        wbsItem?.subwbs.map(async (subWbs) => await fetchWbsRecursively(subWbs.$oid))
      )).filter(Boolean) as DataNode[];

      return {
        key: wbsItem._id.$oid,
        title: <TreeFolderItem folder={wbsItem} isShare={true} />,
        children: childrenData,
      };
    };

    if (project?.share_wbs) {
      const rootNode = await fetchWbsRecursively(project.share_wbs.$oid);
      if (rootNode) {
        setTreeData([rootNode]);
        setExpandedKeys(allKeys);
      }

    }
  };

  const collapseAll = () => {
    setExpandedKeys([]);
  };

  return (
    <header style={{ display: 'flex', flexDirection: 'row' }}>
      <div className='ms-auto'>
        <button
          className='btn btn-light btn-icon btn-sm me-2 rotate active'
          onClick={collapseAll}
          type='button'
          style={{ transition: 'all 0.2s ease-in-out' }}
        >
          <KTSVG
            path='/media/icons/duotune/arrows/arr021.svg'
            className='svg-icon-muted text-hover-primary svg-icon-1 rotate-90'
          />
        </button>
        <button
          className='btn btn-light btn-icon btn-sm me-2 rotate active'
          onClick={loadExpandAll}
          type='button'
          style={{ transition: 'all 0.2s ease-in-out' }}
        >
          <KTSVG
            path='/media/icons/duotune/arrows/arr021.svg'
            className='svg-icon-muted text-hover-primary svg-icon-1 rotate-n90'
          />
        </button>
      </div>
    </header>
  );
}
