import moment from 'moment'
import {formatNumber} from '../../../../utils/formatter'

export const getTableColumns = (data: any) => {
  if (!data?.labels) return []
  let columns: any[] = []
  data.labels.forEach((item: string) => {
    columns.push(item)
  })
  return columns
}
export const getCSVReport = (data: any) => {
  let content: any = []

  data?.plannedValues?.original?.cumulative.forEach((item: any, index: any) => {
    if (index === 0) {
      content.push({'0': 'Cumulative PV'})
    } else {
      content[0] = {
        ...content[0],
        [index.toString()]: formatNumber(parseFloat(item), data?.floatFormater, '.'),
      }
    }
  })
  data?.plannedValues?.original?.periodic.forEach((item: any, index: any) => {
    if (index === 0) {
      content.push({'0': 'Periodic PV'})
    } else {
      content[1] = {
        ...content[1],
        [index.toString()]: formatNumber(parseFloat(item), data?.floatFormater, '.'),
      }
    }
  })
  data?.earnedValues?.mixed?.cumulative.forEach((item: any, index: any) => {
    if (index === 0) {
      content.push({'0': 'Cumulative EV'})
    } else {
      content[2] = {
        ...content[2],
        [index.toString()]: formatNumber(parseFloat(item), data?.floatFormater, '.'),
      }
    }
  })
  data?.earnedValues?.mixed?.periodic.forEach((item: any, index: any) => {
    if (index === 0) {
      content.push({'0': 'Periodic EV'})
    } else {
      content[3] = {
        ...content[3],
        [index.toString()]: formatNumber(parseFloat(item), data?.floatFormater, '.'),
      }
    }
  })
  data?.actualCosts?.original?.cumulative.forEach((item: any, index: any) => {
    if (index === 0) {
      content.push({'0': 'Cumulative AC'})
    } else {
      content[4] = {
        ...content[3],
        [index.toString()]: formatNumber(parseFloat(item), data?.floatFormater, '.'),
      }
    }
  })
  data?.actualCosts?.original?.periodic.forEach((item: any, index: any) => {
    if (index === 0) {
      content.push({'0': 'Periodic AC'})
    } else {
      content[5] = {
        ...content[5],
        [index.toString()]: formatNumber(parseFloat(item), data?.floatFormater, '.'),
      }
    }
  })

  return content
}
export const getHeader = (data: any) => {
  let header = [{label: '-', key: '0'}]
  data?.labels?.forEach((item: string, index: number) => {
    header.push({label: moment(item).format('MM/YY'), key: (index + 1).toString()})
  })
  return header
}
export const validateCumulative = (currentValue: number, previousValue: number) => {
  if (isNaN(currentValue)) return 'Value must be a number'
  if (+currentValue >= +previousValue) return undefined
  else return 'Value must be bigger than previous'
}

export const getPeriodicFromCumulative = (cumulativeArray: any[] | undefined) => {
  if (!cumulativeArray) return []
  let periodicArray: any[] = []
  cumulativeArray.forEach((item: any, index: number) => {
    if (index === 0) {
      periodicArray.push(+item)
    } else {
      periodicArray.push(item - cumulativeArray[index - 1])
    }
  })
  return periodicArray
}
