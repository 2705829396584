import {FC, useEffect, useState} from 'react'
import {IActionPlan, Status} from './IActionPlan'
import {Formik, useFormik} from 'formik'
import _ from 'lodash'
import {patchVersion} from '../../core/_requests'
import {sweetAlert} from '../../../../utils/funcs'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import {useProject} from '../../core/ProjectContext'
import {ProjectObject} from '../../core/_models'
import {DatePicker} from 'antd'
import dayjs from 'dayjs'
import clsx from 'clsx'
import {getDateFormat} from '../../../simulations/core/SimulationHelpers'
import {add, format} from 'date-fns'
import {getFNSDateFormat} from '../../../../helpers/DateFormatter'
import AssociationBadge from '../comments/AssociationBadge'
import ActionAssociationDropdown from './ActionAssociationDropdown'
import {useNavigate} from 'react-router-dom'
import {deassociateActionComment} from './ActionPlanHelpers'
import { getComment, patchCommentById } from '../comments/request'
import { deleteActionPlan, patchActionPlan } from './requests'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../store'
import { adding, done } from '../comments/CommentsState'
import { actions } from 'react-table'
interface Props {
  item: IActionPlan
  items: IActionPlan[]
  projectId: string
  dateFormat: string
  showActions?: boolean
}

const EditActionPlan: FC<Props> = ({item, items, projectId, dateFormat, showActions}: Props) => {
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const {setOriginalVersion, setVersion, originalVersion: project} = useProject()
  const [associatedCommentid,setAssociatedCommentid]=useState<any>()
  const [associatedCommentdata,setAssociatedCommentdata]=useState<any>()
  const added=useSelector((state:RootState)=>state.added.value)
  const [startDate,setStartDate]=useState<any>()
  const [deadline,setDeadline]=useState<any>()
  const [report_date,setReport_date]=useState<any>()
  const [close_date,setClose_date]=useState<any>()
  const dispatch=useDispatch()
  useEffect(() => {
    const fetchdata = async () => {
      if (item) {
        setAssociatedCommentid(item.associated_comment);
      }
    };
    fetchdata();
  }, [item]);
  
  useEffect(() => {
    const fetchCommentData = async () => {
      if (associatedCommentid) {
        const data = await getComment(associatedCommentid.$oid);
        if (data) setAssociatedCommentdata(data);
      }
    };
    fetchCommentData();
  }, [associatedCommentid]);
  
  const formik = useFormik({
    initialValues: item,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (
        values.close_date &&
        !(values.status === Status.DONE || values.status === Status.CANCELED)
      ) {
        setLoading(false)
        setIsEdit(true)
        return sweetAlert({title: 'Error', text: 'Please select status first', icon: 'error'})
      }
      if (!formik.dirty) return setIsEdit(false)
      setLoading(true)
      setIsEdit(false)
      const start_date=new Date(formik.values.start_date.$date)
      const deadline=new Date(formik.values.deadline.$date)
      const reportDate=formik.values.report_date!==null ? new Date(formik.values.report_date.$date): null
      const realdate= formik.values.close_date!==null ? new Date(formik.values.close_date.$date): null
      patchActionPlan(item._id.$oid,{action:formik.values.action,responsible:formik.values.responsible,start_date:start_date ,deadline:deadline,report_date:reportDate,close_date:realdate,status:values.status})
        .then((res) => {
          sweetAlert({
            text: 'Action patched Successfully',
            title: 'patched',
            icon: 'success',
          })
        })
        .catch((err) =>
          sweetAlert({
            text: err.message || 'Something Went Wrong!',
            title: 'Error',
            icon: 'error',
          })
        )
        .finally(() => {
          setIsEdit(false)
          setLoading(false)
        })
    },
  })
  function formateddata(date:any){
    console.log(date)
    const data = new Date(date);

    const month = (data.getMonth() + 1).toString().padStart(2, '0'); // getMonth() is zero-based
    const day = data.getDate().toString().padStart(2, '0');
    const year = data.getFullYear().toString().slice(-2); // get the last two digits of the year
    
    const formattedDate = `${month}/${day}/${year}`; 
    return formattedDate
  }
  useEffect(()=>{
    const startdatedata =formateddata(formik?.values.start_date?.$date)
    const deadline=formateddata(formik?.values.deadline?.$date)
    const report_date=formateddata(formik?.values.report_date?.$date)
    const close_date=formateddata(formik?.values.close_date?.$date)
    setReport_date(report_date)
    setClose_date(close_date)
    setStartDate(startdatedata)
    setDeadline(deadline)
  },[formik.values])


  const deleteAction = (action: IActionPlan) => {
    dispatch(adding())
    sweetAlert({
      title: 'Are you sure?',
      text: 'You cannot recover this later',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      icon: 'warning',
    }).then((res) => {
      dispatch(done())
      if (!res.isConfirmed) return
      else
      deleteActionPlan( action._id.$oid )
      .then((res) => {
      
    const newassociated =associatedCommentdata?.associated_actions?.filter((associate:any)=>{
      return associate.$oid!==item._id.$oid
    })
    const cleaneddata=newassociated.map((item:any)=>item.$oid)
    patchCommentById(associatedCommentdata._id.$oid,{associated_actions:cleaneddata})
          sweetAlert({
            text: 'Action Deleted Successfully',
            title: 'Deleted',
            icon: 'success',
          })
        })
    })
  }

  const deleteAssociation = (commentId: string) => {
    deassociateActionComment(item?.subject, commentId, project)?.then((res) => {
      setVersion((version) =>
        version
          ? ({...version, action_plan: res.action_plan, comments: res.comments} as ProjectObject)
          : undefined
      )
      setOriginalVersion(res)
    })
  }

  const getBadgeColor = () => {
    let color
    switch (formik.values.status) {
      case 'done':
        color = 'badge-success'
        break
      case 'cancelled':
        color = 'badge-danger'
        break
      case 'in_progress':
        color = 'badge-info'
        break
      case 'standby':
        color = 'badge-warning'
        break

      default:
        break
    }
    return color
  }
  const [isEdit, setIsEdit] = useState<boolean>(false)

  const cancelform=()=>{
    setIsEdit(false)
    formik.resetForm()
  }

  return (
    <tr>
      <td className='fw-bold'>{formik.values.subject}</td>
       <td className='fw-bold d-flex flex-wrap align-items-center gap-3'>

             { associatedCommentdata ? <AssociationBadge
                key={associatedCommentdata?._id.$oid}
                editable={isEdit}
                id={associatedCommentdata?.subject}
                goTo={() => navigate('../comments', {state: {action: item}})}
                onDelete={() => deleteAssociation(associatedCommentdata)}
              />
              :
              <div>loading ... </div>
              }
      </td> 
      <td className='fw-bold min-w-150px mw-500px'>
        {isEdit ? (
          <textarea
            className={`text-dark fw-bold d-block fs-7 ActionPlan min-w-200px ${
              isEdit && ' border-bottom border-primary form-control'
            }`}
            id='action'
            {...formik.getFieldProps('action')}
            disabled={!isEdit}
          />
        ) : (
          <span>{formik.values.action}</span>
        )}
      </td>
      <td className='fw-bold'>
        {isEdit ? (
          <textarea
            className={`text-dark fw-bold d-block fs-7 ActionPlan ${
              isEdit && ' border-bottom border-primary form-control'
            }`}
            id='responsible'
            {...formik.getFieldProps('responsible')}
          />
        ) : (
          formik.values.responsible
        )}
      </td>
       <td className='fw-bold'>
        {isEdit ? (
           <DatePicker
           format={getDateFormat('days', dateFormat)}
           className={clsx('form-control fs-7 mw-unset', {
             'border-bottom border-primary': isEdit,
           })}
           id='start_date'
           {...formik.getFieldProps('start_date')}
           value={formik.values.start_date ? dayjs(formik.values.start_date.$date) : null} // Convert to Dayjs here
           onChange={(date) => {
             if (date) {
               const newDate = { $date: date.toDate() };  // Convert back to your data format if needed
               formik.setFieldValue('start_date', newDate);
             } else {
               formik.setFieldValue('start_date', null);
             }
           }}
           disabled={!isEdit}
           allowClear={false}
           suffixIcon={null}
         />
        ) : (
          // format(new Date(startDate&&startDate), getFNSDateFormat(dateFormat))
          startDate&&format(new Date(startDate), getFNSDateFormat(dateFormat))
)}
      </td> 
      <td className='fw-bold'>
        {isEdit ? (
           <DatePicker
           format={getDateFormat('days', dateFormat)}
           className={clsx('form-control fs-7 mw-unset', {
             'border-bottom border-primary': isEdit,
           })}
           id='deadline'
           {...formik.getFieldProps('deadline')}
           value={formik.values.deadline ? dayjs(formik.values.deadline.$date) : null} // Convert to Dayjs here
           onChange={(date) => {
             if (date) {
               const newDate = { $date: date.toDate() };  // Convert back to your data format if needed
               formik.setFieldValue('deadline', newDate);
             } else {
               formik.setFieldValue('deadline', null);
             }
           }}
           disabled={!isEdit}
           allowClear={false}
           suffixIcon={null}
         />
        ) : (
          deadline&&format(new Date(deadline), getFNSDateFormat(dateFormat))
        )}
      </td>
      <td className='fw-bold'>
        {isEdit ? (
           <DatePicker
           format={getDateFormat('days', dateFormat)}
           className={clsx('form-control fs-7 mw-unset', {
             'border-bottom border-primary': isEdit,
           })}
           id='report_date'
           {...formik.getFieldProps('report_date')}
           value={formik.values.report_date ? dayjs(formik.values.report_date.$date) : null} // Convert to Dayjs here
           onChange={(date) => {
             if (date) {
               const newDate = { $date: date.toDate() };  // Convert back to your data format if needed
               formik.setFieldValue('report_date', newDate);
             } else {
               formik.setFieldValue('report_date', null);
             }
           }}
           disabled={!isEdit}
           allowClear={false}
           suffixIcon={null}
         />
        ) :formik.values.report_date!==null ? (
      report_date && format(new Date(report_date), getFNSDateFormat(dateFormat))
        ):(<p className='text-center'>-</p>)}
      </td>
      <td className='fw-bold'>
        {isEdit ? (
           <DatePicker
           format={getDateFormat('days', dateFormat)}
           className={clsx('form-control fs-7 mw-unset', {
             'border-bottom border-primary': isEdit,
           })}
           id='close_date'
           {...formik.getFieldProps('close_date')}
           value={formik.values.close_date ? dayjs(formik.values.close_date.$date) : null} // Convert to Dayjs here
           onChange={(date) => {
             if (date) {
               const newDate = { $date: date.toDate() };  // Convert back to your data format if needed
               formik.setFieldValue('close_date', newDate);
             } else {
               formik.setFieldValue('close_date', null);
             }
           }}
           disabled={!isEdit}
           allowClear={false}
           suffixIcon={null}
         />
        ) :formik.values.close_date!==null ? (
          close_date && format(new Date(close_date), getFNSDateFormat(dateFormat))
        ):(<p className='text-center'>-</p>)}
      </td>
      <td>
        <a
          role='button'
          data-kt-menu-trigger='hover'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={'badge badge-light-primary fw-bold me-auto text-white ' + getBadgeColor()}
        >
          {formik.values.status}
        </a>
        <div
          style={{display: !isEdit ? 'none' : undefined}}
          className='menu menu-sub menu-sub-dropdown w-150px w-md-200px '
          data-kt-menu='true'
        >
          <div className='px-7 py-5'>
            <div className='fs-5 text-dark fw-bolder'>Change Status</div>
          </div>

          <div className='separator border-gray-200'></div>

          <div className='px-7 py-2'>
            <div className='mb-10'>
              <div className='d-flex flex-column gap-2'>
                {!formik.values?.close_date ? (
                  <>
                    <a
                      onClick={() => formik.setFieldValue('status', 'in_progress')}
                      type='button'
                      className='btn btn-light-info font-weight-bold mr-2 btn-sm'
                    >
                      In Progress
                    </a>
                    <a
                      onClick={() => formik.setFieldValue('status', 'standby')}
                      type='button'
                      className='btn btn-light-warning font-weight-bold mr-2 btn-sm'
                    >
                      Stand By
                    </a>
                  </>
                ) : (
                  <>
                    <a
                      type='button'
                      onClick={() => formik.setFieldValue('status', 'done')}
                      className='btn btn-light-success font-weight-bold mr-2 btn-sm'
                    >
                      Done
                    </a>
                    <a
                      type='button'
                      onClick={() => formik.setFieldValue('status', 'cancelled')}
                      className='btn btn-light-danger font-weight-bold mr-2 btn-sm'
                    >
                      Cancelled
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </td>
      <td className='d-flex justify-content-end gap-2'>
        {!isEdit ? (
          loading ? (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          ) : (
            showActions !== false && (
              <>
                <a onClick={() => deleteAction(item)} className='btn btn-danger btn-sm'>
                  Delete
                </a>
                <a
                  onClick={() => setIsEdit(true)}
                  className='btn btn-bg-light btn-active-color-primary btn-sm'
                >
                  Edit
                </a>
              </>
            )
          )
        ) : (
          <>
            <a onClick={formik.submitForm} className='btn btn-primary btn-bg-light btn-sm'>
              Save
            </a>
            <a onClick={cancelform} className='btn btn-secondary btn-bg-light btn-sm'>
                Cancel
            </a>
          </>
        )}
      </td>
    </tr>
  )
}

export default EditActionPlan
