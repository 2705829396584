function formatNumber(number: number, precision: number, separator: string): string {
  if (isNaN(number)) return '-'
  const fixedNumber = number.toFixed(precision)
  if (precision === 0) return fixedNumber
  const [integerPart, decimalPart] = fixedNumber.split('.')

  const formattedIntegerPart = integerPart
    .split('')
    .reverse()
    .reduce((acc, digit, index) => {
      if (index !== 0 && index % 3 === 0) {
        return digit + ' ' + acc
      } else {
        return digit + acc
      }
    }, '')

  return `${formattedIntegerPart}${separator}${decimalPart || ''}`
}

export {formatNumber}
