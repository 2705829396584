import {useEffect, useRef, useState} from 'react'
import {Collapse} from 'react-bootstrap'
import {getLabels, getMaxArrayLength} from '../../../../../helpers/DateFormatter'
import {useProject} from '../../../core/ProjectContext'
import {getCustomCurve} from '../Project'
import {getMilestonesOptions} from '../_helpers'
import ApexCharts from 'apexcharts'
import {KTSVG} from '../../../../../../_metronic/helpers'
import clsx from 'clsx'
import MilestoneSettingsModal from '../modals/MilestoneSettingsModal'
import EmptyValuesCard from '../../../../../components/emptyValuesCard'
import {useNavigate} from 'react-router-dom'
import {useLang} from '../../../../../../_metronic/i18n/Metronici18n'
import {useThemeMode} from '../../../../../../_metronic/partials'
import { DateFormatType } from '../../../../../utils/data-transformarion/date-utils'
import { useDispatch, useSelector } from 'react-redux'
import { Milestone } from '../../milestones/_models'
import { RootState } from '../../../../../store'
import { getMilestonebywbs } from '../../milestones/requests'
import { done } from '../../comments/CommentsState'

type Props = {
  isShared?: boolean;
}

export default function MilestonesChart({isShared}: Props) {
  const {displayVersion} = useProject()
  const [expanded, setExpanded] = useState<boolean>(
    localStorage.getItem('milestonesExpanded') !== 'false'
  )
  const secondChartRef = useRef<HTMLDivElement | null>(null)
  const [showModal, setShowModal] = useState<boolean>(false)
  const currentwbs=useSelector((state:RootState)=>state.currentwbs.value)
  const [milestones,setMilestones]=useState<Milestone[]>()
  const added=useSelector((state:RootState)=>state.added.value)
  const dispatch=useDispatch()
  const navigate = useNavigate()
  const {mode} = useThemeMode()

  useEffect(() => {
    const fetchData = async () => {
      try {
        setMilestones([])
        const milestones = await getMilestonebywbs(currentwbs.toString());
        setMilestones(milestones);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        dispatch(done());
      }
    };

    fetchData();
  }, [added, currentwbs]);
  
  const toggleExpanded = () => {
    setExpanded((prev) => {
      localStorage.setItem('milestonesExpanded', (!prev).toString())
      return !prev
    })
  }
  const lang = useLang()

  const refreshChart = () => {
    const maxLength = getMaxArrayLength(
      displayVersion?.output?.plannedValues?.original?.cumulative,
      displayVersion?.output?.earnedValues?.mixed?.cumulative,
      displayVersion?.output?.actualCosts?.mixed?.cumulative,
      getCustomCurve('cumulativePlannedValue', displayVersion)
    )

    const labels = displayVersion
      ? getLabels(
          displayVersion.start_date.$date,
          5 + maxLength,
          displayVersion?.delay_tolerance?.type === 'monthly'
            ? 'month'
            : displayVersion?.delay_tolerance?.type === 'monthly'
            ? 'month'
            : 'day',
          displayVersion?.date_format as DateFormatType,
          lang
        )
      : []

    const chart = new ApexCharts(
      secondChartRef.current,
      getMilestonesOptions(
        maxLength,
        labels,
        milestones || [],
        displayVersion,
        lang
      )
    )
    if (chart) chart?.render()
    return chart
  }

  useEffect(() => {
    let chart: ApexCharts | undefined = undefined
    if (milestones && milestones?.length) chart = refreshChart()
    return () => chart?.destroy()
  }, [secondChartRef, displayVersion,milestones, mode])

  return (
    <>
    {/* show the Milestones only when there is data on the shared project link */}
     {(milestones && milestones.length > 0 || !isShared) && (
      <>
      <div className='my-3 px-5 align-items-center d-flex justify-content-between'>
        <div className='d-inline-flex gap-2'>
          <span>
            <button
              onClick={toggleExpanded}
              className='btn btn-light p-2 d-inline-flex align-items-center justify-content-center rotate active z-index-2'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr021.svg'
                className={clsx('svg-icon-muted svg-icon svg-icon-sm me-0', {
                  'rotate-270': !expanded,
                  'rotate-90': expanded,
                })}
              />
            </button>
          </span>
          <span className='card-label fw-bold fs-3 mb-1'>Milestones</span>
        </div>
        {!isShared && (
        <div className='d-flex align-items-center gap-2'>
          <i
            onClick={() => setShowModal(true)}
            className='bi bi-gear text-hover-primary d-flex justify-content-end cursor-pointer ms-2'
            onMouseOver={(e) => (e.currentTarget.style.opacity = '0.75')}
            onMouseOut={(e) => (e.currentTarget.style.opacity = '1')}
            style={{fontSize: '2rem', transition: 'all 0.2s ease-in-out'}}
          ></i>
          <MilestoneSettingsModal show={showModal} onHide={() => setShowModal(false)} />
        </div>
        )}
      </div>
            
      { /* display milestones and the add button if there is none */ }
      <Collapse in={expanded}>
        <div>
          {milestones && milestones.length ? (
            <div className='d-flex justify-content-center' ref={secondChartRef}></div>
          ) : (
            <EmptyValuesCard
              text='Create milestones first'
              title='No Milestones'
              buttonText='Add Milestone'
              setShowCreateAppModal={() =>
                navigate(`/project/${displayVersion?.project.$oid}/milestones`)
              }
              className='py-5'
            />
          )}
        </div>
      </Collapse>
      </> )}

    </>
  )
}
