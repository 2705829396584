import axios, {AxiosResponse} from 'axios'
import {Wbs} from './_models'

const API_URL_WBS = `${process.env.REACT_APP_API_URL}/wbs`

  const getWbss = () =>
  axios.get(`${API_URL_WBS}/all`).then((res: AxiosResponse<Wbs[]>) => res.data)

const getWbs = (wbsId?: string|number) =>
  axios
    .get(`${API_URL_WBS}${wbsId ? `/${wbsId}` : ''}`)
    .then((res: AxiosResponse<Wbs>) => res.data)

const createWbs = (wbsParams: {name: string; parent_wbs?: string; parent_project?:string;data?:string}) =>
  axios.post(`${API_URL_WBS}`, wbsParams).then((res: AxiosResponse<Wbs>) => res.data)

const deleteWbs = (wbsId: string) => axios.delete(`${API_URL_WBS}/${wbsId}`)

const patchWbs = (
  wbsId: string,
  wbsParams: {
    name?: string; 
    parent_wbs?: string | null; 
    last_opened_wbs?: string | null; 
    data?: string | null; 
    rank?: 1 | -1;
    comments?:string[]|null;
    subwbs?: string[]; 
  }
) =>
  axios
    .patch(`${API_URL_WBS}/${wbsId}`, wbsParams)
    .then((res: AxiosResponse<Wbs>) => res.data)

export {getWbss, getWbs, createWbs, deleteWbs, patchWbs}
