import {FC, memo, useState} from 'react'
import moment from 'moment'
import _ from 'lodash'
import {ChartUnit} from '../../modules/simulations/simulationSinglePage'
import CopyBtn from '../copyButton/copyButton'
import Copier from '../../helpers/Copier'
import {formatNumber} from '../../utils/formatter'

interface TableSectionProps {
  outputs: any
  isForSigmoid?: boolean
  timeUnit?: string
  chartUnit: ChartUnit
}

const TableSection: FC<TableSectionProps> = memo(
  ({outputs, isForSigmoid = false, timeUnit, chartUnit}) => {
    const [floatFormatter, setFloatFormatter] = useState(1)
    const incrementFormatter = () => {
      setFloatFormatter((prev) => {
        if (prev === 5) return 5
        else return prev + 1
      })
    }
    const decrementFormatter = () => {
      setFloatFormatter((prev) => {
        if (prev === 0) return 0
        else return prev - 1
      })
    }
    const formatPourcentage = (pourcentageString: string): string => {
      const number = pourcentageString.split('%')[0]
      const formattedNumber = formatNumber(+number * chartUnit.ratio, floatFormatter, '.')
      return `${formattedNumber} ${chartUnit.suffix}`
    }
    const getLabeledTableData = () => {
      const locale = localStorage.getItem('i18nextLng') || 'en'

      if (!_.isEmpty(outputs)) {
        const firstRow = outputs?.isInitialState
          ? outputs?.tableData[0]
          : isForSigmoid
          ? outputs?.tableData[0].map(
              (el: number) => `${timeUnit === 'days' ? 'Day' : 'Month'} ${el}`
            )
          : outputs?.tableData[0]
              .map((el: any) =>
                el === '–'
                  ? el
                  : moment(el)
                      .locale(locale)
                      .format(`${timeUnit === 'days' ? 'D-' : ''}MMM-YYYY`)
              )
              .map((str: string) => str.charAt(0).toUpperCase() + str.slice(1))

        const tab = [
          ['Date', ...firstRow],
          [
            'Cumulative Progress in ' + chartUnit.suffix,
            ...outputs?.tableData[1].map(formatPourcentage),
          ],
          [
            'Periodic Progress in ' + chartUnit.suffix,
            ...outputs?.tableData[2].map(formatPourcentage),
          ],
        ]

        return tab
      }
      return []
    }

    const labeledTableData = getLabeledTableData()

    const min = outputs?.tableData[2].reduce((a: any, b: any) => (+a > +b ? +b : +a), Infinity)
    const max = outputs?.tableData[2].reduce((a: any, b: any) => (+a < +b ? +b : +a), 0)
    const average =
      outputs?.tableData[2].reduce((a: any, b: any) => +a + +b, 0) / outputs?.tableData[2].length

    const real = outputs?.tableData[2].slice(0, outputs.highlightIdx + 1)
    const forecast = outputs?.tableData[2].slice(outputs.highlightIdx + 1)

    const getMinMaxAverage = (arr: number[]) => {
      return {
        min: arr.reduce((a: any, b: any) => (+a > +b ? +b : +a), Infinity),
        max: arr.reduce((a: any, b: any) => (+a < +b ? +b : +a), 0),
        average: arr.reduce((a: any, b: any) => +a + +b, 0) / arr.length,
      }
    }

    const realMinMaxAverage = getMinMaxAverage(real)
    const forecastMinMaxAverage = getMinMaxAverage(forecast)

    return (
      <>
        {outputs.tableData && !!outputs.tableData[0].length && (
          <div className='card card-xxl-stretch mb-5 mb-xl-10'>
            <div className='card-body py-3'>
              {/* <div className='row w-50'> */}
              <div className='d-flex justify-content-between align-items-center overflow-scroll'>
                <div className='d-flex'>
                  {outputs.highlightIdx <= 0 || chartUnit.suffix !== 'FTE' ? (
                    <>
                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                        <div className='fw-bold fs-6 text-gray-400'>
                          Periodic Max
                          <span className='text-muted ms-2'>{formatPourcentage(max + '')}</span>
                        </div>
                      </div>
                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                        <div className='fw-bold fs-6 text-gray-400'>
                          Periodic Min
                          <span className='text-muted ms-2'>{formatPourcentage(min + '')}</span>
                        </div>
                      </div>
                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                        <div className='fw-bold fs-6 text-gray-400'>
                          Periodic Avg
                          <span className='text-muted ms-2'>{formatPourcentage(average + '')}</span>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                        <div className='fw-bold fs-6 text-gray-400'>
                          Real Max
                          <span className='text-muted ms-2'>
                            {formatPourcentage(realMinMaxAverage.max.toFixed(floatFormatter) + '%')}
                          </span>
                        </div>
                      </div>
                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                        <div className='fw-bold fs-6 text-gray-400'>
                          Real Min
                          <span className='text-muted ms-2'>
                            {formatPourcentage(realMinMaxAverage.min.toFixed(floatFormatter) + '%')}
                          </span>
                        </div>
                      </div>
                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                        <div className='fw-bold fs-6 text-gray-400'>
                          Real Avg
                          <span className='text-muted ms-2'>
                            {formatPourcentage(
                              realMinMaxAverage.average.toFixed(floatFormatter) + '%'
                            )}
                          </span>
                        </div>
                      </div>
                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                        <div className='fw-bold fs-6 text-gray-400'>
                          Forecast Max
                          <span className='text-muted ms-2'>
                            {formatPourcentage(
                              forecastMinMaxAverage.max.toFixed(floatFormatter) + '%'
                            )}
                          </span>
                        </div>
                      </div>
                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                        <div className='fw-bold fs-6 text-gray-400'>
                          Forecast Min
                          <span className='text-muted ms-2'>
                            {formatPourcentage(
                              forecastMinMaxAverage.min.toFixed(floatFormatter) + '%'
                            )}
                          </span>
                        </div>
                      </div>
                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                        <div className='fw-bold fs-6 text-gray-400'>
                          Forecast Avg
                          <span className='text-muted ms-2'>
                            {formatPourcentage(
                              forecastMinMaxAverage.average.toFixed(floatFormatter) + '%'
                            )}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className='d-flex flex-column flex-xl-row align-items-end align-items-xl-center gap-2'>
                  <div className='d-flex align-items-center gap-5'>
                    <div className='fw-bold text-muted'>Float Formatter</div>
                    {/* put float formatter here */}
                    <div className=''>
                      <div
                        className='position-relative w-md-300px'
                        data-kt-dialer='true'
                        data-kt-dialer-min='1000'
                        data-kt-dialer-max='50000'
                        data-kt-dialer-step='1000'
                        data-kt-dialer-prefix='$'
                        data-kt-dialer-decimals='2'
                      >
                        <button
                          type='button'
                          className='btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 start-0'
                          data-kt-dialer-control='decrease'
                          onClick={decrementFormatter}
                        >
                          <span className='svg-icon svg-icon-1'>
                            <svg
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <rect
                                opacity='0.3'
                                x='2'
                                y='2'
                                width='20'
                                height='20'
                                rx='5'
                                fill='currentColor'
                              />
                              <rect
                                x='6.0104'
                                y='10.9247'
                                width='12'
                                height='2'
                                rx='1'
                                fill='currentColor'
                              />
                            </svg>
                          </span>
                        </button>
                        <input
                          type='text'
                          className='form-control form-control-solid border-0 ps-12'
                          data-kt-dialer-control='input'
                          placeholder='Float Formatter'
                          name='manageBudget'
                          readOnly={true}
                          value={floatFormatter}
                        />
                        <button
                          type='button'
                          className='btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 end-0'
                          data-kt-dialer-control='increase'
                          onClick={incrementFormatter}
                        >
                          <span className='svg-icon svg-icon-1'>
                            <svg
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <rect
                                opacity='0.3'
                                x='2'
                                y='2'
                                width='20'
                                height='20'
                                rx='5'
                                fill='currentColor'
                              />
                              <rect
                                x='10.8891'
                                y='17.8033'
                                width='12'
                                height='2'
                                rx='1'
                                transform='rotate(-90 10.8891 17.8033)'
                                fill='currentColor'
                              />
                              <rect
                                x='6.01041'
                                y='10.9247'
                                width='12'
                                height='2'
                                rx='1'
                                fill='currentColor'
                              />
                            </svg>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <CopyBtn
                    imagePath='/media/icons/duotune/general/gen005.svg'
                    disabled={_.isEmpty(outputs)}
                    label='Copy CSV'
                    onClick={() => Copier.handleCopyAsCSV(labeledTableData)}
                  />
                  <CopyBtn
                    imagePath='/media/icons/duotune/coding/cod003.svg'
                    label='Copy HTML'
                    disabled={_.isEmpty(outputs)}
                    onClick={() => Copier.handleCopyAsHTML(labeledTableData, outputs.highlightIdx)}
                  />
                </div>
              </div>
              <div className='table-responsive'>
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                  <thead>
                    <tr className='fw-bolder text-muted'>
                      {labeledTableData[0].map((item: any, index: number) => (
                        <th
                          key={index}
                          className={`min-w-120px bg-opacity-10 ${
                            index === outputs.highlightIdx + 1 && 'bg-warning'
                          }`}
                          style={{minWidth: '120px'}}
                        >
                          {item}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <a className='text-primary fw-bolder text-hover-primary fs-6'>Cumulative</a>
                      </td>
                      {outputs.tableData[1].map((item: any, index: number) => (
                        <td
                          className={`bg-opacity-10 ${
                            index === outputs.highlightIdx && 'bg-warning'
                          }`}
                          key={index}
                        >
                          <span className='fw-bold d-block fs-7 text-dark'>
                            {formatPourcentage(item)}
                          </span>
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td>
                        <a className='text-primary fw-bolder text-hover-primary fs-6'>Periodic</a>
                      </td>
                      {outputs.tableData[2].map((item: any, index: number) => (
                        <td
                          className={`bg-opacity-10 ${
                            index === outputs.highlightIdx && 'bg-warning'
                          }`}
                          key={index}
                        >
                          <span className='fw-bold d-block fs-7 text-dark'>
                            {formatPourcentage(item)}
                          </span>
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </>
    )
  }
)

export default TableSection
