import React, { useEffect, useState } from 'react'
import TreeView, { flattenTree, NodeId } from 'react-accessible-treeview';
import { FaCheckSquare, FaMinusSquare, FaSquare } from 'react-icons/fa';
import { IoMdArrowDropright } from 'react-icons/io';
import { KTSVG } from '../../../../../_metronic/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { useProject } from '../../core/ProjectContext';
import { patchCommentById, transformToFolder } from './request';
import clsx from 'clsx';
import { Comment } from './models';
import { Wbs } from '../Wbs/Wbs';
import { patchActionPlan } from '../action-plan/requests';
import { sweetAlert } from '../../../../utils/funcs';
import { adding, done } from './CommentsState';
type Props = {
    handleClose: () => void
    comment:Comment
  }
  
export default function SharedWbsTree({handleClose,comment}:Props) {
    const currentwbs=useSelector((state:RootState)=>state.currentwbs.value)
    const knownWbs = useSelector((state: RootState) => state.treedata.knownWbs);
    const added=useSelector((state:RootState)=>state.added.value)
    const dispatch=useDispatch()
    const { setProject, setOriginalVersion, project } = useProject();
    const [selectednodes,setSelectednodes]=useState<string[]>([])
    const [preselectedIds,setPreselectedIds]=useState<NodeId[]>()
    console.log()
   
    console.log(selectednodes)
    const [data, setData] = useState<any>();
    useEffect(() => {
        setData(knownWbs[project?.current_wbs]); 
      }, [knownWbs]); 
    const folder = transformToFolder(data,knownWbs)  ;
    const treedata =  flattenTree(folder) ;
    const handleNodeSelect = (node: any) => {
       

        const selectedIds = node.treeState.selectedIds;
        let idsArray:string[] = Array.from(selectedIds)
        setSelectednodes(idsArray);
      };  
    
    useEffect(()=>{
        const cleanedIds=comment.shared_with_wbs.map((item)=>{
            return item.$oid
        })
        setPreselectedIds(cleanedIds)
    },[comment])
console.log(selectednodes)
const save = async () => {
  try {
    // Update the comment
    dispatch(adding())
    const commentUpdateResponse = await patchCommentById(comment._id.$oid, {
      shared_with_wbs: [...selectednodes],
    });

    // Update associated actions in parallel
    const actionUpdatePromises = comment.associated_actions.map((item:any) =>
      patchActionPlan(item.$oid, {
        shared_with_wbs: [...selectednodes],
      })
    );

    // Await all promises
    await Promise.all(actionUpdatePromises).then((res)=>{
      handleClose()
      sweetAlert({
        text: 'wbs updated Successfully',
        title: 'Updated',
        icon: 'success',
      });
      dispatch(done())
    })
    
    console.log("Comment and associated actions updated successfully.");
  } catch (error) {
    console.error("Error updating comment or associated actions:", error);
  }
};

  return (
    <div>
        <div className='checkbox'>
        <TreeView
          data={treedata}
          aria-label='Single select'
          multiSelect={true}
          propagateSelectUpwards={false}
          propagateSelect={true}
          propagateCollapse={false}
          togglableSelect={true}
          selectedIds={preselectedIds}
          defaultDisabledIds={[comment.wbs.$oid]}
          defaultSelectedIds={preselectedIds}
          defaultExpandedIds={[comment.wbs.$oid]}
          nodeAction='check'
          onSelect={(node) => handleNodeSelect(node)}
          nodeRenderer={({
            element,
            isBranch,
            isExpanded,
            isSelected,
            isHalfSelected,
            isDisabled,
            getNodeProps,
            level,
            handleSelect,
            handleExpand,
          }) => (
            <div
              {...getNodeProps({ onClick: handleExpand })}
              style={{ marginLeft: 30 * (level - 1),
                opacity: isDisabled ? 0.3 : 1,
               }}
            >
              {isBranch && <ArrowIcon isOpen={isExpanded} />
              }
              <CheckBoxIcon
                onClick={(e: any) => {
                  handleSelect(e);
                  e.stopPropagation();
     
                }}
                
                variant={isHalfSelected ? 'some' : isSelected ? 'all' : 'none'}
              />
               {isBranch?
              <KTSVG className='svg-icon-primary svg-icon-2 me-2'
              path='/media/icons/duotune/graphs/git04.svg' />:
              <KTSVG
              path='/media/icons/duotune/files/fil003.svg'
              className='svg-icon-primary svg-icon-2 me-2'
            /> }
              <span className='name h5'>{element.name}</span>
            </div>
          )}
        />
        </div>
        <div className='d-flex flex-stack '>
        <button
          type='submit'
          className='btn btn-lg btn-primary'
          onClick={()=>{save()}}
        >
            <span className='indicator-label'>
              save
              <KTSVG
                path='/media/icons/duotune/arrows/arr064.svg'
                className='svg-icon-3 ms-2 me-0'
              />
            </span>
        </button>
      </div>
    </div>
  )
}

const ArrowIcon = ({ isOpen, className }: { isOpen: boolean; className?: string }) => {
    const baseClass = "arrow";
    const classes = clsx(
      baseClass,
      { [`${baseClass}--closed`]: !isOpen },
      { [`${baseClass}--open`]: isOpen },
      className
    );
        return <IoMdArrowDropright className={classes} />
  };
  
  interface CheckBoxIconProps {
    variant: 'all' | 'none' | 'some';
    [key: string]: any;
  }
  
  const CheckBoxIcon: React.FC<CheckBoxIconProps> = ({ variant,isDisabled, ...rest }) => {
    switch (variant) {
      case 'all':
        return <FaCheckSquare {...rest} />;
      case 'none':
        return <FaSquare {...rest} />;
      case 'some':
        return <FaMinusSquare {...rest} />;
      default:
        return null;
    }
  };

function cx(baseClass: string, arg1: { "arrow--closed": boolean; }, arg2: { "arrow--open": boolean; }, className: string | undefined) {
    throw new Error('Function not implemented.');
}
  