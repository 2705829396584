import {ApexOptions} from 'apexcharts'
import {ProjectObject} from '../../../core/_models'
import {getLabels, getMaxArrayLength} from '../../../../../helpers/DateFormatter'
import {getCustomCurve} from '../Project'
import {getCSSVariableValue} from '../../../../../../_metronic/assets/ts/_utils'
import moment from 'moment'
import { DateFormatType } from '../../../../../utils/data-transformarion/date-utils'

const getResourceOptions = (project: ProjectObject, lang: string): ApexOptions => {
  const labelColor = getCSSVariableValue('--kt-dark')

  const maxLength = getMaxArrayLength(
    project?.output?.plannedValues?.original?.cumulative,
    project?.output?.earnedValues?.mixed?.cumulative,
    project?.output?.actualCosts?.mixed?.cumulative,
    getCustomCurve('cumulativePlannedValue', project)
  )
  const labels = getLabels(
    project.start_date.$date,
    maxLength,
    project?.delay_tolerance?.type === 'monthly'
      ? 'month'
      : project?.delay_tolerance?.type === 'monthly'
      ? 'month'
      : 'day',
    project?.date_format as DateFormatType,
    lang
  )

  const plannedValues = project.output?.FTE?.plannedFTE
    ? labels.map((item, index) => project.output?.FTE?.plannedFTE[index] || null)
    : []

  const realValues = project.output?.FTE?.realFTE
    ? labels.map((item, index) => project.output?.FTE?.realFTE[index] || null)
    : []

  const forecastValues = project.output?.FTE?.forecastFTE
    ? labels.map((item, index) => project.output?.FTE?.forecastFTE[index] || null)
    : []

  const firstNotNull = forecastValues.findIndex((item) => item !== null)

  forecastValues[firstNotNull] = null

  const toCompleteValues = project.output?.FTE?.FTEToComplete
    ? [
        ...Array(project.output.nbOfTimeUnitPassed).fill(null),
        ...project.output?.FTE?.FTEToComplete,
      ]
    : []

  toCompleteValues[firstNotNull] = null

  const averagePlanned =
    plannedValues.slice(0, project.output?.nbOfTimeUnitPassed + 1).reduce((a, b) => a + b, 0) /
    (project.output?.nbOfTimeUnitPassed + 1)

  const averageReal =
    realValues.reduce((a, b) => (a ? a + b : b), 0) / (project.output?.nbOfTimeUnitPassed + 1)

  const cleanForecastValues = forecastValues.filter((item) => !!item)

  const averageForecast =
    cleanForecastValues.reduce((a, b) => (a ? a + b : b), 0) / cleanForecastValues.length

  const cleanToCompleteValues = toCompleteValues.filter((item) => !!item)

  const averageToComplete =
    cleanToCompleteValues.reduce((a, b) => (a ? a + b : b), 0) / cleanToCompleteValues.length

  return {
    chart: {
      height: 500,
      animations: {enabled: false},
    },
    grid: {
      padding: {
        top: 0,
        left: 60,
        right: 60,
      },
    },
    labels,
    tooltip: {
      shared: false,
      intersect: false,
      followCursor: false,
      marker: {
        show: true,
      },
    },
    xaxis: {
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
        rotate: -45,
        rotateAlways: true,
      },
    },
    legend: {
      show: false,
    },
    yaxis: [
      {
        seriesName: 'Planned FTE',
        decimalsInFloat: project.float_formatter,
        labels: {style: {colors: labelColor}},
        title: {
          style: {color: labelColor},
          text: 'Resources in FTE',
        },
      },
      {
        labels: {show: false},
        seriesName: 'Planned FTE',
        decimalsInFloat: project.float_formatter,
      },
      {
        labels: {show: false},
        seriesName: 'Planned FTE',
        decimalsInFloat: project.float_formatter,
      },
      {
        labels: {show: false},
        seriesName: 'Planned FTE',
        decimalsInFloat: project.float_formatter,
      },
      {
        seriesName: 'Planned FTE',
        decimalsInFloat: project.float_formatter,
        labels: {show: false},
      },
      {
        labels: {show: false},
        seriesName: 'Planned FTE',
        decimalsInFloat: project.float_formatter,
      },
      {
        labels: {show: false},
        seriesName: 'Planned FTE',
        decimalsInFloat: project.float_formatter,
      },
      {
        labels: {show: false},
        seriesName: 'Planned FTE',
        decimalsInFloat: project.float_formatter,
      },
      {
        labels: {show: false},
        opposite: true,
      },
    ],
    colors:
      project.colors?.resource_colors && project.colors?.resource_colors?.length
        ? project.colors?.resource_colors
        : undefined,
    fill: {
      opacity: 0.5,
      type: ['solid', 'solid', 'pattern', 'pattern'],
      pattern: {style: 'slantedLines'},
    },
    stroke: {
      width: 2,
      dashArray: Array(8)
        .fill(0)
        .map((item, index) => (index >= 6 ? 5 : 0)),
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [4, 5, 6, 7],
      formatter: (val, opts) => {
        if (!val) return ''
        if ([4, 5].includes(opts.seriesIndex))
          if (opts.dataPointIndex === 0) return (val as number).toFixed(project.float_formatter)
        if ([6, 7].includes(opts.seriesIndex))
          if (opts.dataPointIndex === firstNotNull + 1)
            return (val as number).toFixed(project.float_formatter)
        return ''
      },
    },

    series: [
      {
        name: 'Planned FTE',
        data: plannedValues.map((item, index) =>
          index <= project.output?.nbOfTimeUnitPassed ? item : null
        ),
        type: 'column',
      },
      {
        name: 'Real FTE',
        data: realValues,
        type: 'column',
      },
      {
        name: 'Forecast FTE',
        data: forecastValues,
        type: 'column',
      },
      {
        name: 'FTE To Complete',
        data: toCompleteValues,
        type: 'column',
      },
      {
        name: 'Average Planned FTE',
        type: 'line',
        data: realValues.map((item) => (item ? averagePlanned : null)),
      },
      {
        name: 'Average Real FTE',
        type: 'line',
        data: realValues.map((item) => (item ? averageReal : null)),
      },
      {
        name: 'Average Forecast FTE',
        type: 'line',
        data: forecastValues.map((item) => (item ? averageForecast : null)),
      },
      {
        name: 'Average To Complete FTE',
        type: 'line',
        data: toCompleteValues.map((item) => (item ? averageToComplete : null)),
      },
    ],
    annotations: {
      xaxis: [
        {
          x: moment(project.data_date.$date).format('MMM YY'),
          strokeDashArray: 0,
          borderColor: '#775DD0',
          label: {
            borderColor: '#775DD0',
            orientation: 'horizontal',
            style: {
              color: '#fff',
              background: 'rgba(119, 93, 208, 0.5)',
              cssClass: 'border-0',
            },
          },
        },
      ],
    },
  }
}

export {getResourceOptions}
