import dayjs from 'dayjs'
import {Calendar} from '../../modules/calendars/_models'
import {
  applyCalendarToIncompleteCurve,
  getCapacity,
  getReelDays,
} from '../../modules/library/ProjectCurve/Context/ProjectCurveHelpers'
import {getDisplayableValues} from '../../modules/library/ProjectCurve/core'
import {getPeriodicFromCumulative} from '../../modules/projects/components/data/prepareDataTable'
import {getCustomCurve} from '../../modules/projects/components/overview/Project'
import {getDiffBetweenTwoDates} from '../forecast'

const forecastFinishDate = async (
  inputs: any,
  data: any,
  cumulativeEarnedValuesAsPercentages: any,
  SPIt: number[],
  EarnedSchedule: number[],
  calendar?: Calendar
) => {
  if (
    dayjs(data.data_date.$date).isAfter(dayjs(data.end_date.$date)) ||
    dayjs(data.data_date.$date).isSame(dayjs(data.end_date.$date), 'month')
  )
    return getDisplayableValues(inputs, [], [], false)
  const cumulativePlannedValuesAsPercentages = getCustomCurve('cumulativePlannedValue', data).map(
    (item) => (+item / data.budget_at_completion.amount) * 100
  )
  const plannedDuration =
    getDiffBetweenTwoDates(data.start_date.$date, data.end_date.$date, 'month') + 1
  const tcspi =
    (plannedDuration - EarnedSchedule[EarnedSchedule.length - 1]) /
    (plannedDuration - cumulativeEarnedValuesAsPercentages.length)

  let esForecast = Array(plannedDuration - cumulativeEarnedValuesAsPercentages.length).fill(0)
  for (let i = 0; i < plannedDuration - cumulativeEarnedValuesAsPercentages.length; i++) {
    esForecast[i] = EarnedSchedule[EarnedSchedule.length - 1] + tcspi * (i + 1)
  }
  let evForecast = esForecast.map((item) => {
    const t1 = Math.floor(item)
    const t2 = Math.ceil(item) >= plannedDuration ? plannedDuration : Math.ceil(item)
    return (
      (item - t1) *
        (cumulativePlannedValuesAsPercentages[t2 - 1] -
          cumulativePlannedValuesAsPercentages[t1 - 1]) +
      cumulativePlannedValuesAsPercentages[t1 - 1]
    )
  })
  let fullEv = [...cumulativeEarnedValuesAsPercentages, ...evForecast].map((item) => item / 100)

  if (calendar) {
    const {reelDays, totalDays} = await getReelDays(
      dayjs(data.data_date.$date).add(1, 'month').toDate(),
      evForecast.length,
      calendar
    )

    const capacity = getCapacity(reelDays, totalDays)
    evForecast = applyCalendarToIncompleteCurve(
      capacity,
      evForecast,
      cumulativeEarnedValuesAsPercentages
    ).tableData[1]

    fullEv = [...cumulativeEarnedValuesAsPercentages, ...evForecast].map((item) => item / 100)
  }

  return getDisplayableValues(
    inputs,
    fullEv,
    getPeriodicFromCumulative(fullEv).map((item) => Math.max(item, 0)),
    false
  )
}

export {forecastFinishDate}
