import _ from 'lodash'
import {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {Sparklines, SparklinesBars} from 'react-sparklines'
import {sweetAlert} from '../../../../utils/funcs'
import {useAuth} from '../../../auth'
import {updateUser} from '../../../auth/core/_requests'
import {calculateSigmoidOutputs} from '../../../library/ProjectCurve/core'
import {useCategories, useCurves} from '../../../library/_queries'
import {Pagination} from '../../../projects/components/pagination/Pagination'
import {CurveSettings} from '../../core/_models'
import clsx from 'clsx'

type Props = {
  show: boolean
  onHide: () => void
  formik: any
}

const RECORD_TO_IGNORE = 'IGNORE_THIS_RECORD_6DBE8ADAD7F9'

export default function CurveSettingsModal({show, onHide, formik}: Props) {
  const {currentUser, setCurrentUser} = useAuth()
  const [selectedCurve, setSelectedCurve] = useState<CurveSettings | undefined>(undefined)
  const {data: categories} = useCategories()
  const [page, setPage] = useState(1)
  const [selectedCategory, setSelectedCategory] = useState<string | undefined>()
  const {data: curveData, isLoading} = useCurves({
    page: page,
    per_page: 5,
    category_id: selectedCategory,
  })
  const handleHide = () => {
    setSelectedCurve(undefined)
    onHide()
  }

  const loadCurve = () => {
    if (!selectedCurve) return handleHide()
    formik.setFieldValue('curve_settings.tangent', selectedCurve.tangent)
    formik.setFieldValue('curve_settings.offset', selectedCurve.offset)
    handleHide()
  }

  const handleDelete = (curve: CurveSettings) => {
    let curveSettings = currentUser?.curve_settings?.filter((c) => !_.isEqual(c, curve))
    if (!curveSettings?.length) curveSettings = [{name: RECORD_TO_IGNORE, tangent: 0, offset: 0}]
    updateUser(undefined, {curve_settings: curveSettings})
      .then((res) => {
        setCurrentUser(res.data)
      })
      .catch((err) => {
        sweetAlert({
          text: err.response.data.message || 'Something went wrong',
          title: 'Error',
          icon: 'error',
        })
      })
  }
  return (
    <Modal size='lg' centered show={show} onHide={handleHide}>
      <Modal.Header>
        <Modal.Title>Import Profile</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='table-responsive'>
          <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
            <thead>
              <tr className='text-start text-muted fw-bolder fs-6 text-uppercase gs-0'>
                <th></th>
                <th className=''>NAME</th>
                <th className=''>PREVIEW</th>
                <th className=''>DELETE</th>
              </tr>
            </thead>
            <tbody className='text-gray-700 fw-bold fs-5'>
              <tr>
                <td className='text-center table-active' colSpan={4}>
                  Simulation Profiles
                </td>
              </tr>
              {!currentUser?.curve_settings?.filter((curve) => curve.name !== RECORD_TO_IGNORE)
                .length && (
                <tr>
                  <td className='text-center' colSpan={4}>
                    <h3>No Results</h3>
                  </td>
                </tr>
              )}
              {currentUser?.curve_settings
                ?.filter((curve) => curve.name !== RECORD_TO_IGNORE)
                ?.map((curve, index) => (
                  <tr key={index}>
                    <td className='leave'>
                      <input
                        value={JSON.stringify({tangent: curve.tangent, offset: curve.offset})}
                        type='radio'
                        className='form-check-input'
                        checked={_.isEqual(selectedCurve, {
                          tangent: curve.tangent,
                          offset: curve.offset,
                          name: curve.name,
                        })}
                        onChange={(e) => setSelectedCurve(JSON.parse(e.target.value))}
                      />
                    </td>
                    <td className='ms-5 min-width-100px'>{curve.name}</td>
                    <td className='min-width-200px'>
                      <Sparklines
                        svgWidth={170}
                        svgHeight={60}
                        data={calculateSigmoidOutputs(
                          {
                            mu: curve.tangent,
                            sig: curve.offset,
                            nbOfMonthsPassed: 12,
                          },
                          false
                        ).tableData[2].map((item) => +item)}
                      >
                        <SparklinesBars />
                      </Sparklines>
                    </td>
                    <td className='min-width-100px'>
                      <button
                        onClick={() => handleDelete(curve)}
                        type='button'
                        className='btn btn-icon btn-lg btn-active-icon-danger ms-5'
                      >
                        <i className='bi bi-trash fs-2'></i>
                      </button>
                    </td>
                  </tr>
                ))}
              <tr>
                <td className='text-center table-active' colSpan={4}>
                  Curve Library
                </td>
              </tr>
              <tr>
                <td colSpan={4}>
                  <div className='d-flex gap-2 align-items-center'>
                    {[{name: 'All sectors', _id: {$oid: undefined}}, ...(categories || [])]?.map(
                      (item) => (
                        <span
                          key={item._id.$oid || 'all'}
                          onClick={() => setSelectedCategory(item._id.$oid)}
                          className={clsx('badge badge-lg badge-primary cursor-pointer', {
                            'badge-outline': item._id.$oid !== selectedCategory,
                          })}
                        >
                          {item.name}
                        </span>
                      )
                    )}
                  </div>
                </td>
              </tr>
              {!curveData?.items?.length && (
                <tr>
                  <td className='text-center' colSpan={4}>
                    <h3>No Library Results</h3>
                  </td>
                </tr>
              )}
              {curveData?.items.map((item) => (
                <tr key={item._id.$oid}>
                  <td className='leave'>
                    <input
                      value={JSON.stringify({tangent: item.tangent, offset: item.axis})}
                      type='radio'
                      className='form-check-input'
                      checked={_.isEqual(selectedCurve, {
                        tangent: item.tangent,
                        offset: item.axis,
                      })}
                      onChange={(e) => setSelectedCurve(JSON.parse(e.target.value))}
                    />
                  </td>
                  <td className='ms-5 min-width-100px'>{item.name}</td>
                  <td className='min-width-200px'>
                    <Sparklines
                      svgWidth={170}
                      svgHeight={60}
                      data={calculateSigmoidOutputs(
                        {
                          mu: item.tangent,
                          sig: item.axis,
                          nbOfMonthsPassed: 12,
                        },
                        false
                      ).tableData[2].map((item) => +item)}
                    >
                      <SparklinesBars />
                    </Sparklines>
                  </td>
                  <td className='min-width-100px'></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className='mt-5'>
          <Pagination
            pagination={curveData?.meta}
            isLoading={isLoading}
            updateState={(data: any) => {
              setPage(data.page)
            }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className='justify-content-center'>
        <button
          onClick={loadCurve}
          disabled={!selectedCurve}
          className='btn btn-primary '
          style={{width: '60%'}}
        >
          Load
        </button>
      </Modal.Footer>
    </Modal>
  )
}
