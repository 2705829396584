import Select, {GroupBase} from 'react-select'
import {SelectComponents} from 'react-select/dist/declarations/src/components'
interface CustomSelectProps {
  options?: any
  name: string
  formikProps?: any
  onChange?: any
  value?: any
  onBlur?: any
  padding?: boolean
  components?: Partial<SelectComponents<any, false, GroupBase<any>>>
  maxHeight?: string
  isSearchable?: boolean
  placeholderText?: string
}
function CustomSelect({
  options,
  name,
  onChange,
  value,
  onBlur,
  padding = true,
  components,
  maxHeight = 'unset',
  isSearchable = false,
  placeholderText = ''
}: CustomSelectProps) {
  return (
    <Select
      isSearchable={isSearchable}
      menuPlacement='auto'
      menuPortalTarget={document.body}
      name={name || undefined}
      onBlur={onBlur || undefined}
      placeholder={placeholderText}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          borderColor: state.isFocused ? 'transparent' : 'transparent',
          background: 'var(--kt-input-solid-bg)',
          border: 'var(--kt-highlight-bg)',
          minHeight: '3.20rem',
          padding: padding ? '0.5rem 1.5rem' : '',
          fontSize: '1.15rem',
        }),
        input: (base) => ({
          ...base,
          color: 'var(--kt-text-dark)',
        }),
        menu: (base, state) => ({
          ...base,
          background: 'var(--kt-input-solid-bg)',
          zIndex: 100000,
        }),
        singleValue: (provided) => ({
          ...provided,
          color: 'var(--kt-input-solid-color)',
        }),
        option: (base, {data, isDisabled, isFocused, isSelected}) => {
          return {
            ...base,
            backgroundColor: isDisabled
              ? 'var(--kt-input-disabled-bg)'
              : isFocused
              ? 'var(--kt-component-hover-bg)'
              : 'var(--kt-input-solid-bg)',
            color: isSelected ? 'var(--kt-component-hover-color)' : 'var(--kt-gray-700);',
            transition: 'color 0.2s ease',
            cursor: isDisabled ? 'not-allowed' : 'default',
            // backgroundColor: isSelected ? "rgba(189,197,209,.3)" : "white",
          }
        },
        menuPortal: (base) => ({...base, zIndex: 9999}),
        menuList: (base) => ({...base, maxHeight: maxHeight}),
      }}
      options={options || []}
      value={value}
      onChange={onChange}
      defaultValue={options?.filter((item: any) => item.value === value)[0]}
      components={components}
      isOptionDisabled={(option) => {
        if (option?.isDisabled) return true
        return false
      }}
    />
  )
}

export default CustomSelect
