import {useFormik} from 'formik'
import {FTEFormModel, FTEValidationSchema, InitFTE} from '../_models'
import InputWithLabel from '../../../../../components/inputWithLabel/InputWithLabel'
import ColorInputWithLabel from '../../../../../components/inputWithLabel/ColorInputWithLabel'
import {Modal} from 'react-bootstrap'
import {useState} from 'react'
import {patchVersion} from '../../../core/_requests'
import {useProject} from '../../../core/ProjectContext'
import {showError, showSuccess, sweetAlert} from '../../../../../utils/funcs'
import {getProjectData} from '../../../../../utils/project-data'

interface Props {
  onHide: () => void
}

const FTEForm = ({onHide}: Props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const {displayVersion, setVersion, setOriginalVersion, project, version, originalVersion} =
    useProject()
  const formik = useFormik<FTEFormModel>({
    initialValues:
      displayVersion?.colors?.resource_colors && displayVersion?.colors?.resource_colors?.length
        ? {
            ...InitFTE,
            full_capacity: displayVersion?.full_capacity ? displayVersion?.full_capacity + '' : '',
            total_hours: displayVersion?.total_hours ? displayVersion?.total_hours + '' : '',
            planned_color: displayVersion?.colors?.resource_colors[0],
            real_color: displayVersion?.colors?.resource_colors[1],
            forecast_color: displayVersion?.colors?.resource_colors[2],
            to_complete_color: displayVersion?.colors?.resource_colors[3],
          }
        : {
            ...InitFTE,
            full_capacity: displayVersion?.full_capacity ? displayVersion?.full_capacity + '' : '',
            total_hours: displayVersion?.total_hours ? displayVersion?.total_hours + '' : '',
          },
    validationSchema: FTEValidationSchema,
    onSubmit: async (values) => {
      setLoading(true)
      setTimeout(async () => {
        const promiseArray = []
        try {
          promiseArray.push(
            getProjectData({
              ...displayVersion,
              full_capacity: values.full_capacity,
              total_hours: values.total_hours,
            })
          )
          if (version)
            promiseArray.push(
              getProjectData({
                ...originalVersion,
                full_capacity: values.full_capacity,
                total_hours: values.total_hours,
              })
            )

          const responses: any[] = await Promise.all(promiseArray)

          if (version) {
            patchVersion(
              {
                total_hours: values.total_hours,
                full_capacity: values.full_capacity,
                output: responses[1],
                colors: {
                  ...displayVersion?.colors,
                  resource_colors: [
                    values.planned_color,
                    values.real_color,
                    values.forecast_color,
                    values.to_complete_color,
                  ],
                },
              },
              originalVersion?._id.$oid
            )
              .then((res) => {
                setVersion((prev) =>
                  prev
                    ? {
                        ...prev,
                        total_hours: res.total_hours,
                        full_capacity: res.full_capacity,
                        output: responses[0],
                      }
                    : undefined
                )
                setOriginalVersion((prev) =>
                  prev
                    ? {
                        ...prev,
                        total_hours: res.total_hours,
                        full_capacity: res.full_capacity,
                        output: responses[1],
                      }
                    : undefined
                )
                onHide()
              })
              .catch(showError)
          } else {
            patchVersion(
              {
                total_hours: values.total_hours,
                full_capacity: values.full_capacity,
                output: responses[0],
                colors: {
                  ...displayVersion?.colors,
                  resource_colors: [
                    values.planned_color,
                    values.real_color,
                    values.forecast_color,
                    values.to_complete_color,
                  ],
                },
              },
              originalVersion?._id.$oid
            )
              .then((res) => {
                setOriginalVersion((prev) =>
                  prev
                    ? {
                        ...prev,
                        total_hours: res.total_hours,
                        full_capacity: res.full_capacity,
                        output: responses[0],
                      }
                    : undefined
                )
                onHide()
              })
              .catch(showError)
          }
        } catch (err) {
          showError(err)
        } finally {
          setLoading(false)
        }
      })
    },
    enableReinitialize: true,
  })
  return (
    <>
      <Modal.Body>
        <form className='form'>
          {/* Total Hours */}
          <InputWithLabel
            error={formik.errors.total_hours}
            formikProps={formik.getFieldProps('total_hours')}
            label='Total work hours for the project'
            touched={formik.touched.total_hours}
            required
            placeholder='0'
          />
          {/* END Total Hours */}
          {/* Full Capacity */}
          <InputWithLabel
            error={formik.errors.full_capacity}
            formikProps={formik.getFieldProps('full_capacity')}
            label='Full time capacity for 1 ressource per month'
            touched={formik.touched.full_capacity}
            required
            placeholder='0'
            unit='Hours/Month'
          />
          {/* END Full Capacity */}
          {/* Color Pickers */}
          <ColorInputWithLabel
            error={formik.errors.planned_color}
            formikProps={formik.getFieldProps('planned_color')}
            label='Planned FTE Color'
            touched={formik.touched.planned_color}
            value={formik.values.planned_color}
            placeholder=''
          />
          {/* End Color Pickers */}
          <ColorInputWithLabel
            error={formik.errors.real_color}
            formikProps={formik.getFieldProps('real_color')}
            label='Real FTE Color'
            touched={formik.touched.real_color}
            value={formik.values.real_color}
            placeholder=''
          />

          <ColorInputWithLabel
            error={formik.errors.forecast_color}
            formikProps={formik.getFieldProps('forecast_color')}
            label='Forecast FTE Color'
            touched={formik.touched.forecast_color}
            value={formik.values.forecast_color}
            placeholder=''
          />
          <ColorInputWithLabel
            error={formik.errors.to_complete_color}
            formikProps={formik.getFieldProps('to_complete_color')}
            label='FTE To Complete Color'
            touched={formik.touched.to_complete_color}
            value={formik.values.to_complete_color}
            placeholder=''
          />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={() => formik.handleSubmit()}
          disabled={loading || !formik.dirty || !formik.isValid}
          className='btn btn-primary'
        >
          {loading ? (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          ) : (
            'Save'
          )}
        </button>
      </Modal.Footer>
    </>
  )
}

export default FTEForm
