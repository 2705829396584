import { KTSVG } from '../../../../../../_metronic/helpers'
import clsx from 'clsx'
import { Wbs } from '../../../_models'

type Props = {
  wbs: Wbs
}

export default function SelectTreeItem({ wbs }: Props) {
  return (
    <div className={clsx('d-inline-flex justify-content-start align-items-center')}>
      <KTSVG
        path='/media/icons/duotune/files/fil012.svg'
        className='svg-icon-primary svg-icon-2 me-2'
      />
      <span className='fw-bold me-2'>{wbs.name}</span>
    </div>
  )
}
