import {useFormik} from 'formik'
import _ from 'lodash'
import {useState} from 'react'
import {Card} from 'react-bootstrap'
import CustomSelect from '../../../../components/CustomSelect'
import {sweetAlert} from '../../../../utils/funcs'
import {useProject} from '../../core/ProjectContext'
import EacFormulaSelection from './EacFormulaSelection'
import ForecastFormulaSelection from './ForecastFormulaSelection'
import {
  ForecastSettingsFormWithName,
  getForecastSettingsValidationSchema,
  getInitForecastSettings,
} from '../overview/_models'
import {changeForecastTypeFromSettings} from '../../../../utils/forecast'

interface Props {
  versionData: any
  projectData: any
}
export default function ProjectSettings({versionData}: Props) {
  const {setOriginalVersion, displayVersion, originalVersion, project, setVersion} = useProject()
  const initialValues = {
    name: versionData?.name || '',
    dateUnit: versionData.date_format,
    floatFormatter: versionData.float_formatter,
    eacFormula: displayVersion?.forecast_settings?.eac_formula || 0,
    forecastFormula: displayVersion?.forecast_settings?.eac_formula || 0,
    bottom_up_ec:
      (displayVersion?.forecast_settings?.bottom_up_ec.find(
        (item) => item.label === displayVersion.output?.dataDateLabel
      )?.value || '') + '',
    custom_eac: (displayVersion?.forecast_settings?.custom_eac || '') + '',
    max_capacity: (displayVersion?.forecast_settings?.max_capacity || '') + '',
    custom_end_date: displayVersion?.forecast_settings?.custom_end_date
      ? new Date(displayVersion?.forecast_settings?.custom_end_date.$date)
      : undefined,
  }

  const [loading, setLoading] = useState(false)
  const showAlert = () => {
    sweetAlert({
      title: 'Success',
      text: 'Changes made successfully!',
      icon: 'success',
      confirmButtonText: 'OK',
    })
  }
  const formik = useFormik<ForecastSettingsFormWithName>({
    initialValues: {
      ...getInitForecastSettings(displayVersion),
      name: versionData?.name || '',
      dateUnit: versionData.date_format,
      floatFormatter: versionData.float_formatter,
    },
    enableReinitialize: true,
    validationSchema: getForecastSettingsValidationSchema(displayVersion),
    onSubmit: (values) =>
      changeForecastTypeFromSettings(
        values,
        displayVersion,
        originalVersion,
        project,
        setVersion,
        setOriginalVersion,
        setLoading,
        showAlert
      ),
  })
  return (
    <Card className='mt-5'>
      <Card.Header>
        <Card.Title>Version Settings</Card.Title>
      </Card.Header>
      <Card.Body>
        <div className='row mb-8'>
          <div className='col-xl-3'>
            <div className='fs-6 fw-semibold mt-2 mb-3'>Version Name</div>
          </div>
          <div className='col-xl-9 fv-row'>
            <input
              placeholder='Unnamed'
              type='text'
              className='form-control form-control-solid'
              {...formik.getFieldProps('name')}
            />
          </div>
        </div>
        <div className='row mb-8'>
          <div className='col-xl-3'>
            <div className='fs-6 fw-semibold mt-2 mb-3'>Date Format</div>
          </div>
          <div className='col-xl-9 fv-row'>
            <CustomSelect
              onBlur={formik.handleBlur}
              name='date_unit'
              value={{value: formik.values.dateUnit, label: formik.values.dateUnit}}
              options={[
                {value: 'mm/dd/yyyy', label: 'mm/dd/yyyy'},
                {value: 'dd/mm/yyyy', label: 'dd/mm/yyyy'},
              ]}
              onChange={(d: any) => {
                formik.setFieldValue('dateUnit', d.value)
              }}
            />
          </div>
        </div>
        <div className='row mb-8'>
          <div className='col-xl-3'>
            <div className='fs-6 fw-semibold mt-2 mb-3'>Float formatter</div>
          </div>
          <div className='col-xl-9 fv-row'>
            <CustomSelect
              value={{value: formik.values.floatFormatter, label: formik.values.floatFormatter}}
              name='floatFormatter'
              onBlur={formik.handleBlur}
              options={[
                {value: '0', label: '0'},
                {value: '1', label: '1'},
                {value: '2', label: '2'},
                {value: '3', label: '3'},
                {value: '4', label: '4'},
                {value: '5', label: '5'},
              ]}
              onChange={(d: any) => {
                formik.setFieldValue('floatFormatter', parseInt(d.value))
              }}
            />
          </div>
        </div>
        <EacFormulaSelection formik={formik} />
        <ForecastFormulaSelection formik={formik} sector={project?.sector} />
      </Card.Body>
      <Card.Footer className='d-flex justify-content-end py-6 px-9'>
        <button
          type='submit'
          className='btn btn-primary'
          id='kt_project_settings_submit'
          onClick={formik.submitForm}
          disabled={_.isEqual(formik.values, initialValues) || loading}
        >
          {!loading && <span className='indicator-label'>Save Changes</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </Card.Footer>
    </Card>
  )
}
