import {useEffect} from 'react'
import {MenuComponent} from '../../../assets/ts/components'

/* eslint-disable jsx-a11y/anchor-is-valid */
interface Dropdown1Props {
  setFilter?: any
  filter: string[]
  filters?: Option[]
  title: string
}

interface Option {
  label: string
  value: string
}

export function MultiOptionDropdown({setFilter, filter, filters, title}: Dropdown1Props) {
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])
  const toggleOption = (option: Option) => {
    setFilter((prev: string[]) => {
      if (prev.includes(option.value)) return prev.filter((item) => item !== option.value)
      else return [...prev, option.value]
    })
  }
  return (
    <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
      </div>

      <div className='separator border-gray-200'></div>

      <div className='px-7 py-5'>
        <div className='mb-10'>
          <label className='form-label fw-bold'>{title}</label>
          <div></div>
          {!filters?.length && <span className='text-muted fw-bold'>No options found.</span>}
          {filters?.map((item) => (
            <label
              key={item.value}
              onClick={() => toggleOption(item)}
              className='row my-3 mx-5 form-check form-check-solid'
            >
              <input
                readOnly
                checked={!!filter.find((filter) => item.value === filter)}
                className='col-3 form-check-input'
                type='checkbox'
              />
              <span className='col-9 fs-5 fw-3'>{item.label}</span>
            </label>
          ))}
        </div>
      </div>
    </div>
  )
}
