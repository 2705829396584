import {Skeleton} from 'antd'

export default function SnapshotsSkeleton() {
  return (
    <div style={{display: 'flex', justifyContent: 'center'}}>
        <Skeleton active title={{width: '100%'}} paragraph={{rows: 0, width: '100%'}} />
    </div>
    
  )
}
