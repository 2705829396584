/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../assets/ts/_utils'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'
import moment from 'moment'
import {getFirstDayOfMonth} from '../../../../app/helpers/func'
import styles from './chart.module.scss'
import TotalPriceModal from '../../../../app/modules/simulations/components/TotalPriceModal'
import {ChartUnit} from '../../../../app/modules/simulations/simulationSinglePage'
import {CustomCurve} from '../../../../app/modules/simulations/core/_models'
import {getFormattedDate} from '../../../../app/helpers/DateFormatter'
import clsx from 'clsx'
import _ from 'lodash'
import {formatNumber} from '../../../../app/utils/formatter'
import {RECORD_TO_IGNORE} from '../../../../app/modules/simulations/components/Chart/_helpers'
import {ChartCustomCurve} from '../../../../app/modules/simulations/components/Chart/_models'

type Props = {
  saveSettings: () => void
  className: string
  outputs?: any
  isForSigmoid?: boolean
  type?: string
  data?: any
  chartUnit: ChartUnit
  showCustomCurves: () => void
}

const ChartsWidget3: React.FC<Props> = ({
  className,
  outputs,
  isForSigmoid,
  type,
  data,
  chartUnit,
  saveSettings,
  showCustomCurves,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const [modalOpen, setModalOpen] = useState(false)
  const [showCumulative, setShowCumulative] = useState<boolean>(true)
  const [showPeriodic, setShowPeriodic] = useState<boolean>(true)
  const [customCurve, setCustomCurve] = useState<ChartCustomCurve[]>(
    data.custom_curve.map((item: CustomCurve) => ({...item, show: true, id: _.uniqueId()}))
  )
  const getData = () => {
    const firstDayOfCurrentMonth = getFirstDayOfMonth(new Date())
    let labels, dataset1, dataset2
    if (isForSigmoid) {
      if (outputs.isInitialState) {
        labels = Array.from({length: 17}).map(
          (_el, idx) => `${type === 'days' ? 'Day' : 'Month'} ${idx + 1}`
        )
        dataset1 = []
        dataset2 = []
      } else {
        labels = Array.from({length: outputs?.tableData[1]?.length})?.map(
          (_el, idx) => `${type === 'days' ? 'Day' : 'Month'} ${idx + 1}`
        )

        dataset1 = outputs.tableData[1].map((el: string) => +el.slice(0, el.length - 1))
        dataset2 = outputs.tableData[2].map((el: string) => +el.slice(0, el.length - 1))
      }
    } else {
      if (outputs.isInitialState) {
        labels = Array.from({length: 17})
          .map((_el, idx) =>
            moment(firstDayOfCurrentMonth)
              .add(idx - 8, 'months')
              .format('MMM YYYY')
          )
          .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
        dataset1 = []
        dataset2 = []
      } else {
        if (outputs?.tableData?.length > 0) {
          const locale = localStorage.getItem('i18nextLng') || 'en'
          labels = outputs?.tableData[0]
            .map((el: any) =>
              el === '–'
                ? el
                : moment(el)
                    .locale(locale)
                    .format(`${type === 'days' ? 'D-' : ''}MMM-YYYY`)
            )
            .map((str: string) => str.charAt(0).toUpperCase() + str.slice(1))

          dataset1 = outputs?.tableData[1].map((el: string) => +el.slice(0, el.length - 1))
          dataset2 = outputs?.tableData[2].map((el: string) => +el.slice(0, el.length - 1))
        }
      }
    }

    return {dataset1, dataset2, labels}
  }

  const refreshMode = (dataset: any[], dataset2: any[], labels: any[]) => {
    if (!chartRef.current) {
      return
    }
    const height = parseInt(getCSS(chartRef.current, 'height'))
    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(
        height,
        chartUnit,
        showCumulative ? dataset : [],
        showPeriodic ? dataset2 : [],
        labels,
        customCurve,
        outputs.highlightIdx
      )
    )
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    if (!outputs.tableData) return
    const {dataset1, dataset2, labels} = getData()
    const chart = refreshMode(dataset1, dataset2, labels)
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, outputs, chartUnit, showPeriodic, showCumulative, customCurve])

  // Effect to delete or add elements in the label
  useEffect(() => {
    setCustomCurve((prev) => {
      let newCustomCurve: ChartCustomCurve[] = []
      data.custom_curve.map((curve: CustomCurve) => {
        const foundItem = prev.find(
          (item: ChartCustomCurve) => curve.name === item.name && curve.color === item.color
        )
        newCustomCurve.push({
          ...curve,
          id: foundItem?.id || _.uniqueId(),
          show: foundItem?.show || true,
        })
      })
      return newCustomCurve
    })
  }, [data])

  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='row justify-content-md-end my-5 mx-5'>
        <div className='col-2'>
          <i
            onClick={() => {
              saveSettings()
              setModalOpen(true)
            }}
            className='bi bi-gear d-flex text-hover-primary justify-content-end cursor-pointer'
            //add animation to that
            onMouseOver={(e) => {
              e.currentTarget.style.opacity = '0.75'
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.opacity = '1'
            }}
            style={{fontSize: '2rem', transition: 'all 0.2s ease-in-out'}}
          ></i>
          <TotalPriceModal data={data} show={modalOpen} closeModal={() => setModalOpen(false)} />
        </div>
      </div>
      <div className='card-body'>
        {/* Custom Legend */}
        <div className='d-flex justify-content-center gap-5 flex-wrap'>
          <div
            onClick={() => setShowPeriodic((prev) => !prev)}
            className='d-flex flex-row gap-2 align-items-center cursor-pointer'
          >
            <div
              style={{height: '15px', width: '15px', backgroundColor: '#14D193'}}
              className='rounded-circle'
            />
            <span className={clsx('fs-6', {'text-muted': !showPeriodic})}>Periodic Progress</span>
          </div>
          <div
            onClick={() => setShowCumulative((prev) => !prev)}
            className='d-flex flex-row gap-2 align-items-center cursor-pointer'
          >
            <div
              style={{
                height: '15px',
                width: '15px',
                backgroundColor: '#0B69FF',
              }}
              className='rounded-circle'
            />
            <span className={clsx('fs-6', {'text-muted': !showCumulative})}>
              Cumulative Progress
            </span>
          </div>
          {customCurve
            ?.filter((curve: ChartCustomCurve) => curve.name !== RECORD_TO_IGNORE)
            .map((item: ChartCustomCurve) => (
              <div
                key={item.id}
                onClick={() =>
                  setCustomCurve((oldCurves: ChartCustomCurve[]) => {
                    let intermediate = [...oldCurves]
                    let selectedCurveIndex = intermediate.findIndex((curve) => curve.id === item.id)
                    let selectedCurve = intermediate[selectedCurveIndex]
                    intermediate[selectedCurveIndex] = {...selectedCurve, show: !selectedCurve.show}
                    return intermediate
                  })
                }
                className='d-flex flex-row gap-2 align-items-center cursor-pointer'
              >
                <div
                  style={{height: '15px', width: '15px', backgroundColor: item.color}}
                  className='rounded-circle'
                />
                <span className={clsx('fs-6', {'text-muted': !item.show})}>{item.name}</span>
              </div>
            ))}
        </div>
        <div className='d-flex flex-row w-100 justify-content-center'></div>
        {/* begin::Chart */}
        <div ref={chartRef} id='kt_charts_widget_3_chart' style={{height: '400px'}}></div>
        {/* end::Chart */}
        <div className={`${styles.helper} d-flex flex-row w-100 justify-content-between ala`}>
          <div>
            {!isForSigmoid && (
              <div>
                <p className='fs-5 text-gray-800 fw-bold'>
                  {'Number of remaining'} {type === 'days' ? ' Days:' : ' Months :'}
                  <br />
                  <span>
                    {outputs.nbOfRemainingMonths ? `${outputs.nbOfRemainingMonths} ${type}` : '-'}
                  </span>
                </p>

                <p className='fs-5 text-gray-800 fw-bold'>
                  {'Estimated end date :'}
                  <br />
                  <span className={styles.capitalized}>
                    {outputs?.estimatedEndDate
                      ? getFormattedDate(
                          (outputs.estimatedEndDate as moment.Moment).toDate(),
                          type === 'months' ? 'monthly' : 'daily'
                        )
                      : '-'}
                  </span>
                </p>
              </div>
            )}
          </div>
          <div className='d-flex flex-column'>
            <button
              type='button'
              className='btn btn-lg btn-primary'
              data-kt-stepper-action='next'
              onClick={() => {
                saveSettings()
                showCustomCurves()
              }}
            >
              Manage Custom Curves
            </button>
          </div>
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ChartsWidget3}

function getChartOptions(
  height: number,
  chartUnit: ChartUnit,
  data: any[],
  data2?: any[],
  labels?: any[],
  custom_curve?: any,
  highlightIdx?: number
): ApexOptions {
  const labelColor = getCSSVariableValue('--kt-gray-500')
  const borderColor = getCSSVariableValue('--kt-gray-200')
  const baseColor = getCSSVariableValue('--kt-info')
  const lightColor = getCSSVariableValue('--kt-info-light')
  return {
    series: [
      {
        type: 'column',
        name: 'Percentage of periodic progress',
        data: data2 || [],
      },
      {
        type: 'line',
        name: 'Percentage of cumulative progress',
        data: data || [],
      },
      ...custom_curve
        ?.filter((curve: ChartCustomCurve) => curve.name !== RECORD_TO_IGNORE && curve.show)
        ?.map((curve: CustomCurve) => ({
          type: 'line',
          name: curve.name,
          data: curve.values.slice(0, data2?.length || curve.values.length),
          color: curve.color,
        })),
    ],
    chart: {
      animations: {
        enabled: false,
      },
      fontFamily: 'inherit',
      height: 400,
      type: 'line',
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,

          customIcons: [],
        },
      },
    },
    tooltip: {
      enabled: true,
      shared: true,
      y: {
        formatter: (val: any) => {
          if (!val) return '- ' + chartUnit.suffix
          return formatNumber(val * chartUnit.ratio, 1, '.') + ' ' + chartUnit.suffix
        },
      },
    },
    legend: {
      show: false,
      fontSize: '15px',
      position: 'top',
      offsetY: 15,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'straight',
      show: true,
      width: 3,
    },
    xaxis: {
      categories: labels || [],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: baseColor,
          width: 1,
          dashArray: 3,
        },
      },
      decimalsInFloat: 2,
    },
    yaxis: [
      {
        labels: {
          formatter: (value: number) =>
            formatNumber(value * chartUnit.ratio, 1, '.') + ' ' + chartUnit.suffix,
          style: {
            colors: labelColor,
          },
        },
        opposite: true,
        title: {
          text: 'Periodic',
        },
      },
      {
        title: {
          text: 'Cumulative',
        },
        max: 100,
        labels: {
          formatter: (value: number) =>
            formatNumber(value * chartUnit.ratio, 1, '.') + ' ' + chartUnit.suffix,
          style: {
            colors: labelColor,
          },
        },
      },
      ...custom_curve?.map((curve: CustomCurve) => ({
        max: 100,
        labels: {
          show: false,
        },
      })),
    ],
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    colors: [
      ({value, seriesIndex, dataPointIndex, w}: any) => {
        if (!data2 || !highlightIdx) return '#14D193'
        if (dataPointIndex === highlightIdx) return '#FFB800'
        return '#14D193'
      },
      '#0B69FF',
    ],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: baseColor,
      strokeWidth: 3,
    },
  }
}
