import {getPeriodicFromCumulative} from '../modules/projects/components/data/prepareDataTable'
import {calculateOutputs} from './func'
import moment from 'moment'

/**
 * Optimized version of the calculateOutputs method that reduces error between requested % done and the value at data date
 * @param inputs recieves percentage done, sig, mu and months passed
 * @param  customCurve  the custom curve used to calculate the error
 * @returns closest sigmoid curve that matches the input
 */
export const calculateOptimizedOutputs = (
  inputs: any,
  customCurve: number[],
  errorRate: number = 10
) => {
  const initialResult = calculateOutputs(inputs, false)
  if (customCurve.length < 2) return initialResult
  // calculate the acceptable error margin
  const threshold =
    (customCurve[customCurve.length - 1] - customCurve[customCurve.length - 2]) * (errorRate / 100)
  const initialError = calculateError(customCurve, initialResult.tableData[1])
  // if initial results respect the acceptable error then accept
  if (initialError <= threshold) return initialResult
  // check if adding the initial error to ev reduces output error then give up
  let fullErrorOutputs = calculateOutputs(
    {
      ...inputs,
      donePercentage: inputs.donePercentage + initialError,
    },
    false
  )
  // if you don't find better results with ev+error return initialResult
  if (calculateError(customCurve, fullErrorOutputs.tableData[1]) >= initialError)
    return initialResult
  // if you get better results then try to find the closes to the EV
  let index = 4
  while (index > 1) {
    let iterationOutputs = calculateOutputs(
      {
        ...inputs,
        donePercentage: inputs.donePercentage + initialError / index,
      },
      false
    )
    let iterationError = calculateError(customCurve, iterationOutputs.tableData[1])
    if (iterationError < threshold) return iterationOutputs
    index = index / 2
  }
  return fullErrorOutputs
}

/**
 * Calculates error between percentage done and output at data date
 * @param customCurve Earned value / Actual Cost
 * @param predictedCurve result of calculateOutputs (cumulative)
 * @returns Error value at data date
 */
const calculateError = (customCurve: number[], predictedCurve: number[]) => {
  return Math.abs(customCurve.slice(-1)[0] - predictedCurve[customCurve.length - 1])
}
/**
 * Converts a periodic value array to a cumulative one
 * @param periodicValues array of periodic number
 */
export const calculateCumulativeFromPeriodic = (periodicValues: number[]) => {
  return periodicValues.reduce((acc: string[], value, index) => {
    if (index === 0) {
      acc.push(value.toString())
    } else {
      const previousCumulativeValue = acc[index - 1]
      const cumulativeValue = +previousCumulativeValue + +value
      acc.push(cumulativeValue.toString())
    }
    return acc
  }, [])
}

export const dampenResults = (
  customCurve: number[],
  result: ReturnType<typeof calculateOutputs>
) => {
  const initialError =
    customCurve[customCurve.length - 1] - result.tableData[1][customCurve.length - 1]
  for (let i = customCurve.length; i < result.tableData[2].length; i++) {
    result.tableData[2][i] = (
      result.tableData[2][i] -
      initialError / (result.tableData[2].length - customCurve.length)
    ).toString()
  }
  result.tableData[1] = calculateCumulativeFromPeriodic(result.tableData[2])
  result.tableData[1][result.tableData[1].length - 1] = '100'
  return result
}

export const dampenProportion = (
  customCurve: number[],
  result: ReturnType<typeof calculateOutputs>
) => {
  const initialError =
    customCurve[customCurve.length - 1] - result.tableData[1][customCurve.length - 1]
  let totalForecast = 0
  for (let i = customCurve.length; i < result.tableData[2].length; i++)
    totalForecast += +result.tableData[2][i]
  const newPeriodic = result.tableData[2].map((item, index) =>
    index >= customCurve.length ? (+item * (1 - initialError / totalForecast)).toString() : item
  )
  newPeriodic[customCurve.length - 1] = (
    +newPeriodic[customCurve.length - 1] + initialError
  ).toString()
  const newCumulative = calculateCumulativeFromPeriodic(newPeriodic)
  return {
    ...result,
    tableData: [result.tableData[0], newCumulative, newPeriodic],
  }
}

export const dampenSigmoidProportion = (
  customCurve: number[],
  result: ReturnType<typeof calculateOutputs>
) => {
  const initialError =
    result.tableData[1][customCurve.length - 1] - customCurve[customCurve.length - 1]
  let totalForecast = 0
  for (let i = customCurve.length; i < result.tableData[2].length; i++)
    totalForecast += +result.tableData[2][i]
  const newPeriodic = result.tableData[2].map((item, index) =>
    index >= customCurve.length ? (+item * (1 + initialError / totalForecast)).toString() : item
  )
  newPeriodic[customCurve.length - 1] = (
    +newPeriodic[customCurve.length - 1] - initialError
  ).toString()
  const newCumulative = calculateCumulativeFromPeriodic(newPeriodic)
  return {
    ...result,
    tableData: [result.tableData[0], newCumulative, newPeriodic],
  }
}

export const dampenToCompleteProportion = (initialValue: number, result: {tableData: any[][]}) => {
  const initialError = result.tableData[1][0] - initialValue
  let totalForecast = 0
  for (let i = 1; i < result.tableData[2].length; i++) totalForecast += +result.tableData[2][i]
  const newPeriodic = result.tableData[2].map((item, index) =>
    index === 0 ? initialValue : +item * (1 + initialError / totalForecast)
  )
  const newCumulative = calculateCumulativeFromPeriodic(newPeriodic)
  newCumulative[newCumulative.length - 1] = '100'
  return {
    ...result,
    tableData: [
      result.tableData[0],
      newCumulative,
      getPeriodicFromCumulative(newCumulative).map((item) => item + ''),
    ],
  }
}

export const trimExtension = (
  customCurve: number[],
  predicted: ReturnType<typeof calculateOutputs>,
  threshold = 5,
  customMaxPeriodic?: number
) => {
  if (customCurve.length >= predicted.tableData[1].length) return predicted
  if (customCurve.at(-1) === 100) return predicted
  const maxPeriodic = Math.max(
    predicted.tableData[2].reduce((acc, value) => (value > acc ? value : acc), 0),
    customMaxPeriodic || 0
  )
  let lastIndex = predicted.tableData[2].findIndex(
    (item) => +item < (maxPeriodic * threshold) / 100
  )
  if (!lastIndex) return predicted
  if (lastIndex <= customCurve.length - 1) return predicted
  const error = 100 - predicted.tableData[1][lastIndex]
  const totalDivision = predicted.tableData[1][lastIndex - 1]
  let newPeriodic = predicted.tableData[2]
    .map((item, index) => (index <= lastIndex ? +item * (1 + error / totalDivision) : undefined))
    .filter((item) => item)
  const newCumulative = calculateCumulativeFromPeriodic(newPeriodic as number[])
  newCumulative[newCumulative.length - 1] = '100'
  newPeriodic = getPeriodicFromCumulative(newCumulative)
  return {
    ...predicted,
    estimatedEndDate: moment(predicted.tableData[0][0]).add(newCumulative.length, 'months'),
    nbOfRemainingMonths: newCumulative.length - customCurve.length,
    tableData: [
      predicted.tableData[0].slice(0, lastIndex + 1),
      newCumulative.map((item) => item + ''),
      newPeriodic.map((item) => item + ''),
    ],
  }
}

export const trimCalendarExtension: any = (predicted: ReturnType<typeof calculateOutputs>) => {
  if (predicted.tableData[2].at(-1) < 0.05)
    return trimCalendarExtension({
      ...predicted,
      tableData: [
        predicted.tableData[0].slice(0, -1),
        predicted.tableData[1].slice(0, -1),
        predicted.tableData[2].slice(0, -1),
      ],
      highlightIdx: predicted.highlightIdx,
      isInitialState: predicted.isInitialState,
      nbOfRemainingMonths: predicted.nbOfRemainingMonths - 1,
    })
  return predicted
}
