import {DataNode} from 'antd/es/tree'

export const updateTreeData = (list: DataNode[], key: string, children: DataNode[]): DataNode[] =>
  list.map((node) => {
    if (node.key === key) return {...node, children}
    if (node.children) return {...node, children: updateTreeData(node.children, key, children)}
    return node
  })

export const appendTreeData = (list: DataNode[], key: string, children: DataNode[]): DataNode[] =>
  list.map((node) => {
    if (node.key === key)
      return {
        ...node,
        children: node.children ? [...node.children, ...children] : children,
        switcherIcon: undefined,
      }
    if (node.children) return {...node, children: appendTreeData(node.children, key, children)}

    return node
  })

export const deleteTreeNode = (list: DataNode[], keyToDelete: string): DataNode[] => {
  return list.filter((node) => {
    if (node.key === keyToDelete) {
      // Filter out the node if its key matches the key to delete
      return false
    }

    if (node.children) {
      // Recursively call deleteTreeNode on the children
      const updatedChildren = deleteTreeNode(node.children, keyToDelete)

      if (updatedChildren.length > 0) {
        // If there are updated children, include the node with updated children
        node.children = updatedChildren
      } else {
        // If there are no updated children, remove the children property
        delete node.children
      }
    }

    // Include the node in the filtered result
    return true
  })
}

export const findNodeById = (origin: DataNode[], idToFind: string): DataNode | null => {
  for (const node of origin) {
    if (node.key === idToFind) {
      return node // Return the node if the ID matches
    }

    if (node.children) {
      const foundNode = findNodeById(node.children, idToFind)
      if (foundNode) {
        return foundNode // If found in children, return that node
      }
    }
  }

  return null // Return null if the ID is not found in the tree
}
