import {useQuery} from 'react-query'
import {getCurrentSubscription} from '../../profile/components/billing/_requests'
import {Team, TeamRequest} from './_models'
import {getTeam, getTeamRequests} from './_requests'

export const useTeam = (onSuccessCallback: (data: Team) => void, enabled: boolean = true) => {
  return useQuery(['team'], () => getTeam(), {
    onSuccess: onSuccessCallback,
    refetchOnWindowFocus: false,
    enabled: enabled,
  })
}
export const useTeamRequests = (onSuccessCallback: (data: TeamRequest[]) => void) => {
  return useQuery(['teamRequests'], () => getTeamRequests(), {
    onSuccess: onSuccessCallback,
    refetchOnWindowFocus: false,
  })
}
export const useCurrentSubscription = (onSuccessCallback: (data: any) => void) => {
  return useQuery(['currentSubscription'], () => getCurrentSubscription(), {
    onSuccess: onSuccessCallback,
    refetchOnWindowFocus: false,
  })
}
