import axios, {AxiosResponse} from 'axios'
import {Calendar, CalendarPagination, CreateCalendar, Pagination} from './_models'
import {Response} from '../../../_metronic/helpers'

const API_URL_CALENDARS = `${process.env.REACT_APP_API_URL}/calendars`
const API_URL_CALENDAR = `${process.env.REACT_APP_API_URL}/calendars`

const getCalendars = (calendarData: CalendarPagination) =>
  axios
    .get(`${API_URL_CALENDARS}?page=${calendarData.page}&per_page=${calendarData.per_page}`)
    .then((res: AxiosResponse<Pagination<Calendar>>) => res.data)

const getCalendar = (calendarId: string) =>
  axios.get(`${API_URL_CALENDAR}/${calendarId}`).then((res: AxiosResponse<Calendar>) => res.data)

const addCalendar = (calendar: CreateCalendar) =>
  axios.post(API_URL_CALENDARS, calendar).then((res: AxiosResponse<Calendar>) => res.data)

const patchCalendar = (calendar: Partial<Calendar>, calendarId: string) =>
  axios
    .patch(`${API_URL_CALENDAR}/${calendarId}`, calendar)
    .then((res: AxiosResponse<Calendar>) => res.data)

const deleteCalendar = (calendarId: string) =>
  axios
    .delete(`${API_URL_CALENDAR}/${calendarId}`)
    .then((response: AxiosResponse<Response<any>>) => response)
    .then((response: Response<any>) => response)

const sendCalendar = (calendarId: string, email: string) =>
  axios.post(`${API_URL_CALENDAR}/${calendarId}`, {email})
export {getCalendars, getCalendar, addCalendar, patchCalendar, deleteCalendar, sendCalendar}
