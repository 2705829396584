import { Card } from 'react-bootstrap'
import { KTSVG } from '../../../../../_metronic/helpers'
import { Dispatch, SetStateAction, useEffect } from 'react'
import clsx from 'clsx'
import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import WpData from '../../../wp/components/WpData'
import { Wbs } from '../../_models'
import { getVersionById } from '../../../projects/core/_requests'
import { getWbs } from '../../_requests'
import WbsData from '../../../wp/components/WbsData'
import { DataNode } from 'antd/es/tree'
import TreeFolderItem from '../TreeView/TreeFolderItem'
import { FolderNode } from './HierarchyView'
import { useDispatch, useSelector } from 'react-redux';
import { addWbsDataToStore, addWbsToStore } from '../../treedata';
import { RootState } from '../../../../store'
import { useProject } from '../../../projects/core/ProjectContext'
import { combineVersionsV2 } from '../TreeView/_helpers'


type Props = {
  allExpanded: boolean
  allCollapsed: boolean
  node: FolderNode
  setExpanded: Dispatch<SetStateAction<boolean>>
  expanded: boolean
  resetView: () => void
  resetExpanded: () => void
}


interface TreeNodeProps {
  folder: {
    _id: { $oid: string };
    name: string;
    parent_wbs?: { $oid: string };
    data: { $oid: string };
    subwbs: any;
  };
}





export default function HierarchyItem({
  node,
  setExpanded,
  expanded,
  resetView,
  resetExpanded,
}: Props) {
  const [showWp, setShowWp] = useState(false);
  const [showWbs, setShowWbs] = useState(false)
  const { setWbsDataDateFirstStepAsync, setWbsDataDateSecondStepAsync } = useProject();

  const [versionId, setVersionId] = useState<string>("")
  const [wbs, setWbs] = useState<any | null>(null);
  const [wbsData, setWbsData] = useState<any | null>(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const knownWbs = useSelector((state: RootState) => state.treedata.knownWbs);
  const projectversiondata = useSelector((state: RootState) => state.versiondata.data)
  const handleClose = () => setShowWp(false)
  const handleWbsClose = () => setShowWbs(false)

  // Fetch WBS data from Redux or API
  useEffect(() => {
    const fetchWbs = async () => {
      try {
        let response = knownWbs[node.id];
        if (!response) {
          response = await getWbs(node.id);
          dispatch(addWbsToStore(response));
        }
        setWbs(response);
      } catch (err) {
        console.log('Failed to fetch WBS data.');
      }
    };

    fetchWbs();
  }, [node.id, knownWbs, dispatch]);




  const loadData = async (childWbs: Wbs) => {
    const childData: DataNode[] = await Promise.all(childWbs.subwbs.map(async (item) => {
      let response = knownWbs[item.$oid];
      if (!response) {
        response = await getWbs(item.$oid);
        dispatch(addWbsToStore(response));
      }

      return {
        key: response._id.$oid,
        name: response.name,
        title: <TreeFolderItem folder={response} />,
        switcherIcon: response.subwbs.length ? undefined : <></>,
      };
    }));
    return childData;
  };


  const collectLeafData = async (nodesMap: Map<string, DataNode>): Promise<any[]> => {
    const leafData: any[] = [];
    const processedNodes = new Set<string>();

    const traverse = async (currentNodeKey: string) => {
      if (processedNodes.has(currentNodeKey)) return;
      processedNodes.add(currentNodeKey);

      const currentNode = nodesMap.get(currentNodeKey);
      if (!currentNode) return;

      const titleElement = currentNode.title as React.ReactElement<TreeNodeProps>;
      if (titleElement && titleElement.props && titleElement.props.folder) {
        if (!titleElement.props.folder.subwbs || titleElement.props.folder.subwbs.length === 0) {
          const versionData = await getVersionById(titleElement.props.folder.data.$oid);
          leafData.push(versionData);
        } else {
          for (const child of titleElement.props.folder.subwbs) {
            if (!nodesMap.has(child.$oid)) {

              const childWbsData = knownWbs[child.$oid] || await getWbs(child.$oid);
              if (!knownWbs[child.$oid]) {
                dispatch(addWbsToStore(childWbsData));
              }

              const childNode: DataNode = {
                key: childWbsData._id.$oid,
                title: <TreeFolderItem folder={childWbsData} />,
              };
              nodesMap.set(child.$oid, childNode);
            }
            await traverse(child.$oid);
          }
        }
      }
    };

    // Traverse all nodes in the map
    for (const nodeKey of nodesMap.keys()) {
      await traverse(nodeKey);
    }

    return leafData;
  };


  const combineVersions = (versions: any[]): any => {
    if (versions.length === 0) {
      return {};
    }

    if (versions.length === 1) {
      return versions[0];
    }

    let combinedVersion: any = { ...versions[0] };

    combinedVersion.start_date = { $date: new Date(Math.min(...versions.map((v) => new Date(v.start_date.$date).getTime()))) };
    combinedVersion.end_date = { $date: new Date(Math.max(...versions.map((v) => new Date(v.end_date.$date).getTime()))) };
    combinedVersion.data_date = { $date: new Date(Math.min(...versions.map((v) => new Date(v.data_date.$date).getTime()))) };

    combinedVersion.reserve = {
      _cls: versions[0].reserve._cls,
      amount: versions.reduce((sum, v) => sum + v.reserve.amount, 0),
      unit: versions[0].reserve.unit,
    };

    combinedVersion.delay_tolerance = {
      type: versions[0].delay_tolerance.type,
      count: versions.reduce((sum, v) => sum + v.delay_tolerance.count, 0),
    };

    combinedVersion.budget_at_completion = {
      _cls: versions[0].budget_at_completion._cls,
      amount: versions.reduce((sum, v) => sum + v.budget_at_completion.amount, 0),
      unit: versions[0].budget_at_completion.unit,
      currency: versions[0].budget_at_completion.currency,
    };

    combinedVersion.total_hours = versions.reduce((sum, v) => sum + (v.total_hours || 0), 0);
    combinedVersion.achieved_percentage = versions.reduce((sum, v) => sum + (v.achieved_percentage || 0), 0) / versions.length;

    const combineValues = (fieldName: string) => {
      const maxLength = Math.max(...versions.map(v => {
        const field = v.output[fieldName]?.original?.cumulative || [];
        return field.length;
      }));

      const combinedCumulative = Array.from({ length: maxLength }, (_, index) =>
        versions.reduce((sum, version) => {
          const values = version.output[fieldName]?.original?.cumulative || [];
          return sum + (values[index] ? parseFloat(values[index]) : 0);
        }, 0)
      );

      const combinedPeriodic = Array.from({ length: maxLength }, (_, index) =>
        versions.reduce((sum, version) => {
          const values = version.output[fieldName]?.original?.periodic || [];
          return sum + (values[index] ? parseFloat(values[index]) : 0);
        }, 0)
      );

      return { cumulative: combinedCumulative, periodic: combinedPeriodic };
    };

    const combinedPlannedValue = combineValues('plannedValues');
    const combinedEarnedValue = combineValues('earnedValues');
    const combinedActualCost = combineValues('actualCosts');

    combinedVersion.output = {
      ...combinedVersion.output,
      plannedValues: {
        original: combinedPlannedValue,
        mixed: combinedPlannedValue,
        forecast: combinedPlannedValue,
      },
      earnedValues: {
        original: combinedEarnedValue,
        mixed: combinedEarnedValue,
        forecast: combinedEarnedValue,
      },
      actualCosts: {
        original: combinedActualCost,
        mixed: combinedActualCost,
        forecast: combinedActualCost,
      },
    };

    const combineCustomCurves = (curveName: string) => {
      const maxLength = Math.max(...versions.map(v => {
        const curve = v.custom_curve.find((c: any) => c.name === curveName);
        return curve ? curve.values.length : 0;
      }));

      return Array.from({ length: maxLength }, (_, index) =>
        versions.reduce((sum, version) => {
          const curve = version.custom_curve.find((c: any) => c.name === curveName);
          return sum + (curve && curve.values[index] ? curve.values[index] : 0);
        }, 0)
      );
    };

    combinedVersion.custom_curve = [
      {
        name: 'cumulativePlannedValue',
        color: 'black',
        values: combineCustomCurves('cumulativePlannedValue'),
      },
      {
        name: 'cumulativeEarnedValue',
        color: 'white',
        values: combineCustomCurves('cumulativeEarnedValue'),
      },
      {
        name: 'cumulativeActualCost',
        color: 'red',
        values: combineCustomCurves('cumulativeActualCost'),
      },
    ];

    return combinedVersion;
  };




  const handleClickwp = async (event: React.MouseEvent, node: FolderNode) => {

    console.log("node.children : ", node.children)
    console.log("node.children.length : ", node.children?.length)

    let response = knownWbs[node.id];
    if (!response) {
      response = await getWbs(node.id);
      dispatch(addWbsToStore(response));
    }
    setVersionId(response?.data.$oid)

    setShowWp(true)
  }




  const handleClickwbs = async (event: React.MouseEvent, node: FolderNode) => {
    setLoading(true);
    try {
      const treeData = await loadData(wbs);
      const nodesMap = new Map<string, DataNode>();

      const buildMap = (nodes: DataNode[], map: Map<string, DataNode>) => {
        nodes.forEach((node) => {
          map.set(node.key as string, node);
          if (node.children) {
            buildMap(node.children, map);
          }
        });
      };

      buildMap(treeData, nodesMap);
      console.log(nodesMap);

      const leafData = await collectLeafData(nodesMap);
      console.log('Leaf node data fields: ', leafData);
      let combinedVersion = await combineVersionsV2(knownWbs[node.id].name, leafData, true, setWbsDataDateFirstStepAsync, setWbsDataDateSecondStepAsync);
      const wbsData = projectversiondata[knownWbs[node.id].data.$oid];
      combinedVersion = combinedVersion ? combinedVersion : wbsData;
      setWbsData(combinedVersion);
      setShowWbs(true);
    } catch (error) {
      console.error('Error fetching WBS data:', error);
    } finally {
      setLoading(false);
    }
  };


  return (
    <Card className='border border-2 border-gray-300 border-hover cursor-pointer mb-3'>
      <Card.Body>
        <div
          onClick={node.children?.length === 0 ? (event) => handleClickwp(event, node) : (event) => handleClickwbs(event, node)}
          className='d-flex flex-column align-items-center gap-2'
        >
          {node.children?.length === 0 ?
            <div className='symbol symbol-50px w-50px'>
              <KTSVG
                path='/media/icons/duotune/files/fil003.svg'
                className='svg-icon-muted svg-icon-2hx text-primary'
              /> </div>
            :
            <div className='symbol symbol-50px w-50px'>
              <KTSVG
                path='/media/icons/duotune/files/fil012.svg'
                className='svg-icon-muted svg-icon-2hx text-primary'
              /> </div>
          }
          <h3>{node.label}</h3>
          {!!node.children?.length && (
            <div
              className='position-absolute bottom-0 start-50 z-index-2 gap-2 align-items-center'
              style={{ transform: 'translateX(-50%) translateY(70%)' }}
            >
              <button
                onClick={(e) => {
                  e.stopPropagation()
                  setExpanded((prev) => !prev)
                  setTimeout(() => resetView(), 100)
                  resetExpanded()
                }}
                className='btn btn-icon btn-light p-0 rotate active'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr021.svg'
                  className={clsx('svg-icon-muted svg-icon', {
                    'rotate-270': !expanded,
                    'rotate-90': expanded,
                  })}
                />
              </button>
            </div>
          )}
        </div>
        <Modal size="xl" show={showWp} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>WP Data</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <WpData data={wbsData} setData={setWbsData} />
          </Modal.Body>
          <Modal.Footer>

          </Modal.Footer>
        </Modal>¨

        <Modal size="xl" show={showWbs} onHide={handleWbsClose}>
          <Modal.Header closeButton>
            <Modal.Title>WBS DATA</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <WbsData wbsData={wbsData} />
          </Modal.Body>
        </Modal>

      </Card.Body>
    </Card>

  )
}
