import {getSubscriptionStatus} from '../app/helpers/func'
import {UserModel} from '../app/modules/auth'

const useDisabledActions = (currentUser: UserModel | undefined) => {
  const checkIfActionsDisabled = (): any => {
    if (!currentUser) return true
    const subscriptionStatus = getSubscriptionStatus(currentUser)

    switch (subscriptionStatus) {
      case 'Trial mode':
      case 'Subscription is active but will expire in less than 14 days':
      case 'Auto-renew deactivated':
        return false
      case 'Free Plan':
      case 'Trial mode ended with no active subscription':
      case 'Subscription ended':
        return true
    }
  }

  const areActionsDisabled = checkIfActionsDisabled()

  return {
    areActionsDisabled,
    handleActionsDisabled: () => {
      //       sweetAlert({
      //         title: 'Subscription required',
      //         text: 'This action cannot be performed without an active subscription.',
      //         icon: 'error',
      //       })
    },
  }
}

export default useDisabledActions
