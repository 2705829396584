import { DataNode } from 'antd/es/tree';
import { Wbs } from '../../_models';
import { getWbs } from '../../_requests';
import { useTree } from './TreeContext';
import TreeFolderItem from './TreeFolderItem';
import { KTSVG } from '../../../../../_metronic/helpers';
import { useProject } from '../../../projects/core/ProjectContext';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { addWbsToStore } from '../../treedata';

interface Props {
  isShare?: boolean
  setLoadTreeCompleted?: React.Dispatch<React.SetStateAction<boolean>>
}
export default function TreeViewHeader({ isShare = false, setLoadTreeCompleted }: Props) {
  const { setExpandedKeys, setTreeData } = useTree();
  const { project } = useProject();
  const dispatch = useDispatch();
  const knownWbs = useSelector((state: RootState) => state.treedata.knownWbs);


  useEffect(() => {

    loadExpandAll();
    if (setLoadTreeCompleted)
      setLoadTreeCompleted(true);

  }, []);

  const loadExpandAll = async () => {
    const allKeys: string[] = [];

    const fetchWbsRecursively = async (wbsId: string): Promise<DataNode> => {
      let knownWbsItem = knownWbs[wbsId];
      if (!knownWbsItem) {
        const response = await getWbs(wbsId);
        dispatch(addWbsToStore(response));
        knownWbsItem = response;
      }

      allKeys.push(knownWbsItem._id.$oid);

      const childrenData: DataNode[] = await Promise.all(
        knownWbsItem.subwbs.map(async (subWbs) => {
          return await fetchWbsRecursively(subWbs.$oid);
        })
      );

      return {
        key: knownWbsItem._id.$oid,
        title: <TreeFolderItem folder={knownWbsItem} isShare={isShare} />,
        children: childrenData,
      };
    };

    if (project?.current_wbs) {
      const rootNode = await fetchWbsRecursively(project.current_wbs);
      const updatedTreeData = updateTreeTitles([rootNode]);
      setTreeData(updatedTreeData);
      setExpandedKeys(allKeys);
    }
  };

  const collapseAll = () => {
    setExpandedKeys([]);
  };




  const generateNodePosition = (node: DataNode, parentPosition: string, index: number): string => {
    return parentPosition ? `${parentPosition}.${index + 1}` : `${index + 1}`;
  };

  const updateTreeTitles = (data: DataNode[], parentPosition = ''): DataNode[] => {
    return data.map((node, index) => {
      const titleElement = node.title as React.ReactElement<{ folder: Wbs }>;
      const nodePosition = generateNodePosition(node, parentPosition, index);
      const folder = { ...titleElement.props.folder, nodePosition: nodePosition };
      return {
        ...node,
        title: <TreeFolderItem folder={folder} />,
        children: node.children ? updateTreeTitles(node.children, nodePosition) : [],
      };
    });
  };

  return (
    <header style={{ display: 'flex', flexDirection: 'row' }}>
      <div className='ms-auto d-flex'>
        <button
          className='btn btn-light btn-icon btn-sm me-2 rotate active'
          onClick={collapseAll}
          type='button'
          style={{ transition: 'all 0.2s ease-in-out' }}
        >
          <KTSVG
            path='/media/icons/duotune/arrows/arr021.svg'
            className='svg-icon-muted text-hover-primary svg-icon-1 rotate-90'
          />
        </button>
        <button
          className='btn btn-light btn-icon btn-sm me-2 rotate active'
          onClick={loadExpandAll}
          type='button'
          style={{ transition: 'all 0.2s ease-in-out' }}
        >
          <KTSVG
            path='/media/icons/duotune/arrows/arr021.svg'
            className='svg-icon-muted text-hover-primary svg-icon-1 rotate-n90'
          />
        </button>
      </div>
    </header>
  );
}
