import { FC, useState } from 'react';
import { Col, Container, Modal, Row, Button } from 'react-bootstrap';
import { getFreeTrial } from './users-list/core/_requests';

import './zoom.css';
import Plan from '../../../../_metronic/partials/modals/upgrade-plan/components/PlanComponent';
import { planContents } from '../../../../_metronic/partials/modals/upgrade-plan/contents/PlanContents';

interface Props {
  currentUser: any;
  show: boolean;
  handleClose: () => void;
  sweetAlert?: any;
}

const FreeTrialAdd: FC<Props> = ({ show, handleClose, currentUser, sweetAlert }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const filteredPlans = planContents.filter(plan => plan.header !== 'Free');

  const planSubscription = (plan: string, subscribe: any) => {
    sweetAlert({
      title: `${plan} plan subscription`,
      text:
        plan === 'Free'
          ? 'Are you sure that you want to subscribe to the free plan?'
          : `Are you sure you want a free trial for the ${plan} plan`,
      icon: 'question',
      showCancelButton: true,
    }).then((res: any) => {
      if (res.isConfirmed) {
        setLoading(true);
        subscribe(currentUser.id)
          .then(() => {
            sweetAlert({
              title: 'Success',
              text: 'Congratulations you are now subscribed to our free plan',
              icon: 'success',
            });
          })
          .catch((err: any) => {
            sweetAlert({
              title: 'Error',
              text: err.message || 'Something went wrong',
              icon: 'error',
            });
          })
          .finally(() => setLoading(false));
      }
    });
  };

  const handleButtonClick = (header: string, trial_type: string) => {
    planSubscription(header, getFreeTrial(trial_type));
  };

  const plans = planContents.map((obj, i) => {
    return (
      <Plan
        key={obj.header}
        header={obj.header}
        price={obj.price}
        trial_type={obj.trial_type}
        features={obj.features}
        not_available_features={obj.not_available_features}
        buttonLabel={obj.buttonLabel}
        buttonAction={handleButtonClick}
        outline={obj.outline}
      />
    );
  });

  const handleCloseAndNavigate = () => {
    handleClose();
  };

  const showAlert = () => {
    handleCloseAndNavigate();
    sweetAlert({
      title: 'Trial added Successfully',
      text: 'Alert successful',
      icon: 'success',
      confirmButtonText: 'OK',
    });
  };

  const showError = () => {
    handleCloseAndNavigate();
    sweetAlert({
      title: 'Error',
      text: 'Error',
      icon: 'error',
      confirmButtonText: 'OK',
    });
  };

  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-content rounded modal-content-centered mw-900px'
      show={show}
      onHide={handleCloseAndNavigate}
      backdrop='static'
    >
      <Modal.Header>
        <Button variant='close' onClick={handleCloseAndNavigate} aria-label='Close'>
        </Button>
      </Modal.Header>
      <div className='card'>
        <div className='card-body'>
          <div className='mx-auto mw-600px w-100 pt-15 pb-10' id='kt_create_account_form'>
            <div className='current' data-kt-stepper-element='content'>
              <div className='w-100'>
                <div className='pb-10 pb-lg-15 d-flex justify-content-between align-items-center'>
                  <h2 className='fw-bolder d-flex align-items-center text-dark'>
                    Your current plan does not allow you to use this feature,
                    <br />
                    Please choose a free trial
                  </h2>
                </div>

                <div className='text-gray-400 fw-bold fs-6'>
                  If you need more info, please check out
                  <a href='/dashboard' className='link-primary fw-bolder'>
                    {' '}
                    Help Page
                  </a>
                  .
                </div>
                <br></br>
                <br></br>
                <Container>
                  <Row>
                    {filteredPlans.map((plan, i) => (
                      <Col key={i} className='card-deck col-6 mb-3 text-center'>
                        <Plan 
                          header={plan.header}
                          price={plan.price}
                          trial_type={plan.trial_type}
                          features={plan.features}
                          not_available_features={plan.not_available_features}
                          outline={plan.outline}
                          buttonLabel={plan.buttonLabel}
                          buttonAction={handleButtonClick}
                        />
                      </Col>
                    ))}
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FreeTrialAdd;