import {CurrentSubscriptionModel} from '../../../auth'
import {Team} from '../_models'

export const getCurrentLimit = (
  type: 'slot-simulation' | 'slot-project',
  memberId: string,
  team: Team | undefined
) => {
  if (!team || !memberId) return 0

  const slot = team.slots.find((slot: any) => slot.key === type)
  const usage = slot?.usages_values.find((usage) => usage.user.$oid === memberId)
  return usage?.limit || 0
}

export const getCurrentUsed = (
  type: 'slot-simulation' | 'slot-project',
  memberId: string,
  team: Team | undefined
) => {
  if (!team || !memberId) return 0

  const slot = team.slots.find((slot: any) => slot.key === type)
  const usage = slot?.usages_values.find((usage) => usage.user.$oid === memberId)
  return usage?.usage || 0
}

export const getCurrentUsedFromUser = (
  type: 'slot-simulation' | 'slot-project',
  memberId: string | undefined,
  subscription: CurrentSubscriptionModel | undefined
) => {
  if (!subscription || !memberId) return 0

  const slot = subscription.slots.find((slot: any) => slot.key === type)
  const usage = slot?.usages_values.find((usage: any) => usage.user.$oid === memberId)
  return usage?.usage || 0
}
