import {Calendar} from '../../modules/calendars/_models'
import {getCustomCurve} from '../../modules/projects/components/overview/Project'
import {standardizeCurve} from './baseline-fitting'
import {calculateCumulativeFromPeriodic, dampenSigmoidProportion} from '../../helpers/optimizers'
import moment from 'moment'
import {getPeriodicFromCumulative} from '../../modules/projects/components/data/prepareDataTable'
import {calculateSigmoidOutputs} from '../../modules/library/ProjectCurve/core'
import {
  applyCalendarToCurve,
  getCapacity,
  getReelDays,
} from '../../modules/library/ProjectCurve/Context/ProjectCurveHelpers'

const forecastMaxFte = async (
  inputs: any,
  data: any,
  cumulativeEarnedValuesAsPercentages: any,
  calendar?: Calendar
): Promise<{
  tableData: (string[] | moment.Moment[])[]
  nbOfRemainingMonths: number
}> => {
  const cumulativePlannedValuesAsPercentages = getCustomCurve('cumulativePlannedValue', data).map(
    (item) => (+item / data.budget_at_completion.amount) * 100
  )
  if (!data.total_hours || !data.full_capacity || !data?.forecast_settings?.max_capacity)
    return {tableData: [], nbOfRemainingMonths: 0}
  if (data.curve_settings?.tangent && data.curve_settings?.offset) {
    inputs.mu = data.curve_settings?.tangent
    inputs.sig = data.curve_settings?.offset
  } else {
    const {axis, tangent} = await standardizeCurve({
      associated_calendar: calendar,
      color: '',
      duration: cumulativePlannedValuesAsPercentages.length,
      name: 'test',
      start_date: new Date(data.start_date.$date),
      total: 100,
      values: cumulativePlannedValuesAsPercentages,
      unit: 'currency',
    })
    inputs.mu = tangent
    inputs.sig = axis
  }
  let i = 0
  let currentMaxCapacity = Infinity
  let latestOutputs = undefined
  while (currentMaxCapacity > data.forecast_settings.max_capacity) {
    i++
    latestOutputs = calculateSigmoidOutputs(
      {
        mu: inputs.mu,
        sig: inputs.sig,
        nbOfMonthsPassed: cumulativeEarnedValuesAsPercentages.length + i,
      },
      false
    )

    if (calendar) {
      const {reelDays, totalDays} = await getReelDays(
        new Date(data.start_date.$date),
        cumulativeEarnedValuesAsPercentages.length + i,
        calendar
      )

      const capacity = getCapacity(reelDays, totalDays)
      latestOutputs = applyCalendarToCurve(capacity, latestOutputs)
    }

    latestOutputs = dampenSigmoidProportion(
      cumulativeEarnedValuesAsPercentages,
      latestOutputs as any
    )

    let forecast = latestOutputs.tableData[2].slice(cumulativeEarnedValuesAsPercentages.length)

    currentMaxCapacity = forecast.reduce(
      (acc, item) => Math.max((item * (data.total_hours / data.full_capacity)) / 100, acc),
      -Infinity
    )
  }
  const fullPeriodic = [
    ...getPeriodicFromCumulative(cumulativeEarnedValuesAsPercentages),
    ...(latestOutputs?.tableData[2].slice(cumulativeEarnedValuesAsPercentages.length) || []),
  ]
  return {
    tableData: [
      Array.from({length: cumulativeEarnedValuesAsPercentages.length + 1}).map((_el, idx) =>
        moment(inputs.inputDataDate).add(idx + 1, 'month')
      ),
      calculateCumulativeFromPeriodic(fullPeriodic).map((item) => item + ''),
      fullPeriodic.map((item) => item + ''),
    ],
    nbOfRemainingMonths: i,
  }
}

export {forecastMaxFte}
