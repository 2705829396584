import React from 'react'
import {Dropdown} from 'react-bootstrap'
import {KTSVG} from '../../../../../_metronic/helpers'
import {Comment} from './models'
import {useProject} from '../../core/ProjectContext'
import {associateActionComment} from '../action-plan/ActionPlanHelpers'
import {ActionPlan, ProjectObject} from '../../core/_models'

type Props = {
  item: Comment
}

export const CustomToggle = React.forwardRef(({children, onClick}: any, ref: any) => (
  <button onClick={onClick} ref={ref} className='btn btn-icon'>
    <KTSVG path='/media/icons/duotune/arrows/arr013.svg' className='svg-icon-1' />
  </button>
))

export default function CommentAssociationDropdown({item}: Props) {
  const {originalVersion: project, setVersion, setOriginalVersion} = useProject()

  const handleAssociate = (action: ActionPlan) => {
    associateActionComment(action?.subject, item?.subject, project)?.then((res) => {
      setVersion((version) =>
        version
          ? ({...version, action_plan: res.action_plan, comments: res.comments} as ProjectObject)
          : undefined
      )
      setOriginalVersion(res)
    })
  }

  const filteredActions = project?.action_plan.filter(
    (action) => !item.associated_actions.includes(action?.subject)
  )
  return (
    <Dropdown className='display-relative'>
      <Dropdown.Toggle as={CustomToggle}></Dropdown.Toggle>
      <Dropdown.Menu className=''>
        {filteredActions && filteredActions.length ? (
          filteredActions.map((item) => (
            <Dropdown.Item onClick={() => handleAssociate(item)} key={item?.subject}>
              {item?.subject}
            </Dropdown.Item>
          ))
        ) : (
          <Dropdown.Item disabled>Empty</Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}
