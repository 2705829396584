/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import LanguagesDropDown from '../../components/customLang'
import Terms from './components/terms'

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')

    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      {/* begin::Body */}
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
        <div className='row'>
          <div className=' col-5 col-md-8 '></div>
          <div className='col-7  col-md-4 '>
            <LanguagesDropDown />
          </div>
        </div>

        {/* begin::Form */}
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          {/* begin::Wrapper */}
          <div className='w-lg-500px p-10'>
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}

        {/* begin::Footer */}
        <div className='d-flex flex-center flex-wrap px-5'>
          {/* begin::Links */}
          <div className='d-flex fw-semibold text-primary fs-base'>
            <span
              data-bs-toggle='modal'
              style={{cursor: 'pointer'}}
              data-bs-target='#kt_modal_1'
              className='px-5'
            >
              Terms
            </span>

            <a href='https://project-curve.com' className='px-5' target='_blank'>
              Plans
            </a>

            <a href='https://project-curve.com' className='px-5' target='_blank'>
              Contact Us
            </a>
          </div>
          {/* end::Links */}
        </div>
        {/* end::Footer */}
      </div>
      {/* end::Body */}

      {/* begin::Aside */}
      <div
        className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2'
        style={{backgroundImage: `url(${toAbsoluteUrl('/media/misc/auth-bg.png')})`}}
      >
        {/* begin::Content */}
        <div className='d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100'>
          {/* begin::Logo d-none d-lg-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2*/}
          <Link to='/' className='mb-0 mb-lg-12'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/custom-1.png')}
              className='h-60px h-lg-75px'
            />
          </Link>
          {/* end::Logo */}

          {/* begin::Image */}
          <img
            className='d-none d-lg-block mx-auto w-275 w-md-50 w-xl-500px mb-10 mb-lg-20'
            src={toAbsoluteUrl('/media/misc/auth-screens.png')}
            alt=''
          />
          {/* end::Image */}

          {/* begin::Title */}
          <h1 className='d-none d-lg-block text-white fs-2qx fw-bolder text-center mb-7'>
            Anticipate to control better
          </h1>
          {/* end::Title */}

          {/* begin::Text */}
          <div className='d-none d-lg-block text-white fs-base text-center'>
            Predicting the time and cost of your project, the time when you take your morning coffee
            or tea, is now possible with PROJECT CURVE. Fast, reliable and simple... Simulate your
            project's timeline in a few clicks!
          </div>
          {/* end::Text */}
        </div>
        {/* end::Content */}
      </div>

      {/* end::Aside */}
      <Terms />
    </div>
  )
}

export {AuthLayout}
