/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'

import {CreateActionPlanForm} from './ActionPlanForm'

type Props = {
  project: any
  show: boolean
  handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const CreateActionPlan = ({project, show, handleClose}: Props) => {
  return createPortal(
    <Modal
      size='lg'
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-content rounded modal-content-centered mw-900px'
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Action</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CreateActionPlanForm project={project} handleClose={handleClose} />
      </Modal.Body>
    </Modal>,
    modalsRoot
  )
}

export {CreateActionPlan}
