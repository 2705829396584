import {useQuery} from 'react-query'
import {getWbs} from './_requests'
import {Wbs} from './_models'

export const useWbs = (
  wbsId?: string,
  onSuccess?: (data: Wbs) => void,
  onError?: any,
  queryKey?: string
) =>
  useQuery(['wbsId', queryKey || wbsId], () => getWbs(wbsId), {
    onError,
    onSuccess,
    refetchOnWindowFocus: false,
  })
