import React from 'react'
import { Modal } from 'react-bootstrap'
import SharedWbsTree from './SharedWbsTree'
type Props={
    showtree:boolean
    onClose: () => void
    comment:any
}
export default function TreeModal({showtree,onClose,comment}:Props) {

  return (
    <Modal onHide={onClose} size='lg' show={showtree}>
      <Modal.Header closeButton={true}>
        <Modal.Title>Add Comment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SharedWbsTree handleClose={onClose} comment={comment} />
      </Modal.Body>
    </Modal>
  )
}
