import {dampenProportion, trimCalendarExtension} from '../../helpers/optimizers'
import {Calendar} from '../../modules/calendars/_models'
import {
  getCapacity,
  getReelDays,
} from '../../modules/library/ProjectCurve/Context/ProjectCurveHelpers'
import {
  calculateCalendarOutputs,
  calculateEstimatedCalendarOutputs,
} from '../../modules/library/ProjectCurve/core'
import {getPeriodicFromCumulative} from '../../modules/projects/components/data/prepareDataTable'
import {getDiffBetweenTwoDates} from '../forecast'

const forecastEvFitting = async (
  inputs: any,
  data: any,
  cumulativeEarnedValuesAsPercentages: any,
  calendar?: Calendar,
  skipTrim?: boolean
) => {


  let capacity: number[] | undefined = undefined

  if (calendar) {
    const {reelDays, totalDays} = await getReelDays(new Date(data.start_date.$date), 80, calendar)
    capacity = getCapacity(reelDays, totalDays)
  }
  const cols = cumulativeEarnedValuesAsPercentages
  const {axis, tg}: any = await calculateEstimatedCalendarOutputs(
    {
      start_date: data.start_date,
      isCalculatingRemainingTodo: false,
      donePercentage: inputs.donePercentage,
      projectDurationUnit: inputs.projectDurationUnit,
      nbOfMonthsPassed:
        getDiffBetweenTwoDates(
          data.start_date.$date,
          data.data_date.$date,
          inputs.projectDurationUnit
        ) + 1,
    },
    {cols},
    capacity
  )
 
  inputs.mu = tg
  inputs.sig = axis

  let EarnedValue: any = calculateCalendarOutputs(
    {
      ...inputs,
      nbOfMonthsPassed: inputs.nbOfTimeUnitPassed + 1,
      nbOfTimeUnitPassed: inputs.nbOfTimeUnitPassed + 1,
    },
    false,
    capacity,
    true && !skipTrim,
    getPeriodicFromCumulative(cumulativeEarnedValuesAsPercentages).reduce(
      (acc, value) => (+value > +acc ? +value : +acc),
      0
    )
  )
  // let threshold: number = 5

  // EarnedValue = trimExtension(cumulativeEarnedValuesAsPercentages, EarnedValue, threshold)

  // if (calendar) {
  //   EarnedValue = {
  //     estimatedEndDate: moment((EarnedValue as any).estimatedEndDate) as any,
  //     ...applyCalendarToCurve(capacity, EarnedValue),
  //   }
  //   EarnedValue.tableData[0] = EarnedValue.tableData[0].map((item: any) => moment(item))
  // }

  if (capacity) EarnedValue = trimCalendarExtension(EarnedValue)

  EarnedValue = dampenProportion(cumulativeEarnedValuesAsPercentages, EarnedValue)

  return EarnedValue
}

export {forecastEvFitting}
