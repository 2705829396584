import _ from 'lodash'
import {useState} from 'react'
import {Dropdown} from 'react-bootstrap'
import {useNavigate, useParams} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {convertDate, getCreatedAtDate} from '../../../../helpers/func'
import {sweetAlert} from '../../../../utils/funcs'
import {CustomToggle} from '../../../projects/core/ProjectHelpers'
import {deleteSimulation} from '../../core/_requests'
import {useSimulationContext} from '../SimulationContext'
import SendSimulationModal from '../modals/SendSimulationModal'

type Props = {}

export default function SimulationHeader({}: Props) {
  const {formik} = useSimulationContext()
  const [loading, setLoading] = useState<boolean>(false)
  const [showSendModal, setShowSendModal] = useState<boolean>(false)
  const {id} = useParams()
  const navigate = useNavigate()

  const handleDeleteSimulation = () => {
    setLoading(true)
    deleteSimulation(formik?.values?._id?.$oid || '')
      .catch((err) =>
        sweetAlert({
          title: 'Error',
          text: err.message || 'Something went wrong!',
          icon: 'error',
        })
      )
      .finally(() => setLoading(false))
  }

  const showConfirm = () => {
    sweetAlert({
      title: 'Are you sure?',
      text: 'You will not be able to recover this simulation!',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      showLoaderOnConfirm: true,
      icon: 'warning',
      preConfirm: () => handleDeleteSimulation(),
      allowOutsideClick: () => !loading,
    }).then((res) => {
      if (res.isConfirmed) {
        sweetAlert({
          title: 'Deleted!',
          text: 'Simulation has been deleted.',
          icon: 'success',
        }).then(() => navigate('/simulations'))
      }
    })
  }
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='me-7 mb-4'>
            <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
              <img src={toAbsoluteUrl('/media/logos/default-small.svg')} alt='Metronic' />
            </div>
          </div>

          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                    {formik?.values.name}
                  </a>
                </div>

                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  <a
                    href='#'
                    className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                  >
                    <KTSVG
                      path='/media/icons/duotune/communication/com006.svg'
                      className='svg-icon-4 me-1'
                    />
                    {formik?.values.description}
                  </a>
                </div>
              </div>
            </div>

            <div className='d-flex flex-wrap flex-stack'>
              <div className='d-flex flex-column flex-grow-1 pe-8'>
                <div className='d-flex flex-wrap'>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <div className='fs-6 text-gray-800 fw-bolder'>
                        {convertDate(new Date(formik?.values.updated_at.$date))}
                      </div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>Last Update</div>
                  </div>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <div className='fs-6 text-gray-800 fw-bolder'>
                        {getCreatedAtDate(id || '').toString()}
                      </div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>Creation date</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex gap-1'>
            <div>
              <button
                disabled={!formik?.isValid || _.isEqual(formik?.values, formik?.initialValues)}
                className='btn btn-sm btn-primary me-3'
                onClick={() => {
                  setLoading(true)
                  formik?.submitForm().finally(() => setLoading(false))
                }}
              >
                {loading ? (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    <span className='spinner-border spinner-border-sm align-middle' />
                  </span>
                ) : (
                  'Save'
                )}
              </button>
            </div>
            <Dropdown>
              <Dropdown.Toggle as={CustomToggle}></Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={showConfirm}>Delete Simulation</Dropdown.Item>
                <Dropdown.Item onClick={() => setShowSendModal(true)}>
                  Send Simulation
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
      <SendSimulationModal
        show={showSendModal}
        onHide={() => setShowSendModal(false)}
        simulationId={formik?.values?._id?.$oid}
      />
    </div>
  )
}
