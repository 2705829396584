import WbsContainer from '../../../../../app/modules/wbs_structure/components/WbsContainer'
import { StepProps } from '../IProjectModels'

const Step4 = ({ formik, currentStep }: StepProps) => {
  return (
    <>
      {/*begin::Step Structure */}
      <div className='pb-5' data-kt-stepper-element='content'>
        <div className='w-100'>
          <WbsContainer currentStep={currentStep} />
        </div>
      </div>
      {/*end::Step Structure */}
    </>
  )
}

export { Step4 }
