import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { PageTitle as PageBreadCrumbs } from '../../../_metronic/layout/components/toolbar/page-title/PageTitle'
import ProjectsWithFolders from './core/ProjectsWithFolders/ProjectsWithFolders'
import ShowLoadingModal from './components/pagination/header/ShowLoadingModal'

function Index() {
  const intl = useIntl()

  return (
    <>
      <div className='row mt-5'>
        <ShowLoadingModal />
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'PROJECTS.MY_PROJECTS' })}</PageTitle>
        <PageBreadCrumbs />
        {/* <div className='d-flex justify-content-between mt-5'>
          Search Project Waiting on api update
          <div className='d-flex align-items-center position-relative my-1'>
            <KTSVG
              path='/media/icons/duotune/general/gen021.svg'
              className='svg-icon-1 position-absolute ms-6'
            />
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control form-control w-250px ps-14'
              placeholder='Search project'
            />
          </div>
          <div></div>
          <ToolbarProjectCustom
            filter={filter}
            setFilter={setFilter}
            showCreateAppModalExternal={showCreateAppModal}
            setShowCreateAppModalExternal={setShowCreateAppModal}
          />
        </div> */}
        <ProjectsWithFolders />
      </div>
    </>
  )
}

export default Index
