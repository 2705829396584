import { Outlet, useParams } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { ProjectShareDetailsHeader } from './components/pagination/header/ProjectShareDetailsHeader'
import { useAuth } from '../auth'
import { useProject } from './core/ProjectContext'
import { useEffect, useState } from 'react'
import { Error405 } from '../errors/components/Error405'
import { getProtectedSnapshotById, getSnapshotById } from './core/_requests'
import { PasswordPage } from './PasswordPage'
import { Overview } from './components/overview/Overview'
import { useDispatch } from 'react-redux'
import { setVersionData } from '../wbs/treeVersionData'
import { addWbsToStore } from '../wbs/treedata'
import { TreeProvider } from '../wbs/components/TreeView/SnapshotShareTreeContext'

const projectDetailsBreadCrumbs: Array<PageLink> = [
  {
    title: 'Project',
    path: '/projects/overview',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ProjectSnapshotSharePage = () => {
  const dispatch = useDispatch()

  const {
    setProject,
    setOriginalVersion,
    displayVersion,
    project,
  } = useProject()
  const { id } = useParams()
  const { areActionsDisabled } = useAuth()
  const [error405, setError405] = useState<boolean>(false)
  const [showPasswordPage, setShowPasswordPage] = useState<boolean>(false)

  useEffect(() => {
    if (id) {
      //Navigate to snapshot page
      getSnapshotById(id).then((snapshot: any) => {
        const newData = snapshot.project
        // Set the project data from the snapshot
        setProject({
          ...newData,
          name: snapshot.snapshot_name ? snapshot.snapshot_name : newData.name,
          widget_order: snapshot?.widget_order,
          share_wbs: snapshot.share_wbs,
          current_wbs: snapshot.share_wbs.$oid,
          generated_wbs_list: snapshot.list_wbs_checked,
          checked_wbs: snapshot.list_wbs_checked.map((wbs: any) => wbs._id),
          generated_wbs_data: snapshot.list_wbs_data,

        })
        // This function help with displaying the snapshot data in the overview
        setOriginalVersion(snapshot.list_wbs_data[0])
        if (snapshot.list_wbs_data && snapshot.list_wbs_data.length > 0)
          snapshot.list_wbs_data.map((item: any) => {
            dispatch(setVersionData({ id: item._id.$oid, data: item }));
          })
  
        if (snapshot.list_wbs_checked  &&snapshot.list_wbs_checked.length > 0)
          snapshot.list_wbs_checked.map((item: any) => {
            dispatch(addWbsToStore(item));
          })
      }, (err: any) => {
        // Navigate to error page if the error is 405
        if (err?.response?.status === 405) {
          setError405(true)
          // If there is a password, the password page is shown.
        } else if (err?.response?.status === 400) {
          setShowPasswordPage(true)
        }
      }
      )
    }
  }, [id]);

  // Handle the password submit for the password page
  const onSubmitPassword = (password: string) => {

    if (id) {
      return getProtectedSnapshotById(id, password)
        .then(snapshot => {
          const newData = snapshot.project
          // Set the project data from the snapshot
          setProject({
            ...newData,
            name: snapshot.snapshot_name ? snapshot.snapshot_name : newData.name,
            widget_order: snapshot?.widget_order,
            share_wbs: snapshot.share_wbs,
            current_wbs: snapshot.share_wbs.$oid,
            generated_wbs_list: snapshot.list_wbs_checked,
            checked_wbs: snapshot.list_wbs_checked.map((wbs: any) => wbs._id),
            generated_wbs_data: snapshot.list_wbs_data,
          })
          // This function help with displaying the snapshot data in the overview
          setOriginalVersion(snapshot.list_wbs_data[0])
          if (newData.generated_wbs_data && newData.generated_wbs_data.length > 0)
            newData.generated_wbs_data.map((item: any) => {
              dispatch(setVersionData({ id: item._id.$oid, data: item }));
            })
    
          if (newData.generated_wbs_list && newData.generated_wbs_list.length > 0)
            newData.generated_wbs_list.map((item: any) => {
              dispatch(addWbsToStore(item));
            })
          setShowPasswordPage(false)
        })

    }
  }


  if (error405) return <Error405 />
  if (showPasswordPage) return <PasswordPage onSubmitPassword={onSubmitPassword} />

  if (!displayVersion || !project)
    return (
      <div className='d-flex justify-content-center'>
        <div
          className='spinner-border text-primary mt-5'
          role='status'
          style={{ width: '3rem', height: '3rem' }}
        >
          <span className='sr-only'>Loading...</span>
        </div>
      </div>
    )
  return (
    <TreeProvider>
      <ProjectShareDetailsHeader share_type={'snapshot'}/>
      <fieldset disabled={areActionsDisabled}>
        <Outlet />
      </fieldset>
      <PageTitle breadcrumbs={projectDetailsBreadCrumbs}>Overview</PageTitle>
      <Overview isShared={true} share_type='snapshot' />
    </TreeProvider>
  )
}

export default ProjectSnapshotSharePage