import {getDisplayableValues} from '../../helpers/func'
import {dampenProportion, trimCalendarExtension} from '../../helpers/optimizers'
import {Calendar} from '../../modules/calendars/_models'
import {
  getCapacity,
  getReelDays,
} from '../../modules/library/ProjectCurve/Context/ProjectCurveHelpers'
import {calculateCalendarOutputs} from '../../modules/library/ProjectCurve/core'
import {getPeriodicFromCumulative} from '../../modules/projects/components/data/prepareDataTable'
import {SectorParameters} from '../../modules/projects/core/_models'
import {sweetAlert} from '../funcs'

export const sectorForecast = async (
  inputs: any,
  data: any,
  cumulativeEarnedValuesAsPercentages: any,
  calendar?: Calendar,
  sector?: string
) => {
  const sectorParams = SectorParameters.find((item) => item.label === sector)

  if (!sectorParams) {
    await sweetAlert({
      title: 'Warning',
      icon: 'warning',
      text: 'No sector parameters found for this sector, please check your sector',
    })
    return getDisplayableValues(
      {
        ...inputs,
        nbOfMonthsPassed: inputs.nbOfTimeUnitPassed + 1,
        nbOfTimeUnitPassed: inputs.nbOfTimeUnitPassed + 1,
      },
      cumulativeEarnedValuesAsPercentages.map((item: any) => item / 100),
      getPeriodicFromCumulative(cumulativeEarnedValuesAsPercentages).map((item) => item / 100),
      false
    )
  }

  let capacity: number[] | undefined = undefined

  if (calendar) {
    const {reelDays, totalDays} = await getReelDays(new Date(data.start_date.$date), 80, calendar)
    capacity = getCapacity(reelDays, totalDays)
  }

  let EarnedValue: any = calculateCalendarOutputs(
    {
      ...inputs,
      sig: sectorParams.params.axis,
      mu: sectorParams.params.tg,
      nbOfMonthsPassed: inputs.nbOfTimeUnitPassed + 1,
      nbOfTimeUnitPassed: inputs.nbOfTimeUnitPassed + 1,
    },
    false,
    capacity,
    true
  )



  if (capacity) EarnedValue = trimCalendarExtension(EarnedValue)

  EarnedValue = dampenProportion(cumulativeEarnedValuesAsPercentages, EarnedValue)

  return EarnedValue
}
