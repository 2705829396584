import {ActionPlan, ProjectObject} from '../../core/_models'
import {patchVersion} from '../../core/_requests'
import {Comment} from '../comments/models'

export const getNextId = (
  action_plan: ActionPlan[],
  projectName: string,
  prefix: string
): string => {
  let id: string
  if (action_plan && action_plan.length > 0) {
    const regex = /(\d+)$/
    const biggestNumber = action_plan.reduce((acc: number, curr: ActionPlan): number => {
      const match = curr?.subject.match(regex)
      if (!match) return acc
      const number = parseInt(match[0])
      if (number > acc) return number
      return 0
    }, 0)

    id = projectName + '-' + prefix + (biggestNumber + 1).toString().padStart(3, '0')
  } else id = projectName + '-' + prefix + '001'
  return id
}

export const getNextCommentId = (
  comments: Comment[],
  projectName: string,
  prefix: string
): string => {
  let id: string
  if (comments && comments.length > 0) {
    const regex = /(\d+)$/
    const biggestNumber = comments?.reduce((acc: number, curr: Comment): number => {
      const match = curr?.subject.match(regex)
      if (!match) return acc
      const number = parseInt(match[0])
      if (number > acc) return number
      return 0
    }, 0)

    id = projectName + '-' + prefix + (biggestNumber + 1).toString().padStart(3, '0')
  } else id = projectName + '-' + prefix + '001'
  return id
}

export const associateActionComment = (
  actionId: string,
  commentId: string,
  version: ProjectObject | undefined
) => {
  if (!version) return
  const newVersion = version
  const actionIndex = version.action_plan.findIndex((item) => item.subject === actionId)
  const commentIndex = version.comments.findIndex((item) => item.subject === commentId)
  if (actionIndex === -1 || commentIndex === -1) return
  newVersion.action_plan[actionIndex].associated_comments.push(commentId)
  newVersion.comments[commentIndex].associated_actions.push(actionId)
  return patchVersion(
    {comments: newVersion.comments, action_plan: newVersion.action_plan},
    version._id.$oid
  )
}

export const deassociateActionComment = (
  actionId: string,
  commentId: string,
  version: ProjectObject | undefined
) => {
  if (!version) return
  const newVersion = version
  const actionIndex = version.action_plan.findIndex((item) => item.subject === actionId)
  const commentIndex = version.comments.findIndex((item) => item.subject === commentId)
  if (actionIndex === -1 || commentIndex === -1) return
  newVersion.action_plan[actionIndex].associated_comments = newVersion.action_plan[
    actionIndex
  ].associated_comments.filter((item) => item !== commentId)
  newVersion.comments[commentIndex].associated_actions = newVersion.comments[
    commentIndex
  ].associated_actions.filter((item) => item !== actionId)
  return patchVersion(
    {comments: newVersion.comments, action_plan: newVersion.action_plan},
    version._id.$oid
  )
}
