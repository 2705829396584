import React, {useState} from 'react'
import CandleStickChart from './CandleStickChart'
import {useProject} from '../../core/ProjectContext'
import {CandleChartType} from './Project'
import clsx from 'clsx'
import {KTSVG} from '../../../../../_metronic/helpers'
import {Collapse} from 'react-bootstrap'

type Props = {
  isShared?: boolean;
}

export default function Variances({isShared}: Props) {
  const {displayVersion , isRebaseLined} = useProject()
  const [expanded, setExpanded] = useState<boolean>(true)
  return (
    <>
      <div className='d-inline-flex gap-2 px-5'>
        <span>
          <button
            onClick={() => setExpanded((prev) => !prev)}
            className='btn btn-light p-2 d-inline-flex align-items-center justify-content-center rotate active z-index-2'
          >
            <KTSVG
              path='/media/icons/duotune/arrows/arr021.svg'
              className={clsx('svg-icon-muted svg-icon svg-icon-sm me-0', {
                'rotate-270': !expanded,
                'rotate-90': expanded,
              })}
            />
          </button>
        </span>
        <span className='card-label fw-bold fs-3'>Variances</span>
      </div>
      <Collapse in={expanded}>
        <div className='row g-5 g-xl-8'>
          <div className='col-xl-6'>
            <CandleStickChart
              data={displayVersion}
              isRebaselined = {isRebaseLined}
              className='card-xl-stretch mb-xl-8'
              chartName={'Schedule Variance'}
              projectData={displayVersion?.output}
              CandleType={CandleChartType.SV}
            />
          </div>

          <div className='col-xl-6'>
            <CandleStickChart
              isRebaselined = {isRebaseLined}
              data={displayVersion}
              className='card-xl-stretch mb-5 mb-xl-8'
              chartName={'Cost Variance'}
              projectData={displayVersion?.output}
              CandleType={CandleChartType.CV}
            />
          </div>
        </div>
      </Collapse>
    </>
  )
}
