import axios, {AxiosResponse} from 'axios'
import { IActionPlan, Status } from './IActionPlan'

const API_URL_ACTIONPLAN = `${process.env.REACT_APP_API_URL}/action-plans`   
const API_URL_ACTIONPLAN_WBS=`${process.env.REACT_APP_API_URL}/action-plans-by-wbs`   
export const getActionPlans = () =>
    axios.get(`${API_URL_ACTIONPLAN}`).then((res: AxiosResponse<IActionPlan[]>) => res.data)
  
export const getActionPlan = (actionplanId?: string) =>
    axios
      .get(`${API_URL_ACTIONPLAN}${actionplanId ? `/${actionplanId}` : ''}`)
      .then((res: AxiosResponse<IActionPlan>) => res.data)

export const getActionPlanbywbs = (wbsId?: string) =>
  axios
    .get(`${API_URL_ACTIONPLAN_WBS}${wbsId ? `/${wbsId}` : ''}`)
    .then((res: AxiosResponse<IActionPlan[]>) => res.data)
      
export const postActionPlan = (commentParams: {subject:string;action: string; responsible?: string,associated_comment?:string ,start_date?:Date,deadline?:Date;status?:string,report_date?:Date|null;shared_with_wbs?:{$oid:string}[];close_date?:Date|null;}) =>
    axios.post(`${API_URL_ACTIONPLAN}`, commentParams).then((res: AxiosResponse<IActionPlan>) => res.data)


export const patchActionPlan = (
    actionplanId: string,
    commentParams: {subject?:string;action?: string; responsible?: string;commenter?:string;shared_with_wbs?:string[];start_date?:Date;deadline?:Date;wbs?:string,report_date?:Date|null ,close_date?:Date|null,status?:string}
  ) =>
    axios
      .patch(`${API_URL_ACTIONPLAN}/${actionplanId}`, commentParams)
      .then((res: AxiosResponse<IActionPlan>) => res.data)

export const deleteActionPlan = (actionplanId: string) => axios.delete(`${API_URL_ACTIONPLAN}/${actionplanId}`)



