import React from 'react'
import { Modal } from 'react-bootstrap'
import SharedWbsTree from './shareWbsTree'
 
type Props={
    showtree:boolean
    onClose: () => void
    Milstone:any
}
export default function TreeModal({showtree,onClose,Milstone}:Props) {

  return (
    <Modal onHide={onClose} size='lg' show={showtree}>
      <Modal.Header closeButton={true}>
        <Modal.Title>Add Comment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SharedWbsTree handleClose={onClose} Milstone={Milstone} />
      </Modal.Body>
    </Modal>
  )
}
