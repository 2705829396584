import {Card} from 'react-bootstrap'
import {KTSVG} from '../../../../../_metronic/helpers'
import {FolderNode} from './HierarchyView'
import {Dispatch, SetStateAction} from 'react'
import clsx from 'clsx'

type Props = {
  allExpanded: boolean
  allCollapsed: boolean
  node: FolderNode
  setExpanded: Dispatch<SetStateAction<boolean>>
  expanded: boolean
  resetView: () => void
  resetExpanded: () => void
}

export default function HierarchyItem({
  node,
  setExpanded,
  expanded,
  resetView,
  resetExpanded,
}: Props) {
  return (
    <Card className='border border-2 border-gray-300 border-hover cursor-pointer mb-3'>
      <Card.Body>
        <div className='d-flex flex-column align-items-center gap-2'>
          <div className='symbol symbol-50px w-50px'>
            <KTSVG
              path='/media/icons/duotune/graphs/git04.svg'
              className='svg-icon-muted svg-icon-2hx text-primary'
            />
          </div>
          <h3>{node.label}</h3>
          {!!node.children?.length && (
            <div
              className='position-absolute bottom-0 start-50 z-index-2 gap-2 align-items-center'
              style={{transform: 'translateX(-50%) translateY(70%)'}}
            >
              <button
                onClick={(e) => {
                  e.stopPropagation()
                  setExpanded((prev) => !prev)
                  setTimeout(() => resetView(), 100)
                  resetExpanded()
                }}
                className='btn btn-icon btn-light p-0 rotate active'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr021.svg'
                  className={clsx('svg-icon-muted svg-icon', {
                    'rotate-270': !expanded,
                    'rotate-90': expanded,
                  })}
                />
              </button>
            </div>
          )}
        </div>
      </Card.Body>
    </Card>
  )
}
