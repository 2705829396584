/* eslint-disable jsx-a11y/anchor-is-valid */
import {StepProps} from '../IProjectModels'
import CustomSelect from '../../../../../app/components/CustomSelect'
import {useEffect, useState} from 'react'
import {currencyData} from '../../../../helpers'

const unitOptions = [
  {value: 'billion', label: 'Billion'},
  {value: 'million', label: 'Million'},
  {value: 'thousand', label: 'Thousand'},
  {value: 'unit', label: 'Unit'},
]

const floatFormatterOptions = [
  {value: '0', label: '0'},
  {value: '1', label: '1'},
  {value: '2', label: '2'},
  {value: '3', label: '3'},
  {value: '4', label: '4'},
  {value: '5', label: '5'},
]

const Step3 = ({formik}: StepProps) => {
  const [currency, setCurrency] = useState<any>([])
  useEffect(() => {
    const localCurrency: any = []
    Object.values(currencyData[0]).forEach((item) => {
      localCurrency.push({label: `${item.name_plural} (${item.code})`, value: item.code})
    })
    setCurrency(localCurrency)
  }, [])

  return (
    <>
      {/*begin::Step 3 */}
      <div className='pb-5' data-kt-stepper-element='content'>
        <div className='w-100'>
          {/*begin::Form Group */}
          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span className='required'>Currency</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title=''
              ></i>
            </label>
            {currency.length && (
              <CustomSelect
                isSearchable
                maxHeight='200px'
                name='currency'
                value={formik.values.currency}
                options={currency}
                onChange={(d: any) => {
                  formik.setFieldValue('currency', d)
                }}
              />
            )}
            {formik.touched.currency && formik.errors.currency && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.currency}</span>
                </div>
              </div>
            )}
          </div>
          {/*end::Form Group */}
          {/*begin::Form Group */}
          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span className='required'>Unit</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title=''
              ></i>
            </label>

            <CustomSelect
              name='unit'
              options={unitOptions}
              value={unitOptions.find((item) => item.value === formik.values.unit)}
              onChange={(d: any) => {
                formik.setFieldValue('unit', d.value)
              }}
            />
            {formik.touched.unit && formik.errors.unit && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.unit}</span>
                </div>
              </div>
            )}
          </div>
          {/*end::Form Group */}
          {/*begin::Form Group */}
          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span className='required'>Float Formatter</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title=''
              ></i>
            </label>
            <CustomSelect
              name='float_formatter'
              options={floatFormatterOptions}
              value={{
                value: formik.values.float_formatter.toString(),
                label: formik.values.float_formatter.toString(),
              }}
              onChange={(d: any) => {
                formik.setFieldValue('float_formatter', d.value)
              }}
            />
            {formik.touched.float_formatter && formik.errors.float_formatter && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.float_formatter}</span>
                </div>
              </div>
            )}
          </div>
          {/*end::Form Group */}
        </div>
      </div>
      {/*end::Step 3 */}
    </>
  )
}

export {Step3}
