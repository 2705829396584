import { Navigate, Routes, Route, Outlet, useParams, useNavigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { ActionPlan } from './components/action-plan/ActionPlan'
import { Budget } from './components/budget/Budget'
import { Data } from './components/data/Data'
import { Overview } from './components/overview/Overview'
import { ProjectDetailsHeader } from './components/pagination/header/ProjectDetailsHeader'
import { Settings } from './components/settings/Settings'
import { useProjectDetailsById, useProjectVersionById } from './core/_queries'
import { ExportData } from './components/ExportData/ExportData'
import { useState } from 'react'
import { useAuth } from '../auth'
import { useProject } from './core/ProjectContext'
import Comments from './components/comments/Comments'
import Milestones from './components/milestones/Milestones'
import { patchVersion } from './core/_requests'
import { showError } from '../../utils/funcs'
import ReabseLine from './components/reabse-line/ReabseLine'
import { Wbs } from './components/Wbs/Wbs'
import { TestWbs } from './components/ExportData/TestWbs'
import ShowLoadingModal from './components/pagination/header/ShowLoadingModal'
import { TreeProvider } from '../wbs/components/TreeView/ChartTreeContext'

const projectDetailsBreadCrumbs: Array<PageLink> = [
  {
    title: 'Project',
    path: '/projects/overview',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ProjectPage = () => {
  const navigate = useNavigate()
  const {
    setProject,
    setOriginalVersion,
    displayVersion,
    project,
    setVersion,
    loading: dataLoading,
  } = useProject()
  const [versionId, setVersionId] = useState<string>()
  const [shareVersionId, setShareVersionId] = useState<string>()
  const { id } = useParams()
  const { areActionsDisabled } = useAuth()
  const { isLoading } = useProjectDetailsById(
    id || '',
    (newData) => {
      console.log({ newData })
      //find the period where the default version is present
      setProject({ ...newData, current_wbs: newData?.default_wbs?._id.$oid })
      setOriginalVersion(newData.default_version)
      if (!versionId) setVersionId(newData.default_version._id.$oid)
      if (!shareVersionId && newData?.share_version?._id?.$oid) setShareVersionId(newData.share_version._id.$oid)
    },
    undefined,
    (err) => showError(err).then(() => navigate('/projects'))
  )
  //only triggers when versionId is set
  const { isLoading: versionLoading } = useProjectVersionById(
    versionId,
    (version) => {
      setVersion(undefined)
      if (version.associated_calendar?.$oid !== project?.associated_calendar?._id?.$oid) {
        return patchVersion(
          { associated_calendar: project?.associated_calendar?._id?.$oid || null },
          version._id.$oid
        ).then((res: any) => setOriginalVersion(res))
      }
      setOriginalVersion(version)
    },
    !!versionId
  )

  if (isLoading || versionLoading || !displayVersion || !project)
    return (
      <div className='d-flex justify-content-center'>
        <div
          className='spinner-border text-primary mt-5'
          role='status'
          style={{ width: '3rem', height: '3rem' }}
        >
          <span className='sr-only'>Loading...</span>
        </div>
      </div>
    )
  return (
    <TreeProvider>
      <ShowLoadingModal />
      <Routes>
        <Route
          element={
            <>
              <ProjectDetailsHeader versionId={versionId} setVersionId={setVersionId} />
              <fieldset disabled={areActionsDisabled}>
                {dataLoading ? (
                  <div className='d-flex justify-content-center'>
                    <div
                      className='spinner-border text-primary mt-5'
                      role='status'
                      style={{ width: '3rem', height: '3rem' }}
                    >
                      <span className='sr-only'>Loading...</span>
                    </div>
                  </div>
                ) : (
                  <>
                    <Outlet />
                  </>
                )}
              </fieldset>
            </>
          }
        >
          <Route
            path={`overview`}
            element={
              <>
                <PageTitle breadcrumbs={projectDetailsBreadCrumbs}>Overview</PageTitle>
                <Overview />
              </>
            }
          />
          <Route
            path='rebase_line'
            element={
              <>
                <PageTitle breadcrumbs={projectDetailsBreadCrumbs}>Rebase Line</PageTitle>
                <ReabseLine setVersionId={setVersionId} />
              </>
            }
          />
          <Route
            path='data'
            element={
              <>
                <PageTitle breadcrumbs={projectDetailsBreadCrumbs}>Data</PageTitle>
                <Data setVersionId={setVersionId} />
              </>
            }
          />
          <Route
            path='milestones'
            element={
              <>
                <PageTitle breadcrumbs={projectDetailsBreadCrumbs}>Milestones</PageTitle>
                <Milestones />
              </>
            }
          />
          <Route
            path='export_data'
            element={
              <>
                <PageTitle breadcrumbs={projectDetailsBreadCrumbs}>Data</PageTitle>
                <ExportData />
              </>
            }
          />
          <Route
            path='test_wbs'
            element={
              <>
                <PageTitle breadcrumbs={projectDetailsBreadCrumbs}>Data</PageTitle>
                <TestWbs />
              </>
            }
          />
          <Route
            path='budget'
            element={
              <>
                <PageTitle breadcrumbs={projectDetailsBreadCrumbs}>Budget</PageTitle>
                <Budget />
              </>
            }
          />
          <Route
            path='action-plan'
            element={
              <>
                <PageTitle breadcrumbs={projectDetailsBreadCrumbs}>Action Plan</PageTitle>
                <ActionPlan />
              </>
            }
          />
          <Route
            path='comments'
            element={
              <>
                <PageTitle breadcrumbs={projectDetailsBreadCrumbs}>Action Plan</PageTitle>
                <Comments />
              </>
            }
          />
          <Route
            path='settings'
            element={
              <>
                <PageTitle breadcrumbs={projectDetailsBreadCrumbs}>Settings</PageTitle>
                <Settings />
              </>
            }
          />
          <Route index element={<Navigate to={`/project/${id}/overview`} />} />
          <Route
            path='wbs/*'
            element={
              <>
                <PageTitle breadcrumbs={projectDetailsBreadCrumbs}>WBS</PageTitle>
                <Wbs />
              </>

            }
          />

        </Route>
      </Routes>
    </TreeProvider>
  )
}

export default ProjectPage
