import { Tree } from 'antd';
import type { DataNode, EventDataNode } from 'antd/es/tree';
import { createWbs, getWbs, patchWbs } from '../../_requests';
import TreeWbsItem from './TreeWbsItem';
import { appendTreeData, deleteTreeNode, updateTreeData } from './_helpers';
import { useTree } from './TreeContext';
import SwitcherIcon from './icons/SwitcherIcon';
import DraggableIcon from './icons/DraggableIcon';
import { showError, sweetAlert } from '../../../../utils/funcs';
import TreeViewHeader from './TreeViewHeader';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { addWbsToStore } from '../../../wbs/treedata';
import React from 'react';
import { getVersionById } from '../../../projects/core/_requests';
import { Wbs } from '../../_models';
import { useProject } from '../../../projects/core/ProjectContext';

type WbsType = {
  _id: { $oid: string };
  name: string;
  parent_wbs?: { $oid: string };
  subwbs: { $oid: string }[];
  rank: number;
  user: { $oid: string };
  milestones: any[];
  hierarchy: any[];
  data?: { $oid: string }
};

interface TreeNodeProps {
  folder: WbsType
}

interface Props {
  currentStep?: number
}

export default function TreeView({ currentStep }: Props) {
  const { expandedKeys, setExpandedKeys, setTreeData, treeData, isLoading, setSelectedWp, setSelectedWpData } = useTree();
  const { project } = useProject();
  const dispatch = useDispatch()
  const knownWbs = useSelector((state: RootState) => state.treedata.knownWbs)
  const isrenaming = useSelector((state: RootState) => state.treerenaming.value)
  const loadData = async (treeNode: EventDataNode<DataNode>) => {
    let childWbs = knownWbs[treeNode.key.toString()]
    if (!childWbs) {
      childWbs = await getWbs(treeNode.key.toString());
      dispatch(addWbsToStore(childWbs));
    }

    const childData: DataNode[] = await Promise.all(childWbs.subwbs.map(async (item) => {
      let response = knownWbs[item.$oid];
      if (!response) {
        response = await getWbs(item.$oid);
        dispatch(addWbsToStore(response));
      }

      return {
        key: response._id.$oid,
        name: response.name,
        title: <TreeWbsItem wbs={response} />,
        switcherIcon: response.subwbs.length ? undefined : <></>,
      }
    })
    )
    const sortedChildData = childData.slice().sort((a, b) => {
      const getRank = (node: DataNode) => {
        if (React.isValidElement(node.title) && node.title.props?.folder?.rank !== undefined) {
          return node.title.props.folder.rank
        }
        return Infinity; // Assign a default high rank if undefined
      };

      const rankA = getRank(a);
      const rankB = getRank(b);

      return rankA - rankB; // Ascending order
    });


    setTreeData((origin) => {
      return updateTreeData(origin, treeNode.key.toString(), sortedChildData)
    })
  };

  const findParentKey = (key: string, nodes: DataNode[]): string | null => {
    for (const node of nodes) {
      const nodeWbs = node.title && typeof node.title === 'object' && 'props' in node.title
        ? (node.title.props as { wbs: WbsType }).wbs
        : null;

      if (nodeWbs?.subwbs.some(subwbs => subwbs.$oid === key)) {
        return node.key.toString();
      }

      if (node.children) {
        const parentKey = findParentKey(key, node.children);
        if (parentKey) return parentKey;
      }
    }
    return null;
  };

  const handleClick = async (node: EventDataNode<DataNode>) => {
    const nodeTitle = node.title as React.ReactElement<TreeNodeProps>;

    if (!nodeTitle?.props?.folder) {
      return;
    }

    const folder = nodeTitle.props.folder;

    if (!folder.subwbs || folder.subwbs.length === 0) {
      try {

        const wp = await getWbs(node.key);
        let wpData;
        if (folder.data) {
          wpData = await getVersionById(folder.data.$oid);
        }

        setSelectedWp(wp);
        setSelectedWpData(wpData);
      } catch (error) {
        showError(error);
      }
    }
  };

  // Extracted handleDrop function
  const handleDrop = async (info: any) => {

    let draggedWbs = knownWbs[info.dragNode.key.toString()];
    if (!draggedWbs) {
      draggedWbs = await getWbs(info.dragNode.key.toString());
      dispatch(addWbsToStore(draggedWbs));
    }


    const oldParentKey = draggedWbs.parent_wbs?.$oid || null; // Get old parent key
    const newParentKey = info.node.key.toString(); // Get new parent key

    if (oldParentKey === newParentKey) {
      // No need to do anything if the node is dropped back to the same parent
      return;
    }

    let newParentWbs = knownWbs[newParentKey];
    if (!newParentWbs) {
      newParentWbs = await getWbs(newParentKey);
      dispatch(addWbsToStore(newParentWbs));
    }

    // Check if the new parent has no subwbs and has a data field
    if (newParentWbs && newParentWbs.subwbs.length === 0 && newParentWbs.data) {

      sweetAlert({
        title: 'Attention',
        text: 'Curve will push the current data of the WP you wanna drop into in a new WP beside the one you are dropping, press no if you wish to drop only the current node',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
      }).then(async (res) => {
        if (!res.isConfirmed) {
          // If not confirmed, proceed with normal drop
          await proceedWithDrop(info, oldParentKey, newParentKey, newParentWbs, false);
        } else {
          // If confirmed, proceed with special drop (remove data and create a new node)
          await proceedWithDrop(info, oldParentKey, newParentKey, newParentWbs, true);
        }
      });
    } else {
      // If conditions don't match, proceed directly with the drop
      await proceedWithDrop(info, oldParentKey, newParentKey, newParentWbs, false);
    }
  };


  // Updated proceedWithDrop to handle the special case
  const proceedWithDrop = async (info: any, oldParentKey: string | null, newParentKey: string, newParentWbs: WbsType | Wbs | null, isConfirmed: boolean) => {
    try {
      let newParent = newParentWbs;
      // If confirmed, remove the data field and create a new node with that data
      if (isConfirmed && newParentWbs) {

        const newSubNode = await createWbs({
          name: 'sub ' + newParentWbs.name,
          parent_wbs: newParentWbs._id.$oid,
          data: newParentWbs.data?.$oid,
          parent_project: project?._id.$oid,

        });

        dispatch(addWbsToStore(newSubNode));

        // Remove the data field from the new parent node
        newParent = await patchWbs(newParentWbs._id.$oid, {
          data: null
        });

        dispatch(addWbsToStore(newParent));

        // Append the new subnode to the tree
        setTreeData((origin) => {
          const updatedTree = appendTreeData(origin, newParentKey, [
            {
              key: newSubNode._id.$oid,
              title: <TreeWbsItem wbs={newSubNode} />,
              children: [],
            }
          ]);
          return updatedTree;
        });
      }

      // Continue with the normal drop operation
      const patchedDraggedNode = await patchWbs(info.dragNode.key.toString(), {
        parent_wbs: newParentKey,
      });

      dispatch(addWbsToStore(patchedDraggedNode));

      // Update the tree state to reflect the changes
      setTreeData((origin) => {
        // Remove the node from the old parent's subwbs
        let updatedTree = deleteTreeNode(origin, info.dragNode.key.toString());

        // Append the node to the new parent's subwbs
        updatedTree = appendTreeData(updatedTree, newParentKey, [info.dragNode]);

        return updatedTree;
      });

      // Update the old parent's subwbs to remove the moved node
      if (oldParentKey) {
        let oldParentWbs = knownWbs[oldParentKey];
        if (!oldParentWbs) {
          oldParentWbs = await getWbs(oldParentKey);
          dispatch(addWbsToStore(oldParentWbs));
        }
        if (oldParentWbs) {
          const updatedOldParentSubwbs = oldParentWbs?.subwbs.filter(subwbs => subwbs.$oid !== info.dragNode.key.toString());
          const patchedOldParent = await patchWbs(oldParentKey, {
            subwbs: updatedOldParentSubwbs?.map(subwbs => subwbs.$oid) || []
          });
          dispatch(addWbsToStore(patchedOldParent));
        }
      }

      // Update the new parent's subwbs to include the moved node
      const updatedNewParentSubwbs = [...(newParent?.subwbs.map(subwbs => subwbs.$oid) || []), info.dragNode.key.toString()];

      if (newParent) {
        const patchedNewParent = await patchWbs(newParentKey, {
          subwbs: updatedNewParentSubwbs
        });
        dispatch(addWbsToStore(patchedNewParent));
      }

    } catch (error) {
      showError(error);
    }
  };

  if (isLoading || !treeData.length || treeData[0].key !== project?.default_wbs?._id?.$oid)
    return (
      <div className='d-flex justify-content-center'>
        <div
          className='spinner-border text-primary mt-5'
          role='status'
          style={{ width: '3rem', height: '3rem' }}
        >
          <span className='sr-only'>Loading...</span>
        </div>
      </div>
    );

  return (
    <>
      <TreeViewHeader currentStep={currentStep} />
      <div className='card mt-5 py-2 px-5'>
        <Tree
          style={{ overflowX: 'scroll' }}
          selectable={false}
          switcherIcon={SwitcherIcon}
          onExpand={(expandedKeys, { expanded, node }) =>
            setExpandedKeys((prev) =>
              !expanded ? prev.filter((item) => item !== node.key) : [...prev, node.key.toString()]
            )
          }
          // onClick={(event, node) => !isrenaming && handleClick(node)}
          expandedKeys={expandedKeys}
          loadData={loadData}
          treeData={treeData}
          onDrop={handleDrop}
          onDragEnter={(info) => {
            if (!expandedKeys.find((item) => item === info.node.key))
              setExpandedKeys((keys) => [...keys, info.node.key.toString()]);
          }}
          draggable={{
            icon: DraggableIcon(),
          }}
        />
      </div>
    </>
  );
}
