import {Field, FieldArray, FormikProvider, useFormik} from 'formik'
import {getLabels, normalizeDateUnit} from '../../../../helpers/DateFormatter'
import {
  getProjectData,
} from '../../../../utils/project-data'
import {ProjectObject} from '../../core/_models'
import {getCustomCurve} from '../overview/Project'
import * as Yup from 'yup'
import {useState} from 'react'
import {createVersion, patchProject, patchVersion} from '../../core/_requests'
import {useProject} from '../../core/ProjectContext'
import {sweetAlert} from '../../../../utils/funcs'
import {Dropdown} from 'react-bootstrap'
import {getNewVersion, greaterThanOrEqual} from '../../core/ProjectHelpers'
import {useProjectDetailsById} from '../../core/_queries'
import { DateFormatType, getDiffrentBettwenDate } from '../../../../utils/data-transformarion/date-utils'
interface Props {
  data: ProjectObject | undefined
}

export default function CompleteData({data}: Props) {
  const {refetch} = useProjectDetailsById(data?.project.$oid || '', () => {}, false)
  const {setOriginalVersion, setVersion, project, setProject} = useProject()
  const [loading, setLoading] = useState<boolean>(false)
  const dateDiff = getDiffrentBettwenDate(
    data?.data_date.$date || 0,
    data?.start_date.$date || 0,
    data?.period_count.type || ''
  )
  const zerosArray = Array(dateDiff + 1).fill(0)
  const evValues = getCustomCurve('cumulativeEarnedValue', data)
  const acValues = getCustomCurve('cumulativeActualCost', data)
  const formik = useFormik({
    initialValues: {
      earnedValue: zerosArray.map((item, index) => evValues[index] || 0),
      actualCost: zerosArray.map((item, index) => acValues[index] || 0),
    },
    validationSchema: Yup.object().shape({
      earnedValue: Yup.array()
        .test('number', 'Earned values must be numbers', (value, context) => {
          return value?.every((el) => !isNaN(+el)) || false
        })
        .test('evTest', 'Earned values must be cumulative', (value, context) => {
          return value?.every(greaterThanOrEqual) || false
        }),
      actualCost: Yup.array()
        .test('number', 'Earned values must be numbers', (value, context) => {
          return value?.every((el) => !isNaN(+el)) || false
        })
        .test('acTest', 'Actual Cost values must be cumulative', (value, context) => {
          return value?.every(greaterThanOrEqual) || false
        }),
    }),
    onSubmit: async (values) => {
      try {
        setLoading(true)
        const newVersion = {...data}
        newVersion.custom_curve = [
          {
            color: 'black',
            name: 'cumulativePlannedValue',
            values: data?.custom_curve ? data?.custom_curve[0].values : [],
          },
          {
            color: 'white',
            name: 'cumulativeEarnedValue',
            values: values.earnedValue.map((item) => +item),
          },
          {
            color: 'red',
            name: 'cumulativeActualCost',
            values: values.actualCost.map((item) => +item),
          },
        ]
        const res = await getProjectData(newVersion, project?.associated_calendar, project?.sector)
        const updatedVersion = await patchVersion(
          {
            data_date: new Date(data?.data_date.$date || 0),
            output: res,
            custom_curve: newVersion.custom_curve,
          },
          data?._id?.$oid
        )
        setVersion(updatedVersion as ProjectObject)
        setOriginalVersion(updatedVersion as ProjectObject)
        if (project?.status === 'pending')
          patchProject({status: 'in_progress'}, data?.project.$oid || '').then((result) =>
            setProject(result)
          )
      } catch (error: any) {
        sweetAlert({
          text: error.message,
          icon: 'error',
          title: 'Error',
        })
      } finally {
        setLoading(false)
      }
    },
    enableReinitialize: true,
  })
  const normalizedDateUnit = normalizeDateUnit(data?.period_count?.type)
  const labels = getLabels(
    data?.start_date.$date || 0,
    dateDiff + 1,
    normalizedDateUnit,
    data?.date_format as DateFormatType
  )

  const saveNewVersion = async () => {
    try {
      if (!data) return
      setLoading(true)
      const newVersion = getNewVersion(data)
      newVersion.custom_curve = [
        {
          color: 'black',
          name: 'cumulativePlannedValue',
          values: data?.custom_curve ? data?.custom_curve[0].values : [],
        },
        {
          color: 'white',
          name: 'cumulativeEarnedValue',
          values: formik.values.earnedValue.map((item) => +item),
        },
        {
          color: 'red',
          name: 'cumulativeActualCost',
          values: formik.values.actualCost.map((item) => +item),
        },
      ]
      const result = await getProjectData(
        {...data, custom_curve: newVersion.custom_curve},
        project?.associated_calendar,
        project?.sector
      )
      await createVersion(
        {project_version: {...newVersion, output: result}, timestamp_period: 1},
        data.project.$oid
      ).then((res) => {
        sweetAlert({
          text: 'New Version Created Successfully',
          title: 'Success',
          icon: 'success',
        })
        refetch()
      })
      if (project?.status === 'pending')
        patchProject({status: 'in_progress'}, data?.project.$oid).then((result) =>
          setProject(result)
        )
    } catch (error: any) {
      sweetAlert({
        text: error.message,
        icon: 'error',
        title: 'Error',
      })
    } finally {
      setLoading(false)
    }
  }
  return (
    <FormikProvider value={formik}>
      <div className='d-flex flex-wrap flex-stack align-items-end'>
        <h3 className=''>Fill the Missing Data</h3>
        <div className='d-flex align-items-center gap-1'>
          <Dropdown>
            <Dropdown.Toggle className='btn btn-sm btn-primary show menu-dropdown'>
              Save As
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                disabled={!formik.isValid || !formik.dirty || loading}
                onClick={() => saveNewVersion()}
              >
                New Version
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <button
            type='submit'
            onClick={() => formik.handleSubmit()}
            disabled={!formik.isValid || !formik.dirty || loading}
            className=' btn btn-sm btn-primary'
          >
            {loading ? (
              <span className='indicator-progress' style={{display: 'block'}}>
                <span className='spinner-border spinner-border-sm align-middle'></span>
              </span>
            ) : (
              'Save'
            )}
          </button>
        </div>
      </div>
      <div className='card card-xxl-stretch mb-5 mb-xl-10'>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bolder text-muted'>
                  <th className='min-w-150px'></th>
                  {zerosArray.map((item: any, index: number) => (
                    <th key={index} className='min-w-120px'>
                      {labels[index]}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <a className='text-primary fw-bolder text-hover-primary fs-6'>Earned Value</a>
                  </td>
                  <FieldArray
                    name='earnedValue'
                    render={(arrayHelpers) => (
                      <>
                        {formik.values.earnedValue &&
                          formik.values.earnedValue.map((value: any, index: number) => (
                            <td className='position-relative' key={index}>
                              <Field
                                name={`earnedValue.${index}`}
                                className="text-dark fw-bold d-block fs-7 text-danger 'border-bottom border-primary"
                              />
                            </td>
                          ))}
                      </>
                    )}
                  />
                </tr>
                <tr>
                  <td>
                    <a className='text-primary fw-bolder text-hover-primary fs-6'>Actual Cost</a>
                  </td>
                  <FieldArray
                    name='actualCost'
                    render={(arrayHelpers) => (
                      <>
                        {formik.values.earnedValue &&
                          formik.values.earnedValue.map((value: any, index: number) => (
                            <td className='position-relative' key={index}>
                              <Field
                                name={`actualCost.${index}`}
                                className="text-dark fw-bold d-block fs-7 text-danger 'border-bottom border-primary"
                              />
                            </td>
                          ))}
                      </>
                    )}
                  />
                </tr>
              </tbody>
            </table>
          </div>
          {formik.errors.earnedValue && (
            <h4 className='text-danger'>{formik.errors.earnedValue.toString()}</h4>
          )}
          {formik.errors.actualCost && (
            <h4 className='text-danger'>{formik.errors.actualCost.toString()}</h4>
          )}
        </div>
      </div>
    </FormikProvider>
  )
}
