import { DatePicker } from 'antd'
import clsx from 'clsx'
import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'
import ExportCustomData from '../../../../lib/export/ExportCustomData'
import MultiOptionFilter from '../../../../lib/filters/MultiOptionFilter'
import { useProject } from '../../core/ProjectContext'
import CreateMilestoneModal from './CreateMilestoneModal'
import MilestoneTable from './MilestoneTable'
import { Milestone } from './_models'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../store'
import { getMilestonebywbs } from './requests'
import EmptyValuesCard from '../../../../components/emptyValuesCard'
import { addWbsToStore } from '../../../wbs/treedata'
import { getWbs } from '../../../wbs/_requests'

export default function Milestones() {
  const [showModal, setShowModal] = useState<boolean>(false)
  const { displayVersion, setDataDate, project } = useProject()
  const [milestones, setMilestones] = useState<Milestone[] | null>()
  const currentwbs = useSelector((state: RootState) => state.currentwbs.value)
  const added = useSelector((state: RootState) => state.added.value)
  const knownWbs = useSelector((state: RootState) => state.treedata.knownWbs);
  const dispatch = useDispatch();

  useEffect(() => {
    const getdata = async () => {
      let currentWbs = knownWbs[project?.current_wbs]
      if (!knownWbs[project?.current_wbs]) {
        currentWbs = await getWbs(project?.current_wbs)
        dispatch(addWbsToStore(currentWbs))
      }
      const last_opened_wbs = currentWbs?.last_opened_wbs?.$oid ?
        currentWbs?.last_opened_wbs?.$oid
        : project?.current_wbs
      if (last_opened_wbs) {
        const data = await getMilestonebywbs(last_opened_wbs)
        setMilestones(data)
      }
    }

    getdata()
  }, [added, project?.current_wbs])

  return (
    <>
      <CreateMilestoneModal onHide={() => setShowModal(false)} show={showModal} />
      <h2>Milestones</h2>
      <div className='d-flex flex-wrap flex-stack pb-7'>
        <div className='d-flex flex-wrap align-items-center my-1 gap-3'>
          <label htmlFor='milestones_dd_picker' className='fw-bold'>
            Data Date
          </label>
          <DatePicker
            id='milestones_dd_picker'
            cellRender={(current: Dayjs, info: any) => {
              if (current.isBefore(dayjs(displayVersion?.start_date.$date)))
                return displayVersion?.period_count.type === 'monthly' ? 'Not Started' : 'NS'
              return (
                <div
                  className={clsx({
                    highlighted:
                      (displayVersion?.data_date.$date || 0) >= current.toDate().getTime(),
                  })}
                >
                  {info.originNode}
                </div>
              )
            }}
            format='MM/YYYY'
            suffixIcon={null}
            clearIcon={null}
            picker='month'
            value={dayjs(displayVersion?.data_date.$date).startOf('month')}
            onChange={(date) => date && setDataDate(date.startOf('month').toDate(), false)}
          />
        </div>
        <div className='d-flex flex-wrap my-1 gap-2'>
          <ExportCustomData data={[]} name={`comments_`} />

          <MultiOptionFilter title='Commenter' filter={[]} setFilter={() => { }} filters={[]} />
          <div className='d-flex my-0'>
            <a onClick={() => setShowModal(true)} href='#' className='btn btn-sm btn-primary me-3'>
              Add Milestone
            </a>
          </div>
        </div>
      </div>
      {
        !milestones ? (
          <div className='justify-content-center'>
            <p className="placeholder-glow">
              <span className="placeholder col-12"></span>
            </p>
            <p className="placeholder-glow">
              <span className="placeholder col-12"></span>
            </p>
            <p className="placeholder-glow">
              <span className="placeholder col-12"></span>
            </p>
          </div>
        ) : milestones.length ? (
          <MilestoneTable
            milestones={milestones} />)
          : (<EmptyValuesCard title='No Milestones' text='You have yet to create milestones' />)
      }
    </>
  )
}
