import {DataNode, EventDataNode} from 'antd/es/tree'
import {findNodeById} from '../../../eps/components/TreeView/_helpers'

export const constructHierarchy = (node: EventDataNode<DataNode>, treeData: DataNode[]) => {
  if (node.key === 'root') {
    return {hierarchy: [{key: 'root', name: 'root'}], selectedFolder: 'root'}
  }
  let hierarchyArray = []
  const getFolderHierarchy = (id: string) => {
    const foundNode = findNodeById(treeData, id)
    if (!foundNode) return undefined
    return {
      name: (foundNode.title as any).props.folder?.name,
      key: (foundNode.title as any).props.folder?._id.$oid,
      parent_folder: (foundNode.title as any).props.folder?.parent_folder?.$oid,
    }
  }

  let initial = (node.title as any).props.folder?.parent_folder?.$oid
  hierarchyArray.unshift({
    name: (node.title as any).props.folder.name,
    key: (node.title as any).props.folder?._id.$oid,
  })
  let parent = getFolderHierarchy(initial)
  while (parent) {
    hierarchyArray.unshift(parent)
    parent = getFolderHierarchy(parent.parent_folder)
  }
  hierarchyArray.unshift({key: 'root', name: 'root'})
  return {hierarchy: hierarchyArray, selectedFolder: node.key.toString()}
}
