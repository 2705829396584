import {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {ProjectFilter} from '../../../../app/modules/projects/core/_models'
import {SimulationFilter} from '../../../../app/modules/simulations/core/_models'
import {useSimulationsData} from '../../../../app/modules/simulations/core/_queries'
import {MenuComponent} from '../../../assets/ts/components'

/* eslint-disable jsx-a11y/anchor-is-valid */
interface Dropdown1Props {
  setFilter?: Dispatch<SetStateAction<ProjectFilter>>
  filter?: ProjectFilter | SimulationFilter
  filters?: any[]
}

export function Dropdown1({setFilter, filter, filters}: Dropdown1Props) {
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])
  return (
    <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
      </div>

      <div className='separator border-gray-200'></div>

      <div className='px-7 py-5'>
        <div className='mb-10'>
          <label className='form-label fw-bold'>Status:</label>

          <div>
            <select
              className='form-select form-select-solid'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              onChange={(e) => {
                setFilter && setFilter(e.target.value as ProjectFilter)
              }}
              value={filter}
            >
              {filters?.map((filter: any) => (
                <option key={filter.value} value={filter.value}>
                  {filter.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  )
}
