import { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { KTSVG } from '../../../../../_metronic/helpers'
import CustomSelect from '../../../../components/CustomSelect'
import UploadField from '../../../../components/uploadField'
import { FormikProps } from 'formik'
import { useCurves } from '../../../library/_queries'
import dayjs from 'dayjs'
import { Curve } from '../../../library/_models'
import { useProject } from '../../core/ProjectContext'
import { calculateSigmoidOutputs } from '../../../../helpers/func'
import { downloadCustomCurveTemplate } from '../../../library/ProjectCurve/Modals/utils'
import {
  applyCalendarToCurve,
  getCapacity,
  getReelDays,
} from '../../../library/ProjectCurve/Context/ProjectCurveHelpers'
import { getDiffrentBettwenDate } from '../../../../utils/data-transformarion/date-utils'

type Props = {
  formik: FormikProps<any>
}

export enum ImportType {
  XLSX = 'XLSX',
  CURVE = 'curve',
}

export default function VirtualBaseline({ formik }: Props) {
  const { data, isLoading } = useCurves({ page: 1, per_page: 1000 })
  const [importType, setImportType] = useState<ImportType>(ImportType.XLSX)
  const [curves, setCurves] = useState<Curve[]>([])
  const { displayVersion, project } = useProject()

  useEffect(() => {
    const localCurves: any = []
    data?.items
      .filter(
        (item) =>
          dayjs(item.start_date.$date)
            .add(item.duration - 1, 'months')
            .isSame(displayVersion?.end_date.$date, 'month') &&
          dayjs(item.start_date.$date).isSame(displayVersion?.start_date.$date, 'month')
      )
      .forEach((item) => {
        localCurves.push({
          label: item.name,
          value: {
            name: item?.name,
            tangent: item?.tangent,
            offset: item.axis,
            duration: item.duration,
            budget_at_completion: item.budget_at_completion.amount || undefined,
          },
        })
      })
    setCurves(localCurves)
  }, [isLoading, displayVersion])

  const handleChange = async (curve: {
    tangent: number
    offset: number
    duration: number
    name: string
  }) => {
    let tableData = calculateSigmoidOutputs(
      {
        projectDurationUnit: 'month',
        mu: curve.tangent,
        sig: curve.offset,
        nbOfMonthsPassed: curve.duration,
      },
      false
    ).tableData

    if (project?.associated_calendar) {
      const { reelDays, totalDays } = await getReelDays(
        new Date(displayVersion?.start_date.$date || 0),
        curve.duration,
        project.associated_calendar
      )
      const capacity = getCapacity(reelDays, totalDays)
      tableData = applyCalendarToCurve(capacity, {
        tableData,
        isInitialState: false,
        highlightIdx: -1,
      }).tableData
    }

    const pvTable = tableData[1].map(
      (el: number) =>
        +((el * (displayVersion?.budget_at_completion.amount || 1)) / 100).toFixed(
          displayVersion?.float_formatter
        )
    )
    formik.setFieldValue('savedCurve', {
      name: curve.name,
      offset: curve.offset,
      tangent: curve.tangent,
    })
    formik.setFieldValue('cumulativePlannedValue', pvTable)
  }

  return (
    <>
      <div className='row justify-content-center'>
        <label className='col-12 col-xl-8 fs-6 fw-bolder text-dark mb-7d-flex align-items-center fs-5 fw-semibold mb-4'>
          <Form.Group className='d-flex py-5 justify-content-between'>
            <Form.Check
              type='radio'
              label='Import Excel file'
              value={ImportType.XLSX}
              name='radio-group'
              onChange={(e) => setImportType(e.target.value as ImportType)}
              defaultChecked={true}
            />
            <Form.Check
              type='radio'
              label='Import Virtual Baseline'
              value={ImportType.CURVE}
              name='radio-group'
              onChange={(e) => setImportType(e.target.value as ImportType)}
            />
          </Form.Group>
        </label>
      </div>
      {importType === ImportType.XLSX ? (
        <>
          <div className='fv-row mb-10'>
            <label className='fs-6 fw-bolder text-dark mb-7d-flex align-items-center fs-5 fw-semibold mb-4'>
              <span className='required'>Cumulative Planned Value</span>
              <div className='text-muted fw-semibold '>
                you can download the generated XLSX file
                <a
                  href='#'
                  className='link-primary'
                  onClick={() => {
                    downloadCustomCurveTemplate(
                      new Date(displayVersion?.start_date.$date || 0),
                      getDiffrentBettwenDate(
                        displayVersion?.end_date.$date || 0,
                        displayVersion?.start_date.$date || 0,
                        displayVersion?.delay_tolerance?.type || 'monthly'
                      ) + 1
                    )
                  }}
                >
                  {' '}
                  Download Template
                </a>
              </div>
            </label>
            <UploadField
              index={0}
              durationUnit=''
              setFileContent={async (value: any) => {
                formik.setFieldValue('savedCurve', undefined)
                formik.setFieldValue('cumulativePlannedValue', value)
                setTimeout(() => formik.setFieldTouched('cumulativePlannedValue', true))
              }}
            />
          </div>
        </>
      ) : (
        <>
          <div className='fv-row'>
            <label className='fs-6 fw-bolder text-dark mb-7d-flex align-items-center fs-5 fw-semibold mb-4'>
              <div className='text-muted fw-semibold '>
                Or you can use a saved curve as base line
              </div>
            </label>
          </div>
          <div className='fv-row mb-10'>
            <label className='fs-6 fw-bolder text-dark mb-7d-flex align-items-center fs-5 fw-semibold mb-4'>
              <span className='required'>Curve Library</span>
            </label>
            <CustomSelect
              maxHeight='200px'
              name='savedSimulation'
              options={curves}
              onChange={(d: any) => handleChange(d.value as any)}
            />
            <div className='alert alert-light d-flex align-items-center p-3 my-5'>
              <KTSVG
                className='svg-icon svg-icon-2hx svg-icon-secondary-dark me-3'
                path='/media/icons/duotune/general/gen045.svg'
              />
              <span className='text-muted'>
                Project start date, duration and calendar need to match the selected curve.
              </span>
            </div>
          </div>
        </>
      )}
      {formik.errors.cumulativePlannedValue && formik.touched.cumulativePlannedValue && (
        <div className='row mt-2'>
          <h4 className='text-danger'>{formik.errors.cumulativePlannedValue.toString()}</h4>
        </div>
      )}
    </>
  )
}
