import { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { getCSSVariableValue } from '../../../_metronic/assets/ts/_utils'
import { useProject } from '../../modules/projects/core/ProjectContext'
import { isNotEmpty } from '../../../_metronic/helpers'
import { formatFloatFromString } from '../../utils/funcs'
export default function TestCostChart() {
  const [isVariance, setIsVariance] = useState(true)
  const labelColor = getCSSVariableValue('--kt-dark')
  const { displayVersion } = useProject()
  const EAC = displayVersion?.output?.EAC && Array.isArray(displayVersion.output.EAC) && displayVersion.output.EAC.length > 0
    ? Number(displayVersion.output.EAC[displayVersion.output.EAC.length - 1])
    : 0;
  const BAC = displayVersion?.budget_at_completion?.amount
    ? Number(displayVersion.budget_at_completion.amount)
    : 0;
  const reserve = displayVersion?.output?.reserve
    ? Number(displayVersion.output.reserve)
    : 1;

  const formatCurrency = (value: number) =>
    `$${(value / 1000).toFixed(displayVersion?.float_formatter)}K`
  function findMaxNumber(arr: Array<number>) {
    if (arr.length === 0) {
      return 0
    }
    let maxNumber = arr[0]
    for (let i = 1; i < arr.length; i++) {
      if (arr[i] > maxNumber) {
        maxNumber = arr[i]
      }
    }

    return maxNumber
  }
  const handleChartUpdate = () => {
    let svgPaths = document.querySelectorAll('#chartCost .apexcharts-boxPlot-area')
    let estimated = svgPaths[3]
    let variance = svgPaths[4]

    if (estimated) {
      estimated.setAttribute('fill', '#7EFCB0')
      variance.setAttribute('fill', '#E53876')
    }
  }

  useEffect(() => {
    handleChartUpdate()
  }, [])
  let total = BAC + reserve

  const state: any = {
    series: [
      {
        type: 'boxPlot',
        data: [
          { x: 'Bac', y: [0, BAC, BAC + reserve, 0, total] },
          { x: 'EAC', y: [0, EAC, 0, EAC, EAC] },
          isVariance
            ? {
              x: 'Variance',
              y: [
                Math.min(BAC, Math.round(EAC)),
                Math.min(BAC, Math.round(EAC)),
                Math.max(BAC, Math.round(EAC)),
                Math.max(BAC, Math.round(EAC)),
              ],
            }
            : null,
        ].filter(Boolean),
      },
    ],
    options: {
      chart: {
        type: 'boxPlot',
        height: '100%',
        toolbar: { show: false },
        animations: {
          enabled: true,
          easing: 'linear',
          animateGradually: {
            enabled: true,
          },
        },
        events: {
          updated: (chart: any, options: any) => handleChartUpdate(), // This callback is triggered when the chart is updated
        },
      },
      stroke: {
        show: false,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '80%',
          distributed: true,
        },
        boxPlot: {
          colors: {
            upper: '#FFCC00',
            lower: '#FFFF00',
          },
          stroke: {
            show: false,
          },
        },
      },
      fill: {
        opacity: 1,
      },
      yaxis: {
        labels: {
          style: { colors: labelColor },
          formatter: (val: number) => formatCurrency(val),
        },
      },
      xaxis: {
        labels: {
          style: { colors: labelColor },
        },
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
          var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex]
          if (data.x === 'Variance' && EAC - (BAC + reserve) === 0) return null
          if (data.x === 'Bac')
            return (
              '<div  class="p-3 ">' +
              '<b> '
              + '<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#FFFF00" class="bi bi-circle-fill" viewBox="0 0 16 16">'
              + ' <circle cx="8" cy="8" r="8"/>' + ' </svg> ' +
              data.x +
              ': </b>' +
              formatCurrency(BAC) +
              '</div>' +
              '<div class="p-3">' +
              '<b> '
              + '<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#FFCC00" className="bi bi-circle-fill" viewBox="0 0 16 16">'
              + ' <circle cx="8" cy="8" r="8"/>' + ' </svg> ' +
              'Reserve' +
              ': </b>' +
              formatCurrency(reserve) +
              '</div>'
            )
          else if (data.x === 'EAC')
            return (
              '<div class="p-3">' +
              '<b> '
              + '<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#7EFCB0" class="bi bi-circle-fill" viewBox="0 0 16 16">'
              + ' <circle cx="8" cy="8" r="8"/>' + ' </svg> ' +
              data.x +
              ': </b>' +
              formatCurrency(findMaxNumber(data.y)) +
              '</div>'
            )
          else
            return (
              '<div class="p-3">' +
              '<b> '
              + '<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#E53876" class="bi bi-circle-fill" viewBox="0 0 16 16">'
              + ' <circle cx="8" cy="8" r="8"/>' + ' </svg> ' +
              data.x +
              ': </b>' +
              formatCurrency(Math.abs(BAC - EAC)) +
              '</div>'
            )
        },
      },
    },
  }

  return (
    <div id='chartCost'>
      <div className='card card-flush w-100 row h-50 g-1 mb-5 '>
        <div className='CPI-container'>
          <div className='card-header pt-5 d-grid'>
            <span className='text-dark widget-title'>Cost details</span>
          </div>
          <div
            className='d-flex align-items-center py-1 px-2 rounded bg-hover-light-primary gap-2 mt-2 justify-content-center '
            onClick={() => {
              setIsVariance(!isVariance)
            }}
          >
            <input
              role='button'
              className='form-check-input'
              type='checkbox'
              checked={isVariance}
              id='flexCheckDefault'
            />

            <span className='ml-2  fs-6 fw-bold'>Display Variance</span>
          </div>
          <ReactApexChart
            options={state.options}
            series={state.series}
            type='boxPlot'
            height={400}
          />
        </div>
      </div>
    </div>
  )
}
