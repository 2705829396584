import {FC, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'

interface CopyBtnProps {
  label: string
  onClick: any
  imagePath: string
  disabled?: boolean
}

const CopyBtn: FC<CopyBtnProps> = ({label, onClick, imagePath, disabled = false}) => {
  const [status, setStatus] = useState('initial')

  const handleClick = async () => {
    if (status !== 'initial') return

    setStatus('processing')

    onClick()
      .then(() => {
        setStatus('copied')
        setTimeout(() => {
          setStatus('initial')
        }, 2000)
      })
      .catch(() => {
        setStatus('error')
        setTimeout(() => {
          setStatus('initial')
        }, 2000)
      })
  }

  return (
    <button
      type='button'
      // className={`${styles.copyBtn} ${
      //   status !== 'initial' ? undefined : styles.active
      // }`}
      disabled={disabled}
      className='btn btn-secondary btn-sm d-flex align-items-center'
      onClick={handleClick}
    >
      <KTSVG className='d-none d-sm-block' path={imagePath} />
      <span>
        {status === 'initial'
          ? label
          : status === 'copied'
          ? 'Copied to clipboard!'
          : status === 'error'
          ? "Not copied, make sure window doesn't lose focus."
          : 'Being copied..'}
      </span>
    </button>
  )
}

export default CopyBtn
