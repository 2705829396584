import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { ProjectVersions } from '../projects/core/_models';
import { getVersionById } from '../projects/core/_requests';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

// Define the shape of the state
interface DataState {
  loading: boolean;
  data: Record<string, any>; // Hash map with IDs as keys
  error: string | null;
}

// Initial state with an empty hash map
const initialState: DataState = {
  loading: false,
  data: {},
  error: null,
};

// Create an async thunk to fetch data
export const fetchDataById = createAsyncThunk<any, string, { state: RootState }>(
  'data/fetchDataById',
  async (id, { getState }) => {
    const state = getState().versiondata;
    if(!state.data[id]){
      const dataa = await getVersionById(id);
      return dataa;
    } 
    else{
    return state.data[id]}
  }
);

// Create a slice to manage the data state
const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setVersionData: (state, action: PayloadAction<{ id: string, data: any }>) => {
      state.data[action.payload.id] = action.payload.data;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDataById.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDataById.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data[action.payload._id.$oid] = action.payload;  
      })
      .addCase(fetchDataById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch data';
      });
  },
});

export const { setVersionData } = dataSlice.actions;
export const setprojectversion = dataSlice.reducer;