import { useState } from 'react';
import { PageTitle } from '../../../../_metronic/layout/core';
import { PageTitle as PageBreadCrumbs } from '../../../../_metronic/layout/components/toolbar/page-title/PageTitle';
import Wbss from './Wbss';
import TreeView from './TreeView/TreeView';
import { TreeProvider } from './TreeView/TreeContext';
import HierarchyView from '../../projects/core/hierarchy/HierarchyView';
import clsx from 'clsx';

interface Props {
  currentStep?: number
}

export default function WbsContainer({ currentStep }: Props) {
  const [currentView, setCurrentView] = useState('tree_view');

  const handleNavigation = (view: string) => {
    setCurrentView(view);
  };

  const alertUnderDevelopment = () => {
    alert('Under Development');
  }

  return (
    <>
      <div className='mt-5'>
        <div className='d-flex flex-row justify-content-between'>
          <PageTitle breadcrumbs={[]}>WBS Structure</PageTitle>
          <PageBreadCrumbs />
        </div>

        <nav className='d-flex overflow-auto h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item'>
              <a
                href='#'
                className={clsx(`nav-link text-active-primary me-6`, {
                  active: currentView === 'tree_view',
                })}
                onClick={() => handleNavigation('tree_view')}
              >
                Tree View
              </a>
            </li>
            <li className='nav-item'>
              <a
                href='#'
                className={clsx(`nav-link text-active-primary me-6`, {
                  active: currentView.includes('folder_view'),
                })}
                // onClick={() => handleNavigation('folder_view')}
                onClick={alertUnderDevelopment}
              >
                Folder View
              </a>
            </li>
            <li className='nav-item'>
              <a
                href='#'
                className={clsx(`nav-link text-active-primary me-6`, {
                  active: currentView === 'hierarchy_view',
                })}
                // onClick={() => handleNavigation('hierarchy_view')}
                onClick={alertUnderDevelopment}
              >
                Hierarchy View
              </a>
            </li>
          </ul>
        </nav>

        <div>
          {currentView === 'tree_view' && <TreeView currentStep={currentStep} />}
          {currentView === 'folder_view' && <Wbss />}
          {currentView === 'hierarchy_view' && <HierarchyView />}
        </div>
      </div>
    </>
  );
}
