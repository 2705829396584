import {useQuery} from 'react-query'
import {getSimulations, getSimulationsById} from './_requests'
export interface SimulationData {
  page: number
  perPage: number
  filter: string
  name?: string
}
export const useSimulationsData = (simulationData: SimulationData) => {
  return useQuery(['simulations', simulationData], () => getSimulations(simulationData), {
    refetchOnWindowFocus: false,
  })
}

export const useSimulationsByIdData = (
  id: string,
  onSuccessCallback?: any,
  enabled: boolean = true,
  onError?: ((err: unknown) => void) | undefined
) => {
  return useQuery(['simulationsById', id], () => getSimulationsById(id), {
    refetchOnWindowFocus: false,
    onSuccess: onSuccessCallback,
    enabled,
    retry: 1,
    onError,
  })
}
