import { getProjectImageLink } from '../../core/_requests'

interface Props {
  setImage: (value: any) => void
  image: any
  projectId?: string
}

export default function UploadProjectImage({ setImage, image, projectId }: Props) {
  const isDefaultImage = !image && !projectId; // Check if the image is the default

  return (
    <>
      <div
        className=' image-input image-input-outline'
        data-kt-image-input='true'
        style={{
          backgroundImage: 'url("/media/logos/default-small.svg")',
        }}
      >
        {/*begin::Preview existing avatar*/}
        {(image || projectId) && (
          <img
            src={
              image
                ? URL.createObjectURL(image)
                : projectId
                  ? getProjectImageLink(projectId || '')
                  : '/media/svg/files/upload.svg'
            }
            alt='Profile'
            onError={(e) => ((e.target as HTMLImageElement).src = '/media/logos/default-small.svg')}
            className='profile-photo image-input-wrapper'
          />
        )}
        {!image && !projectId && (
          <img
            src='/media/logos/default-small.svg'
            className='profile-photo image-input-wrapper'
            alt='Profile'
          />
        )}
        {/*end::Preview existing avatar */}

        {/*begin::Label*/}
        <label
          className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
          data-kt-image-input-action='change'
          data-bs-toggle='tooltip'
          title='Change avatar'
        >
          <i className='bi bi-pencil-fill fs-7' />
          {/*begin::Inputs*/}
          <input
            type='file'
            name='avatar'
            accept='.png, .jpg, .jpeg'
            onChange={(e) => {
              setImage(!!e?.target?.files && e?.target?.files[0])
            }}
          />
          <input
            type='hidden'
            name='avatar_remove'
            onClick={() => {
              setImage(null);
            }}
          />
          {/*end::Inputs*/}
        </label>
        {/*end::Label*/}
        {/*begin::Cancel*/}
        <span
          className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
          data-kt-image-input-action='cancel'
          data-bs-toggle='tooltip'
          title='Cancel avatar'
        >
          <i className='bi bi-x fs-2' />
        </span>
        {/*end::Cancel*/}

        {/*begin::Remove*/}
        {!isDefaultImage && (
          <span
            className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
            data-kt-image-input-action='remove'
            data-bs-toggle='tooltip'
            title='Remove avatar'
            onClick={() => {
              setImage(null);
            }}
          >
            <i className='bi bi-x fs-2' />
          </span>
        )}
        {/*end::Remove*/}
      </div>
      <div className='form-text'>Allowed file types: png, jpg, jpeg.</div>
    </>
  )
}
