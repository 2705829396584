import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CheckedState {
  value: boolean;
}

const initialCheckedState: CheckedState = {
  value: false,
};

interface LoaderState {
  value: boolean;
}

const initialLoaderState: LoaderState = {
  value: false,
};

interface RenamingState {
  value: boolean;
}

const initialRenamingState: RenamingState = {
  value: false,
};

interface IsHeaderState {
  value: boolean;
}

const initialIsHeaderState: IsHeaderState = {
  value: false,
};

interface MaxDataDateState {
  value: string;
}

const initialMaxDataDateState: MaxDataDateState = {
  value: '',
};
interface currentwbsState {
  value: string|number;
}

const initialcurrentwbsState: currentwbsState = {
  value: '',
};

const wbstreechecked = createSlice({
  name: 'checked',
  initialState: initialCheckedState,
  reducers: {
    open: (state: CheckedState) => {
      state.value = true;
    },
    close: (state: CheckedState) => {
      state.value = false;
    },
  },
});

const wbstreeloading = createSlice({
  name: 'loader',
  initialState: initialLoaderState,
  reducers: {
    loaded: (state: LoaderState) => {
      state.value = false;
    },
    loading: (state: LoaderState) => {
      state.value = true;
    },
  },
});

const wbstreerenaming = createSlice({
  name: 'renaming',
  initialState: initialRenamingState,
  reducers: {
    isrename: (state: RenamingState) => {
      state.value = true;
    },
    isntrename: (state: RenamingState) => {
      state.value = false;
    },
  },
});

const IsHeader = createSlice({
  name: 'isheader',
  initialState: initialIsHeaderState,
  reducers: {
    isheader: (state: IsHeaderState) => {
      state.value = true;
    },
    isntheader: (state: IsHeaderState) => {
      state.value = false;
    },
  },
});

const maxdatadate = createSlice({
  name: 'maxdatadate',
  initialState: initialMaxDataDateState,
  reducers: {
    setmaxdatadate: (state: MaxDataDateState, action: PayloadAction<string>) => {
      state.value = action.payload;
    },
  },
});

const currentwbs = createSlice({
  name: 'currentwbs',
  initialState: initialcurrentwbsState,
  reducers: {
    setcurrentwbs: (state: currentwbsState, action: PayloadAction<string |number>) => {
      state.value = action.payload;
    },
  },
});

export const { open, close } = wbstreechecked.actions;
export const { loaded, loading } = wbstreeloading.actions;
export const { isrename, isntrename } = wbstreerenaming.actions;
export const { isheader, isntheader } = IsHeader.actions;
export const { setmaxdatadate } = maxdatadate.actions;
export const {setcurrentwbs}=currentwbs.actions

const treeStates = {
  checked: wbstreechecked.reducer,
  loader: wbstreeloading.reducer,
  renaming: wbstreerenaming.reducer,
  isheader: IsHeader.reducer,
  maxdatadate: maxdatadate.reducer,
  currentwbs:currentwbs.reducer
};

export default treeStates;
