/* eslint-disable jsx-a11y/anchor-is-valid */
import { StepProps } from '../IProjectModels'
import TreeDataView from '../../../../../app/modules/wbs_structure/components/TreeView/TreeDataView'
import { Step5DataSection } from './Step5DataSection'

const Step5 = ({ formik, currentStep }: StepProps) => {

  return (
    <>

      <div className='pb-5' data-kt-stepper-element='content'>
        <div className='row w-100 mt-3'>
          <div className='col-md-3'>
            <TreeDataView currentStep={currentStep} />
          </div>
          <div className='col-md-9'>
            <Step5DataSection formik={formik} />
          </div>
        </div>
      </div>

    </>
  )
}

export { Step5 }
