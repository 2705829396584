/* eslint-disable jsx-a11y/anchor-is-valid */
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {Languages} from './Languages'
import {getUserImageLink} from '../../../../app/modules/auth/core/_requests'
import {useEffect} from 'react'
import {MenuComponent} from '../../../assets/ts/components'

const HeaderUserMenu = ({isMobile}: {isMobile: boolean}) => {
  const {currentUser, logout} = useAuth()
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  return (
    <div
      className={`menu menu-sub ${
        isMobile ? 'class show' : 'menu-sub-dropdown'
      } menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-375px`}
      data-kt-menu='true'
      id='rightBar'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            {currentUser?.image?.$oid ? (
              <img src={getUserImageLink(currentUser?._id.$oid || '')} alt='' />
            ) : (
              <div
                className='bg-secondary d-flex align-items-center justify-content-center rounded'
                style={{width: '50px', height: '50px'}}
              >
                <h4>
                  {currentUser?.full_name
                    ?.split(' ')
                    .reduce((acc, val) => acc.charAt(0) + val.charAt(0))
                    .toUpperCase()}
                </h4>
              </div>
            )}
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>{currentUser?.full_name}</div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to={'/profile'} className='menu-link px-5'>
          My Profile
        </Link>
      </div>

      <div className='menu-item px-5'>
        <Link to='/profile/billing' className='menu-link px-5'>
          <span className='menu-text'>My Subscription</span>
        </Link>
      </div>
      {currentUser?.role === 'team_owner' && (
        <div className='menu-item px-5'>
          <Link to='/team/users' className='menu-link px-5'>
            My Team
          </Link>
        </div>
      )}
      {currentUser?.role === 'particular' && (
        <div className='menu-item px-5'>
          <Link to='/team/invitations' className='menu-link px-5'>
            My Invitations
          </Link>
        </div>
      )}
      <div className='separator my-2'></div>

      <Languages />

      <div className='menu-item px-5 my-1'>
        <Link to='/profile/settings' className='menu-link px-5'>
          Account Settings
        </Link>
      </div>

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Logout
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
