/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { StepProps } from '../IProjectModels'
import CustomSelect from '../../../../../app/components/CustomSelect'
import { DatePicker, DatePickerProps } from 'antd'
import dayjs from 'dayjs'
import { useEffect } from 'react'
import moment from 'moment'
import { getDateFormat } from '../../../../../app/modules/simulations/core/SimulationHelpers'
import { Calendar } from '../../../../../app/modules/calendars/_models'
import { useCalendars } from '../../../../../app/modules/calendars/_queries'

const dateUnitOptions = [
  { value: 'daily', label: 'day', isDisabled: true },
  { value: 'weekly', label: 'week', isDisabled: true },
  { value: 'monthly', label: 'month' },
]

const DisplayTypeOptions = [
  { value: 'name', label: 'Name' },
  { value: 'index', label: 'Index' },
  { value: 'both', label: 'Name and Index' },
]

const dateFormatOptions = [
  { value: 'mm/dd/yyyy', label: 'mm/dd/yyyy' },
  { value: 'dd/mm/yyyy', label: 'dd/mm/yyyy' },
]

const Step2 = ({ formik }: StepProps) => {
  const { data: calendars } = useCalendars({ page: 1, per_page: 0 })

  const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    formik.setFieldValue(
      'start_date',
      formik.values.date_unit === 'monthly'
        ? date?.format('YYYY-MM')
        : date?.set('hour', 0).toDate()
    )
  }
  const onEndChange: DatePickerProps['onChange'] = (date, dateString) => {
    formik.setFieldValue(
      'end_date',
      formik.values.date_unit === 'monthly'
        ? date?.format('YYYY-MM')
        : date?.set('hour', 0).toDate()
    )
  }
  const onDataChange: DatePickerProps['onChange'] = (date, dateString) => {
    formik.setFieldValue(
      'data_date',
      formik.values.date_unit === 'monthly'
        ? date?.format('YYYY-MM')
        : date?.set('hour', 0).toDate()
    )
  }
  useEffect(() => {
    if (formik.values.date_unit === 'monthly') {
      formik.setFieldValue('data_date', moment(formik.values.data_date).format('YYYY-MM'))
    }
  }, [formik.values.date_unit])
  return (
    <div className='pb-5' data-kt-stepper-element='content'>
      <div className='w-100'>
        {/*begin::Form Group */}
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
            <span className='required'>Date Format</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title=''
            ></i>
          </label>
          <CustomSelect
            name='date_format'
            value={dateFormatOptions.find((item) => item.value === formik.values.date_format)}
            options={dateFormatOptions}
            onChange={(d: any) => {
              formik.setFieldValue('date_format', d.value)
            }}
          />
          {formik.touched.date_format && formik.errors.date_format && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.date_format}</span>
              </div>
            </div>
          )}
        </div>
        {/*end::Form Group */}
        {/*begin::Form Group */}
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
            <span className='required'>Date Unit</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title=''
            ></i>
          </label>

          <CustomSelect
            name='date_unit'
            value={dateUnitOptions.find((item) => item.value === formik.values.date_unit)}
            options={dateUnitOptions}
            onChange={(d: any) => {
              formik.setFieldValue('date_unit', d.value)
            }}
          />
          {formik.touched.date_unit && formik.errors.date_unit && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.date_unit}</span>
              </div>
            </div>
          )}
        </div>
        {/*end::Form Group */}

        {/*begin::Form Group */}
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
            <span className='required'>WBS Display Type</span>
            <i className='fas fa-exclamation-circle ms-2 fs-7' data-bs-toggle='tooltip' title=''></i>
          </label>

          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              id="displayTypeName"
              name="display_type"
              value="name"
              checked={formik.values.display_type.includes('name')}
              onChange={(e) => {
                const value = e.target.value;
                const isChecked = formik.values.display_type.includes(value);
                if (isChecked && formik.values.display_type.length > 1) {
                  formik.setFieldValue(
                    'display_type',
                    formik.values.display_type.filter((type: string) => type !== value)
                  );
                } else if (!isChecked) {
                  formik.setFieldValue('display_type', [...formik.values.display_type, value]);
                }
              }}
            />
            <label className="form-check-label" htmlFor="displayTypeName">
              Name
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              id="displayTypeIndex"
              name="display_type"
              value="index"
              checked={formik.values.display_type.includes('index')}
              onChange={(e) => {
                const value = e.target.value;
                const isChecked = formik.values.display_type.includes(value);
                if (isChecked && formik.values.display_type.length > 1) {
                  formik.setFieldValue(
                    'display_type',
                    formik.values.display_type.filter((type: string) => type !== value)
                  );
                } else if (!isChecked) {
                  formik.setFieldValue('display_type', [...formik.values.display_type, value]);
                }
              }}
            />
            <label className="form-check-label" htmlFor="displayTypeIndex">
              Index
            </label>
          </div>
          {formik.touched.display_type && formik.errors.display_type && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.display_type}</span>
              </div>
            </div>
          )}
        </div>

        {/*end::Form Group */}
      </div>
    </div>
  )
}

export { Step2 }
