
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: "",
};

const currentIdSlice = createSlice({
  name: 'currentId',
  initialState,
  reducers: {
    setCurrentId: (state, action) => {
      state.id = action.payload;
    },
  },
});

export const { setCurrentId } = currentIdSlice.actions;

export default currentIdSlice.reducer;
