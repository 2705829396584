import { FC, Suspense } from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import { PrivateRoutes } from './PrivateRoutes';
import { ErrorsPage } from '../modules/errors/ErrorsPage';
import { Logout, AuthPage, useAuth } from '../modules/auth';
import { App } from '../App';
import ProjectPageShare from '../modules/projects/ProjectPageShare';
import { ProjectProvider } from '../modules/projects/core/ProjectContext'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import TopBarProgress from 'react-topbar-progress-indicator'
import ProjectSnapshotSharePage from '../modules/projects/ProjectSnapshotSharePage';



const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {
  const { currentUser } = useAuth()
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />

          <Route
            path='projectshare/:id/*'
            element={
              <SuspensedView>
                <ProjectProvider>
                  <ProjectPageShare />
                </ProjectProvider>
              </SuspensedView>
            }
          />

          <Route
            path='snapshotshare/:id/*'
            element={
              <SuspensedView>
                <ProjectProvider>
                  <ProjectSnapshotSharePage />
                </ProjectProvider>
              </SuspensedView>
            }
          />

          {currentUser ? (
            <>
              {/* auth required to privateroutes */}

              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/dashboard' />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}
export { AppRoutes }
