import React from 'react'

interface EmptyValuesCardProps {
  title: string
  text: string
  buttonText?: string
  setShowCreateAppModal?: any
  className?: string
}

function EmptyValuesCard({
  buttonText,
  setShowCreateAppModal,
  text,
  title,
  className,
}: EmptyValuesCardProps) {
  return (
    <div className='card'>
      {/*begin::Card body*/}
      <div className={`card-body py-20 ${className}`}>
        {/*begin::Wrapper*/}
        <div className='card-px text-center  '>
          {/*begin::Title*/}
          <h2 className='fs-2x fw-bold mb-10'>{title}</h2>
          {/*end::Title*/}
          {/*begin::Description*/}
          <p className='text-gray-400 fs-4 fw-semibold mb-10'>{text}</p>
          {/*end::Description*/}
          {/*begin::Action*/}
          {setShowCreateAppModal && (
            <button onClick={() => setShowCreateAppModal(true)} className='btn btn-primary'>
              {buttonText}
            </button>
          )}
          {/*end::Action*/}
        </div>
        {/*end::Wrapper*/}
        {/*begin::Illustration*/}
        <div className='text-center '>
          <img className='mw-100 mh-300px' src='/media/illustrations/sketchy-1/2.png' />
        </div>
        {/*end::Illustration*/}
      </div>
      {/*end::Card body*/}
    </div>
  )
}

export default EmptyValuesCard
