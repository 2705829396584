import {useEffect, useState} from 'react'
import GaugeComponent from 'react-gauge-component'
import {getCSSVariableValue} from '../../../_metronic/assets/ts/_utils'
import {useThemeMode} from '../../../_metronic/partials'
import {formatScheduleVariance} from '../../modules/projects/components/overview/_helpers'
import {useProject} from '../../modules/projects/core/ProjectContext'
import {formatFloatFromString} from '../../utils/funcs'

type Props = {
  type: 'CPI' | 'SPI'
}

export default function CPIGauge({type}: Props) {
  const {displayVersion, project} = useProject()
  const warningColor = getCSSVariableValue('--kt-warning')
  const successColor = getCSSVariableValue('--kt-success')
  const dangerColor = getCSSVariableValue('--kt-danger')
  const [dark, setDark] = useState(getCSSVariableValue('--kt-dark'))

  const realValue =
    (type === 'CPI'
      ? displayVersion?.output?.cpi?.cumulative?.at(-1)
      : displayVersion?.output?.SPICum?.at(-1)) || 0
  const gaugeValue = realValue > 2 ? 2 : realValue < 0 ? 0 : realValue

  const {mode} = useThemeMode()
  useEffect(() => {
    setDark(getCSSVariableValue('--kt-dark'))
  }, [mode])

  return (
    <div className='card card-flush w-100 h-100 mb-5'>
      <div className='CPI-container'>
        <div className='card-header pt-5 d-grid'>
          <span className='text-dark widget-title'>
            {type === 'CPI' ? 'Cost performance index' : 'Schedule Performance index'}
          </span>
        </div>
        <div className='card-body d-flex flex-column align-items-center justify-content-end mt-6'>
          <GaugeComponent
            minValue={0}
            maxValue={2}
            style={{textAlign: 'center'}}
            labels={{
              valueLabel: {
                formatTextValue: (value: number) =>
                  (type === 'CPI'
                    ? displayVersion?.output?.cpi?.cumulative?.at(-1)
                    : displayVersion?.output?.SPICum?.at(-1)) === undefined
                    ? '-'
                    : realValue.toFixed(displayVersion?.float_formatter || 0),

                style: {
                  fill:
                    realValue < 0.8
                      ? getCSSVariableValue('--kt-danger')
                      : realValue >= 1
                      ? getCSSVariableValue('--kt-success')
                      : getCSSVariableValue('--kt-warning'),
                  textShadow: 'none',
                },
              },
              tickLabels: {
                ticks: [{value: 0}, {value: 1}, {value: 2}],
                defaultTickLineConfig: {
                  style: {fill: dark},
                },
                defaultTickValueConfig: {
                  style: {
                    color: dark,
                    fill: dark,
                  },
                },
              },
            }}
            arc={{
              width: 0.15,
              padding: 0.03,
              subArcs: [
                {
                  limit: +(project?.gauge_params?.red_limit ?? 0.8),
                  color: dangerColor,
                },

                {
                  limit: +(project?.gauge_params?.yellow_limit ?? 1),
                  color: warningColor,
                },
                {color: successColor},
              ],
            }}
            value={gaugeValue}
            pointer={{type: 'arrow', elastic: true, color: dark}}
            type='radial'
          />
          {type === 'CPI' ? (
            <div className='cpi-text-body text-center fw-bold d-flex gap-5'>
              <div>
                CV ={' '}
                {displayVersion?.output?.CardsData?.CostPerformance?.CV?.Cumulative !== undefined
                  ? formatFloatFromString(
                      displayVersion?.output?.CardsData?.CostPerformance?.CV?.Cumulative,
                      displayVersion?.float_formatter || 0
                    )
                  : '-'}
              </div>
              <div>
                EAC={' '}
                {displayVersion?.output?.CardsData?.CostPerformance?.EAC?.Value !== undefined
                  ? formatFloatFromString(
                      displayVersion?.output?.CardsData?.CostPerformance?.EAC?.Value,
                      displayVersion?.float_formatter || 0
                    )
                  : '-'}
              </div>
            </div>
          ) : (
            <div className='cpi-text-body text-center fs-5 fw-bold d-flex gap-5'>
              <div>
                SV(t) ={' '}
                {displayVersion?.output?.CardsData?.SchedulePerformance?.SVT?.cumulative
                  ? formatScheduleVariance(displayVersion?.output?.SVtCum?.at(-1))
                  : '-'}
              </div>
              <div>
                EED={' '}
                {displayVersion?.output?.CardsData?.SchedulePerformance?.EED?.Value
                  ? displayVersion?.output.CardsData.SchedulePerformance.EED.Value
                  : '-'}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
