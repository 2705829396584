/* eslint-disable jsx-a11y/anchor-is-valid */
import ApexCharts, { ApexOptions } from 'apexcharts'
import type { FC } from 'react'
import { useEffect, useRef } from 'react'
import { getCSS, getCSSVariableValue } from '../../../../../_metronic/assets/ts/_utils'
import { useThemeMode } from '../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import useFormatter from '../../../../../hooks/useFormatter'
import EmptyValuesCard from '../../../../components/emptyValuesCard'
import { findSym } from '../../../../helpers/func'
import { formatNumber } from '../../../../utils/formatter'
import { CandleChartType } from './Project'
import { formatScheduleVariance } from './_helpers'
import { sameMonthAndYear } from '../../../../utils/data-transformarion/date-utils'
import { getFormattedUnit } from '../../../../utils/data-transformarion/budget-utils'

// function getChartOptions(height: number): ApexOptions {
interface ICandleStickChart {
  chartName: string
  className: string
  isRebaselined: boolean
  projectData: any
  CandleType: CandleChartType
  data: any
}

const CandleStickChart: FC<ICandleStickChart> = ({
  chartName,
  className,
  isRebaselined,
  projectData,
  CandleType,
  data,
}) => {
  const formatter = useFormatter()
  const chartRef = useRef<HTMLDivElement | null>(null)
  const { mode } = useThemeMode()
  const formatCandleStickData = (objectArray: any) => {
    const dataToFormat =
      CandleType === CandleChartType.SV ? projectData?.SVtCum : projectData?.CVCum
    if (!objectArray || !dataToFormat) return []
    const newObjectArray = [
      { x: 'First Date', y: [0, 0, +dataToFormat[0], +dataToFormat[0]] },
      ...objectArray,
    ]
    return newObjectArray.map((item: any, index: number) => {
      let startDate = new Date(data.start_date.$date)
      let nextDay = startDate
      if (data.delay_tolerance.type === 'monthly') nextDay.setMonth(nextDay.getMonth() + index)
      else if (data.delay_tolerance.type === 'yearly')
        nextDay.setMonth(nextDay.getMonth() + 12 * index)
      else nextDay.setDate(nextDay.getDate() + index)
      return {
        y: item.y.map((value: any) => +value?.toFixed(data.float_formatter)),
        x: formatter(new Date(nextDay)),
      }
    })
  }
  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, data])
  const transformObjects = (objects: any[], dateToZero: any) => {
    objects.forEach((obj: any) => {
      let ep = sameMonthAndYear(new Date(obj.x).setFullYear(new Date(data?.start_date?.$date).getFullYear()), dateToZero)
      if (ep) {
        obj.y = obj.y.map(() => 0);
      }
    });
    return objects;
  }
  const chartValues =
    CandleType === CandleChartType.SV
      ? transformObjects(formatCandleStickData(projectData?.scheduleVarianceDataCandle), data?.rebase_line[data?.rebase_line?.length - 1]?.data_date) :
      formatCandleStickData(projectData?.CVCumDataCandle)

  // to do
  // CandleType === CandleChartType.SV
  // ?  transformObjects(formatCandleStickData(projectData?.scheduleVarianceDataCandle), data?.rebase_line[data?.rebase_line?.length - 1]?.data_date) :
  //   formatCandleStickData(projectData?.CVCumDataCandle) : []


  const getChartOptions = (_height: number): ApexOptions => {
    const labelColor = getCSSVariableValue('--kt-gray-500')
    const baseColor = getCSSVariableValue('--kt-primary')
    const darkColor = getCSSVariableValue('--kt-dark')

    return {
      plotOptions: {
        candlestick: {
          colors: {
            downward: getCSSVariableValue('--kt-danger'),
            upward: getCSSVariableValue('--kt-success'),
          },
        },
      },
      series: [
        {
          name: 'Candle',
          type: 'candlestick',
          data: chartValues,
        },
      ],
      chart: {
        height: 350,
        type: 'candlestick',
        toolbar: {
          offsetY: -15,
        },
      },
      stroke: {
        width: [1, -1],
      },
      tooltip: {
        shared: false,
        custom: [
          function ({ seriesIndex, dataPointIndex, w }: any) {
            return w.globals.series[seriesIndex][dataPointIndex]
          },
          function ({ seriesIndex, dataPointIndex, w }: any) {
            const h = w.globals.seriesCandleH[seriesIndex][dataPointIndex]
            const l = w.globals.seriesCandleL[seriesIndex][dataPointIndex]
            return (
              '<div' +
              '<div>High: <span class="value">' +
              h +
              '</span></div>' +
              '<div>Low: <span class="value">' +
              l +
              '</span></div>' +
              '</div>'
            )
          },
        ],
      },
      xaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        type: 'category',
        labels: {
          rotateAlways: true,
          rotate: -45,
          style: {
            colors: darkColor,
            fontSize: '12px',
          },
        },
      },
      yaxis: {
        max: Math.max(
          ...[
            ...((CandleType === CandleChartType.SV ? projectData?.SVtCum : projectData?.CVCum) ||
              []),
            0,
          ]
        ),
        min: Math.min(
          ...[
            ...((CandleType === CandleChartType.SV ? projectData?.SVtCum : projectData?.CVCum) ||
              []),
            0,
          ]
        ),
        labels: {
          style: {
            colors: darkColor,
            fontSize: '12px',
          },
        },
      },
      grid: {
        borderColor: baseColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      legend: {
        offsetY: 7,

        fontSize: '12px',
        labels: {
          colors: labelColor,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: { width: '380px' },
          },
        },
      ],
      annotations: {
        points: [
          {
            x: chartValues.at(-1)?.x,
            y:
              CandleType === CandleChartType.SV
                ? +projectData?.scheduleVarianceData[0]?.y || projectData?.SVtCum[0]
                : +projectData?.CVPeriodData[0]?.y || projectData?.CVCum[0],

            marker: {
              size: 5,
            },
            label: {
              text:
                CandleType === CandleChartType.SV
                  ? formatScheduleVariance(
                    projectData?.scheduleVarianceData[0]?.y || projectData?.SVtCum[0]
                  )
                  : formatNumber(
                    projectData?.CVPeriodData[0]?.y || projectData?.CVCum[0],
                    data.float_formatter,
                    '.'
                  ),
            },
          },
        ],
        yaxis: [
          {
            y: 0,
            borderColor: darkColor,
            fillColor: darkColor,
            strokeDashArray: 0,
            opacity: 1,
            offsetX: -10,
            borderWidth: 2.5,
          },
        ],
      },
    }
  }

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }
    if (JSON.stringify(projectData) === '{}') return
    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height))
    if (chart) {
      chart.render()
    }

    return chart
  }

  return (
    <div className={`${className}`}>
      {/* begin::Header */}
      <div className='d-flex flex-column align-items-center pt-5'>
        {/* begin::Title */}
        <h1 className='fw-bold mb-1'>
          {CandleType === CandleChartType.CV
            ? `CV(${getFormattedUnit(data.budget_at_completion.unit)}${findSym(
              data.budget_at_completion.currency
            )})`
            : 'SV(t)'}
        </h1>
        <h3>{CandleType === CandleChartType.CV ? 'Cost Variance' : 'Schedule Variance'}</h3>
        {/* end::Title */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body'>
        {/* begin::Chart */}
        {JSON.stringify(projectData) === '{}' || !projectData?.earnedValues?.mixed ? (
          <EmptyValuesCard text='Make sure there is no missing data!' title='Data Unavailable' />
        ) : (
          <div ref={chartRef} id='kt_charts_widget_1_chart' style={{ height: '350px' }} />
        )}

        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export default CandleStickChart
