import dayjs from 'dayjs'
import moment from 'moment'
import {Calendar} from '../../modules/calendars/_models'
import {
  applyCalendarToIncompleteCurve,
  getCapacity,
  getReelDays,
} from '../../modules/library/ProjectCurve/Context/ProjectCurveHelpers'
import {getPeriodicFromCumulative} from '../../modules/projects/components/data/prepareDataTable'
import {getCustomCurve} from '../../modules/projects/components/overview/Project'

const forecastSpit = async (
  inputs: any,
  data: any,
  cumulativeEarnedValuesAsPercentages: any,
  SPIt: number[],
  EarnedSchedule: number[],
  calendar?: Calendar
) => {
  const currentSpit = SPIt[SPIt.length - 1]
  const forecastLength = Math.round(
    getCustomCurve('cumulativePlannedValue', data).length / currentSpit
  )

  const cumulativePlannedValuesAsPercentages = getCustomCurve('cumulativePlannedValue', data).map(
    (item) => (+item / data.budget_at_completion.amount) * 100
  )

  let esForecast = Array(forecastLength - cumulativeEarnedValuesAsPercentages.length).fill(0)

  let currentES = EarnedSchedule[EarnedSchedule.length - 1]
  for (let i = 0; i < forecastLength - cumulativeEarnedValuesAsPercentages.length; i++) {
    currentES = currentES + currentSpit
    esForecast[i] = currentES
  }

  let evForecast = esForecast.map((item) => {
    const t1 = Math.floor(item)
    const t2 =
      Math.ceil(item) >= cumulativePlannedValuesAsPercentages.length
        ? cumulativePlannedValuesAsPercentages.length
        : Math.ceil(item)

    return (
      (item - t1) *
        (cumulativePlannedValuesAsPercentages[t2 - 1] -
          cumulativePlannedValuesAsPercentages[t1 - 1]) +
      cumulativePlannedValuesAsPercentages[t1 - 1]
    )
  })

  evForecast[evForecast.length - 1] = 100



  let cumulativeEv = [...cumulativeEarnedValuesAsPercentages, ...evForecast]

  if (calendar) {
    const {reelDays, totalDays} = await getReelDays(
      dayjs(data.data_date.$date).add(1, 'month').toDate(),
      evForecast.length,
      calendar
    )
    const capacity = getCapacity(reelDays, totalDays)
    evForecast = applyCalendarToIncompleteCurve(
      capacity,
      evForecast,
      cumulativeEarnedValuesAsPercentages
    ).tableData[1]

    cumulativeEv = [...cumulativeEarnedValuesAsPercentages, ...evForecast]
  }

  return {
    tableData: [
      Array.from({length: forecastLength}).map((_el, idx) =>
        moment(data.start_date.$date).add(idx, 'month')
      ),
      cumulativeEv.map((item) => item + ''),
      getPeriodicFromCumulative(cumulativeEv).map((item) => item + ''),
    ],
    nbOfRemainingMonths: forecastLength - cumulativeEarnedValuesAsPercentages.length,
  }
}

export {forecastSpit}
