export interface Invoice {
  amount?: number
  created: number
  description?: string
  number?: string
  invoice_type?: string
  stripe_direct_url?: string
  stripe_dowload_url?: string
  user?: string
}
export enum Range {
  MONTH = 'MONTH',
  YEAR = 'YEAR',
  ALL_TIME = 'ALL_TIME',
}
export interface Product {
  name: string
  description?: string
  images: string[]
  prices: Price[]
}

export interface Price {
  price_id: string
  tiers_mode?: string
  amount: number
  description?: string
  tiers: any
  currency?: string
  tax_behavior: string
  slots: Slots
  type: string
  recurring_interval?: string
  trial_period_days?: number
}

export interface Slots {
  'slot-simulation': number
  'slot-project': number
  'slot-trial-project'?: number
  'slot-trial-simulation'?: number
  'slot-user'?: number
  'slot-trial-user'?: number
}
export enum ProductType {
  SUB = 'subscription',
  SLOT = 'slot',
}

export enum SlotType {
  //to be fixed to simulation once the backend is fixed
  SIM = 'Simultation',
  PROJ = 'Project',
  USR = 'User',
}
