/* eslint-disable jsx-a11y/anchor-is-valid */

import { useProject } from '../../core/ProjectContext'
import { ShowData } from './ShowData'

export function TestWbs() {
  const { displayVersion: data, beforeFirstStep, afterFirstStep, beforeSecondStep } = useProject()

  return (
    <>
      <h2>You need to change the datadate to test the calculation</h2>
      <h3>WPs before first step</h3>
      {(beforeFirstStep && beforeFirstStep.length > 0) &&
        (beforeFirstStep.map((wp: any, idx: string | number) =>

          <ShowData title={'WP' + idx} data={wp} />

        )
        )}


      <h3>WPs after first step</h3>
      {(afterFirstStep && afterFirstStep.length > 0) &&
        (afterFirstStep.map((wp: any, idx: string | number) =>
          <ShowData title={'WP' + idx} data={wp} />

        )
        )}

      {beforeSecondStep &&
        (
          <>
            <h3>WBS before second step</h3>
            <ShowData title={'WBS Before'} data={beforeSecondStep} />
          </>

        )}

      {data &&
        (
          <>
            <h3>WBS after second step</h3>
            <ShowData title={'WBS After'} data={data} />
          </>

        )}


    </>
  )
}
