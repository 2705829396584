import axios, {AxiosResponse} from 'axios'
import * as authHelper from '../../modules/auth/core/AuthHelpers'

const API_URL_TEAMS = `${process.env.REACT_APP_API_URL}/teams`
export const getTeam = () => {
  return axios
    .get(API_URL_TEAMS, {
      headers: {Authorization: 'Bearer ' + authHelper.getAuth()?.api_token},
    })
    .then((d: AxiosResponse<any>) => d.data)
}
