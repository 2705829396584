import { useIntl } from 'react-intl'
import { MenuItem } from './MenuItem'
import { MenuInnerWithSub } from './MenuInnerWithSub'
import { MegaMenu } from './MegaMenu'
import { useAuth } from '../../../../../app/modules/auth'
import { useNavigate } from 'react-router-dom'
import useFreeTrialAdd from '../../../../../app/components/useFreeTrialAdd'
import { sweetAlert } from '../../../../../app/utils/funcs'

export function MenuInner() {
  const { currentUser, currentPlanLevel } = useAuth()
  const intl = useIntl()
  const navigate = useNavigate()
  const { FreeTrialAddComponent, showAddTrial, setShowAddTrial } = useFreeTrialAdd(currentUser, sweetAlert)

  const handlePaywallClick = (to: string) => {
    if (currentPlanLevel > 1) {
      navigate(to);
    } else {
      setShowAddTrial(true);
    }
  };

  const handleMenuItemClick = (path: string) => (e: React.MouseEvent<HTMLAnchorElement>) => {
    handlePaywallClick(path);
  };

  return (
    <>
      <MenuItem title={intl.formatMessage({ id: 'MENU.DASHBOARD' })} to='/dashboard' />
      <MenuItem title='Projects' to='/projects' />
      <MenuItem title='Simulations' to='#' onClick={handleMenuItemClick('/simulations')} />
      {/* {currentUser?.role === "team_owner" && <MenuItem title='Users' to='/team/users' />} */}
      <MenuInnerWithSub
        title='Entreprise'
        to='/enterprise'
        menuPlacement='bottom-start'
        menuTrigger={`{default:'click', lg: 'hover'}`}
      >
        <MenuItem
          title='Calendars'
          to='/enterprise/calendars'
          icon='/media/icons/duotune/general/gen014.svg'
        />
        <MenuItem
          title='Curve Library'
          to='/enterprise/library'
          icon='/media/icons/duotune/graphs/gra012.svg'
        />
        <MenuItem title='EPS' to='#' icon='/media/icons/duotune/graphs/git04.svg' onClick={handleMenuItemClick('/enterprise/eps')}/>
      </MenuInnerWithSub>

      <MenuInnerWithSub
        title='Help'
        to='/apps'
        menuPlacement='bottom-start'
        menuTrigger={`{default:'click', lg: 'hover'}`}
      >
        {/* PAGES */}
        <MenuItem title='Support Center' to='#' icon='/media/icons/duotune/graphs/gra006.svg' />
        <MenuItem icon='/media/icons/duotune/coding/cod003.svg' to='/account' title='Release' />
        <MenuItem
          icon='/media/icons/duotune/communication/com003.svg'
          to='/account'
          title='Contact Us'
        />
      </MenuInnerWithSub>
      {showAddTrial && FreeTrialAddComponent}
    </>
  )
}
