import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

interface WbsNameModalProps {
    show: boolean;
    onHide: () => void;
    onSave: (name: string) => void;
}

const WbsNameModal: React.FC<WbsNameModalProps> = ({ show, onHide, onSave }) => {
    const [wbsName, setWbsName] = useState<string>('');
    const [validationError, setValidationError] = useState<string>('');

    const handleSave = () => {
        if (wbsName.length < 3 || wbsName.length > 50) {
            setValidationError('Version name must be between 3 and 50 characters.');
            return;
        }

        onSave(wbsName);
        setWbsName('');
        setValidationError('');
        onHide();
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Set Version Name</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Version Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={wbsName}
                            onChange={(e) => setWbsName(e.target.value)}
                            placeholder="Enter Version name"
                            isInvalid={!!validationError}
                        />
                        <Form.Control.Feedback type="invalid">
                            {validationError}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default WbsNameModal;
