import {ProjectData, ProjectModel, ProjectObject} from './_models'
import {useQuery} from 'react-query'
import {
  getProjectById,
  getProjects,
  getProjectImageById,
  getProjectDetailsById,
  getProjectShareDetailsById,
  getProjectSnapshotDetailsById,
  getVersionById,
  getSnapshotById,
} from './_requests'

export const useProjectsData = (
  project: ProjectData,
  refetchOnMount: boolean | 'always' = true
) => {
  return useQuery(['projects', project], () => getProjects(project), {
    refetchOnWindowFocus: false,
    refetchOnMount,
  })
}

export const useProjectByIdData = (id: string) => {
  return useQuery(['projectById', id], () => getProjectById(id), {
    refetchOnWindowFocus: false,
  })
}

export const useProjectDetailsById = (
  id: string,
  onSuccessCallback: (newData: ProjectModel) => void,
  enabled: boolean = true,
  onError?: ((err: unknown) => void) | undefined
) => {
  return useQuery(['projectById', id], () => getProjectDetailsById(id), {
    refetchOnWindowFocus: false,
    onSuccess: onSuccessCallback,
    enabled,
    onError,
    retry: 1,
  })
}

export const useProjectShareDetailsById = (
  id: string,
  onSuccessCallback: (newData: ProjectModel) => void,
  enabled: boolean = true,
  onError?: ((err: unknown) => void) | undefined
) => {
  return useQuery(['projectById', id], () => getProjectShareDetailsById(id), {
    refetchOnWindowFocus: false,
    onSuccess: onSuccessCallback,
    enabled,
    onError,
    retry: 1,
  })
}

export const useProjectSnapshotDetailsById = (
  id: string,
  onSuccessCallback: (newData: ProjectModel) => void,
  enabled: boolean = true,
  onError?: ((err: unknown) => void) | undefined
) => {
  return useQuery(['projectById', id], () => getProjectSnapshotDetailsById(id), {
    refetchOnWindowFocus: false,
    onSuccess: onSuccessCallback,
    enabled,
    onError,
    retry: 1,
  })
}


export const useProjectImage = (projectId: string) => {
  return useQuery(['projectImage', projectId], () => getProjectImageById(projectId), {
    refetchOnWindowFocus: false,
  })
}

export const useProjectVersionById = (
  id: string | undefined,
  onSuccessCallback: (newData: ProjectObject) => void,
  enabled: boolean
) => {
  return useQuery(['versionById', id], () => getVersionById(id || ''), {
    refetchOnWindowFocus: false,
    onSuccess: onSuccessCallback,
    enabled: enabled,
  })
}
