/* eslint-disable jsx-a11y/anchor-is-valid */

import {useFormik} from 'formik'
import {defaultInitalActionPlan} from './IActionPlan'
import {addActionPlanSchema} from '../../../../lib/forms'
import clsx from 'clsx'
import {KTSVG} from '../../../../../_metronic/helpers'
import {useEffect, useState} from 'react'
import {patchVersion} from '../../core/_requests'
import {sweetAlert} from '../../../../utils/funcs'
import {useProject} from '../../core/ProjectContext'
import {DatePicker, DatePickerProps} from 'antd'
import dayjs from 'dayjs'
import {getNextId} from './ActionPlanHelpers'
import { getComment, getCommentbywbs, getComments, patchCommentById } from '../comments/request'
import { patchActionPlan, postActionPlan } from './requests'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../store'
import { adding, done } from '../comments/CommentsState'
export interface IProps {
  project: any
  handleClose: () => void
}

const CreateActionPlanForm = ({project, handleClose}: IProps) => {
  const {setVersion, setOriginalVersion, project: projectObject} = useProject()
  const currentwbs=useSelector((state:RootState)=>state.currentwbs.value)
  const [loading, setLoading] = useState(false)
  const [comments, setComments] = useState<any[]>();
  const dispatch=useDispatch()
  useEffect(() => {
    const fetchComments = async () => {
      try {
        const commentsData = await getCommentbywbs(currentwbs.toString());
        setComments(commentsData);
      } catch (error) {
        console.error('Failed to fetch comments', error);
      }
    };

    fetchComments();
  }, []);

  const formik = useFormik({
    initialValues: defaultInitalActionPlan,
    validationSchema: addActionPlanSchema,
    onSubmit: async (values) => {
      dispatch(adding())
      setLoading(true)
      postActionPlan({subject:formik.values.subject,action:formik.values.action,responsible:formik.values.responsible,associated_comment:formik.values.associated_comment,start_date:formik.values.start_date, deadline:formik.values.deadline,status:defaultInitalActionPlan.status,report_date:defaultInitalActionPlan.report_date,close_date:defaultInitalActionPlan.close_date })
        .then(async (result) => {
          showAlert()
          const id:any=result.associated_comment
          const comment=await getComment(id.$oid)
          const ids=comment.shared_with_wbs.map(item=>item.$oid)
          patchActionPlan(result?._id.$oid,{shared_with_wbs:ids ,wbs:comment.wbs.$oid})
          const cleanedids=comment.associated_actions.map((item:any)=>item?.$oid)
          patchCommentById(comment._id.$oid,{associated_actions:[...cleanedids,result?._id.$oid]})
        })
        .catch((errorMessage) => {
          showError(errorMessage)
        })
        .finally(() => {
          dispatch(done())
          setLoading(false)})
    },
  })
  const showAlert = () => {
    handleClose()
    sweetAlert({
      title: 'Success',
      text: 'Action Plan Added Successfully',
      icon: 'success',
      confirmButtonText: 'OK',
    })
  }
  const showError = (err: any) => {
    handleClose()
    sweetAlert({
      title: 'Error',
      text: err.response.data.message || 'Something went wrong',
      icon: 'error',
      confirmButtonText: 'OK',
    })
  }
  const onChangeStart: DatePickerProps['onChange'] = (date, dateString) =>{
    formik.setFieldValue('start_date', date?.toISOString())
}
  const onChangeEnd: DatePickerProps['onChange'] = (date, dateString) =>
    formik.setFieldValue('deadline', date?.toISOString())

  return (
    <form noValidate id='kt_modal_create_app_form' className='form' onSubmit={formik.handleSubmit}>
      <div className='current' data-kt-stepper-element='content'>
        <div className='w-100'>
          {/*begin::Form Group */}
          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span className='required'>Subject</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title=''
              ></i>
            </label>
            <input
              type='text'
              placeholder='Subject'
              className={clsx(
                'form-control ',
                {'is-invalid': formik.touched.subject && formik.errors.subject},
                {
                  'is-valid': formik.touched.subject && !formik.errors.subject,
                }
              )}
              {...formik.getFieldProps('subject')}
            />
            {formik.touched.subject && formik.errors.subject && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{String(formik.errors.subject)}</span>
                </div>
              </div>
            )}
          </div>
          {/*end::Form Group */}

          {/*begin::Form Group */}
          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span className='required'>Action</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title=''
              ></i>
            </label>
            <input
              type='text'
              placeholder='Action'
              className={clsx(
                'form-control ',
                {'is-invalid': formik.touched.action && formik.errors.action},
                {
                  'is-valid': formik.touched.action && !formik.errors.action,
                }
              )}
              {...formik.getFieldProps('action')}
            />
            {formik.touched.action && formik.errors.action && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{String(formik.errors.action)}</span>
                </div>
              </div>
            )}
          </div>
          {/*end::Form Group */}
          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span className='required'>Responsible</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title=''
              ></i>
            </label>
            <input
              type='text'
              placeholder='Responsible'
              className={clsx(
                'form-control ',
                {'is-invalid': formik.touched.responsible && formik.errors.responsible},
                {
                  'is-valid': formik.touched.responsible && !formik.errors.responsible,
                }
              )}
              {...formik.getFieldProps('responsible')}
            />
            {formik.touched.responsible && formik.errors.responsible && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{String(formik.errors.responsible)}</span>
                </div>
              </div>
            )}
          </div>
          {/*end::Form Group */}

          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span className='required'>Related Comment</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title=''
              ></i>
            </label>
            <select
              className={clsx(
                'form-control',
                { 'is-invalid': formik.touched.associated_comment && formik.errors.associated_comment },
                { 'is-valid': formik.touched.associated_comment && !formik.errors.associated_comment }
              )}
              {...formik.getFieldProps('associated_comment')}
            >
              <option value='' disabled>
                Select a comment
              </option>
              {comments?.map((comment) => (
                <option className='' key={comment._id.$oid} value={comment._id.$oid}>
                  {comment.subject}
                </option>
              ))}
            </select>
            {formik.touched.associated_comment && formik.errors.associated_comment ? (
              <div className='invalid-feedback'>{formik.errors.associated_comment}</div>
            ) : null}
          </div>

          {/*end::Form Group */}

          {/*begin::Form Group */}
          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span className='required'>Start Date</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title=''
              ></i>
            </label>
            {/* <input
              type='date'
              className={clsx(
                'form-control ',
                {'is-invalid': formik.touched.initial_date && formik.errors.initial_date},
                {
                  'is-valid': formik.touched.initial_date && !formik.errors.initial_date,
                }
              )}
              {...formik.getFieldProps('initial_date')}
            /> */}
            <DatePicker
              className={clsx(
                'form-control ',
                {'is-invalid': formik.touched.start_date && formik.errors.start_date},
                {
                  'is-valid': formik.touched.start_date && !formik.errors.start_date,
                }
              )}
              {...formik.getFieldProps('start_date')}
              value={dayjs(formik.values.start_date)}
              onChange={onChangeStart}
              allowClear={false}
              suffixIcon={null}
            />
            {formik.touched.start_date && formik.errors.start_date && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{String(formik.errors.start_date)}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span className='required'>Planned Deadline</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title=''
              ></i>
            </label>
            <DatePicker
              className={clsx(
                'form-control ',
                {'is-invalid': formik.touched.deadline && formik.errors.deadline},
                {
                  'is-valid': formik.touched.deadline && !formik.errors.deadline,
                }
              )}
              {...formik.getFieldProps('deadline')}
              value={dayjs(formik.values.deadline)}
              onChange={onChangeEnd}
              allowClear={false}
              suffixIcon={null}
            />
            {formik.touched.deadline && formik.errors.deadline && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{String(formik.errors.deadline)}</span>
                </div>
              </div>
            )}
          </div>
          {/*end::Form Group */}
        </div>
      </div>
      {/*<SimulationForm data={simulationData} updateData={updateData} hasError={hasError} />*/}
      <div className='d-flex flex-stack pt-10'>
        <div>
          <button
            type='button'
            className='btn btn-lg btn-primary'
            data-kt-stepper-action='submit'
            disabled={!formik.isValid || !formik.dirty || loading}
            onClick={() => {
              formik.handleSubmit()
            }}
          >
            {!loading && (
              <span className='indicator-label'>
                Create
                <KTSVG
                  path='/media/icons/duotune/arrows/arr064.svg'
                  className='svg-icon-3 ms-2 me-0'
                />
              </span>
            )}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </form>
  )
}

export {CreateActionPlanForm}
