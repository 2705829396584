import { useEffect, useState } from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
import { Wbs } from '../../_models'
import { RootState } from '../../../../store'
import { useSelector } from 'react-redux'

type Props = {
  wbs: Wbs
}

export default function TreeDataWbsItem({ wbs }: Props) {
  const [missing, setMissing] = useState<Boolean>(false);
  const [wbsData, setWbsData] = useState<Wbs>(wbs);
  const knownWbs = useSelector((state: RootState) => state.treedata.knownWbs);

  useEffect(() => {
    if (knownWbs[wbs._id.$oid]) {
      setWbsData(knownWbs[wbs._id.$oid]);
    }
  }, [knownWbs]);
  useEffect(() => {

    if (!wbsData?.subwbs || wbsData?.subwbs?.length === 0) {
      if (wbsData?.data) {
        setMissing(false);
      } else {
        setMissing(true);
      }
    }
  }, [wbsData]);

  return (

    <div
      className='d-inline-flex justify-content-start align-items-center'
    >
      <KTSVG
        className={`svg-icon-2 me-2 ${wbsData?.subwbs?.length > 0
          ? 'svg-icon-primary'
          : missing
            ? 'svg-icon-warning'
            : 'svg-icon-success'
          }`}
        path={
          wbsData?.subwbs?.length > 0
            ? '/media/icons/duotune/graphs/git04.svg'
            : '/media/icons/duotune/files/fil003.svg'
        }
      />


      <span className='fw-bold me-2'>{wbsData.name}</span>
    </div>
  )
}
