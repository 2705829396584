/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import './Date.scss'
import { addPhotoProject, patchProject, patchVersion } from '../../core/_requests'
import { sweetAlert } from '../../../../utils/funcs'
import { useFormik } from 'formik'
import ProjectSettings from './ProjectSettings'
import { useIntl } from 'react-intl'
import UploadProjectImage from './UploadProjectImage'
import { useProject } from '../../core/ProjectContext'
import CustomSelect from '../../../../components/CustomSelect'
import { Calendar } from '../../../calendars/_models'
import { useCalendars } from '../../../calendars/_queries'
import { getProjectData } from '../../../../utils/project-data'
import CreatableSelect from '../../../../components/CreatableSelect/CreatableSelect'
import { Sectors, WbsDisplayType } from '../../core/_models'
import * as Yup from 'yup'
import OverviewOrder from './WidgetOrder/OverviewOrder'
import ShareOverviewOrder from './ShareWidgetOrder/ShareOverviewOrder'

export function Settings() {
  const {
    project,
    originalVersion: versionData,
    setProject,
    setOriginalVersion,
    setVersion,
    displayVersion,
  } = useProject()
  const { data: calendars, isLoading } = useCalendars({ page: 1, per_page: 0 })

  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const initialValues = {
    name: project?.name,
    description: project?.description,
    referenceNumber: project?.reference_number,
    associated_calendar: project?.associated_calendar,
    display_type: project?.wbs_display_type === 'both'
      ? ['name', 'index']
      : project?.wbs_display_type
        ? [project.wbs_display_type]
        : ['name'],
    sector: project?.sector || '',
    custom_sector: '',
  }
  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object().shape({
      name: Yup.string().required('name is required'),
      referenceNumber: Yup.string().required('reference number is required'),
      sector: Yup.string().required('sector is required'),
      custom_sector: Yup.string().when('sector', {
        is: 'Other',
        then: (schema) =>
          schema
            .required('custom sector is required')
            .min(2, 'Custom Sector must be at least 2 characters'),
      }),
      display_type: Yup.array().min(1, 'At least one display type must be selected'),
    }),
    enableReinitialize: true,
    onSubmit: () => {
      saveChanges()
    },
  })
  const [profilePhoto, setProfilPhoto] = useState<any>(null)

  const saveChanges = async () => {
    setLoading(true)
    const selectedDisplayType = formik.values.display_type.length === 2 ? 'both' : formik.values.display_type[0]
    const changedProject = {
      name: formik.values.name,
      reference_number: formik.values.referenceNumber,
      description: formik.values.description,
      associated_calendar: formik.values.associated_calendar?._id.$oid || null,
      wbs_display_type: selectedDisplayType,
      sector: formik.values.sector === 'Other' ? formik.values.custom_sector : formik.values.sector,
    }
    try {
      let newProject = undefined
      let res: any = undefined
      if (
        project?.associated_calendar?._id.$oid !== formik.values.associated_calendar?._id.$oid ||
        project?.sector !== formik.values.sector
      )
        res = await getProjectData(
          displayVersion,
          formik.values.associated_calendar,
          formik.values.sector
        )
      if (profilePhoto)
        newProject = await addPhotoProject({
          image: profilePhoto,
          project_id: project?._id.$oid || '',
        })
      if (formik.dirty) {
        const newVersion = await patchVersion(
          { associated_calendar: changedProject.associated_calendar || null, output: res },
          versionData?._id.$oid
        )
        setVersion((prev) =>
          prev
            ? {
              ...prev,
              associated_calendar: newVersion.associated_calendar,
              output: res || prev.output,
            }
            : undefined
        )
        setOriginalVersion(newVersion)
        patchProject(changedProject, project?._id?.$oid || '').then((res) => {
          setProject((prev) =>
            prev && res ? {
              ...prev,
              name: res.name,
              description: res.description,
              reference_number: res.reference_number,
              associated_calendar: res.associated_calendar,
              wbs_display_type: res.wbs_display_type,
              sector: res.sector,
              image: res.image
            } : undefined
          )
        })
      }
      sweetAlert({ title: 'Success', text: 'Changes made successfully!', icon: 'success' })
    } catch (error: any) {
      sweetAlert({ title: 'Error', text: error.message || 'Something went wrong!', icon: 'error' })
    } finally {
      setProfilPhoto(null)
      setLoading(false)
    }
  }
  return (
    <>
      <div className='card'>
        <div className='card-header'>
          <div className='card-title fs-3 fw-bold'>
            {intl.formatMessage({ id: 'SETTINGS.PROJECT_SETTINGS' })}
          </div>
        </div>
        <form id='kt_project_settings_form' className='form'>
          <div className='card-body p-9'>
            <div className='row mb-8'>
              <div className='col-xl-3 d-flex align-items-center'>
                <div className='fs-6 fw-semibold mt-2 mb-3'>Image</div>
              </div>
              <div className='col-xl-9'>
                <div className='col-lg-12 fv-row'>
                  <UploadProjectImage
                    image={profilePhoto}
                    setImage={setProfilPhoto}
                    projectId={project?.image && project?._id?.$oid}
                  />
                </div>
              </div>
            </div>
            <div className='row mb-8'>
              <div className='col-xl-3'>
                <div className='fs-6 fw-semibold mt-2 mb-3'>
                  {intl.formatMessage({ id: 'SETTINGS.PROJECT_NAME' })}
                </div>
              </div>
              <div className='col-xl-9 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-solid'
                  id='name'
                  {...formik.getFieldProps('name')}
                />
              </div>
            </div>
            <div className='row mb-8'>
              <div className='col-xl-3'>
                <div className='fs-6 fw-semibold mt-2 mb-3'>
                  {intl.formatMessage({ id: 'SETTINGS.PROJECT_REFERENCE' })}
                </div>
              </div>
              <div className='col-xl-9 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-solid'
                  {...formik.getFieldProps('referenceNumber')}
                />
              </div>
            </div>
            <div className='row mb-8'>
              <div className='col-xl-3'>
                <div className='fs-6 fw-semibold mt-2 mb-3'>
                  {intl.formatMessage({ id: 'SETTINGS.PROJECT_DESCRIPTION' })}
                </div>
              </div>
              <div className='col-xl-9 fv-row'>
                <textarea
                  className='form-control form-control-solid h-100px'
                  id='description'
                  {...formik.getFieldProps('description')}
                >
                  {formik.values.description}
                </textarea>
              </div>
            </div>
            <div className='row mb-8'>
              <div className='col-xl-3'>
                <div className='fs-6 fw-semibold mt-2 mb-3'>WBS Display Type</div>
              </div>
              <div className="col-xl-9 fv-row">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="displayTypeName"
                    name="display_type"
                    value="name"
                    checked={formik.values.display_type.includes('name')}
                    onChange={(e) => {
                      const value = e.target.value;
                      const isChecked = formik.values.display_type.includes(value);
                      if (isChecked && formik.values.display_type.length > 1) {
                        formik.setFieldValue(
                          'display_type',
                          formik.values.display_type.filter((type) => type !== value)
                        );
                      } else if (!isChecked) {
                        formik.setFieldValue('display_type', [...formik.values.display_type, value]);
                      }
                    }}
                  />
                  <label className="form-check-label" htmlFor="displayTypeName">
                    Name
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="displayTypeIndex"
                    name="display_type"
                    value="index"
                    checked={formik.values.display_type.includes('index')}
                    onChange={(e) => {
                      const value = e.target.value;
                      const isChecked = formik.values.display_type.includes(value);
                      if (isChecked && formik.values.display_type.length > 1) {
                        formik.setFieldValue(
                          'display_type',
                          formik.values.display_type.filter((type) => type !== value)
                        );
                      } else if (!isChecked) {
                        formik.setFieldValue('display_type', [...formik.values.display_type, value]);
                      }
                    }}
                  />
                  <label className="form-check-label" htmlFor="displayTypeIndex">
                    Index
                  </label>
                </div>
                {formik.errors.display_type && formik.touched.display_type && (
                  <div className="text-danger mt-2">
                    {formik.errors.display_type}
                  </div>
                )}
              </div>

            </div>

            <div className='row mb-8'>
              <div className='col-xl-3'>
                <div className='fs-6 fw-semibold mt-2 mb-3'>Sector</div>
              </div>
              <div className='col-xl-9 fv-row'>
                <CreatableSelect
                  background='var(--kt-input-solid-bg) !important'
                  options={Sectors}
                  value={{ label: formik.values.sector, value: formik.values.sector }}
                  onChange={(value) => formik.setFieldValue('sector', value?.value)}
                  error={!!formik.touched.sector && !!formik.errors.sector}
                />
              </div>
            </div>
            {formik.values.sector === 'Other' && (
              <div className='row mb-8'>
                <div className='col-xl-3'>
                  <div className='fs-6 fw-semibold mt-2 mb-3'>Custom Sector</div>
                </div>
                <div className='col-xl-9 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    id='custom_sector'
                    {...formik.getFieldProps('custom_sector')}
                  />
                </div>
              </div>
            )}
            <div className='row mb-8'>
              <div className='col-xl-3'>
                <div className='fs-6 fw-semibold mt-2 mb-3'>Calendar</div>
              </div>
              <div className='col-xl-9 fv-row'>
                <CustomSelect
                  name='calendar'
                  value={
                    formik.values.associated_calendar
                      ? {
                        value: formik.values.associated_calendar,
                        label: formik.values.associated_calendar.name,
                      }
                      : {
                        value: {},
                        label: isLoading ? 'Loading...' : 'No Calendar',
                        isOptionDisabled: isLoading,
                      }
                  }
                  isSearchable
                  maxHeight='200px'
                  onChange={(item: { label: string; value: Calendar }) => {
                    formik.setFieldValue('associated_calendar', item.value)
                  }}
                  options={[
                    { label: 'No Calendar', value: undefined },
                    ...(calendars?.items?.map((item) => ({ label: item.name, value: item })) || []),
                  ]}
                />
              </div>
            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button
              type='button'
              className='btn btn-primary'
              id='kt_project_settings_submit'
              onClick={formik.submitForm}
              disabled={(!formik.dirty && !profilePhoto) || loading || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Save Changes</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
      <ProjectSettings projectData={project} versionData={versionData} />
      <OverviewOrder />
    </>
  )
}
