import { CustomCurve } from "../../../simulations/core/_models";
import { BudgetAtCompletionProject, DateObject } from "../../core/_models";

export const RebaseLineHeaderList: any[] = [
  {Header: 'WP Name', width: '100', accessor: 'Table Name'},
  {Header: 'Rebaseline date', width: '100', accessor: 'New Rebaseline data date'},
    {
      Header: 'Start date',
      width: '90',
      accessor: 'subject',
    },
    {
      Header: 'End date',
      width: '100',
      accessor: 'comment_date',
    },
    {Header: 'Previous Budget reserve', width: '100', accessor: 'budget_reserve'},
    {Header: 'Previous Delay tolerance', width: '100', accessor: 'Delay tolerance'},
    {Header: 'Previous Budget at completion', width: '100', accessor: 'budget_at_completion'},
  ]

  export type RebaseLine = {
    wp_data_id?: string;
    id:string;
    name:string;
    start_date : DateObject,
    end_date : Date,
    data_date : Date,
    delay_tolerance?: {
      type: string
      count: number
    },
    reserve ?:{
      unit: string;
      amount : number
    }
    custom_curve?: CustomCurve[],
    budget_at_completion: BudgetAtCompletionProject
    
  }