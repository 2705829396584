import dayjs, {Dayjs} from 'dayjs'
import {Exception, RecurrentDate} from '../_models'
import {Holiday} from '../../projects/components/calendar/models'

const getCellColor = (
  date: Dayjs,
  selectedDates: Exception[],
  daysOfWeek: boolean[],
  recurrentDates: RecurrentDate[],
  holidays: Holiday[]
) => {
  let working: boolean = true
  if (!daysOfWeek[date.get('day')]) working = false
  if (recurrentDates.find((item) => item.day === date.date() && item.month === date.get('month')))
    working = false
  if (holidays.find((item) => dayjs(item.date).isSame(date, 'day'))) working = false
  const foundException = selectedDates.find(
    (item) => date.toDate().setHours(0, 0, 0, 0) === item.date.setHours(0, 0, 0, 0)
  )
  if (foundException) working = foundException.working
  return {working, exception: !!foundException}
}

export const getRecurrentColor = (dates: RecurrentDate[], date: Dayjs) => {
  return !dates.find((item) => item.day === date.date() && item.month === date.get('month'))
}

export {getCellColor}
