import React, { useState } from 'react';
import Toast from 'react-bootstrap/Toast';
import { ProjectModel } from '../../../core/_models';
import { Modal } from 'react-bootstrap';
import WbsShareModal from './WbsShareModal';
import SnapshotShareProjectModal from './SnapshotShareProjectModal';




type Props = {

  show: boolean;
  onHide: () => void;
  refetch: any;
  onSuccess?: (project: ProjectModel) => any;
};

export default function ShareProjectModal({
  onHide,
  show,
}: Props) {
 
  const [enableToast, setEnableToast] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<'LiveShare' | 'SnapshotShare'>('LiveShare');

  /**
   * Check the response from the child element to enable the toast
   * @param enable
   */
  const handleEnableToast = (enable: boolean) => {
    setEnableToast(enable);
  };

  const handleTabClick = (tab: 'LiveShare' | 'SnapshotShare') => {
    setActiveTab(tab);
  };

  return (
    <>
    <Modal centered onHide={onHide} show={show} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Share Project</Modal.Title>
      </Modal.Header>
    
   

      <div className='d-flex justify-content-center gap-5 overflow-auto h-55px'>
        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
          <li className='nav-item'>
            <div
              className={`nav-link text-active-primary me-6 cursor-pointer ${activeTab === 'LiveShare' && 'active'}`}
              onClick={() => handleTabClick('LiveShare')}
            >
              Live Share
            </div>
          </li>
          <li className='nav-item'>
            <div
              className={`nav-link text-active-primary me-6 cursor-pointer ${activeTab === 'SnapshotShare' && 'active'}`}
              onClick={() => handleTabClick('SnapshotShare')}
            >
              Snapshot Share
            </div>
          </li>
        </ul>
      </div>
      {activeTab === 'LiveShare' && (
        //Live share Component
        <WbsShareModal
        onHide={onHide}
        sendEnableToast={handleEnableToast}
      />
      )}
      {activeTab === 'SnapshotShare' && (
        //Snapshot share Component
        <SnapshotShareProjectModal/>
      )}

     
    </Modal>
    {enableToast &&
      <div style={{position: 'fixed', bottom: '1%', right: '1%', zIndex: 999}}>
        <Toast bg={'primary'} onClose={() => setEnableToast(false)} show={enableToast} delay={3000} autohide>
          <Toast.Header>
            <strong className="me-auto">Success</strong>
          </Toast.Header>
          <Toast.Body>
            <div className='justify-content-center'>
              <p style={{textAlign: 'center', fontWeight: '800', fontSize: '1.5em', color: 'white'}}>
                Link Copied!
              </p>
            </div>
          </Toast.Body>
        </Toast>
      </div>
      }
      </>
    
  );
}
