import {Response} from './../../../../_metronic/helpers/crud-helper/models'
import {CreateSimulation, Simulation, SimulationObject} from './_models'
import axios, {AxiosResponse} from 'axios'
import * as authHelper from '../../auth/core/AuthHelpers'
import {SimulationData} from './_queries'

const API_URL_SIMULATIONS = `${process.env.REACT_APP_API_URL}/simulations`
authHelper.setupAxios(axios)
const getSimulations = (simulation: SimulationData): Promise<Simulation> => {
  return axios
    .get(
      `${API_URL_SIMULATIONS}?page=${simulation.page}&per_page=${simulation.perPage}${
        simulation.filter.length > 0
          ? '&is_start=' + simulation.filter
          : '' + '&name=' + (simulation.name || '')
      }`,
      {
        headers: {Authorization: 'Bearer ' + authHelper.getAuth()?.api_token},
      }
    )
    .then((d: AxiosResponse<Simulation>) => d.data)
}

const createSimulations = (simulation: CreateSimulation): Promise<SimulationObject | undefined> => {
  return axios
    .post(API_URL_SIMULATIONS, simulation, {
      headers: {Authorization: 'Bearer ' + authHelper.getAuth()?.api_token},
    })
    .then((response: AxiosResponse<SimulationObject>) => response.data)
}

const getSimulationsById = (id: string): Promise<SimulationObject> => {
  return axios
    .get(`${API_URL_SIMULATIONS}/${id}`, {
      headers: {Authorization: 'Bearer ' + authHelper.getAuth()?.api_token},
    })
    .then((d: AxiosResponse<SimulationObject>) => d.data)
}

const deleteSimulation = (projectId: string): Promise<any | undefined> => {
  return axios
    .delete(`${API_URL_SIMULATIONS}/${projectId}`, {
      headers: {Authorization: 'Bearer ' + authHelper.getAuth()?.api_token},
    })
    .then((response: AxiosResponse<Response<any>>) => response)
    .then((response: Response<any>) => response)
}

const patchSimulation = (simulation: any, simulationId: string): Promise<any> => {
  return axios
    .patch(API_URL_SIMULATIONS + '/' + simulationId, simulation)
    .then((d: AxiosResponse<SimulationObject>) => d.data)
}

const sendSimulation = (simulationId: any, email: string) => {
  return axios
    .post(`${API_URL_SIMULATIONS}/${simulationId}`, {
      email,
    })
    .then((d: AxiosResponse<SimulationObject>) => d.data)
}

export {
  getSimulations,
  createSimulations,
  getSimulationsById,
  deleteSimulation,
  patchSimulation,
  sendSimulation,
}
