import {Calendar} from '../../calendars/_models'
import { Wbs } from '../../wbs/_models'
import {Comment} from '../components/comments/models'
import {WidgetOrder} from '../components/settings/WidgetOrder/_models'
import {Option} from '../components/settings/_utils'
import {CurveSettings, CustomCurve, PeriodCount} from './../../simulations/core/_models'

export type ObjectId = {
  $oid: string
}
export type DateObject = {
  $date: number
}
export interface Reserve {
  amount: number
  unit: string
}
export interface Annotations {
  name: string
  type: string
  value: string
}
export interface BudgetAtCompletionProject {
  amount: number
  currency: string
  unit: string
}

export type MilestoneDataDates = {
  data_date: Date
  real_date?: Date
  achieved: boolean
  hidden?: boolean
}

export type Milestone = {
  id: string
  name: string
  baseline_date: Date
  real_dates: MilestoneDataDates[]
  achieved?: boolean
  real_date?: Date
}

type Colors = {
  milestone_colors?: string[]
  s_cruve_colors?: string[]
  resource_colors?: string[]
}

type BottomUpValues = {
  label: string
  value: number
}

export type ForecastSettings = {
  eac_formula: EacFormula
  forecast_formula: ForecastFormula
  bottom_up_ec: BottomUpValues[]
  max_capacity: number
  custom_eac: number
  custom_end_date: any
}

export type ProjectObject = {
  isWbs?: boolean
  forecast_msg?: string
  forecastable?: boolean
  associated_calendar: any
  default_version: any
  share_version: any
  _id?: any
  name: string
  reference_number: string
  description: string
  date_format: string
  data_date: DateObject
  end_date: DateObject
  start_date: DateObject
  real_start_date?: DateObject
  float_formatter: number
  budget_at_completion: BudgetAtCompletionProject
  curve_settings?: CurveSettings
  custom_curve?: CustomCurve[]
  period_count: PeriodCount
  reserve?: Reserve
  annotations?: Annotations[]
  delay_tolerance?: {
    type: string
    count: number
  }
  action_plan: ActionPlan[]
  comments: Comment[]
  status: string
  updated_at: any
  unit: string
  achieved_percentage: number
  currency: number
  budgetAtCompletion: number
  budgetReserve: number
  delayTolerance: number
  savedSimulation: string
  output?: any
  versions: any
  project: ObjectId
  is_featured?: boolean
  milestones: Milestone[]
  total_hours: number
  full_capacity: number
  forecast_settings: ForecastSettings
  colors: Colors
  gauge_params: GaugeParameters
  rebase_line: RebaseLine[]
}
export interface ProjectData {
  page: number
  perPage: number
  filter: string
  featured?: boolean
  parent_folder?: string
  name?: string
}

export interface CreateProjectObject {
  is_create_completed: boolean
  description: string
  name: string
  wbs_display_type: string
  reference_number: string
  status: string
  associated_calendar: string | undefined
  parent_folder?: string
  sector?: string
}

export interface ProjectVersions {
  _id: ObjectId
  description: string
  name: string
  reference_number: string
  status: string
  versions: any
}

export type Project = {
  wbs_list: any
  default_wbs: any
  items: ProjectObject[]
  meta: {
    page: number
    per_page: number
    total_results: number
    num_pages: number
    has_next: number
    has_prev: number
    prev_num: number
    next_num: number
  }
}
export type ImageProject = {
  image: any
  project_id: string
}
export type ActionPlan = {
  action: string
  initial_date: Date
  real_date?: Date
  report_date?: Date
  responsible?: string
  subject: string
  status?: string
  associated_comments: string[]
  deadline: Date
}

export enum ProjectFilter {
  ALL = '',
  PENDING = 'pending',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
}

export type GaugeParameters = {
  red_limit: number
  yellow_limit: number
}

export type RebaseLine = {
  wp_data_id?: any
  id:string;
  name:string;
  start_date : DateObject,
  end_date : Date,
  data_date : Date,
  delay_tolerance?: {
    type: string
    count: number
  },
  reserve ?:{
    unit: string;
    amount : number
  }
  custom_curve?: CustomCurve[],
  budget_at_completion: BudgetAtCompletionProject
}
export type ProjectModel = {
  bars_thickness: any
  _id: ObjectId
  name: string
  description?: string
  reference_number: string
  status: string
  default_version: ProjectObject
  share_version: ProjectObject
  wbs_display_type?: string 
  share_wbs?: any
  checked_wbs?: any[]
  generated_wbs_list?: Wbs[]
  generated_wbs_data?: any[]
  updated_at?: DateObject
  user?: ObjectId
  versions?: any
  snapshots?: any
  wbs_list?: any
  image?: ObjectId
  is_featured?: boolean
  parent_folder?: {$oid: string}
  associated_calendar: Calendar | undefined
  sector?: string
  gauge_params: GaugeParameters
  widget_order?: WidgetOrder[]
  share_widget_order?: WidgetOrder[]
  shareable?: boolean
  share_protection?: boolean
  expiration_date?: any
  snapshot_widget_order?: WidgetOrder[]
  snapshot_shareable?: boolean
  snapshot_expiration_date?: any
  data?:any
  default_wbs?: any
  current_wbs?: any
  is_visible?: boolean
  selected_project_ids?: any
  dashboard_colors: string[] 
  float_formatter: number
  date_format?: string
  date_unit?: string
  currency?: string
  currency_unit?: string
}

export type Sector =
  | 'Construction (civil -industrial -institutional)'
  | 'Construction (commercial)'
  | 'Construction (residential)'
  | 'Software'
  | 'Engineering'

export type Recommendation = {
  sector: Sector
  forecast_formula: ForecastFormula
}

export enum EacFormula {
  'BAC/CPI' = 0,
  'AC + (Bottom UP Estimated Cost)' = 1,
  'AC + (BAC - EV)' = 2,
  'AC + (BAC - EV) / (CPI x SPI)' = 3,
  'Forced to BAC' = 4,
  'Custom EAC' = 5,
}

export enum ForecastFormula {
  'Auto' = 8,
  'EV Fitting correlated to baseline profile' = 1,
  'EV Fitting correlated to similar projects' = 7,
  'EV Fitting based only on EV curve' = 0,
  'Estimated End Date using Earned Schedule' = 3,
  'Max FTE' = 4,
  'Project Finish Date' = 5,
  'Custom Finish Date' = 6,
}

export const enabledFormulasFree = [
  ForecastFormula['Estimated End Date using Earned Schedule'],
  ForecastFormula['Project Finish Date']
]

export const enabledFormulasPro = [
  ForecastFormula['Estimated End Date using Earned Schedule'],
  ForecastFormula['Project Finish Date'],
  ForecastFormula['EV Fitting based only on EV curve'],
  ForecastFormula['EV Fitting correlated to baseline profile'],
  ForecastFormula['EV Fitting correlated to similar projects'],
  ForecastFormula['Max FTE'],
  ForecastFormula['Custom Finish Date']
]

export const WbsDisplayType: Option<string>[] = [
  {
    label: 'Name',
    value: 'name',
  },
  {
    label: 'Index',
    value: 'index',
  },
  {
    label: 'Name and Index',
    value: 'both',
  },
]

export const Sectors: Option<string>[] = [
  {
    label: 'Construction (civil -industrial -institutional)',
    value: 'Construction (civil -industrial -institutional)',
  },
  {
    label: 'Construction (commercial)',
    value: 'Construction (commercial)',
  },
  {
    label: 'Construction (residential)',
    value: 'Construction (residential)',
  },
  {
    label: 'Software',
    value: 'Software',
  },
  {
    label: 'Engineering',
    value: 'Engineering',
  },
  {
    label: 'Other',
    value: 'Other',
  },
]

export const SectorParameters: {label: string; params: {axis: number; tg: number}}[] = [
  {
    label: 'Construction (civil -industrial -institutional)',
    params: {
      tg: 1.56,
      axis: 0.23,
    },
  },
  {
    label: 'Construction (commercial)',
    params: {
      tg: 1.36,
      axis: -0.44,
    },
  },
  {
    label: 'Construction (residential)',
    params: {
      tg: 2.33,
      axis: 0.29,
    },
  },
  {
    label: 'Software',
    params: {
      tg: 1.23,
      axis: 0.11,
    },
  },
  {
    label: 'Engineering',
    params: {
      tg: 2.19,
      axis: -1,
    },
  },
]
