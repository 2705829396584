import Select from 'react-select'
import {Option} from '../../modules/projects/components/settings/_utils'
import {ActionMeta, SingleValue} from 'react-select'

type Props = {
  options: Option<string>[]
  onChange:
    | ((newValue: SingleValue<Option<string>>, actionMeta: ActionMeta<Option<string>>) => void)
    | undefined
  value: Option<string>
  error?: boolean
  background?: string
}

export default function CreatableSelect({
  options,
  onChange,
  value,
  error = false,
  background,
}: Props) {
  return (
    <Select
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          border: '1px solid var(--kt-input-border-color)',
          borderColor: error
            ? 'var(--kt-danger) !important'
            : background
            ? 'transparent !important'
            : 'var(--kt-input-border-color) !important',
          background: background || 'transparent',
          minHeight: '3.20rem',
          fontSize: '1.15rem',
          padding: '0.5rem 1.5rem',
        }),
        input: (base) => ({
          ...base,
          border: 'none',
          color: 'var(--kt-text-dark)',
        }),
        menu: (base, state) => ({
          ...base,
          background: 'var(--kt-input-solid-bg)',
          zIndex: 100000,
        }),
        singleValue: (provided) => ({
          ...provided,
          color: 'var(--kt-input-solid-color)',
        }),
        option: (base, {data, isDisabled, isFocused, isSelected}) => {
          return {
            ...base,
            backgroundColor: isDisabled
              ? 'var(--kt-input-disabled-bg)'
              : isFocused
              ? 'var(--kt-component-hover-bg)'
              : 'var(--kt-input-solid-bg)',
            color: isSelected ? 'var(--kt-component-hover-color)' : 'var(--kt-gray-700);',
            transition: 'color 0.2s ease',
            cursor: isDisabled ? 'not-allowed' : 'default',
          }
        },
        menuPortal: (base) => ({...base, zIndex: 9999}),
      }}
      value={value}
      options={options}
      menuPlacement='auto'
      onChange={onChange}
    />
  )
}
