import {SimulationObject, SimulationObjectWithDates} from '../core/_models'
import * as Yup from 'yup'

export const simulationInit: SimulationObjectWithDates = {
  _id: {$oid: ''},
  achieved_percentage: 0,
  budget_at_completion: {amount: 0, currency: '', total_amount: 0, unit: ''},
  curve_settings: {name: '', offset: 1.22, tangent: 0.33},
  custom_curve: [],
  data_date: new Date(),
  description: '',
  end_date: new Date(),
  has_remaining: false,
  is_start: false,
  name: '',
  period_count: {count: '0', type: 'monthly'},
  total_hour: 0,
  updated_at: {$date: new Date().getTime()},
  resources_per_month: 0,
}

export const convertDates: (simulation: SimulationObject) => SimulationObjectWithDates = (
  simulation
) => ({
  ...simulation,
  end_date: simulation.end_date && new Date(simulation.end_date.$date),
  data_date: simulation.data_date && new Date(simulation.data_date.$date),
  _id: {$oid: simulation._id?.$oid || ''},
  period_count: simulation.period_count || {count: '0', type: 'monthly'},
})

export const enum TypeOfCalculate {
  CALC_END_DATE = 'endOfDate',
  FIXED_END_DATE = 'fixedFinishDate',
}

export const simulationValidationSchema = Yup.object().shape({
  achieved_percentage: Yup.number()
    .typeError('Percentage complete is a number')
    .min(1, 'cannot be less than 1%')
    .max(99, 'cannot be more than 99%')
    .when('is_start', {
      is: true,
      then: Yup.number().required(),
    }),
  curve_settings: Yup.object({
    tangent: Yup.number().typeError('must be a number').required(),
    offset: Yup.number().typeError('must be a number').required(),
  }),
  period_count: Yup.object({
    count: Yup.number().typeError('must be a number').min(1, 'cannot be less than 1'),
  }),
})
