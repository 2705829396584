import {TreeSelect, TreeSelectProps} from 'antd'
import {DataNode} from 'antd/es/tree'
import {useEffect, useState} from 'react'
import {ProjectModel} from '../../../core/_models'
import {getFolder} from '../../../../eps/_requests'
import SelectTreeItem from '../../../../eps/components/TreeView/select/SelectTreeItem'
import {updateTreeData} from '../../../../eps/components/TreeView/_helpers'
import {Modal} from 'react-bootstrap'
import {patchProject} from '../../../core/_requests'
import {showError} from '../../../../../utils/funcs'

type Props = {
  project: ProjectModel | undefined
  show: boolean
  onHide: () => void
  refetch: any
  onSuccess?: (project: ProjectModel) => any
}

export default function MoveItem({project, onHide, show, refetch, onSuccess}: Props) {
  const [treeData, setTreeData] = useState<DataNode[]>([])
  const [selectedFolder, setSelectedFolder] = useState<string>('root')
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => setSelectedFolder('root'), [show])

  useEffect(() => {
    getFolder().then((data) =>
      setTreeData([
        {
          title: <SelectTreeItem folder={data} />,
          key: data._id?.$oid || 'root',
          value: data._id?.$oid || 'root',
        },
      ] as any)
    )
  }, [])

  const loadData: TreeSelectProps['loadData'] = async (treeNode) => {
    if (!treeNode) return
    const childFolder = await getFolder(
      treeNode.key !== 'root' ? treeNode.key?.toString() : undefined
    )
    const childData: DataNode[] = childFolder.subfolders.map((item) => ({
      key: item._id.$oid,
      title: <SelectTreeItem folder={item} />,
      switcherIcon: item.subfolders.length ? undefined : <></>,
      value: item._id.$oid,
    }))
    setTreeData((origin) => {
      return updateTreeData(origin, treeNode.key?.toString() || 'root', childData)
    })
  }

  const handleSubmit = () => {
    if (!project) return
    setLoading(true)
    patchProject({parent_folder: selectedFolder}, project._id.$oid)
      .then((res) => {
        refetch()
        if (onSuccess && res) onSuccess(res)
        onHide()
      })
      .catch(showError)
      .finally(() => setLoading(false))
  }

  return (
    <Modal centered onHide={onHide} show={show}>
      <Modal.Header closeButton>
        <Modal.Title>Move Project</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='fv-row mb-5'>
          <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
            <span>Choose Destination Folder</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title=''
            ></i>
          </label>
          <TreeSelect
            value={selectedFolder}
            onChange={(value) => {
              setSelectedFolder(value)
            }}
            className='form-control'
            dropdownStyle={{zIndex: 10000}}
            popupClassName='eps-dropdown'
            loadData={loadData}
            treeData={treeData}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button disabled={loading} onClick={onHide} className='btn btn-light-primary'>
          Cancel
        </button>
        <button disabled={loading} onClick={handleSubmit} className='btn btn-primary'>
          {loading ? (
            <span className='indicator-progress' style={{display: 'block'}}>
              <span className='spinner-border spinner-border-sm align-middle'></span>
            </span>
          ) : (
            'Submit'
          )}
        </button>
      </Modal.Footer>
    </Modal>
  )
}
