import * as htmlToImage from 'html-to-image';

export default class Copier {
  static getRowColor(rowIdx: number) {
    switch (rowIdx) {
      case 1:
        return '#14d193';
      case 2:
        return '#0B69FF';
      default:
        return '#4F4F4F';
    }
  }

  static handleCopyAsHTML(tableData: any, highlightIdx: number) {
    return new Promise(async (resolve: any, reject: any) => {
      const htmlRows = tableData
        .map((row: any, rowIdx: number) => {
          row = row.map((cell: any, cellIdx: number) => {
            return `<td bgcolor="${
              cellIdx === highlightIdx + 1
                ? '#14d193'
                : rowIdx === 0 || cellIdx === 0
                ? '#fafafa'
                : ''
            }"
            ${cellIdx === highlightIdx + 1 ? 'style="color: white"' : ''}
          >${cell}</td>`;
          });

          return `<tr style="color: ${Copier.getRowColor(rowIdx)}">${row.join(
            ''
          )}</tr>`;
        })
        .join('');

      try {
        navigator.clipboard.writeText(
          `<table bgcolor="#fff" border="1" bordercolor="#CFCFC9">${htmlRows}</table>`
        );
        resolve();
      } catch (err) {
       
        reject();
      }
    });
  }

  static handleCopyChart(elId: any) {
    const el = (document as any).getElementById(elId);

    return new Promise(async (resolve: any, reject: any) => {
      try {
        const dataUrl = await htmlToImage.toBlob(el);
        navigator.clipboard.write([
          new ClipboardItem({
            'image/png': dataUrl as any,
          }),
        ]);
        resolve();
      } catch (err) {
       
        reject();
      }
    });
  }

  static handleCopyAsCSV(tableData: any) {
    return new Promise(async (resolve: any, reject: any) => {
      const text = tableData.map((row: any) => row.join('\t')).join('\n');

      try {
        navigator.clipboard.writeText(text);
        resolve();
      } catch (err) {
     
        reject();
      }
    });
  }
}
