import dayjs from 'dayjs';
import { useEffect, useState, useCallback, useRef } from 'react';
import { Dropdown } from 'react-bootstrap';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';
import { useProject } from '../../../../modules/projects/core/ProjectContext';
import { ProjectObject } from '../../../../modules/projects/core/_models';
import ProjectTimelineChart from './ProjectTimelineChart';
import { Tooltip, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { useTree } from '../../../wbs/components/TreeView/SnapshotShareTreeContext';
import { getVersionById } from '../../core/_requests';
import { setVersionData } from '../../../wbs/treeVersionData';
import SnapshotShareProjectTimelineChart from './SnapshotShareProjectTimelineChart';

export default function LiveShareChart() {
    const dispatch = useDispatch();
    const knownWbs = useSelector((state: RootState) => state.treedata.knownWbs);
    const isTreeLoaded = useSelector((state: RootState) => state.treedata.isTreeLoaded);
    const wpsCalculatedData = useSelector((state: RootState) => state.treedata.wpsCalculatedData);
    const wbsCalculatedData = useSelector((state: RootState) => state.treedata.wbsCalculatedData);
    const { expandedKeys, treeData } = useTree();

    const [projects, setProjects] = useState<ProjectObject[]>([]);
    const [selectedProjectIds, setSelectedProjectIds] = useState<Set<string>>(new Set());
    const [plottedProjects, setPlottedProjects] = useState<any>([]);
    const [dataDateDates, setDataDateDates] = useState<Date[]>([]);
    const [interval, setInterval] = useState(6);
    const [selectedLabel, setSelectedLabel] = useState("6 Months");
    const { setProjectDashboardDataDate, displayVersion: data, project } = useProject();
    const [selectedDataDate, setSelectedDataDate] = useState<Date>(new Date());
    const [loading, setLoading] = useState<boolean>(false);
    const prevSelectedDataDateRef = useRef<Date>(selectedDataDate);
  const initialProjectVersionData = useSelector((state: RootState) => state.versiondata.data)

    useEffect(() => {
        const newSelectedDate = new Date(data?.data_date?.$date || 0);
        setSelectedDataDate(newSelectedDate);
    }, [data?.data_date]);

    const fetchProjectData = useCallback(async () => {

        let projectversiondata: Record<string, any> = JSON.parse(JSON.stringify(initialProjectVersionData));

        wpsCalculatedData.forEach((data) => {
            projectversiondata[data._id.$oid] = data;
        });
        wbsCalculatedData.forEach((data) => {
            projectversiondata[data._id.$oid] = data;
        });

        if (true) {
            try {
                setLoading(true); // Start loading
                const expandedKeysSet = new Set(expandedKeys);

              // Define shownWbs to include only the current WBS if it's not in expandedKeys
let shownWbs: any[];
const current_wbs = project?.current_wbs

if (current_wbs && !expandedKeys.includes(current_wbs)) {
    // If the current WBS is not in expandedKeys, show only the current WBS
    shownWbs = [current_wbs];
} else {
    // Otherwise, proceed with the existing filtering logic
    shownWbs = expandedKeys.filter(w => {
        const wbs = knownWbs[w];
        const parentOid = wbs?.parent_wbs?.$oid;
        return wbs && parentOid && expandedKeysSet.has(parentOid);
        
    });
    
        shownWbs.push(current_wbs)
    shownWbs.forEach(w => {
        const wbs = knownWbs[w];
        if (wbs && wbs.subwbs) {
            wbs.subwbs.forEach(subWbs => {
                if (!shownWbs.includes(subWbs.$oid)) {
                    shownWbs.push(subWbs.$oid);
                }
            });
        }
    });


}

                

             
                const order = extractTreeOrder(treeData);
                shownWbs.sort((a, b) => order.indexOf(a) - order.indexOf(b));

                const filteredprojectsData = shownWbs.filter(w => knownWbs[w]?.data?.$oid);

                const mappedData = await Promise.all(
                    filteredprojectsData.map(async (w) => {
                        if (projectversiondata[knownWbs[w].data.$oid]) {
                            return {
                                ...projectversiondata[knownWbs[w].data.$oid],
                                name: knownWbs[w].name,
                                isWbs: knownWbs[w].subwbs && knownWbs[w].subwbs.length !== 0
                            };
                        }
                    })
                );
                let validProjectsData = mappedData.filter(data => data !== undefined);

                validProjectsData = validProjectsData.map((project: any) => {
                    if (project && project.output && project.output.CardsData && project.output.CardsData.SchedulePerformance) {
                        const eedValue = project.output.CardsData.SchedulePerformance.EED?.Value;
                        if (eedValue) {
                            project = {
                                ...project,
                                output: {
                                    ...project.output,
                                    estimatedEndDate: eedValue
                                }
                            }
                        }
                    }
                    return project;
                });
                
  

                    await setProjectDashboardDataDate(selectedDataDate, validProjectsData, setProjects);

                
                // setProjects(validProjectsData)

                setSelectedProjectIds(new Set(validProjectsData.map(p => p._id.$oid)));
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false); // End loading
            }
        }
    }, [expandedKeys, knownWbs, wpsCalculatedData, wbsCalculatedData, selectedDataDate, isTreeLoaded, initialProjectVersionData]);

    useEffect(() => {
        fetchProjectData();
    }, [fetchProjectData]);

    useEffect(() => {
        setPlottedProjects([]);

        projects
            .filter(project => selectedProjectIds.has(project._id.$oid))
            .forEach(project => addProjectToChart(project));

        setPlottedProjects((prev: any) => [
            {
                id: '',
                name: '',
                graphEndDate: [selectedDataDate.getTime(), selectedDataDate.getTime()],
                graphEstimatedEndDate: [selectedDataDate.getTime(), selectedDataDate.getTime()],
                brokenLine: selectedDataDate.getTime(),
            },
            ...prev,
            {
                id: 'dsd',
                name: '  ',
                graphEndDate: [selectedDataDate.getTime(), selectedDataDate.getTime()],
                graphEstimatedEndDate: [selectedDataDate.getTime(), selectedDataDate.getTime()],
                brokenLine: selectedDataDate.getTime(),
            }
        ]);
    }, [projects, selectedDataDate, selectedProjectIds]);

    useEffect(() => {
        const result = findMinMaxDates(plottedProjects);
        if (result) {
            setDataDateDates([result.minStartDate, result.maxEndDate]);
        }
    }, [plottedProjects]);

    const addProjectToChart = useCallback((project: ProjectObject) => {
        if (!project) return;

        const startDate = new Date(project.start_date.$date).getTime();
        const realStartDate = project?.real_start_date ? project.real_start_date : new Date(project.start_date.$date).getTime();
        const endDate = new Date(project.end_date.$date).getTime();
        const estimatedEndDate = new Date(project.output.estimatedEndDate).getTime();
        const dataDate = new Date(project.data_date.$date).getTime();

        setPlottedProjects((prev: any) => {
            const projectToAdd = {
                id: project._id.$oid,
                name: project.name,
                forecastable: project.forecastable,
                forecast_msg: project.forecast_msg,
                graphEndDate: [startDate, endDate],
                graphEstimatedEndDate: [realStartDate, estimatedEndDate],
                dataDate: dataDate,
                brokenLine: estimatedEndDate === endDate ? selectedDataDate.getTime() : selectedDataDate.getTime() - (estimatedEndDate - endDate),
                isWbs : project.isWbs
            };

            return [...prev, projectToAdd];
        });
    }, [selectedDataDate]);

    const handleIntervalChange = (item: any) => {
        setInterval(item.value);
        setSelectedLabel(item.label);
    };

    function checkInterval(minDate: any, maxDate: any, intervalMonths: number) {
        const totalMonths = dayjs(maxDate).diff(dayjs(minDate), 'months');
        const ticksCount = Math.ceil(totalMonths / intervalMonths);
        const requiredSpace = ticksCount * 50;
        return requiredSpace < 1080;
    }

    const intervals = [1, 3, 6, 12].map(months => {
        const isAppropriate = months === 12 ? true : dataDateDates.length === 2
            ? checkInterval(dataDateDates[0], dataDateDates[1], months)
            : false;

        return {
            value: months,
            label: `${months} Month${months > 1 ? 's' : ''}`,
            disabled: !isAppropriate,
            message: !isAppropriate ? `The ${months}-month interval makes the chart too crowded.` : ""
        };
    });

    return (
        <>
            <div>
                <div className='card-header '>
                    <div className='card-title d-flex justify-content-between w-100'>
                        <div className='card-title mr-auto p-2'>
                            <span className=' fw-bold text-dark  d-flex align-items-start gap-2' style={{ fontSize: '16px' }}>
                                <img className='w-24px h-24px ' src={toAbsoluteUrl('/media/svg/card-logos/overview.svg')} alt='Overview logo' />
                                <div>
                                    <div>WBS Overview</div>
                                    <div className='overview-description'>Get a global overview over your current wbs</div>
                                </div>
                            </span>
                        </div>
                        <div className='card-title ml-auto p-2 gap-2'>
                            <Dropdown className='my-5 dropdown-custom' autoClose='outside'>
                                <Dropdown.Toggle className='calendar-dropdown'>
                                    <img className='w-24px h-24px' src={toAbsoluteUrl('/media/svg/card-logos/calendar.svg')} alt='Dashboard logo' />
                                    <span style={{ color: 'var(--bs-btn-color)' }}>
                                        {selectedLabel}
                                    </span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className='dropdown-projects'>
                                    {intervals.map((item) => (
                                        <Tooltip title={item.disabled ? item.message : ""} key={item.value} placement="right">
                                            <Dropdown.Item
                                                className={`d-flex align-items-center fs-5 gap-2 p-3 ${item.label === selectedLabel ? 'selected-interval' : ''}`}
                                                onClick={() => { if (!item.disabled) handleIntervalChange(item); }}
                                                style={{ opacity: item.disabled ? 0.5 : 1 }}
                                            >
                                                <div className='btn btn-outline form-check form-check-custom me-6 p-3 w-100'>
                                                    <label className='form-label fs-5'>{item.label}</label>
                                                </div>
                                            </Dropdown.Item>
                                        </Tooltip>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </div>
            <div className='card-body'>
                {!plottedProjects[0] ? (
                    <div>no Data</div>
                ) : (
                    <SnapshotShareProjectTimelineChart
                        projects={plottedProjects}
                        treeData={treeData}
                        dataDate={selectedDataDate}
                        interval={interval}
                        loading={loading}
                    />
                )}
            </div>
        </>
    );
}

function extractTreeOrder(treeData: any[]) {
    const order: any[] = [];
    function traverse(node: { key: any; children: any[]; }) {
        order.push(node.key);
        if (node.children) {
            node.children.forEach((child: any) => traverse(child));
        }
    }
    treeData.forEach(node => traverse(node));
    return order;
}

function findMinMaxDates(dateArray: any) {
    if (dateArray && dateArray.length <= 2) {
        return null;
    }

    let minStartDate = dateArray[1].graphEndDate[0];
    let maxEndDate = dateArray[1].graphEndDate[1];

    for (const entry of dateArray) {
        if (entry.id !== '' && entry.id !== 'dsd') {
            const startDate = entry.graphEndDate[0];
            const endDate = entry.graphEndDate[1];
            const dataDate = entry.dataDate;

            if (startDate < minStartDate) {
                minStartDate = startDate;
            }

            if (endDate > maxEndDate) {
                maxEndDate = endDate;
            }

            if (dataDate > maxEndDate) {
                maxEndDate = dataDate;
            }
        }
    }

    return { minStartDate, maxEndDate };
}