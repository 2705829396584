/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {deleteProject, getProjectImageLink} from '../../../../app/modules/projects/core/_requests'
import {useNavigate} from 'react-router-dom'
import {Dropdown} from 'react-bootstrap'
import {CustomBorderlessToggle} from '../../../../app/modules/projects/core/ProjectHelpers'
import {showError, sweetAlert} from '../../../../app/utils/funcs'
import SendProjectModal from '../../../../app/modules/projects/components/pagination/header/SendProjectModal'

type Props = {
  parentComponent: string
  icon: string
  id: string
  badgeColor: string
  status: string
  statusColor: string
  title: string
  description: string
  date: string
  dataDate: string
  budget?: string
  image?: boolean
  showFeatured?: boolean
  featured?: boolean
  toggleFeatured?: any
  refetch?: any
}

const Card2: FC<Props> = ({
  parentComponent,
  icon,
  id,
  badgeColor,
  status,
  statusColor,
  title,
  description,
  date,
  dataDate,
  budget,
  image = false,
  showFeatured,
  featured,
  toggleFeatured,
  refetch = () => {},
}) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const [showMoving, setShowMoving] = useState<boolean>(false)
  const [showSendModal, setShowSendModal] = useState<boolean>(false)

  const handleDelete = () => {
    setLoading(true)
    deleteProject(id)
      .then((res) => refetch())
      .catch(showError)
      .finally(() => setLoading(false))
  }

  const showConfirm = () => {
    sweetAlert({
      title: 'Are you sure?',
      text: 'You will not be able to recover this project!',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      showLoaderOnConfirm: true,
      icon: 'warning',
      preConfirm: () => handleDelete(),
      allowOutsideClick: () => !loading,
    }).then((res) => {
      if (res.isConfirmed) {
        sweetAlert({
          title: 'Deleted!',
          text: 'Project has been deleted.',
          icon: 'success',
        }).then(() => navigate('/projects'))
      }
    })
  }

  return (
    <div
      onClick={() => navigate(`/${parentComponent}/${id}`)}
      className='card border border-2 border-gray-300 border-hover cursor-pointer'
      style={{width: '100%'}}
    >
      <div onClick={(e) => e.stopPropagation()}>
        <SendProjectModal
          show={showSendModal}
          handleClose={() => setShowSendModal(false)}
          projectId={id}
        />
      </div>
      <div className='card-header border-0 pt-9'>
        <div className='card-title m-0'>
          <div className='symbol symbol-50px w-50px bg-light'>
            <img
              src={
                parentComponent === 'simulations' || !image
                  ? toAbsoluteUrl(icon)
                  : getProjectImageLink(id)
              }
              onError={(e) => ((e.target as HTMLImageElement).src = toAbsoluteUrl(icon))}
              alt='card2'
              className='p-3'
            />
          </div>
        </div>
        <div className='card-toolbar'>
          <span className={`badge badge-light-${badgeColor} fw-bolder me-auto px-4 py-3`}>
            {status}
          </span>
        </div>
      </div>

      <div className='card-body p-9'>
        <div className='d-flex justify-content-between w-100'>
          <div>
            <div className='fs-3 fw-bolder text-dark'>{title}</div>
            <p
              className='text-gray-400 fw-bold fs-5 mt-1 mb-7 overflow-hidden'
              style={{height: '2rem'}}
            >
              {description}
            </p>
          </div>
          {showFeatured && (
            <div
              onClick={(e: any) => {
                e.stopPropagation()
                if (toggleFeatured) toggleFeatured()
              }}
            >
              <KTSVG
                path='/media/icons/duotune/abstract/abs024.svg'
                className={`svg-icon-2x text-hover-warning ${featured && 'text-warning'}`}
              />
            </div>
          )}
        </div>
        <div className='d-flex justify-content-between'>
          <div className='d-flex flex-wrap mb-5'>
            <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3'>
              <div className='fs-6 text-gray-800 fw-bolder'>{date}</div>
              <div className='fw-bold text-gray-400'>Last Update</div>
            </div>
            {budget ? (
              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3'>
                <div className='fs-6 text-gray-800 fw-bolder'>{budget}</div>
                <div className='fw-bold text-gray-400'>Budget at completion</div>
              </div>
            ) : (
              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3'>
                <div className='fs-6 text-gray-800 fw-bolder'>{dataDate}</div>
                <div className='fw-bold text-gray-400'>Creation date</div>
              </div>
            )}
          </div>
          <Dropdown onClick={(e) => e.stopPropagation()} style={{alignSelf: 'flex-end'}}>
            <Dropdown.Toggle as={CustomBorderlessToggle}></Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={showConfirm}>Delete Project</Dropdown.Item>
              <Dropdown.Item onClick={() => setShowSendModal(true)}>Send Project</Dropdown.Item>
              <Dropdown.Item onClick={() => setShowMoving(true)}>Move Project</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  )
}

export {Card2}
