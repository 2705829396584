import {useEffect, useState} from 'react'

import {Card2} from '../../../_metronic/partials/content/cards/Card2'
import {useSimulationsData} from './core/_queries'
import {Pagination} from './components/pagination/Pagination'
import {ToolbarClassicCustom} from './core/ToolbarClassic'
import {PageTitle} from '../../../_metronic/layout/core'
import {PageTitle as PageBreadCrumbs} from '../../../_metronic/layout/components/toolbar/page-title/PageTitle'
import {useIntl} from 'react-intl'
import EmptyValuesCard from '../../components/emptyValuesCard'
import {getFormattedDate} from '../../utils/funcs'
import {SimulationFilter} from './core/_models'
import {KTSVG, useDebounce} from '../../../_metronic/helpers'

function Index() {
  const [page, setPage] = useState(1)
  // eslint-disable-next-line  @typescript-eslint/no-unused-vars
  const [perPage, setPerPage] = useState(9)
  const [filter, setFilter] = useState<SimulationFilter>(SimulationFilter.ALL)
  const [showCreateSimulationModal, setShowCreateSimulationModal] = useState(false)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedTerm = useDebounce(searchTerm, 200)
  const {data, isLoading} = useSimulationsData({page, perPage, filter, name: debouncedTerm})
  const intl = useIntl()

  return (
    <>
        <div className='row mb-5 mt-5'>
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.SIMULATIONS'})}</PageTitle>
        <PageBreadCrumbs />
          <div className='d-flex justify-content-between'>
            <div className='d-flex align-items-center position-relative py-2'>
              <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon-1 position-absolute ms-6'
              />
              <input
                type='text'
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
                data-kt-user-table-filter='search'
                className='form-control form-control w-250px ps-14'
                placeholder='Search simulations'
              />
            </div>
            <ToolbarClassicCustom
              filter={filter}
              setFilter={setFilter}
              showCreateAppModalExternal={showCreateSimulationModal}
              setShowCreateAppModalExternal={setShowCreateSimulationModal}
            />
          </div>
      </div>
      <div className='row g-6 g-xl-9'>
        {isLoading && (
          <div className='d-flex justify-content-center'>
            <div
              className='spinner-border text-primary mt-5'
              role='status'
              style={{width: '3rem', height: '3rem'}}
            >
              <span className='sr-only'>Loading...</span>
            </div>
          </div>
        )}
          {data?.items.length === 0 ? (
            <EmptyValuesCard
              buttonText='Create Simulation'
              setShowCreateAppModal={setShowCreateSimulationModal}
              text={
                filter || debouncedTerm
                  ? 'No simulations for this filter'
                  : 'There are no simulations added yet.'
              }
              title='Welcome to Project Curve'
            />
          ) : (
            data?.items?.map((item, index) => (
              <div key={index} className='col-md-6 col-xl-4'>
                <Card2
                  parentComponent='simulations'
                  icon='/media/logos/default-small.svg'
                  badgeColor={item.is_start ? 'success' : 'primary'}
                  status={item.is_start ? 'On Going' : 'Not Started'}
                  statusColor='primary'
                  title={item.name}
                  description={item.description}
                  id={item?._id?.$oid || ''}
                  date={getFormattedDate(item.updated_at.$date)}
                  dataDate={getFormattedDate(item.updated_at.$date)}
                />
              </div>
            ))
          )
        }
      </div>
      <div className='mt-5'>
        <Pagination
          pagination={data?.meta}
          isLoading={isLoading}
          updateState={(datsa: any) => {
            setPage(datsa.page)
          }}
        />
      </div>
      </>
)}

export default Index
