import * as authHelper from '../../auth/core/AuthHelpers'
import axios, {AxiosResponse} from 'axios'
import {Team, TeamRequest} from './_models'
authHelper.setupAxios(axios)
const API_URL_TEAMS = `${process.env.REACT_APP_API_URL}/teams`
const API_URL_TEAM_REQUESTS = `${process.env.REACT_APP_API_URL}/team-requests`
const API_URL_SUBSCRIPTIONS = `${process.env.REACT_APP_API_URL}/subscriptions`

export const getTeam = () => {
  return axios.get(API_URL_TEAMS).then((response: AxiosResponse<Team>) => response.data)
}

export const addMember = (userEmail: string) => {
  return axios
    .post(API_URL_TEAMS, {member_email: userEmail})
    .then((response: AxiosResponse<Team>) => response.data)
}

export const deleteMember = (requestId: string) => {
  return axios
    .delete(API_URL_TEAMS, {
      data: {request_id: requestId},
    })
    .then((response: AxiosResponse<Team>) => response.data)
}
export const getTeamRequests = () => {
  return axios
    .get(API_URL_TEAM_REQUESTS)
    .then((response: AxiosResponse<TeamRequest[]>) => response.data)
}
export const acceptTeamRequest = (requestId: string) => {
  return axios
    .patch(API_URL_TEAM_REQUESTS, {request_id: requestId})
    .then((response: AxiosResponse<TeamRequest>) => response.data)
}
export const refuseTeamRequest = (requestId: string) => {
  return axios
    .delete(API_URL_TEAM_REQUESTS, {
      data: {request_id: requestId},
    })
    .then((response: AxiosResponse<TeamRequest>) => response.data)
}
export const updateLimit = (
  email: string,
  type: 'slot-project' | 'slot-simulation',
  newLimit: number
) => {
  return axios
    .patch(API_URL_SUBSCRIPTIONS, {
      key: type,
      new_limit: newLimit,
      email,
    })
    .then((response: AxiosResponse<any>) => response.data)
}

export const patchTeam = ({name, vat}: {name: string | undefined; vat?: string | undefined}) => {
  return axios
    .patch(API_URL_TEAMS, {name, vat})
    .then((response: AxiosResponse<Team>) => response.data)
}
