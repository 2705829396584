import { useFormik } from 'formik'
import { useState } from 'react'
import { Card } from 'react-bootstrap'
import { showError, sweetAlert } from '../../../../../utils/funcs'
import { useProject } from '../../../core/ProjectContext'
import { patchProject } from '../../../core/_requests'
import WidgetItem from './WidgetItem'
import { mockWidgetOrder, WidgetID, WidgetOrder } from './_models'

export default function OverviewOrder() {
  const [loading, setLoading] = useState<boolean>(false)

  const { project, setProject } = useProject()
  const widgetList: WidgetID[] = ['summary' as WidgetID, 'milestones' as WidgetID, 's_curve' as WidgetID, 'variances' as WidgetID, 'resources' as WidgetID, 'action_plan' as WidgetID, 'dashboard' as WidgetID]

  const initialOrder = project?.widget_order && project.widget_order.length
    ? project.widget_order
    : mockWidgetOrder

  const order = widgetList.map(widget =>
    initialOrder.find(item => item.widget === widget) || { widget: widget as WidgetID, show: false }
  )
  order.sort((a, b) => {
    const indexA = initialOrder.findIndex(item => item.widget === a.widget);
    const indexB = initialOrder.findIndex(item => item.widget === b.widget);
    return indexA - indexB;
  });

  



  const formik = useFormik({
    initialValues: {
      widgetOrder: order,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      setLoading(true)
      patchProject({ widget_order: values.widgetOrder }, project?._id.$oid || '')
        .then((res) => {
          setProject((prev) => (prev ? { ...prev, widget_order: res?.widget_order || [] } : prev))
          sweetAlert({
            title: 'Success',
            text: 'Changes made successfully!',
            icon: 'success',
            confirmButtonText: 'OK',
          })
        })
        .catch(showError)
        .finally(() => setLoading(false))
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <Card className='mt-5'>
        <Card.Header>
          <Card.Title>Overview</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className='d-flex flex-column gap-2'>
            {formik.values.widgetOrder.map((item) => (
              <WidgetItem
                formik={formik}
                key={item.widget}
                item={item}
              />
            ))}
          </div>
        </Card.Body>
        <Card.Footer>
          <button disabled={loading} type='submit' className='btn btn-primary'>
            {loading ? (
              <span className='spinner-border spinner-border-sm align-middle'></span>
            ) : (
              'Save'
            )}
          </button>
        </Card.Footer>
      </Card>
    </form>
  )
}
