import clsx from 'clsx';
import React from 'react';

type Props = {
  label: string;
  formikProps: any;
  touched: boolean | undefined;
  error: string | undefined;
  required?: boolean;
  placeholder?: string;
  unit?: string;
  props?: React.HTMLProps<HTMLInputElement>;
  className?: string;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
};

export default function InputWithLabel({
  error,
  formikProps,
  label,
  touched,
  required,
  placeholder,
  unit,
  props,
  className,
  disabled,
  onClick,
}: Props) {
  return (
    <div
      className={clsx('fv-row ' + className, {
        'mb-10': !className?.includes('mb'),
      })}
    >
      {!!label && (
        <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
          <span className={required ? 'required' : ''}>{label}</span>
          <i className='fas fa-exclamation-circle ms-2 fs-7' data-bs-toggle='tooltip' title=''></i>
        </label>
      )}
      <div className='input-group'>
        {!!unit && <span className='input-group-text'>{unit}</span>}
        <input
          disabled={disabled}
          type='text'
          placeholder={placeholder || ''}
          className={clsx(
            'form-control ',
            {'is-invalid': touched && error}
            // {
            //   'is-valid': touched && !error,
            // }
          )}
          {...formikProps}
          {...props}
          onClick={onClick}
        />
      </div>
      {touched && error && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{String(error)}</span>
          </div>
        </div>
      )}
    </div>
  );
}
