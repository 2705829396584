import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface addedState {
  value: boolean;
}

const initialAddedState: addedState = {
  value: false,
};

const commentsstateadded = createSlice({
    name: 'added',
    initialState: initialAddedState,
    reducers: {
        adding: (state: addedState) => {
        state.value = true;
      },
      done: (state: addedState) => {
        state.value = false;
      },
    },
  });

  export const { adding, done } = commentsstateadded.actions;

  const commentStates = {
    added: commentsstateadded.reducer,
  
  };
  
  export default commentStates;
