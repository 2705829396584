import { FC, Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import TopBarProgress from 'react-topbar-progress-indicator';
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils';
import { WithChildren } from '../../_metronic/helpers';
import { MasterLayout } from '../../_metronic/layout/MasterLayout';
import { NotificationProvider } from '../modules/notifications/NotificationContext';
import ProjectsPage from '../modules/projects';
import ProjectPage from '../modules/projects/ProjectPage';
import { ProjectProvider } from '../modules/projects/core/ProjectContext';
import SimulationsPage from '../modules/simulations';
import Simulation from '../modules/simulations/components/Simulation';
import { SimulationContextProvider } from '../modules/simulations/components/SimulationContext';
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper';
import { useAuth } from '../modules/auth';
import UnauthorizedPage from '../components/UnauthorizedPage';
import { TreeProvider } from '../modules/wbs/components/TreeView/TreeContext';

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'));
  const ReleasesPage = lazy(() => import('../modules/releases/ReleasesPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'));
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'));
  const CalendarsPage = lazy(() => import('./CalendarRoutes'));
  const LibraryPage = lazy(() => import('./LibraryRoutes'));
  const EPSPage = lazy(() => import('./EPSRoutes'));

  const { currentUser, currentPlanLevel } = useAuth();

  const ProtectedRoute = ({ children, requiredPlanLevel }: { children: JSX.Element, requiredPlanLevel: number }) => {
    if (currentUser && currentPlanLevel >= requiredPlanLevel) {
      return children;
    } else {
      return <Navigate to='/unauthorized' replace />;
    }
  };

  return (
    <NotificationProvider>
      <Routes>
        <Route element={<MasterLayout />}>
          {/* Redirect to Dashboard after success login/registration */}
          <Route path='auth/*' element={<Navigate to='/dashboard' />} />
          {/* Pages */}
          <Route
            path='dashboard'
            element={
              <ProjectProvider>
                <DashboardWrapper />
              </ProjectProvider>
            }
          />
          <Route path='simulations' element={<ProtectedRoute requiredPlanLevel={2}><SimulationsPage /></ProtectedRoute>} />
          <Route
            path='simulations/:id'
            element={
              <ProtectedRoute requiredPlanLevel={1}>
                <SimulationContextProvider>
                  <Simulation />
                </SimulationContextProvider>
              </ProtectedRoute>
            }
          />
          <Route path='projects' element={
             <ProjectProvider>
               <TreeProvider>
               <ProjectsPage />
               </TreeProvider>
             </ProjectProvider>
           
            } />
          {/* Lazy Modules */}
          <Route
            path='profile/*'
            element={
              <SuspensedView>
                <ProfilePage />
              </SuspensedView>
            }
          />
          <Route
            path='project/:id/*'
            element={
              <SuspensedView>
                <ProjectProvider>
                  <ProjectPage />
                </ProjectProvider>
              </SuspensedView>
            }
          />
          <Route
            path='account/*'
            element={
              <SuspensedView>
                <AccountPage />
              </SuspensedView>
            }
          />
          <Route path='enterprise'>
            <Route
              path='calendars/*'
              element={
                <SuspensedView>
                  <CalendarsPage />
                </SuspensedView>
              }
            />
            <Route
              path='library/*'
              element={
                <SuspensedView>
                  <LibraryPage />
                </SuspensedView>
              }
            />
            <Route path='eps/*' element={<ProtectedRoute requiredPlanLevel={3}><EPSPage /></ProtectedRoute>} />
          </Route>
          <Route path='help'>
               <Route
              path='releases/*'
              element={
                <SuspensedView>
                  <ReleasesPage />
                </SuspensedView>
              }
            />
          </Route>
          <Route path='help'>
               <Route
              path='releases/*'
              element={
                <SuspensedView>
                  <ReleasesPage />
                </SuspensedView>
              }
            />
          </Route>
          <Route
            path='team/*'
            element={
              <SuspensedView>
                <UsersPage />
              </SuspensedView>
            }
          />
          {/* Unauthorized Page */}
          <Route path='unauthorized' element={<UnauthorizedPage />} />
          {/* Page Not Found */}
          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
      </Routes>
    </NotificationProvider>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary');
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
