import {FC} from 'react'
import {Modal} from 'react-bootstrap'
import FTEForm from './FTEForm'

type Props = {
  show: boolean
  onHide: () => void
}

const FTEModal: FC<Props> = ({show, onHide}) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>FTE Settings</Modal.Title>
      </Modal.Header>
      <FTEForm onHide={onHide} />
    </Modal>
  )
}

export default FTEModal
