import { DatePicker } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { Field, FieldArray, FormikProvider, useFormik } from 'formik'
import { Dispatch, useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { KTSVG } from '../../../../../_metronic/helpers'
import { DateFormatType, sweetAlert } from '../../../../utils/funcs'
import { useProjectDetailsById, useProjectVersionById } from '../../core/_queries'
import ImportData from './ImportData'
import { getProjectData } from '../../../../utils/project-data'
import { getCustomCurve } from '../overview/Project'
import { getLabels } from '../../../../helpers/DateFormatter'
import { normalizeDateUnit } from '../../../../helpers/DateFormatter'
import * as Yup from 'yup'
import { Calendar } from '../../../calendars/_models'
import { getNewVersion, greaterThanOrEqual } from '../../core/ProjectHelpers'
import { createVersion, getVersionById, patchVersion } from '../../core/_requests'
import { useProject } from '../../core/ProjectContext'
import "./styles.css";
import { getDiffrentBettwenDate, normalizeToFirstOfMonthUTC } from '../../../../utils/data-transformarion/date-utils'
import clsx from 'clsx'
import { useCalendars } from '../../../calendars/_queries'
import CustomSelect from '../../../../components/CustomSelect'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../store'
import { combineVersionsV2 } from '../../../wbs/components/TreeView/_helpers'
import { updateLabel, updateLoaded, updateShow, updateTotal } from '../../../wbs/treeLoader'
import { setVersionData } from '../../../wbs/treeVersionData'
import { updateIsWpChanged, updateWpChangedId } from '../../../wbs/treedata'
import { isValid } from 'date-fns'

interface Props {
  data: any
  setVersionId?: Dispatch<React.SetStateAction<string | undefined>>
  setProjectversion?: Dispatch<React.SetStateAction<any | null>>
  handleUpdateWbsDataDate?: any
  handleUpdateWpDataDate?: any
  isEditable?: boolean
  isWbs?: boolean
  wpId?: string
}

export default function DateInputs({ data, setVersionId, isEditable = true, isWbs = false, setProjectversion, handleUpdateWbsDataDate, handleUpdateWpDataDate, wpId }: Props) {
  const { refetch } = useProjectVersionById(data._id.$oid, () => { }, false)
  const { refetch: refetchProject } = useProjectDetailsById(
    data?.project?.$oid || '',
    () => { },
    false
  )
  const { data: calendars, isLoading } = useCalendars({ page: 1, per_page: 0 })
  const [dateDiff, setDateDiff] = useState(
    getDiffrentBettwenDate(data.end_date.$date, data.start_date.$date, data?.period_count.type) + 1
  )
  const [dataDateDiff, setDataDateDiff] = useState(
    getDiffrentBettwenDate(data.data_date.$date, data.start_date.$date, data?.period_count.type) + 1
  )

  const {
    setPatchedWpData,
    setOriginalVersion,
    setVersion,
    project,
    originalVersion,
  } = useProject()

  const [loading, setLoading] = useState<boolean>(false)
  const dispatch = useDispatch();
  const projectversiondata = useSelector((state: RootState) => state.versiondata.data)


  useEffect(() => {
    setDataDateDiff(
      getDiffrentBettwenDate(data.data_date.$date, data.start_date.$date, data?.period_count.type) +
      1
    )
  }, [data?.data_date?.$date])

  useEffect(() => {
    if (data) {
      formik.setFieldValue('data_date', normalizeToFirstOfMonthUTC(new Date(data.data_date.$date)));
      formik.setFieldValue('end_date', normalizeToFirstOfMonthUTC(new Date(data.end_date.$date)));
      formik.setFieldValue('start_date', normalizeToFirstOfMonthUTC(new Date(data.start_date.$date)));
      formik.setFieldValue('plannedValue', getCustomCurve('cumulativePlannedValue', data));
      formik.setFieldValue('earnedValue', getCustomCurve('cumulativeEarnedValue', data));
      formik.setFieldValue('actualCost', getCustomCurve('cumulativeActualCost', data));
      formik.setFieldValue('delay_tolerance', data?.delay_tolerance?.count || 0);
      formik.setFieldValue('associated_calendar', data.associated_calendar);
      formik.setFieldValue('reserve', data?.reserve?.amount || 0);
    }
  }, [data]);



  const formik = useFormik({
    initialValues: {
      data_date: normalizeToFirstOfMonthUTC(new Date(data.data_date.$date)),
      end_date: normalizeToFirstOfMonthUTC(new Date(data.end_date.$date)),
      start_date: normalizeToFirstOfMonthUTC(new Date(data.start_date.$date)),
      plannedValue: getCustomCurve('cumulativePlannedValue', data),
      earnedValue: getCustomCurve('cumulativeEarnedValue', data),
      actualCost: getCustomCurve('cumulativeActualCost', data),
      delay_tolerance: data?.delay_tolerance?.count || 0,
      associated_calendar: data?.associated_calendar || null,
      reserve: data?.reserve?.amount || 0,
    },
    validationSchema: Yup.object().shape({
      data_date: Yup.date(),
      start_date: Yup.date().required('Start date is required'),
      end_date: Yup.date()
        .required('End date is required')
        .test('end_date', 'End date must be greater than start date', (value): boolean => {
          if (value)
            return value > formik.values.start_date
          return false
        }),
      plannedValue: Yup.array()
        .test('number', 'Planned values must be numbers', (value, context) => {
          return value?.every((el) => !isNaN(+el)) || false
        })
        .test('non-zero', 'Planned values must be greater than 0', (value, context) => {
          return value?.every((el) => el > 0) || false
        }),
      actualCost: Yup.array()
        .test(
          'length',
          'Actual cost values must be equal to the number of periods',
          (value, context) => {
            return (value?.length || 0) >= dataDateDiff || false
          }
        )

        .test('number', 'Actual cost values must be numbers', (value, context) => {
          return value?.every((el) => !isNaN(+el)) || false
        })
        .test('evTest', 'Actual cost values must be cumulative', (value, context) => {
          return value?.every(greaterThanOrEqual) || false
        }),
      earnedValue: Yup.array()
        .test(
          'length',
          'Earned values must be equal to the number of periods',
          (value, context) => {
            return (value?.length || 0) >= dataDateDiff || false
          }
        )

        .test('number', 'Earned values must be numbers', (value, context) => {
          return value?.every((el) => !isNaN(+el)) || false
        })
        .test('evTest', 'Earned values must be cumulative', (value, context) => {
          return value?.every(greaterThanOrEqual) || false
        }),
      delay_tolerance: Yup.number()
        .required('Delay Tolerance is required')
        .min(0, "Delay Tolerance can't be negative"),
      reserve: Yup.number().required('Budget is required').min(0, "Budget can't be negative"),
    }),
    enableReinitialize: false,
    onSubmit: async (values) => {
      console.log('values', values)
    },
  })

  const changeReserve = (changeType: string) => {
    if (changeType === '+') {
      formik.setFieldValue('reserve', formik.values.reserve + 1000)
    } else {
      formik.setFieldValue(
        'reserve',
        formik.values.reserve - 1000 < 0 ? 0 : formik.values.reserve - 1000
      )
    }
  }

  const changeTolerance = (changeType: string) => {
    if (changeType === '+') {
      formik.setFieldValue('delay_tolerance', formik.values.delay_tolerance + 1)
    } else {
      formik.setFieldValue(
        'delay_tolerance',
        formik.values.delay_tolerance - 1 < 0 ? 0 : formik.values.delay_tolerance - 1
      )
    }
  }
  
  //this is for saving it to the current version
  const handleSave = async () => {
    if (!formik.isValid || !formik.dirty || loading) return

    setLoading(true)
    let newVersion = { ...data }
    newVersion.data_date = { $date: formik.values.data_date.getTime() }
    newVersion.end_date = { $date: formik.values.end_date.getTime() }
    newVersion.start_date = { $date: formik.values.start_date.getTime() }
    newVersion.custom_curve = [
      {
        color: 'black',
        name: 'cumulativePlannedValue',
        values: formik.values.plannedValue.map((el) => +el).slice(0, dateDiff),
      },
      {
        color: 'white',
        name: 'cumulativeEarnedValue',
        values: formik.values.earnedValue.map((el) => +el).slice(0, dataDateDiff),
      },
      {
        color: 'red',
        name: 'cumulativeActualCost',
        values: formik.values.actualCost.map((el) => +el).slice(0, dataDateDiff),
      },
    ]
    newVersion.delay_tolerance = {
      count: +formik.values.delay_tolerance,
      type: data?.delay_tolerance?.type || 'daily',
    }

    newVersion.budget_at_completion = {
      ...newVersion.budget_at_completion,
      amount: getCustomCurve('cumulativePlannedValue', newVersion)[getCustomCurve('cumulativePlannedValue', newVersion).length - 1]
    };

    newVersion.associated_calendar = formik.values.associated_calendar
    try {

      dispatch(updateShow(true));
      setLoading(true)

      const calculatedVersionData = await setPatchedWpData(
        data?.name ? data?.name : 'WBS',
        newVersion,
        formik.values.data_date,
      );
      console.log("calculatedVersionData ==>")
      console.log(calculatedVersionData)
      setLoading(false)
      dispatch(updateShow(false));

      newVersion = calculatedVersionData ? { ...calculatedVersionData } : { ...newVersion, output: {} }
      const { _id, project, user, isWbs, milestones, rebase_line, comments, action_plan, updated_at, ...payload } = newVersion

      const updatedVersion = await patchVersion(
        {
          ...payload,
          data_date: formik.values.data_date,
          start_date: formik.values.start_date,
          end_date: formik.values.end_date,
          custom_curve: newVersion.custom_curve,
          associated_calendar: formik.values.associated_calendar?._id.$oid || null,
          delay_tolerance: newVersion.delay_tolerance,
          forecast_settings: {
            ...payload.forecast_settings,
            custom_end_date: payload.forecast_settings?.custom_end_date
              ? new Date(payload.forecast_settings.custom_end_date.$date)
              : null,
          },
          reserve: {
            amount: +formik.values.reserve,
            unit: data?.budget_at_completion.unit || 'thousand',
          },
          output: calculatedVersionData ? calculatedVersionData.output : data.output,
        },
        data._id.$oid
      )
      dispatch(setVersionData({ id: updatedVersion._id.$oid, data: updatedVersion }))
      sweetAlert({
        text: 'Your changes have been saved successfully',
        title: 'Success',
        icon: 'success',
      }).then(() => {
        if (wpId) {
          dispatch(updateWpChangedId(wpId))
          dispatch(updateIsWpChanged(true));
        }
      })
      dispatch(setVersionData({ id: updatedVersion._id.$oid, data: updatedVersion }));
      if (originalVersion?._id.$oid === data._id.$oid) {
        setOriginalVersion(updatedVersion)
        setVersion(updatedVersion)
      }
      if (setProjectversion)
        setProjectversion(updatedVersion)
    } catch (error: any) {
      sweetAlert({
        title: 'Error',
        text: error?.message || 'Something went wrong',
        icon: 'error',
      })
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setDateDiff(
      getDiffrentBettwenDate(
        formik.values.end_date.getTime(),
        formik.values.start_date.getTime(),
        data?.period_count.type
      ) + 1
    )
    setDataDateDiff(
      getDiffrentBettwenDate(
        data.data_date.$date,
        formik.values.start_date.getTime(),
        data?.period_count.type
      ) + 1
    )
  }, [formik.values.start_date, formik.values.end_date])

  const normalizedDateUnit = normalizeDateUnit(data?.period_count?.type)
  const labels = getLabels(
    formik.values.start_date.getTime(),
    Math.max(dateDiff, dataDateDiff) + 1,
    normalizedDateUnit,
    data?.date_format as DateFormatType
  )

  const checkCompleteData = async (data_date: Date) => {
    let originalVersion = projectversiondata[data._id.$oid]
    if (!originalVersion) {
      originalVersion = await getVersionById(data._id.$oid)
    }
    const dateDiff = getDiffrentBettwenDate(
      data_date.getTime(),
      originalVersion?.start_date.$date || 0,
      originalVersion?.period_count.type || ''
    )
    return !(
      dateDiff > getCustomCurve('cumulativeEarnedValue', originalVersion).length - 1 ||
      dateDiff > getCustomCurve('cumulativeActualCost', originalVersion).length - 1
    )
  }

  const handleDataDateChange = async (newDate: dayjs.Dayjs | null) => {
    formik.setFieldTouched('data_date', true)
    if (newDate) {
      const selectedDate = normalizeToFirstOfMonthUTC(newDate.toDate());
      formik.setFieldValue('data_date', selectedDate)
      if (setProjectversion)
        setProjectversion((prev: any) => ({ ...prev, data_date: { $date: selectedDate.getTime() } }))
      const isComplete = await checkCompleteData(selectedDate)
      if (!isComplete) return
      if (isWbs && handleUpdateWbsDataDate) {
        await handleUpdateWbsDataDate(selectedDate)
      } else if (isEditable && handleUpdateWpDataDate) {
        handleUpdateWpDataDate(selectedDate)
      }
    }
  }



  useEffect(() => {
    const newDateDiff = getDiffrentBettwenDate(
      formik.values.end_date.getTime(),
      formik.values.start_date.getTime(),
      data?.period_count.type
    ) + 1;

    const newDataDateDiff = getDiffrentBettwenDate(
      formik.values.data_date.getTime(),
      formik.values.start_date.getTime(),
      data?.period_count.type
    ) + 1;

    // Update dateDiff and dataDateDiff
    setDateDiff(newDateDiff);
    setDataDateDiff(newDataDateDiff);

    // Ensure plannedValue is an array and adjust its length
    const updatedPlannedValue = [...(formik.values.plannedValue || [])];
    const lastPlannedValue = updatedPlannedValue[updatedPlannedValue.length - 1] || 0;
    if (updatedPlannedValue.length < newDateDiff) {
      updatedPlannedValue.push(...new Array(newDateDiff - updatedPlannedValue.length).fill(lastPlannedValue));
    } else if (updatedPlannedValue.length > newDateDiff) {
      updatedPlannedValue.length = newDateDiff; // Truncate the array
    }
    formik.setFieldValue('plannedValue', updatedPlannedValue);

    // Ensure earnedValue is an array and adjust its length
    const updatedEarnedValue = [...(formik.values.earnedValue || [])]; // Always ensure array
    const lastEarnedValue = updatedEarnedValue[updatedEarnedValue.length - 1] || 0;
    if (updatedEarnedValue.length < newDataDateDiff) {
      updatedEarnedValue.push(...new Array(newDataDateDiff - updatedEarnedValue.length).fill(lastEarnedValue));
    } else if (updatedEarnedValue.length > newDataDateDiff) {
      updatedEarnedValue.length = newDataDateDiff;
    }
    formik.setFieldValue('earnedValue', updatedEarnedValue);

    // Ensure actualCost is an array and adjust its length
    const updatedActualCost = [...(formik.values.actualCost || [])]; // Always ensure array
    const lastActualCost = updatedActualCost[updatedActualCost.length - 1] || 0;
    if (updatedActualCost.length < newDataDateDiff) {
      updatedActualCost.push(...new Array(newDataDateDiff - updatedActualCost.length).fill(lastActualCost));
    } else if (updatedActualCost.length > newDataDateDiff) {
      updatedActualCost.length = newDataDateDiff;
    }
    formik.setFieldValue('actualCost', updatedActualCost);

  }, [formik.values.start_date, formik.values.end_date, formik.values.data_date]);





  return (
    <>
      <div className='d-flex flex-wrap flex-stack pb-7'>
        <div>
          <h4>Change Project Duration</h4>
        </div>
        {isEditable && (
          <div className='d-flex flex-wrap my-1 gap-1'>
            <Dropdown>
              <Dropdown.Toggle
                onClick={() => handleSave()}
                disabled={
                  !formik.dirty ||
                  !formik.isValid ||
                  loading
              
                }
                className='btn btn-sm btn-primary show menu-dropdown'
              >
                Save
              </Dropdown.Toggle>
            </Dropdown>
          </div>
        )}
      </div>
      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
          <span>Data Date</span>
        </label>
        <DatePicker
          cellRender={(current: Dayjs, info: any) => {
            const startDate = dayjs(
              projectversiondata[data?._id.$oid] ?
                projectversiondata[data?._id.$oid]?.start_date.$date
                : data?.start_date.$date
            );

            const dataDate = dayjs(
              projectversiondata[data?._id.$oid] ?
                projectversiondata[data?._id.$oid]?.data_date.$date
                : data?.data_date.$date);


            if (current.isBefore(startDate)) {
              return <div className="not-started">{data?.period_count.type === 'monthly' ? 'NS' : 'Not Started'}</div>;
            }

            return (
              <div
                className={clsx(
                  current.isBefore(dataDate) || current.isSame(dataDate) ? 'highlighted' : ''
                )}
              >
                {info.originNode}
              </div>
            );
          }}
          picker={data?.period_count?.type === 'daily' ? 'date' : 'month'}
          value={dayjs(formik.values.data_date)}
          popupClassName={'dark-mode-datepicker'}
          className='form-control form-control-solid my-datepicker fw-bold'
          onChange={async (newDate) => {
            await handleDataDateChange(newDate)
          }}
          placeholder={''}
          disabledDate={(current) => {
            const startDate = dayjs(
              projectversiondata[data?._id.$oid]
                ? projectversiondata[data?._id.$oid]?.start_date.$date
                : data?.start_date.$date
            );
            // Disable dates before startDate
            return current && current.isBefore(startDate);
          }}
        />
      </div>
      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
          <span>Start Date</span>
        </label>
        <DatePicker
          picker={data?.period_count?.type === 'daily' ? 'date' : 'month'}
          value={dayjs(formik.values.start_date)}
          popupClassName={'dark-mode-datepicker'}
          className='form-control form-control-solid my-datepicker fw-bold'
          onChange={(newDate) => {
            formik.setFieldTouched('start_date', true)
            if (newDate) {
              formik.setFieldValue('start_date', normalizeToFirstOfMonthUTC(newDate.toDate()))
            }
          }}
          placeholder={''}
          disabled={!isEditable}
        />
      </div>
      {formik.touched.start_date && formik?.errors?.start_date && (
        <div className='row mb-10'>
          <h4 className='text-danger'>
            <>{formik.errors.start_date}</>
          </h4>
        </div>
      )}

      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
          <span>End Date</span>
        </label>
        <DatePicker
          picker={data?.period_count?.type === 'daily' ? 'date' : 'month'}
          value={dayjs(formik.values.end_date)}
          popupClassName={'dark-mode-datepicker'}
          className='form-control form-control-solid my-datepicker fw-bold'
          onChange={(newDate) => {
            formik.setFieldTouched('end_date', true)
            if (newDate) {
              formik.setFieldValue('end_date', normalizeToFirstOfMonthUTC(newDate.toDate()))
            }
          }}
          placeholder={''}
          disabled={!isEditable}
        />
      </div>
      {formik.touched.end_date && formik?.errors?.end_date && (
        <div className='row mb-10'>
          <h4 className='text-danger'>
            <>{formik.errors.end_date}</>
          </h4>
        </div>
      )}


      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
          <span>Associated Calendar </span>
        </label>

        <CustomSelect
          name='calendar'
          value={
            formik.values.associated_calendar
              ? {
                value: formik.values.associated_calendar,
                label: formik.values.associated_calendar.name,
              }
              : {
                value: {},
                label: isLoading ? 'Loading...' : 'No Calendar',
                isOptionDisabled: isLoading,
              }
          }
          isSearchable
          maxHeight='200px'
          onChange={(item: { label: string; value: Calendar }) => {
            formik.setFieldValue('associated_calendar', item.value)
          }}
          options={[
            { label: 'No Calendar', value: undefined },
            ...(calendars?.items?.map((item) => ({ label: item.name, value: item })) || []),
          ]}
        />
      </div>


      <div className="row">
        <div className="col-md-6">
          <div className='row mb-8'>
            <div className='col-xl-3'>
              <div className='fs-6 fw-semibold mt-2 mb-3'>Manage Delay Tolerance</div>
            </div>
            <div className='col-xl-9'>
              <div className='position-relative w-md-300px'>
                <button
                  type='button'
                  className='btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 start-0'
                  data-kt-dialer-control='decrease'
                  onClick={() => changeTolerance('-')}
                  disabled={!isEditable}
                >
                  <span className='svg-icon svg-icon-1'>
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <rect
                        opacity='0.3'
                        x='2'
                        y='2'
                        width='20'
                        height='20'
                        rx='5'
                        fill='currentColor'
                      />
                      <rect x='6.0104' y='10.9247' width='12' height='2' rx='1' fill='currentColor' />
                    </svg>
                  </span>
                </button>
                <input
                  style={{
                    WebkitAppearance: 'none',
                    margin: 0

                  }}
                  type='number'
                  className='form-control form-control-solid border-0 ps-12'
                  data-kt-dialer-control='input'
                  placeholder='Amount'
                  name='delay_tolerance'
                  onBlur={formik.handleBlur}
                  onChange={(e: any) => {
                    if (isNaN(e.target.value)) return
                    formik.handleChange(e)
                  }}
                  value={formik.values.delay_tolerance}
                  disabled={!isEditable}
                />
                <button
                  type='button'
                  className='btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 end-0'
                  data-kt-dialer-control='increase'
                  onClick={() => changeTolerance('+')}
                  disabled={!isEditable}
                >
                  <span className='svg-icon svg-icon-1'>
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <rect
                        opacity='0.3'
                        x='2'
                        y='2'
                        width='20'
                        height='20'
                        rx='5'
                        fill='currentColor'
                      />
                      <rect
                        x='10.8891'
                        y='17.8033'
                        width='12'
                        height='2'
                        rx='1'
                        transform='rotate(-90 10.8891 17.8033)'
                        fill='currentColor'
                      />
                      <rect x='6.01041' y='10.9247' width='12' height='2' rx='1' fill='currentColor' />
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className='row mb-5'>
            {formik.errors.delay_tolerance && (
              <h4 className='text-danger'>{formik.errors.delay_tolerance.toString()}</h4>
            )}
          </div>
        </div>
        <div className="col-md-6">
          <div className='row mb-8'>
            <div className='col-xl-3'>
              <div className='fs-6 fw-semibold mt-2 mb-3'>Manage Reserve</div>
            </div>
            <div className='col-xl-9'>
              <div
                className='position-relative w-md-300px'
                data-kt-dialer='true'
                data-kt-dialer-min='1000'
                data-kt-dialer-max='50000'
                data-kt-dialer-step='1000'
                data-kt-dialer-prefix='$'
                data-kt-dialer-decimals='2'
              >
                <button
                  type='button'
                  className='btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 start-0'
                  data-kt-dialer-control='decrease'
                  onClick={() => changeReserve('-')}
                  disabled={!isEditable}
                >
                  <span className='svg-icon svg-icon-1'>
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <rect
                        opacity='0.3'
                        x='2'
                        y='2'
                        width='20'
                        height='20'
                        rx='5'
                        fill='currentColor'
                      />
                      <rect
                        x='6.0104'
                        y='10.9247'
                        width='12'
                        height='2'
                        rx='1'
                        fill='currentColor'
                      />
                    </svg>
                  </span>
                </button>
                <input
                  type='text'
                  className='form-control form-control-solid border-0 ps-12'
                  data-kt-dialer-control='input'
                  placeholder='Amount'
                  name='reserve'
                  onBlur={formik.handleBlur}
                  onChange={(e: any) => {
                    if (isNaN(e.target.value)) return
                    formik.handleChange(e)
                  }}
                  value={formik.values.reserve}
                  disabled={!isEditable}
                />
                <button
                  type='button'
                  className='btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 end-0'
                  data-kt-dialer-control='increase'
                  onClick={() => changeReserve('+')}
                  disabled={!isEditable}
                >
                  <span className='svg-icon svg-icon-1'>
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <rect
                        opacity='0.3'
                        x='2'
                        y='2'
                        width='20'
                        height='20'
                        rx='5'
                        fill='currentColor'
                      />
                      <rect
                        x='10.8891'
                        y='17.8033'
                        width='12'
                        height='2'
                        rx='1'
                        transform='rotate(-90 10.8891 17.8033)'
                        fill='currentColor'
                      />
                      <rect x='6.01041' y='10.9247' width='12' height='2' rx='1' fill='currentColor' />
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FormikProvider value={formik}>
        {getCustomCurve('cumulativePlannedValue', data).length < dateDiff && formik.dirty && (
          <>
            <h3 className=''>Planned Data</h3>
            <div className='table-responsive'>
              <div className='d-flex flex-wrap flex-stack align-items-end my-2'></div>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bolder text-muted'>
                    <th className='min-w-150px'></th>
                    {Array(dateDiff)
                      .fill(0)
                      .map((item: any, index: number) => (
                        <th key={index} className='min-w-120px'>
                          {labels[index]}
                        </th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <a className='text-primary fw-bolder text-hover-primary fs-6'>
                        Planned Value
                      </a>
                    </td>
                    <FieldArray
                      name='earnedValue'
                      render={(arrayHelpers) => (
                        <>
                          {formik.values.plannedValue &&
                            Array(dateDiff)
                              .fill(0)
                              .map((value: any, index: number) => (
                                <td className='position-relative' key={index}>
                                  <Field
                                    placeholder='0'
                                    name={`plannedValue.${index}`}
                                    className="text-dark fw-bold d-block fs-7 text-danger 'border-bottom border-primary"
                                    disabled={!isEditable}
                                  />
                                </td>
                              ))}
                        </>
                      )}
                    />
                  </tr>
                </tbody>
              </table>
            </div>
            {formik.errors.plannedValue && formik.touched.plannedValue && (
              <h4 className='text-danger'>{formik.errors.plannedValue.toString()}</h4>
            )}
          </>
        )}
        {/* second table for actual Data */}
        {getCustomCurve('cumulativeActualCost', data).length < dataDateDiff &&
          formik.dirty && (
            <>
              <h3 className=''>Actual Project Data</h3>
              <div className='d-flex flex-wrap flex-stack align-items-end my-2'></div>
              <div className='table-responsive'>
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                  <thead>
                    <tr className='fw-bolder text-muted'>
                      <th className='min-w-150px'></th>
                      {Array(dataDateDiff)
                        .fill(0)
                        .map((item: any, index: number) => (
                          <th key={index} className='min-w-120px'>
                            {labels[index]}
                          </th>
                        ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <a className='text-primary fw-bolder text-hover-primary fs-6'>
                          Earned Value
                        </a>
                      </td>
                      <FieldArray
                        name='earnedValue'
                        render={(arrayHelpers) => (
                          <>
                            {formik.values.earnedValue &&
                              Array(dataDateDiff)
                                .fill(0)
                                .map((value: any, index: number) => (
                                  <td className='position-relative' key={index}>
                                    <Field
                                      placeholder='0'
                                      name={`earnedValue.${index}`}
                                      className="text-dark fw-bold d-block fs-7 text-danger 'border-bottom border-primary"
                                      disabled={!isEditable}
                                    />
                                  </td>
                                ))}
                          </>
                        )}
                      />
                    </tr>
                    <tr>
                      <td>
                        <a className='text-primary fw-bolder text-hover-primary fs-6'>
                          Actual Cost
                        </a>
                      </td>
                      <FieldArray
                        name='actualCost'
                        render={(arrayHelpers) => (
                          <>
                            {formik.values.earnedValue &&
                              Array(dataDateDiff)
                                .fill(0)
                                .map((value: any, index: number) => (
                                  <td className='position-relative' key={index}>
                                    <Field
                                      placeholder='0'
                                      name={`actualCost.${index}`}
                                      className="text-dark fw-bold d-block fs-7 text-danger 'border-bottom border-primary"
                                      disabled={!isEditable}
                                    />
                                  </td>
                                ))}
                          </>
                        )}
                      />
                    </tr>
                  </tbody>
                </table>
              </div>
              {formik.errors.earnedValue && formik.touched.earnedValue && (
                <h4 className='text-danger'>{formik.errors.earnedValue.toString()}</h4>
              )}
              {formik.errors.actualCost && formik.touched.actualCost && (
                <h4 className='text-danger'>{formik.errors.actualCost.toString()}</h4>
              )}
            </>
          )}
      </FormikProvider>
    </>
  )
}
