import { DataNode } from 'antd/es/tree';
import { Dispatch, FC, SetStateAction, createContext, useContext, useEffect, useState } from 'react';
import { WithChildren } from '../../../../../_metronic/helpers';
import { useProject } from '../../../projects/core/ProjectContext';
import TreeFolderItem from './TreeFolderItem';

type TreeViewProps = {
  treeData: DataNode[];
  setTreeData: Dispatch<SetStateAction<DataNode[]>>;
  expandedKeys: string[];
  setExpandedKeys: Dispatch<SetStateAction<string[]>>;
  isLoading: boolean;
};

const initTreeViewProps: TreeViewProps = {
  treeData: [],
  setTreeData: () => { },
  expandedKeys: [],
  setExpandedKeys: () => { },
  isLoading: true,
};

const TreeContext = createContext<TreeViewProps>(initTreeViewProps);

const useTree = () => useContext(TreeContext);

const TreeProvider: FC<WithChildren> = ({ children }) => {
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [treeData, setTreeData] = useState<DataNode[]>([]);
  const { project } = useProject();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadAllWbs = async (wbsId: string): Promise<DataNode | null> => {
      if (project?.checked_wbs) {
        const includeNode = project.checked_wbs.some(wbs => wbs.$oid === wbsId);
        if (!includeNode && wbsId !== project?.share_wbs.$oid) {
          return null;
        }
      }

      const wbsItem = project?.generated_wbs_list?.find(wbs => wbs._id.$oid === wbsId);

      if (!wbsItem) {
        throw new Error(`WBS item with id ${wbsId} not found`);
      }

      const childrenData: DataNode[] = (await Promise.all(
        wbsItem?.subwbs.map(async (subWbs) => await loadAllWbs(subWbs.$oid))
      )).filter(Boolean) as DataNode[];

      return {
        key: wbsItem._id.$oid,
        title: <TreeFolderItem folder={wbsItem} isShare={true} />,
        children: childrenData,
      };
    };

    const initializeTree = async () => {
      if (project?.share_wbs.$oid) {
        const rootNode = await loadAllWbs(project.share_wbs.$oid);

        if (rootNode) {
          setTreeData([rootNode]);
          setExpandedKeys((keys) => [...keys, rootNode.key.toString()]);
        }
      }
      setIsLoading(false);
    };

    initializeTree();
  }, [project?.share_wbs, project?.checked_wbs]);

  return (
    <TreeContext.Provider value={{ expandedKeys, isLoading, setExpandedKeys, setTreeData, treeData }}>
      {children}
    </TreeContext.Provider>
  );
};

export { TreeProvider, useTree };
