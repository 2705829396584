// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".input_inputContent__TDbGX input[type=range] {\n  outline: none;\n}\n.input_inputContent__TDbGX input[type=range]:active {\n  outline: 1px solid rgba(35, 91, 236, 0.3137254902);\n  border-radius: 6px;\n}", "",{"version":3,"sources":["webpack://./src/app/components/Input/input.module.scss"],"names":[],"mappings":"AACI;EACI,aAAA;AAAR;AAEQ;EACI,kDAAA;EACA,kBAAA;AAAZ","sourcesContent":[".inputContent {\n    input[type='range'] {\n        outline: none;\n      \n        &:active {\n            outline: 1px solid #235bec50;\n            border-radius: 6px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputContent": "input_inputContent__TDbGX"
};
export default ___CSS_LOADER_EXPORT___;
