/* eslint-disable jsx-a11y/anchor-is-valid */
import { TreeProvider } from '../../../wbs/components/TreeView/TreeContext';
import TreeView from '../../../wbs/components/TreeView/TreeView';
import { useProject } from '../../core/ProjectContext';
import { Link, Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import Folders from '../../../wbs/components/Folders';
import { PageTitle as PageBreadCrumbs } from '../../../../../_metronic/layout/components/toolbar/page-title/PageTitle';
import { PageTitle } from '../../../../../_metronic/layout/core';
import HierarchyView from '../../../wbs/components/hierarchy/HierarchyView';
export function Wbs() {
  const { displayVersion: data, project } = useProject();
  const location = useLocation();
  const intl = useIntl();

  return (
    <>
      <div className='d-flex flex-row justify-content-between'>
        <PageTitle breadcrumbs={[]}>WBS</PageTitle>
        <PageBreadCrumbs />
      </div>

      <Routes>
        <Route
          path="/"
          element={
            <>
              <nav className='d-flex overflow-auto h-55px'>
                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                  <li className='nav-item'>
                    <Link
                      className={clsx('nav-link text-active-primary me-6', {
                        active: location.pathname === `/project/${project?._id.$oid}/wbs/tree`,
                      })}
                      to={`/project/${project?._id.$oid}/wbs/tree`}
                    >
                      Tree
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={clsx('nav-link text-active-primary me-6', {
                        active: location.pathname.includes(`/project/${project?._id.$oid}/wbs/heirarchy/${project?.default_wbs?._id.$oid}`),
                      })}
                      to={`/project/${project?._id.$oid}/wbs/heirarchy/${project?.default_wbs?._id.$oid}`}
                    >
                      heirarchy
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={clsx('nav-link text-active-primary me-6', {
                        active: location.pathname.includes(`/project/${project?._id.$oid}/wbs/folders/${project?.default_wbs?._id.$oid}`),
                      })}
                      to={`/project/${project?._id.$oid}/wbs/folders/${project?.default_wbs?._id.$oid}`}
                    >
                      Folder
                    </Link>
                  </li>
                </ul>
              </nav>
              <Outlet />
            </>
          }
        >
          <Route path="tree" element={<TreeProvider><TreeView /></TreeProvider>} />
          <Route path="folders/:id" element={<Folders />} />
          <Route path="folders" element={<Folders />} />
          <Route path="heirarchy/:id" element={<HierarchyView />} />
          <Route index element={<Navigate to={`/project/${project?._id.$oid}/wbs/tree`} />} />
        </Route>
      </Routes>
    </>
  );
}
