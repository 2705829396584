import clsx from 'clsx'
import {useState, useEffect, useMemo} from 'react'
import FreeTrialAdd from '../../../../app/modules/apps/user-management/FreeTrialAdd'
import {useAuth} from '../../../../app/modules/auth'
import {KTSVG} from '../../../helpers'
import {HeaderNotificationsMenu, HeaderUserMenu, Search, ThemeModeSwitcher} from '../../../partials'
import {useLayout} from '../../core'
import {sweetAlert} from '../../../../app/utils/funcs'
import {getUserImageLink} from '../../../../app/modules/auth/core/_requests'

const itemClass = 'ms-1 ms-md-3'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'svg-icon-1'

const Navbar = () => {
  const {config} = useLayout()
  const [width, setWidth] = useState<number>(window.innerWidth)
  const {currentUser, currentPlanLevel} = useAuth()
  const [showTrial, setShowTrial] = useState(false)
  const [showTrialButton, setShowTrialButton] = useState(false)

  useEffect(() => {
    currentPlanLevel ==  1 ? setShowTrialButton(true) : setShowTrialButton(false)
  })

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])
  const isMobile = useMemo(() => width <= 768, [width])

  return (
    <div className='app-navbar flex-shrink-0'>
      {!currentUser?.current_subscription && currentUser?.role !== 'team_member' && (
        <div className={clsx('app-navbar-item', itemClass)}>
          {
            showTrialButton && (
            <span
              onClick={() => setShowTrial(true)}
              className='btn btn-icon btn-custom btn-muted btn-color-muted btn-active-light btn-active-color-primary w-90px h-35px w-md-90px h-md-40px'
            >
              Free Trial
              <FreeTrialAdd
                currentUser={currentUser}
                handleClose={() => setShowTrial(false)}
                show={showTrial}
                sweetAlert={sweetAlert}
              />
            </span>)
          }
          
        </div>
      )}
      <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
        <Search />
      </div>
      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'hover'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={clsx('position-relative', btnClass)}
        >
          <KTSVG path='/media/icons/duotune/general/gen007.svg' className={btnIconClass} />
          {!!currentUser?.socket?.missed_events?.length && (
            <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
          )}
        </div>
        <HeaderNotificationsMenu />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>

      <div
        // onClick={() => {
        //   const rightBar = document.getElementById('rightBar')
        //   if (rightBar !== null && rightBar !== undefined) {
        //     rightBar.style.display = 'block !important'
        //   }
        // }}
        className={clsx('app-navbar-item', itemClass)}
      >
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          {currentUser?.image?.$oid ? (
            <img src={getUserImageLink(currentUser?._id.$oid || '')} alt='' />
          ) : (
            <div
              className='bg-secondary d-flex align-items-center justify-content-center rounded'
              style={{width: '40px', height: '40px'}}
            >
              <h4>
                {currentUser?.full_name
                  ?.split(' ')
                  .reduce((acc, val) => acc.charAt(0) + val.charAt(0))
                  .toUpperCase()}
              </h4>
            </div>
          )}
        </div>
        <HeaderUserMenu isMobile={isMobile} />
      </div>
    </div>
  )
}

export {Navbar}
