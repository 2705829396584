import clsx from 'clsx'
import {useState} from 'react'

type Props = {id: string; goTo: any; editable: boolean; onDelete: any}

export default function AssociationBadge({id, goTo, editable, onDelete}: Props) {
  const [isHovered, setIsHovered] = useState<boolean>(false)
  return (
    <button
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className='btn btn-secondary btn-sm px-3 py-2 position-relative'
      onClick={goTo}
    >
      {id}
      {editable && (
        <span
          onClick={(e) => {
            e.stopPropagation()
            onDelete()
          }}
          className={clsx(
            'position-absolute top-0 start-100 translate-middle badge badge-circle badge-danger badge-sm',
            {
              'opacity-0': !isHovered,
              'opacity-1': isHovered,
            }
          )}
          style={{transition: 'opacity ease-in-out 300ms'}}
        >
          x
        </span>
      )}
    </button>
  )
}
