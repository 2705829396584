import Chart from "./Chart";
import clsx from 'clsx'
import { useState } from 'react'
import { Collapse } from 'react-bootstrap'
import { KTSVG } from '../../../../../_metronic/helpers'
import SnapshotShareChart from "./SnapshotShareChart";
import LiveShareChart from "./LiveShareChart";
import GlobalDashboardSettingsModal from "./modals/GlobalDashboardSettingsModal";



type Props = {
  isShared?: boolean;
  share_type?: string;
}
export default function Dashboard({ isShared, share_type }: Props) {
  const [dashboardExpanded, setDashboardExpanded] = useState(true)
  const [showModal, setShowModal] = useState<boolean>(false)

  return (
    <>
      <div className='my-3 d-flex justify-content-between px-5'>
        <div className='d-inline-flex gap-2'>
          <span>
            <button
              onClick={() => setDashboardExpanded((prev) => !prev)}
              className='btn btn-light p-2 d-inline-flex align-items-center justify-content-center rotate active z-index-2'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr021.svg'
                className={clsx('svg-icon-muted svg-icon svg-icon-sm me-0', {
                  'rotate-270': !dashboardExpanded,
                  'rotate-90': dashboardExpanded,
                })}
              />
            </button>
          </span>
          <span className='card-label fw-bold fs-3'>Dashboard</span>

        </div>

        {!isShared && (
          <div className='d-flex align-items-center gap-2'>
            <i
              onClick={() => setShowModal(true)}
              className='bi bi-gear text-hover-primary d-flex justify-content-end cursor-pointer ms-2'
              onMouseOver={(e) => (e.currentTarget.style.opacity = '0.75')}
              onMouseOut={(e) => (e.currentTarget.style.opacity = '1')}
              style={{ fontSize: '2rem', transition: 'all 0.2s ease-in-out' }}
            ></i>
            {/* <GlobalDashboardSettingsModal show={showModal} onHide={() => setShowModal(false)} /> */}
          </div>
        )}
      </div>
      <Collapse in={dashboardExpanded}>
        <div>

          <div className='row g-5 gy-2 g-xl-8 gy-xl-2 pt-5'>
            <div className='col'>
              {(isShared && share_type === 'snapshot') ?
                (
                  <SnapshotShareChart />
                ) : (isShared && share_type === 'live') ? (
                  <LiveShareChart />
                ) : (
                  <Chart />
                )
              }
              {/* </TreeProvider> */}
            </div>
          </div>
        </div>
      </Collapse>
    </>
  )
}