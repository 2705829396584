import { Link, useParams } from 'react-router-dom'
import WbsItem from './WbsItem'
import { useWbs } from '../_queries'
import { KTSVG } from '../../../../_metronic/helpers'
import { useState } from 'react'
import CreateWbsItem from './CreateWbsItem'
import { Wbs } from '../_models'
import { showError } from '../../../utils/funcs'
import EmptyValuesCard from '../../../components/emptyValuesCard'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import PreviousItem from './PreviousItem'

export default function Wbss() {
  const { id } = useParams()
  const [newWbs, setNewWbs] = useState<boolean>(false)
  const { data, isLoading, refetch } = useWbs(id, undefined, showError)

  return (
    <>
      <header className='my-3 d-flex justify-content-between'>
        <span className='badge badge-lg badge-primary'>
          <Link to='/enterprise/eps/wbss'>
            <KTSVG path='/media/icons/duotune/general/gen001.svg' className='text-white' />
            <span className='ms-2 text-white'>home</span>
          </Link>

          {data?.hierarchy?.map((item) => (
            <span key={item._id.$oid}>
              <i className='bi bi-chevron-right text-white'></i>
              <Link className='text-white' to={'/enterprise/eps/wbss/' + item._id.$oid}>
                {item.name}
              </Link>
            </span>
          ))}
        </span>
        <button
          onClick={() => setNewWbs((prev) => !prev)}
          type='button'
          className='btn btn-light btn-icon btn-sm'
        >
          <KTSVG
            path={`/media/icons/duotune/files/fil0${newWbs ? '15' : 13}.svg`}
            className='svg-icon-muted svg-icon-2x'
          />
        </button>
      </header>
      <DndProvider backend={HTML5Backend}>
        <div className='row'>
          {!!id && !!data && !!data?.subwbs.length && (
            <PreviousItem refetch={refetch} wbs={data} />
          )}
          {!data && isLoading && (
            <div className='d-flex justify-content-center'>
              <div
                className='spinner-border text-primary mt-5'
                role='status'
                style={{ width: '3rem', height: '3rem' }}
              >
                <span className='sr-only'>Loading...</span>
              </div>
            </div>
          )}
          {!isLoading && !data?.subwbs.length && !newWbs && (
            <EmptyValuesCard text='' title='Empty Wbs' />
          )}
          {data?.subwbs.map((item) => (
            <WbsItem key={item._id.$oid} wbs={item} refetch={refetch} />
          ))}
          {newWbs && (
            <CreateWbsItem setNewWbs={setNewWbs} parentId={id} refetch={refetch} />
          )}
        </div>
      </DndProvider>
    </>
  )
}
