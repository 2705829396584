import {format} from 'date-fns'
import {StatisticsWidget1} from '../../../_metronic/partials/widgets'
import {ProjectObject} from '../../modules/projects/core/_models'
import {useAuth} from '../../modules/auth'
import {getFNSDateFormat} from '../../helpers/DateFormatter'

interface Props {
  projects: ProjectObject[]
}

export default function NearestActions({projects}: Props) {
  const {currentUser} = useAuth()
  let actions: any[] = []
  let newActions: any[] = []
  projects.forEach((project) => {
    newActions =
      project?.default_version?.action_plan?.map((item: any) => {
        return {
          ...item,
          project_name: project.name,
          project_id: project._id.$oid,
        }
      }) || []
    actions = [...actions, ...newActions]
  })
  actions = actions
    .filter((value) => {
      if (!value.deadline) return false
      return new Date(value.deadline).getTime() - new Date().getTime() > 0
    })
    .sort((a, b) => {
      if (!a.deadline || !b.deadline) return 0
      const dateA = new Date(a.deadline)
      const dateB = new Date(b.deadline)
      if (dateA < dateB) return 1
      if (dateA === dateB) return 1
      return -1
    })
    .filter((value, index) => index < 3)
  return (
    <div className='row g-5 g-xl-8'>
      {actions.map((item, index) => (
        <div key={index} className='col-xl-4'>
          <StatisticsWidget1
            projectId={item.project_id}
            projectName={item.project_name}
            className='card-xl-stretch mb-xl-8'
            image='abstract-4.svg'
            title={item.action}
            time={format(
              new Date(item.deadline),
              getFNSDateFormat(currentUser?.date_format || 'dd/mm/yyyy')
            )}
            description={item?.subject}
          />
        </div>
      ))}
    </div>
  )
}
