import FileSaver from 'file-saver'
import {ProjectObject} from '../../core/_models'
import * as xlsx from 'xlsx'
import {formatNumber} from '../../../../utils/formatter'
import {getPeriodicFromCumulative} from '../data/prepareDataTable'

const expandPvDisplay = (
  plannedValues: number[],
  earnedValues: number[],
  isPeriodic: boolean = false
) => {
  if (plannedValues.length > earnedValues.length) return plannedValues
  return earnedValues.map(
    (item, index) => plannedValues[index] ?? (isPeriodic ? 0 : plannedValues.at(-1))
  )
}
const formatDataExport = (project: ProjectObject, backupLabels: any[] = []) => {
  const workbook = xlsx.utils.book_new()
  const data = [
    ['', ...(project?.output?.labels || backupLabels)],
    [
      'Cumulative PV',
      ...(project.custom_curve?.at(0)?.values || []).map((item) =>
        formatNumber(+item, project.float_formatter, '.')
      ),
    ],
    [
      'Periodic PV',
      ...getPeriodicFromCumulative(project.custom_curve?.at(0)?.values || []).map((item) =>
        formatNumber(+item, project.float_formatter, '.')
      ),
    ],
    [
      'Cumulative EV',
      ...(
        project.output?.earnedValues?.mixed?.cumulative ||
        project.custom_curve?.at(1)?.values ||
        []
      ).map((item: number) => formatNumber(+item, project.float_formatter, '.')),
    ],
    [
      'Periodic EV',
      ...(
        project.output?.earnedValues?.mixed.periodic ||
        getPeriodicFromCumulative(project.custom_curve?.at(1)?.values) ||
        []
      ).map((item: number) => formatNumber(+item, project.float_formatter, '.')),
    ],
    [
      'Cumulative AC',
      ...(
        project.output?.actualCosts?.mixed?.cumulative ||
        project.custom_curve?.at(2)?.values ||
        []
      ).map((item: number) => formatNumber(+item, project.float_formatter, '.')),
    ],
    [
      'Periodic AC',
      ...(
        project.output?.actualCosts?.mixed.periodic ||
        getPeriodicFromCumulative(project.custom_curve?.at(2)?.values) ||
        []
      ).map((item: number) => formatNumber(+item, project.float_formatter, '.')),
    ],
    [
      'Cumulative SPI',
      ...(project?.output?.SPICum || []).map((item: any) =>
        formatNumber(+item, project.float_formatter, '.')
      ),
    ],
    [
      'Periodic SPI',
      ...(project?.output?.SPIPeriodic || []).map((item: any) =>
        formatNumber(+item, project.float_formatter, '.')
      ),
    ],
    [
      'Cumulative CPI',
      ...(project?.output?.cpi?.cumulative || []).map((item: any) =>
        formatNumber(+item, project.float_formatter, '.')
      ),
    ],
    [
      'Periodic CPI',
      ...(project?.output?.cpi?.periodic || []).map((item: any) =>
        formatNumber(+item, project.float_formatter, '.')
      ),
    ],
    [
      'EAC',
      ...(project.output?.EAC || []).map((item: any) =>
        formatNumber(+item, project.float_formatter, '.')
      ),
    ],
    [
      'ES',
      ...(project.output?.ES || []).map((item: any) =>
        formatNumber(+item, project.float_formatter, '.')
      ),
    ],
    [
      'SPIt',
      ...(project.output?.SPIt || []).map((item: any) =>
        formatNumber(+item, project.float_formatter, '.')
      ),
    ],
    [
      'Cumulative CV',
      ...(project.output?.CVCum || []).map((item: any) =>
        formatNumber(+item, project.float_formatter, '.')
      ),
    ],
    [
      'Periodic CV',
      ...(project.output?.CVCum || []).map((item: any) =>
        formatNumber(+item, project.float_formatter, '.')
      ),
    ],
    [
      'Cumulative SVt',
      ...(project.output?.SVtCum || []).map((item: any) =>
        formatNumber(+item, project.float_formatter, '.')
      ),
    ],
    [
      'Variance EAC',
      ...(project.output?.VarianceEAC || []).map((item: any) =>
        formatNumber(+item, project.float_formatter, '.')
      ),
    ],
    [
      'Management Reserve Remaining',
      ...(project.output?.ManagementReserveRemaining || []).map((item: any) =>
        formatNumber(+item, project.float_formatter, '.')
      ),
    ],
    [
      'Estimate To Complete',
      ...(project.output?.EstimateToComplete || []).map((item: any) =>
        formatNumber(+item, project.float_formatter, '.')
      ),
    ],
  ]

  const worksheet = xlsx.utils.aoa_to_sheet(data)
  xlsx.utils.book_append_sheet(workbook, worksheet, 'Sheet 1')
  const excelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'})
  const dataBlob = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })
  FileSaver(dataBlob, 'export_data.xlsx')
}

export {expandPvDisplay, formatDataExport}
