export interface BudgetAtCompletion {
  total_amount: number
  currency: string
  unit: string
  amount: number
}
export interface CurveSettings {
  name: string
  tangent: number
  offset: number
}
export interface CustomCurve {
  name: string
  color: string
  values: any[]
}
export interface PeriodCount {
  type: string
  count: string
}

export type SimulationObject = {
  _id?: {$oid: string}
  budget_at_completion: BudgetAtCompletion
  curve_settings: CurveSettings
  custom_curve: CustomCurve[]
  data_date: {$date: any}
  end_date: {$date: any}
  name: string
  period_count: PeriodCount
  is_start: boolean
  has_remaining: boolean
  total_hour: number
  description: string
  updated_at: any
  achieved_percentage: number
  resources_per_month: number
}

export type SimulationObjectWithDates = {
  _id: {$oid: string}
  budget_at_completion: BudgetAtCompletion
  curve_settings: CurveSettings
  custom_curve: CustomCurve[]
  data_date: Date
  end_date: Date
  name: string
  period_count: PeriodCount
  is_start: boolean
  has_remaining: boolean
  total_hour: number
  description: string
  updated_at: any
  achieved_percentage: number
  resources_per_month: number
}

export type Simulation = {
  items: SimulationObject[]
  meta: {
    page: number
    per_page: number
    total_results: number
    num_pages: number
    has_next: number
    has_prev: number
    prev_num: number
    next_num: number
  }
}

export type CreateSimulation = {
  name: string
  description: string
  is_start: boolean
}

export enum SimulationFilter {
  ALL = '',
  STARTED = 'true',
  NOT_STARTED = 'false',
}
