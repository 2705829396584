/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Link} from 'react-router-dom'
import EmptyValuesCard from '../../../../app/components/emptyValuesCard'
import {capitalizeFirstLetter} from '../../../../app/modules/profile/components/billing/billingHelpers'
import {LatestFilter} from '../../../../app/pages/dashboard/LatestFilter'
import {KTSVG} from '../../../helpers'

type Props = {
  className: string
  items: any[]
  filter: number
  setFilter: any
}
export const DIVISIONS = [
  {amount: 60, name: 'seconds'},
  {amount: 60, name: 'minutes'},
  {amount: 24, name: 'hours'},
  {amount: 7, name: 'days'},
  {amount: 4.34524, name: 'weeks'},
  {amount: 12, name: 'months'},
  {amount: Number.POSITIVE_INFINITY, name: 'years'},
]

const ListsWidget1: React.FC<Props> = ({className, items, filter, setFilter}: Props) => {
  function formatTimeAgo(date: any) {
    let transformedDate = new Date(date)
    let duration = (transformedDate.getTime() - new Date().getTime()) / 1000

    for (let i = 0; i < DIVISIONS.length; i++) {
      const division = DIVISIONS[i]
      if (Math.abs(duration) < division.amount) {
        return formatter.format(Math.round(duration), division.name as Intl.RelativeTimeFormatUnit)
      }
      duration /= division.amount
    }
  }
  const formatter = new Intl.RelativeTimeFormat('en', {numeric: 'auto'})
  const getColor = (status: string): string => {
    let color
    switch (status) {
      case 'On Going':
        color = 'badge-primary'
        break
      case 'Not Started':
        color = 'badge-secondary'
        break
      case 'In Progress':
        color = 'badge-info'
        break
      default:
        color = 'badge-primary'
        break
    }
    return color
  }
  const normalizeStatus = (status: string): string => {
    if (status === 'in_progress') return 'In Progress'
    return status
  }
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold text-dark'>Latest Updates</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Simulations and Projects</span>
        </h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </button>
          <LatestFilter filter={filter} setFilter={setFilter} />
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      {!items.length && (
        <EmptyValuesCard
          text='There are no items added yet. Kickstart your work by adding your first Simulation or Project'
          title='Welcome to Project Curve App'
        />
      )}
      <div className='card-body pt-5'>
        {/* begin::Item */}
        {items.map((item) => (
          <div key={item.id} className='row mb-7'>
            {/* <div className='d-flex justify-content-between'> */}
            <div className='col-sm-7 d-flex align-items-center '>
              {/* begin::Symbol */}
              <div className='symbol symbol-50px me-5'>
                <span
                  className={`symbol-label bg-light-${
                    item.type === 'project' ? 'success' : 'danger'
                  }`}
                >
                  <KTSVG
                    path={
                      item.type === 'project'
                        ? '/media/icons/duotune/general/gen022.svg'
                        : '/media/icons/duotune/graphs/gra011.svg'
                    }
                    className={`svg-icon-2x svg-icon-${
                      item.type === 'project' ? 'success' : 'danger'
                    }`}
                  />
                </span>
              </div>
              {/* end::Symbol */}
              {/* begin::Text */}
              <div className='d-flex flex-column'>
                {item.type === 'project' ? (
                  <Link
                    to={'/project/' + item.id}
                    className='text-dark text-hover-primary fs-6 fw-bold'
                  >
                    {item.name}
                  </Link>
                ) : (
                  <Link
                    to={'/simulations/' + item.id}
                    className='text-dark text-hover-primary fs-6 fw-bold'
                  >
                    {item.name}
                  </Link>
                )}
                <span className='text-muted fw-semibold'>{capitalizeFirstLetter(item.type)}</span>
              </div>
              {/* end::Text */}
            </div>
            <div className='col-sm-3 text-muted fw-semibold d-flex flex-column align-items-center '>
              Updated
              <div className='text-dark mt-1 fw-bold'>{formatTimeAgo(item.updated_at.$date)}</div>
            </div>
            <div className='col-sm-2 m-auto'>
              <span className={`badge ${getColor(normalizeStatus(item.status))}`}>
                {normalizeStatus(item.status)}
              </span>
            </div>
          </div>
        ))}
        {/* end::Item */}
        {/* begin::Item */}
        {/* end::Item */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ListsWidget1}
