/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'


const mappedLabel = (label: string): string => {
  if (label === '&laquo; Previous') {
    return 'Previous'
  }

  if (label === 'Next &raquo;') {
    return 'Next'
  }

  return label
}
interface PaginationProps {
  pagination: any,
  isLoading: boolean,
  updateState: any
}
const Pagination = ({ pagination, isLoading, updateState }: PaginationProps) => {

  const updatePage = (page: number | null) => {
    updateState({ page, per_page: pagination.per_page || 10 })
  }
 
  return (
    <div className='row'>
      <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div id='kt_table_users_paginate'>
          <ul className='pagination'>
            {
              <li className={`page-item previous ${pagination?.has_prev === false && 'disabled'}`} onClick={() => { pagination?.has_prev && updatePage(pagination.prev_num) }} style={{ cursor: 'pointer' }}>
                <a href="#" className="page-link">
                  <i className="previous"></i>
                </a>
              </li>
            }

            {
              pagination &&
              Array.from(Array(pagination?.num_pages).keys())
                .map((page: any,index:number) => {
                  return <li key={index} className={
                    clsx('page-item', {
                      active: page === pagination.page - 1,
                      disabled: isLoading
                    })}
                    style={{ cursor: 'pointer' }}
                    onClick={() => { updatePage(page + 1) }}>
                    <a className="page-link">
                      {page + 1}
                    </a>
                  </li>

                })}
            {
              <li className={`page-item next ${pagination?.has_next === false && 'disabled'}`} onClick={() => { pagination?.has_next && updatePage(pagination.next_num) }} style={{ cursor: 'pointer' }}>
                <a href="#" className="page-link">
                  <i className="next"></i>
                </a>
              </li>
            }

          </ul>
        </div>
      </div>
    </div>
  )
}

export { Pagination }
