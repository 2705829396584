/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import clsx from 'clsx'
import {currencyData, KTSVG} from '../../../../../_metronic/helpers'
import {patchVersion} from '../../core/_requests'
import CustomSelect from '../../../../components/CustomSelect'
import {getFormattedBudget, sweetAlert} from '../../../../utils/funcs'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {getCustomCurve} from '../overview/Project'
import {useProject} from '../../core/ProjectContext'
import {getProjectData} from '../../../../utils/project-data'
import {useNavigate} from 'react-router-dom'
import {ProjectObject} from '../../core/_models'
import {formatNumber} from '../../../../utils/formatter'
import { IBudget } from '../../../../utils/data-transformarion/budget-utils'

const unitOptions = [
  {value: 'billion', label: 'Billion'},
  {value: 'million', label: 'Million'},
  {value: 'thousand', label: 'Thousand'},
  {value: 'unit', label: 'Unit'},
]

export function Budget() {
  const {displayVersion: data, setOriginalVersion, setVersion, project} = useProject()
  const [loading, setLoading] = useState(false)
  const [currency, setCurrency] = useState<any>([])
  const navigate = useNavigate()
  const initialValues = {
    budget: data?.budget_at_completion?.amount || 0,
    reserve: data?.reserve?.amount || 0,
    unit: data?.budget_at_completion.unit || 'thousand',
    // currency: data?.budget_at_completion.currency.toUpperCase() || '',
    currency:
      currency.find(
        (item: any) => item.value === data?.budget_at_completion.currency.toUpperCase()
      ) || '',
    delay_tolerance: data?.delay_tolerance?.count || 0,
  }
  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object().shape({
      budget: Yup.number().required('Budget is required').min(0, "Budget can't be negative"),
      reserve: Yup.number().required('Budget is required').min(0, "Budget can't be negative"),
    }),
    enableReinitialize: true,
    onSubmit: async () => {
      setLoading(true)
      const updatedProject = {...data}
      updatedProject.budget_at_completion = {
        currency: formik.values.currency.value.toLowerCase(),
        unit: formik.values.unit,
        amount: +formik.values.budget,
      }
      updatedProject.reserve = {
        amount: +formik.values.reserve,
        unit: formik.values.unit,
      }
      setTimeout(async () => {
        try {
          let res: any = {}
          if ((data?.data_date.$date || 0) > (data?.start_date.$date || 0))
            res = await getProjectData(
              updatedProject,
              project?.associated_calendar,
              project?.sector
            )
          patchVersion(
            {
              budget_at_completion: updatedProject.budget_at_completion,
              reserve: updatedProject.reserve,
              output: res,
            },
            data?._id?.$oid
          )
            .then((res) => {
              showAlert()
              setOriginalVersion(res)
              setVersion((version) =>
                version
                  ? ({
                      ...version,
                      budget_at_completion: updatedProject.budget_at_completion,
                      reserve: updatedProject.reserve,
                      output: res,
                    } as ProjectObject)
                  : undefined
              )
            })
            .catch((err) => {
              showError(err.message)
            })
        } catch (error: any) {
          sweetAlert({
            title: 'Error',
            text: error.response.data.message || 'Project has not been updated',
            icon: 'error',
          })
        } finally {
          setLoading(false)
        }
      }, 0)
    },
  })

  useEffect(() => {
    const localCurrency: any = []
    Object.values(currencyData[0]).forEach((item) => {
      localCurrency.push({label: `${item.name_plural} (${item.code})`, value: item.code})
    })
    setCurrency(localCurrency)
  }, [])
  const emptyOutput = JSON.stringify(data?.output) === JSON.stringify({})
  const getUsedPercentage = () => {
    const actualCosts = getCustomCurve('cumulativeActualCost', data)
    if (
      data?.budget_at_completion?.amount &&
      parseFloat(actualCosts[actualCosts.length - 1]) > data?.budget_at_completion?.amount
    )
      return '100%'
    return (
      (
        (parseFloat(actualCosts[actualCosts.length - 1] || '0') * 100) /
        (data?.budget_at_completion?.amount || 1)
      )
        .toFixed(0)
        .toString() + '%'
    )
  }
  const changeReserve = (changeType: string) => {
    if (changeType === '+') {
      formik.setFieldValue('reserve', formik.values.reserve + 1000)
    } else {
      formik.setFieldValue(
        'reserve',
        formik.values.reserve - 1000 < 0 ? 0 : formik.values.reserve - 1000
      )
    }
  }
  const showAlert = () => {
    sweetAlert({
      title: 'Success',
      text: 'Changes made successfully!',
      icon: 'success',
      confirmButtonText: 'OK',
    })
  }
  const showError = (err: string) => {
    sweetAlert({
      title: 'Error',
      text: err || 'Something went wrong!',
      icon: 'error',
      confirmButtonText: 'OK',
    })
  }
  return (
    <>
      <div className='card'>
        <div className='card-header'>
          <div className='card-title fs-3 fw-bold'>Project Budget</div>
        </div>
        <div className='card-body'>
          <div className='row mb-8'>
            <div className='col-xl-3'>
              <div className='fs-6 fw-semibold mt-2 mb-3'>Current Status</div>
            </div>
            <div className='col-xl-9'>
              <div className='d-flex flex-column'>
                <div className='d-flex justify-content-between w-100 fs-4 fw-bold mb-3'>
                  <span>Budget</span>
                  <span>
                    {getCustomCurve('cumulativeActualCost', data).length
                      ? formatNumber(
                          getCustomCurve('cumulativeActualCost', data)[
                            getCustomCurve('cumulativeActualCost', data).length - 1
                          ],
                          data?.float_formatter || 0,
                          '.'
                        )
                      : '0'}{' '}
                    of{' '}
                    {formatNumber(
                      data?.budget_at_completion?.amount || 0,
                      data?.float_formatter || 0,
                      '.'
                    )}{' '}
                    Used
                  </span>
                </div>

                <div className='h-8px bg-light rounded mb-3'>
                  <div
                    className={clsx(
                      'progress-bar rounded h-8px',
                      {
                        'bg-success': !emptyOutput
                          ? +data?.output?.CardsData?.CostPerformance?.CPI?.Cumulative >= 1
                          : false,
                      },
                      {
                        'bg-danger': !emptyOutput
                          ? +data?.output?.CardsData?.CostPerformance?.CPI?.Cumulative < 1
                          : true,
                      }
                    )}
                    role='progressbar'
                    style={{width: getUsedPercentage()}}
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div className='row mb-5'>
            <div className='col-xl-3'>
              <div className='fs-6 fw-semibold mt-2 mb-3'>
                Manage Budget
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Change this by updating your planned values'
                ></i>
              </div>
            </div>
            <div className='col-xl-9'>
              <div
                className='position-relative w-md-300px'
                data-kt-dialer='true'
                data-kt-dialer-min='1000'
                data-kt-dialer-max='50000'
                data-kt-dialer-step='1000'
                data-kt-dialer-prefix='$'
                data-kt-dialer-decimals='2'
              >
                <input
                  disabled
                  type='text'
                  className='form-control form-control-solid border-0'
                  data-kt-dialer-control='input'
                  placeholder='Amount'
                  name='budget'
                  id='budget'
                  value={
                    data?.budget_at_completion &&
                    getFormattedBudget(data?.budget_at_completion as IBudget)
                  }
                  readOnly
                />
                <button
                  onClick={() => {
                    sweetAlert({
                      title: 'Change Budget',
                      text: 'Modify Planned Values to change the budget',
                      icon: 'info',
                    }).then(() => navigate(`/project/${data?.project?.$oid}/data`))
                  }}
                  className='btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 end-0'
                >
                  <KTSVG
                    className='svg-icon svg-icon-2'
                    path='/media/icons/duotune/art/art005.svg'
                  />
                </button>
              </div>
            </div>
          </div>
          <div className='row mb-5'>
            {formik.errors.budget && (
              <h4 className='text-danger'>{formik.errors.budget.toString()}</h4>
            )}
          </div>
          <div className='row mb-8'>
            <div className='col-xl-3'>
              <div className='fs-6 fw-semibold mt-2 mb-3'>Manage Reserve</div>
            </div>
            <div className='col-xl-9'>
              <div
                className='position-relative w-md-300px'
                data-kt-dialer='true'
                data-kt-dialer-min='1000'
                data-kt-dialer-max='50000'
                data-kt-dialer-step='1000'
                data-kt-dialer-prefix='$'
                data-kt-dialer-decimals='2'
              >
                <button
                  type='button'
                  className='btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 start-0'
                  data-kt-dialer-control='decrease'
                  onClick={() => changeReserve('-')}
                >
                  <span className='svg-icon svg-icon-1'>
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <rect
                        opacity='0.3'
                        x='2'
                        y='2'
                        width='20'
                        height='20'
                        rx='5'
                        fill='currentColor'
                      />
                      <rect
                        x='6.0104'
                        y='10.9247'
                        width='12'
                        height='2'
                        rx='1'
                        fill='currentColor'
                      />
                    </svg>
                  </span>
                </button>
                <input
                  type='text'
                  className='form-control form-control-solid border-0 ps-12'
                  data-kt-dialer-control='input'
                  placeholder='Amount'
                  name='reserve'
                  onBlur={formik.handleBlur}
                  onChange={(e: any) => {
                    if (isNaN(e.target.value)) return
                    formik.handleChange(e)
                  }}
                  value={formik.values.reserve}
                />
                <button
                  type='button'
                  className='btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 end-0'
                  data-kt-dialer-control='increase'
                  onClick={() => changeReserve('+')}
                >
                  <span className='svg-icon svg-icon-1'>
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <rect
                        opacity='0.3'
                        x='2'
                        y='2'
                        width='20'
                        height='20'
                        rx='5'
                        fill='currentColor'
                      />
                      <rect
                        x='10.8891'
                        y='17.8033'
                        width='12'
                        height='2'
                        rx='1'
                        transform='rotate(-90 10.8891 17.8033)'
                        fill='currentColor'
                      />
                      <rect
                        x='6.01041'
                        y='10.9247'
                        width='12'
                        height='2'
                        rx='1'
                        fill='currentColor'
                      />
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className='row mb-5'>
            {formik.errors.reserve && (
              <h4 className='text-danger'>{formik.errors.reserve.toString()}</h4>
            )}
          </div>
          <div className='row mb-8'>
            <div className='col-xl-3'>
              <div className='fs-6 fw-semibold mt-2 mb-3'>Manage Unit</div>
            </div>
            <div className='col-xl-9'>
              <div className='fv-row mb-10'>
                <CustomSelect
                  name='unit'
                  onBlur={formik.handleBlur}
                  options={unitOptions}
                  value={unitOptions.find((d) => d.value === formik.values.unit)}
                  onChange={(d: any) => {
                    formik.setFieldValue('unit', d.value)
                  }}
                />
              </div>
            </div>
          </div>
          <div className='row mb-8'>
            <div className='col-xl-3'>
              <div className='fs-6 fw-semibold mt-2 mb-3'>Manage Currency</div>
            </div>
            <div className='col-xl-9'>
              <div className='fv-row mb-10'>
                {currency.length && (
                  <CustomSelect
                    maxHeight='200px'
                    name='currency'
                    value={formik.values.currency}
                    options={currency}
                    onChange={(d: any) => {
                      formik.setFieldValue('currency', d)
                    }}
                    onBlur={formik.handleBlur}
                  />
                )}
              </div>
            </div>
          </div>
          <div className='d-flex flex-stack pt-10'>
            <div>
              <button
                disabled={!formik.dirty || !formik.isValid || loading}
                type='button'
                className='btn btn-lg btn-primary'
                data-kt-stepper-action='submit'
                onClick={formik.submitForm}
              >
                {!loading && (
                  <span className='indicator-label'>
                    Save
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr064.svg'
                      className='svg-icon-3 ms-2 me-0'
                    />
                  </span>
                )}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
