import { Project, ProjectFilter, ProjectModel } from '../_models'
import EmptyValuesCard from '../../../../components/emptyValuesCard'
import { useAuth } from '../../../auth'
import ProjectSkeleton from '../ProjectSkeleton'
import { useState } from 'react'
import { ProjectCard } from './ProjectCard'
import SendProjectModal from '../../components/pagination/header/SendProjectModal'
import MoveProjectModal from '../../components/pagination/header/MoveProjectModal'

type Props = {
  selectedFolder: string
  isLoading: boolean
  data: Project | any
  filter: ProjectFilter
  refetch: any
}

export default function Projects({ selectedFolder, data, filter, isLoading, refetch }: Props) {
  const { areActionsDisabled, currentPlanLevel } = useAuth()
  const [sendProject, setSendProject] = useState<ProjectModel | undefined>()
  const [moveProject, setMoveProject] = useState<ProjectModel | undefined>()

  const projects = currentPlanLevel === 1 ? data : data?.items;


  return (
    <div className='row h-100'>
      <SendProjectModal
        show={!!sendProject}
        handleClose={() => setSendProject(undefined)}
        projectId={sendProject?._id.$oid || ''}
      />
      <MoveProjectModal
        show={!!moveProject}
        project={moveProject}
        onHide={() => setMoveProject(undefined)}
        refetch={refetch}
      />
      {isLoading && (
        <>
          <ProjectSkeleton />
          <ProjectSkeleton />
          <ProjectSkeleton />
          <ProjectSkeleton />
        </>
      )}
      {(projects === undefined && isLoading === false) /*projects.length === 0*/ ? (
        <div className='mb-5'>
          <EmptyValuesCard
            buttonText={filter ? undefined : 'Create Project'}
            setShowCreateAppModal={filter || areActionsDisabled ? undefined : () => { }}
            text={
              filter
                ? 'Retry after editing the currently set filter'
                : 'Create a project or check other EPS.'
            }
            title={filter ? 'No Projects that match filter' : 'No projects found in this EPS'}
          />
        </div>
      ) : (
        projects?.map((item: any) => (
          <ProjectCard
            key={item._id.$oid}
            setMoveProject={setMoveProject}
            setSendProject={setSendProject}
            project={item as ProjectModel}
            refetch={refetch}
          />
        ))
      )}
    </div>
  )
}
