import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useProject } from '../../core/ProjectContext'
import { StepperComponent } from '../../../../../_metronic/assets/ts/components'
import { KTSVG, currencyData } from '../../../../../_metronic/helpers'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Modal } from 'react-bootstrap'
import { sweetAlert } from '../../../../utils/funcs'
import { getProjectData } from '../../../../utils/project-data'
import { patchVersion } from '../../core/_requests'
import { getCustomCurve } from '../overview/Project'
import { FirstStep } from '../data/Re-Baseline/FirstStep'
import { SecondStep } from '../data/Re-Baseline/SecondStep'
import { greaterThanOrEqual } from '../../core/ProjectHelpers'
import { RebaseLine } from './_models'
import { ProjectObject } from '../../core/_models'
import { getDiffrentBettwenDate } from '../../../../utils/data-transformarion/date-utils'
import { useDispatch } from 'react-redux'
import { updateShow } from '../../../wbs/treeLoader'
import { setVersionData } from '../../../wbs/treeVersionData'
import { updateIsWpChanged, updateWpChangedId } from '../../../wbs/treedata'

interface Props {
  show: boolean
  handleClose: () => void
  plannedValuesObject: any;
  wpId: string;
  rebase_line_id?: undefined | any;
}

export const CreateRebaseLine = ({ show, handleClose, plannedValuesObject, wpId, rebase_line_id }: Props) => {
  const { displayVersion: data, setOriginalVersion, setVersion, project, setPatchedWpData } = useProject()

  const displayVersion: any = {
    ...data,
    rebase_line: data?.rebase_line?.map((item) => ({ ...item })),
  };

  const [loading, setLoading] = useState<boolean>(false)
  const [currency, setCurrency] = useState<any>([])
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [isSubmitButton, setSubmitButton] = useState(false)
  const [width, setWidth] = useState<number>(window.innerWidth)
  const dispatch = useDispatch();
  const isMobile = useMemo(() => width <= 768, [width])
  const selectedDateSource = new Date(
    (rebase_line_id?.data_date) ??
    (displayVersion?.rebase_line?.[displayVersion.rebase_line.length - 1]?.data_date) ??
    (displayVersion?.data_date?.$date) ??
    0
  );


  const formik = useFormik({
    initialValues: {
      rebase_line: displayVersion?.rebase_line?.sort((a: any, b: any) => {
        return a.id - b.id
      }),
      new_cumulativePlannedValue: rebase_line_id?.start_date ? getCustomCurve('cumulativePlannedValue', rebase_line_id) : null,
      new_latest_cumulativePlannedValue: getCustomCurve('cumulativePlannedValue', rebase_line_id! || displayVersion),
      cumulativeEarnedValue: getCustomCurve('cumulativeEarnedValue', rebase_line_id! || displayVersion),
      cumulativeActualCost: getCustomCurve('cumulativeActualCost', rebase_line_id! || displayVersion),
      worstCaseBaseline: getCustomCurve('worstCaseBaseline', rebase_line_id! || displayVersion) || undefined,
      savedCurve: displayVersion?.curve_settings,
      start_date: new Date(displayVersion?.start_date.$date || 0),
      end_date: new Date(rebase_line_id?.start_date || displayVersion?.end_date.$date || 0),
      rebaseLine_dataDate: rebase_line_id?.data_date ? selectedDateSource : new Date(selectedDateSource.setMonth(selectedDateSource.getMonth() + 1)),
      unit: displayVersion?.budget_at_completion.unit || 'thousand',
      currency:
        currency.find(
          (item: any) => item.value === displayVersion?.budget_at_completion.currency.toUpperCase()
        ) || '',
      delayTolerance: rebase_line_id?.delay_tolerance?.count || displayVersion?.delay_tolerance?.count || 0,
      date_unit: rebase_line_id?.delay_tolerance?.type || displayVersion?.delay_tolerance?.type || 'monthly',
      budgetReserve: rebase_line_id?.reserve?.amount || displayVersion?.reserve?.amount || 0,
    },
    validationSchema: Yup.object().shape({

      budgetReserve: Yup.number().min(0, 'New Budget Resrve should be positive'),
      end_date: Yup.date().min(Yup.ref('start_date'), 'New End date should be after start date'),
      rebaseLine_dataDate: Yup.date().min(Yup.ref('start_date'), 'New Rebaseline data date should be after start date'),
      delayTolerance: Yup.number().min(0, 'New Delay Tolerance should be positive'),
      new_cumulativePlannedValue: Yup.array().nullable(true)
        .test('required', 'Planned values is required', (value: any, context) => {
          return (value && value?.length || 0)
        })
        .when(
          "end_date",
          (end_date, schema) => !rebase_line_id && end_date && schema.test(
            'length',
            'Planned values must be equal to the number of periods ',
            (value: string | any[], context: any) => {
              return (value?.length || 0) === getDiffrentBettwenDate(
                new Date(end_date).getTime(),
                displayVersion?.start_date.$date || 0,
                displayVersion?.period_count.type || 'monthly'
              ) + 1 || false
            }
          )
        )
        .test('number', 'Planned values must be numbers', (value, context) => {
          return value?.every((el) => !isNaN(+el)) || false
        })
        .test('cum', 'Planned values must be cumulative', (value, context) => {
          return value?.every(greaterThanOrEqual) || false
        })
    }),
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: async (v: any) => {
      let newVersion: any;
      if (rebase_line_id) {
        newVersion = {
          ...displayVersion,
          rebase_line: displayVersion?.rebase_line?.map((item: { id: any; budget_at_completion: any; reserve: any; delay_tolerance: any }) => {
            if (item.id == rebase_line_id.id) {
              return {
                ...item,
                end_date: formik.values?.end_date,
                data_date: formik.values?.rebaseLine_dataDate,
                budget_at_completion: {
                  ...item?.budget_at_completion,
                  amount: formik.values?.new_cumulativePlannedValue ? formik.values?.new_cumulativePlannedValue[formik.values?.new_cumulativePlannedValue.length - 1] : 0
                },
                reserve: {
                  ...item.reserve,
                  amount: formik.values?.budgetReserve
                },
                delay_tolerance: {
                  ...item.delay_tolerance,
                  count: formik.values?.delayTolerance
                },
                custom_curve: displayVersion?.custom_curve,
                name: displayVersion?.name
              }
            } else return item
          })
        }
      } else {
        newVersion = {
          ...displayVersion,
          custom_curve: displayVersion?.custom_curve,
          end_date: { $date: formik.values.end_date.getTime() },
          budget_at_completion: {
            ...displayVersion?.budget_at_completion!,
            amount: formik.values?.new_cumulativePlannedValue ? formik.values?.new_cumulativePlannedValue[formik.values?.new_cumulativePlannedValue.length - 1] : 0
          },
          reserve: {
            amount: formik.values.budgetReserve,
            unit: displayVersion?.reserve?.unit
          },
          delay_tolerance: {
            type: formik.values.date_unit,
            count: formik.values.delayTolerance,
          },
          rebase_line: [...(displayVersion?.rebase_line || []), {
            id: new Date().valueOf(),
            end_date: displayVersion?.end_date.$date,
            data_date: formik.values.rebaseLine_dataDate,
            budget_at_completion: displayVersion?.budget_at_completion,
            reserve: displayVersion?.reserve,
            delay_tolerance: displayVersion?.delay_tolerance,
            custom_curve: displayVersion?.custom_curve,
            name: displayVersion?.name
          }],
        }
        newVersion.custom_curve = [
          {
            color: 'yellow',
            name: 'oldCumulativePlannedValue',
            values: getCustomCurve('cumulativePlannedValue', displayVersion),
          },
          {
            color: 'yellow',
            name: 'new_latest_cumulativePlannedValue',
            values: formik.values.new_latest_cumulativePlannedValue,
          },
          {
            color: 'black',
            name: 'cumulativePlannedValue',
            values: formik.values?.new_cumulativePlannedValue,
          },
          {
            color: 'white',
            name: 'cumulativeEarnedValue',
            values: formik.values.cumulativeEarnedValue,
          },
          {
            color: 'red',
            name: 'cumulativeActualCost',
            values: formik.values.cumulativeActualCost,
          },
          {
            color: 'green',
            name: 'worstCaseBaseline',
            values: formik.values.worstCaseBaseline,
          },
          {
            color: 'blue',
            name: 'rebaseLineDataDate',
            values: [formik.values.rebaseLine_dataDate],
          },
        ]
      }
      try {
        setLoading(true)
        const calculatedVersionData = await setPatchedWpData(
          data?.name ? data?.name : 'WBS',
          newVersion,
          new Date(displayVersion?.data_date?.$date || 0),
        );
        console.log("calculatedVersionData ==>")
        console.log(calculatedVersionData)
        setLoading(false)
        dispatch(updateShow(false));

        newVersion = calculatedVersionData ? { ...calculatedVersionData } : { ...newVersion, output: {} }
        const { _id, project, user, created_at, updated_at, start_date, data_date, forecast_settings, ...payload } = newVersion
        const patched_version = await patchVersion(
          {
            ...payload,
            end_date: formik.values.end_date,
          },
          displayVersion?._id.$oid
        )
        dispatch(setVersionData({ id: patched_version._id.$oid, data: patched_version }));
        setVersion(patched_version);
        setOriginalVersion(patched_version);
        sweetAlert({
          text: 'Rebase Line created successfully',
          icon: 'success',
          title: 'Success',
        }).then(() => {
          if (wpId && wpId !== '') {
            dispatch(updateWpChangedId(wpId))
            dispatch(updateIsWpChanged(true));
          }
        }
        );
        handleClose();
        formik.resetForm();
      } catch (error: any) {
        sweetAlert({
          text: error?.response?.data?.message || error?.message || 'Something went wrong',
          icon: 'error',
          title: 'Error',
        })
      } finally {
        setLoading(false)
        handleClose()
      }
    },
  })
  useEffect(() => {
    const localCurrency: any = []
    Object.values(currencyData[0]).forEach((item) => {
      localCurrency.push({ label: `${item.name_plural} (${item.code})`, value: item.code })
    })
    setCurrency(localCurrency)
  }, [])
  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }
  const clearAndClose = () => {
    sweetAlert({
      title: 'Are you sure?',
      text: 'Are you sure you want to leave?',
      icon: 'warning',
      confirmButtonText: 'Close',
      showCancelButton: true,
    }).then((res: any) => {
      if (!res.isConfirmed) return
      formik.resetForm()
      handleClose()
    })
  }
  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    setSubmitButton(false)
    stepper.current.goPrev()
  }

  const submitStep = () => {
    if (!stepper.current) {
      return
    }

    if (stepper.current.currentStepIndex !== 2) {
      nextStep()
    } else {
      if (!formik.isValid) return
      formik.handleSubmit()
    }
  }

  const nextStep = async () => {
    if (!stepper.current) {
      return
    }

    switch (stepper.current.currentStepIndex) {
      case 1:
        if (!formik.errors.end_date && !formik.errors.budgetReserve) {
          stepper.current.goNext()
          setSubmitButton(true)
        }
        break
      case 2:
        stepper.current.goNext()
        break
      default:
        break
    }
  }
  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-content rounded modal-content-centered mw-900px'
      show={show}
      backdrop={true}
      onHide={clearAndClose}
      onEntered={loadStepper}
    >
      <div className='modal-header pb-0 border-0 justify-content-end'>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>
      <div className='modal-body '>
        <div className='mb-13 text-center'>
          <h1 className='mb-3'>Re-baseline</h1>
        </div>
        <div
          ref={stepperRef}
          className='stepper stepper-pills  d-flex flex-column'
          id='kt_modal_create_app_stepper'
        >
          <div className='d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px'>
            {/* begin::Nav*/}
            <div
              className={
                'stepper-nav justify-content-around w-100 ps-lg-10 gap-6 ' +
                (isMobile && 'w-100 d-none')
              }
            >
              {/* begin::Step 1*/}
              <div className='stepper-item current' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>1</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title pt-2'>General</h3>

                    <div className='stepper-desc'>General Details</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 1*/}

              {/* begin::Step 2*/}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>2</span>
                  </div>
                  {/* begin::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title pt-2'>Upload</h3>

                    <div className='stepper-desc'>Provide Information</div>
                  </div>
                  {/* begin::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
            </div>
          </div>
          <div className='flex-row-fluid py-lg-5 px-lg-15'>
            {/*begin::Form */}
            <form id='kt_modal_create_app_form' className='form' onSubmit={formik.handleSubmit}>
              <FirstStep formik={formik} project={project} rebase_line_id={rebase_line_id} />
              <SecondStep formik={formik} plannedValuesObject={plannedValuesObject} />

              {/*begin::Actions */}
              <div className='d-flex flex-stack pt-10'>
                <div className='me-2'>
                  <button
                    type='button'
                    className='btn btn-lg btn-light-primary me-3'
                    data-kt-stepper-action='previous'
                    onClick={prevStep}
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr063.svg'
                      className='svg-icon-3 me-1'
                    />{' '}
                    Previous
                  </button>
                </div>
                <div>
                  <button
                    onClick={submitStep}
                    className='btn btn-lg btn-primary me-3'
                    disabled={loading}
                    type='button'
                  >
                    <span className='indicator-label'>
                      {!isSubmitButton &&
                        (loading ? (
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        ) : (
                          <span>
                            Next Step
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr064.svg'
                              className='svg-icon-3 ms-1 me-0'
                            />
                          </span>
                        ))}
                      {isSubmitButton && (
                        loading ? (
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        ) : (
                          <span className='indicator-label'>
                            Submit{' '}
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr064.svg'
                              className='svg-icon-3 ms-2 me-0'
                            />
                          </span>
                        )
                      )}
                    </span>
                  </button>
                </div>
              </div>
              {/*end::Actions */}
            </form>
          </div>
        </div>
      </div>
    </Modal>
  )
}
