import { Modal, ProgressBar } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';

export default function ShowLoadingModal() {
  const label = useSelector((state: RootState) => state.treeLoader.label);
  const show = useSelector((state: RootState) => state.treeLoader.show);
  const loaded = useSelector((state: RootState) => state.treeLoader.loaded);
  const total = useSelector((state: RootState) => state.treeLoader.total);

  const progress = total === 0 ? 0 : (loaded / total) * 100;

  return (
    <>
      <Modal show={show} backdrop="static" keyboard={false} centered>
        <Modal.Body className="d-flex flex-column justify-content-center align-items-center">
          <div className="mb-3 fs-5 fw-bold d-flex justify-content-between align-items-center w-100">
            <div>{label}</div>
            <div>{total === 0 ? '...' : loaded + "/" + total}</div>
          </div>
          <ProgressBar now={progress} style={{ width: '100%' }} />
        </Modal.Body>
      </Modal>
    </>
  );
}
