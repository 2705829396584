import {FC} from 'react'
import {Modal} from 'react-bootstrap'
import ForecastSettingsForm from './ForecastSettingsForm'

type Props = {
  show: boolean
  onHide: () => void
}

const ForecastSettingsModal: FC<Props> = ({show, onHide}) => {
  return (
    <Modal size='xl' show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>S-Curve Settings</Modal.Title>
      </Modal.Header>
      <ForecastSettingsForm onHide={onHide} />
    </Modal>
  )
}

export default ForecastSettingsModal
