/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import { getCSVReport, getHeader, getPeriodicFromCumulative } from '../data/prepareDataTable'
import { KTSVG } from '../../../../../_metronic/helpers'
import { getCustomCurve } from '../overview/Project'
import { useProject } from '../../core/ProjectContext'
import clsx from 'clsx'
import { getLabels } from '../../../../helpers/DateFormatter'
import { DateFormatType } from '../../../../utils/funcs'
import { formatNumber } from '../../../../utils/formatter'
import { expandPvDisplay, formatDataExport } from './_utils'
import { getDiffrentBettwenDate } from '../../../../utils/data-transformarion/date-utils'

interface Props {
  title: string
  data: any
}

export function ShowData({ title, data }: Props) {
  const dataTable = data?.output


  if (!data) return null
  const backupLabels = getLabels(
    data.start_date.$date || 0,
    getDiffrentBettwenDate(
      data.end_date.$date || 0,
      data.start_date.$date || 0,
      data.period_count.type
    ) + 1,
    data.period_count.type === 'monthly' ? 'month' : 'day',
    data.date_format as DateFormatType
  )
  return (
    <>
      <div className='card'>
        <div className='card-header'>
          <div className='card-title fs-3 fw-bold'>{title}</div>
        </div>
        <div className='card-body'>
          <div className='d-flex flex-wrap flex-stack pb-7'>
            <div className='d-flex my-1 flex-row-fluid gap-5'>
              <button
                type='button'
                className='btn btn-sm btn-bg-light btn-active-color-primary'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                onClick={() => formatDataExport(data, backupLabels)}
              >
                <span className='svg-icon svg-icon-2 svg-icon-primary'>
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr044.svg'
                    className='svg-icon-3 ms-2 me-0'
                  />
                </span>
                Export Data
              </button>
            </div>
            <div className='d-flex flex-wrap my-1'></div>
          </div>
          {!data?.output?.actualCosts?.mixed.cumulative && (
            <div className='d-flex mt-2 align-items-center'>
              <i className='bi bi-info-circle fs-5'></i>
              <p className='fw-7 ms-2 text-muted mb-0'>Forecast Data Unavailable</p>
            </div>
          )}
          <div className='table-responsive'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bolder text-muted'>
                  <th></th>
                  {(data?.output?.labels || backupLabels)?.map((item: any, index: number) => (
                    <th style={{ maxWidth: '12ch', minWidth: '12ch' }} key={index}>
                      {item}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className='text-primary fw-bold'>Cumulative PV</td>
                  {dataTable?.plannedValues?.original?.cumulative
                    ? expandPvDisplay(
                      dataTable?.plannedValues?.original?.cumulative ||
                      data?.custom_curve?.at(0)?.values ||
                      [],
                      data?.output?.earnedValues?.mixed?.cumulative || []
                    ).map((item: any, index: number) => (
                      <td className='fw-bold' key={index}>
                        {formatNumber(parseFloat(item), data.float_formatter, '.')}
                      </td>
                    ))
                    : getCustomCurve('cumulativePlannedValue', data)?.map(
                      (item: string, index: number) => (
                        <td
                          className={clsx(
                            index < (data.custom_curve?.at(0)?.values?.length || Infinity)
                              ? ''
                              : 'text-info',
                            'fw-bold'
                          )}
                          key={index}
                        >
                          {formatNumber(parseFloat(item), data.float_formatter, '.')}
                        </td>
                      )
                    )}
                </tr>
                <tr>
                  <td className='text-primary fw-bold'>Periodic PV</td>
                  {dataTable?.plannedValues?.original?.periodic
                    ? expandPvDisplay(
                      dataTable?.plannedValues?.original?.periodic ||
                      getPeriodicFromCumulative(data?.custom_curve?.at(0)?.values || []),
                      data?.output?.earnedValues?.mixed?.cumulative || [],
                      true
                    ).map((item: any, index: number) => (
                      <td className='fw-bold' key={index}>
                        {formatNumber(parseFloat(item), data.float_formatter, '.')}
                      </td>
                    ))
                    : getPeriodicFromCumulative(
                      getCustomCurve('cumulativePlannedValue', data)
                    )?.map((item: string, index: number) => (
                      <td className='fw-bold' key={index}>
                        {formatNumber(parseFloat(item), data.float_formatter, '.')}
                      </td>
                    ))}
                </tr>
                <tr>
                  <td className='text-primary fw-bold'>Cumulative EV</td>
                  {dataTable?.earnedValues?.mixed?.cumulative
                    ? dataTable?.earnedValues?.mixed?.cumulative?.map(
                      (item: string, index: number) => (
                        <td
                          className={clsx(
                            index < data?.output?.earnedValues?.original?.cumulative?.length
                              ? ''
                              : 'text-info',
                            'fw-bold'
                          )}
                          key={index}
                        >
                          {formatNumber(parseFloat(item), data.float_formatter, '.')}
                        </td>
                      )
                    )
                    : getCustomCurve('cumulativeEarnedValue', data)?.map(
                      (item: string, index: number) => (
                        <td className='fw-bold' key={index}>
                          {formatNumber(parseFloat(item), data.float_formatter, '.')}
                        </td>
                      )
                    )}
                </tr>
                <tr>
                  <td className='fw-bold text-primary'>Periodic EV</td>
                  {dataTable?.earnedValues?.mixed?.periodic
                    ? dataTable?.earnedValues?.mixed?.periodic?.map(
                      (item: string, index: number) => (
                        <td
                          className={clsx(
                            index < data?.output?.earnedValues?.original?.cumulative?.length
                              ? ''
                              : 'text-info',
                            'fw-bold'
                          )}
                          key={index}
                        >
                          {formatNumber(parseFloat(item), data.float_formatter, '.')}
                        </td>
                      )
                    )
                    : getPeriodicFromCumulative(getCustomCurve('cumulativeEarnedValue', data))?.map(
                      (item: string, index: number) => (
                        <td className='fw-bold' key={index}>
                          {formatNumber(parseFloat(item), data.float_formatter, '.')}
                        </td>
                      )
                    )}
                </tr>
                <tr>
                  <td className='text-primary fw-bold'>Cumulative AC</td>
                  {dataTable?.actualCosts?.mixed?.cumulative
                    ? dataTable?.actualCosts?.mixed?.cumulative?.map(
                      (item: string, index: number) => (
                        <td
                          className={clsx(
                            index < data?.output?.earnedValues?.original?.cumulative?.length
                              ? ''
                              : 'text-info',
                            'fw-bold'
                          )}
                          key={index}
                        >
                          {formatNumber(parseFloat(item), data.float_formatter, '.')}
                        </td>
                      )
                    )
                    : getCustomCurve('cumulativeActualCost', data)?.map(
                      (item: string, index: number) => (
                        <td className='fw-bold' key={index}>
                          {formatNumber(parseFloat(item), data.float_formatter, '.')}
                        </td>
                      )
                    )}
                </tr>
                <tr>
                  <td className='text-primary fw-bold'>Periodic AC</td>
                  {dataTable?.actualCosts?.mixed?.periodic
                    ? dataTable?.actualCosts?.mixed?.periodic?.map(
                      (item: string, index: number) => (
                        <td
                          className={clsx(
                            index < data?.output?.earnedValues?.original?.cumulative?.length
                              ? ''
                              : 'text-info',
                            'fw-bold'
                          )}
                          key={index}
                        >
                          {formatNumber(parseFloat(item), data.float_formatter, '.')}
                        </td>
                      )
                    )
                    : getPeriodicFromCumulative(getCustomCurve('cumulativeActualCost', data))?.map(
                      (item: string, index: number) => (
                        <td className='fw-bold' key={index}>
                          {formatNumber(parseFloat(item), data.float_formatter, '.')}
                        </td>
                      )
                    )}
                </tr>
                {data?.output?.SPICum && (
                  <tr>
                    <td className='text-primary fw-bold'>Cumulative SPI</td>
                    {data?.output?.SPICum?.map((item: any, index: number) => (
                      <td
                        className={clsx(
                          index < data?.output?.earnedValues?.original?.cumulative?.length
                            ? ''
                            : 'text-info',
                          'fw-bold'
                        )}
                      >
                        {formatNumber(item, data.float_formatter, '.')}
                      </td>
                    ))}
                  </tr>
                )}
                {data?.output?.SPIPeriodic && (
                  <tr>
                    <td className='text-primary fw-bold'>Periodic SPI</td>
                    {data?.output?.SPIPeriodic?.map((item: any) => (
                      <td className='fw-bold'>{formatNumber(item, data.float_formatter, '.')}</td>
                    ))}
                  </tr>
                )}
                {!!data?.output?.CVCum && (
                  <tr>
                    <td className='text-primary fw-bold'>CPI (cum)</td>
                    {dataTable?.cpi?.cumulative?.map((item: string, index: number) => (
                      <td
                        className={clsx(
                          index < data?.output?.earnedValues?.original?.cumulative?.length
                            ? ''
                            : 'text-info',
                          'fw-bold'
                        )}
                      >
                        {formatNumber(
                          parseFloat(dataTable?.cpi.cumulative[index]),
                          data.float_formatter,
                          '.'
                        )}
                      </td>
                    ))}
                  </tr>
                )}
                {!!data?.output?.CPIPeriod && (
                  <tr>
                    <td className='text-primary fw-bold'>CPI (period)</td>
                    {dataTable?.cpi?.periodic?.map((item: string, index: number) => (
                      <td
                        className={clsx(
                          index < data?.output?.earnedValues?.original?.cumulative?.length
                            ? ''
                            : 'text-info',
                          'fw-bold'
                        )}
                      >
                        {formatNumber(
                          parseFloat(dataTable?.cpi.periodic[index]),
                          data.float_formatter,
                          '.'
                        )}
                      </td>
                    ))}
                  </tr>
                )}
                {!!data?.output?.EAC && (
                  <tr>
                    <td className='text-primary fw-bold'>EAC</td>
                    {dataTable?.EAC?.map((item: string, index: number) => (
                      <td
                        className={clsx(
                          index < data?.output?.earnedValues?.original?.cumulative?.length
                            ? ''
                            : 'text-info',
                          'fw-bold'
                        )}
                      >
                        {formatNumber(parseFloat(dataTable?.EAC[index]), data.float_formatter, '.')}
                      </td>
                    ))}
                  </tr>
                )}
                {!!data?.output?.ES && (
                  <tr>
                    <td className='text-primary fw-bold'>Earned Schedule</td>
                    {dataTable?.ES?.map((item: string, index: number) => (
                      <td className='fw-bold' key={index}>
                        {formatNumber(parseFloat(dataTable?.ES[index]), data.float_formatter, '.')}
                      </td>
                    ))}
                  </tr>
                )}
                {!!data?.output?.SPIt && (
                  <tr>
                    <td className='text-primary fw-bold'>Cumulative SPIt</td>
                    {dataTable?.SPIt?.map((item: string, index: number) => (
                      <td className='fw-bold' key={index}>
                        {formatNumber(
                          parseFloat(dataTable?.SPIt[index]),
                          data.float_formatter,
                          '.'
                        )}
                      </td>
                    ))}
                  </tr>
                )}
                {!!data?.output?.CVCum && (
                  <tr>
                    <td className='text-primary fw-bold'>Cumulative CV</td>
                    {dataTable?.CVCum?.map((item: string, index: number) => (
                      <td className='fw-bold' key={index}>
                        {formatNumber(
                          parseFloat(dataTable?.CVCum[index]),
                          data.float_formatter,
                          '.'
                        )}
                      </td>
                    ))}
                  </tr>
                )}
                {!!data?.output?.CVPeriod && (
                  <tr>
                    <td className='text-primary fw-bold'>Periodic CV</td>
                    {dataTable?.CVPeriod?.map((item: string, index: number) => (
                      <td className='fw-bold' key={index}>
                        {formatNumber(
                          parseFloat(dataTable?.CVPeriod[index]),
                          data.float_formatter,
                          '.'
                        )}
                      </td>
                    ))}
                  </tr>
                )}
                {!!data?.output?.SVtCum && (
                  <tr>
                    <td className='text-primary fw-bold'>Cumulative SVt</td>
                    {dataTable?.SVtCum?.map((item: string, index: number) => (
                      <td className='fw-bold' key={index}>
                        {formatNumber(
                          parseFloat(dataTable?.SVtCum[index]),
                          data.float_formatter,
                          '.'
                        )}
                      </td>
                    ))}
                  </tr>
                )}
                {!!data?.output?.SVtPeriod && (
                  <tr>
                    <td className='text-primary fw-bold'>Periodic SVt</td>
                    {dataTable?.SVtPeriod?.map((item: string, index: number) => (
                      <td className='fw-bold' key={index}>
                        {formatNumber(
                          parseFloat(dataTable?.SVtPeriod[index]),
                          data.float_formatter,
                          '.'
                        )}
                      </td>
                    ))}
                  </tr>
                )}
                {!!data?.output?.VarianceEAC && (
                  <tr>
                    <td className='text-primary fw-bold'>Variance EAC</td>
                    {dataTable?.VarianceEAC?.map((item: string, index: number) => (
                      <td className='fw-bold' key={index}>
                        {formatNumber(
                          parseFloat(dataTable?.VarianceEAC[index]),
                          data.float_formatter,
                          '.'
                        )}
                      </td>
                    ))}
                  </tr>
                )}
                {!!data?.output?.ManagementReserveRemaining && (
                  <tr>
                    <td className='text-primary fw-bold'>Management Reserve Remaining</td>
                    {dataTable?.ManagementReserveRemaining?.map((item: string, index: number) => (
                      <td className='fw-bold' key={index}>
                        {formatNumber(
                          parseFloat(dataTable?.ManagementReserveRemaining[index]),
                          data.float_formatter,
                          '.'
                        )}
                      </td>
                    ))}
                  </tr>
                )}
                {!!data?.output?.EstimateToComplete && (
                  <tr>
                    <td className='text-primary fw-bold'>Estimate To Complete</td>
                    {dataTable?.EstimateToComplete?.map((item: string, index: number) => (
                      <td className='fw-bold' key={index}>
                        {formatNumber(
                          parseFloat(dataTable?.EstimateToComplete[index]),
                          data.float_formatter,
                          '.'
                        )}
                      </td>
                    ))}
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}
