import dayjs from 'dayjs'
import { Milestone } from '../../milestones/_models'
import {prepareMilestoneDates} from '../../milestones/_utils'

const isMilestoneValid = (milestone: any, dataDate: Date) => {
  const processedMilestone = prepareMilestoneDates([milestone], dataDate)[0]
  let anteriorItem = undefined
  if (!processedMilestone.real_date) {
    anteriorItem = milestone?.real_dates
      .filter((item:any) => dayjs(dataDate).isAfter(item.data_date))
      .sort((a: { data_date: string | number | Date }, b: { data_date: string | number | Date }) => new Date(a.data_date).getTime() - new Date(b.data_date).getTime())[0]

    if (anteriorItem)
      return anteriorItem.achieved || dayjs(anteriorItem.real_date).isAfter(dataDate)
  }
  return true
}

export {isMilestoneValid}
