import { useEffect, useRef } from 'react';
import { KTSVG } from '../../../../../_metronic/helpers';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import { appendTreeData, deleteTreeNode } from './_helpers';
import { createWbs, patchWbs } from '../../_requests';
import TreeFolderItem from './TreeFolderItem';
import { showError } from '../../../../utils/funcs';
import { useTree } from './TreeContext';
import { useProject } from '../../../projects/core/ProjectContext';
import { useDispatch, useSelector } from 'react-redux';
import { isntrename, setcurrentwbs } from '../../treeStates';
import { addWbsToStore } from '../../treedata';
import { RootState } from '../../../../store';

type Props = {
  parent: any | undefined;
  createTwo: boolean;
};

export default function TreeCreateFolderItem({ parent, createTwo }: Props) {
  const { setTreeData } = useTree();
  const inputRef = useRef(null);
  const { project } = useProject();
  const knownWbs = useSelector((state: RootState) => state.treedata.knownWbs);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: { name: '' },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required('Name is required')
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols'),
    }),
    onSubmit: async (values, { resetForm }) => {
      dispatch(isntrename());
      const createWbsRequests = [];

      if (createTwo) {
        createWbsRequests.push(createWbs({
          name: "wp " + parent.name,
          parent_wbs: parent._id.$oid,
          parent_project: project?._id.$oid,
        }));
      }

      createWbsRequests.push(createWbs({
        name: values.name,
        parent_wbs: parent._id.$oid,
        parent_project: project?._id.$oid,
      }));

      Promise.all(createWbsRequests)
        .then((res) => {
          res.forEach((wbs) => {
            dispatch(addWbsToStore(wbs));
            if (wbs._id.$oid !== parent._id.$oid) {
              dispatch(setcurrentwbs(wbs._id.$oid))
            }
          });
          setTreeData((origin) => {
            const filteredNode = deleteTreeNode(origin, 'new_child' + (parent._id.$oid || 'root'));
            const newTreeData = res.reduce((acc, res) => {
              return appendTreeData(acc, parent._id.$oid || 'root', [
                {
                  title: <TreeFolderItem folder={res} />,
                  key: res._id.$oid,
                },
              ]);
            }, filteredNode);
            return newTreeData;
          });

          const parentId = parent._id.$oid;
          // Patch the subwbs of the parent to include the new WBs
          if (parentId && parentId !== 'root') {
            const newWbss = res.filter((item) => item._id.$oid !== parent._id.$oid);
            const updatedSubwbs = [
              ...(parent.subwbs.map((item: any) => item.$oid) || []),
              ...newWbss.map((item) => item._id.$oid)
            ];

            patchWbs(parentId, {
              subwbs: updatedSubwbs
            }).then((patchedParent) =>
              dispatch(addWbsToStore(patchedParent))
            );
          }

          resetForm();
        })
        .catch(showError);
    },
  });

  useEffect(() => {
    const node = inputRef.current;
    if (node) (node as any).focus();
  }, [parent._id.$oid]);

  return (
    <div className='d-inline-flex justify-content-start align-items-center'>
      <KTSVG
        className={clsx('svg-icon-2 me-2', {
          'svg-icon-secondary': !(formik.errors.name && formik.touched.name),
          'svg-icon-danger': formik.errors.name && formik.touched.name,
        })}
        path='/media/icons/duotune/files/fil012.svg'
      />
      <input
        ref={inputRef}
        type='text'
        placeholder='New Wbs'
        className='form-control form-control-flush ps-0'
        {...formik.getFieldProps('name')}
      />
      <button
        onClick={() => {
          dispatch(isntrename());
          setTreeData((origin) => deleteTreeNode(origin, 'new_child' + (parent._id.$oid || 'root')));
        }}
        type='button'
        style={{ transition: 'all 0.2s ease-in-out' }}
        className={clsx('btn btn-icon btn-sm me-2 thisbtn')}
      >
        <KTSVG
          path='/media/icons/duotune/arrows/arr011.svg'
          className='svg-icon-dark text-hover-danger svg-icon-3'
        />
      </button>
      <button
        onClick={() => formik.handleSubmit()}
        type='button'
        style={{ transition: 'all 0.2s ease-in-out' }}
        className={clsx('btn btn-icon btn-sm me-2')}
      >
        <KTSVG
          path='/media/icons/duotune/arrows/arr012.svg'
          className='svg-icon-dark text-hover-success svg-icon-3'
        />
      </button>
    </div>
  );
}
