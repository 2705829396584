interface Dropdown1Props {
  setFilter?: any
  filter: number
}
export function LatestFilter({setFilter, filter}: Dropdown1Props) {
  return (
    <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
      </div>

      <div className='separator border-gray-200'></div>

      <div className='px-7 py-5'>
        <div className='mb-10'>
          <label className='form-label fw-bold'>Status:</label>

          <div>
            <select
              className='form-select form-select-solid'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              value={filter}
              onChange={(e) => {
                setFilter(e.target.value)
              }}
            >
              <option></option>
              <option value={5}>5</option>
              <option value={8}>8</option>
              {/* <option value={15}>15</option> */}
            </select>
          </div>
        </div>
      </div>
    </div>
  )
}
