import {AntTreeNodeProps} from 'antd/es/tree'
import clsx from 'clsx'

export default function SwitcherIcon(props: AntTreeNodeProps) {
  return (
    <div
      className='d-flex align-items-center justify-content-center rotate active'
      style={{height: '100%'}}
    >
      <i
        className={clsx('bi bi-caret-down-fill', {
          'rotate-n90': !props.expanded,
          'rotate-0': props.expanded,
        })}
      ></i>
    </div>
  )
}
