import { sameMonthAndYear } from '../../../../../utils/data-transformarion/date-utils'
import { useProject } from '../../../core/ProjectContext'
import CostChart from './CostChart'
import CPIGauge from './CPIGauge'
type Props = {}

export default function GaugeSection({}: Props) {
  const {originalVersion: data , isRebaseLined} = useProject()
  return (
    <>
      <div className='row g-5 gy-2 g-xl-8 gy-xl-2 pt-5'>
        <div className='col-xl-4'>
          <CostChart />
        </div>
        <div className='col-xl-4'>
          <CPIGauge type='CPI' />
        </div>
        <div className='col-xl-4'>
          <CPIGauge type='SPI' 
                          isRebaseLined={ isRebaseLined? sameMonthAndYear(data?.data_date?.$date!, data?.rebase_line[data?.rebase_line?.length - 1]?.data_date!):false}
                          />
        </div>
      </div>
    </>
  )
}
