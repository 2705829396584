import {getSubscriptionStatus} from '../app/helpers/func'
import {UserModel} from '../app/modules/auth'

const usePlansAsLevels = (currentUser: UserModel | undefined) => {
  const checkPlan = (): any => {
    if (!currentUser) return true
    const subscriptionStatus = getSubscriptionStatus(currentUser)
    
    switch (subscriptionStatus) {

      case 'Free Plan':
          return 1
      case 'Professional':
          return 2
      case 'Enterprise':
          return 3
    }
  }

  const currentPlanLevel = checkPlan()

  return {
    currentPlanLevel,
    handleActionsDisabled: () => {
    },
  }
}

export default usePlansAsLevels
