import { TreeSelect, TreeSelectProps } from 'antd'
import { useEffect, useState } from 'react'
import { getWbs, patchWbs } from '../_requests'
import { DataNode } from 'antd/es/tree'
import { appendTreeData, deleteTreeNode, updateTreeData } from './TreeView/_helpers'
import SelectTreeItem from './TreeView/select/SelectTreeItem'
import { Wbs } from '../_models'
import { Modal } from 'react-bootstrap'
import { useTree } from './TreeView/TreeContext'
import { showError } from '../../../utils/funcs'
import TreeFolderItem from './TreeView/TreeFolderItem'
import { getProjectById } from '../../projects/core/_requests'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { addWbsToStore } from '../treedata'

type Props = {
  folder: Wbs
  show: boolean
  onHide: () => void
}

export default function MoveItem({ folder, onHide, show }: Props) {
  const { setTreeData: setOriginalTreeData } = useTree()
  const [treeData, setTreeData] = useState<DataNode[]>([])
  const [selectedWbs, setSelectedWbs] = useState<string>('root')
  const [loading, setLoading] = useState<boolean>(false)
  const dispatch = useDispatch();
  const knownWbs = useSelector((state: RootState) => state.treedata.knownWbs);



  const loadData: TreeSelectProps['loadData'] = async (treeNode) => {
    if (!treeNode) return;

    let childWbs;
    childWbs = knownWbs[treeNode.key?.toString() || 'root'];
    if (!childWbs) {
      childWbs = await getWbs(treeNode.key?.toString());
      dispatch(addWbsToStore(childWbs));
    }

    const childData: DataNode[] = await Promise.all(
      childWbs.subwbs.map(async (item: { $oid: string }) => {
        let subWbsItem = knownWbs[item.$oid];
        if (!subWbsItem) {
          subWbsItem = await getWbs(item.$oid);
          dispatch(addWbsToStore(subWbsItem));
        }

        return {
          key: subWbsItem._id.$oid,
          title: <SelectTreeItem folder={subWbsItem} />,
          switcherIcon: subWbsItem.subwbs.length ? undefined : <></>,
          value: subWbsItem._id.$oid,
          disabled: (treeNode.key?.toString()) === subWbsItem._id.$oid,
        };
      })
    );

    setTreeData((origin) => {
      return updateTreeData(origin, treeNode.key?.toString() || 'root', childData);
    });
  };

  const submitMove = () => {
    if (!selectedWbs) return
    if (selectedWbs === folder._id?.$oid) return
    setLoading(true)
    patchWbs(folder._id.$oid, {
      parent_wbs: selectedWbs,
    })
      .then((res) => {
        dispatch(addWbsToStore(res));
        setOriginalTreeData((origin) =>
          appendTreeData(deleteTreeNode(origin, folder._id.$oid), selectedWbs, [
            { key: res._id.$oid, title: <TreeFolderItem folder={res} /> },
          ])
        )
        onHide()
      })
      .catch(showError)
      .finally(() => setLoading(false))
  }
  return (
    <Modal centered onHide={onHide} show={show}>
      <Modal.Header closeButton>
        <Modal.Title>Move Wbs</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='fv-row mb-5'>
          <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
            <span>Choose Destination Wbs</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title=''
            ></i>
          </label>
          <TreeSelect
            value={selectedWbs}
            onChange={(value) => {
              setSelectedWbs(value)
            }}
            className='form-control'
            dropdownStyle={{ zIndex: 10000 }}
            popupClassName='eps-dropdown'
            loadData={loadData}
            treeData={treeData}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button disabled={loading} className='btn btn-light-primary'>
          Cancel
        </button>
        <button
          disabled={
            loading ||
            (selectedWbs === 'root' ? undefined : selectedWbs) ===
            (!folder.parent_wbs ? undefined : folder.parent_wbs)
          }
          onClick={submitMove}
          className='btn btn-primary'
        >
          {loading ? (
            <span className='indicator-progress' style={{ display: 'block' }}>
              <span className='spinner-border spinner-border-sm align-middle'></span>
            </span>
          ) : (
            'Submit'
          )}
        </button>
      </Modal.Footer>
    </Modal>
  )
}
