import React, { useState, useEffect } from 'react';
import { Wbs } from '../../_models';

type Props = {
  folder: Wbs;
};

export default function ChartTreeFolderItem({ folder }: Props) {
  const [isSelected, setIsSelected] = useState(false);

  // Check if the item is selected on mount
  useEffect(() => {
    const selectedFolderId = sessionStorage.getItem('selectedFolder');
    setIsSelected(selectedFolderId === folder._id.$oid);
  }, [folder._id.$oid]);

  // Handle click event to set selected item globally
  const handleClick = () => {
    sessionStorage.setItem('selectedFolder', folder._id.$oid);
    setIsSelected(true);
    // Trigger an update in other instances of this component
    window.dispatchEvent(new Event('storage'));
  };

  // Listen to changes in the selection to update the UI
  useEffect(() => {
    const handleStorageChange = () => {
      const selectedFolderId = sessionStorage.getItem('selectedFolder');
      setIsSelected(selectedFolderId === folder._id.$oid);
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, [folder._id.$oid]);

  return (
    <div
      onClick={handleClick}
      className="d-inline-flex justify-content-start align-items-center"
      style={{
        backgroundColor: isSelected ? '#009ef7' : 'transparent',
        color: isSelected ? 'white' : 'black',
        cursor: 'pointer',
        padding: '5px',
        borderRadius: '4px'
      }}
    >
      <span
        className="fw-bold me-2"
        style={{
          maxWidth: '150px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        }}
        title={folder.name}
      >
        {folder.name.length > 20 ? `${folder.name.substring(0, 17)}...` : folder.name}
      </span>
    </div>
  );
}
