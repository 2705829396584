import React, { Dispatch, useEffect, useState } from 'react';
import { useProject } from '../../core/ProjectContext';
import { getCustomCurve } from '../overview/Project';
import { DateFormatType } from '../../../../utils/funcs';
import { getLabels, getMaxArrayLength, normalizeDateUnit } from '../../../../helpers/DateFormatter';
import EmptyValuesCard from '../../../../components/emptyValuesCard';
import RebaseLineTable from './RebaseLineTable';
import { CreateRebaseLine } from './CreateRebaseLine';
import { CreateRebaselineRemainingToDo } from './CreateRebaselineRemainingToDo';
import { RootState } from '../../../../store';
import { useSelector } from 'react-redux';

interface Props {
  setVersionId: Dispatch<React.SetStateAction<string | undefined>>;
}

const ReabseLine = ({ setVersionId }: Props) => {
  const { originalVersion: data, isRebaseLined, project: projectData } = useProject();
  const [showModalWholeWP, setShowModalWholeWP] = useState(false);
  const [showModalRemainingToDo, setShowModalRemainingToDo] = useState(false);
  const [dataTable, setDataTable] = useState<any>(null);
  const [isWbs, setIsWbs] = useState(false);
  const [id, setId] = useState<string>('');
  const knownWbs = useSelector((state: RootState) => state.treedata.knownWbs);

  const generatedLabelsLength = getMaxArrayLength(
    dataTable?.plannedValues?.original.cumulative,
    dataTable?.earnedValues?.mixed?.cumulative,
    dataTable?.actualCosts?.mixed?.cumulative,
    getCustomCurve('cumulativePlannedValue', data)
  );

  const normalizedDateUnit = normalizeDateUnit(data?.period_count?.type);
  const labels = getLabels(
    data?.start_date?.$date || 0,
    generatedLabelsLength ||
    data?.custom_curve?.find((item: any) => item.name === 'cumulativePlannedValue')?.values.length || 0,
    normalizedDateUnit,
    data?.date_format as DateFormatType
  );

  const plannedValuesObject = createArrayOfObjects(
    labels,
    getCustomCurve('cumulativePlannedValue', data)
  );

  function createArrayOfObjects(headers: any, data: any) {
    let resultArray = [];
    for (let i = 0; i < headers.length; i++) {
      let obj = {
        header: headers[i],
        data: data[i],
      };
      resultArray.push(obj);
    }
    return resultArray;
  }

  useEffect(() => {
    const last_opened_wbs = knownWbs[projectData?.current_wbs]?.last_opened_wbs?.$oid ? knownWbs[projectData?.current_wbs]?.last_opened_wbs?.$oid : projectData?.current_wbs
    if (last_opened_wbs) {
      setId(last_opened_wbs)
      const latestOpenWbs = knownWbs[last_opened_wbs];
      const iswbs = latestOpenWbs && latestOpenWbs?.subwbs && latestOpenWbs?.subwbs?.length > 0;
      setIsWbs(iswbs);
    }
  }, [projectData?.current_wbs, knownWbs]);

  return (
    <>
      <CreateRebaseLine
        show={showModalWholeWP}
        handleClose={() => setShowModalWholeWP(false)}
        plannedValuesObject={plannedValuesObject}
        wpId={id}
      />
      <CreateRebaselineRemainingToDo
        show={showModalRemainingToDo}
        handleClose={() => setShowModalRemainingToDo(false)}
        plannedValuesObject={plannedValuesObject}
        wpId={id}
      />
      <h2>Rebase Line</h2>
      <div className='d-flex flex-wrap flex-stack pb-7'>
        <div className='d-flex flex-wrap align-items-center my-1 gap-3'></div>
        {!isWbs && (
          <div className='d-flex flex-wrap my-1 gap-2'>
            <div className='d-flex my-0'>
              <div className="dropdown">
                <button
                  className="btn btn-sm btn-primary me-3 dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Add Rebase Line
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() => setShowModalRemainingToDo(true)}
                    >
                      Rebaseline the Remaining To Do
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() => setShowModalWholeWP(true)}
                    >
                      Rebaseline the Whole WP
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className='tab-content'>
        {isRebaseLined ? (
          <RebaseLineTable plannedValuesObject={plannedValuesObject} isWbs={isWbs} wpId={id} />
        ) : (
          <EmptyValuesCard title='No Rebase Line' text='Add Rebase Line' />
        )}
      </div>
    </>
  );
};

export default ReabseLine;
