import _ from 'lodash'
import {findSym} from '../../../../helpers/func'
import {getFormattedUnit} from '../../../../utils/funcs'
import {useSimulationContext} from '../SimulationContext'
import SimulationChart from './SimulationChart'

type Props = {}

export default function SimulationChartContainer({}: Props) {
  const {formik, outputs, chartUnit, setChartUnit} = useSimulationContext()

  return (
    <div className='col-xxl-8 mb-5 mb-xl-10'>
      {outputs && (
        <>
          <div className='btn-group mb-5' role='group'>
            <input
              type='radio'
              className='btn-check'
              name='typeOfChart'
              id='valuespc'
              checked={_.isEqual(chartUnit, {suffix: '%', ratio: 1})}
              readOnly
            />
            <label
              className='btn btn-outline-primary border border-right-0 border-2'
              htmlFor='valuespc'
              onClick={() => setChartUnit({suffix: '%', ratio: 1})}
            >
              Values in %
            </label>
            <input
              type='radio'
              className='btn-check'
              name='typeOfChart'
              disabled={!formik?.values?.budget_at_completion?.amount}
              checked={_.isEqual(chartUnit, {
                suffix:
                  getFormattedUnit(formik?.values?.budget_at_completion?.unit || '') +
                  findSym(formik?.values?.budget_at_completion?.currency || ''),
                ratio: (formik?.values?.budget_at_completion?.amount || 100) / 100,
              })}
              readOnly
            />
            <label
              className='btn btn-outline-primary border border-right-0 border-left-0 border-2'
              htmlFor='valuesincurrency'
              onClick={() =>
                setChartUnit({
                  suffix:
                    getFormattedUnit(formik?.values?.budget_at_completion?.unit || '') +
                    findSym(formik?.values?.budget_at_completion?.currency || ''),
                  ratio: (formik?.values?.budget_at_completion?.amount || 100) / 100,
                })
              }
            >
              Values in currency
            </label>
            <input
              type='radio'
              className='btn-check'
              name='typeOfChart'
              id='valuesinhours'
              autoComplete='off'
              disabled={!formik?.values?.total_hour}
              checked={_.isEqual(chartUnit, {
                suffix: 'hours',
                ratio: (formik?.values?.total_hour || 100) / 100,
              })}
              readOnly
            />
            <label
              className='btn btn-outline-primary border border-left-0 border-2'
              htmlFor='valuesinhours'
              onClick={() =>
                setChartUnit({
                  suffix: 'hours',
                  ratio: (formik?.values?.total_hour || 100) / 100,
                })
              }
            >
              Values in hours
            </label>
            <input
              type='radio'
              className='btn-check'
              name='typeOfChart'
              id='values_in_resources'
              autoComplete='off'
              disabled={!formik?.values?.total_hour || !formik.values.resources_per_month}
              checked={chartUnit.suffix === 'FTE'}
              readOnly
            />
            <label
              className='btn btn-outline-primary border border-left-0 border-2'
              htmlFor='values_in_resources'
              onClick={() =>
                formik?.values.total_hour &&
                +formik?.values.resources_per_month &&
                setChartUnit({
                  suffix: 'FTE',
                  ratio: formik?.values.total_hour / 100 / +formik?.values.resources_per_month,
                })
              }
            >
              Values in resources
            </label>
          </div>
          <SimulationChart />
        </>
      )}
    </div>
  )
}
