import {format} from 'date-fns'
import FileSaver from 'file-saver'
import moment from 'moment'

import * as xlsx from 'xlsx'

import {getDeepClone} from './data-updaters'
import {currencyData} from '../../_metronic/helpers'
import {calculateOptimizedOutputs} from './optimizers'

export const getAccessToken = () => localStorage.getItem('access-token') as string

export const getRefreshToken = () => localStorage.getItem('refresh-token') as string

export const getTodayDate = () => moment().format('YYYY-MM-DD')

export const getNextMonth = () => moment().add(1, 'months').format('YYYY-MM')

export function removeNullsFromEnd(arr: any[]) {
  let i = arr.length - 1
  while (i >= 0 && arr[i] === null) {
    arr.pop()
    i--
  }
  return arr
}

export const getDisplayableValues = (
  inputs: any,
  resultTab1: number[],
  resultTab2: number[],
  addPercentSign: boolean
) => {
  const R = inputs.donePercentage / 100
  let i
  for (i = 0; i < resultTab1.length; i++)
    if (resultTab1[i] > R) {
      i--
      break
    }

  const highlightIdx = i
  const totalNbOfMonths = resultTab1.length
  const nbOfMonthsLeft = totalNbOfMonths - highlightIdx

  const timeUnitToAdd = inputs.projectDurationUnit === 'month' ? 'months' : 'days'

  const tableData: any[][] = [
    [
      // 'Date',
      ...Array.from({length: totalNbOfMonths}).map((_el, idx) =>
        moment(inputs.inputDataDate).add(idx - inputs.nbOfMonthsPassed + 1, timeUnitToAdd)
      ),
    ],
    [
      // "Pourcentage d'avancement par mois",
      ...resultTab1.map((el) => (el * 100).toString() + (addPercentSign ? '%' : '')),
    ],
    [
      // "Pourcentage d'avancement cumulé",
      ...resultTab2.map((el) => (el * 100).toString() + (addPercentSign ? '%' : '')),
    ],
  ]

  return {
    tableData,
    highlightIdx: highlightIdx /* + 1*/, // FOR THE FIRST ROW THAT DOESN'T CONTAIN VALUES
    nbOfRemainingMonths: nbOfMonthsLeft - 1,
    estimatedEndDate: moment(inputs.inputDataDate).add(nbOfMonthsLeft - 1, timeUnitToAdd),
    isInitialState: false,
  }
}

export const calculateOutputs = (inputs: any, addPercentSign: boolean = true) => {
  inputs = getDeepClone(inputs)

  if (inputs.isCalculatingRemainingTodo)
    return calculateRemainingTodoOutputs(inputs, addPercentSign)

  inputs.mu = +inputs.mu
  inputs.sig = +inputs.sig
  inputs.nbOfMonthsPassed = +inputs.nbOfMonthsPassed
  inputs.donePercentage = +inputs.donePercentage

  let D = inputs.nbOfMonthsPassed
  let R = inputs.donePercentage / 100
  let m = D
  let sig = m * inputs.mu
  let mu = m * inputs.sig

  const tab0: number[] = []
  const tab1: number[] = []
  const tab2: number[] = []
  const tab3: number[] = []

  for (let j = D; j <= 10000; j++) {
    let i
    m = j

    for (i = 0; i <= m - 1; i++) {
      sig = m * inputs.mu
      mu = m * inputs.sig
      tab0[i] = -(((m - 1) * 5) / 2) + 5 * i
      tab1[i] = (1 / (sig * Math.sqrt(2 * Math.PI))) * Math.exp(-0.5 * ((tab0[i] - mu) / sig) ** 2)
    }

    tab2[0] = tab1[0]

    for (i = 0; i <= m - 2; i++) {
      tab2[i + 1] = tab2[i] + tab1[i + 1]
    }

    for (i = 0; i <= m - 1; i++) {
      tab3[i] = tab2[i] / tab2[m - 1]
      if (R < tab3[i]) break
    }

    if (i === D) break
  }

  for (let i = 0; i <= m - 1; i++) {
    sig = m * inputs.mu
    mu = m * inputs.sig
    tab0[i] = -(((m - 1) * 5) / 2) + 5 * i
    tab1[i] = (1 / (sig * Math.sqrt(2 * Math.PI))) * Math.exp(-0.5 * ((tab0[i] - mu) / sig) ** 2)
  }

  tab2[0] = tab1[0]

  for (let i = 0; i <= m - 2; i++) {
    tab2[i + 1] = tab2[i] + tab1[i + 1]
  }

  for (let i = 0; i <= m - 1; i++) {
    tab3[i] = tab2[i] / tab2[m - 1]
  }

  let resultTab1: number[] = []
  let resultTab2: number[] = []

  for (let i = 0; i <= m - 1; i++) {
    resultTab1[i] = tab3[i]
    resultTab2[i] = tab1[i] / tab2[m - 1]
  }

  return getDisplayableValues(inputs, resultTab1, resultTab2 /*m - D*/, addPercentSign)
}

// ==================================

const getRemainingTodoDisplayableValues = (
  inputs: any,
  resultTab1: number[],
  resultTab2: number[],
  addPercentSign: boolean = true
) => {
  const R = inputs.donePercentage / 100
  let i
  for (i = 0; i < resultTab1.length; i++)
    if (resultTab1[i] > R) {
      break
    }

  const highlightIdx = i
  const totalNbOfMonths = resultTab1.length
  const nbOfMonthsLeft = totalNbOfMonths - highlightIdx

  const timeUnitToAdd = inputs.projectDurationUnit === 'month' ? 'months' : 'days'

  const tableData: any[][] = [
    [],
    [
      // "Pourcentage d'avancement par mois",
      ...resultTab1
        .slice(highlightIdx)
        .map((el) => (el * 100).toFixed(2) + (addPercentSign ? '%' : '')),
    ],
    [
      // "Pourcentage d'avancement cumulé",
      ...resultTab2
        .slice(highlightIdx)
        .map((el) => (el * 100).toFixed(2) + (addPercentSign ? '%' : '')),
    ],
  ]

  tableData[0] = [
    // 'Date',
    ...Array.from({length: tableData[1].length}).map((_el, idx) =>
      moment(inputs.inputDataDate).add(idx, timeUnitToAdd)
    ),
  ]

  return {
    tableData,
    highlightIdx: 0 /* + 1*/, // FOR THE FIRST ROW THAT DOESN'T CONTAIN VALUES
    nbOfRemainingMonths: nbOfMonthsLeft - 1,
    // estimatedEndDate: moment(inputs.inputDataDate).add(
    //   nbOfMonthsLeft - 1,
    //   timeUnitToAdd
    // ),
    isInitialState: false,
  }
}

const calculateRemainingTodoOutputs = (inputs: any, addPercentSign: boolean = true) => {
  inputs.mu = +inputs.mu
  inputs.sig = +inputs.sig
  inputs.nbOfMonthsPassed = +inputs.nbOfMonthsPassed
  inputs.donePercentage = +inputs.donePercentage
  inputs.enteredEndDate +=
    inputs.projectDurationUnit === 'month' ? inputs.inputDataDate.substring(7) : ''

  // TODO: DOUBLE CHECK IF THIS IS NEEDED OR NOT
  const timeUnitToDifferWith = inputs.projectDurationUnit === 'month' ? 'months' : 'days'

  let D = Math.round(
    moment(new Date(inputs.enteredEndDate)).diff(
      new Date(inputs.inputDataDate),
      timeUnitToDifferWith,
      true
    )
  )

  let R = inputs.donePercentage / 100
  let m = D
  let sig = m * inputs.mu
  let mu = m * inputs.sig

  const tab0: number[] = []
  const tab1: number[] = []
  const tab2: number[] = []
  const tab3: number[] = []

  for (let j = D; j <= 10000; j++) {
    let i
    m = j

    for (i = 0; i <= m - 1; i++) {
      sig = m * inputs.mu
      mu = m * inputs.sig
      tab0[i] = -(((m - 1) * 5) / 2) + 5 * i
      tab1[i] = (1 / (sig * Math.sqrt(2 * Math.PI))) * Math.exp(-0.5 * ((tab0[i] - mu) / sig) ** 2)
    }

    tab2[0] = tab1[0]

    for (i = 0; i <= m - 2; i++) {
      tab2[i + 1] = tab2[i] + tab1[i + 1]
    }

    for (i = 0; i <= m - 1; i++) {
      tab3[i] = tab2[i] / tab2[m - 1]
      if (R < tab3[i]) break
    }

    if (i === m - D - 1) break
  }

  for (let i = 0; i <= m - 1; i++) {
    sig = m * inputs.mu
    mu = m * inputs.sig
    tab0[i] = -(((m - 1) * 5) / 2) + 5 * i
    tab1[i] = (1 / (sig * Math.sqrt(2 * Math.PI))) * Math.exp(-0.5 * ((tab0[i] - mu) / sig) ** 2)
  }

  tab2[0] = tab1[0]

  for (let i = 0; i <= m - 2; i++) {
    tab2[i + 1] = tab2[i] + tab1[i + 1]
  }

  for (let i = 0; i <= m - 1; i++) {
    tab3[i] = tab2[i] / tab2[m - 1]
  }

  let resultTab1: number[] = []
  let resultTab2: number[] = []

  for (let i = 0; i <= m - 1; i++) {
    resultTab1[i] = tab3[i]
    resultTab2[i] = tab1[i] / tab2[m - 1]
  }

  return getRemainingTodoDisplayableValues(inputs, resultTab1, resultTab2 /*m - D*/, addPercentSign)
}

// ==================================

export const getSigmoidDisplayableValues = (
  resultTab1: number[],
  resultTab2: number[],
  addPercentSign: boolean
) => {
  const totalNbOfMonths = resultTab1.length

  const tableData: any[][] = [
    [
      // 'Date',
      ...Array.from({length: totalNbOfMonths}).map((_el, idx) => idx + 1),
    ],
    [
      // "Pourcentage d'avancement par mois",
      ...resultTab1.map(
        (el) => (el * 100).toString() /*.toFixed(2)*/ + (addPercentSign ? '%' : '')
      ),
    ],
    [
      // "Pourcentage d'avancement cumulé",
      ...resultTab2.map(
        (el) => (el * 100).toString() /*.toFixed(2)*/ + (addPercentSign ? '%' : '')
      ),
    ],
  ]



  return {
    tableData,
    isInitialState: false,
    highlightIdx: -2, // TO NOT HIGHLIGHT ANY
  }
}

export const calculateSigmoidOutputs = (inputs: any, addPercentSign: boolean = true) => {
  inputs.mu = +inputs.mu
  inputs.sig = +inputs.sig
  inputs.nbOfMonthsPassed = +inputs.nbOfMonthsPassed

  let m = inputs.nbOfMonthsPassed // RATHER, NUMBER OF TOTAL MONTHS
  let sig = m * inputs.mu
  let mu = m * inputs.sig

  const tab0: number[] = []
  const tab1: number[] = []
  const tab2: number[] = []
  const tab3: number[] = []

  for (let i = 0; i <= m - 1; i++) {
    tab0[i] = -(((m - 1) * 5) / 2) + 5 * i
    tab1[i] = (1 / (sig * Math.sqrt(2 * Math.PI))) * Math.exp(-0.5 * ((tab0[i] - mu) / sig) ** 2)
  }

  tab2[0] = tab1[0]

  for (let i = 0; i <= m - 2; i++) {
    tab2[i + 1] = tab2[i] + tab1[i + 1]
  }

  for (let i = 0; i <= m - 1; i++) {
    tab3[i] = tab2[i] / tab2[m - 1]
  }

  let resultTab1: number[] = []
  let resultTab2: number[] = []

  for (let i = 0; i <= m - 1; i++) {
    resultTab1[i] = tab3[i]
    resultTab2[i] = tab1[i] / tab2[m - 1]
  }

  return getSigmoidDisplayableValues(resultTab1, resultTab2, addPercentSign)
}

// ==================================

export const calculateEstimatedOutputs = (
  inputs: any,
  sigmoidCustomChartValues: any,
  isForSigmoid?: boolean
) => {
  const tempInputs = getDeepClone(inputs)
  // const MIN_TG = 0.1; // MU
  // const MAX_TG = 5;
  // const MIN_AXIS = -5; // SIG
  // const MAX_AXIS = 10;
  const MIN_TG = 1.5 // MU
  const MAX_TG = 4
  const MIN_AXIS = -4 // SIG
  const MAX_AXIS = 4



  let minDiffSum = null
  let minDiffSumArr: null | Record<string, number>[] = null
  const arr2 = sigmoidCustomChartValues.cols.map((el: string) =>
    el === '' || el === null ? null : +el
  )
  let diffSumArr: number[] = []
  // let iterArrs: any;
  // let iterCurveSettings: any;
  for (let i = MIN_TG; i <= MAX_TG; i += 0.1) {
    const outerLoopArr: number[] = []
    for (let j = MIN_AXIS /*, iterCount = 0*/; j <= MAX_AXIS; j += 0.1 /*, iterCount++*/) {
      tempInputs.mu = i
      tempInputs.sig = j

      const res = isForSigmoid
        ? calculateSigmoidOutputs(tempInputs, false)
        : calculateOutputs(tempInputs, false)
      const arr1 = res.tableData[1]

      // ['19.210', '39.600', '60.400', '80.790', '100.000']
      // ['5', '30', '55', '80', '100']

      // if (iterCont === 7600) {
      //   iterArrs = [arr1, arr2];
      //   iterCurveSettings = { tg: i, axis: j };
      // }

      let diffSum = 0
      const length = Math.min(arr1.length, arr2.length)

      for (let k = 0; k < length; k++)
        if (arr2[k] !== null) {
          diffSum += (arr1[k] - arr2[k]) ** 2
          diffSumArr = [...diffSumArr, diffSum]
          outerLoopArr.push(diffSum)
        }

      if (!Number.isNaN(diffSum)) {
        if (minDiffSum === null || diffSum < minDiffSum) {
          minDiffSum = diffSum
          minDiffSumArr = [{tg: i, axis: j}]
        } else if (diffSum === minDiffSum)
          minDiffSumArr && (minDiffSumArr = [...minDiffSumArr, {tg: i, axis: j}])
      }
    }


  }

 

  return minDiffSumArr && minDiffSumArr.length ? minDiffSumArr[0] : null
}

// estimated outputs but for split

export const calculateUnsplitEstimatedOutputs = (
  inputs: any,
  sigmoidCustomChartValues: any,
  isForSigmoid?: boolean
) => {
  const tempInputs = getDeepClone(inputs)

  const MIN_TG = 0.92 // MU
  const MAX_TG = 1.92
  const MIN_AXIS = -0.99 // SIG
  const MAX_AXIS = 1.01

  let minDiffSum: any = null
  let minDiffSumArr: null | Record<string, number>[] = null
  let arr2: number[] = sigmoidCustomChartValues.cols.map((el: string) =>
    el === '' || el === null ? null : +el
  )
  arr2 = removeNullsFromEnd(arr2)
  let startIndex = 0
  if (inputs.nbOfMonthsPassed > 5) startIndex = arr2.length - 5

  let diffSumArr: number[] = []
  const outerLoopArr: number[] = []
  const diffSumArray: number[] = []
  for (let i = MIN_TG; i <= MAX_TG; i += 0.1) {
    for (let j = MIN_AXIS; j <= MAX_AXIS; j += 0.02) {
      tempInputs.mu = i
      tempInputs.sig = j
      const res = isForSigmoid
        ? calculateSigmoidOutputs(tempInputs, false)
        : calculateOutputs(tempInputs, false)
      let arr1 = res.tableData[1]

      let diffSum = 0

      for (let k = startIndex; k < arr2.length; k++)
        if (arr2[k] !== null && arr1[k]) {
          diffSum += Math.abs(arr2[k] - arr1[k]) / arr2[k]
          diffSumArr = [...diffSumArr, diffSum]
          outerLoopArr.push(diffSum)
        }

      if (!Number.isNaN(diffSum)) {
        diffSumArray.push(diffSum)
        if (minDiffSum === null || diffSum < minDiffSum) {
          minDiffSum = diffSum
          minDiffSumArr = [{tg: i, axis: j}]
        } else if (diffSum === minDiffSum)
          minDiffSumArr && (minDiffSumArr = [...minDiffSumArr, {tg: i, axis: j}])
      }
    }
  }

  return minDiffSumArr && minDiffSumArr.length ? minDiffSumArr[0] : null
}
/**
 * Optimized version of the calculateEstimatedOutputs used only for projects
 * @param inputs Inputs object containing pourcentage done and number of months passed
 * @param sigmoidCustomChartValues Custom curve the function needs to forecast
 * @returns Returns sig and mu that produce the closest values to custom curve
 */

export const calculateOptimizedEstimatedOutputs = (
  inputs: any,
  sigmoidCustomChartValues: any,
  errorRate: number = 20
) => {
  const tempInputs = getDeepClone(inputs)

  const MIN_TG = 0.92 // MU
  const MAX_TG = 1.92
  const MIN_AXIS = -0.99 // SIG
  const MAX_AXIS = 1.01

  let minDiffSum: any = null
  let minDiffSumArr: null | Record<string, number>[] = null
  let arr2: number[] = sigmoidCustomChartValues.cols.map((el: string) =>
    el === '' || el === null ? null : +el
  )

  let startIndex = 0
  if (inputs.nbOfMonthsPassed > 5) startIndex = sigmoidCustomChartValues.cols.length - 5

  let diffSumArr: number[] = []
  const outerLoopArr: number[] = []
  const diffSumArray: number[] = []
  for (let i = MIN_TG; i <= MAX_TG; i += 0.1) {
    for (let j = MIN_AXIS; j <= MAX_AXIS; j += 0.02) {
      tempInputs.mu = i
      tempInputs.sig = j
      const res = calculateOptimizedOutputs(tempInputs, arr2, errorRate)
      let arr1 = res.tableData[1]

      let diffSum = 0

      for (let k = startIndex; k < sigmoidCustomChartValues.cols.length; k++)
        if (arr2[k] !== null && arr1[k]) {
          let ratio: number = 1
          if (k === 0) ratio = 0.2
          else if (k === sigmoidCustomChartValues.cols.length - 1) ratio = 1.2
          diffSum += ratio * (Math.abs(arr2[k] - arr1[k]) / arr2[k])
          diffSumArr = [...diffSumArr, diffSum]
          outerLoopArr.push(diffSum)
        }

      if (!Number.isNaN(diffSum)) {
        diffSumArray.push(diffSum)
        if (minDiffSum === null || diffSum < minDiffSum) {
          minDiffSum = diffSum
          minDiffSumArr = [{tg: i, axis: j}]
        } else if (diffSum === minDiffSum)
          minDiffSumArr && (minDiffSumArr = [...minDiffSumArr, {tg: i, axis: j}])
      }
    }
  }

  return minDiffSumArr && minDiffSumArr.length ? minDiffSumArr[0] : null
}

// ==================================

export const getSubscriptionStatus = (connectedUser: any) => {
  if (connectedUser.is_free_plan && !connectedUser?.current_subscription) return 'Free Plan'
  if (connectedUser.admin_grant_enterprise) return 'Enterprise'
  if (connectedUser.admin_grant_professional) return 'Professional'
  if (connectedUser.current_plan === "professional") return 'Professional'
  if (connectedUser.current_plan === "enterprise") return 'Enterprise'
  if (connectedUser.role === 'team_member') return 'Team member'
  if (!connectedUser?.current_subscription) return 'Free Plan'
  

  const remainingDays =
    moment(moment(new Date(connectedUser.current_subscription.period_end * 1000))).diff(
      new Date(),
      'days'
    ) + 1

  if (connectedUser.current_subscription.description.toLowerCase().includes('trial'))
    return remainingDays <= 0 ? 'Trial mode ended with no active subscription' : 'Trial mode'

  if (remainingDays <= 0) return 'Subscription ended'
  if (remainingDays < 14) return 'Subscription is active but will expire in less than 14 days'
  if (connectedUser.current_subscription.is_cancelled) return 'Auto-renew deactivated'
}

export const roundToNDecPoints = (val: any, nbOfDecPoints: number) =>
  parseFloat(val).toFixed(nbOfDecPoints)

export const roundToTwoDecPoints = (val: any) => roundToNDecPoints(val, 2)
// export const roundToTwoDecPoints = (val: any) => val;

export const getShortTitle = (title: string, len: number = 16) =>
  title.length > len ? title.substring(0, len) + '..' : title

export const getCurrencySymbol = (currencyAbbr: string) => {
  switch (currencyAbbr) {
    case 'eur':
      return '€'
    case 'usd':
      return '$'
    default:
      return ''
  }
}

export const getRecurringIntervalFromDescription = ({description: desc, product_name}: any) => {
  if (product_name === 'Business') return 'Year'

  return (
    desc[desc.lastIndexOf(' ') + 1].toUpperCase() +
    desc.substring(desc.lastIndexOf(' ') + 2, desc.length - 1)
  )
}

export const getRandomHexColor = () => {
  // `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  const hexValues = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 'a', 'b', 'c', 'd', 'e', 'f']
  let hex = '#'
  for (let i = 0; i < 6; i++) hex += hexValues[Math.floor(Math.random() * hexValues.length)]
  return hex
}

export const downloadCustomCurveXlsxTemplate = (downloadData: any) => {
  const worksheetsNames = ['Custom Curve']
  const worksheets = {
    'Custom Curve': xlsx.utils.json_to_sheet([downloadData]),
  }

  const workbook = {Sheets: worksheets, SheetNames: worksheetsNames}
  const excelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'})
  const data = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
  })
  FileSaver.saveAs(data, `custom-curve-template.xlsx`)
}
function dateIsValid(date: any) {
  return !Number.isNaN(new Date(date).getTime())
}

export const convertDate = (date: Date, form?: string) => {
  return format(new Date(date), form || 'MM/dd/yyyy')
}
export const getFirstDayOfMonth = (date: any) => new Date(date.getFullYear(), date.getMonth(), 1)
export const getCreatedAtDate = (id: string) => {
  return convertDate(new Date(parseInt(id.substring(0, 8), 16) * 1000))
}

export const findSym = (code: string) =>
  Object.values(currencyData[0]).filter(
    (item) => item.code?.toUpperCase() === code?.toUpperCase()
  )[0]?.symbol
