import axios, { AxiosResponse } from 'axios';
import { differenceInMonths, eachMonthOfInterval, eachYearOfInterval } from 'date-fns';
import { Holiday } from '../modules/projects/components/calendar/models';

const API_PUBLIC_HOLIDAYS = process.env.REACT_APP_API_URL + '/api/v1/publicholidays';

// In-memory cache to store holidays
const memoryCache: { [key: string]: Holiday[] } = {};

// Helper function to open an IndexedDB connection
const openDB = (): Promise<IDBDatabase> => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open('HolidayDB', 1);

    request.onupgradeneeded = () => {
      const db = request.result;
      if (!db.objectStoreNames.contains('holidays')) {
        db.createObjectStore('holidays', { keyPath: 'id' });
      }
    };

    request.onsuccess = () => {
      resolve(request.result);
    };

    request.onerror = () => {
      reject(request.error);
    };
  });
};

// Helper function to get cached holidays from IndexedDB
const getCachedHolidaysFromDB = async (cacheKey: string): Promise<Holiday[] | null> => {
  const db = await openDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction('holidays', 'readonly');
    const store = transaction.objectStore('holidays');
    const request = store.get(cacheKey);

    request.onsuccess = () => {
      resolve(request.result ? request.result.data : null);
    };

    request.onerror = () => {
      reject(request.error);
    };
  });
};

// Helper function to save holidays to IndexedDB
const saveHolidaysToDB = async (cacheKey: string, holidays: Holiday[]): Promise<void> => {
  const db = await openDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction('holidays', 'readwrite');
    const store = transaction.objectStore('holidays');
    const request = store.put({ id: cacheKey, data: holidays });

    request.onsuccess = () => {
      resolve();
    };

    request.onerror = () => {
      reject(request.error);
    };
  });
};

// Helper function to get cached holidays from memory or IndexedDB
const getCachedHolidays = async (cacheKey: string): Promise<Holiday[] | null> => {
  return new Promise((resolve) => {
    setTimeout(async () => {
      // Check in-memory cache first
      if (memoryCache[cacheKey]) {
        resolve(memoryCache[cacheKey]);
        return;
      }

      // Fallback to IndexedDB if not in memory cache
      const cachedData = await getCachedHolidaysFromDB(cacheKey);
      if (cachedData) {
        memoryCache[cacheKey] = cachedData;
      }

      resolve(cachedData);
    }, 100);
  });
};


// Helper function to save holidays to memory cache and IndexedDB
const saveHolidaysToCache = async (cacheKey: string, holidays: Holiday[]): Promise<void> => {
  // Save to memory cache
  memoryCache[cacheKey] = holidays;

  // Save to IndexedDB
  await saveHolidaysToDB(cacheKey, holidays);
};

/**
 * Used to get all holidays for an array of years
 * @param years Array with every year you need holidays for
 * @param countryCode The country code for which to fetch the holidays
 * @returns Array with every holiday from every year
 */
export const getHolidays = async (
  years: number[],
  countryCode: string = 'TN'
): Promise<Holiday[]> => {
  if (countryCode === '-') return [];

  const holidays: Holiday[] = [];

  for (const year of years) {
    const cacheKey = `${year}-${countryCode}`;
    const cachedHolidays = await getCachedHolidays(cacheKey);

    if (cachedHolidays) {
      holidays.push(...cachedHolidays);
    } else {
      // Fetch data from the API if not cached
      try {
        const response: AxiosResponse<Holiday[]> = await axios.get(
          `${API_PUBLIC_HOLIDAYS}/${year}/${countryCode}`
        );
        await saveHolidaysToCache(cacheKey, response.data);
        holidays.push(...response.data);
      } catch (error) {
        console.error(`Error fetching holidays for ${year}:`, error);
      }
    }
  }
  
  return holidays;
};

export const getHolidayArray = async (
  startDate: Date | number | undefined,
  endDate: Date | number | undefined
): Promise<number[]> => {
  if (!startDate || !endDate) return [];
  if (typeof startDate === 'number') startDate = new Date(startDate);
  if (typeof endDate === 'number') endDate = new Date(endDate);

  const years = eachYearOfInterval({ start: startDate, end: endDate }).map((year) =>
    year.getFullYear()
  );
  const months = eachMonthOfInterval({ start: startDate, end: endDate });
  const holidayArray = Array(months.length).fill(0);
  const holidays = await getHolidays(years);

  holidays.forEach(
    (item) => holidayArray[differenceInMonths(new Date(item.date), startDate as Date)]++
  );

  return holidayArray;
};
