import {useDrop} from 'react-dnd'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {Folder} from '../../../../eps/_models'
import {ProjectModel} from '../../_models'
import {patchProject} from '../../_requests'
import {showError} from '../../../../../utils/funcs'
import clsx from 'clsx'

type Props = {
  folder: Folder
  refetch: any
}

export default function ViewTreeItem({folder, refetch}: Props) {
  const [{isOver}, drop] = useDrop(() => ({
    accept: 'project',
    collect: (monitor) => ({isOver: !!monitor.isOver()}),
    drop: (item: ProjectModel) => {
      if (item.parent_folder?.$oid === (folder._id?.$oid || 'root')) return
      patchProject({parent_folder: folder._id?.$oid || 'root'}, item._id.$oid)
        .then(refetch)
        .catch(showError)
    },
  }))
  return (
    <div
      ref={drop}
      className={clsx('d-inline-flex justify-content-start align-items-center', {
        'bg-light-info': isOver,
      })}
    >
      <KTSVG
        path={
          isOver ? '/media/icons/duotune/files/fil017.svg' : '/media/icons/duotune/graphs/git04.svg'
        }
        className='svg-icon-primary svg-icon-2 me-2'
      />
      <span className='fw-bold me-2'>{folder.name}</span>
    </div>
  )
}
