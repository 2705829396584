import {
  CreateProjectObject,
  ImageProject,
  Project,
  ProjectData,
  ProjectModel,
  ProjectObject,
  ProjectVersions,
  Recommendation,
} from './_models'
import {Response} from './../../../../_metronic/helpers/crud-helper/models'

import axios, {AxiosResponse} from 'axios'
import * as authHelper from '../../auth/core/AuthHelpers'
import Dashboard from '../components/overview/Dashboard'

const API_URL_PROJECTS = `${process.env.REACT_APP_API_URL}/projects`
const API_URL_DASHBOARD = `${process.env.REACT_APP_API_URL}/dashboard`
const API_URL_SUB_PROJECTS = `${process.env.REACT_APP_API_URL}/sub_projects`
const API_URL_VERSION = `${process.env.REACT_APP_API_URL}/projects_versions`
const API_URL_SNAPSHOT = `${process.env.REACT_APP_API_URL}/projects/sharesnapshot`
const API_URL_SHARE_VERSION = `${process.env.REACT_APP_API_URL}/project_share_version`
const API_URL_RECOMMENDATIONS = `${process.env.REACT_APP_API_URL}/recommendations`
const API_URL_SET_PROJECT_VISIBILITY = `${process.env.REACT_APP_API_URL}/projects/set_visibility`

authHelper.setupAxios(axios)

const getProjects = (project: ProjectData): Promise<Project> => {
  return axios
    .get(
      `${API_URL_PROJECTS}?page=${project.page}&per_page=${project.perPage}${
        project.filter.length > 0 ? '&status=' + project.filter : ''
      }${project.featured ? '&is_featured=true' : ''}${
        project.parent_folder ? '&parent_folder=' + project.parent_folder : ''
      }&name=${project.name || ''}&is_visible=true&is_create_completed=true`,
      {
        headers: {Authorization: 'Bearer ' + authHelper.getAuth()?.api_token},
      }
    )
    .then((d: AxiosResponse<Project>) => d.data)
}

const createProject = (project: CreateProjectObject) => {
  return axios
    .post(API_URL_PROJECTS, project, {
      headers: {Authorization: 'Bearer ' + authHelper.getAuth()?.api_token},
    })
    .then((response: AxiosResponse<ProjectVersions>) => response.data)
}

const patchProject = (project: any, id: string): Promise<ProjectModel | undefined> => {
  return axios
    .patch(`${API_URL_PROJECTS}/${id}`, project, {
      headers: {Authorization: 'Bearer ' + authHelper.getAuth()?.api_token},
    })
    .then((response: AxiosResponse<ProjectModel>) => response.data)
}


const patchGlobalDashboard = (dashboard:any): Promise<any | undefined> => {
  return axios
    .patch(`${API_URL_DASHBOARD}`, dashboard , {
      headers: {Authorization: 'Bearer ' + authHelper.getAuth()?.api_token},
    })
    .then((response: AxiosResponse<ProjectModel>) => response.data)
}

const createGlobalDashboard = (dashboard: any) => {
  return axios
    .post(API_URL_DASHBOARD, dashboard, {
      headers: {Authorization: 'Bearer ' + authHelper.getAuth()?.api_token},
    })
    .then((response: AxiosResponse<ProjectVersions>) => response.data)
}

const getGlobalDashboard = (): Promise<any> => {
  return axios
    .get(`${API_URL_DASHBOARD}`, {
      headers: {Authorization: 'Bearer ' + authHelper.getAuth()?.api_token},
    })
    .then((d: AxiosResponse<Project>) => d.data)
}


const sendProject = (email: any, id: string): Promise<any | undefined> => {
  return axios
    .post(`${API_URL_PROJECTS}/${id}`, email, {
      headers: {Authorization: 'Bearer ' + authHelper.getAuth()?.api_token},
    })
    .then((response: AxiosResponse<Response<any>>) => response)
    .then((response: Response<any>) => response)
}
const deleteProject = (projectId: string): Promise<any | undefined> => {
  return axios
    .delete(`${API_URL_PROJECTS}/${projectId}`, {
      headers: {Authorization: 'Bearer ' + authHelper.getAuth()?.api_token},
    })
    .then((response: AxiosResponse<Response<any>>) => response)
    .then((response: Response<any>) => response)
}

const addPhotoProject = (Image: ImageProject): Promise<ProjectModel | undefined> => {
  let formData = new FormData()
  formData.append('image', Image.image)
  return axios
    .post(`${API_URL_PROJECTS}/${Image.project_id}/image`, formData, {
      headers: {Authorization: 'Bearer ' + authHelper.getAuth()?.api_token},
    })
    .then((response: AxiosResponse<ProjectModel>) => response.data)
}

const getProjectById = (id: string|undefined): Promise<Project> => {
  return axios
    .get(`${API_URL_PROJECTS}/${id}`, {
      headers: {Authorization: 'Bearer ' + authHelper.getAuth()?.api_token},
    })
    .then((d: AxiosResponse<Project>) => d.data)
}

const getSubProjects = (project: ProjectData): Promise<Project> => {
  return axios
    .get(
      `${API_URL_PROJECTS}?page=${project.page}&per_page=${project.perPage}${
        project.filter.length > 0 ? '&status=' + project.filter : ''
      }${project.featured ? '&is_featured=true' : ''}${
        project.parent_folder ? '&parent_folder=' + project.parent_folder : ''
      }&name=${project.name || ''}`,
      {
        headers: {Authorization: 'Bearer ' + authHelper.getAuth()?.api_token},
      }
    )
    .then((d: AxiosResponse<Project>) => d.data)
}

const createSubProject = (project: CreateProjectObject) => {
  return axios
    .post(API_URL_SUB_PROJECTS, project, {
      headers: {Authorization: 'Bearer ' + authHelper.getAuth()?.api_token},
    })
    .then((response: AxiosResponse<ProjectVersions>) => response.data)
}

const patchSubProject = (project: any, id: string): Promise<ProjectModel | undefined> => {
  return axios
    .patch(`${API_URL_SUB_PROJECTS}/${id}`, project, {
      headers: {Authorization: 'Bearer ' + authHelper.getAuth()?.api_token},
    })
    .then((response: AxiosResponse<ProjectModel>) => response.data)
}

const deleteSubProject = (projectId: string): Promise<any | undefined> => {
  return axios
    .delete(`${API_URL_SUB_PROJECTS}/${projectId}`, {
      headers: {Authorization: 'Bearer ' + authHelper.getAuth()?.api_token},
    })
    .then((response: AxiosResponse<Response<any>>) => response)
    .then((response: Response<any>) => response)
}

const getSubProjectById = (id: string): Promise<Project> => {
  return axios
    .get(`${API_URL_SUB_PROJECTS}/${id}`, {
      headers: {Authorization: 'Bearer ' + authHelper.getAuth()?.api_token},
    })
    .then((d: AxiosResponse<Project>) => d.data)
}

const getProjectDetailsById = (id: string): Promise<ProjectObject> => {
  return axios
    .get(`${API_URL_PROJECTS}/${id}`, {
      headers: {Authorization: 'Bearer ' + authHelper.getAuth()?.api_token},
    })
    .then((d: AxiosResponse<ProjectObject>) => d.data)
}


const getProtectedShareProjectDetailsById = (id: string,  password?: string): Promise<ProjectObject> => {
  return axios
    .post(`${API_URL_PROJECTS}/share/${id}`, { password })
    .then((d: AxiosResponse<any>) => d.data)
}

const getProjectShareDetailsById = (id: string): Promise<ProjectObject> => {
  return axios
    .get(`${API_URL_PROJECTS}/share/${id}`)
    .then((d: AxiosResponse<ProjectObject>) => d.data)
}

const getProjectSnapshotDetailsById = (id: string): Promise<ProjectObject> => {
  return axios
    .get(`${API_URL_PROJECTS}/snapshot/${id}`)
    .then((d: AxiosResponse<ProjectObject>) => d.data)
}

const getProjectImageById = (id: string): Promise<any> => {
  return axios
    .get(`${API_URL_PROJECTS}/${id}/image`, {
      headers: {Authorization: 'Bearer ' + authHelper.getAuth()?.api_token},
    })
    .then((d: AxiosResponse<any>) => d.data)
}

// version queries
const createVersion = (projectVersion: any, projectId: string) => {
  return axios
    .post(API_URL_PROJECTS + '/' + projectId + '/versions', projectVersion)
    .then((response: AxiosResponse<ProjectObject>) => response.data)
}

const getVersionById = async (versionId: string) => {
  return axios
    .get(API_URL_VERSION + '/' + versionId)
    .then((response: AxiosResponse<any>) => response.data)
}


const patchVersion = (newVersion: any, versionId: string) => {
  return axios
    .patch(API_URL_VERSION + '/' + versionId, newVersion)
    .then((response: AxiosResponse<ProjectObject>) => response.data)
}
const setDefaultVersion = (versionId: string) => {
  return axios
    .put(API_URL_VERSION + '/' + versionId)
    .then((response: AxiosResponse<any>) => response.data)
}

const setShareVersion = (shareVersionId: string) => {
  return axios
    .put(API_URL_SHARE_VERSION + '/' + shareVersionId)
    .then((response: AxiosResponse<any>) => response.data)
}
const deleteVersion = (versionId: string) => {
  return axios
    .delete(API_URL_VERSION + '/' + versionId)
    .then((response: AxiosResponse<any>) => response.data)
}

const getSnapshotById = async (snapshotId: string) => {
  const response = await axios
    .get(API_URL_SNAPSHOT + '/' + snapshotId)
  return response.data
}

const getProtectedSnapshotById = (snapshotId: string, password?: string) => {
  return axios
    .post(API_URL_SNAPSHOT + '/' + snapshotId, { password })
    .then((response: AxiosResponse<any>) => response.data)
}

const patchSnapshot = (newSnapshot: any, snapshotId: string) => {
  return axios
    .patch(API_URL_SNAPSHOT + '/' + snapshotId, newSnapshot)
    .then((response: AxiosResponse<ProjectObject>) => response.data)
}

const deleteSnapshot = async (snapshotId: string) => {
  const response = await axios
    .delete(API_URL_SNAPSHOT + '/' + snapshotId)
  return response.data
}

// add timestamp to not cache image
const getProjectImageLink = (projectId: string) =>
  `${API_URL_PROJECTS}/${projectId}/image?${new Date().getTime()}`

const getRecommendations = () => {
  return axios.get(API_URL_RECOMMENDATIONS).then((d: AxiosResponse<Recommendation[]>) => d.data)
}

const updateProjectVisibility = async (project_id: any, isVisible: boolean = false) => {
  console.log('project id : ', project_id)
  try {
      const response = await axios.post(API_URL_SET_PROJECT_VISIBILITY + '/' + project_id, 
      { is_visible: isVisible },
      {
        headers: {Authorization: 'Bearer ' + authHelper.getAuth()?.api_token},
      });
      console.log('Response from server:', response.data);
      return response.data;
  } catch (error) {
      console.error('Error from server:', error);
      throw error;
  }
};

// const updateProjectVisibility = async (selectedProjects: any[]) => {
//   try {
//       const response = await axios.post(API_URL_SET_PROJECT_VISIBILITY, {
//           selected_project_ids: selectedProjects,
//       }, {
//           headers: {
//             Authorization: 'Bearer ' + authHelper.getAuth()?.api_token
//           }
//       });
//       console.log(response.data.message);
//   } catch (error) {
//       console.error('Error updating project visibility:', error);
//   }
// };

export {
  getProjects,
  createProject,
  getProjectById,
  getProjectImageById,
  getProjectDetailsById,
  addPhotoProject,
  patchProject,
  deleteProject,
  sendProject,
  createVersion,
  getVersionById,
  patchVersion,
  setDefaultVersion,
  setShareVersion,
  deleteVersion,
  getSnapshotById,
  patchSnapshot,
  deleteSnapshot,
  getProtectedSnapshotById,
  getProtectedShareProjectDetailsById,
  getProjectShareDetailsById,
  getProjectSnapshotDetailsById,
  getProjectImageLink,
  getRecommendations,
  getSubProjects,
  createSubProject,
  getSubProjectById,
  patchSubProject,
  deleteSubProject,
  updateProjectVisibility,
  patchGlobalDashboard,
  getGlobalDashboard, 
  createGlobalDashboard
  
}
