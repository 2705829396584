import {FormikProps, useFormik} from 'formik'
import {Dispatch, FC, SetStateAction, createContext, useContext, useEffect, useState} from 'react'
import {WithChildren} from '../../../../_metronic/helpers'
import {CustomCurve, SimulationObject, SimulationObjectWithDates} from '../core/_models'
import {useNavigate, useParams} from 'react-router-dom'
import {TypeOfCalculate, convertDates, simulationInit, simulationValidationSchema} from './_models'
import {getSimulationsById, patchSimulation} from '../core/_requests'
import {showError} from '../../../utils/funcs'
import {ChartUnit} from '../simulationSinglePage'
import {calculateOutputs, calculateSigmoidOutputs} from '../../../helpers/func'
import moment from 'moment'

type ModalStates = {
  curveSettings: boolean
  saveSettings: boolean
  customCurve: boolean
  addCustomCurve: boolean
  updateCustomCurve: CustomCurve | undefined
  totalPrice: boolean
}
const ModalStatesInit: ModalStates = {
  curveSettings: false,
  saveSettings: false,
  customCurve: false,
  addCustomCurve: false,
  updateCustomCurve: undefined,
  totalPrice: false,
}

type SimulationContextType = {
  outputs: any
  setOutputs: Dispatch<SetStateAction<any>>
  formik: FormikProps<SimulationObjectWithDates> | undefined
  loading: boolean
  typeOfCalculate: TypeOfCalculate
  setTypeOfCalculate: Dispatch<SetStateAction<TypeOfCalculate>>
  chartUnit: ChartUnit
  setChartUnit: Dispatch<SetStateAction<ChartUnit>>
  modals: ModalStates
  setModals: Dispatch<SetStateAction<ModalStates>>
  selectedCurve: CustomCurve | undefined
  setSelectedCurve: Dispatch<SetStateAction<CustomCurve | undefined>>
}

const SimulationContextInit: SimulationContextType = {
  outputs: undefined,
  setOutputs: () => {},
  formik: undefined,
  loading: true,
  typeOfCalculate: TypeOfCalculate.CALC_END_DATE,
  setTypeOfCalculate: () => {},
  chartUnit: {ratio: 1, suffix: '%'},
  setChartUnit: () => {},
  modals: ModalStatesInit,
  setModals: () => {},
  selectedCurve: undefined,
  setSelectedCurve: () => {},
}

const SimulationContext = createContext<SimulationContextType>(SimulationContextInit)

const useSimulationContext = () => useContext(SimulationContext)

const SimulationContextProvider: FC<WithChildren> = ({children}) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const [simulationToEdit, setSimulationToEdit] = useState<SimulationObject | undefined>(undefined)
  const [typeOfCalculate, setTypeOfCalculate] = useState<TypeOfCalculate>(
    TypeOfCalculate.CALC_END_DATE
  )
  const [outputs, setOutputs] = useState<any>()
  const [chartUnit, setChartUnit] = useState<ChartUnit>({ratio: 1, suffix: '%'})
  const [modalState, setModalState] = useState<ModalStates>(ModalStatesInit)
  const [selectedCurve, setSelectedCurve] = useState<CustomCurve | undefined>()
  const {id} = useParams()
  useEffect(() => {
    if (!id) return
    setLoading(true)
    getSimulationsById(id)
      .then((res) => setSimulationToEdit(res))
      .catch((err) => showError(err).then(() => navigate('enterprise/library')))
      .finally(() => setLoading(false))
  }, [id])

  const formik = useFormik<SimulationObjectWithDates>({
    initialValues: simulationToEdit ? convertDates(simulationToEdit) : simulationInit,
    enableReinitialize: true,
    validationSchema: simulationValidationSchema,
    onSubmit: (values) =>
      patchSimulation(
        {...values, _id: undefined, updated_at: undefined, user: undefined, team: undefined},
        id || ''
      )
        .then((res) => setSimulationToEdit(res))
        .catch((err) => showError(err)),
  })

  useEffect(() => {
    const timeout = setTimeout(refreshOutputs, 0)
    return () => clearTimeout(timeout)
  }, [formik.values, typeOfCalculate])

  const refreshOutputs = () => {
    if (formik.values.is_start) refreshStartedOutputs()
    else refreshNotStartedOutputs()
  }

  const refreshStartedOutputs = () => {
    if (
      !formik.values.achieved_percentage ||
      !formik.values.data_date ||
      (typeOfCalculate === TypeOfCalculate.FIXED_END_DATE && !formik.values.end_date) ||
      (typeOfCalculate === TypeOfCalculate.CALC_END_DATE && !formik.values.period_count.count) ||
      +formik.values.achieved_percentage < 1 ||
      +formik.values.achieved_percentage > 99 ||
      +formik?.values.period_count.count < 1 ||
      isNaN(+formik?.values.period_count.count)
    )
      return

    setOutputs(
      calculateOutputs(
        {
          mu: formik.values.curve_settings.tangent,
          sig: formik.values.curve_settings.offset,
          nbOfMonthsPassed: formik.values.period_count.count,
          projectDurationUnit: formik.values.period_count.type === 'monthly' ? 'month' : 'day',
          donePercentage: formik.values.achieved_percentage,
          enteredEndDate:
            formik.values.period_count.type === 'monthly'
              ? moment(formik.values.end_date).format('YYYY-MM')
              : formik.values.end_date,
          inputDataDate:
            formik.values.period_count.type === 'monthly'
              ? moment(formik.values.data_date).format('YYYY-MM')
              : formik.values.data_date,
          isCalculatingRemainingTodo: typeOfCalculate === TypeOfCalculate.FIXED_END_DATE,
        },
        false
      )
    )
  }

  const refreshNotStartedOutputs = () => {
    setOutputs(
      calculateSigmoidOutputs(
        {
          mu: formik.values.curve_settings.tangent,
          sig: formik.values.curve_settings.offset,
          nbOfMonthsPassed: formik.values.period_count.count,
        },
        false
      )
    )
  }

  return (
    <SimulationContext.Provider
      value={{
        formik,
        loading,
        outputs,
        setOutputs: () => {},
        setTypeOfCalculate,
        typeOfCalculate,
        chartUnit,
        setChartUnit,
        modals: modalState,
        setModals: setModalState,
        selectedCurve,
        setSelectedCurve,
      }}
    >
      {children}
    </SimulationContext.Provider>
  )
}

export {useSimulationContext, SimulationContextProvider, SimulationContext}
