import ReactApexChart from 'react-apexcharts'
import {useProject} from '../../modules/projects/core/ProjectContext'
import {useEffect, useRef, useState} from 'react'
import {getCSSVariableValue} from '../../../_metronic/assets/ts/_utils'
import {ApexOptions} from 'apexcharts'

interface scheduleProps {
  isLoading: boolean
}

export default function TestScheduleChart({isLoading}: scheduleProps) {
  const [isVariance, setIsVariance] = useState(true)
  const labelColor = getCSSVariableValue('--kt-dark')
  const {displayVersion, project} = useProject()
  const defaultVersion = project?.default_version
  const fillSetRef = useRef(false)

  const handleChartUpdate = () => {
    let svgPaths = document.querySelectorAll('#chartSchedule .apexcharts-boxPlot-area')
    let estimated = svgPaths[3]
    let variance = svgPaths[4]

    if (estimated) {
      estimated.setAttribute('fill', '#7EFCB0')
      variance.setAttribute('fill', '#E53876')
      fillSetRef.current = true
    }
  }
  useEffect(() => {
    handleChartUpdate()
  }, [fillSetRef])

  if (!defaultVersion) return <div>empty</div>

  const delay = displayVersion?.delay_tolerance?.count || 0
  let endDate = new Date(defaultVersion.end_date.$date)
  let estimatedEndDate = new Date(defaultVersion.output.estimatedEndDate).getTime()
  const startDate = new Date(defaultVersion.start_date.$date).getTime()
  const delayDate = new Date(endDate)
  delayDate.setMonth(endDate.getMonth() + delay)
  const getMonthDifference = (timestamp1: any, timestamp2: any) => {
    const date1 = new Date(timestamp1)
    const date2 = new Date(timestamp2)

    const months = Math.ceil((Math.abs(date2.getTime() - date1.getTime())) / (1000 * 3600 * 24 * 30.44))

    return months
  }

  function findMaxNumber(arr: Array<number>) {
    if (arr.length === 0) {
      return 0
    }
    let maxNumber = arr[0]
    for (let i = 1; i < arr.length; i++) {
      if (arr[i] > maxNumber) {
        maxNumber = arr[i]
      }
    }

    return maxNumber
  }

  const state: ApexOptions = {
    series: [
      {
        type: 'boxPlot',
        data: [
          {
            x: 'Planned Duration',
            y: [
              0,
              getMonthDifference(endDate.getTime(), startDate),
              getMonthDifference(endDate.getTime(), startDate) +
                getMonthDifference(delayDate.getTime(), endDate.getTime()),
              0,
              getMonthDifference(endDate.getTime(), startDate) +
                getMonthDifference(delayDate.getTime(), endDate.getTime()),
            ],
          },
          {
            x: 'Estimated Duration',
            y: [
              0,
              getMonthDifference(estimatedEndDate, startDate),
              0,
              getMonthDifference(estimatedEndDate, startDate),
              getMonthDifference(estimatedEndDate, startDate),
            ],
          },

          ...(isVariance
            ? [
                {
                  x: 'Variance',
                  y: [
                    Math.min(
                      getMonthDifference(endDate.getTime(), startDate),
                      getMonthDifference(estimatedEndDate, startDate)
                    ) - getMonthDifference(delayDate.getTime(), endDate.getTime()),
                    Math.min(
                      getMonthDifference(endDate.getTime(), startDate),
                      getMonthDifference(estimatedEndDate, startDate)
                    ),
                    Math.max(
                      getMonthDifference(endDate.getTime(), startDate),
                      getMonthDifference(estimatedEndDate, startDate)
                    ),
                    Math.max(
                      getMonthDifference(endDate.getTime(), startDate),
                      getMonthDifference(estimatedEndDate, startDate)
                    ),
                  ],
                },
              ]
            : []),
        ],
      },
    ],
    chart: {
      type: 'boxPlot',
      height: '100%',
      toolbar: {show: false},
      events: {
        updated: (chart, options) => handleChartUpdate(), // This callback is triggered when the chart is updated
      },
    },
    stroke: {
      show: false,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        columnWidth: '80%',
      },
      boxPlot: {
        colors: {
          upper: '#FFCC00',
          lower: '#FFFF00',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    yaxis: {
      labels: {
        style: {colors: labelColor, fontSize: '10px'},
        align: 'left',

        formatter: (val: any, opts?: any) => {
          return val
        },
      },
    },
    xaxis: {
      labels: {
        style: {colors: labelColor},
      },
    },
    tooltip: {
      custom: function ({series, seriesIndex, dataPointIndex, w}: any) {
        var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex]
        if (
          data.x === 'Variance' &&
          getMonthDifference(endDate.getTime(), startDate) -
            getMonthDifference(estimatedEndDate, startDate) ===
            0
        )
          return null
        else if (data.x === 'Variance')
          return (
            '<div class="p-3">' +
            '<b> ' +'<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#E53876" class="bi bi-circle-fill" viewBox="0 0 16 16">'
            +' <circle cx="8" cy="8" r="8"/>'+' </svg> '+
            data.x +
            ': </b>' +
            (findMaxNumber(data.y) -
              Math.min(
                getMonthDifference(endDate.getTime(), startDate),
                getMonthDifference(estimatedEndDate, startDate)
              )) +
            ' Months</div>'
          )
        else if (data.x === 'Planned Duration')
          return (
            '<div class="p-3">' +
            '<b> '
            +'<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#FFFF00" class="bi bi-circle-fill" viewBox="0 0 16 16">'
            +' <circle cx="8" cy="8" r="8"/>'+' </svg> '+
            data.x +
            ': </b>' +
            getMonthDifference(endDate.getTime(), startDate) +
            ' Months</div>' +
            '<div class="p-3">' +
            '<b>' +'<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#FFCC00" class="bi bi-circle-fill" viewBox="0 0 16 16">'
            +' <circle cx="8" cy="8" r="8"/>'+' </svg> '+  'Reserve: </b>' +
            getMonthDifference(delayDate.getTime(), endDate.getTime()) +
            ' Months</div>'
          )
        else
          return (
            '<div class="p-3">' +
            '<b> ' 
            +'<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#7EFCB0" class="bi bi-circle-fill" viewBox="0 0 16 16">'
            +' <circle cx="8" cy="8" r="8"/>'+' </svg> '+
            data.x +
            ': </b>' +
            findMaxNumber(data.y) +
            ' Months</div>'
          )
      },
    },
  }

  return (
    <div id='chartSchedule'>
      <div className='card card-flush w-100 row h-50 g-1 mb-5 '>
        <div className='CPI-container'>
          <div className='card-header pt-5 d-grid'>
            <span className='text-dark widget-title'>Schedule details</span>
          </div>
          <div
            className='d-flex align-items-center py-1 px-2 rounded bg-hover-light-primary gap-2 mt-2 justify-content-center '
            onClick={() => {
              setIsVariance(!isVariance)
            }}
          >
            <input
              role='button'
              className='form-check-input'
              type='checkbox'
              checked={isVariance}
              id='flexCheckDefault'
            />

            <span className='ml-2  fs-6 fw-bold'>Display Variance</span>
          </div>
          <ReactApexChart options={state} series={state.series} type='boxPlot' height={400} />
        </div>
      </div>
    </div>
  )
}
