/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { FC, useEffect, useRef, useState } from 'react'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { setLanguage, useLang } from '../../../_metronic/i18n/Metronici18n'


const languages = [
    {
        lang: 'en',
        name: 'English',
        flag: toAbsoluteUrl('/media/flags/united-states.svg'),
    },
    {
        lang: 'fr',
        name: 'French',
        flag: toAbsoluteUrl('/media/flags/france.svg'),
    },
]
interface LanguagesProps { showText?: boolean }
const LanguagesDropDown: FC<LanguagesProps> = ({ showText = true }) => {
    const lang = useLang()
    const currentLanguage = languages.find((x) => x.lang === lang)
    const [open, setIsOpened] = useState<boolean>(false)
    const ref = useRef<any>(null);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setIsOpened(false)
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);
    return (
        <div
            ref={ref}
            className='menu-item'
            onClick={() => { setIsOpened(!open) }}

        >
            <a href='#' className='menu-link '>
                <span className='menu-title position-relative'>

                    <span className='fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0'>
                        {currentLanguage?.name}{' '}
                        <img
                            className='w-15px h-15px rounded-1 ms-2'
                            src={currentLanguage?.flag}
                            alt='metronic'
                        />
                    </span>
                </span>
            </a>

            <div className='menu-sub menu-sub-dropdown py-4' style={{ display: open ? 'block' : 'none', marginTop: '0.9rem', marginRight: '1.2rem', position:"absolute" }}>
                {languages.map((l) => (
                    <div
                        className='menu-item px-3'
                        key={l.lang}
                        onClick={() => {
                            setLanguage(l.lang)
                        }}
                    >
                        <a
                            href='#'
                            className={clsx('menu-link d-flex px-5', { active: l.lang === currentLanguage?.lang })}
                        >
                            <span className='symbol symbol-20px me-4'>
                                <img className='rounded-1' src={l.flag} alt='metronic' />
                            </span>
                            {l.name}
                        </a>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default LanguagesDropDown
