import clsx from 'clsx'

type Props = {
  title: string
  width: string
  props: any
  isSorted: boolean
  isSortedDesc: boolean | undefined
}

export default function ActionPlanHeader({title, width, props, isSorted, isSortedDesc}: Props) {
  return (
    <th
      {...props}
      className={`min-w-${width}px sorting`}
      aria-controls='kt_profile_overview_table'
      aria-label='Due Date: activate to sort column ascending'
    >
      <div className='rotate active'>
        <i
          className={clsx('bi bi-caret-down-fill cursor-pointer p-1 text-hover-primary me-2', {
            'text-primary': isSorted,
            'rotate-180': isSortedDesc,
            'rotate-0': !isSortedDesc,
            'd-none': title === '',
          })}
        ></i>
      </div>
      {title}
    </th>
  )
}
