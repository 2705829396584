import {useState} from 'react'
import {downloadCustomCurveXlsxTemplate, removeNullsFromEnd} from '../../helpers/func'
import * as xlsx from 'xlsx'
import {useDropzone, Accept} from 'react-dropzone'
import './uploadFile.scss'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
interface UploadFieldProps {
  index: number
  durationUnit: string
  setFileContent?: any
  fileContent?: any
  disabled?: boolean
}
function UploadField({
  index,
  durationUnit,
  setFileContent,
  fileContent,
  disabled,
}: UploadFieldProps) {
  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    useFsAccessApi: false,
    accept: {
      'application/vnd.ms-excel': [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      ],
    } as Accept,
    multiple: false,
    onDrop: ([acceptedFile]) => {
      if (acceptedFile) {
        setFileError(null)
        const reader = new FileReader()
        reader.onload = (e: any) => {
          const workbook = xlsx.read(e.target.result)
          const content: any = xlsx.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {
            blankrows: true,
            raw: true,
            defval: null,
          })
          const valuesFile: string[] = (Object.values(content[0]) as string[]) || []
    
          // if (!areAllValsNumeric) {
          //   const showError = () => {
          //     Swal.fire({
          //       title: 'Error',
          //       text: 'Second row of the file contains non-numeric or invalid values.',
          //       icon: 'error',
          //       confirmButtonText: 'OK',
          //     })
          //   }
          //   return;
          // }
          // else {
          setFileContent(removeNullsFromEnd(valuesFile))
       
          setFile({
            path: (acceptedFile as any).path,
            size: Math.round((acceptedFile as any).size / 1024),
            content,
          })
          // }
        }
        reader.readAsArrayBuffer(acceptedFile)
      }
    },
    onDropRejected: (rejectedFiles) => {

      setFile(null)
      setFileError(rejectedFiles[0].errors)
      // do something with rejected files
    },
  })
  const [file, setFile] = useState<any>(null)
  const [fileError, setFileError] = useState<any>(null)
  const getLabels = () => {
    const locale = localStorage.getItem('i18nextLng') || 'en'
    const timeUnitTranlation = durationUnit === 'month' ? 'Month' : 'Day'
  }

  const handleDownloadTemplate = () => {
    let downloadData
    let labels = getLabels()
    downloadCustomCurveXlsxTemplate(downloadData)
  }

  return (
    <>
      {/*begin::Input group*/}
      <>
        <div>
          <div {...getRootProps({ className: 'dropzone d-flex flex-column align-items-center justify-content-center' })}>
            <input {...getInputProps()} />
            {file && (
              <label
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow edit-xls'
                data-bs-toggle='tooltip'
                title='Change avatar'
              >
                <i className='bi bi-pencil-fill fs-7' />
              </label>
            )}
            {!file && isDragActive && <h3>Drop Here</h3>}
            {!file && !isDragActive ? (
              <>
                <div className='dz-message needsclick'>
                  {/*begin::Icon*/}
                  {/*begin::Svg Icon | path: icons/duotune/files/fil010.svg*/}
                  <span className='svg-icon svg-icon-3hx svg-icon-primary'>
                    <svg
                      width={24}
                      height={24}
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        opacity='0.3'
                        d='M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM14.5 12L12.7 9.3C12.3 8.9 11.7 8.9 11.3 9.3L10 12H11.5V17C11.5 17.6 11.4 18 12 18C12.6 18 12.5 17.6 12.5 17V12H14.5Z'
                        fill='currentColor'
                      />
                      <path
                        d='M13 11.5V17.9355C13 18.2742 12.6 19 12 19C11.4 19 11 18.2742 11 17.9355V11.5H13Z'
                        fill='currentColor'
                      />
                      <path
                        d='M8.2575 11.4411C7.82942 11.8015 8.08434 12.5 8.64398 12.5H15.356C15.9157 12.5 16.1706 11.8015 15.7425 11.4411L12.4375 8.65789C12.1875 8.44737 11.8125 8.44737 11.5625 8.65789L8.2575 11.4411Z'
                        fill='currentColor'
                      />
                      <path d='M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z' fill='currentColor' />
                    </svg>
                  </span>
                  {/*end::Svg Icon*/}
                  {/*end::Icon*/}
                  {/*begin::Info*/}
                  <div className='ms-4'>
                    <h3 className='dfs-3 fw-bold text-gray-900 mb-1'>
                      Drop files here or click to upload.
                    </h3>
                  </div>
                </div>
              </>
            ) : (
              <div>
                <span className='svg-icon svg-icon-3hx svg-icon-primary'>
                  <img
                    className='w-75px h-75px'
                    src={toAbsoluteUrl('/media/icons/duotune/technology/icon-XLS.svg')}
                    alt={file?.path}
                  />
                </span>
              </div>
            )}
          </div>
          {file && (
            <div>
              <span
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow remove-xls'
                data-bs-toggle='tooltip'
                title='Cancel avatar'
                onClick={() => {
                  setFile(null)
                  setFileError(null)
                  setFileContent(null)
                }}
              >
                <i className='bi bi-x fs-2' />
              </span>
              <div className='dz-details'>
                <div className='dz-size'>{file?.path}</div>
                <div className='dz-filename'>{file?.size} KB</div>
              </div>
            </div>
          )}

          {!file && fileError && fileError.length > 0 && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{fileError[0].message}</span>
              </div>
            </div>
          )}
        </div>

        {/* TODO: MAYBE SHOW (click to remove) ONLY WHEN EDITING A CUSTOM CURVE  */}
      </>
      {/*<div className="fv-row mb-8" >*/}
      {/*  /!*begin::Dropzone*!/*/}
      {/*  <div ref={dropzoneRef} className="dropzone" id={disabled ? '': `kt_modal_create_project_settings_logo${index}`} >*/}
      {/*    /!*begin::Message*!/*/}
      {/*    <div className="dz-message needsclick" >*/}
      {/*      /!*begin::Icon*!/*/}
      {/*      /!*begin::Svg Icon | path: icons/duotune/files/fil010.svg*!/*/}
      {/*      <span className="svg-icon svg-icon-3hx svg-icon-primary">*/}
      {/*        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
      {/*          <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM14.5 12L12.7 9.3C12.3 8.9 11.7 8.9 11.3 9.3L10 12H11.5V17C11.5 17.6 11.4 18 12 18C12.6 18 12.5 17.6 12.5 17V12H14.5Z" fill="currentColor" />*/}
      {/*          <path d="M13 11.5V17.9355C13 18.2742 12.6 19 12 19C11.4 19 11 18.2742 11 17.9355V11.5H13Z" fill="currentColor" />*/}
      {/*          <path d="M8.2575 11.4411C7.82942 11.8015 8.08434 12.5 8.64398 12.5H15.356C15.9157 12.5 16.1706 11.8015 15.7425 11.4411L12.4375 8.65789C12.1875 8.44737 11.8125 8.44737 11.5625 8.65789L8.2575 11.4411Z" fill="currentColor" />*/}
      {/*          <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="currentColor" />*/}
      {/*        </svg>*/}
      {/*      </span>*/}
      {/*      /!*end::Svg Icon*!/*/}
      {/*      /!*end::Icon*!/*/}
      {/*      /!*begin::Info*!/*/}
      {/*      <div className="ms-4">*/}
      {/*        <h3 className="dfs-3 fw-bold text-gray-900 mb-1">Drop files here or click to upload.</h3>*/}

      {/*      </div>*/}
      {/*      /!*end::Info*!/*/}

      {/*    </div>*/}
      {/*    /!*end::Dropzone*!/*/}
      {/*  </div>*/}
      {/*  {fileError && fileError.length > 0 && (*/}
      {/*    <div className='fv-plugins-message-container'>*/}
      {/*      <div className='fv-help-block'>*/}
      {/*        <span role='alert'>{fileError}</span>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  )}*/}
      {/*</div>*/}
      {/*end::Input group*/}
    </>
  )
}

export default UploadField
