export enum EventType {
  GRAPH = 'graph',
  TEAM = 'team',
  SUBSCRIPTION = 'subscription',
}

export type NotificationEvent = {
  type: EventType
  sender: string
  date: string
  message: string
  all_events?: NotificationEvent[]
}
