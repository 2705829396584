import { findSym } from "../../helpers/func"


  
  export type BugetUnitType = 'billion' | 'million' | 'thousand' | 'unit'
  
  export interface IBudget {
    amount: number
    currency: string
    unit: BugetUnitType
  }
  
  const amountFirstLetters = {
    billion: 'B',
    million: 'M',
    thousand: 'K',
    unit: '',
  }
  
  
  /**
   * Formats a budget object into a string representation.
   * @param budgetObj The budget object containing amount, currency, and unit.
   * @returns A formatted string representing the budget.
   */
  export const getFormattedBudget = (budgetObj: IBudget): string => {
    // Destructure properties from the budget object
    const { amount, currency, unit } = budgetObj;
  
    // Get the first letter of the unit from the predefined map
    const amountFirstLetter = amountFirstLetters[unit as keyof typeof amountFirstLetters];
  
    // Concatenate amount, first letter of the unit, and currency symbol
    return `${amount} ${amountFirstLetter}${findSym(currency)}`;
  }
  
  
  /**
   * Retrieves the formatted unit for a given unit string.
   * @param unit The unit string.
   * @returns The formatted unit.
   */
  
  export const getFormattedUnit = (unit: string): string => {
    // Retrieve the formatted unit from the predefined map
    return amountFirstLetters[unit as keyof typeof amountFirstLetters];
  }
  
  
  
  /**
   * Calculates the average of a sliced portion of a table.
   * @param Tabs The table from which to slice elements.
   * @param nbrUnite The number of elements to slice from the beginning of the table.
   * @returns The average of the sliced elements.
   */
  export const getAverageOfSlicedTable = (Tabs: any, nbrUnite: number): number => {
    return (
      // Slice the first 'nbrUnite' elements from the table, then calculate the sum
      Tabs.slice(0, nbrUnite).reduce(
        (partialSum: any, a: any) => !isNaN(Number(a)) && partialSum + Number(a),
        0
      ) / nbrUnite // Calculate the average by dividing the sum by the number of elements
    );
  }
  