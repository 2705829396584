import {Skeleton, Tree} from 'antd'
import {Dispatch, SetStateAction} from 'react'
import {Card} from 'react-bootstrap'
import SwitcherIcon from '../../../eps/components/TreeView/icons/SwitcherIcon'
import {useFolder} from '../../../eps/_queries'
import {DataNode, EventDataNode} from 'antd/es/tree'
import {getFolder} from '../../../eps/_requests'
import {updateTreeData} from '../../../eps/components/TreeView/_helpers'
import ViewTreeItem from './tree/ViewTreeItem'
import {constructHierarchy} from './_helpers'

type Props = {
  selectedFolder: string
  setSelectedFolder: (folder: string) => void
  setHierarchy: Dispatch<SetStateAction<{name: string; key: string; parent_folder?: string}[]>>
  treeData: DataNode[]
  setTreeData: Dispatch<SetStateAction<DataNode[]>>
  refetch: any
}

export default function SideFolders({
  selectedFolder,
  setSelectedFolder,
  setHierarchy,
  treeData,
  setTreeData,
  refetch,
}: Props) {
  const {isLoading} = useFolder(
    undefined,
    (data) =>
      setTreeData([
        {
          title: <ViewTreeItem refetch={refetch} folder={data} />,
          key: data._id?.$oid || 'root',
        },
      ]),
    undefined,
    'another'
  )

  const loadData = async (treeNode: EventDataNode<DataNode>) => {
    const childFolder = await getFolder(
      treeNode.key !== 'root' ? treeNode.key.toString() : undefined
    )
    const childData: DataNode[] = childFolder.subfolders.map((item) => ({
      key: item._id.$oid,
      title: <ViewTreeItem refetch={refetch} folder={item} />,
      switcherIcon: item.subfolders.length ? undefined : <></>,
    }))
    setTreeData((origin) => {
      return updateTreeData(origin, treeNode.key.toString(), childData)
    })
  }

  return (
    <div className='h-100 pb-5'>
      <Card className='h-100'>
        <Card.Body>
          <h3 className='mb-4'>Choose EPS</h3>
          {isLoading || !treeData.length ? (
            <Skeleton
              active
              style={{textAlign: 'end'}}
              paragraph={{rows: 4, width: ['30%', '40%', '50%', '60%']}}
            ></Skeleton>
          ) : (
            <Tree
              style={{overflowX: 'scroll'}}
              selectedKeys={[selectedFolder]}
              loadData={loadData}
              switcherIcon={SwitcherIcon}
              treeData={treeData}
              onSelect={(selectedKeys, info) => {
                const {hierarchy, selectedFolder} = constructHierarchy(info.node, treeData)
                setHierarchy(hierarchy)
                setSelectedFolder(selectedFolder)
              }}
            />
          )}
        </Card.Body>
      </Card>
    </div>
  )
}
