import {useSimulationContext} from '../SimulationContext'

type Props = {}

export default function ProjectNotStartedForm({}: Props) {
  const {formik} = useSimulationContext()
  return (
    <>
      <label className='col-lg-8 col-form-label required fw-bold fs-6'>
        Number in {formik?.values.period_count.type === 'monthly' ? 'months' : 'days'}
      </label>
      <div className='btn-group mb-5' role='group'>
        <input
          type='radio'
          className='btn-check'
          name='type'
          id='new_days'
          onClick={() => formik?.setFieldValue('period_count.type', 'daily')}
          checked={formik?.values.period_count.type === 'daily'}
          readOnly
        />
        <label className='btn btn-outline-primary border border-right-0' htmlFor='new_days'>
          Days
        </label>
        <input
          type='radio'
          className='btn-check'
          name='type'
          id='new_months'
          onClick={() => formik?.setFieldValue('period_count.type', 'monthly')}
          checked={formik?.values.period_count.type === 'monthly'}
          readOnly
          autoComplete='off'
        />
        <label className='btn btn-outline-primary border border-left-0' htmlFor='new_months'>
          Months
        </label>
      </div>
      <input
        type={'text'}
        className='form-control form-control-lg form-control-solid'
        placeholder={'Natural Number'}
        value={formik?.values.period_count.count}
        onChange={(e) => {
          formik?.setFieldValue('period_count.count', e.target.value)
        }}
        onBlur={formik?.handleBlur('period_count.count')}
      />
      {formik?.errors.period_count?.count && formik.errors.period_count.count && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>{formik?.errors.period_count?.count}</div>
        </div>
      )}
    </>
  )
}
