




import { FC } from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useThemeMode } from '../../../../_metronic/partials'
import { useEffect } from 'react'

const BODY_CLASSES = ['bgi-size-cover', 'bgi-position-center', 'bgi-no-repeat']
export const Error405 = () => {
  const { mode } = useThemeMode()
  useEffect(() => {
    BODY_CLASSES.forEach((c) => document.body.classList.add(c))
    document.body.style.backgroundImage =
      mode === 'dark'
        ? `url(${toAbsoluteUrl('/media/auth/bg7-dark.jpg')})`
        : `url(${toAbsoluteUrl('/media/auth/bg7.jpg')})`

    return () => {
      BODY_CLASSES.forEach((c) => document.body.classList.remove(c))
      document.body.style.backgroundImage = 'none'
    }
  }, [mode])




  return (
    <div className='d-flex flex-column flex-root'>
      <div className='d-flex flex-column flex-center flex-column-fluid'>
        <div className='d-flex flex-column flex-center text-center p-10'>
          <div className='card card-flush  w-lg-650px py-5'>
            <div className='card-body py-15 py-lg-20'>
              {/* begin::Title */}
              <h1 className='fw-bolder fs-2hx text-gray-900 mb-4'>Forbidden</h1>
              {/* end::Title */}

              {/* begin::Text */}
              <div className='fw-semibold fs-6 text-gray-500 mb-7'>Access to this resource on the server is denied!</div>
              {/* end::Text */}

              {/* begin::Illustration */}
              <div className='mb-3'>
                <img
                  src={toAbsoluteUrl('/media/auth/405-error.jpg')}
                  className='mw-100 mh-300px theme-light-show'
                  alt=''
                />
                <img
                  src={toAbsoluteUrl('/media/auth/405-error-dark.jpg')}
                  className='mw-100 mh-300px theme-dark-show'
                  alt=''
                />
              </div>
              {/* end::Illustration */}

              {/* begin::Link */}
              <div className='mb-0'>
                <button className='btn btn-sm btn-primary'>
                  Contact Support
                </button>
              </div>
              {/* end::Link */}

            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

