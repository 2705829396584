import { AnyAction, configureStore, ThunkDispatch, ThunkAction } from '@reduxjs/toolkit';
import treeStates from './modules/wbs/treeStates';
import treedata from './modules/wbs/treedata';
import globalDashboardData from './modules/wbs/dashboardData';
import commentStates from './modules/projects/components/comments/CommentsState';
import treeShare from './modules/wbs/treeShare';
import { setprojectversion } from './modules/wbs/treeVersionData';

import CurrentFolder from './modules/wbs/CurrentFolder';
import treeLoader from './modules/wbs/treeLoader';
const rootReducer = {
  treeStates: treeStates.checked,
  treeloader: treeStates.loader,
  treerenaming: treeStates.renaming,
  treeisheader: treeStates.isheader,
  maxdatadate: treeStates.maxdatadate,
  added:commentStates.added,
  treedata: treedata.updatedata,
  globalDashboardData: globalDashboardData.globalDashboardData,
  treeLoader: treeLoader.updateTreeLoader,
  currentwbs: treeStates.currentwbs,
  treeShare: treeShare.updatedata,
  versiondata: setprojectversion,
  CurrentFolder:CurrentFolder

};

const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export default store;
export type AppDispatch = ThunkDispatch<RootState, any, AnyAction>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>;