import {Fragment} from 'react'

type Props = {
  legends: boolean[]
  toggleLegend: (index: number) => void
  colors: string[]
  legendLabels: string[]
  labelsStyles: {id: string; dashed: boolean; line: boolean}[]
}

export default function CustomLegends({
  legends,
  toggleLegend,
  colors,
  legendLabels,
  labelsStyles: labels,
}: Props) {
  return (
    <div className='d-flex justify-content-center gap-5 flex-wrap pb-7'>
      {legends.map((item, index) => (
        <Fragment key={legendLabels[index]}>
          <div
            onClick={() => toggleLegend(index)}
            className='d-flex flex-row gap-2 align-items-center cursor-pointer'
          >
            {labels[index].line ? (
              <div
                style={{
                  width: '15px',
                  height: '2px',
                  borderTop:
                    '2px ' +
                    (labels[index].dashed
                      ? legendLabels[index] === 'Latest PV'
                        ? 'dotted'
                        : 'dashed '
                      : 'solid ') +
                    colors[index],
                }}
              />
            ) : (
              <div
                style={{
                  height: '15px',
                  width: '15px',
                  background: labels[index].dashed
                    ? `repeating-linear-gradient(45deg, ${colors[index]}, ${colors[index]} 3px, transparent 3px, transparent 6px)`
                    : 'unset',
                  backgroundColor: labels[index].dashed ? 'unset' : colors[index],
                }}
              />
            )}
            <span
              className='fs-6'
              style={{textDecoration: !item ? 'line-through' : undefined, color: colors[index]}}
            >
              {legendLabels[index]}
            </span>
          </div>
          {(labels[index].id === 'PV(m)' || labels[index].id === 'EV(m forecast)') && (
            <div
              key={labels[index].id + 'separator'}
              className='d-flex flex-row gap-2 align-items-center'
            >
              <div className='bg-secondary rounded' style={{height: '15px', width: '3px'}} />
            </div>
          )}
        </Fragment>
      ))}
    </div>
  )
}
