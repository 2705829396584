import {Modal} from 'react-bootstrap'
import Input from '../../../../../../components/Input/Input'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useProject} from '../../../../core/ProjectContext'
import {useState} from 'react'
import {patchProject} from '../../../../core/_requests'
import {showError} from '../../../../../../utils/funcs'

type Props = {
  show: boolean
  onHide: () => void
}

export default function GaugeParamsModal({onHide, show}: Props) {
  const {project, setProject} = useProject()
  const [loading, setLoading] = useState<boolean>(false)
  const formik = useFormik({
    initialValues: {
      redLimit: +(project?.gauge_params?.red_limit ?? 0.8),
      yellowLimit: +(project?.gauge_params?.yellow_limit ?? 1),
    },
    validationSchema: Yup.object().shape({
      redLimit: Yup.number()
        .min(0)
        .max(2)
        .required()
        .lessThan(Yup.ref('yellowLimit'), 'Must be less than yellow limit'),
      yellowLimit: Yup.number()
        .min(0)
        .max(2)
        .required()
        .moreThan(Yup.ref('redLimit'), 'Must be greater than red limit'),
    }),
    enableReinitialize: true,
    onSubmit: () => {
      setLoading(true)
      patchProject(
        {
          gauge_params: {
            red_limit: formik.values.redLimit,
            yellow_limit: formik.values.yellowLimit,
          },
        },
        project?._id.$oid || ''
      )
        .then((res) =>
          setProject((prev) =>
            prev && res ? {...prev, gauge_params: res?.gauge_params} : undefined
          )
        )
        .catch(showError)
        .finally(() => {
          setLoading(false)
          onHide()
        })
    },
  })

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Gauge Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <Input
            labelText='Red Limit'
            error={formik.errors.redLimit as string}
            formikProps={formik}
            name='redLimit'
            isTouched={!!formik.touched.redLimit}
            placeholder=''
            type='range'
            max={2}
            min={0}
          />
          <Input
            labelText='Yellow Limit'
            error={formik.errors.yellowLimit as string}
            formikProps={formik}
            name='yellowLimit'
            isTouched={!!formik.touched.yellowLimit}
            placeholder=''
            type='range'
            max={2}
            min={0}
          />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button
          disabled={loading || !formik.isValid}
          className='btn btn-primary'
          onClick={formik.submitForm}
        >
          {loading ? (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          ) : (
            'Save'
          )}
        </button>
      </Modal.Footer>
    </Modal>
  )
}
