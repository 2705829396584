import { FC, ReactNode, useState } from 'react'
import { Dropdown } from 'antd'
import type { MenuProps } from 'antd'
import { FormikProps } from 'formik'
import { Wbs } from '../../_models'
import { deleteWbs } from '../../_requests'
import { deleteTreeNode } from './_helpers'
import { useTree } from './TreeContext'
import { showError } from '../../../../utils/funcs'
import MoveItem from '../MoveItem'

type Props = {
  children: ReactNode
  setRenaming: () => void
  formik: FormikProps<{ name: string }>
  folder: Wbs
}

export const FolderDropdown: FC<Props> = ({ children, setRenaming, formik, folder }) => {
  const { setTreeData } = useTree()
  const [showMoveModal, setShowMoveModal] = useState<boolean>(false)

  const handleDelete = () => {
    deleteWbs(folder._id.$oid)
      .then(() => {
        setTreeData((origin) => deleteTreeNode(origin, folder._id.$oid))
      })
      .catch(showError)
  }

  const items: MenuProps['items'] = [
    {
      label: 'Rename Wbs',
      key: '1',
      onClick: setRenaming,
      disabled: !folder._id,
    },
    {
      label: 'Delete Wbs',
      key: '2',
      onClick: handleDelete,
      disabled: !folder._id,
    },
    {
      label: 'Move Wbs',
      key: '3',
      onClick: () => setShowMoveModal(true),
      disabled: !folder._id,
    },
  ]

  return (
    <>
      <MoveItem show={showMoveModal} onHide={() => setShowMoveModal(false)} folder={folder} />
      <Dropdown menu={{ items }} trigger={['contextMenu']}>
        <form onSubmit={formik.handleSubmit}>{children}</form>
      </Dropdown>
    </>
  )
}
