import {EacFormula, ForecastFormula} from '../../core/_models'

export type Option<T> = {
  label: string
  value: T
}

export const eacFormulaOptions: Option<EacFormula>[] = [
  {
    label: 'BAC/CPI',
    value: EacFormula['BAC/CPI'],
  },
  {
    label: 'AC + (Bottom UP Estimated Cost)',
    value: EacFormula['AC + (Bottom UP Estimated Cost)'],
  },
  {
    label: 'AC + (BAC - EV)',
    value: EacFormula['AC + (BAC - EV)'],
  },
  {
    label: 'AC + (BAC - EV) / (CPI x SPI)',
    value: EacFormula['AC + (BAC - EV) / (CPI x SPI)'],
  },
  {
    label: 'Forced to BAC',
    value: EacFormula['Forced to BAC'],
  },
  {
    label: 'Custom EAC',
    value: EacFormula['Custom EAC'],
  },
]

// export const forecastFormulaOptions: Option<ForecastFormula>[] =
const enumStrings: string[] = Object.keys(ForecastFormula).filter((key) => isNaN(Number(key)))
export const forecastFormulaOptions: Option<ForecastFormula>[] = enumStrings.map((key) => ({
  label: key,
  value: ForecastFormula[key as keyof typeof ForecastFormula],
}))
