import moment from 'moment'
import {useLang} from '../../_metronic/i18n/Metronici18n'

type DurationUnitType = 'year' | 'month' | 'day'
type DateFormatType = 'mm/dd/yyyy' | 'dd/mm/yyyy'
//gets length of the longest array
const getMaxArrayLength = (...arrays: any) =>
  Math.max(...arrays.filter((el: any) => !!el).map((el: any) => el?.length))
//formats a date according to duration unit
const getFormattedDateFromMomentObj = (m: any, durationUnit: DurationUnitType) =>
  m.format(durationUnit === 'day' ? 'D MMM YYYY' : 'MMM YYYY')
//returns a "length" amount of formatted dates according to durationUnit
const getLabels = (
  startTimestamp: number,
  length: number,
  durationUnit: DurationUnitType,
  _dateFormat: DateFormatType = 'dd/mm/yyyy',
  locale: string = 'en'
) => {
  moment.locale(locale)
  return Array.from({length}).map((_el, idx) =>
    moment(startTimestamp).add(idx, `${durationUnit}s`).format('MMM YY')
  )
}
const normalizeDateUnit = (unit: string | undefined): DurationUnitType =>
  unit === 'monthly' ? 'month' : unit === 'yearly' ? 'year' : 'day'
export {getLabels, getMaxArrayLength, normalizeDateUnit, getFormattedDateFromMomentObj}

export const getFormattedDate = (
  date: Date,
  durationUnit: 'monthly' | 'yearly' | 'daily',
  dateFormat?: string
) => {
  if (!durationUnit) return 'Invalid Unit'
  const normalizedDateUnit = normalizeDateUnit(durationUnit)
  return moment(date).format(
    normalizedDateUnit === 'day'
      ? dateFormat === 'mm/dd/yyyy'
        ? 'MM/DD/YYYY'
        : 'DD/MM/YYYY'
      : 'MM/YYYY'
  )
}

export const getFormattedMonth = (
  date: Date,
  durationUnit: 'monthly' | 'yearly' | 'daily',
  dateFormat?: string
) => {
  if (!durationUnit) return 'Invalid Unit'
  const normalizedDateUnit = normalizeDateUnit(durationUnit)
  // Set locale to English
  moment.locale('en')
  return moment(date)
    .format(
      normalizedDateUnit === 'day'
        ? dateFormat === 'mm/dd/yyyy'
          ? 'MM/DD/YYYY'
          : 'DD/MM/YYYY'
        : 'MMMM'
    )
    .toLocaleString()
}

export const getFNSDateFormat = (format: string) =>
  format.toLowerCase() === 'dd/mm/yyyy' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'
