import {useFormik} from 'formik'
import {useState} from 'react'
import {Modal} from 'react-bootstrap'
import ColorInputWithLabel from '../../../../../components/inputWithLabel/ColorInputWithLabel'
import {InitMilestonesSettings, MilestonesSettings} from '../_models'
import {useProject} from '../../../core/ProjectContext'
import {patchVersion} from '../../../core/_requests'
import {showError, showSuccess} from '../../../../../utils/funcs'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {Link} from 'react-router-dom'

type Props = {onHide: () => void}

export default function MilestoneSettingsForm({onHide}: Props) {
  const {displayVersion, setVersion, setOriginalVersion, project} = useProject()
  const [loading, setLoading] = useState(false)
  const formik = useFormik<MilestonesSettings>({
    initialValues:
      displayVersion?.colors?.milestone_colors && displayVersion?.colors?.milestone_colors?.length
        ? {
            plannedColor: displayVersion?.colors?.milestone_colors[2],
            achievedColor: displayVersion?.colors?.milestone_colors[1],
            forecastColor: displayVersion?.colors?.milestone_colors[0],
          }
        : InitMilestonesSettings,
    onSubmit: (values) => {
      setLoading(true)
      patchVersion(
        {
          colors: {
            ...displayVersion?.colors,
            milestone_colors: [values.forecastColor, values.achievedColor, values.plannedColor],
          },
        },
        displayVersion?._id.$oid
      )
        .then((res) => {
          showSuccess('Settings changed!').then(onHide)
          setVersion((prev) =>
            prev
              ? {...prev, total_hours: res.total_hours, full_capacity: res.full_capacity}
              : undefined
          )
          setOriginalVersion(res)
        })
        .catch((err) => showError(err))
        .finally(() => setLoading(false))
    },
  })
  return (
    <>
      <Modal.Body>
        <ColorInputWithLabel
          error={formik.errors.plannedColor}
          label='Baseline Color'
          formikProps={formik.getFieldProps('plannedColor')}
          touched={formik.touched.plannedColor}
          value={formik.values.plannedColor}
        />
        <ColorInputWithLabel
          error={formik.errors.achievedColor}
          label='Achieved Color'
          formikProps={formik.getFieldProps('achievedColor')}
          touched={formik.touched.achievedColor}
          value={formik.values.achievedColor}
        />
        <ColorInputWithLabel
          error={formik.errors.forecastColor}
          label='Forecast Color'
          formikProps={formik.getFieldProps('forecastColor')}
          touched={formik.touched.forecastColor}
          value={formik.values.forecastColor}
        />
        <Link to={`/project/${project?._id.$oid}/milestones`} className='btn btn-light-primary'>
          Update Milestones{' '}
          <KTSVG
            path='/media/icons/duotune/arrows/arr001.svg'
            className='svg-icon-muted svg-icon-2'
          />
        </Link>
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={() => formik.handleSubmit()}
          disabled={loading || !formik.dirty || !formik.isValid}
          className='btn btn-primary'
        >
          {loading ? (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          ) : (
            'Save'
          )}
        </button>
      </Modal.Footer>
    </>
  )
}
