export const planContents = [
  {
    header: 'Free',
    price: 0,
    trial_type: '', 
    features: ['3 Projects', 'EVM, ES', 'Library'],
    not_available_features: ['Forecasting', 'Simulations', 'Video Support', 'Project Sharing', 'Organization', 'EPS', 'Multiproject Dashboard'],
    buttonLabel: 'Get free trial',
    outline: false,
  },
  {
    header: 'Professional',
    price: 9,
    trial_type: 'pro_trial', 
    features: ['Unlimited Projects', 'EVM, ES', 'Library', 'Forecasting', 'Simulations', 'Video Support', 'Project Sharing'],
    not_available_features: ['Organization', 'EPS', 'Multiproject Dashboard'],
    buttonLabel: 'Get free trial',
    outline: false,
  },
  {
    header: 'Enterprise',
    price: 19,
    trial_type: 'enterprise_trial',
    features: ['Unlimited Projects', 'EVM, ES', 'Library', 'Forecasting', 'Simulations', 'Premium Support', 'Project Sharing', 'Organization', 'EPS', 'Multiproject Dashboard'],
    not_available_features: [],
    buttonLabel: 'Get free trial',
    outline: false,
  },
]
  