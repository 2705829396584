/* eslint-disable jsx-a11y/anchor-is-valid */
import {FormikProps} from 'formik'
import {CreateSimulation} from '../../../../../app/modules/simulations/core/_models'
import {KTSVG} from '../../../../../_metronic/helpers'

interface Props {
  formik: FormikProps<CreateSimulation>
}
const SimulationForm = ({formik}: Props) => {
  return (
    <div className='current' data-kt-stepper-element='content'>
      <div className='w-100'>
        {/*begin::Form Group */}
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
            <span className='required'>Simulation name</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Specify your unique app name'
            ></i>
          </label>
          <input
            type='text'
            className='form-control form-control-lg form-control-solid'
            placeholder=''
            {...formik.getFieldProps('name')}
          />
          {formik.errors.name && formik.touched.name && (
            <div className='fv-plugins-message-container'>
              <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                {formik.errors.name}
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
            <span className='required'>Description</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Specify your unique app name'
            ></i>
          </label>
          <textarea
            className='form-control form-control-lg form-control-solid'
            rows={4}
            cols={50}
            {...formik.getFieldProps('description')}
            placeholder=''
          ></textarea>
          {formik.errors.description && formik.touched.description && (
            <div className='fv-plugins-message-container'>
              <div data-field='description' data-validator='notEmpty' className='fv-help-block'>
                {formik.errors.description}
              </div>
            </div>
          )}
        </div>
        {/*end::Form Group */}

        {/*begin::Form Group */}
        <div className='fv-row'>
          {/* begin::Label */}
          <label className='d-flex align-items-center fs-5 fw-semibold mb-4'>
            <span className='required'>Category</span>

            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Select your app category'
            ></i>
          </label>
          {/* end::Label */}
          <div>
            {/*begin:Option */}
            <label className='d-flex align-items-center justify-content-between mb-6 cursor-pointer'>
              <span className='d-flex align-items-center me-2'>
                <span className='symbol symbol-50px me-6'>
                  <span className='symbol-label bg-light-primary'>
                    <KTSVG
                      path='/media/icons/duotune/maps/map004.svg'
                      className='svg-icon-1 svg-icon-primary'
                    />
                  </span>
                </span>

                <span className='d-flex flex-column'>
                  <span className='fw-bolder fs-6'>Not Started Project</span>
                  <span className='fs-7 text-muted'>
                    Run simulation for a project that hasn't started yet
                  </span>
                </span>
              </span>

              <span className='form-check form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='appType'
                  value='Started Project'
                  checked={!formik.values.is_start}
                  onClick={() => formik.setFieldValue('is_start', false)}
                  readOnly
                />
              </span>
            </label>
            {/*end::Option */}

            {/*begin:Option */}
            <label className='d-flex align-items-center justify-content-between mb-6 cursor-pointer'>
              <span className='d-flex align-items-center me-2'>
                <span className='symbol symbol-50px me-6'>
                  <span className='symbol-label bg-light-danger'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen024.svg'
                      className='svg-icon-1 svg-icon-danger'
                    />
                  </span>
                </span>

                <span className='d-flex flex-column'>
                  <span className='fw-bolder fs-6'>On Going Project </span>
                  <span className='fs-7 text-muted'>Run Simulations for an on going project</span>
                </span>
              </span>

              <span className='form-check form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='appType'
                  value='On Going Project'
                  checked={formik.values.is_start}
                  onClick={() => formik.setFieldValue('is_start', true)}
                  readOnly
                />
              </span>
            </label>
            {/*end::Option */}
          </div>
        </div>
        {/*end::Form Group */}
      </div>
    </div>
  )
}

export {SimulationForm}
