import UploadField from '../../../../components/uploadField'
import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../../../_metronic/helpers'
import { patchVersion } from '../../core/_requests'
import { sweetAlert } from '../../../../utils/funcs'
import { useFormik } from 'formik'
import { useProject } from '../../core/ProjectContext'
import { getCustomCurve } from '../overview/Project'
import * as Yup from 'yup'
import { getProjectData } from '../../../../utils/project-data'
import { greaterThanOrEqual } from '../../core/ProjectHelpers'
import VirtualBaseline from './VirtualBaseline'
import { getDiffrentBettwenDate } from '../../../../utils/data-transformarion/date-utils'
import { useDispatch } from 'react-redux'
import { updateLabel, updateLoaded, updateShow, updateTotal } from '../../../wbs/treeLoader'
import { combineVersionsV2 } from '../../../wbs/components/TreeView/_helpers'
import { updateWpChangedId, updateIsWpChanged } from '../../../wbs/treedata'
import { setVersionData } from '../../../wbs/treeVersionData'

interface Props {
  show: boolean
  handleClose: () => void
  data: any
  wpId?: string
  setData?: (data: any) => void
}
const ImportData = ({ show, handleClose, data, wpId, setData }: Props) => {
  const { setOriginalVersion, setVersion, project, setWbsDataDateFirstStepAsync, setWbsDataDateSecondStepAsync } = useProject()
  const [loading, setLoading] = useState<boolean>(false)
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      cumulativePlannedValue: getCustomCurve('cumulativePlannedValue', data),
      cumulativeEarnedValue: getCustomCurve('cumulativeEarnedValue', data),
      cumulativeActualCost: getCustomCurve('cumulativeActualCost', data),
      worstCaseBaseline: getCustomCurve('worstCaseBaseline', data) || undefined,
      savedCurve: data?.curve_settings,
    },
    validationSchema: Yup.object().shape({
      cumulativePlannedValue: Yup.array()


        .test('number', 'Planned values must be numbers', (value, context) => {
          return value?.every((el) => !isNaN(+el)) || false
        })
        .test('cum', 'Planned values must be cumulative', (value, context) => {
          return value?.every(greaterThanOrEqual) || false
        }),
      cumulativeActualCost: Yup.array()


        .test('number', 'Actual cost values must be numbers', (value, context) => {
          return value?.every((el) => !isNaN(+el)) || false
        })
        .test('evTest', 'Actual cost values must be cumulative', (value, context) => {
          return value?.every(greaterThanOrEqual) || false
        }),
      cumulativeEarnedValue: Yup.array()


        .test('number', 'Earned values must be numbers', (value, context) => {
          return value?.every((el) => !isNaN(+el)) || false
        })
        .test('evTest', 'Earned values must be cumulative', (value, context) => {
          return value?.every(greaterThanOrEqual) || false
        }),
      worstCaseBaseline: Yup.array()


        .test('number', 'Planned values must be numbers', (value, context) => {
          return value?.every((el) => !isNaN(+el)) || false
        })
        .test('cum', 'Planned values must be cumulative', (value, context) => {
          return value?.every(greaterThanOrEqual) || false
        }),
    }),
    enableReinitialize: true,
    onSubmit: async () => {
      try {
        setLoading(true)
        const newVersion = { ...data, curve_settings: formik.values.savedCurve }
        newVersion.custom_curve = [
          {
            color: 'black',
            name: 'cumulativePlannedValue',
            values: formik.values.cumulativePlannedValue,
          },
          {
            color: 'white',
            name: 'cumulativeEarnedValue',
            values: formik.values.cumulativeEarnedValue,
          },
          {
            color: 'red',
            name: 'cumulativeActualCost',
            values: formik.values.cumulativeActualCost,
          },
          {
            color: 'green',
            name: 'worstCaseBaseline',
            values: formik.values.worstCaseBaseline,
          },
        ]
        const newBudgetAtCompletion = { ...data.budget_at_completion };
        if (
          formik.values.cumulativePlannedValue[formik.values.cumulativePlannedValue.length - 1] !==
          data?.budget_at_completion?.amount
        )
          newBudgetAtCompletion.amount = +formik.values.cumulativePlannedValue[formik.values.cumulativePlannedValue.length - 1];

        let calculatedVersionData: any = { output: {} };
        if (data?.data_date.$date > data?.start_date.$date) {
          dispatch(updateShow(true));
          dispatch(updateLabel('Calculating Data'));
          dispatch(updateTotal(1));
          dispatch(updateLoaded(0));

          calculatedVersionData = await combineVersionsV2(
            data?.name ? data?.name : 'WBS',
            [{ ...data, custom_curve: newVersion.custom_curve }],
            true,
            setWbsDataDateFirstStepAsync,
            setWbsDataDateSecondStepAsync,
          );
          setLoading(false);
          dispatch(updateShow(false));
        }

        delete newBudgetAtCompletion._cls;

        const res = await patchVersion({
          budget_at_completion: newBudgetAtCompletion,
          custom_curve: newVersion.custom_curve,
          curve_settings: newVersion.curve_settings,
          output: calculatedVersionData ? calculatedVersionData.output : data.output,
        }, data._id?.$oid);

        dispatch(setVersionData({ id: res._id.$oid, data: res }));
        setOriginalVersion(res);
        setVersion(res);
        if (setData) {
          setData(res);
        }
        sweetAlert({
          text: 'Data imported and saved successfully',
          icon: 'success',
          title: 'Success',
        }).then(() => {
          if (wpId) {
            dispatch(updateWpChangedId(wpId));
            dispatch(updateIsWpChanged(true));
          }
        });
      } catch (error: any) {
        sweetAlert({
          text: error?.response?.data?.message || error?.message || 'Something went wrong',
          icon: 'error',
          title: 'Error',
        })
      } finally {
        setLoading(false)
        handleClose()
      }
    },
  })

  return (
    <>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-content rounded modal-content-centered mw-900px'
        show={show}
        onHide={() => {
          formik.resetForm()
          handleClose()
        }}
        backdrop={true}
      >
        <div className='modal-header pb-0 border-0 justify-content-end'>
          {/* begin::Close */}
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
          {/* end::Close */}
        </div>
        <div className='modal-body py-lg-10 px-lg-10'>
          <div className='mb-13 text-center'>
            <h1 className='mb-3'>Import Data</h1>
          </div>
          {false ? (
            <div className='fv-row'>
              <label className='fs-6 fw-bolder text-dark mb-7d-flex align-items-center fs-5 fw-semibold mb-4'>
                <span className='required'>Cumulative Planned Value</span>
              </label>
              <UploadField
                index={0}
                durationUnit=''
                setFileContent={(table: any) => {
                  formik.setFieldTouched('cumulativePlannedValue', true)
                  formik.setFieldValue('cumulativePlannedValue', table)
                }}
              />
            </div>
          ) : (
            <VirtualBaseline formik={formik} />
          )}

          <div className='fv-row mb-10'>
            <label className='fs-6 fw-bolder text-dark mb-7d-flex align-items-center fs-5 fw-semibold mb-4'>
              <span>Worst Case Planned Value</span>
            </label>
            <UploadField
              index={0}
              durationUnit=''
              setFileContent={(table: any) => {
                formik.setFieldTouched('worstCaseBaseline', true)
                formik.setFieldValue('worstCaseBaseline', table)
              }}
            />
            {formik.errors.worstCaseBaseline && formik.touched.worstCaseBaseline && (
              <div className='row mt-2'>
                <h4 className='text-danger'>{formik.errors.worstCaseBaseline.toString()}</h4>
              </div>
            )}
          </div>

          {(data?.data_date.$date || 0) > (data?.start_date.$date || 0) && (
            <>
              <div className='fv-row mb-10'>
                <label className='fs-6 fw-bolder text-dark mb-7d-flex align-items-center fs-5 fw-semibold mb-4'>
                  <span className='required'>Cumulative Earned Value</span>
                </label>
                <UploadField
                  index={0}
                  durationUnit=''
                  setFileContent={(table: any) => {
                    formik.setFieldTouched('cumulativeEarnedValue', true)
                    formik.setFieldValue('cumulativeEarnedValue', table)
                  }}
                />
              </div>
              {formik.errors.cumulativeEarnedValue && formik.touched.cumulativeEarnedValue && (
                <div className='row mt-2'>
                  <h4 className='text-danger'>{formik.errors.cumulativeEarnedValue.toString()}</h4>
                </div>
              )}
              <div className='fv-row mb-10'>
                <label className='fs-6 fw-bolder text-dark mb-7d-flex align-items-center fs-5 fw-semibold mb-4'>
                  <span className='required'>Cumulative Actual Cost</span>
                </label>
                <UploadField
                  index={0}
                  durationUnit=''
                  setFileContent={(table: any) => {
                    formik.setFieldTouched('cumulativeActualCost', true)
                    formik.setFieldValue('cumulativeActualCost', table)
                  }}
                />
              </div>
              {formik.errors.cumulativeActualCost && formik.touched.cumulativeActualCost && (
                <div className='row mt-2'>
                  <h4 className='text-danger'>{formik.errors.cumulativeActualCost.toString()}</h4>
                </div>
              )}
            </>
          )}
          <div className='d-flex flex-stack pt-10'>
            <div>
              <button
                type='button'
                className='btn btn-lg btn-primary'
                data-kt-stepper-action='submit'
                onClick={() => {
                  formik.handleSubmit()
                }}
                disabled={!formik.isValid || !formik.dirty || loading}
              >
                {loading ? (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                ) : (
                  <span className='indicator-label'>
                    Add{' '}
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr064.svg'
                      className='svg-icon-3 ms-2 me-0'
                    />
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}
export default ImportData
