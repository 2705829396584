/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import { getFormattedDate } from '../../../../../helpers/DateFormatter'
import { IBudget, getFormattedBudget } from '../../../../../utils/funcs'
import { useProject } from '../../../core/ProjectContext'
import {
  getProjectImageLink,
} from '../../../core/_requests'
import { isMilestoneValid } from '../../overview/milestones/_helpers'
import { getDiffrentBettwenDate } from '../../../../../utils/data-transformarion/date-utils'
import ShowSharedWbsModal from './ShowSharedWbsModal'
export type DateUnit = 'monthly' | 'yearly' | 'daily'
const convertDateUnite = (delayUnit: string) => {
  switch (delayUnit) {
    case 'monthly':
      return 'Months'
    case 'daily':
      return 'Days'
    case 'yearly':
      return 'Years'
    default:
      break
  }
}

type Props = {
  share_type: string
}

const ProjectShareDetailsHeader: FC<Props> = ({share_type}) => {

  const {
    setDataDate,
    originalVersion: data,
    project: projectData,
    displayVersion: newVersion,
    setProject,
  } = useProject()



  const [selectedDataDate, setSelectedDataDate] = useState<Date>(
    new Date(data?.data_date?.$date || 0)
  )
  const [showOverlay, setShowOverlay] = useState(
    false ||
    !newVersion?.milestones?.every((item) =>
      isMilestoneValid(item, new Date(newVersion.data_date.$date))
    )
  )

  const [showWbsModal, setShowWbsModal] = useState(true)
  const handleshowwbs = () => {
    setShowWbsModal(true)
  }



  useEffect(() => {
    if (data) setDataDate(new Date(data.data_date.$date), true)
  }, [data])

  useEffect(() => {
    setShowOverlay(
      !newVersion?.milestones.every((item) =>
        isMilestoneValid(item, new Date(newVersion.data_date.$date))
      )
    )
  }, [newVersion])

  useEffect(() => {
    setSelectedDataDate(new Date(data?.data_date?.$date || 0))
  }, [data?._id?.$oid, data?.data_date?.$date])

  useEffect(() => {
    setSelectedDataDate(new Date(newVersion?.data_date.$date || 0))
  }, [newVersion?.data_date.$date])




  const dataDateIndex =
    getDiffrentBettwenDate(
      newVersion?.data_date.$date || 0,
      newVersion?.start_date.$date || 0,
      newVersion?.period_count.type || 'month'
    ) || 0



  return (
    <>
      <div className='card mb-5 mt-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='me-7 mb-4'>
              <div className='d-flex flex-center flex-shrink-0 bg-light rounded w-100px h-100px w-lg-150px h-lg-150px me-7 mb-4'>
                <img
                  src={
                    projectData?.image
                      ? getProjectImageLink(projectData?._id?.$oid || '')
                      : toAbsoluteUrl('/media/logos/default-small.svg')
                  }
                  onError={(e) =>
                  ((e.target as HTMLImageElement).src = toAbsoluteUrl(
                    '/media/logos/default-small.svg'
                  ))
                  }
                  alt='profile'
                  className='mw-100px mw-lg-150px mh-100px mh-lg-150px rounded'
                  style={{ height: '150px', width: '150px' }}
                />
              </div>
            </div>
            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between'>
                <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                  <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center mb-2'>
                      <div className='text-gray-800 fs-2 fw-bolder me-1'>
                        {projectData?.name}
                      </div>
                      <div className='btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3'>
                        {projectData?.status}
                      </div>
                    </div>

                    <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                      <a
                        href='#'
                        className='d-flex align-items-center text-gray-400 mb-2'
                      >
                        {projectData?.description}
                      </a>
                    </div>
                  </div>
                </div>
                <button type='button' className='btn btn-light' onClick={() => handleshowwbs()}>
                  Show WBS Tree
                </button>
              </div>
              <ShowSharedWbsModal
                onHide={() => { setShowWbsModal(false) }}
                show={showWbsModal}
                share_type={share_type}
              />
              <div className='d-flex flex-wrap'>
                <div className='d-flex flex-column pe-8'>
                  <div className='d-flex flex-wrap'>
                    <div
                      className='border border-dashed border-primary border-300 rounded min-w-125px py-3 px-4 me-6 mb-3'
                    >
                      <div className='d-flex align-items-center'>
                        <div className='fs-2 fw-bolder text-primary'>
                          {new Date(data?.start_date.$date || 0).getTime() <=
                            selectedDataDate.getTime()
                            ? getFormattedDate(
                              selectedDataDate,
                              data?.period_count?.type as DateUnit,
                              data?.date_format
                            )
                            : 'Not Started'}
                        </div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>Data Date</div>
                    </div>
                  </div>
                </div>
                <div className='d-flex flex-column'>
                  <div className='d-flex flex-wrap'>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <div className='fs-2 fw-bolder'>
                          {dataDateIndex >= 0
                            ? (dataDateIndex || 0) +
                            1 +
                            ' ' +
                            (convertDateUnite(newVersion?.delay_tolerance?.type || 'monthly') ||
                              '')
                            : 'Not started'}
                        </div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400 text-nowrap'>Actual Duration</div>
                    </div>
                  </div>
                </div>
                <div className='d-flex flex-column'>
                  <div className='d-flex flex-wrap'>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <div className='fs-2 fw-bolder'>
                          {getFormattedDate(
                            new Date(data?.start_date?.$date || 0),
                            data?.period_count?.type as DateUnit,
                            data?.date_format
                          )}
                        </div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>Start Date</div>
                    </div>
                  </div>
                </div>
                <div className='d-flex flex-column'>
                  <div className='d-flex flex-wrap'>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <div className='fs-2 fw-bolder'>
                          {(getDiffrentBettwenDate(
                            data?.end_date.$date || 0,
                            data?.start_date.$date || 0,
                            data?.delay_tolerance?.type || 'monthly'
                          ) || 0) + 1}{' '}
                          {convertDateUnite(data?.delay_tolerance?.type || 'monthly')}
                        </div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400 text-nowrap'>Planned Duration</div>
                    </div>
                  </div>
                </div>
                <div className='d-flex flex-column'>
                  <div className='d-flex flex-wrap'>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <div className='fs-2 fw-bolder'>
                          {getFormattedDate(
                            new Date(data?.end_date?.$date || 0),
                            data?.period_count?.type as DateUnit,
                            data?.date_format
                          )}
                        </div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>Finish Date</div>
                    </div>
                  </div>
                </div>
                <div className='d-flex flex-column'>
                  <div className='d-flex flex-wrap'>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <div className='fs-2 fw-bolder'>
                          {(data?.delay_tolerance?.count || '0') +
                            ' ' +
                            (convertDateUnite(newVersion?.delay_tolerance?.type || 'monthly') ||
                              '')}
                        </div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400 text-nowrap'>Delay Tolerance</div>
                    </div>
                  </div>
                </div>
                <div className='d-flex flex-column'>
                  <div className='d-flex flex-wrap'>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <div className='fs-2 fw-bolder text-nowrap'>
                          {getFormattedBudget(
                            {
                              ...data?.budget_at_completion,
                              floatFormater: data?.float_formatter,
                            } as IBudget,
                            true
                          )}
                        </div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400 text-nowrap'>
                        Budget at completion
                      </div>
                    </div>
                  </div>
                </div>
                <div className='d-flex flex-column'>
                  <div className='d-flex flex-wrap'>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <div className='fs-2 fw-bolder text-nowrap'>
                          {getFormattedBudget(
                            {
                              ...data?.budget_at_completion,
                              amount: data?.reserve?.amount,
                              floatFormater: data?.float_formatter,
                            } as IBudget,
                            true
                          )}
                        </div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>Reserve</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { ProjectShareDetailsHeader }
