import axios, {AxiosResponse} from 'axios'
import { Comment } from './models'

const API_URL_COMMENT = `${process.env.REACT_APP_API_URL}/comments`   
const API_URL_COMMENT_WBS=`${process.env.REACT_APP_API_URL}/comments-by-wbs`   
export const getComments = () =>
    axios.get(`${API_URL_COMMENT}`).then((res: AxiosResponse<Comment[]>) => res.data)
  
export const getComment = (commentId?: string) =>
    axios
      .get(`${API_URL_COMMENT}${commentId ? `/${commentId}` : ''}`)
      .then((res: AxiosResponse<Comment>) => res.data)

export const getCommentbywbs = (wbsId?: string) =>
  axios
    .get(`${API_URL_COMMENT_WBS}${wbsId ? `/${wbsId}` : ''}`)
    .then((res: AxiosResponse<Comment[]>) => res.data)
      
export const patchComment = (commentParams: {subject: string; comment?: string,commenter?:string ,wbs?:string,concerned_data_date?:Date,shared_with_wbs:string[],comment_date?:Date}) =>
    axios.post(`${API_URL_COMMENT}`, commentParams).then((res: AxiosResponse<Comment>) => res.data)


export const patchCommentById = (
    commentId: string,
    commentParams: {subject?: string; comment?: string;commenter?:string;shared_with_wbs?:string[],concerned_data_date?:Date, comment_date?:Date,associated_actions?:string[]}
  ) =>
    axios
      .patch(`${API_URL_COMMENT}/${commentId}`, commentParams)
      .then((res: AxiosResponse<Comment>) => res.data)

export const deleteComment = (commentId: string) => axios.delete(`${API_URL_COMMENT}/${commentId}`)



export const transformToFolder = (data: any,knownWbs:any) => {
    const buildChildren = (items: any) => {
      return items?.filter((item: any) => knownWbs[item?.$oid].data)
              ?.map((item: any) => ({
        id: item?.$oid,
        name: knownWbs[item?.$oid]?.name,
        children: knownWbs[item?.$oid]?.subwbs ? buildChildren(knownWbs[item?.$oid]?.subwbs) : []
      }));
    };
    const root={
      id:"1",
      name:"root",
      children:[{
      id: data?._id?.$oid,
      name: data?.name,
      children: buildChildren(data?.subwbs)
      }]
    }
    return root;
  };

export const fetchComments = async () => {
    return await getComments();
  };