import FileSaver from 'file-saver'
import * as xlsx from 'xlsx'
export const handleDownloadTemplate = (data: any, fileName: string) => {
  const worksheet = xlsx.utils.json_to_sheet(data)

  const workbook = xlsx.utils.book_new()
  xlsx.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
  const excelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'})
  const blob = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })
  FileSaver.saveAs(blob, fileName)
}
