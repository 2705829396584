import {Calendar} from '../../../calendars/_models'
import moment from 'moment'
import {getCellColor} from '../../../calendars/AddCalendar/AddCalendarHelpers'
import dayjs from 'dayjs'
import {getHolidays} from '../../../../helpers/HolidayService'
import {calculateSigmoidOutputs} from '../../../../helpers/func'
import {getDeepClone} from '../../../../helpers/data-updaters'
import {calculateCumulativeFromPeriodic} from '../../../../helpers/optimizers'
import {getPeriodicFromCumulative} from '../../../projects/components/data/prepareDataTable'

const getYearsBetween = (start: Date, end: Date): number[] => {
  const years: number[] = []
  let currentYear = start.getFullYear()

  while (currentYear <= end.getFullYear()) {
    years.push(currentYear)
    currentYear++
  }

  return years
}

export const getReelDays = async (start_date: Date, duration: number, calendar: Calendar) => {
  const currentMonth = moment(start_date).startOf('month')
  const lastMonth = currentMonth.clone().add(duration - 1, 'month')
  const holidays = await getHolidays(
    getYearsBetween(currentMonth.toDate(), lastMonth.toDate()),
    calendar.holiday?.country_code
  )
  const filteredHolidays = holidays.filter(
    (item) => !calendar.holiday?.exceptions.find((exception) => exception === item.name)
  )
  const totalDays: number[] = []
  const result: number[] = []
  while (currentMonth.isSameOrBefore(lastMonth)) {
    const startOfMonth = currentMonth.clone().startOf('month')
    const endOfMonth = currentMonth.clone().endOf('month')
    const daysInMonth = endOfMonth.diff(startOfMonth, 'days') + 1
    let count = 0
    for (let day = 0; day < daysInMonth; day++) {
      const currentDay = startOfMonth.clone().add(day, 'days')
      let working = getCellColor(
        dayjs(currentDay.toDate()),
        calendar.exceptions.map((item: any) => ({...item, date: new Date(item.date.$date)})),
        calendar.week_days,
        calendar.recurrent_dates,
        filteredHolidays
      ).working
      if (!working) count++
    }
    totalDays.push(daysInMonth)
    result.push(count)
    currentMonth.add(1, 'month')
  }
  return {totalDays, reelDays: totalDays.map((item, index) => item - result[index])}
}

export const getCapacity = (reelDays: number[], totalDays: number[]) => {
  if (!reelDays.length || !totalDays.length) return []
  const totalDaysAverage = totalDays.reduce((a: number, b: number) => a + b) / totalDays.length
  return reelDays.map((item) => item / totalDaysAverage || 0.001)
}

// Apply a calendar to a curve
export const applyCalendarToCurve = (
  capacity: number[],
  result: ReturnType<typeof calculateSigmoidOutputs>
) => {
  const ponderatedCapacity = result.tableData[2].map((item, index) => capacity[index] * +item)
  const ponderatedSum = ponderatedCapacity.reduce((acc, curr) => acc + curr, 0)
  const newResult: ReturnType<typeof calculateSigmoidOutputs> = getDeepClone(result)
  newResult.tableData[2] = ponderatedCapacity.map((item) =>
    ((item * 100) / ponderatedSum).toString()
  )
  newResult.tableData[1] = calculateCumulativeFromPeriodic(newResult.tableData[2])
  return newResult
}

export const applyCalendarToIncompleteCurve = (
  capacity: number[],
  curve: number[],
  cumulativeEarnedVaues: number[]
) => {
  const periodic = getPeriodicFromCumulative(curve)
  const remainingTodo = 100 - cumulativeEarnedVaues[cumulativeEarnedVaues.length - 1]
  periodic[0] = periodic[0] - cumulativeEarnedVaues[cumulativeEarnedVaues.length - 1]
  const ponderatedCapacity = periodic.map((item, index) => capacity[index] * item)
  const ponderatedSum = ponderatedCapacity.reduce((acc, curr) => acc + curr, 0)
  const newPonderated = ponderatedCapacity.map((item) => (item / ponderatedSum) * remainingTodo)
  const newCumulative = calculateCumulativeFromPeriodic(
    newPonderated.map((item, index) =>
      index === 0 ? item + cumulativeEarnedVaues[cumulativeEarnedVaues.length - 1] : item
    )
  )
  return {tableData: [[], newCumulative.map((item) => +item), newPonderated]}
}

// Strip a curve from a calendar
export const stripCalendarFromCurve = (capacity: number[], curve: number[]) => {
  const periodicValues = getPeriodicFromCumulative(curve)
  const dividedByCapacity = periodicValues.map((item: any, index) => item / capacity[index])
  const dividedSum = dividedByCapacity.reduce((acc, curr) => acc + curr, 0)
  const nonCalendarPeriodic = dividedByCapacity.map((item) => (item * 100) / dividedSum)
  const nonCalendarCumulative = calculateCumulativeFromPeriodic(nonCalendarPeriodic)
  return nonCalendarCumulative.map((item) => +item)
}
