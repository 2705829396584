import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Row, Col } from 'react-bootstrap';
import EmptyValuesCard from './emptyValuesCard';

const UnauthorizedPage: React.FC = () => {
  const navigate = useNavigate();

  const handleUpgradePlan = () => {
    navigate('/profile/billing');
  };

  const handleBackToDashboard = () => {
    navigate('/dashboard');
  };

  return (

    <>
      <div className='row mb-5 mt-5'>
      </div>
      <div className='row g-6 g-xl-9'>
      <div className='card'>
      <div className={'card-body py-20'}>
        <div className='card-px text-center  '>
          <h2 className='fs-2x fw-bold mb-10'>Oops! Looks like you stumbled on a feature that is not included in your current offer</h2>
          <p className='text-gray-400 fs-4 fw-semibold mb-10'>Please upgrade if you want to use this feature</p>
          <div>
          <Button variant="primary" onClick={handleUpgradePlan} className="m-2">
            Upgrade Plan
           </Button>
           <Button variant="secondary" onClick={handleBackToDashboard} className="m-2">
             Back to Dashboard
           </Button>
           </div>
          {/* {setShowCreateAppModal && (
            <button onClick={() => setShowCreateAppModal(true)} className='btn btn-primary'>
              {buttonText}
            </button>
          )} */}
        </div>
        <div className='text-center '>
          <img className='mw-100 mh-300px' src='/media/illustrations/sketchy-1/6.png' />
        </div>
      </div>
    </div>
      </div>
      </>
    // <Container className="text-center mt-5">
    //   <Row>
    //     <Col>
    //       <h1>Unauthorized</h1>
    //       <p>You do not have permission to view this page.</p>
    //       <Button variant="primary" onClick={handleUpgradePlan} className="m-2">
    //         Upgrade Plan
    //       </Button>
    //       <Button variant="secondary" onClick={handleBackToDashboard} className="m-2">
    //         Back to Dashboard
    //       </Button>
    //     </Col>
    //   </Row>
    // </Container>
  );
};

export default UnauthorizedPage;
