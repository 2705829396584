import {DatePicker} from 'antd'
import dayjs from 'dayjs'
import {useIntl} from 'react-intl'
import Input from '../../../../components/Input/Input'
import {useAuth} from '../../../auth'
import {getDateFormat} from '../../core/SimulationHelpers'
import {useSimulationContext} from '../SimulationContext'
import {TypeOfCalculate} from '../_models'
import {useEffect} from 'react'

type Props = {}

export default function ProjectStartedForm({}: Props) {
  const {currentUser} = useAuth()
  const {formik, typeOfCalculate, setTypeOfCalculate} = useSimulationContext()
  const intl = useIntl()

  return (
    <>
      <div className='row'>
        <label className='col-lg-12 col-form-label required fw-bold fs-6'>
          {intl.formatMessage({id: 'SIMULATION.DATA_DATE'})}
        </label>
      </div>
      <DatePicker
        picker={formik?.values.period_count.type === 'daily' ? 'date' : 'month'}
        value={dayjs(formik?.values.data_date)}
        className='form-control form-control-lg form-control-solid'
        onChange={(date, dateString) => formik?.setFieldValue('data_date', date?.toDate())}
        clearIcon={<></>}
        suffixIcon={<></>}
        format={getDateFormat(
          formik?.values.period_count.type === 'monthly' ? 'months' : 'days',
          currentUser?.date_format
        )}
      />
      <Input
        formikProps={formik}
        name={'achieved_percentage'}
        labelText={intl.formatMessage({id: 'SIMULATION.POURCENTAGE_COMPLETE'})}
        placeholder={`Nombre en pourcentage`}
        type='number'
        max={100}
        min={1}
        error={formik?.errors.achieved_percentage as string}
        isTouched={formik?.touched.achieved_percentage as boolean}
      />
      <div className='btn-group mb-5 btn-group-vertical' role='group'>
        <input
          type='radio'
          className='btn-check'
          name='typeOfCalculate'
          id='end_date'
          onChange={() => {

            setTypeOfCalculate(TypeOfCalculate.CALC_END_DATE)
          }}
          checked={typeOfCalculate === TypeOfCalculate.CALC_END_DATE}
        />
        <label
          onClick={() => setTypeOfCalculate(TypeOfCalculate.CALC_END_DATE)}
          className='btn btn-outline-primary rounded-top border border-bottom-0 border-2'
          htmlFor='end_date'
        >
          Calculate End Date
        </label>
        <input
          readOnly
          type='radio'
          className='btn-check'
          name='typeOfCalculate'
          id='fixed_end'
          onClick={() => {
            setTypeOfCalculate(TypeOfCalculate.FIXED_END_DATE)
          }}
          checked={typeOfCalculate === TypeOfCalculate.FIXED_END_DATE}
        />
        <label
          onClick={() => setTypeOfCalculate(TypeOfCalculate.FIXED_END_DATE)}
          className='btn btn-outline-primary rounded-bottom border border-top-0 border-2'
          htmlFor='fixed_end'
        >
          Calculate Remaining To Do (w/ fixed finish date)
        </label>
      </div>
      <h5>
        {typeOfCalculate === TypeOfCalculate.CALC_END_DATE
          ? `Number of passed ${formik?.values.period_count.type === 'monthly' ? 'months' : 'days'}`
          : 'End Date'}
      </h5>
      <div className='btn-group mb-5' role='group'>
        <input
          readOnly
          type='radio'
          className='btn-check'
          name='type'
          id='new_days'
          onClick={() => formik?.setFieldValue('period_count.type', 'daily')}
          checked={formik?.values.period_count.type === 'daily'}
        />
        <label className='btn btn-outline-primary' htmlFor='new_days'>
          Days
        </label>
        <input
          readOnly
          type='radio'
          className='btn-check'
          name='type'
          id='new_months'
          onClick={() => formik?.setFieldValue('period_count.type', 'monthly')}
          checked={formik?.values.period_count.type === 'monthly'}
        />
        <label className='btn btn-outline-primary' htmlFor='new_months'>
          Months
        </label>
      </div>

      {typeOfCalculate === TypeOfCalculate.CALC_END_DATE ? (
        <>
          {
            <>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder={'Natural Number'}
                value={formik?.values.period_count.count}
                onChange={(e) => {
                  formik?.setFieldValue('period_count.count', e.target.value)
                }}
                onBlur={formik?.handleBlur('period_count.count')}
              />
              {formik?.errors.period_count?.count && formik.errors.period_count.count && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik?.errors.period_count?.count}</div>
                </div>
              )}
            </>
          }
        </>
      ) : (
        <>
          <DatePicker
            name='enteredEndDate'
            className='form-control form-control-lg form-control-solid'
            onChange={(date, dateString) => {
              formik?.setFieldValue(
                'end_date',
                formik.values.period_count.type === 'monthly'
                  ? date?.format('YYYY-MM')
                  : date?.toDate()
              )
            }}
            disabledDate={(current) =>
              current.toDate().getTime() < (formik?.values.data_date.getTime() || 0)
            }
            value={dayjs(formik?.values.end_date)}
            picker={formik?.values.period_count.type === 'daily' ? 'date' : 'month'}
            clearIcon={<></>}
            suffixIcon={<></>}
            format={getDateFormat(
              formik?.values.period_count.type === 'monthly' ? 'months' : 'days',
              currentUser?.date_format
            )}
          />
        </>
      )}
    </>
  )
}
