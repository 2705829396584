/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Dispatch, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getTableColumns } from './prepareDataTable'
import './Data.scss'
import { DateFormatType } from '../../../../utils/funcs'
import ImportData from './ImportData'
import '../settings/Date.scss'
import { getLabels, getMaxArrayLength, normalizeDateUnit } from '../../../../helpers/DateFormatter'
import EarnedValueTable from './EarnedValueTable'
import PlannedValueTable from './PlannedValueTable'
import ActualCostTable from './ActualCostTable'
import DateInputs from './DateInputs'
import { useProject } from '../../core/ProjectContext'
import { checkCompleteData } from '../../core/ProjectHelpers'
import CompleteData from './CompleteData'
import { getCustomCurve } from '../overview/Project'
import { Card } from 'react-bootstrap'
import WorstCastBaseline from './WorstCastBaseline'
import { RootState } from '../../../../store'
import { useSelector } from 'react-redux'


interface Props {
  setVersionId: Dispatch<React.SetStateAction<string | undefined>>
}

export function Data({ setVersionId }: Props) {
  const { originalVersion: data, project } = useProject()
  const location = useLocation()
  const [dataTable, setDataTable] = useState<any>(null)
  const [columns, setColumns] = useState<any[]>([])
  const [showModal, setShowModal] = useState(false)
  const [isEditable, setIsEditable] = useState(false)
  const knownWbs = useSelector((state: RootState) => state.treedata.knownWbs);
  const [wbsId, setWbsId] = useState<string>(project?.current_wbs)




  useEffect(() => {
    const last_opened_wbs = knownWbs[project?.current_wbs]?.last_opened_wbs?.$oid
    if (last_opened_wbs) {
      const latestOpenWbs = knownWbs[last_opened_wbs]
      const isWp = latestOpenWbs && latestOpenWbs.subwbs && latestOpenWbs.subwbs.length === 0
      setWbsId(last_opened_wbs)
      setIsEditable(isWp)
    }
  }, [project?.current_wbs, knownWbs])

  useEffect(() => {
    const dataTableFirst: any = location.state
    setDataTable(location.state)
    setColumns(getTableColumns(data?.output))
  }, [])

  const generatedLabelsLength = getMaxArrayLength(
    dataTable?.plannedValues?.original.cumulative,
    dataTable?.earnedValues?.mixed?.cumulative,
    dataTable?.actualCosts?.mixed?.cumulative,
    getCustomCurve('cumulativePlannedValue', data)
  )

  const normalizedDateUnit = normalizeDateUnit(data?.period_count?.type)

  const labels = getLabels(
    data?.start_date?.$date || 0,
    generatedLabelsLength ||
    data?.custom_curve?.find((item: any) => item.name === 'cumulativePlannedValue')?.values
      .length ||
    0,
    normalizedDateUnit,
    data?.date_format as DateFormatType
  )

  const dataDateIndex = labels.findIndex(
    (value: any) =>
      value ===
      getLabels(
        data?.data_date?.$date || 0,
        1,
        normalizedDateUnit,
        data?.date_format as DateFormatType
      )[0]
  )
  return (
    <>
      <Card className='mb-5'>
        <Card.Header>
          <Card.Title>Project Duration</Card.Title>
        </Card.Header>
        <Card.Body>
          <DateInputs wpId={wbsId} setVersionId={setVersionId} data={data} isEditable={isEditable} />
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>
          <Card.Title>Project data</Card.Title>
        </Card.Header>
        <Card.Body>
          {checkCompleteData(new Date(data?.data_date.$date || 0), data) ? (
            <>
              <PlannedValueTable wpId={wbsId} data={data} labels={labels} isEditable={isEditable} />
              <EarnedValueTable wpId={wbsId} data={data} dataDateIndex={dataDateIndex} labels={labels} isEditable={isEditable} />
              <ActualCostTable wpId={wbsId} data={data} dataDateIndex={dataDateIndex} labels={labels} isEditable={isEditable} />
              {!!getCustomCurve('worstCaseBaseline', data) &&
                !!getCustomCurve('worstCaseBaseline', data).length && (
                  <WorstCastBaseline labels={labels} />
                )}
            </>
          ) : (
            <CompleteData data={data} />
          )}
          <ImportData
            show={showModal}
            handleClose={() => {
              setShowModal(false)
            }}
            data={data}
          />
        </Card.Body>
      </Card>
    </>
  )
}
