/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC, useMemo, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useNotification} from '../../../../app/modules/notifications/NotificationContext'
import {EventType, NotificationEvent} from '../../../../app/modules/notifications/_models'
import {DIVISIONS} from '../../widgets'
import {clearNotifications} from '../../../../app/modules/notifications/_requests'

const HeaderNotificationsMenu: FC = () => {
  function formatTimeAgo(date: any) {
    let transformedDate = new Date(date)
    let duration = (transformedDate.getTime() - new Date().getTime()) / 1000

    for (let i = 0; i < DIVISIONS.length; i++) {
      const division = DIVISIONS[i]
      if (Math.abs(duration) < division.amount) {
        return formatter.format(Math.round(duration), division.name as Intl.RelativeTimeFormatUnit)
      }
      duration /= division.amount
    }
  }
  const formatter = new Intl.RelativeTimeFormat('en', {numeric: 'auto'})
  const {notifications: volativeNotifications, setNotifications} = useNotification()
  const [filter, setFilter] = useState<EventType | undefined>()
  const [loading, setLoading] = useState<boolean>(false)
  const notifications = useMemo(
    () =>
      volativeNotifications.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()),
    [volativeNotifications]
  )
  const filteredNotifications = notifications.filter(
    (item: NotificationEvent) => !filter || item.type === filter
  )
  const handleClearNotifications = () => {
    setLoading(true)
    clearNotifications()
      .then((res) => setNotifications(res.socket.all_events || []))
      .finally(() => setLoading(false))
  }
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
      data-kt-menu='true'
    >
      <div
        className='d-flex flex-column bgi-no-repeat rounded-top'
        style={{backgroundImage: `url('${toAbsoluteUrl('/media/misc/menu-header-bg.jpg')}')`}}
      >
        <h3 className='text-white fw-bold px-9 mt-10 mb-6'>Notifications</h3>

        <ul className='nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-bold px-9'>
          <li className='nav-item'>
            <a
              onClick={() => setFilter(undefined)}
              className={clsx(
                'nav-link text-white opacity-75 opacity-state-100 pb-4 cursor-pointer',
                {
                  active: !filter,
                }
              )}
            >
              All
            </a>
          </li>

          <li className='nav-item'>
            <a
              onClick={() => setFilter(EventType.GRAPH)}
              className={clsx(
                'nav-link text-white opacity-75 opacity-state-100 pb-4 cursor-pointer',
                {
                  active: filter === EventType.GRAPH,
                }
              )}
            >
              Graph
            </a>
          </li>

          <li className='nav-item'>
            <a
              onClick={() => setFilter(EventType.TEAM)}
              className={clsx(
                'nav-link text-white opacity-75 opacity-state-100 pb-4 cursor-pointer',
                {
                  active: filter === EventType.TEAM,
                }
              )}
            >
              Team
            </a>
          </li>

          <li className='nav-item'>
            <a
              onClick={() => setFilter(EventType.SUBSCRIPTION)}
              className={clsx(
                'nav-link text-white opacity-75 opacity-state-100 pb-4 cursor-pointer',
                {
                  active: filter === EventType.SUBSCRIPTION,
                }
              )}
            >
              Subscription
            </a>
          </li>
        </ul>
      </div>

      <div>
        <div className='tab-pane'>
          <div className='scroll-y mh-325px my-5 px-8'>
            {!filteredNotifications.length && (
              <div className='d-flex flex-column px-9'>
                <div className='pt-10 pb-0'>
                  <h3 className='text-dark text-center fw-bolder'>No Notifications</h3>

                  <div className='text-center text-gray-600 fw-bold pt-1'>
                    {filter ? 'No notifications for this filter' : 'You have no notifications'}
                  </div>
                </div>

                <div className='text-center px-4'>
                  <img
                    className='mw-100 mh-200px'
                    alt='metronic'
                    src={toAbsoluteUrl('/media/illustrations/sketchy-1/1.png')}
                  />
                </div>
              </div>
            )}
            {filteredNotifications.map((alert: NotificationEvent, index: number) => (
              <div key={index} className='d-flex flex-stack py-4'>
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-35px me-4'>
                    <span className={clsx('symbol-label', `bg-light-primary}`)}>
                      {' '}
                      <KTSVG
                        path={`/media/icons/duotune/technology/teh008.svg`}
                        className={`svg-icon-2 svg-icon-${
                          alert.type === EventType.SUBSCRIPTION ? 'warning' : 'primary'
                        }`}
                      />
                    </span>
                  </div>

                  <div className='mb-0 me-2'>
                    <a href='#' className='fs-6 text-gray-800 text-hover-primary fw-bolder'>
                      {alert.sender}
                    </a>
                    <div className='text-gray-400 fs-7'>{alert.message}</div>
                  </div>
                </div>

                <span className='badge badge-light fs-8'>{formatTimeAgo(alert.date)}</span>
                {/* <span className='badge badge-light fs-8'>{alert.time}</span> */}
              </div>
            ))}
          </div>

          <div className='py-3 text-center border-top'>
            {loading ? (
              <span className='indicator-progress text-muted'>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            ) : (
              <button
                disabled={loading || !notifications.length}
                onClick={handleClearNotifications}
                className='btn btn-color-gray-600 btn-active-color-primary'
              >
                Clear Notifications <i className='bi bi-x-lg'></i>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export {HeaderNotificationsMenu}
