/* eslint-disable jsx-a11y/anchor-is-valid */

import clsx from 'clsx'
import {useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {useLayout} from '../../../../_metronic/layout/core'
import {Dropdown1} from '../../../../_metronic/partials'
import {ProjectStepper} from '../../../../_metronic/partials/modals/project-stepper/ProjectStepper'
import {useAuth} from '../../auth'
import {ProjectFilter} from './_models'
import { Button, Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

const projectFilters: any = Object.keys(ProjectFilter).map((key) => ({
  label: (ProjectFilter as any)[key],
  value: (ProjectFilter as any)[key],
}))
interface ToolbarProjectCustomProps {
  setFilter: any
  showCreateAppModalExternal?: boolean
  setShowCreateAppModalExternal?: any
  filter: ProjectFilter
  parentFolder?: string
}
const ToolbarProjectCustom = ({
  filter,
  setFilter,
  showCreateAppModalExternal,
  setShowCreateAppModalExternal,
  parentFolder,
}: ToolbarProjectCustomProps) => {
  const {config} = useLayout()
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  const {areActionsDisabled} = useAuth()
  const {currentPlanLevel} = useAuth()
  const {currentUser} = useAuth()
  const navigate = useNavigate();
  const [showLimitExceededModal, setShowLimitExceededModal] = useState(false);

  const handleUpgradeClick = () => {
    navigate('/profile/billing');
  };
   
  
  const daterangepickerButtonClass = config.app?.toolbar?.fixed?.desktop
    ? 'btn-light'
    : 'bg-body btn-color-gray-700 btn-active-color-primary'

  return (
    <div className='d-flex align-items-center gap-2 gap-lg-3'>
      {config.app?.toolbar?.filterButton && (
        <div className='m-0'>
          <a
            href='#'
            className={clsx('btn btn-sm btn-flex fw-bold', daterangepickerButtonClass)}
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen031.svg'
              className={`svg-icon-6 svg-icon-muted me-1 ${filter && 'text-success'}`}
            />
            Filter
          </a>
          <Dropdown1 filters={projectFilters} filter={filter} setFilter={setFilter} />
        </div>
      )}

      {config.app?.toolbar?.daterangepickerButton && (
        <div
          data-kt-daterangepicker='true'
          data-kt-daterangepicker-opens='left'
          className={clsx(
            'btn btn-sm fw-bold  d-flex align-items-center px-4',
            daterangepickerButtonClass
          )}
        >
          <div className='text-gray-600 fw-bold'>Loading date range...</div>
          <KTSVG path='/media/icons/duotune/general/gen014.svg' className='svg-icon-1 ms-2 me-0' />
        </div>
      )}

      {config.app?.toolbar?.secondaryButton && (
        <a href='#' className='btn btn-sm btn-flex btn-light fw-bold'>
          Filter
        </a>
      )}

      {config.app?.toolbar?.primaryButton && (
        <a
          href='#'
          onClick={() => {
            // if (currentPlanLevel == 1 && currentUser?.slots! < 1) setShowLimitExceededModal(true)
            // else
             setShowCreateAppModal(true)
          }}
          className='btn btn-sm fw-bold btn-primary'
          data-bs-toggle='modal'
          data-bs-target='#kt_modal_create_app'
        >
          New Project
        </a>
      )}
      {2 > 1 && (
        <ProjectStepper
        parentFolder={parentFolder}
        show={showCreateAppModalExternal || showCreateAppModal}
        handleClose={() =>
          showCreateAppModalExternal
            ? setShowCreateAppModalExternal(false)
            : setShowCreateAppModal(false)
        }
      />)}
      {(currentPlanLevel === 1 && currentUser?.slots! > 0) &&
      (<ProjectStepper
        parentFolder={parentFolder}
        show={showCreateAppModalExternal || showCreateAppModal}
        handleClose={() =>
          showCreateAppModalExternal
            ? setShowCreateAppModalExternal(false)
            : setShowCreateAppModal(false)
        }
      />) }

      <Modal show={showLimitExceededModal} onHide={() => setShowLimitExceededModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Limit Exceeded</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You have exceeded the project limit for the free plan. Please upgrade to add more projects.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowLimitExceededModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpgradeClick}>
            Upgrade Plan
          </Button>
        </Modal.Footer>
      </Modal>
      
      
    </div>
  )
}

export {ToolbarProjectCustom}
