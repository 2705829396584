import {useFormik} from 'formik'
import {achieved, Milestone, MilestoneValidationSchema} from './_models'
import { getMilestoneValidationSchema} from './_models'
import {format} from 'date-fns'
import {getFNSDateFormat} from '../../../../helpers/DateFormatter'
import {useEffect, useState} from 'react'
import {
  // createEditMilestoneObject,
  getMilestoneBadgeColor,
  // handleDeleteMilestone,
  // handleEditMilestone,
  // prepareMilestoneDates,
} from './_utils'
import {useProject} from '../../core/ProjectContext'
import {showError, sweetAlert} from '../../../../utils/funcs'
import {DatePicker} from 'antd'
import {getDateFormat} from '../../../simulations/core/SimulationHelpers'
import dayjs from 'dayjs'
import clsx from 'clsx'
 
import { deleteMilestone, patchMilestone } from './requests'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../store'
import { KTSVG } from '../../../../../_metronic/helpers'
import TreeModal from './treeModal'
import { adding, done } from '../comments/CommentsState'

type Props = {
  milestones: Milestone[]
  milestone: Milestone
  dateFormat: string
}

export default function EditMilestone({dateFormat, milestone, milestones}: Props) {
  const {displayVersion, setVersion, setOriginalVersion} = useProject()
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const knownWbs = useSelector((state: RootState) => state.treedata.knownWbs);
  const [showtree,setShowtree]=useState<boolean>(false)
  const dispatch=useDispatch()
  const formik = useFormik<Milestone>({
    initialValues:milestone,
    onSubmit: (values) => {
      if (!formik.dirty) return setIsEdit(false)
        const baseline=new Date(values.baseline_date.$date)
      const realdate=formik.values.real_dates!==null ? new Date(values.real_dates.$date): null  
      if (real_dates && isValidDate(real_dates)){
       formik.values.achieved=achieved.ACHIEVED
      }
      setLoading(true)
      dispatch(adding())
      setIsEdit(false)
      patchMilestone(milestone._id.$oid,{subject:formik.values.subject,achieved:formik.values.achieved,name:formik.values.name,baseline_date:baseline,real_dates:realdate})
        .then((res) => {
          sweetAlert({
            text: 'Milestone updated successfully',
            title: 'Success',
            icon: 'success',
          })
        })
        .catch((err) =>
          sweetAlert({
            title: 'Error',
            text: err?.response?.data?.message || 'Something went wrong',
            icon: 'error',
          })
        )
        .finally(() => {setIsEdit(false);setLoading(false);dispatch(done())})
      
    },
  })
  const [baseline_date,setBaseline_date]=useState<any>()
  const [real_dates,setReal_date]=useState<any>()
  const [forcast_date,setForcast_date]=useState<any>()
  function formateddata(date:any){
    
    const data = new Date(date);

    const month = (data.getMonth() + 1).toString().padStart(2, '0'); // getMonth() is zero-based
    const day = data.getDate().toString().padStart(2, '0');
    const year = data.getFullYear().toString().slice(-2); // get the last two digits of the year
    
    const formattedDate = `${month}/${day}/${year}`; 
    return formattedDate
  }
  useEffect(()=>{
    const baseline_date =formateddata(formik?.values.baseline_date?.$date)
    const real_dates=formateddata(formik?.values.real_dates?.$date)
    const forcast_date=formateddata(formik?.values.forcast_date?.$date)
    setReal_date(real_dates)
    setBaseline_date(baseline_date)
    setForcast_date(forcast_date)
    
  },[formik.values])
  function isValidDate(date:any) {
    return !isNaN(new Date(date).getTime());
  }
  useEffect(() => {
    const updateMilestone = async () => {
      if (real_dates && !isValidDate(real_dates)) {
        if (displayVersion && new Date(displayVersion?.data_date.$date) < new Date(baseline_date)) {
          formik.values.achieved = achieved.NOT_ACHIEVED;
        } else if (displayVersion && new Date(displayVersion?.data_date.$date) >= new Date(baseline_date)) {
          formik.values.achieved = achieved.NOT_ACHIEVED_FORCAST;
          let forecastDate = new Date(forcast_date);
          forecastDate.setMonth(forecastDate.getMonth() + 1); 
          const newDate = { $date: forecastDate  } 
          formik.setFieldValue('forcast_date',newDate )
          console.log(forcast_date)
          
        }
      }
    };
    updateMilestone();
  }, [displayVersion?.data_date.$date,real_dates]);
  
  const cancelform=()=>{
    setIsEdit(false)
    formik.resetForm()
  }
   const handleToggleHidden = () => {
    dispatch(adding())
     const current =milestone.hidden
        patchMilestone(milestone._id.$oid,{hidden:!current})
       .then((res) => {
        dispatch(done())
       })
       .catch(showError)
   }

  const handleDelete = () => {
    sweetAlert({
      title: 'Are you sure?',
      text: 'You cannot recover this later',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      icon: 'warning',
    }).then((res) => {
      if (!res.isConfirmed) return
      setLoading(true)
      dispatch(adding())
     deleteMilestone(milestone._id.$oid)
        .then((res) => {
          sweetAlert({
            text: 'Milestone deleted successfully',
            title: 'Success',
            icon: 'success',
          })
          setLoading(false)
          dispatch(done())
        })
        .catch((err) => {
          sweetAlert({
            title: 'Error',
            text: err?.response?.data?.message || 'Something went wrong',
            icon: 'error',
          })
        })
    })
  }
  
  useEffect(() => {
    if (
      !formik.values.achieved &&
      formik.values.real_dates &&
      dayjs(formik.values.real_dates).isBefore(dayjs(displayVersion?.data_date.$date).endOf('month'))
    )
      formik.setFieldValue(
        'real_date',
        dayjs(displayVersion?.data_date.$date).add(1, 'month').toDate()
      )
  }, [formik.values])

  return (
    <>
    <TreeModal showtree={showtree} onClose={() => setShowtree(false)} Milstone={milestone} /><tr>
      <td>
        {isEdit ? (
          <textarea
            className={clsx(
              'text-dark fw-bold d-block fs-7 ActionPlan border-bottom border-primary form-control',
              {
                'border-danger': !!formik.errors.subject,
              }
            )}
            id='id'
            {...formik.getFieldProps('subject')} />
        ) : (
          formik.values.subject
        )}
      </td>
      <td>
        {isEdit ? (
          <textarea
            className={clsx(
              'text-dark fw-bold d-block fs-7 ActionPlan border-bottom border-primary form-control',
              {
                'border-danger': !!formik.errors.name,
              }
            )}
            id='name'
            {...formik.getFieldProps('name')} />
        ) : (
          formik.values.name
        )}
      </td>
      <td className='fw-bold'>
        {isEdit ? (
          <DatePicker
            format={getDateFormat('days', dateFormat)}
            className={clsx('form-control fs-7 mw-unset', {
              'border-bottom border-primary': isEdit,
            })}
            id='baseline_date'
            {...formik.getFieldProps('baseline_date')}
            value={formik.values.baseline_date ? dayjs(formik.values.baseline_date.$date) : null}
            onChange={(date) => {
              if (date) {
                const newDate = { $date: date.toDate() }
                formik.setFieldValue('baseline_date', newDate)
              } else {
                formik.setFieldValue('baseline_date', null)
              }
            } }
            disabled={!isEdit}
            allowClear={false}
            suffixIcon={null} />
        ) : (
          baseline_date ? format(new Date(baseline_date), getFNSDateFormat(dateFormat)) : "loading..."

        )}
      </td>
      <td>
        <a
          href='#'
          role='button'
          data-kt-menu-trigger='hover'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={'badge badge-light-primary fw-bold me-auto text-white ' +
            getMilestoneBadgeColor(formik.values.achieved)}
        >
          {formik.values.achieved ? formik.values.achieved : 'loading...'}
        </a>
      </td>
      <td className='fw-bold'>
        {isEdit ? (
          <DatePicker
            format={getDateFormat('days', dateFormat)}
            className={clsx('form-control fs-7 mw-unset', {
              'border-bottom border-primary': isEdit,
            })}
            id='real_dates'
            {...formik.getFieldProps('real_dates')}
            value={formik.values.real_dates ? dayjs(formik.values.real_dates.$date) : null} // Convert to Dayjs here
            onChange={(date) => {
              if (date) {
                const newDate = { $date: date.toDate() } // Convert back to your data format if needed
                formik.setFieldValue('real_dates', newDate)
              } else {
                formik.setFieldValue('real_dates', null)
              }
            } }
            disabled={!isEdit}
            allowClear={false}
            suffixIcon={null} />
        ) :formik.values.real_dates!==null ? (
          real_dates && format(new Date(real_dates), getFNSDateFormat(dateFormat))
            ):(<p className='text-center'>-</p>)}
          </td>
      <td className='fw-bold'>
        { formik.values.forcast_date ? (
          forcast_date && format(new Date(forcast_date), getFNSDateFormat(dateFormat))
            ):(<p className='text-center'>-</p>)}
          </td>
      <td>
        <button onClick={handleToggleHidden} className='btn btn-icon text-hover-primary'>
          {milestone.hidden ? (
            <i className='bi bi-eye-slash-fill fs-2'></i>
          ) : (
            <i className='bi bi-eye-fill fs-2'></i>
          )}
        </button>
      </td>

      <td className=''>
        <div className='fw-bold d-flex flex-wrap align-items-center gap-3'>

          {milestone?.shared_with_wbs?.map((item: any) => (
            (milestone.wbs.$oid === item.$oid ?
              <button className='btn btn-primary btn-sm px-3 py-2 position-relative'>
                {knownWbs[item.$oid]?.name}
              </button>
              :
              <button className='btn btn-secondary btn-sm px-3 py-2 position-relative'>
                {knownWbs[item.$oid]?.name}
              </button>
            )
          ))}
          {/* {!!isEdit && <CommentWbsDropdown comment={comment} onAdd={() => setShowtree(!showtree)} />} */}
          {!!isEdit &&
            <button onClick={() => setShowtree(true)} className='btn btn-icon'>
              <KTSVG path='/media/icons/duotune/arrows/arr013.svg' className='svg-icon-1' />
            </button>}
        </div>
      </td>


      <td>
        <div className='d-flex justify-content-end gap-2'>
          {!isEdit ? (
            loading ? (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            ) : (
              <>
                <a href='#' onClick={handleDelete} className='btn btn-danger btn-sm'>
                  Delete
                </a>
                <a
                  href='#'
                  onClick={() => setIsEdit(true)}
                  className='btn btn-bg-light btn-active-color-primary btn-sm'
                >
                  Edit
                </a>
              </>
            )
          ) : (
            <><a onClick={formik.submitForm} className='btn btn-primary btn-bg-light btn-sm'>
              Save
            </a><a onClick={cancelform} className='btn btn-secondary btn-bg-light btn-sm'>
                Cancel
              </a></>
          )}
        </div>
      </td>
    </tr></>
  )
}
