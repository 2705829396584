import {useFormik} from 'formik'
import {Comment} from './models'
import {useEffect, useState} from 'react'
import {getFNSDateFormat, getFormattedDate} from '../../../../helpers/DateFormatter'
import {addDays, addMonths, format} from 'date-fns'
import AssociationBadge from './AssociationBadge'
import CommentAssociationDropdown from './CommentAssociationDropdown'
import {DatePicker} from 'antd'
import {getDateFormat} from '../../../simulations/core/SimulationHelpers'
import clsx from 'clsx'
import dayjs from 'dayjs'
import {useNavigate} from 'react-router-dom'
import {patchVersion} from '../../core/_requests'
import {useProject} from '../../core/ProjectContext'
import {ProjectObject} from '../../core/_models'
import {sweetAlert} from '../../../../utils/funcs'
import {deassociateActionComment} from '../action-plan/ActionPlanHelpers'
import CustomSelect from '../../../../components/CustomSelect'
import {DateUnit} from '../pagination/header/ProjectDetailsHeader'
import { deleteComment, patchCommentById } from './request'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../store'
import { adding, done } from './CommentsState'
import { getWbs, getWbss } from '../../../wbs/_requests'
import CommentWbsDropdown from './CommentWbsDropdown'
import { Wbs } from '../../../wbs/_models'
import { deleteActionPlan, getActionPlan } from '../action-plan/requests'
import TreeModal from './TreeModal'
import { Button } from 'react-bootstrap'
import { KTSVG } from '../../../../../_metronic/helpers'

type Props = {comment: Comment; dateFormat: string; comments: Comment[]; showActions?: boolean}

export default function EditComment({comment, comments, dateFormat, showActions}: Props) {
  const {originalVersion: project, setVersion, setOriginalVersion} = useProject()
  const navigate = useNavigate()
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const added=useSelector((state:RootState)=>state.added.value)
  const [filteredWbs, setFilteredWbs] = useState<any>();
  const knownWbs = useSelector((state: RootState) => state.treedata.knownWbs);
  const [associationactions, setAssociationactions] = useState<any[]>([]);
  const [showtree,setShowtree]=useState<boolean>(false)

  useEffect(() => {
    const allactions = async () => {
      const dataPromises = comment.associated_actions.map((item: any) => {
        return getActionPlan(item.$oid);
      });

      try {
        const results = await Promise.all(dataPromises);
        setAssociationactions(results);
      } catch (error) {
        console.error('Error fetching associated actions:', error);
        // Handle error if needed
      }
    };

    if (comment && comment.associated_actions) {
      allactions();
    }
  }, [comment]);
  const [commentdate,setCommentDate]=useState<any>()
 
  const cancelform=()=>{
    setIsEdit(false)
    formik.resetForm()
  }
  const dispatch=useDispatch()
  const formik = useFormik({
    initialValues: comment,
    onSubmit: (values) => {
      if (!formik.dirty) return setIsEdit(false)
      setLoading(true)
      setIsEdit(false)
      patchCommentById(
        comment._id.$oid,
        {subject:values.subject,comment:values.comment,commenter:values.commenter,concerned_data_date:formik.values.concerned_data_date.$date}
      )
        .then((res:any) => {
        
        })
        .catch((err:any) =>
          sweetAlert({
            text: err.message || 'Something Went Wrong!',
            title: 'Error',
            icon: 'error',
          })
        )
        .finally(() => {
          setLoading(false)
          setIsEdit(false)
        })
    },
  })
  useEffect(()=>{
    const data = new Date(formik?.values.concerned_data_date?.$date);
    const month = (data.getMonth() + 1).toString().padStart(2, '0'); // getMonth() is zero-based
    const day = data.getDate().toString().padStart(2, '0');
    const year = data.getFullYear().toString().slice(-2); // get the last two digits of the year
    
    const formattedDate = `${month}/${day}/${year}`;  
    setCommentDate(formattedDate)
  },[formik.values.concerned_data_date])
  const deletethisComment = async (comment:Comment) => {
    const confirmed = await sweetAlert({
      title: 'Are you sure?',
      text: 'You cannot recover this later',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      icon: 'warning',
    });
  
    if (!confirmed.isConfirmed) {
      return;
    }
  
    try {
      // Dispatch action indicating deletion process has started
      dispatch(adding());
  
      // Delete each associated action plan
      await Promise.all(comment.associated_actions.map(async (item:any) => {
        await deleteActionPlan(item.$oid);
      }));
  
      // After all action plans are deleted, delete the comment
      await deleteComment(comment._id.$oid);
  
      // Display success message
      await sweetAlert({
        text: 'Comment Deleted Successfully',
        title: 'Deleted',
        icon: 'success',
      });
  
      // Optionally, dispatch action or update state after successful deletion
    } catch (error) {
      console.error('Error deleting comment and associated actions:', error);
      // Handle error appropriately, e.g., display error message
      await sweetAlert({
        title: 'Error',
        text: 'Failed to delete comment and associated actions',
        icon: 'error',
      });
    }
    dispatch(done())
  };
  const getDataDates = () => {
    let dates: any[] = []
    const unit: 'month' | 'day' = project?.period_count.type === 'monthly' ? 'month' : 'day'
    let date = new Date(project?.start_date.$date || 0)
    while (date.getTime() <= (project?.end_date.$date as number)) {
      dates.push({
        label: getFormattedDate(
          date,
          project?.period_count?.type as DateUnit,
          project?.date_format
        ),
        value: date,
      })
      if (unit === 'month') date = addMonths(date, 1)
      else date = addDays(date, 1)
    }
    return dates
  }
  const deleteAssociation = (actionId: string) => {
    deassociateActionComment(actionId, comment.subject, project)?.then((res) => {
      setVersion((version) =>
        version
          ? ({...version, action_plan: res.action_plan, comments: res.comments} as ProjectObject)
          : undefined
      )
      setOriginalVersion(res)
    })
  }
  return (
    <>
    <TreeModal showtree={showtree} onClose={() => setShowtree(false)}  comment={comment} />
    <tr>
      <td className='fw-bold mw-250px'>
        {isEdit ? (
          <textarea
            className='text-dark fw-bold d-block fs-7 ActionPlan border-bottom border-primary form-control min-w-200px min-h-100px'
            id='subject'
            {...formik.getFieldProps('subject')}
          ></textarea>
        ) : (
          comment.subject
        )}
      </td>
      <td className='fw-bold'>

        {comment.comment_date && format(new Date(comment.comment_date.$date), getFNSDateFormat(dateFormat))}
      </td>
      <td className='fw-bold mw-250px'>
        {isEdit ? (
          <textarea
            className='text-dark fw-bold d-block fs-7 ActionPlan border-bottom border-primary form-control min-w-200px min-h-100px'
            id='comment'
            {...formik.getFieldProps('comment')}
          ></textarea>
        ) : (
          comment.comment
        )}
      </td>
      <td className='fw-bold'>
        {isEdit ? (
          <textarea
            className='text-dark fw-bold d-block fs-7 ActionPlan border-bottom border-primary form-control'
            id='commenter'
            {...formik.getFieldProps('commenter')} />
        ) : (
          comment.commenter
        )}
      </td>
      <td className='fw-bold'>
        {isEdit ? (
          <DatePicker
            format={getDateFormat('days', dateFormat)}
            className={clsx('form-control fs-7 mw-unset', {
              'border-bottom border-primary': isEdit,
            })}
            id='concerned_data_date'
            {...formik.getFieldProps('concerned_data_date')}
            value={formik.values.concerned_data_date ? dayjs(formik.values.concerned_data_date.$date) : null} // Convert to Dayjs here
            onChange={(date) => {
              if (date) {
                const newDate = { $date: date.toDate() } // Convert back to your data format if needed
                formik.setFieldValue('concerned_data_date', newDate)
              } else {
                formik.setFieldValue('concerned_data_date', null)
              }
            } }
            disabled={!isEdit}
            allowClear={false}
            suffixIcon={null} />
        ) : (
          commentdate ? format(new Date(commentdate), getFNSDateFormat(dateFormat)) : "loading..."

        )}
      </td>
      <td className=''>
        <div className='fw-bold d-flex flex-wrap align-items-center gap-3'>
          {associationactions?.map((item: any) => (
            <AssociationBadge
              editable={isEdit}
              goTo={() => navigate('../action-plan', { state: { comment } })}
              key={item}
              id={item?.subject}
              onDelete={() => deleteAssociation(item)} />
          ))}
          {!!isEdit && <CommentAssociationDropdown item={comment} />}
        </div>
      </td>
      <td className=''>
        <div className='fw-bold d-flex flex-wrap align-items-center gap-3'>

          {comment?.shared_with_wbs?.map((item: any) => (
            (comment.wbs.$oid === item.$oid ?
              <button className='btn btn-primary btn-sm px-3 py-2 position-relative'>
                {knownWbs[item.$oid]?.name}
              </button>
              :
              <button className='btn btn-secondary btn-sm px-3 py-2 position-relative'>
                {knownWbs[item.$oid]?.name}
              </button>
            )
          ))}
          {/* {!!isEdit && <CommentWbsDropdown comment={comment} onAdd={() => setShowtree(!showtree)} />} */}
          {!!isEdit &&
            <button  onClick={()=>setShowtree(true)}  className='btn btn-icon'>
            <KTSVG path='/media/icons/duotune/arrows/arr013.svg' className='svg-icon-1' />
          </button>}
        </div>
      </td>
      <td className=''>
        <div className='d-flex justify-content-end gap-2'>
          {!isEdit ? (
            loading ? (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            ) : (
              showActions !== false && (
                <>
                  <a onClick={() => deletethisComment(comment)} className='btn btn-danger btn-sm'>
                    Delete
                  </a>
                  <a
                    onClick={() => setIsEdit(true)}
                    className='btn btn-bg-light btn-active-color-primary btn-sm'
                  >
                    Edit
                  </a>
                </>
              )
            )
          ) : (
            <><a onClick={formik.submitForm} className='btn btn-primary btn-bg-light btn-sm'>
                  Save
                </a><a onClick={cancelform} className='btn btn-secondary btn-bg-light btn-sm'>
                  Cancel
                  </a></>
          )}
        </div>
      </td>
    </tr></>
  )
}
