import { useDrop } from 'react-dnd'
import { KTSVG } from '../../../../_metronic/helpers'
import clsx from 'clsx'
import { Wbs } from '../_models'
import { Link } from 'react-router-dom'
import { patchWbs } from '../_requests'
import { showError } from '../../../utils/funcs'
import { useDispatch } from 'react-redux'
import { addWbsToStore } from '../../wbs/treedata'

type Props = {
  wbs: Wbs
  refetch: any
}

export default function PreviousItem({ wbs, refetch }: Props) {
  const dispatch = useDispatch();

  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'wbs',
    collect: (monitor) => ({ isOver: !!monitor.isOver() }),
    drop: (item: Wbs) => {
      patchWbs(item._id.$oid, {
        parent_wbs: wbs.parent_wbs?.$oid || 'root',
      })
        .then((res) => {
          dispatch(addWbsToStore(res));
          refetch();
        })
        .catch(showError)
    },
  }))
  return (
    <Link
      to={'/enterprise/eps/wbss/' + (wbs.parent_wbs?.$oid || '')}
      ref={drop}
      className={clsx('mb-5 col-md-4 col-xl-3', {
        'opacity-50': isOver,
      })}
    >
      <div
        style={{ height: '200px' }}
        className='card border border-2 border-gray-300 border-hover cursor-pointer d-flex justify-content-center align-items-center'
      >
        <div className='symbol symbol-50px w-50px bg-light'>
          <KTSVG
            path='/media/icons/duotune/arrows/arr002.svg'
            className='svg-icon-muted svg-icon-3hx text-primary'
          />
        </div>
      </div>
    </Link>
  )
}
