import { string } from "yup"

export interface IActionPlan {
  _id: any
  subject: string
  action: string
  responsible: string
  start_date: any
  report_date?: any
  close_date?: any
  associated_comment: string
  shared_with_wbs:{$oid:string}[]
  status: string
  wbs:{$oid:string}
  deadline: any
}

export enum Status {
  DONE = 'done',
  IN_PROGRESS = 'in_progress',
  CANCELED = 'cancelled',
  STANDBY = 'standby',
}
export const defaultInitalActionPlan:Omit<IActionPlan, '_id'> ={
  action: '',
  start_date: new Date(),
  report_date: null,
  responsible: '',
  subject: '',
  status: Status.IN_PROGRESS,
  associated_comment: "",
  shared_with_wbs:[],
  wbs:{$oid:""},
  close_date:null,
  deadline: new Date(),
}

export const HeaderList: any[] = [
  {
    Header: 'N°',
    width: '90',
    accessor: 'subject',
  },
  {
    Header: 'Associated Comments',
    width: '180',
    accessor: 'associated_comments',
  },
  {Header: 'Action', width: '90', sort: true, accessor: 'action'},
  {Header: 'Responsible', width: '150', sort: true, accessor: 'responsible'},
  {
    Header: 'Start Date',
    width: '90',
    sort: true,
    accessor: 'initial_date',
    sortType: (a: any, b: any) => {
      return new Date(b.values.initial_date).getTime() - new Date(a.values.initial_date).getTime()
    },
  },
  {
    Header: 'Deadline',
    width: '90',
    sort: true,
    accessor: 'deadline',
    sortType: (a: any, b: any) => {
      return new Date(b.values.deadline).getTime() - new Date(a.values.deadline).getTime()
    },
  },
  {
    Header: 'Report Date',
    width: '90',
    sort: true,
    accessor: 'report_date',
    sortType: (a: any, b: any) => {
      if (!b.values.report_date) return -1
      if (!a.values.report_date) return +1
      return new Date(b.values.report_date).getTime() - new Date(a.values.report_date).getTime()
    },
  },
  {
    Header: 'Close Date',
    width: '90',
    sort: true,
    accessor: 'real_date',
    sortType: (a: any, b: any) => {
      if (!b.values.real_date) return -1
      if (!a.values.real_date) return +1
      return new Date(b.values.real_date).getTime() - new Date(a.values.real_date).getTime()
    },
  },
  {Header: 'Status', width: '90', sort: true, accessor: 'status'},
  {Header: '', width: '50', sort: false, accessor: 'test', disableSortBy: true},
]
