import _ from 'lodash'
import {Dispatch, SetStateAction} from 'react'
import {Modal} from 'react-bootstrap'
import {CustomCurve} from '../../core/_models'
import {useSimulationContext} from '../SimulationContext'

type Props = {
  show: boolean
  onHide: () => void
  setShowAdd: any
  selected: CustomCurve | undefined
  selectCustomCurve: Dispatch<SetStateAction<CustomCurve | undefined>>
  updateCurve: (CustomCurve: CustomCurve) => void
}

const RECORD_TO_IGNORE = 'IGNORE_THIS_RECORD_6DBE8ADAD7F9'

export default function CustomCurveModal({
  show,
  onHide,
  setShowAdd,
  selected,
  selectCustomCurve,
  updateCurve,
}: Props) {
  const {formik} = useSimulationContext()
  const handleDelete = (curve: CustomCurve) => {
    let custom_curve = formik?.values?.custom_curve || []
    custom_curve = custom_curve.filter((item) => !_.isEqual(item, curve))
    if (!custom_curve.length)
      custom_curve.push({
        color: '-1',
        name: RECORD_TO_IGNORE,
        values: [],
      })
    formik?.setFieldValue('custom_curve', custom_curve)
  }
  return (
    <Modal size='lg' centered show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>Custom Curves</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='table-responsive'>
          <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
            <thead>
              <tr className='text-start text-muted fw-bolder fs-6 text-uppercase gs-0'>
                <th className=''>COLOR</th>
                <th className=''>NAME</th>
                {/* <th className=''>IS ESTIMATE</th> */}
                <th className=''>EDIT</th>
                <th className=''>DELETE</th>
              </tr>
            </thead>
            <tbody className='text-gray-700 fw-bold fs-5'>
              {!formik?.values.custom_curve?.filter((item) => item.name !== RECORD_TO_IGNORE)
                .length && (
                <tr>
                  <td className='text-center' colSpan={5}>
                    <h3>No Results</h3>
                  </td>
                </tr>
              )}
              {formik?.values.custom_curve
                ?.filter((item) => item.name !== RECORD_TO_IGNORE)
                ?.map((curve, index) => (
                  <tr key={index}>
                    <td className='ms-5 min-width-100px'>
                      <div
                        className='rounded'
                        style={{width: '20px', height: '20px', backgroundColor: curve.color}}
                      ></div>
                    </td>
                    <td>{curve.name}</td>
                    {/* <td className='leave'>
                      <input
                        checked={_.isEqual(selected, curve)}
                        type='radio'
                        onClick={() => {
                          selectCustomCurve((prev: CustomCurve | undefined) =>
                            prev === curve ? undefined : curve
                          )
                        }}
                        readOnly
                        className='form-check-input'
                      />
                    </td> */}
                    <td>
                      <button
                        onClick={() => {
                          updateCurve(curve)
                          onHide()
                        }}
                        type='button'
                        className='btn btn-icon btn-lg btn-active-icon-info'
                      >
                        <i className='bi bi-pencil fs-2'></i>
                      </button>
                    </td>
                    <td>
                      <button
                        onClick={() => handleDelete(curve)}
                        type='button'
                        className='btn btn-icon btn-lg btn-active-icon-danger'
                      >
                        <i className='bi bi-trash fs-2'></i>
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </Modal.Body>
      <Modal.Footer className='justify-content-center'>
        <button
          onClick={() => {
            setShowAdd()
            onHide()
          }}
          className='btn btn-primary'
          style={{width: '60%'}}
        >
          Add
        </button>
      </Modal.Footer>
    </Modal>
  )
}
