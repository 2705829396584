import { Tree } from 'antd';
import type { DataNode, EventDataNode } from 'antd/es/tree';
import { updateTreeData } from './_helpers';
import { useTree } from './SnapshotShareTreeContext';
import SwitcherIcon from './icons/SwitcherIcon';
import { Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { useEffect } from 'react';
import { useProject } from '../../../projects/core/ProjectContext';
import ChartTreeFolderItem from './ChartTreeFolderItem';
import { useLocation } from 'react-router-dom';

const SnapshotShareChartTreeView = () => {
  const { expandedKeys, setExpandedKeys, setTreeData, treeData } = useTree();
  const knownWbs = useSelector((state: RootState) => state.treedata.knownWbs);
  const isTreeLoaded = useSelector((state: RootState) => state.treedata.isTreeLoaded);
  const { project } = useProject();
  const location = useLocation();

  useEffect(() => {
    const savedExpandedKeys = localStorage.getItem('expandedKeys');
    if (savedExpandedKeys) {
      setExpandedKeys(JSON.parse(savedExpandedKeys));
    }
    if (isTreeLoaded)
      loadExpandAll();
  }, [location.pathname, knownWbs, isTreeLoaded]);

  useEffect(() => {
    localStorage.setItem('expandedKeys', JSON.stringify(expandedKeys));
  }, [expandedKeys]);

  const loadExpandAll = async () => {
    const allKeys: string[] = [];

    const fetchWbsRecursively = async (wbsId: string): Promise<DataNode> => {
      let knownWbsItem = knownWbs[wbsId];
      if (knownWbsItem)
        allKeys.push(knownWbsItem._id.$oid);

      const childrenData: DataNode[] = await Promise.all(
        knownWbsItem.subwbs.map(async (subWbs) => {
          return await fetchWbsRecursively(subWbs.$oid);
        })
      );

      return {
        key: knownWbsItem._id.$oid,
        title: <ChartTreeFolderItem folder={knownWbsItem} />,
        children: childrenData,
      };
    };

      const rootNode = await fetchWbsRecursively(project?.share_wbs.$oid);
      setTreeData([rootNode]);
      setExpandedKeys(allKeys);
    
  };

  const loadData = async (treeNode: EventDataNode<DataNode>) => {
    const childWbs = knownWbs[treeNode.key.toString()];

    if (childWbs) {

    
    const childData: any[] = await Promise.all(childWbs.subwbs.map(async (item) => {
      let response = knownWbs[item.$oid];
      if (response)
      return {
        key: response._id.$oid,
        name: response.name,
        title: <ChartTreeFolderItem folder={response} />,
        switcherIcon: response.subwbs.length > 0 ? <SwitcherIcon /> : <></>,
        children: childData.filter(item => item),
      };
    }));

    setTreeData((origin) => {
      return updateTreeData(origin, treeNode.key.toString(), childData.filter(item => item));
    });
  }
  };

  const extractTreeOrder = (nodes: DataNode[], order: string[] = []): string[] => {
    nodes.forEach(node => {
      order.push(node.key as string);
      if (node.children) {
        extractTreeOrder(node.children, order);
      }
    });
    return order;
  };

  const handleExpand = (newExpandedKeys: any, { expanded, node }: any) => {
    //if (node.key === project?.current_wbs) return;

    const updatedExpandedKeys = expanded
      ? [...expandedKeys, node.key.toString()]
      : expandedKeys.filter(key => key !== node.key.toString());

    const treeOrder = extractTreeOrder(treeData);
    const sortedExpandedKeys = updatedExpandedKeys.sort((a, b) => treeOrder.indexOf(a) - treeOrder.indexOf(b));

    setExpandedKeys(sortedExpandedKeys);
  };


  

  return (
    <>
      <div>
        <Row>
          <Tree
            style={{ overflowX: 'scroll' }}
            selectable={false}
            switcherIcon={SwitcherIcon}
            onExpand={handleExpand}
            expandedKeys={expandedKeys}
            loadData={loadData}
            treeData={treeData}
          />
        </Row>
      </div>
    </>
  );
};

export default SnapshotShareChartTreeView;