import {getCustomCurve} from '../components/overview/Project'
import {ObjectId, ProjectModel, ProjectObject} from './_models'
import React from 'react'
import {prepareMilestoneDates} from '../components/milestones/_utils'
import { getDiffrentBettwenDate } from '../../../utils/data-transformarion/date-utils'

export const getAllVersions = (project: ProjectModel | undefined): ObjectId[] => {
  if (!project) return []
  let versions: ObjectId[] = []
  Object.keys(project.versions).forEach((key, index) => {
    versions = [...versions, ...project.versions[key]]
  })
  return versions
}

export const getNewVersion = (version: ProjectObject) => {
  return {
    associated_calendar: version.associated_calendar?.$oid,
    achieved_percentage: version.achieved_percentage,
    action_plan: version.action_plan,
    annotations: version.annotations,
    budget_at_completion: {
      amount: version.budget_at_completion.amount,
      unit: version.budget_at_completion.unit,
      currency: version.budget_at_completion.currency,
    },
    colors: version.colors,
    custom_curve: version.custom_curve,
    data_date: new Date(version.data_date?.$date),
    end_date: new Date(version.end_date.$date),
    start_date: new Date(version.start_date.$date),
    date_format: version.date_format,
    delay_tolerance: version.delay_tolerance,
    forecast_settings: {
      ...version.forecast_settings,
      custom_end_date: version.forecast_settings?.custom_end_date
        ? new Date(version.forecast_settings.custom_end_date.$date)
        : null,
    },
    float_formatter: version.float_formatter,
    period_count: version.period_count,
    milestones: prepareMilestoneDates(version.milestones),
    total_hours: version.total_hours,
    full_capacity: version.full_capacity,
    reserve: {
      amount: version.reserve?.amount,
      unit: version.reserve?.unit,
    },
    output: version.output,
  }
}

export const checkCompleteData = (data_date: Date, originalVersion: ProjectObject | undefined) => {
  if (data_date.getTime() < (originalVersion?.start_date.$date || 0)) return true
  const dateDiff = getDiffrentBettwenDate(
    data_date.getTime(),
    originalVersion?.start_date.$date || 0,
    originalVersion?.period_count.type || ''
  )
  return !(
    dateDiff > getCustomCurve('cumulativeEarnedValue', originalVersion).length - 1 ||
    dateDiff > getCustomCurve('cumulativeActualCost', originalVersion).length - 1
  )
}

export function greaterThanOrEqual(el: any, idx: number, arr: any[]) {
  const prevElement = arr[idx - 1]
  return !idx || +el >= +prevElement
}
export const CustomToggle = React.forwardRef(({children, onClick}: any, ref: any) => (
  <button type='button' onClick={onClick} ref={ref} className='btn btn-light btn-icon btn-sm'>
    <i className='bi bi-three-dots-vertical'></i>
  </button>
))

export const CustomBorderlessToggle = React.forwardRef(({children, onClick}: any, ref: any) => (
  <button type='button' onClick={onClick} ref={ref} className='btn btn-icon btn-sm'>
    <i className='bi bi-three-dots-vertical'></i>
  </button>
))

export const promptReload = (event: any) => {
  event.preventDefault()
  event.returnValue = ''
  return ''
}
