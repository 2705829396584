import {format} from 'date-fns'
import {findSym} from '../helpers/func'
import Swal, {SweetAlertOptions} from 'sweetalert2'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {formatNumber} from './formatter'
import withReactContent from 'sweetalert2-react-content'

export type DateFormatType = 'mm/dd/yyyy' | 'dd/mm/yyyy'
export type BugetUnitType = 'billion' | 'million' | 'thousand' | 'unit'

export interface IBudget {
  amount?: number
  currency: string
  unit: BugetUnitType
  floatFormater?: number
}

const amountFirstLetters = {
  billion: 'B',
  million: 'M',
  thousand: 'K',
  unit: '',
}

// <amount><unit> <code>,  e.g.: 1K USD
export const getFormattedBudget = (budgetObj: IBudget, showZero?: boolean) => {
  const {amount, currency, unit} = budgetObj

  const amountFirstLetter = amountFirstLetters[unit as keyof typeof amountFirstLetters]
  return `${
    amount ? formatNumber(+amount, budgetObj.floatFormater || 0, ',') : showZero ? '0' : '-'
  } ${amountFirstLetter}${findSym(currency)}`
}
export const getFormattedUnit = (unit: string) => {
  return amountFirstLetters[unit as keyof typeof amountFirstLetters]
}
export const getFormattedUnitAndCurrency = (budgetObj: IBudget) => {
  // eslint-disable-next-line  @typescript-eslint/no-unused-vars
  const {amount, currency, unit} = budgetObj

  const amountFirstLetter = amountFirstLetters[unit as keyof typeof amountFirstLetters]

  return `${amountFirstLetter}${findSym(currency)}`
}
export const getFormattedDate = (
  dateTimestamp: number,
  dateFormat: DateFormatType = 'dd/mm/yyyy'
) => format(new Date(dateTimestamp), dateFormat === 'mm/dd/yyyy' ? 'MM/dd/yyyy' : 'dd/MM/yyyy')

const MySwal = withReactContent(Swal)

export const sweetAlert = (content: SweetAlertOptions<any, any>) => {
  return MySwal.fire({
    ...content,
    background: 'var(--kt-modal-bg)',
    confirmButtonColor: getCSSVariableValue('--kt-primary-active'),
  })
}

export const showSuccess = (text: string, args?: SweetAlertOptions) =>
  sweetAlert({title: 'Success', icon: 'success', text, ...args})

export const showError = (err: any, args?: SweetAlertOptions) =>
  sweetAlert({
    title: 'Error',
    icon: 'error',
    text: err?.response?.data?.message || 'Something went wrong!',
    ...args,
  })

export const formatFloatFromString = (stringValue: any, floatFormatter: number) => {
  const arrayValue = stringValue.split(' ')
  arrayValue[0] = formatNumber(parseFloat(arrayValue), floatFormatter, '.')
  return arrayValue.join(' ')
}
