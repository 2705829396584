import {useIntl} from 'react-intl'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import { useAuth } from '../../../../../app/modules/auth'
import { useNavigate } from 'react-router-dom'
import useFreeTrialAdd from '../../../../../app/components/useFreeTrialAdd'
import { sweetAlert } from '../../../../../app/utils/funcs'

const SidebarMenuMain = () => {
  const { currentUser, currentPlanLevel } = useAuth()
  const intl = useIntl()
  const navigate = useNavigate()
  const { FreeTrialAddComponent, showAddTrial, setShowAddTrial } = useFreeTrialAdd(currentUser, sweetAlert)

  const handlePaywallClick = (to: string) => {
    if (currentPlanLevel > 1) {
      navigate(to)
    } else {
      setShowAddTrial(true)
    }
  }

  const handleMenuItemClick = (path: string) => (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    handlePaywallClick(path)
  }

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='#'
        title='Projects'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <SidebarMenuItem to='/projects' title='View Projects' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='#'
        title='Simulations'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/graphs/gra011.svg'
      >
        <SidebarMenuItem to='#' title='View Simulations' hasBullet={true} onClick={handleMenuItemClick('/simulations')} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='#'
        title='Enterprise'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/finance/fin006.svg'
      >
        <SidebarMenuItem to='/enterprise/calendars' title='Calendars' hasBullet={true} />
        <SidebarMenuItem to='/enterprise/library' title='Curve Library' hasBullet={true} />
        <SidebarMenuItem to='#' title='EPS' hasBullet={true} onClick={handleMenuItemClick('/enterprise/eps')} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/help'
        title='Help'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen011.svg'
      >
        <SidebarMenuItem to='https://project-curve.com/' title='Support Center' hasBullet={true} />
        <SidebarMenuItem to='/help/releases' title='Releases' hasBullet={true} />
        <SidebarMenuItem to='https://project-curve.com/' title='Contact us' hasBullet={true} />
      </SidebarMenuItemWithSub>
      {showAddTrial && FreeTrialAddComponent}
    </>
  )
}

export {SidebarMenuMain}
