/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useFormik} from 'formik'
import {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {createPortal} from 'react-dom'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import * as Yup from 'yup'
import {CreateSimulation} from '../../../../app/modules/simulations/core/_models'
import {useSimulationsData} from '../../../../app/modules/simulations/core/_queries'
import {createSimulations} from '../../../../app/modules/simulations/core/_requests'
import {sweetAlert} from '../../../../app/utils/funcs'
import {KTSVG} from '../../../helpers'
import {SimulationForm} from '../forms/simulationFrom'

type Props = {
  show: boolean
  handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const CreateAppModal = ({show, handleClose}: Props) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const {refetch} = useSimulationsData({page: 1, perPage: 9, filter: ''})
  const formik = useFormik<CreateSimulation>({
    initialValues: {
      name: '',
      description: '',
      is_start: false,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Name is required'),
      description: Yup.string().required('Description is required'),
      is_start: Yup.boolean().required('Simulation type is required'),
    }),
    onSubmit: (values) => {
      setLoading(true)
      createSimulations({
        ...values,
        curve_settings: {
          tangent: 1.22,
          offset: 0.33,
        },
        data_date: new Date(),
        end_date: new Date(),
      } as CreateSimulation)
        .then((data) => {
          refetch()
          sweetAlert({
            title: 'Success',
            text: 'Creation Successfull',
            icon: 'success',
          }).then(() => navigate(`/simulations/${data?._id?.$oid}`))
        })
        .catch((err) =>
          sweetAlert({
            text: err?.response?.data?.message || 'Something went wrong',
            icon: 'error',
            title: 'Error',
          })
        )
        .finally(() => setLoading(false))
    },
  })

  const clearAndClose = () => {
    sweetAlert({
      title: 'Are you sure?',
      text: 'Are you sure you want to leave?',
      icon: 'warning',
      confirmButtonText: 'Close',
      showCancelButton: true,
    }).then((res) => {
      if (!res.isConfirmed) return
      formik.resetForm()
      handleClose()
    })
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={show}
      onHide={clearAndClose}
      backdrop={true}
    >
      <div className='modal-header'>
        <h2>{intl.formatMessage({id: 'MENU.CREATE_SIMULATION'})}</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={clearAndClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        <form id='kt_modal_create_app_form'>
          <SimulationForm formik={formik} />
          <div className='d-flex flex-stack pt-10'>
            <div>
              <button
                type='button'
                className='btn btn-lg btn-primary'
                data-kt-stepper-action='submit'
                disabled={!formik.isValid}
                onClick={() => formik.handleSubmit()}
              >
                {!loading && (
                  <span className='indicator-label'>
                    {' '}
                    Create{' '}
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr064.svg'
                      className='svg-icon-3 ms-2 me-0'
                    />
                  </span>
                )}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>,
    modalsRoot
  )
}

export {CreateAppModal}
