import { FC, useEffect, useRef, useState } from 'react'
import ReactCodeInput from 'react-code-input'
import { useLocation, useNavigate } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import { getUserByToken, login, sendOTP, validateOTP, verifyOTP } from '../core/_requests'
import { useAuth } from '../core/Auth'
import styles from './auth.module.scss'
import Countdown from 'react-countdown'
import { isMobile } from 'react-device-detect'
interface IPropState {
  email: string | ''
  password: string | ''
  otp_token: string | undefined
  type: string | undefined
}
interface CountdownRendererProps {
  minutes: any;
  seconds: any;
  completed: any;
}
const CountdownRenderer: FC<CountdownRendererProps> = ({
  minutes,
  seconds,
  completed,
}) => {
  if (completed) return <span>Expired, please click on resend code.</span>;
  else
    return (
      <>
        Expires in: {minutes.toString().padStart(2, '0')}:
        {seconds.toString().padStart(2, '0')}
      </>
    );
};


export function OtpPage() {
  const [loading, setLoading] = useState(false)
  const [otp, setOTP] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [resendMessage, setResendMessage] = useState('')
  const [isInputDisabled, setIsInputDisabled] = useState(false)
  const [isBtnDisabled, setIsBtnDisabled] = useState(true)
  const { saveAuth, setCurrentUser } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const { email, password, otp_token, type } = location.state as IPropState
  const [expirationTimestamp, setExpirationTimestamp] = useState(
    (jwt_decode(otp_token || '') as any).exp
  )
  const [currTimestamp, setCurrTimestamp] = useState(
    Math.floor(Date.now() / 1000)
  );

  useEffect(() => {
    if (expirationTimestamp - currTimestamp <= 0) setIsInputDisabled(true);
    else
      setTimeout(() => {
        const tempCurrTimestamp = Math.floor(Date.now() / 1000);
        if (expirationTimestamp - tempCurrTimestamp <= 0)
          setIsInputDisabled(true);
      }, (expirationTimestamp - currTimestamp + 1) * 1000);
  }, []);
  const countdownRef = useRef<any>();

  const handleChange = (v: any) => {
    setOTP(v)
    setIsBtnDisabled(v.length < 6)
  }

  const handleClick = async () => {
    setLoading(true)

    validateOTP(otp_token || '', otp)
      .then(async (res) => {
        if (type === 'reset-password') {
          navigate('/auth/recover-password', {
            state: { validation_token: res.data.validation_token, email },
          })
        } else {
          const res_verify = await verifyOTP(res.data.validation_token)
          const { api_token, refreshToken } = await login(email, password)
          saveAuth({
            api_token,
            refreshToken
          })
          const { data: user } = await getUserByToken(api_token)
          setCurrentUser(user)
          navigate('/')
          setLoading(false)
        }
      })
      .catch((err) => {
        setErrorMsg(err.response.data.message)
        saveAuth(undefined)
        setIsBtnDisabled(false)
        setLoading(false)
      })
  }
  return (
    <div className=''>
      <div className='text-center mb-10'>
        <img alt='Logo' className='mh-125px' src='../media/letterbox.png' />
      </div>
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Otp Verification</h1>
        <div className='text-muted fw-semibold fs-5 mb-5'>
          Enter the verification code we sent to
        </div>
        <div className='fw-bold text-dark fs-3'>{email}</div>
        <Countdown
          ref={countdownRef}
          date={expirationTimestamp * 1000}
          renderer={CountdownRenderer}
        />
      </div>
      <div className='mb-10'>
        {errorMsg.length > 0 && (
          <div className='alert alert-danger d-flex align-items-center p-5 mb-10'>
            <div className='d-flex flex-column'>
              <span>{errorMsg}</span>
            </div>
          </div>
        )}
        {resendMessage.length > 0 && (
          <div className='alert alert-success d-flex align-items-center p-5 mb-10'>
            <div className='d-flex flex-column'>
              <span>{resendMessage}</span>
            </div>
          </div>
        )}
        <div className='fw-bold text-start text-dark fs-6 mb-1 ms-1'>
          Type your 6 digit security code
        </div>
        <div>
          <ReactCodeInput
            value={otp}
            onChange={handleChange}
            name='pin'
            fields={6}
            inputMode='numeric'
            disabled={false}
            inputStyle={{
              height: '60px',
              width: '60px',
              background: 'transparent',
              border: localStorage.getItem('kt_theme_mode_value') === 'dark' ? '1px solid  #323248' : '1px solid  #e4e6ef',
              margin: isMobile ? '1rem 1rem' : '0.5rem 0.25rem',
              fontWeight: '500',
              color: localStorage.getItem('kt_theme_mode_value') === 'dark' ? '#92929f' : 'black',
              textAlign: 'center',
              fontSize: '2.25rem',
              borderRadius: '4px 4px 0 0',
            }}

          />
        </div>
      </div>

      <div className='d-flex flex-wrap justify-content-center pb-lg-0 mb-13'>
        <button
          type='button'
          onClick={() => handleClick()}
          id='kt_password_reset_submit'
          disabled={isBtnDisabled}
          className='btn btn-primary me-4'
        >
          <span className='indicator-label'>Verify </span>
          {loading && (
            <span className=''>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Form group */}

      <div className='text-center fw-semibold fs-5'>
        <span className='text-muted me-1'>Didn’t get the code ?</span>
        <span
          onClick={async () => {
            setTimeout(() => {
              sendOTP(email, type)
                .then((res) => {
                  (countdownRef as any)?.current?.start();
                  const tempExpirationTimestamp = (
                    jwt_decode(res.data.otp_token || '') as any
                  ).exp;
                  setExpirationTimestamp(tempExpirationTimestamp);
                  const tempCurrTimestamp = Math.floor(Date.now() / 1000);
                  setCurrTimestamp(tempCurrTimestamp);
                  setIsBtnDisabled(true);
                  setTimeout(() => {
                    const tempCurrTimestamp = Math.floor(Date.now() / 1000);
                    if (expirationTimestamp - tempCurrTimestamp <= 0)
                      setIsInputDisabled(true);
                  }, (tempExpirationTimestamp - tempCurrTimestamp + 1) * 1000);
                 // if (isInputDisabled) window.location.reload();
                  setResendMessage("Token has been resent successfully.")
                  setTimeout(() => {
                    setResendMessage("")
                  }, 3000);
                  navigate('.', {
                    state: { otp_token: res.data.otp_token, email: email, password, type },
                  })

                })
                .catch(() => { })
            }, 1000)
          }}
          className='link-primary fs-5 me-1'
          style={{ cursor: 'pointer' }}
        >
          Resend
        </span>
      </div>
    </div>
  )
}
