import {KTSVG} from '../../../_metronic/helpers'

type Props = {
  placeholder: string
  keyword: string
  setKeyword: any
}

export default function SearchBar({placeholder, keyword, setKeyword}: Props) {
  return (
    <>
      <KTSVG
        path='/media/icons/duotune/general/gen021.svg'
        className='svg-icon-1 position-absolute ms-6'
      />
      <input
        type='text'
        data-kt-user-table-filter='search'
        className='form-control form-control w-250px ps-14'
        placeholder={placeholder}
        value={keyword}
        onChange={(e) => setKeyword(e.target.value)}
      />
    </>
  )
}
