import { useEffect, useRef, useState } from 'react';
import { KTSVG } from '../../../../../_metronic/helpers';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import { appendTreeData, deleteTreeNode } from './_helpers';
import { createWbs, patchWbs, getWbs } from '../../_requests'; // Import getWbs to fetch parent details
import TreeWbsItem from './TreeWbsItem';
import { showError } from '../../../../utils/funcs';
import { useTree } from './TreeContext';
import { useProject } from '../../../projects/core/ProjectContext';
import { useDispatch, useSelector } from 'react-redux';
import { isntrename } from '../../../wbs/treeStates';
import { addWbsToStore } from '../../../wbs/treedata';
import { RootState } from '../../../../store';

type Props = {
  parentId: string | undefined;
};

export default function TreeCreateWbsItem({ parentId }: Props) {
  const { setTreeData } = useTree();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { project } = useProject();
  const knownWbs = useSelector((state: RootState) => state.treedata.knownWbs);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false); // New state to track loading

  const formik = useFormik({
    initialValues: { name: '' },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required('Name is required')
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols'),
    }),
    onSubmit: async (values) => {
      setLoading(true); // Set loading to true when submit starts
      try {
        dispatch(isntrename());
        // Create the new WBS item
        const newWbs = await createWbs({
          name: values.name,
          parent_wbs: parentId === 'root' ? undefined : parentId,
          parent_project: project?._id.$oid,
        });
        dispatch(addWbsToStore(newWbs));

        // Patch the subwbs of the parent to include the new WBS
        if (parentId && parentId !== 'root') {
          let parentWbs = knownWbs[parentId];
          if (!parentWbs) {
            parentWbs = await getWbs(parentId);  // Fetch the current state of the parent WBS
            dispatch(addWbsToStore(parentWbs));
          }
          const updatedSubwbs = [
            ...(parentWbs.subwbs.map((item: any) => item.$oid) || []),
            newWbs._id.$oid
          ];

          const patchedParent = await patchWbs(parentId, {
            subwbs: updatedSubwbs  // Update parent WBS with the new list of subwbs
          });
          dispatch(addWbsToStore(patchedParent));
        }

        // Update the tree data to add the new WBS item
        setTreeData((origin) => {
          const filteredNode = deleteTreeNode(origin, 'new_child' + (parentId || 'root'));
          return appendTreeData(filteredNode, parentId || 'root', [
            {
              title: <TreeWbsItem wbs={newWbs} />,
              key: newWbs._id.$oid,
              children: [],
              switcherIcon: <></>,
            },
          ]);
        });


      } catch (error) {
        showError(error);
      } finally {
        setLoading(false); // Set loading to false after submit is done
      }
    },
  });

  useEffect(() => {
    formik.resetForm();
    const node = inputRef.current;
    if (node) node.focus();
  }, [parentId]);

  return (
    <div className='d-inline-flex justify-content-start align-items-center'>
      <KTSVG
        className={clsx('svg-icon-2 me-2', {
          'svg-icon-secondary': !(formik.errors.name && formik.touched.name),
          'svg-icon-danger': formik.errors.name && formik.touched.name,
        })}
        path='/media/icons/duotune/files/fil012.svg'
      />
      <input
        ref={inputRef}
        type='text'
        placeholder='New Wbs'
        className='form-control form-control-flush ps-0'
        {...formik.getFieldProps('name')}
        disabled={loading} // Disable input while loading
      />
      <button
        onClick={() => {
          dispatch(isntrename());
          setTreeData((origin) => deleteTreeNode(origin, 'new_child' + (parentId || 'root')))
        }
        }
        type='button'
        style={{ transition: 'all 0.2s ease-in-out' }}
        className={clsx('btn btn-icon btn-sm me-2')}
        disabled={loading} // Disable the cancel button while loading
      >
        <KTSVG
          path='/media/icons/duotune/arrows/arr011.svg'
          className='svg-icon-dark text-hover-danger svg-icon-3'
        />
      </button>
      <button
        onClick={() => formik.handleSubmit()}
        type='button'
        style={{ transition: 'all 0.2s ease-in-out' }}
        className={clsx('btn btn-icon btn-sm me-2')}
        disabled={loading} // Disable submit button while loading
      >
        {loading ? (
          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> // Loading spinner
        ) : (
          <KTSVG
            path='/media/icons/duotune/arrows/arr012.svg'
            className='svg-icon-dark text-hover-success svg-icon-3'
          />
        )}
      </button>
    </div>
  );
}
