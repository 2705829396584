import { Tree } from 'antd'
import type { DataNode, EventDataNode } from 'antd/es/tree'
import TreeFolderItem from './TreeFolderItem'
import { combineVersionsV2, updateTreeData } from './_helpers'
import { useTree } from './ShareTreeContext'
import SwitcherIcon from './icons/SwitcherIcon'
import { Row } from 'react-bootstrap'
import { useEffect } from 'react'
import { useProject } from '../../../projects/core/ProjectContext';
import ShareTreeViewHeader from './ShareTreeViewHeader'
import { RootState } from '../../../../store'
import { useDispatch, useSelector } from 'react-redux'
import { updateShow } from '../../treeLoader'
import { updateCollectedLeafData } from '../../treedata'


interface TreeNodeProps {
  folder: {
    _id: { $oid: string };
    name: string;
    parent_wbs?: { $oid: string };
    data: { $oid: string };
    subwbs: any;

  };
}

type Props = {
  share_type: string
}


const SharedTreeView = ({share_type}: Props) => {
  const { expandedKeys, setExpandedKeys, setTreeData, treeData } = useTree();
  const { setProject, setOriginalVersion, setVersion, project, setWbsDataDateFirstStepAsync, setWbsDataDateSecondStepAsync } = useProject();
  const knownWbs = useSelector((state: RootState) => state.treedata.knownWbs)
  const projectversiondata = useSelector((state: RootState) => state.versiondata.data)
  const dispatch = useDispatch()


  const getLastSegment = (path: string) => {
    const segments = path.split('/')
    return segments[segments.length - 1]
  }

  useEffect(() => {

    const initializeNode = async () => {

      const targetNodeId = project?.share_wbs.$oid

      const nodesMap = new Map<string, DataNode>()
      const buildMap = (nodes: DataNode[], map: Map<string, DataNode>) => {
        nodes.forEach((node) => {
          map.set(node.key as string, node)
          if (node.children) {
            buildMap(node.children, map)
          }
        })
      }
      buildMap(treeData, nodesMap)

      const targetNode = nodesMap.get(targetNodeId)

      if (targetNode && knownWbs[targetNodeId]?.name != getLastSegment(project?.name || '')) {
        dispatch(updateShow(true))
        const fullPath = buildFullPath(targetNode as EventDataNode<DataNode>, nodesMap)
        
        let combinedVersion
        if (share_type === 'live') {
        const leafData = await collectLeafData(targetNode);
        dispatch(updateCollectedLeafData(leafData));

        combinedVersion = await combineVersionsV2(knownWbs[targetNodeId]?.name, leafData, true, setWbsDataDateFirstStepAsync, setWbsDataDateSecondStepAsync);
        const wbsData = projectversiondata[knownWbs[targetNodeId].data.$oid];
        combinedVersion = combinedVersion ? combinedVersion : wbsData;
      } else {
        combinedVersion = projectversiondata[knownWbs[targetNodeId].data.$oid]
      }
        const titleElement = targetNode.title as React.ReactElement<TreeNodeProps>
        setOriginalVersion(combinedVersion)
        setVersion(combinedVersion)
        if (titleElement && titleElement.props && titleElement.props.folder) {
          const folderName = fullPath
          setProject((prev) => {
            if (prev) {
              const [baseName, existingFolderName] = prev.name.split('/')
              const newName = existingFolderName
                ? `${baseName}/${folderName}`
                : `${prev.name}/${folderName}`
              return { ...prev, name: newName }
            }
            return prev
          })
        }
        dispatch(updateShow(false))
      }
    };

    initializeNode();

  }, [project?.share_wbs.$oid, knownWbs, treeData, projectversiondata]);



  const loadData = async (treeNode: EventDataNode<DataNode>) => {

    const childWbs = project?.generated_wbs_list?.find(wbs => wbs._id.$oid === treeNode.key.toString());


    if (!childWbs) {
      throw new Error(`WBS item with id ${treeNode.key.toString()} not found`);
    }

    const childData: DataNode[] = await Promise.all(childWbs.subwbs.map(async (item) => {
      let response = project?.generated_wbs_list?.find(wbs => wbs._id.$oid === item.$oid);

      if (!response) {
        throw new Error(`WBS item with id ${item.$oid} not found`);
      }

      return {
        key: response._id.$oid,
        name: response.name,
        title: <TreeFolderItem folder={response} isShare={true} />,
        switcherIcon: response.subwbs.length ? undefined : <></>,
      };
    }));
    setTreeData((origin) => {
      return updateTreeData(origin, treeNode.key.toString(), childData);
    });
  };

  const buildFullPath = (node: EventDataNode<DataNode>, nodesMap: Map<string, DataNode>): string => {
    const titleElement = node.title as React.ReactElement<TreeNodeProps>;
    if (!titleElement || !titleElement.props || !titleElement.props.folder) {
      return '';
    }

    let path = titleElement.props.folder.name;
    let parentKey = titleElement.props.folder.parent_wbs?.$oid;

    while (parentKey) {
      const parentNode = nodesMap.get(parentKey);
      if (!parentNode) break;

      const parentTitleElement = parentNode.title as React.ReactElement<TreeNodeProps>;
      if (!parentTitleElement || !parentTitleElement.props || !parentTitleElement.props.folder) {
        break;
      }

      path = `${parentTitleElement.props.folder.name}/${path}`;
      parentKey = parentTitleElement.props.folder.parent_wbs?.$oid;
    }

    return path;
  };

  const collectLeafData = async (node: DataNode): Promise<any[]> => {
    const leafData: any[] = [];
    const traverse = async (currentNode: DataNode) => {
      const titleElement = currentNode.title as React.ReactElement<TreeNodeProps>;
      if (titleElement && titleElement.props && titleElement.props.folder) {
        if (!titleElement.props.folder.subwbs || titleElement.props.folder.subwbs.length === 0) {

          let versionData = project?.generated_wbs_data?.find(data => data._id.$oid === titleElement.props.folder.data.$oid);

          if (!versionData) {
            throw new Error(`WBS item data with id ${titleElement.props.folder.data.$oid} not found`);
          }

          leafData.push(versionData);
        } else {
          for (const child of titleElement.props.folder.subwbs) {
            // Fetch child node data if not present in nodesMap
            if (project?.checked_wbs) {
              const includeNode = project.checked_wbs.some(wbs => wbs.$oid === child.$oid);
              if (includeNode) {
                const childWbsData = project?.generated_wbs_list?.find(wbs => wbs._id.$oid === child.$oid);

                if (!childWbsData) {
                  throw new Error(`WBS item with id ${child.$oid} not found`);
                }

                const childNode: DataNode = {
                  key: childWbsData._id.$oid,
                  title: <TreeFolderItem folder={childWbsData} isShare={true} />,
                };
                await traverse(childNode);
              }
            }

          }
        }
      }
    };
    await traverse(node);
    return leafData;
  };

  const handleClick = async (node: EventDataNode<DataNode>) => {

    if (!node.title) {
      return;
    }

    dispatch(updateShow(true))

    const nodesMap = new Map<string, DataNode>();
    const buildMap = (nodes: DataNode[], map: Map<string, DataNode>) => {
      nodes.forEach((node) => {
        map.set(node.key as string, node);
        if (node.children) {
          buildMap(node.children, map);
        }
      });
    };
    buildMap(treeData, nodesMap);

    const fullPath = buildFullPath(node, nodesMap);

    let combinedVersion
    if(share_type === 'live')
      {
        const leafData = await collectLeafData(node);
        combinedVersion = await combineVersionsV2(knownWbs[node.key]?.name, leafData, true, setWbsDataDateFirstStepAsync, setWbsDataDateSecondStepAsync);
        const wbsData = projectversiondata[knownWbs[node.key].data.$oid];
        combinedVersion = combinedVersion ? combinedVersion : wbsData;
      } else {
        combinedVersion = projectversiondata[knownWbs[node.key].data.$oid]
      }
    const titleElement = node.title as React.ReactElement<TreeNodeProps>;


    setOriginalVersion(combinedVersion);
    if (titleElement && titleElement.props && titleElement.props.folder) {
      const folderName = fullPath;
      setProject((prev) => {
        if (prev) {
          const [baseName, existingFolderName] = prev.name.split('/');
          const newName = existingFolderName
            ? `${baseName}/${folderName}`
            : `${prev.name}/${folderName}`;
          return { ...prev, name: newName };
        }
        return prev;
      });
    }
    dispatch(updateShow(false))

  };



  return (
    <>
      <ShareTreeViewHeader />
      <div>
        <Row>
          <Tree
            style={{ overflowX: 'scroll' }}
            selectable={false}
            switcherIcon={SwitcherIcon}
            onExpand={(expandedKeys, { expanded, node }) =>
              setExpandedKeys((prev) =>
                !expanded ? prev.filter((item) => item !== node.key) : [...prev, node.key.toString()]
              )
            }
            onClick={(event, node) => handleClick(node)}
            expandedKeys={expandedKeys}
            loadData={loadData}
            treeData={treeData}


          />
        </Row>
      </div>
    </>
  );
};

export default SharedTreeView;
