import {ApexOptions} from 'apexcharts'
import {option} from '../../components/model/option'
import {formatNumber} from '../../utils/formatter'
import {CustomCurve} from '../simulations/core/_models'
import {ChartUnit} from '../simulations/simulationSinglePage'
import {getCSSVariableValue} from '../../../_metronic/assets/ts/_utils'
import * as Yup from 'yup'

const RECORD_TO_IGNORE = 'IGNORE_THIS_RECORD_6DBE8ADAD7F9'

export function compareNumericString(rowA: any, rowB: any, id: any, desc: any) {
  let a = Number.parseFloat(rowA.values[id])
  let b = Number.parseFloat(rowB.values[id])
  if (Number.isNaN(a)) {
    // Blanks and non-numeric strings to bottom
    a = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY
  }
  if (Number.isNaN(b)) {
    b = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY
  }
  if (a > b) return 1
  if (a < b) return -1
  return 0
}

export const LibraryHeaders: any[] = [
  {
    Header: 'Curve Name',
    accessor: 'name',
  },
  {
    Header:'Category',
    accessor:'category',
  },
  {
    Header: 'Duration',
    accessor: 'duration',
    sortType: compareNumericString,
  },
  {
    Header: 'Start Date',
    accessor: 'start_date',
    sortType: (a: any, b: any) => {
      return (
        new Date(b.original.start_date.$date).getTime() -
        new Date(a.original.start_date.$date).getTime()
      )
    },
  },
  {
    Header: 'Preview',
    accessor: 'preview',
    disableSortBy: true,
  },
  {
    Header: 'Actions',
    accessor: '',
    disableSortBy: true,
  },
]

export const tangentOptions: option[] = [
  {
    label: 'Maximum Smoothing',
    value: 5,
  },
  {
    label: 'Slow Accelerating',
    value: 3.05,
  },
  {
    label: 'Acceleration from single to double',
    value: 2,
  },
  {
    label: 'Standard Accelerating',
    value: 1.25,
  },
  {
    label: 'Rapid Accelerating',
    value: 1.1,
  },
  {
    label: 'Sudden Accelerating',
    value: 0.65,
  },
]

export const axisOptions: option[] = [
  {
    label: 'Front Loaded S-Curve',
    value: -1.38,
  },
  {
    label: 'Symetric S-Curve',
    value: 0,
  },
  {
    label: 'Standard S-Curve',
    value: 0.33,
  },
  {
    label: 'Back LoadedS-Curve',
    value: 1.38,
  },
]

type ChartCustomCurve = {
  name: string
  id: string
  values: (number | null)[]
  show: boolean
  color: string
}

export function getChartOptions(
  height: number,
  chartUnit: ChartUnit,
  data: any[],
  data2?: any[],
  labels?: any[],
  custom_curve?: any,
  highlightIdx?: number,
  float_formatter: number = 2
): ApexOptions {
  const labelColor = getCSSVariableValue('--kt-gray-500')
  const borderColor = getCSSVariableValue('--kt-gray-200')
  const baseColor = getCSSVariableValue('--kt-info')
  return {
    series: [
      {
        type: 'column',
        name: 'Percentage of periodic progress',
        data: data2 || [],
      },
      {
        type: 'line',
        name: 'Percentage of cumulative progress',
        data: data || [],
      },
      ...custom_curve
        ?.filter((curve: ChartCustomCurve) => curve.name !== RECORD_TO_IGNORE && curve.show)
        ?.map((curve: CustomCurve) => ({
          type: 'line',
          name: curve.name,
          data: curve.values.slice(0, data2?.length || curve.values.length),
          color: curve.color,
        })),
    ],
    chart: {
      animations: {
        enabled: false,
      },
      fontFamily: 'inherit',
      height: 400,
      type: 'line',
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,

          customIcons: [],
        },
      },
    },
    tooltip: {
      enabled: true,
      shared: true,
      y: {
        formatter: (val: any) => {
          if (!val) return '- ' + chartUnit.suffix
          return formatNumber(val * chartUnit.ratio, float_formatter, '.') + ' ' + chartUnit.suffix
        },
      },
    },
    legend: {
      show: false,
      fontSize: '15px',
      position: 'top',
      offsetY: 15,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'straight',
      show: true,
      width: 3,
    },
    xaxis: {
      categories: labels || [],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: baseColor,
          width: 1,
          dashArray: 3,
        },
      },
      decimalsInFloat: float_formatter,
    },
    yaxis: [
      {
        labels: {
          formatter: (value: number) =>
            formatNumber(value * chartUnit.ratio, float_formatter, '.') + ' ' + chartUnit.suffix,
          style: {
            colors: labelColor,
          },
        },
        opposite: true,
        title: {
          text: 'Periodic',
        },
      },
      {
        title: {
          text: 'Cumulative',
        },
        max: 100,
        labels: {
          formatter: (value: number) =>
            formatNumber(value * chartUnit.ratio, float_formatter, '.') + ' ' + chartUnit.suffix,
          style: {
            colors: labelColor,
          },
        },
      },
      ...custom_curve?.map((curve: CustomCurve) => ({
        max: 100,
        labels: {
          show: false,
        },
      })),
    ],
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    colors: [
      ({value, seriesIndex, dataPointIndex, w}: any) => {
        if (!data2 || !highlightIdx) return '#14D193'
        if (dataPointIndex === highlightIdx) return '#FFB800'
        return '#14D193'
      },
      '#0B69FF',
    ],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: baseColor,
      strokeWidth: 3,
    },
  }
}

export const curveValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  tangent: Yup.number()
    .typeError('Tangent is a number')
    .required('Tangent is required')
    .min(0.6, 'Exceeded minimal value')
    .max(5, 'Exceeded maximal value'),
  axis: Yup.number()
    .typeError('Axis is a number')
    .required('Axis is required')
    .min(-1.4, 'Exceeded minimal value')
    .max(1.39, 'Exceeded maximal value'),
  start_date: Yup.date().required('Start date is required'),
  duration: Yup.number().typeError('Duration is a number').positive('Duration should be positive'),
})
