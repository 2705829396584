import { createContext, Dispatch, FC, SetStateAction, useContext, useMemo, useState } from 'react'
import { WithChildren } from '../../../../_metronic/helpers'
import { sweetAlert } from '../../../utils/funcs'
import { getProjectData } from '../../../utils/project-data'
import { getCustomCurve } from '../components/overview/Project'
import { ForecastFormula, ProjectModel, ProjectObject } from './_models'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { getProjectEED } from '../../../utils/project-eed'
import { getCalendar } from '../../calendars/_requests'
import { getDiffrentBettwenDate } from '../../../utils/data-transformarion/date-utils'
import { getProjectDataFirstStep } from '../../../utils/project-data-step1'
import { getProjectDataSecondStep } from '../../../utils/project-data-step2'
import { useDispatch, useSelector } from 'react-redux'
import { incrementLoadedWithDelay, updateLabel, updateLoaded, updateTotal } from '../../wbs/treeLoader'
import { AppDispatch, RootState } from '../../../store'
import { getVersionById } from './_requests'
import { DataNode } from 'antd/es/tree'

type ProjectContextProps = {
  project: ProjectModel | undefined
  version: ProjectObject | undefined
  isRebaseLined: boolean
  originalVersion: ProjectObject | undefined
  setProject: Dispatch<SetStateAction<ProjectModel | undefined>>
  setVersion: Dispatch<SetStateAction<ProjectObject | undefined>>
  setDataDate: (data_date: Date, isShared: boolean, changes?: Partial<ProjectObject>) => void
  setDataDateAsync: (data_date: Date, isShared: boolean, changes?: Partial<ProjectObject>) => Promise<void>
  setWpDataDateAsync: (data: any, setData: React.Dispatch<React.SetStateAction<ProjectObject>>, data_date: Date, changes?: Partial<ProjectObject>) => void
  setWbsDataDateAsync: (wps: any[], data_date: Date, changes?: Partial<ProjectObject>) => Promise<any[]>;
  setWbsDataDateFirstStepAsync: (name: string, wps: any[], data_date: Date, changes?: Partial<ProjectObject>) => Promise<any[]>;
  setWbsDataDateSecondStepAsync: (name: string, data: any, data_date: Date, isShared?: boolean, changes?: Partial<ProjectObject>) => Promise<any>;
  setPatchedWpData: (name: string, data: any, data_date: Date, changes?: Partial<ProjectObject>) => Promise<any>
  setDashboardDataDate: (data_date: Date, projects?: ProjectObject[], setProjects?: React.Dispatch<React.SetStateAction<ProjectObject[]>>, changes?: Partial<ProjectObject>) => void
  setProjectDashboardDataDate: (data_date: Date, projects?: ProjectObject[], setProjects?: React.Dispatch<React.SetStateAction<ProjectObject[]>>, changes?: Partial<ProjectObject>) => void
  setOriginalVersion: Dispatch<SetStateAction<ProjectObject | undefined>>
  setLoading: Dispatch<SetStateAction<boolean>>
  displayVersion: ProjectObject | undefined
  loading: boolean
  beforeFirstStep: any[]
  afterFirstStep: any[]
  beforeSecondStep: any
  treeData: DataNode[]
  setTreeData: Dispatch<SetStateAction<DataNode[]>>
}

const initProjectContextState: ProjectContextProps = {
  project: undefined,
  version: undefined,
  originalVersion: undefined,
  setProject: () => { },
  setVersion: () => { },
  setDataDate: () => { },
  setDataDateAsync: async () => { },
  setWpDataDateAsync: async () => { },
  setWbsDataDateAsync: async () => [],
  setWbsDataDateFirstStepAsync: async () => [],
  setWbsDataDateSecondStepAsync: async () => { },
  setPatchedWpData: async () => { },
  setDashboardDataDate: () => { },
  setProjectDashboardDataDate: () => { },
  setOriginalVersion: () => { },
  setLoading: () => { },
  displayVersion: undefined,
  loading: false,
  isRebaseLined: false,
  beforeFirstStep: [],
  afterFirstStep: [],
  beforeSecondStep: undefined,
  treeData: [],
  setTreeData: () => { },
}

const ProjectContext = createContext<ProjectContextProps>(initProjectContextState)

const useProject = () => useContext(ProjectContext)


const downloadJSON = (data: any, filename: string | undefined) => {
  const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
  // saveAs(blob, filename);
};


const ProjectProvider: FC<WithChildren> = ({ children }) => {
  const navigate = useNavigate()
  const [project, setProject] = useState<ProjectModel | undefined>(undefined)
  const [version, setVersion] = useState<ProjectObject | undefined>(undefined)
  const [originalVersion, setOriginalVersion] = useState<ProjectObject | undefined>(undefined)
  const [loading, setLoading] = useState(false)
  const [isRebaseLined, setRebaselined] = useState(false)
  const [beforeFirstStep, setBeforeFirstStep] = useState<any[]>([])
  const [afterFirstStep, setAfterFirstStep] = useState<any[]>([])
  const [beforeSecondStep, setBeforeSecondStep] = useState<any>(undefined)
  const versionsdata = useSelector((state: RootState) => state.versiondata.data)
  const loadingTotal = useSelector((state: RootState) => state.treeLoader.total);
  const [treeData, setTreeData] = useState<DataNode[]>([])
  const dispatch = useDispatch();
  const trigger = useDispatch<AppDispatch>();
  useMemo(() => {
    setRebaselined(originalVersion?.rebase_line && originalVersion?.rebase_line?.length > 0 ? true : false)
  }, [originalVersion])

  //function that changes data date of a version to display
  //should take in a date and run the project data script

  const checkCompleteData = (data_date: Date, id: string) => {
    let originalVersion = versionsdata[id]
    const dateDiff = getDiffrentBettwenDate(
      data_date.getTime(),
      originalVersion?.start_date.$date || 0,
      originalVersion?.period_count.type || ''
    )
    return !(
      dateDiff > getCustomCurve('cumulativeEarnedValue', originalVersion).length - 1 ||
      dateDiff > getCustomCurve('cumulativeActualCost', originalVersion).length - 1
    )
  }

  const checkCompleteDatav2 = (data_date: Date, originalVersion: ProjectObject | undefined) => {
    if (!originalVersion) return true
    if (data_date.getTime() < (originalVersion?.start_date.$date || 0)) return true
    const dateDiff = getDiffrentBettwenDate(
      data_date.getTime(),
      originalVersion?.start_date.$date || 0,
      originalVersion?.period_count.type || ''
    )
    return !(
      dateDiff > getCustomCurve('cumulativeEarnedValue', originalVersion).length - 1 ||
      dateDiff > getCustomCurve('cumulativeActualCost', originalVersion).length - 1
    )
  }





  const setDataDate = (data_date: Date, isShared: boolean = false, changes?: Partial<ProjectObject>) => {
    if (!originalVersion) return
    const newVersion = { ...originalVersion }
    newVersion.data_date = { $date: data_date.getTime() }
    delete newVersion.output

    const threeMonthsAfterStartDate = dayjs(newVersion.start_date.$date).add(3, 'month').toDate().getTime();
    const newProjectLength = getDiffrentBettwenDate(
      newVersion.data_date.$date,
      dayjs(newVersion.start_date.$date).startOf('month').toDate().getTime(),
      newVersion.period_count.type
    )
    newVersion.custom_curve = [
      {
        color: 'black',
        name: 'cumulativePlannedValue',
        values: getCustomCurve('cumulativePlannedValue', newVersion),
      },
      {
        color: 'white',
        name: 'cumulativeEarnedValue',
        values:
          data_date.getTime() < originalVersion.start_date.$date
            ? []
            : getCustomCurve('cumulativeEarnedValue', newVersion).slice(0, newProjectLength + 1),
      },
      {
        color: 'red',
        name: 'cumulativeActualCost',
        values:
          data_date.getTime() < originalVersion.start_date.$date
            ? []
            : getCustomCurve('cumulativeActualCost', newVersion).slice(0, newProjectLength + 1),
      },
      {
        color: 'green',
        name: 'worstCaseBaseline',
        values: getCustomCurve('worstCaseBaseline', newVersion) || undefined,
      },
    ]
    if (data_date.getTime() < originalVersion.start_date.$date) {
      setVersion({ ...newVersion, output: {} })
      return
    }

    if (data_date.getTime() < threeMonthsAfterStartDate) {
      setLoading(true)
      newVersion.forecast_settings = {
        ...newVersion.forecast_settings,
        forecast_formula: ForecastFormula['Custom Finish Date'],
        custom_end_date: newVersion.end_date
      }

      getProjectData(
        changes ? { ...newVersion, ...changes } : newVersion,
        newVersion?.associated_calendar,
        project?.sector,
        false
      ).then((res) => {
        setVersion({ ...newVersion, ...changes, output: res })
      })
        .finally(() => setLoading(false))
      return
    }


    if (!(checkCompleteDatav2(data_date, originalVersion))) {
      setVersion({ ...newVersion, output: {} })
      {
        {
          !isShared && sweetAlert({
            title: 'Data Missing',

            icon: 'info',
            html: '<p>Data for this date is missing<br> Fill in the missing data to see the forecast</p>',
          }).then(() => navigate(`/project/${project?._id.$oid}/data`))
        }
      }
    } else {
      setLoading(true)
      newVersion.forecast_settings = {
        ...newVersion.forecast_settings,
        forecast_formula: ForecastFormula['Auto'],
        custom_end_date: newVersion.end_date
      }
      setTimeout(() => {
        getProjectData(
          changes ? { ...newVersion, ...changes } : newVersion,
          newVersion?.associated_calendar,
          project?.sector,
          false
        )
          .then((res) => {
            setVersion({ ...newVersion, ...changes, output: res })
          })
          .finally(() => setLoading(false))
      })
    }
  }

  const setDataDateAsync = async (data_date: Date, isShared: boolean = false, changes?: Partial<ProjectObject>) => {
    if (!originalVersion) return
    const newVersion = { ...originalVersion }
    newVersion.data_date = { $date: data_date.getTime() }
    delete newVersion.output
    const newProjectLength = getDiffrentBettwenDate(
      newVersion.data_date.$date,
      dayjs(newVersion.start_date.$date).startOf('month').toDate().getTime(),
      newVersion.period_count.type
    )
    newVersion.custom_curve = [
      {
        color: 'black',
        name: 'cumulativePlannedValue',
        values: getCustomCurve('cumulativePlannedValue', newVersion),
      },
      {
        color: 'white',
        name: 'cumulativeEarnedValue',
        values:
          data_date.getTime() < originalVersion.start_date.$date
            ? []
            : getCustomCurve('cumulativeEarnedValue', newVersion).slice(0, newProjectLength + 1),
      },
      {
        color: 'red',
        name: 'cumulativeActualCost',
        values:
          data_date.getTime() < originalVersion.start_date.$date
            ? []
            : getCustomCurve('cumulativeActualCost', newVersion).slice(0, newProjectLength + 1),
      },
      {
        color: 'green',
        name: 'worstCaseBaseline',
        values: getCustomCurve('worstCaseBaseline', newVersion) || undefined,
      },
    ]
    if (data_date.getTime() < originalVersion.start_date.$date) {
      setVersion({ ...newVersion, output: {} })
      return
    }
    if (!checkCompleteDatav2(data_date, originalVersion)) {
      setVersion({ ...newVersion, output: {} })
      {
        {
          !isShared && sweetAlert({
            title: 'Data Missing',

            icon: 'info',
            html: '<p>Data for this date is missing<br> Fill in the missing data to see the forecast</p>',
          }).then(() => navigate(`/project/${project?._id.$oid}/data`))
        }
      }
    } else {
      setLoading(true)
      try {
        const res: any = await getProjectData(
          changes ? { ...newVersion, ...changes } : newVersion,
          project?.associated_calendar,
          project?.sector,
          !isShared
        );
        setVersion({ ...newVersion, ...changes, output: res })
      } finally {
        setLoading(false);
      }
    }
  }

  const setWpDataDateAsync = async (
    data: any,
    setData: React.Dispatch<React.SetStateAction<ProjectObject>>,
    data_date: Date,
    changes?: Partial<ProjectObject>) => {
    if (!data) return
    const versionData = { ...data }
    versionData.data_date = { $date: data_date.getTime() }
    delete versionData.output

    const threeMonthsAfterStartDate = dayjs(versionData.start_date.$date).add(3, 'month').toDate().getTime();
    const newProjectLength = getDiffrentBettwenDate(
      versionData.data_date.$date,
      dayjs(versionData.start_date.$date).startOf('month').toDate().getTime(),
      versionData.period_count.type
    )
    versionData.custom_curve = [
      {
        color: 'black',
        name: 'cumulativePlannedValue',
        values: getCustomCurve('cumulativePlannedValue', versionData),
      },
      {
        color: 'white',
        name: 'cumulativeEarnedValue',
        values:
          data_date.getTime() < data.start_date.$date
            ? []
            : getCustomCurve('cumulativeEarnedValue', versionData).slice(0, newProjectLength + 1),
      },
      {
        color: 'red',
        name: 'cumulativeActualCost',
        values:
          data_date.getTime() < data.start_date.$date
            ? []
            : getCustomCurve('cumulativeActualCost', versionData).slice(0, newProjectLength + 1),
      },
      {
        color: 'green',
        name: 'worstCaseBaseline',
        values: getCustomCurve('worstCaseBaseline', versionData) || undefined,
      },
    ]
    if (data_date.getTime() < data.start_date.$date) {
      setData({ ...versionData, output: {} })
      return
    }

    if (data_date.getTime() < threeMonthsAfterStartDate) {
      try {

        versionData.forecast_settings = {
          ...versionData.forecast_settings,
          forecast_formula: ForecastFormula['Custom Finish Date'],
          custom_end_date: versionData.end_date
        }

        const res: any = await getProjectData(
          changes ? { ...versionData, ...changes } : versionData,
          versionData?.associated_calendar,
          versionData?.sector,
          false
        );
        setData({ ...versionData, ...changes, output: res })
      } catch {
        console.log('Error Calculating Forecast')
      }
      return
    }

    if (!checkCompleteData(data_date, versionData._id.$oid)) {

      setData({ ...versionData, output: {} })
      return

    } else {

      try {

        versionData.forecast_settings = {
          ...versionData.forecast_settings,
          forecast_formula: ForecastFormula['Auto'],
        }

        const res: any = await getProjectData(
          changes ? { ...versionData, ...changes } : versionData,
          versionData?.associated_calendar,
          versionData?.sector,
          false
        );
        setData({ ...versionData, ...changes, output: res })
      } catch {
        console.log('Error Calculating Forecast')
      }

    }
  }

  const setWbsDataDateAsync = async (
    wps: any[],
    data_date: Date,
    changes?: Partial<ProjectObject>): Promise<any[]> => {

    const promises = wps.map(async (data) => {


      const versionData = { ...data }
      versionData.data_date = { $date: data_date.getTime() }
      delete versionData.output

      const threeMonthsAfterStartDate = dayjs(versionData.start_date.$date).add(3, 'month').toDate().getTime();
      const newProjectLength = getDiffrentBettwenDate(
        versionData.data_date.$date,
        dayjs(versionData.start_date.$date).startOf('month').toDate().getTime(),
        versionData.period_count.type
      )
      versionData.custom_curve = [
        {
          color: 'black',
          name: 'cumulativePlannedValue',
          values: getCustomCurve('cumulativePlannedValue', versionData),
        },
        {
          color: 'white',
          name: 'cumulativeEarnedValue',
          values:
            data_date.getTime() < data.start_date.$date
              ? []
              : getCustomCurve('cumulativeEarnedValue', versionData).slice(0, newProjectLength + 1),
        },
        {
          color: 'red',
          name: 'cumulativeActualCost',
          values:
            data_date.getTime() < data.start_date.$date
              ? []
              : getCustomCurve('cumulativeActualCost', versionData).slice(0, newProjectLength + 1),
        },
        {
          color: 'green',
          name: 'worstCaseBaseline',
          values: getCustomCurve('worstCaseBaseline', versionData) || undefined,
        },
      ]
      if (data_date.getTime() < data.start_date.$date) {
        versionData.output = {}
      }

      if (data_date.getTime() < threeMonthsAfterStartDate) {
        try {

          versionData.forecast_settings = {
            ...versionData.forecast_settings,
            forecast_formula: ForecastFormula['Custom Finish Date'],
            custom_end_date: versionData.end_date
          }

          const res: any = await getProjectData(
            changes ? { ...versionData, ...changes } : versionData,
            versionData?.associated_calendar,
            versionData?.sector,
            false
          );
          versionData.output = res
        } catch {
          console.log('Error Calculating Forecast')
        }
      }

      if (!checkCompleteData(data_date, versionData._id.$oid)) {

        versionData.output = {}

      } else {

        try {

          versionData.forecast_settings = {
            ...versionData.forecast_settings,
            forecast_formula: ForecastFormula['Auto'],
            custom_end_date: versionData.end_date
          }

          const res: any = await getProjectData(
            changes ? { ...versionData, ...changes } : versionData,
            versionData?.associated_calendar,
            versionData?.sector,
            false
          );
          versionData.output = res
        } catch {
          console.log('Error Calculating Forecast')
        }

      }
      return versionData
    })
    const updatedWps = await Promise.all(promises);
    console.log("Wps updated");
    console.log(updatedWps)
    updatedWps.map((wp, idx) => {
      console.log('Wp n\' ' + idx);
      console.log('Value: ');
      console.log(wp);
    })


    return updatedWps
  }

  const setWbsDataDateFirstStepAsync = async (
    name: string,
    wps: any[],
    data_date: Date,
    changes?: Partial<ProjectObject>
  ): Promise<any[]> => {

    downloadJSON(wps, `wps-before-first-step.json`);
    setBeforeFirstStep(wps)

    dispatch(updateLabel('Calculating Data for ' + name))
    dispatch(updateTotal(wps.length + 1))
    dispatch(updateLoaded(0))
    await new Promise((resolve) => setTimeout(resolve, 300));
    const threeMonthsAfterStartDate = (date: any) => dayjs(date).add(3, 'month').toDate().getTime();

    const promises = wps.map(async (data) => {

      const versionData = { ...data }
      versionData.data_date = { $date: data_date.getTime() }
      delete versionData.output

      const newProjectLength = getDiffrentBettwenDate(
        versionData.data_date.$date,
        dayjs(versionData.start_date.$date).startOf('month').toDate().getTime(),
        versionData.period_count.type
      )
      versionData.custom_curve = [
        {
          color: 'black',
          name: 'cumulativePlannedValue',
          values: getCustomCurve('cumulativePlannedValue', versionData),
        },
        {
          color: 'white',
          name: 'cumulativeEarnedValue',
          values:
            data_date.getTime() < data.start_date.$date
              ? []
              : getCustomCurve('cumulativeEarnedValue', versionData).slice(0, newProjectLength + 1),
        },
        {
          color: 'red',
          name: 'cumulativeActualCost',
          values:
            data_date.getTime() < data.start_date.$date
              ? []
              : getCustomCurve('cumulativeActualCost', versionData).slice(0, newProjectLength + 1),
        },
        {
          color: 'green',
          name: 'worstCaseBaseline',
          values: getCustomCurve('worstCaseBaseline', versionData) || undefined,
        },
      ]
      let originalVersion = versionsdata[versionData._id.$oid]
      if (!originalVersion && versionData._id.$oid !== '') {
        originalVersion = await getVersionById(versionData._id.$oid)
      }

      if (data_date.getTime() < data.start_date.$date) {
        versionData.output = {}
        // ev = pv
        // ac = pv
      }

      // check if last pv = last ev return trajaa custom_curve

      if (data_date.getTime() < threeMonthsAfterStartDate(versionData.start_date.$date)) {
        try {
          versionData.forecast_settings = {
            ...versionData.forecast_settings,
            forecast_formula: ForecastFormula['Custom Finish Date'],
            custom_end_date: versionData.end_date
          }

          const res: any = await getProjectDataFirstStep(
            changes ? { ...versionData, ...changes } : versionData,
            versionData?.associated_calendar,
            versionData?.sector,
            false
          );
          versionData.output = res
        } catch {
          console.log('Error Calculating Forecast')
        }


      } else if (!checkCompleteDatav2(data_date, originalVersion)) {

        versionData.output = {}

      } else {

        try {

          const res: any = await getProjectDataFirstStep(
            changes ? { ...versionData, ...changes } : versionData,
            versionData?.associated_calendar,
            versionData?.sector,
            false
          );
          versionData.output = res
        } catch {
          console.log('Error Calculating Forecast')
        }
      }
      trigger(incrementLoadedWithDelay())
      await new Promise((resolve) => setTimeout(resolve, 300));
      return versionData
    })
    const updatedWps = await Promise.all(promises);
    console.log("Wps updated");
    console.log(updatedWps)
    updatedWps.map((wp, idx) => {
      console.log('Wp n\' ' + idx);
      console.log('Value: ');
      console.log(wp);
    })

    downloadJSON(updatedWps, `wps-after-first-step.json`);
    setAfterFirstStep(updatedWps);
    return updatedWps
  }


  const setWbsDataDateSecondStepAsync = async (
    name: string,
    data: any,
    data_date: Date,
    isShared: boolean = false,
    changes?: Partial<ProjectObject>
  ): Promise<any> => {
    downloadJSON(data, `wbs-before-second-step.json`);
    setBeforeSecondStep(data);

    if (!data) return;

    dispatch(updateLabel('Calculating Data for ' + name));
    await new Promise((resolve) => setTimeout(resolve, 300));
    const newVersion = { ...data };
    newVersion.data_date = { $date: data_date.getTime() };
    delete newVersion.output;

    const plannedValues = data.output.plannedValues;
    const earnedValues = data.output.earnedValues;
    const actualCosts = data.output.actualCosts;

    const threeMonthsAfterStartDate = dayjs(newVersion.start_date.$date).add(3, 'month').toDate().getTime();
    const newProjectLength = getDiffrentBettwenDate(
      newVersion.data_date.$date,
      dayjs(newVersion.start_date.$date).startOf('month').toDate().getTime(),
      newVersion.period_count.type
    );
    newVersion.custom_curve = [
      {
        color: 'black',
        name: 'cumulativePlannedValue',
        values: getCustomCurve('cumulativePlannedValue', newVersion),
      },
      {
        color: 'white',
        name: 'cumulativeEarnedValue',
        values: data_date.getTime() < data.start_date.$date
          ? []
          : getCustomCurve('cumulativeEarnedValue', newVersion).slice(0, newProjectLength + 1),
      },
      {
        color: 'red',
        name: 'cumulativeActualCost',
        values: data_date.getTime() < data.start_date.$date
          ? []
          : getCustomCurve('cumulativeActualCost', newVersion).slice(0, newProjectLength + 1),
      },
      {
        color: 'green',
        name: 'worstCaseBaseline',
        values: getCustomCurve('worstCaseBaseline', newVersion) || undefined,
      },
    ];

    let originalVersion = versionsdata[data._id.$oid]
    if (!originalVersion && data._id.$oid !== '') {
      originalVersion = await getVersionById(data._id.$oid)
    }

    if (data_date.getTime() < data.start_date.$date) {
      trigger(incrementLoadedWithDelay())
      return { ...newVersion, output: {} };
    }

    if (data_date.getTime() < threeMonthsAfterStartDate) {
      try {
        newVersion.forecast_settings = {
          ...newVersion.forecast_settings,
          forecast_formula: ForecastFormula['Custom Finish Date'],
          custom_end_date: newVersion.end_date,
        };

        const res: any = await getProjectDataSecondStep(
          changes ? { ...newVersion, ...changes } : newVersion,
          plannedValues,
          earnedValues,
          actualCosts,
          newVersion?.associated_calendar,
          project?.sector,
          false
        );
        const finalVersion = { ...newVersion, ...changes, output: res };
        downloadJSON(finalVersion, `wbs-after-second-step.json`);
        trigger(incrementLoadedWithDelay());
        return finalVersion;
      } catch {
        console.log('Error Calculating Forecast');
        trigger(incrementLoadedWithDelay());
        return { ...newVersion, ...changes, output: {} };
      }

    }

    if (!checkCompleteDatav2(data_date, originalVersion)) {
      const incompleteDataVersion = { ...newVersion, output: {} };
      if (!isShared) {
        sweetAlert({
          title: 'Data Missing',
          icon: 'info',
          html: '<p>Data for this date is missing<br> Fill in the missing data to see the forecast</p>',
        }).then(() => navigate(`/project/${project?._id.$oid}/data`));
      }
      trigger(incrementLoadedWithDelay());
      return incompleteDataVersion;
    } else {
      const res = await getProjectDataSecondStep(
        changes ? { ...newVersion, ...changes } : newVersion,
        plannedValues,
        earnedValues,
        actualCosts,
        newVersion?.associated_calendar,
        project?.sector,
        false
      );
      const finalVersion = { ...newVersion, ...changes, output: res };
      downloadJSON(finalVersion, `wbs-after-second-step.json`);
      trigger(incrementLoadedWithDelay());
      return finalVersion;
    }
  };

  const setPatchedWpData = async (
    name: string,
    data: any,
    data_date: Date,
    changes?: Partial<ProjectObject>
  ): Promise<any> => {


    dispatch(updateLabel('Calculating Data for ' + name))
    dispatch(updateTotal(2))
    dispatch(updateLoaded(0))
    await new Promise((resolve) => setTimeout(resolve, 300));
    const threeMonthsAfterStartDate = (date: any) => dayjs(date).add(3, 'month').toDate().getTime();


    const versionData = { ...data }
    versionData.data_date = { $date: data_date.getTime() }
    delete versionData.output

    const newProjectLength = getDiffrentBettwenDate(
      versionData.data_date.$date,
      dayjs(versionData.start_date.$date).startOf('month').toDate().getTime(),
      versionData.period_count.type
    )
    versionData.custom_curve = [
      {
        color: 'black',
        name: 'cumulativePlannedValue',
        values: getCustomCurve('cumulativePlannedValue', versionData),
      },
      {
        color: 'white',
        name: 'cumulativeEarnedValue',
        values:
          data_date.getTime() < data.start_date.$date
            ? []
            : getCustomCurve('cumulativeEarnedValue', versionData).slice(0, newProjectLength + 1),
      },
      {
        color: 'red',
        name: 'cumulativeActualCost',
        values:
          data_date.getTime() < data.start_date.$date
            ? []
            : getCustomCurve('cumulativeActualCost', versionData).slice(0, newProjectLength + 1),
      },
      {
        color: 'green',
        name: 'worstCaseBaseline',
        values: getCustomCurve('worstCaseBaseline', versionData) || undefined,
      },
    ]

    if (data_date.getTime() < data.start_date.$date) {
      versionData.output = {}
      // ev = pv
      // ac = pv
    }

    // check if last pv = last ev return trajaa custom_curve

    if (data_date.getTime() < threeMonthsAfterStartDate(versionData.start_date.$date)) {
      try {
        versionData.forecast_settings = {
          ...versionData.forecast_settings,
          forecast_formula: ForecastFormula['Custom Finish Date'],
          custom_end_date: versionData.end_date
        }

        const res: any = await getProjectData(
          changes ? { ...versionData, ...changes } : versionData,
          versionData?.associated_calendar,
          versionData?.sector,
          false
        );
        trigger(incrementLoadedWithDelay());
        return { ...versionData, ...changes, output: res };

      } catch {
        console.log('Error Calculating Forecast')
        trigger(incrementLoadedWithDelay());
        return { ...versionData, ...changes, output: {} };
      }


    }


    try {

      const res: any = await getProjectData(
        changes ? { ...versionData, ...changes } : versionData,
        versionData?.associated_calendar,
        versionData?.sector,
        false
      );
      trigger(incrementLoadedWithDelay());
      return { ...versionData, ...changes, output: res };
    } catch {
      console.log('Error Calculating Forecast')
      trigger(incrementLoadedWithDelay());
      return { ...versionData, ...changes, output: {} };
    }
  }



  const setDashboardDataDate = async (
    data_date: Date,
    projects?: any[],
    setProjects?: React.Dispatch<React.SetStateAction<ProjectObject[]>>,
    changes?: Partial<ProjectObject>
  ) => {
    console.log("setdash called correctly with " + projects?.length + " projects and " + data_date + " data_date");

    if (projects && setProjects) {
      const promises = projects.map(async (p) => {
        const newVersion = { ...p };
        newVersion.data_date = { $date: data_date.getTime() };
        delete newVersion.output;

        const threeMonthsAfterStartDate = dayjs(newVersion.start_date.$date).add(3, 'month').toDate().getTime();
        const newProjectLength = getDiffrentBettwenDate(
          newVersion.data_date.$date,
          dayjs(newVersion.start_date.$date).startOf('month').toDate().getTime(),
          newVersion.period_count.type
        );

        newVersion.custom_curve = [
          { color: 'black', name: 'cumulativePlannedValue', values: getCustomCurve('cumulativePlannedValue', newVersion) },
          { color: 'white', name: 'cumulativeEarnedValue', values: data_date.getTime() < p.start_date.$date ? [] : getCustomCurve('cumulativeEarnedValue', newVersion).slice(0, newProjectLength + 1) },
          { color: 'red', name: 'cumulativeActualCost', values: data_date.getTime() < p.start_date.$date ? [] : getCustomCurve('cumulativeActualCost', newVersion).slice(0, newProjectLength + 1) },
          { color: 'green', name: 'worstCaseBaseline', values: getCustomCurve('worstCaseBaseline', newVersion) || undefined }
        ];

        const originalVersion = await getVersionById(newVersion._id.$oid)
        const dataDate = dayjs(originalVersion.data_date.$date).toDate().getTime();

        let forecastable: boolean = false;

        if (data_date.getTime() < threeMonthsAfterStartDate) {
          newVersion.output = {
            estimatedEndDate: newVersion.end_date.$date,
            forecast_msg: "This project is not forecastable because there isn't enough data right now (min 3 months from the start date). We'll use the planned finish date as EED."
          };
        } else if (data_date.getTime() > dataDate) {
          newVersion.output = {
            estimatedEndDate: p.output.CardsData.SchedulePerformance.EED.Value.replace(/é/g, 'e'),
            forecast_msg: "This project is not forecastable because it hasn't data at the selected time. We'll use the data of the last available date ( " + dayjs(p.data_date.$date).format('MMM YYYY') + " )"
          };
        } else if (data_date.getTime() >= newVersion.start_date.$date && checkCompleteDatav2(data_date, p)) {
          setLoading(true);
          forecastable = true
          try {
            let res: any;

            if (p?.associated_calendar) {
              const cal = await getCalendar(p.associated_calendar.$oid);
              res = await getProjectEED(newVersion, cal);
            } else {
              res = await getProjectEED(newVersion);
            }

            newVersion.output = {
              estimatedEndDate: res?.EED?.Value.replace(/é/g, 'e'),
              forecast_msg: 'This project is forecasted successfully'
            };
            console.log("res estimated EED VALUE: " + res?.EED?.Value);
          } catch (error) {
            console.error("Error fetching calendar or project EED:", error);
            newVersion.output = {
              estimatedEndDate: null,
              forecast_msg: 'Failed to forecast this project'
            };
          } finally {
            setLoading(false);
          }

        } else {
          newVersion.output = {
            estimatedEndDate: newVersion.end_date.$date,
            forecast_msg: "This project is not forecastable because it hasn't started yet OR it does not have complete data. We'll use the planned finish date as the end date."
          };
        }

        return {
          ...p,
          output: {
            ...p.output,
            estimatedEndDate: newVersion.output.estimatedEndDate
          },
          forecastable: forecastable,
          forecast_msg: newVersion.output.forecast_msg
        };

      });

      const updatedProjects = await Promise.all(promises);
      console.log("Projects updated");
      console.log(updatedProjects)
      updatedProjects.map(project => {
        console.log('name: ' + project.name);
        console.log('EED: ' + project.output.estimatedEndDate);
      })
      setProjects(updatedProjects);
      console.log("Projects updated");
    }
  };

  const setProjectDashboardDataDate = async (
    data_date: Date,
    datas?: any[],
    setDatas?: React.Dispatch<React.SetStateAction<ProjectObject[]>>,
    changes?: Partial<ProjectObject>
  ) => {
    console.log("setdash called correctly with " + datas?.length + " datas and " + data_date + " data_date");

    if (datas && setDatas) {
      const promises = datas.map(async (p) => {
        const newVersion = { ...p, data_date: { $date: data_date.getTime() } };
        delete newVersion.output;

        const threeMonthsAfterStartDate = dayjs(newVersion.start_date.$date).add(3, 'month').toDate().getTime();
        const newProjectLength = getDiffrentBettwenDate(
          newVersion.data_date.$date,
          dayjs(newVersion.start_date.$date).startOf('month').toDate().getTime(),
          newVersion.period_count.type
        );

        newVersion.custom_curve = [
          { color: 'black', name: 'cumulativePlannedValue', values: getCustomCurve('cumulativePlannedValue', newVersion) },
          { color: 'white', name: 'cumulativeEarnedValue', values: data_date.getTime() < p.start_date.$date ? [] : getCustomCurve('cumulativeEarnedValue', newVersion).slice(0, newProjectLength + 1) },
          { color: 'red', name: 'cumulativeActualCost', values: data_date.getTime() < p.start_date.$date ? [] : getCustomCurve('cumulativeActualCost', newVersion).slice(0, newProjectLength + 1) },
          { color: 'green', name: 'worstCaseBaseline', values: getCustomCurve('worstCaseBaseline', newVersion) || undefined }
        ];


        let realStartIndex = -1;

        ['cumulativeActualCost', 'cumulativeEarnedValue'].some((curveName) => {
          const curve = newVersion.custom_curve?.find((curve: any) => curve.name === curveName);
          if (curve) {
            const index = curve.values.findIndex((value: number) => value !== 0);
            if (index !== -1) {
              realStartIndex = index;
              return true;
            }
          }
          return false;
        });

        if (realStartIndex !== -1) {
          let realStartDate;
          switch (newVersion.period_count.type) {
            case 'daily':
              realStartDate = new Date(newVersion.start_date.$date + realStartIndex * (24 * 60 * 60 * 1000));
              break;
            case 'weekly':
              realStartDate = new Date(newVersion.start_date.$date + realStartIndex * (7 * 24 * 60 * 60 * 1000));
              break;
            case 'monthly':
              realStartDate = new Date(dayjs(newVersion.start_date.$date).add(realStartIndex, 'month').toDate());
              break;
            default:
              realStartDate = new Date(dayjs(newVersion.start_date.$date).add(realStartIndex, 'month').toDate()); // Fallback to monthly
          }
          newVersion.real_start_date = realStartDate;
        }

        let forecastable: boolean = false;

        let originalVersion = versionsdata[newVersion._id.$oid]
        if (!originalVersion)
          originalVersion = await getVersionById(newVersion._id.$oid)
        const earnedValuesLength = getCustomCurve('cumulativeEarnedValue', originalVersion).length
        const dataDate = dayjs(originalVersion.data_date.$date).toDate().getTime();
        const startDate = dayjs(newVersion.start_date.$date).startOf('month').toDate().getTime()
        if (data_date.getTime() < threeMonthsAfterStartDate) {
          newVersion.output = {
            estimatedEndDate: newVersion.end_date.$date,
            forecast_msg: "This project is not forecastable because there isn't enough data right now (min 3 months from the start date). We'll use the planned finish date as EED."
          };
        } else if (data_date.getTime() > dataDate) {
          newVersion.output = {
            estimatedEndDate: p.output.CardsData.SchedulePerformance.EED.Value.replace(/é/g, 'e'),
            forecast_msg: "This project is not forecastable because it hasn't data at the selected time. We'll use the data of the last available date ( " + dayjs(p.data_date.$date).format('MMM YYYY') + " )"
          };
        } else if (data_date.getTime() >= startDate && checkCompleteDatav2(data_date, p)) {

          forecastable = true
          try {

            newVersion.output = {
              forecast_msg: 'This project is forecasted successfully'
            };

          } catch (error) {
            console.error("Error fetching calendar or project EED:", error);
            newVersion.output = {
              forecast_msg: 'Failed to forecast this project'
            };
          }

        } else {
          newVersion.output = {
            estimatedEndDate: newVersion.end_date.$date,
            forecast_msg: "This project is not forecastable because it hasn't started yet OR it does not have complete data. We'll use the planned finish date as the end date."
          };
        }

        return {
          ...p,
          forecastable: forecastable,
          forecast_msg: newVersion.output.forecast_msg,
          real_start_date: newVersion.real_start_date
        };

      });

      const updatedProjects = await Promise.all(promises);
      console.log("Before Projects updated");
      console.log(datas)
      console.log("Projects updated");
      console.log(updatedProjects)
      updatedProjects.map(project => {
        console.log('name: ' + project.name);
        console.log('EED: ' + project.output.estimatedEndDate);
      })
      setDatas(updatedProjects);
      console.log("Projects updated");
    }
  };

  return (
    <ProjectContext.Provider
      value={{
        isRebaseLined,
        project,
        version,
        setProject,
        setVersion,
        originalVersion,
        setDataDate,
        setDataDateAsync,
        setWpDataDateAsync,
        setWbsDataDateAsync,
        setWbsDataDateFirstStepAsync,
        setWbsDataDateSecondStepAsync,
        setPatchedWpData,
        setDashboardDataDate,
        setProjectDashboardDataDate,
        setOriginalVersion,
        setLoading,
        displayVersion: version || originalVersion,
        loading,
        beforeFirstStep,
        afterFirstStep,
        beforeSecondStep,
        treeData,
        setTreeData,
      }}
    >
      {children}
    </ProjectContext.Provider>
  )
}

export { ProjectContext, useProject, ProjectProvider }