import GaugeComponent from 'react-gauge-component'
import { useProject } from '../../../core/ProjectContext'
import { getCSSVariableValue } from '../../../../../../_metronic/assets/ts/_utils'
import { formatFloatFromString } from '../../../../../utils/funcs'
import { KTSVG } from '../../../../../../_metronic/helpers'
import clsx from 'clsx'
import { formatNumber } from '../../../../../utils/formatter'
import { formatScheduleVariance } from '../_helpers'
import { useEffect, useState } from 'react'
import { useThemeMode } from '../../../../../../_metronic/partials'

type Props = { type: 'CPI' | 'SPI', isRebaseLined?: boolean }

export default function CPIGauge({ type, isRebaseLined }: Props) {
  const { displayVersion, project } = useProject()

  const warningColor = getCSSVariableValue('--kt-warning')
  const successColor = getCSSVariableValue('--kt-success')
  const dangerColor = getCSSVariableValue('--kt-danger')
  const [dark, setDark] = useState(getCSSVariableValue('--kt-dark'))
  const cpiDiff = parseFloat(
    (
      displayVersion?.output?.cpi?.cumulative.at(-1) -
      displayVersion?.output?.cpi?.cumulative.at(-2)
    ).toFixed(displayVersion?.float_formatter || 0)
  )

  const spiDiff = parseFloat(
    (displayVersion?.output?.SPICum?.at(-1) - displayVersion?.output?.SPICum?.at(-2)).toFixed(
      displayVersion?.float_formatter || 0
    )
  )

  const realValue =
    (type === 'CPI'
      ? displayVersion?.output?.cpi?.cumulative?.at(-1)
      : displayVersion?.output?.SPICum?.at(-1)) || 0
  const gaugeValue = realValue > 2 ? 2 : realValue < 0 ? 0 : realValue

  const { mode } = useThemeMode()
  useEffect(() => {
    setDark(getCSSVariableValue('--kt-dark'))
  }, [mode])

  return (
    <div className='d-flex flex-column align-items-center justify-content-end'>
      <GaugeComponent
        minValue={0}
        maxValue={2}
        style={{ width: '350px', textAlign: 'center' }}
        labels={{
          valueLabel: {
            formatTextValue: (value: number) =>
              (type === 'CPI'
                ? displayVersion?.output?.cpi?.cumulative?.at(-1)
                : displayVersion?.output?.SPICum?.at(-1)) === undefined
                ? '-'
                : realValue.toFixed(displayVersion?.float_formatter || 0),

            style: {
              fill:
                realValue < 0.8
                  ? getCSSVariableValue('--kt-danger')
                  : realValue >= 1
                    ? getCSSVariableValue('--kt-success')
                    : getCSSVariableValue('--kt-warning'),
              textShadow: 'none',
            },
          },
          tickLabels: {
            ticks: [{ value: 0 }, { value: 1 }, { value: 2 }],
            defaultTickLineConfig: {
              style: { fill: dark },
            },
            defaultTickValueConfig: {
              style: {
                color: dark,
                fill: dark,
              },
            },
          },
        }}
        arc={{
          width: 0.15,
          padding: 0.03,
          subArcs: [
            {
              limit: +(project?.gauge_params?.red_limit ?? 0.8),
              color: dangerColor,
            },

            {
              limit: +(project?.gauge_params?.yellow_limit ?? 1),
              color: warningColor,
            },
            { color: successColor },
          ],
        }}
        value={gaugeValue}
        pointer={{ type: 'arrow', elastic: true, color: dark }}
        type='radial'
      />
      {type === 'CPI' ? (
        <div className='text-center fs-5 fw-bold'>
          <div className='d-flex justify-content-center gap-3'>
            <h2>CPI</h2>
            <div className='d-flex rotate active opacity-50'>
              {cpiDiff ? (
                <KTSVG
                  className={clsx('svg-icon svg-icon-2 mb-2', {
                    'text-danger rotate-n90': cpiDiff < 0,
                    'text-success rotate-90': cpiDiff > 0,
                    'text-muted': !cpiDiff,
                  })}
                  path='/media/icons/duotune/arrows/arr026.svg'
                />
              ) : (
                <div
                  style={{ height: '1.5rem', width: '1.5rem' }}
                  className='bg-secondary mb-2 me-1'
                />
              )}
              <h6
                className={clsx({
                  'text-danger': cpiDiff < 0,
                  'text-success': cpiDiff >= 0,
                  'text-muted': !cpiDiff,
                })}
              >
                {formatNumber(cpiDiff, displayVersion?.float_formatter || 0, '.')}
              </h6>
            </div>
          </div>
          <div>
            CV ={' '}
            {displayVersion?.output?.CardsData?.CostPerformance?.CV?.Cumulative !== undefined
              ? formatFloatFromString(
                displayVersion?.output?.CardsData?.CostPerformance?.CV?.Cumulative,
                displayVersion?.float_formatter || 0
              )
              : '-'}
          </div>
          <div>
            EAC={' '}
            {displayVersion?.output?.CardsData?.CostPerformance?.EAC?.Value !== undefined
              ? formatFloatFromString(
                displayVersion?.output?.CardsData?.CostPerformance?.EAC?.Value,
                displayVersion?.float_formatter || 0
              )
              : '-'}
          </div>
        </div>
      ) : (
        <div className='text-center fs-5 fw-bold'>
          <div className='d-flex justify-content-center gap-3'>
            <h2>SPI</h2>
            <div className='d-flex rotate active opacity-50'>
              {spiDiff ? (
                <KTSVG
                  className={clsx('svg-icon svg-icon-2 mb-2', {
                    'text-danger rotate-n90': spiDiff < 0,
                    'text-success rotate-90': spiDiff > 0,
                    'text-muted': !spiDiff,
                  })}
                  path='/media/icons/duotune/arrows/arr026.svg'
                />
              ) : (
                <div
                  style={{ height: '1.5rem', width: '1.5rem' }}
                  className='bg-secondary mb-2 me-1'
                />
              )}
              <h6
                className={clsx({
                  'text-danger': spiDiff < 0,
                  'text-success': spiDiff >= 0,
                  'text-muted': !spiDiff,
                })}
              >
                {formatNumber(spiDiff, displayVersion?.float_formatter || 0, '.')}
              </h6>
            </div>
          </div>
          <div>
            SV(t) ={' '}
            {displayVersion?.output?.CardsData?.SchedulePerformance?.SVT?.cumulative
              ? formatScheduleVariance(displayVersion?.output?.SVtCum?.at(-1))
              : '-'}
          </div>
          <div>
            EED={' '}
            {displayVersion?.output?.CardsData?.SchedulePerformance?.EED?.Value
              ? displayVersion?.output.CardsData.SchedulePerformance.EED.Value
              : '-'}
          </div>
        </div>
      )}
    </div>
  )
}
