import {Calendar} from '../../modules/calendars/_models'
import {ForecastFormula} from '../../modules/projects/core/_models'
import {getRecommendations} from '../../modules/projects/core/_requests'
import {getForecast} from '../forecast'

export const forecastAuto = async (
  inputs: any,
  data: any,
  cumulativeEarnedValuesAsPercentages: any,
  SPIt: number[],
  EarnedSchedule: number[],
  calendar?: Calendar,
  sector?: string
): Promise<{
  tableData: (string[] | moment.Moment[])[]
  nbOfRemainingMonths: number
}> => {
  const recommendations = await getRecommendations()
  const chosenForecastMethod =
    recommendations.find((item) => item.sector === sector)?.forecast_formula ||
    ForecastFormula['EV Fitting correlated to baseline profile']

  console.log('chosenForecastMethod', chosenForecastMethod)
  // the above line was causing problems of assigning the chosenForecastMethod value
  // data.forecast_settings.forecast_formula = chosenForecastMethod
  const newData = {...data, forecast_settings: {...data.forecast_settings, forecast_formula: chosenForecastMethod}}
  return getForecast(
    inputs,
    newData,
    cumulativeEarnedValuesAsPercentages,
    SPIt,
    EarnedSchedule,
    calendar,
    sector
  )
}
