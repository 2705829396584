import {calculateOptimizedOutputs, dampenProportion, trimExtension} from '../../helpers/optimizers'
import {Calendar} from '../../modules/calendars/_models'
import {
  applyCalendarToCurve,
  getCapacity,
  getReelDays,
  stripCalendarFromCurve,
} from '../../modules/library/ProjectCurve/Context/ProjectCurveHelpers'
import {calculateUnsplitEstimatedOutputs} from '../../modules/library/ProjectCurve/core'
import {CustomCurveWithCalendar} from '../../modules/library/_models'
import {getPeriodicFromCumulative} from '../../modules/projects/components/data/prepareDataTable'
import {getCustomCurve} from '../../modules/projects/components/overview/Project'
import moment from 'moment'

export const standardizeCurve = async (curve: CustomCurveWithCalendar) => {
  let valuesInPerc = curve.values.map((value) => (value * 100) / curve.total)
  if (curve.associated_calendar) {
    const {reelDays, totalDays} = await getReelDays(
      curve.start_date,
      curve.duration,
      curve.associated_calendar
    )
    const capacity = getCapacity(reelDays, totalDays)
    valuesInPerc = stripCalendarFromCurve(capacity, curve.values)
  }
  const res = calculateUnsplitEstimatedOutputs(
    {nbOfMonthsPassed: curve.duration, projectDurationUnit: 'month'},
    {cols: valuesInPerc},
    true
  )
  return {
    tangent: res?.tg,
    axis: res?.axis,
  }
}

const forecastBaselineFitting = async (
  inputs: any,
  data: any,
  cumulativeEarnedValuesAsPercentages: any,
  calendar?: Calendar
) => {
  const cumulativePlannedValuesAsPercentages = getCustomCurve('cumulativePlannedValue', data).map(
    (item) => (+item / data.budget_at_completion.amount) * 100
  )
 
  let capacity: number[] | undefined = undefined
  if (data.curve_settings?.tangent && data.curve_settings?.offset) {

    inputs.mu = data.curve_settings?.tangent
    inputs.sig = data.curve_settings?.offset
  } else {
 
    const {axis, tangent} = await standardizeCurve({
      associated_calendar: calendar,
      color: '',
      duration: cumulativePlannedValuesAsPercentages.length,
      name: 'test',
      start_date: new Date(data.start_date.$date),
      total: 100,
      values: cumulativePlannedValuesAsPercentages,
      unit: 'currency',
    })
    inputs.mu = tangent
    inputs.sig = axis
  }



  let EarnedValue: any = calculateOptimizedOutputs(
    {
      ...inputs,
      nbOfMonthsPassed: inputs.nbOfTimeUnitPassed + 1,
      nbOfTimeUnitPassed: inputs.nbOfTimeUnitPassed + 1,
    },
    cumulativeEarnedValuesAsPercentages
  )

  EarnedValue = trimExtension(
    cumulativeEarnedValuesAsPercentages,
    EarnedValue,
    5,
    getPeriodicFromCumulative(cumulativeEarnedValuesAsPercentages).reduce(
      (acc, value) => (+value > +acc ? +value : +acc),
      0
    )
  )

  if (calendar) {
    const {reelDays, totalDays} = await getReelDays(
      new Date(data.start_date.$date),
      EarnedValue.tableData[1].length,
      calendar
    )
    capacity = getCapacity(reelDays, totalDays)
    EarnedValue = {
      estimatedEndDate: moment((EarnedValue as any).estimatedEndDate) as any,
      ...applyCalendarToCurve(capacity, EarnedValue),
    }
    EarnedValue.tableData[0] = EarnedValue.tableData[0].map((item: any) => moment(item))
  }

  EarnedValue = dampenProportion(cumulativeEarnedValuesAsPercentages, EarnedValue)

  return EarnedValue
}

export {forecastBaselineFitting}
