export const getDeepClone = (objOrArr: any[] | Record<any, any>) => {
  return JSON.parse(JSON.stringify(objOrArr));
};

export const updateReqBody = (data: any, keysToUpdate: any) => {
  return Object.fromEntries(
    Object.entries(data).map(([k, v]) =>
      k in keysToUpdate ? [keysToUpdate[k], v] : [k, v]
    )
  );
};

export const getRafmoidsFromReqBody = (data: any) => {
  data = [
    ...data.map((el: any) => ({
      ...el,
      type: el.is_start ? 'raf' : 'sigmoid',
    })),
  ];

  return data.sort((a: any, b: any) =>
    a._id.$oid < b._id.$oid ? 1 : a._id.$oid > b._id.$oid ? -1 : 0
  );
};

export const getSortedRafmoids = (data: any, col: string, order: string) => {
  switch (col) {
    case 'creationDate':
      return data.sort((a: any, b: any) => {
        if (order === 'DESC')
          return a._id.$oid < b._id.$oid ? 1 : a._id.$oid > b._id.$oid ? -1 : 0;
        return a._id.$oid > b._id.$oid ? 1 : a._id.$oid < b._id.$oid ? -1 : 0;
      });
    case 'name':
      return data.sort((a: any, b: any) => {
        if (order === 'DESC')
          return a.name.toLowerCase() < b.name.toLowerCase()
            ? 1
            : a.name.toLowerCase() > b.name.toLowerCase()
            ? -1
            : 0;
        return a.name.toLowerCase() > b.name.toLowerCase()
          ? 1
          : a.name.toLowerCase() < b.name.toLowerCase()
          ? -1
          : 0;
      });
    default:
      return data.sort((a: any, b: any) => {
        if (order === 'DESC')
          return a[col] < b[col] ? 1 : a[col] > b[col] ? -1 : 0;
        return a[col] > b[col] ? 1 : a[col] < b[col] ? -1 : 0;
      });
  }
};

export const getFakeEvent = (target: Record<string, any>) => {
  return { target };
};

export const getTitleCase = (s: string) => s[0].toUpperCase() + s.substring(1);

export const getSortedArrOfObjects = (
  arrOfObjects: Record<string, any>[],
  sortKey: string,
  sortOrder: 'ASC' | 'DESC' = 'ASC'
) =>
  arrOfObjects.sort((a: any, b: any) =>
    a[sortKey] < b[sortKey]
      ? sortOrder === 'ASC'
        ? -1
        : 1
      : a[sortKey] > b[sortKey]
      ? sortOrder === 'ASC'
        ? 1
        : -1
      : 0
  );
