import { DataNode } from 'antd/es/tree';
import { Dispatch, FC, SetStateAction, createContext, useContext, useEffect, useState } from 'react';
import { WithChildren } from '../../../../../_metronic/helpers';
import { getWbs } from '../../_requests';
import { useDispatch, useSelector } from 'react-redux';
import { addWbsToStore } from '../../treedata';
import { RootState } from '../../../../store';
import ChartTreeFolderItem from './ChartTreeFolderItem';

type TreeViewProps = {
  treeData: DataNode[];
  setTreeData: Dispatch<SetStateAction<DataNode[]>>;
  expandedKeys: string[];
  setExpandedKeys: Dispatch<SetStateAction<string[]>>;
  isLoading: boolean;
  setDisplayNodeNumber: Dispatch<SetStateAction<boolean>>;
  displayNodeNumber: boolean;
};

const initTreeViewProps: TreeViewProps = {
  treeData: [],
  setTreeData: () => { },
  expandedKeys: [],
  setExpandedKeys: () => { },
  isLoading: true,
  setDisplayNodeNumber: () => { },
  displayNodeNumber: false,
};

// Create context with the updated type
const TreeContext = createContext<TreeViewProps>(initTreeViewProps);

// Custom hook for using the context
const useTree = () => useContext(TreeContext);

interface TreeProviderProps extends WithChildren {
  projectsData: any[];
}

const TreeProvider: FC<TreeProviderProps> = ({ children, projectsData }) => {
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [treeData, setTreeData] = useState<DataNode[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [displayNodeNumber, setDisplayNodeNumber] = useState(false);

  const dispatch = useDispatch();
  const knownWbs = useSelector((state: RootState) => state.treedata.knownWbs);

  useEffect(() => {
    const loadAllWbs = async (wbsId: string, parentWbsOid: string | null = null): Promise<DataNode | void> => {
      let knownWbsItem = knownWbs[wbsId];
      if (!knownWbsItem) {
        const response = await getWbs(wbsId);

        // If a parent WBS is provided, update the response with its parent_wbs?.$oid
        if (parentWbsOid) {
          response.parent_wbs = { $oid: parentWbsOid };
        }

        dispatch(addWbsToStore(response));
        knownWbsItem = response;
      }

      const childrenData: any = await Promise.all(
        knownWbsItem.subwbs.map(async (subWbs) => {
          return await loadAllWbs(subWbs.$oid, knownWbsItem._id.$oid);
        })
      );

      if (knownWbsItem.data) {
        return {
          key: knownWbsItem._id.$oid,
          title: <ChartTreeFolderItem folder={knownWbsItem} />,
          children: childrenData.filter((item: any) => item),
          isLeaf: childrenData.length === 0,
        };
      }
    };

    const initializeTree = async () => {
      const rootNodes: DataNode[] = [];

      for (const project of projectsData) {
        const defaultWbs = project.default_wbs?.$oid;
        if (defaultWbs) {
          const projectNode = await loadAllWbs(defaultWbs, 'root');
          if (projectNode) {
            rootNodes.push(projectNode);
            setExpandedKeys((keys) => [...keys, projectNode.key.toString()]);
          }
        }
      }

      setTreeData(rootNodes);
      setIsLoading(false);
    };

    initializeTree();

  }, [projectsData]);

  return (
    <TreeContext.Provider
      value={{
        expandedKeys,
        isLoading,
        setExpandedKeys,
        setTreeData,
        treeData,
        displayNodeNumber,
        setDisplayNodeNumber,
      }}
    >
      {children}
    </TreeContext.Provider>
  );
};

export { TreeProvider, useTree };
