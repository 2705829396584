import {useQuery} from 'react-query'
import {getFolder} from './_requests'
import {Folder} from './_models'

export const useFolder = (
  folderId?: string,
  onSuccess?: (data: Folder) => void,
  onError?: any,
  queryKey?: string
) =>
  useQuery(['folderId', queryKey || folderId], () => getFolder(folderId), {
    onError,
    onSuccess,
    refetchOnWindowFocus: false,
  })
