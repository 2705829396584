import React, { useState, useEffect } from 'react';
import '../wpStyles/TableStyle.scss';
import { useLocation } from 'react-router-dom'
import '../../projects/components/data/Data.scss'
import { DateFormatType } from '../../../utils/funcs';
import '../../projects/components/settings/Date.scss'
import { getLabels, getMaxArrayLength, normalizeDateUnit } from '../../../helpers/DateFormatter';
import EarnedValueTable from '../../projects/components/data/EarnedValueTable';
import PlannedValueTable from '../../projects/components/data/PlannedValueTable';
import ActualCostTable from '../../projects/components/data/ActualCostTable';
import DateInputs from '../../projects/components/data/DateInputs';
import { getCustomCurve } from '../../projects/components/overview/Project';
import WorstCastBaseline from '../../projects/components/data/WorstCastBaseline';


interface WpDataProps {
  wbsData: any;
  wbsId?: string;
  handleUpdateWbsDataDate?: any
}

const WbsData: React.FC<WpDataProps> = ({ wbsData, handleUpdateWbsDataDate, wbsId }) => {


  const normalizedDateUnit = normalizeDateUnit(wbsData?.period_count?.type)

  const cumulative_planned_value_length = getCustomCurve('cumulativePlannedValue', wbsData)?.length || 0
  const cumulative_earned_value_length = wbsData?.output?.earnedValues?.mixed?.cumulative?.length || 0
  const cumulative_actual_cost_length = wbsData?.output?.actualCosts?.mixed?.cumulative?.length || 0



  const label_length = Math.max(cumulative_actual_cost_length, cumulative_earned_value_length, cumulative_planned_value_length)
  const labels = getLabels(
    wbsData?.start_date?.$date || 0,
    label_length ||
    0,
    normalizedDateUnit,
    wbsData?.date_format as DateFormatType
  )

  const dataDateIndex = labels.findIndex(
    (value: any) =>
      value ===
      getLabels(
        wbsData?.data_date?.$date || 0,
        1,
        normalizedDateUnit,
        wbsData?.date_format as DateFormatType
      )[0]
  )


  return (
    <div>
      <div style={{ overflowX: 'auto', maxWidth: '900px' }}>
        <div className="container">
          {wbsData && <DateInputs data={wbsData} isEditable={false} handleUpdateWbsDataDate={handleUpdateWbsDataDate} isWbs={true} />}
        </div>
      </div>
      {wbsData &&
        <>
          <PlannedValueTable data={wbsData} labels={labels} isEditable={false} />
          <EarnedValueTable data={wbsData} dataDateIndex={dataDateIndex} labels={labels} isEditable={false} />
          <ActualCostTable data={wbsData} dataDateIndex={dataDateIndex} labels={labels} isEditable={false} />
          {!!getCustomCurve('worstCaseBaseline', wbsData) &&
            !!getCustomCurve('worstCaseBaseline', wbsData).length && (
              <WorstCastBaseline labels={labels} />
            )}
        </>
      }
    </div>
  );
};

export default WbsData;