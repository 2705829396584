import * as Yup from 'yup';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import LanguagesDropDown from '../../components/customLang';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import Terms from '../auth/components/terms';

export function PasswordPage({ onSubmitPassword }: any) {
  const [password, setPassword] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState('');
  const [attempts, setAttempts] = useState(0);
  const [isLocked, setIsLocked] = useState(false);
  const [countdown, setCountdown] = useState(0);  // State to hold countdown in seconds

  useEffect(() => {
    const checkLockout = () => {
      const lockoutTime = localStorage.getItem('lockoutTime');
      if (lockoutTime) {
        const timeLeft = Number(lockoutTime) - new Date().getTime();
        if (timeLeft > 0) {
          setIsLocked(true);
          setCountdown(Math.ceil(timeLeft / 1000));  // Set initial countdown state
          return timeLeft;
        } else {
          localStorage.removeItem('lockoutTime');  // Clear outdated lockout time
          setIsLocked(false);
        }
      }
      return null;
    };

    let timeLeft = checkLockout();
    if (timeLeft) {
      const interval = setInterval(() => {
        timeLeft = checkLockout();
        if (timeLeft === null) {
          clearInterval(interval);
        }
      }, 1000);  // Update countdown every second

      return () => clearInterval(interval);  // Cleanup interval on component unmount
    }
  }, [isLocked]);

  const handlePasswordSubmit = () => {
    if (isLocked) {
      setErrorMessage(`Please wait ${countdown} seconds before trying again.`);
      return;
    }

    if (attempts < 3) {
      onSubmitPassword(password).finally(() => {
        setErrorMessage('Password incorrect');
        setPassword("");
        setAttempts(prev => prev + 1);
      });
    } else {
      setIsLocked(true);
      const lockoutPeriod = 60000;  // 60 seconds lockout
      const lockoutTime = new Date().getTime() + lockoutPeriod;
      localStorage.setItem('lockoutTime', lockoutTime.toString()); // Set lockout time
      setCountdown(lockoutPeriod / 1000);  // Set countdown for display
      setErrorMessage('Maximum attempts exceeded. Please try again later.');
      setTimeout(() => {
        setIsLocked(false);
        setAttempts(0);  // Reset attempts after the lockout period
        localStorage.removeItem('lockoutTime');  // Clear lockout time from storage
      }, lockoutPeriod);
    }
  };

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      {/* begin::Body */}
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
        <div className='row'>
          <div className=' col-5 col-md-8 '></div>
          <div className='col-7  col-md-4 '>
            <LanguagesDropDown />
          </div>
        </div>

        {/* begin::Form */}
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          {/* begin::Wrapper */}
          <div className='w-lg-500px p-10'>

            <div

            >
              {/* begin::Heading */}
              <div></div>
              <div className='text-center mb-11'>
                <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
                <div className='text-gray-500 fw-semibold fs-6'>Welcome to project curve</div>
              </div>
              {/* begin::Heading */}
              {errorMessage && (<div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{errorMessage}</div>
              </div>)}

              {/* begin::Form group */}
              <div className='fv-row mb-3'>
                <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
                <input
                  type='password'
                  autoComplete='off'
                  placeholder='Enter your password'
                  className={clsx(
                    'form-control bg-transparent',

                  )}
                  onChange={(e) => setPassword(e.target.value)}
                />


              </div>
              {/* end::Form group */}

              {/* begin::Wrapper */}
              <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
                <div />

              </div>
              {/* end::Wrapper */}

              {/* begin::Action */}
              <div className='d-grid mb-10'>
                <button
                  type='submit'
                  id='kt_sign_in_submit'
                  className='btn btn-primary'
                  onClick={handlePasswordSubmit}
                  disabled={isLocked} // Disable button during lockout


                >
                  Submit
                </button>
                {isLocked && <div style={{ color: 'red' }}>Please wait {countdown} sec before trying again.</div>}

              </div>
              {/* end::Action */}

            </div>












          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}

        {/* begin::Footer */}
        <div className='d-flex flex-center flex-wrap px-5'>
          {/* begin::Links */}
          <div className='d-flex fw-semibold text-primary fs-base'>
            <span
              data-bs-toggle='modal'
              style={{ cursor: 'pointer' }}
              data-bs-target='#kt_modal_1'
              className='px-5'
            >
              Terms
            </span>

            <a href='https://project-curve.com' className='px-5' target='_blank'>
              Plans
            </a>

            <a href='https://project-curve.com' className='px-5' target='_blank'>
              Contact Us
            </a>
          </div>
          {/* end::Links */}
        </div>
        {/* end::Footer */}
      </div>
      {/* end::Body */}

      {/* begin::Aside */}
      <div
        className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2'
        style={{ backgroundImage: `url(${toAbsoluteUrl('/media/misc/auth-bg.png')})` }}
      >
        {/* begin::Content */}
        <div className='d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100'>
          {/* begin::Logo d-none d-lg-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2*/}
          <Link to='/' className='mb-0 mb-lg-12'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/custom-1.png')}
              className='h-60px h-lg-75px'
            />
          </Link>
          {/* end::Logo */}

          {/* begin::Image */}
          <img
            className='d-none d-lg-block mx-auto w-275 w-md-50 w-xl-500px mb-10 mb-lg-20'
            src={toAbsoluteUrl('/media/misc/auth-screens.png')}
            alt=''
          />
          {/* end::Image */}

          {/* begin::Title */}
          <h1 className='d-none d-lg-block text-white fs-2qx fw-bolder text-center mb-7'>
            Anticipate to control better
          </h1>
          {/* end::Title */}

          {/* begin::Text */}
          <div className='d-none d-lg-block text-white fs-base text-center'>
            Predicting the time and cost of your project, the time when you take your morning coffee
            or tea, is now possible with PROJECT CURVE. Fast, reliable and simple... Simulate your
            project's timeline in a few clicks!
          </div>
          {/* end::Text */}
        </div>
        {/* end::Content */}
      </div>

      {/* end::Aside */}
      <Terms />
    </div>
  )





}