import { DataNode } from 'antd/es/tree'
import { Dispatch, FC, SetStateAction, createContext, useContext, useState } from 'react'
import { WithChildren } from '../../../../../_metronic/helpers'
import { useWbs } from '../../_queries'
import TreeWbsItem from './TreeWbsItem'
import { useProject } from '../../../projects/core/ProjectContext'
import { ProjectObject } from '../../../projects/core/_models'
import { Wbs } from '../../_models'

type TreeViewProps = {
  treeData: DataNode[]
  setTreeData: Dispatch<SetStateAction<DataNode[]>>
  expandedKeys: string[]
  setExpandedKeys: Dispatch<SetStateAction<string[]>>
  isLoading: boolean
  selectedWp: Wbs | undefined
  setSelectedWp: Dispatch<SetStateAction<Wbs | undefined>>
  selectedWpData: ProjectObject | undefined
  setSelectedWpData: Dispatch<SetStateAction<ProjectObject | undefined>>
}

const initTreeViewProps: TreeViewProps = {
  treeData: [],
  setTreeData: () => { },
  expandedKeys: [],
  setExpandedKeys: () => { },
  isLoading: true,
  selectedWp: undefined,
  setSelectedWp: () => { },
  selectedWpData: undefined,
  setSelectedWpData: () => { },
}

const TreeContext = createContext<TreeViewProps>(initTreeViewProps)

const useTree = () => useContext(TreeContext)

const TreeProvider: FC<WithChildren> = ({ children }) => {
  const { project } = useProject()
  const [expandedKeys, setExpandedKeys] = useState<string[]>([])
  const [treeData, setTreeData] = useState<DataNode[]>([])
  const [selectedWp, setSelectedWp] = useState<Wbs | undefined>(undefined)
  const [selectedWpData, setSelectedWpData] = useState<ProjectObject | undefined>(undefined)
  const { isLoading } = useWbs(project?.default_wbs?._id?.$oid, (data) =>
    setTreeData([
      {
        title: <TreeWbsItem wbs={data} />,
        key: data._id?.$oid || 'root',
      },
    ])
  )

  return (
    <TreeContext.Provider value={{ 
      expandedKeys,
      isLoading,
      setExpandedKeys,
      setTreeData,
      treeData,
      selectedWp,
      setSelectedWp,
      selectedWpData,
      setSelectedWpData,
      }}>    
        {children}
    </TreeContext.Provider>
  )
}

export { TreeProvider, useTree }
