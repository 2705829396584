import { DataNode } from 'antd/es/tree'
import { Wbs } from '../../_models'
import { getWbs, getWbss } from '../../_requests'
import { useTree } from './TreeContext'
import TreeWbsItem from './TreeWbsItem'
import { KTSVG } from '../../../../../_metronic/helpers'
import { useProject } from '../../../projects/core/ProjectContext'
import { useEffect } from 'react'
import { addWbsToStore } from '../../../wbs/treedata'
import { RootState } from '../../../../store'
import { useDispatch, useSelector } from 'react-redux'

interface Props {
  currentStep?: number
}

export default function TreeViewHeader({ currentStep }: Props) {
  const { setExpandedKeys, setTreeData } = useTree()
  const { project } = useProject();
  const dispatch = useDispatch();
  const knownWbs = useSelector((state: RootState) => state.treedata.knownWbs);


  const loadExpandAll = async () => {

    const allKeys: string[] = [];

    const fetchWbsRecursively = async (wbsId: string): Promise<DataNode> => {
      let wbsItem = knownWbs[wbsId];
      if (!wbsItem) {
        const response = await getWbs(wbsId);
        dispatch(addWbsToStore(response));
        wbsItem = response;
      }

      allKeys.push(wbsItem._id.$oid);

      const childrenData: DataNode[] = await Promise.all(
        wbsItem.subwbs.map(async (subWbs) => {
          return await fetchWbsRecursively(subWbs.$oid);
        })
      );

      return {
        key: wbsItem._id.$oid,
        title: <TreeWbsItem wbs={wbsItem} />,
        children: childrenData,
      };
    };

    if (project?.default_wbs) {
      const rootNode = await fetchWbsRecursively(project.default_wbs._id.$oid);
      setTreeData([rootNode]);
      setExpandedKeys(allKeys);
    }
  }

  const collapseAll = () => {
    setExpandedKeys([])
  }

  useEffect(() => {
    if (currentStep && currentStep === 4)
      loadExpandAll();
  }, [currentStep]);


  return (
    <header className='d-flex justify-content-end mt-2'>
      <button
        className='btn btn-light btn-icon btn-sm me-2 rotate active'
        onClick={collapseAll}
        type='button'
        style={{ transition: 'all 0.2s ease-in-out' }}
      >
        <KTSVG
          path='/media/icons/duotune/arrows/arr021.svg'
          className='svg-icon-muted text-hover-primary svg-icon-1 rotate-90'
        />
      </button>
      <button
        className='btn btn-light btn-icon btn-sm me-2 rotate active'
        onClick={loadExpandAll}
        type='button'
        style={{ transition: 'all 0.2s ease-in-out' }}
      >
        <KTSVG
          path='/media/icons/duotune/arrows/arr021.svg'
          className='svg-icon-muted text-hover-primary svg-icon-1 rotate-n90'
        />
      </button>
    </header>
  )
}
