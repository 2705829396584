import {DatePicker, DatePickerProps} from 'antd'
import clsx from 'clsx'
import dayjs from 'dayjs'
import {useFormik} from 'formik'
import {useEffect, useState} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import InputWithLabel from '../../../../components/inputWithLabel/InputWithLabel'
import {sweetAlert} from '../../../../utils/funcs'
import {getDateFormat} from '../../../simulations/core/SimulationHelpers'
import {useProject} from '../../core/ProjectContext'
import {patchVersion} from '../../core/_requests'
import {Milestone, InitMilestone, MilestoneValidationSchema, getMilestoneValidationSchema} from './_models'
// import {createMilestoneObject} from './_utils'
import TreeView, { flattenTree } from 'react-accessible-treeview'
import { FaCheckSquare, FaMinusSquare, FaSquare } from 'react-icons/fa'
import { IoMdArrowDropright } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../store'
import { transformToFolder } from '../comments/request'
import { adding, done } from '../comments/CommentsState'
import { postMilestone } from './requests'

interface Props {
  onHide: () => void
}

export default function MilestoneForm({onHide}: Props) {
  const [loading, setLoading] = useState<boolean>(false)
  const { setProject, setOriginalVersion, project } = useProject();
  const knownWbs = useSelector((state: RootState) => state.treedata.knownWbs);
  const [data, setData] = useState<any>();
  const [selectednodes,setSelectednodes]=useState<string[]>([])
  const currentwbs=useSelector((state:RootState)=>state.currentwbs.value)
  const dipatch=useDispatch()

  useEffect(() => {
    setData(knownWbs[project?.current_wbs]); 
  }, [knownWbs]); 

  

  const folder = transformToFolder(data,knownWbs)  ;
  const treedata =  flattenTree(folder) ;
  

  const formik = useFormik({
    initialValues: {...InitMilestone},
    validationSchema: MilestoneValidationSchema,
    onSubmit: (values) => {
      setLoading(true);
      dipatch(adding())
      postMilestone({subject:formik.values.subject,name:formik.values.name,achieved:InitMilestone.achieved,wbs:currentwbs.toString(),shared_with_wbs:[currentwbs.toString(),...selectednodes],baseline_date:formik.values.baseline_date,hidden:false,real_dates:InitMilestone.real_dates,forcast_date:formik.values.baseline_date})
        .then((res: any) => {
          sweetAlert({
            text: 'Comment added successfully',
            icon: 'success',
            title: 'Success',
          });
          onHide();
          formik.resetForm();
        })
        .catch((err: any) => 
          sweetAlert({
            text: err.response?.data?.message || 'Something went wrong',
            icon: 'error',
            title: 'Error',
          })
        )
        .finally(() => {
          dipatch(done())
          setLoading(false)});
    },
  });












  // const formik = useFormik<CreateMilestone>({
  //   initialValues: {
  //     ...InitMilestone,
  //     baseline_date: new Date(displayVersion?.data_date.$date || ''),
  //   },
  //   validationSchema: displayVersion ? getMilestoneValidationSchema(displayVersion) : undefined,
  //   onSubmit: (values) => {
  //     setLoading(true)
  //     const new_milestones = [
  //       ...(displayVersion?.milestones.map((item) => ({
  //         ...item,
  //         baseline_date: new Date((item.baseline_date as any).$date),
  //       })) || []),
  //       createMilestoneObject(values, new Date(displayVersion?.data_date.$date || '')),
  //     ]
  //     patchVersion(
  //       {
  //         milestones: new_milestones,
  //       },
  //       displayVersion?._id.$oid
  //     )
  //       .then((res) => {
  //         sweetAlert({
  //           title: 'Success',
  //           text: 'Milestone successfully created!',
  //           icon: 'success',
  //         }).then(onHide)
  //         setVersion((prev) => (prev ? ({...prev, milestones: res.milestones} as any) : undefined))
  //         setOriginalVersion(res)
  //       })
  //       .catch((err) =>
  //         sweetAlert({
  //           title: 'Error',
  //           text: err?.response?.data?.message || 'Something went wrong!',
  //           icon: 'error',
  //         })
  //       )
  //       .finally(() => setLoading(false))
  //   },
  // })
  const handleNodeSelect = (node: any) => {
    const selectedIds = node.treeState.selectedIds;
    let idsArray:string[] = Array.from(selectedIds)
    setSelectednodes(idsArray);
  };
  const onChangeStart: DatePickerProps['onChange'] = (date, dateString) =>{
    if (date) {
      const newDate =  date.toDate()    
      formik.setFieldValue('baseline_date', newDate);
    } else {
      formik.setFieldValue('baseline_date', null);
    }
}
 

  return (
    <form className='form'>
      <div className='fv-row mb-10'>
        <InputWithLabel
          props={{maxLength: 5}}
          label='Milestone Subject'
          error={formik.errors.subject}
          formikProps={formik.getFieldProps('subject')}
          touched={formik.touched.subject}
          placeholder='Milestone Subject'
          required
        />
        <InputWithLabel
          error={formik.errors.name}
          formikProps={formik.getFieldProps('name')}
          label='Name'
          touched={formik.touched.name}
          placeholder='Milestone Name'
          required
        />
      </div>
      <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span className='required'>baseline Date</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title=''
              ></i>
            </label>
            <DatePicker
              className={clsx(
                'form-control ',
                {'is-invalid': formik.touched.baseline_date && formik.errors.baseline_date},
                {
                  'is-valid': formik.touched.baseline_date && !formik.errors.baseline_date,
                }
              )}
              {...formik.getFieldProps('baseline_date')}
              value={formik.values.baseline_date ? dayjs(formik.values.baseline_date) : null}
              onChange={onChangeStart}
              allowClear={false}
              suffixIcon={null}
            />
            {formik.touched.baseline_date && formik.errors.baseline_date && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{String(formik.errors.baseline_date)}</span>
                </div>
              </div>
            )}
            </div>
      
      <div className='fv-row '>
        <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
          <span className='required'>Related WBS</span>
        </label>
        <div className='checkbox'>
        <TreeView
          data={treedata}
          aria-label='Single select'
          multiSelect={true}
          propagateSelectUpwards={false}
          propagateSelect={true}
          propagateCollapse={false}
          togglableSelect={true}
          defaultDisabledIds={[currentwbs]}
          nodeAction='check'
          onSelect={(node) => handleNodeSelect(node)}
          nodeRenderer={({
            element,
            isBranch,
            isExpanded,
            isSelected,
            isHalfSelected,
            isDisabled,
            getNodeProps,
            level,
            handleSelect,
            handleExpand,
          }) => (
            <div
              {...getNodeProps({ onClick: handleExpand })}
              style={{ marginLeft: 30 * (level - 1),
                opacity: isDisabled ? 0.3 : 1,
               }}
            >
              {isBranch && <ArrowIcon isOpen={isExpanded} />
              }
              <CheckBoxIcon
                onClick={(e: any) => {
                  handleSelect(e);
                  e.stopPropagation();
     
                }}
                
                variant={isHalfSelected ? 'some' : isSelected ? 'all' : 'none'}
              />
               {isBranch?
              <KTSVG className='svg-icon-primary svg-icon-2 me-2'
              path='/media/icons/duotune/graphs/git04.svg' />:
              <KTSVG
              path='/media/icons/duotune/files/fil003.svg'
              className='svg-icon-primary svg-icon-2 me-2'
            /> }
              <span className='name h5'>{element.name}</span>
            </div>
          )}
        />
        </div>
      </div>

      <div className='d-flex flex-stack pt-10'>
        <div>
          <button
            type='button'
            className='btn btn-lg btn-primary'
            data-kt-stepper-action='submit'
            disabled={!formik.isValid || !formik.dirty || loading}
            onClick={() => {
              formik.handleSubmit()
            }}
          >
            {!loading && (
              <span className='indicator-label'>
                Create
                <KTSVG
                  path='/media/icons/duotune/arrows/arr064.svg'
                  className='svg-icon-3 ms-2 me-0'
                />
              </span>
            )}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </form>
  )
}

const ArrowIcon = ({ isOpen, className }: { isOpen: boolean; className?: string }) => {
  const baseClass = "arrow";
  const classes = clsx(
    baseClass,
    { [`${baseClass}--closed`]: !isOpen },
    { [`${baseClass}--open`]: isOpen },
    className
  );
      return <IoMdArrowDropright className={classes} />
};

interface CheckBoxIconProps {
  variant: 'all' | 'none' | 'some';
  [key: string]: any;
}

const CheckBoxIcon: React.FC<CheckBoxIconProps> = ({ variant,isDisabled, ...rest }) => {
  switch (variant) {
    case 'all':
      return <FaCheckSquare {...rest} />;
    case 'none':
      return <FaSquare {...rest} />;
    case 'some':
      return <FaMinusSquare {...rest} />;
    default:
      return null;
  }
};
function cx(baseClass: string, arg1: { "arrow--closed": boolean }, arg2: { "arrow--open": boolean }, className: string | undefined) {
  throw new Error('Function not implemented.')
}

