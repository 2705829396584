import {KTSVG} from '../../../_metronic/helpers'
import {EventType, NotificationEvent} from './_models'

type Props = {event: NotificationEvent}

export default function NotificationInner({event}: Props) {
  return (
    <div className='row align-items-center'>
      <div className='col-2'>
        {event.type === EventType.GRAPH && (
          <KTSVG
            path='/media/icons/duotune/art/art009.svg'
            className='svg-icon-primary svg-icon-2hx'
          />
        )}
        {event.type === EventType.TEAM && (
          <KTSVG
            path='/media/icons/duotune/communication/com006.svg'
            className='svg-icon-muted svg-icon-2hx'
          />
        )}
        {event.type === EventType.SUBSCRIPTION && (
          <KTSVG
            path='/media/icons/duotune/general/gen044.svg'
            className='svg-icon-warning svg-icon-2hx'
          />
        )}
      </div>
      <div className='col-10 d-flex flex-column'>
        <div className='fw-bold'>{event.sender}</div>
        <div>{event.message}</div>
      </div>
    </div>
  )
}
