/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import {
  filterProducts,
  getLowestPrice,
  isCurrentSubscription,
  isDowngrading,
  isDowngradingFromEnterprise,
} from '../../../../app/modules/profile/components/billing/billingHelpers'
import {
  Price,
  Product,
  ProductType,
} from '../../../../app/modules/profile/components/billing/_models'
import { checkout, freeSubscription } from '../../../../app/modules/profile/components/billing/_requests'
import { KTSVG } from '../../../helpers'
import { useAuth } from '../../../../app/modules/auth'
import { useProducts } from '../../../../app/modules/profile/components/billing/_queries'
import { sweetAlert } from '../../../../app/utils/funcs'
import { useNavigate } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import { planContents } from './contents/PlanContents'

interface Props {
  show: boolean
  onHide: () => void
}

const UpgradePlan = ({ show, onHide }: Props) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const [selected, setSelected] = useState<Product>()
  const [products, setProducts] = useState<Product[]>([])
  const [selectedPrice, setSelectedPrice] = useState<Price>()
  const { currentUser, currentPlanLevel } = useAuth()
  const { isLoading } = useProducts((data) => {
    const filteredProducts = filterProducts(data, ProductType.SUB)
    setProducts(filteredProducts)
    setSelected(filteredProducts[0])
    setSelectedPrice(filteredProducts[0]?.prices[filteredProducts[0]?.prices.length - 1])
  })

  const freeProduct: Product = {
    name: 'Free',
    description: 'Limited but will give a global idea.',
    images: [],
    prices: [],
  }

  const disableUpgradeDowngradeButton = () => {
    if (selected?.name === 'Free' && currentPlanLevel != 1) {
      return false
    }
    if (selected?.name === 'Free' && currentPlanLevel == 1) {
      return true
    }
    if (
      selectedPrice?.price_id === currentUser?.current_subscription?.price_id &&
      !currentUser?.current_subscription?.is_cancelled) {
      return true
    }
    return false
  }

  const renderUppgradeDowngradeButtonText = () => {
    if (currentPlanLevel == 1 && selected?.name === "Free") {
      return 'Current Plan'
    }
    if (
      isCurrentSubscription(currentUser?.current_subscription, selectedPrice) &&
      selected?.name === "Free"
    ) {
      return 'Downgrade Plan'
    }
    if (
      isCurrentSubscription(currentUser?.current_subscription, selectedPrice) &&
      !currentUser?.current_subscription?.is_cancelled) {
      return 'Current Plan'
    }
    if (isDowngrading(currentUser?.current_subscription, selectedPrice)) {
      return 'Downgrade Plan'
    }
    return 'Upgrade Plan'
  }

  const upgrade = async () => {
    if (!currentUser?.address)
      return sweetAlert({
        text: 'Please add your address before subscribing',
        icon: 'warning',
        title: 'Warning',
      }).then(() => {
        onHide()
        navigate('/profile/settings')
      })
    let confirmed: boolean = true
    if (
      isCurrentSubscription(currentUser?.current_subscription, selectedPrice) &&
      !currentUser?.current_subscription?.is_cancelled
    )
      return
    if (isDowngradingFromEnterprise(currentUser?.current_subscription, selected))
      await sweetAlert({
        title: 'Are you sure?',
        text: 'You are downgrading from the Enterprise plan. Changes will apply after current subscription comes to and end',
        showCancelButton: true,
        icon: 'warning',
        confirmButtonText: 'Continue',
      }).then((res) => {
        if (!res.isConfirmed) confirmed = false
      })
    if (selectedPrice && confirmed) {
      setLoading(true)
      if (selected?.name === 'Free') {
        freeSubscription(currentUser.email)
          .then(() => {
            sweetAlert({
              title: 'Success',
              text: 'Your free plan will start once your current plan expires',
              icon: 'success'
            })
          })
          .catch((err) => {
            sweetAlert({
              title: 'Error',
              text: err.response.data.message || 'Something went wrong',
              icon: 'error',
            })
          })

          .finally(() => setLoading(false))
      } else {
        checkout(selectedPrice?.price_id)
          .then((res) => window.location.replace(res.data.checkout_session_url))
          .catch((err) => {
            sweetAlert({
              title: 'Error',
              text: err.response.data.message || 'Something went wrong',
              icon: 'error',
            })
          })
          .finally(() => setLoading(false))
      }
    }
  }
  return (
    <Modal show={show} onHide={onHide} size='xl'>
      <div className='modal-header justify-content-end border-0 pb-0'>
        <div onClick={onHide} className='btn btn-sm btn-icon btn-active-color-primary'>
          <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
        </div>
      </div>

      <Modal.Body>
        <div className='mb-13 text-center'>
          <h1 className='mb-3'>Upgrade a Plan</h1>

          <div className='text-muted fw-bold fs-5'>
            If you need more info, please check{' '}
            <a href='#' className='link-primary fw-bolder'>
              Pricing Guidelines
            </a>
            .
          </div>
        </div>
        {isDowngradingFromEnterprise(currentUser?.current_subscription, selected) && (
          <div className='d-flex mt-2 align-items-center justify-content-center'>
            <i className='bi bi-info-circle fs-5'></i>
            <p className='fw-7 ms-2 text-muted mb-0'>
              Changes will apply after current subscription comes to and end
            </p>
          </div>
        )}
        {(isLoading || products.length === 0) ? (
          <div className='d-flex justify-content-center'>
            <div
              className='spinner-border text-primary mt-5'
              role='status'
              style={{ width: '3rem', height: '3rem' }}
            >
              <span className='sr-only'>Loading...</span>
            </div>
          </div>
        ) : (
          <div className='d-flex flex-column'>
            <div className='row mt-10'>
              <div className='col-lg-6 mb-10 mb-lg-0'>
                <div className='nav flex-column'>
                  <div
                    onClick={() => {
                      setSelected(freeProduct)
                    }}
                    data-bs-toggle='tab'
                    data-bs-target={`#kt_upgrade_plan_free}`}
                    className='nav-link btn btn-outline btn-outline-dashed btn-color-dark d-flex flex-stack text-start p-6'
                  >
                    <div className='d-flex align-items-center me-2'>
                      <div className='form-check form-check-custom form-check-solid form-check-success me-6'>
                        <input
                          className='form-check-input'
                          type='radio'
                          name='plan'
                          value={freeProduct.name}
                          checked={freeProduct.name === selected?.name}
                          readOnly
                        />
                      </div>

                      <div className='flex-grow-1'>
                        <h2 className='d-flex align-items-center fs-2 fw-bolder flex-wrap'>
                          {freeProduct.name}
                        </h2>
                        <div className='fw-bold opacity-50'>{freeProduct.description}</div>
                      </div>


                    </div>
                  </div>
                  {products.slice().reverse().map((product, index) => (

                    <div
                      onClick={() => {
                        setSelected(product)
                        setSelectedPrice(product?.prices[product?.prices.length - 1])
                      }}
                      data-bs-toggle='tab'
                      data-bs-target={`#kt_upgrade_plan_${index}`}
                      key={Math.random()}
                      className='nav-link btn btn-outline btn-outline-dashed btn-color-dark d-flex flex-stack text-start p-6'
                    >
                      <div className='d-flex align-items-center me-2'>
                        <div className='form-check form-check-custom form-check-solid form-check-success me-6'>
                          <input
                            className='form-check-input'
                            type='radio'
                            name='plan'
                            value={product.name}
                            checked={product.name === selected?.name}
                            readOnly
                          />
                        </div>

                        <div className='flex-grow-1'>
                          <h2 className='d-flex align-items-center fs-2 fw-bolder flex-wrap'>
                            {product.name}
                          </h2>
                          <div className='fw-bold opacity-50'>{product.description}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className='col-lg-6'>
                <div className='tab-content rounded h-100 bg-light p-10'>
                  <div className={`tab-pane fade show active`}>
                    {selected?.prices && selected?.prices.length > 1 && (
                      <div className='row pb-5  mb-6'>
                        {selected?.prices
                          ?.slice(0)
                          ?.map((price) => (
                            <div key={price.price_id} className='col-lg col-sm-12 mb-4'>
                              <button
                                onClick={() => setSelectedPrice(price)}
                                className='btn btn-outline form-check form-check-custom form-check-success me-6 p-3'
                              >
                                <input
                                  className='form-check-input'
                                  type='radio'
                                  value={price.price_id}
                                  id={price.price_id}
                                  onChange={(e) => {
                                    setSelectedPrice(price)
                                  }}
                                  checked={price.price_id === selectedPrice?.price_id}
                                />
                                <label className='form-check-label fs-5' htmlFor={price.price_id}>
                                  {price.description}
                                </label>
                              </button>
                            </div>
                          ))}
                      </div>
                    )}
                    {selected?.name !== 'Free' && (
                      <div className="pb-5">
                        <h3 className='fw-bolder text-dark'>
                          You pay <span style={{ color: '#469CF0', fontSize: '2.5em' }}>€{selectedPrice?.amount && selectedPrice?.amount / 100}</span> {selectedPrice?.description == "Yearly" ? '/ Year (2 months offered)' : '/ Month'}
                        </h3>
                      </div>
                    )}
                    <div className='pb-5'>
                      <h2 className='fw-bolder text-dark'>
                        What’s in {selected?.name} Subscribtion?
                      </h2>
                    </div>
                    <div className='pt-1'>
                      {planContents.map((plan, i) => (
                        plan.header === selected?.name && plan.features.map((feature, j) => (
                          <div className='d-flex align-items-center mb-7' key={j}>
                            <span className='fw-bold fs-5 text-gray-700 flex-grow-1'>
                              {feature}
                            </span>
                            <KTSVG
                              path='/media/icons/duotune/general/gen043.svg'
                              className='svg-icon-1 svg-icon-success'
                            />
                          </div>
                        ))
                      ))}
                      {planContents.map((plan, i) => (
                        plan.header === selected?.name && plan.not_available_features.map((feature, j) => (
                          <div className='d-flex align-items-center mb-7' key={j}>
                            <span className='fw-bold fs-5 text-gray-700 flex-grow-1'>
                              {feature}
                            </span>
                            <KTSVG
                              path='/media/icons/duotune/general/gen040.svg'
                              className='svg-icon-1'
                            />
                          </div>
                        ))
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className='d-flex flex-center flex-row-fluid pt-12'>
          <button type='reset' className='btn btn-light me-3' data-bs-dismiss='modal'>
            Cancel
          </button>
          {/* yet to specify the same plan case */}
          <button
            disabled={
              disableUpgradeDowngradeButton()
            }
            onClick={upgrade}
            type='submit'
            className='btn btn-primary'
          >
            {!loading && renderUppgradeDowngradeButtonText()}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export { UpgradePlan }
