import {useEffect, useState} from 'react'
import {getFolders} from '../../../eps/_requests'
import {Tree} from 'react-organizational-chart'
import {Folder} from '../../../eps/_models'
import HierarchyFolderItem from './HierarchyFolderItem'
import HierarchyItem from './HierarchyItem'
import {TransformComponent, TransformWrapper} from 'react-zoom-pan-pinch'
import {KTSVG} from '../../../../../_metronic/helpers'
import {showError} from '../../../../utils/funcs'

type Props = {}

export type FolderNode = {
  label: string
  children?: FolderNode[]
  id: string
}

export default function HierarchyView({}: Props) {
  const [folderTree, setFolderTree] = useState<FolderNode[]>([])
  const [rootExpanded, setRootExpanded] = useState<boolean>(true)
  const [allExpanded, setAllExpanded] = useState(false)
  const [allCollapsed, setAllCollapsed] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  useEffect(() => {
    setLoading(true)
    getFolders()
      .then((res) => {
        const rootFolders = res.filter((item) => !item.parent_folder)

        const getChildren = (folder: Folder): FolderNode => {
          if (folder.subfolders.length === 0)
            return {
              label: folder.name,
              children: [],
              id: folder._id.$oid,
            }
          return {
            label: folder.name,
            children: res
              .filter((item) => item.parent_folder?.$oid === (folder._id?.$oid || 'root'))
              .map((item) => getChildren(item)),
            id: folder._id.$oid,
          }
        }
        setFolderTree(rootFolders.map(getChildren))
      })
      .catch(showError)
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    if (allExpanded) setRootExpanded(true)
    if (allCollapsed) setRootExpanded(false)
  }, [allExpanded, allCollapsed])

  useEffect(() => {
    // Find the ul element with a child li having the "no-end" class
    const resetItems = document.querySelectorAll('.backup-no-end')
    resetItems.forEach((item) => item.classList.remove('backup-no-end'))
    const liElement = document.querySelector('.no-end')
    if (liElement) {
      const ulElement = liElement.closest('ul')
      if (ulElement) {
        // Do something with the ulElement
        ulElement.classList.add('backup-no-end')
      }
    }
  }, [rootExpanded])

  if (loading)
    return (
      <div className='d-flex justify-content-center'>
        <div
          className='spinner-border text-primary mt-5'
          role='status'
          style={{width: '3rem', height: '3rem'}}
        >
          <span className='sr-only'>Loading...</span>
        </div>
      </div>
    )

  return (
    <TransformWrapper>
      {({zoomIn, zoomOut, resetTransform, centerView}) => (
        <>
          <div className='d-flex justify-content-end mb-3 gap-2'>
            <button
              className='btn btn-light btn-icon btn-sm me-2 rotate active'
              onClick={() => {
                setAllExpanded(false)
                setAllCollapsed(true)
              }}
              type='button'
              style={{transition: 'all 0.2s ease-in-out'}}
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr021.svg'
                className='svg-icon-muted text-hover-primary svg-icon-1 rotate-90'
              />
            </button>
            <button
              className='btn btn-light btn-icon btn-sm me-2 rotate active'
              onClick={() => {
                setAllCollapsed(false)
                setAllExpanded(true)
              }}
              type='button'
              style={{transition: 'all 0.2s ease-in-out'}}
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr021.svg'
                className='svg-icon-muted text-hover-primary svg-icon-1 rotate-n90'
              />
            </button>
            <button onClick={() => zoomIn()} className='btn btn-sm btn-secondary'>
              <KTSVG path='/media/icons/duotune/general/gen035.svg' />
              zoom in
            </button>
            <button onClick={() => zoomOut()} className='btn btn-sm btn-secondary'>
              <KTSVG path='/media/icons/duotune/general/gen036.svg' />
              zoom out
            </button>
            <button
              onClick={() => {
                centerView()
                resetTransform()
              }}
              className='btn btn-sm btn-secondary'
            >
              <KTSVG path='/media/icons/duotune/general/gen034.svg' /> reset
            </button>
          </div>
          <TransformComponent wrapperClass='w-100' contentClass='d-block w-100'>
            {/* <div style={{width: '200px', height: '200px', backgroundColor: 'green'}}></div> */}
            <Tree
              lineColor='var(--kt-dark)'
              lineWidth='2px'
              lineBorderRadius='10px'
              lineHeight='25px'
              label={
                <HierarchyItem
                  resetExpanded={() => {
                    setAllExpanded(false)
                    setAllCollapsed(false)
                  }}
                  allExpanded={allExpanded}
                  allCollapsed={!allExpanded}
                  resetView={() => centerView()}
                  expanded={rootExpanded}
                  setExpanded={setRootExpanded}
                  node={{id: 'root', label: 'ROOT', children: folderTree}}
                />
              }
            >
              {folderTree.map((item) => (
                <HierarchyFolderItem
                  className={rootExpanded ? '' : 'invisible no-end'}
                  allCollapsed={allCollapsed}
                  allExpanded={allExpanded}
                  resetExpanded={() => {
                    setAllExpanded(false)
                    setAllCollapsed(false)
                  }}
                  resetView={() => centerView()}
                  key={item.id}
                  node={item}
                />
              ))}
            </Tree>
          </TransformComponent>
        </>
      )}
    </TransformWrapper>
  )
}
