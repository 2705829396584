import axios, {AxiosResponse} from 'axios'
import {Folder} from './_models'

const API_URL_FOLDER = `${process.env.REACT_APP_API_URL}/folders`

const getFolders = () =>
  axios.get(`${API_URL_FOLDER}/all`).then((res: AxiosResponse<Folder[]>) => res.data)

const getFolder = (folderId?: string) =>
  axios
    .get(`${API_URL_FOLDER}${folderId ? `/${folderId}` : ''}`)
    .then((res: AxiosResponse<Folder>) => res.data)

const createFolder = (folderParams: {name: string; parent_folder?: string}) =>
  axios.post(`${API_URL_FOLDER}`, folderParams).then((res: AxiosResponse<Folder>) => res.data)

const deleteFolder = (folderId: string) => axios.delete(`${API_URL_FOLDER}/${folderId}`)

const patchFolder = (
  folderId: string,
  folderParams: {name?: string; parent_folder?: string | null; rank?: 1 | -1}
) =>
  axios
    .patch(`${API_URL_FOLDER}/${folderId}`, folderParams)
    .then((res: AxiosResponse<Folder>) => res.data)

export {getFolders, getFolder, createFolder, deleteFolder, patchFolder}
