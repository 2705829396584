import {dampenSigmoidProportion} from '../../helpers/optimizers'
import {Calendar} from '../../modules/calendars/_models'
import {
  applyCalendarToCurve,
  getCapacity,
  getReelDays,
} from '../../modules/library/ProjectCurve/Context/ProjectCurveHelpers'
import {calculateSigmoidOutputs} from '../../modules/library/ProjectCurve/core'
import {getPeriodicFromCumulative} from '../../modules/projects/components/data/prepareDataTable'
import {getCustomCurve} from '../../modules/projects/components/overview/Project'
import {getDiffBetweenTwoDates} from '../forecast'
import {standardizeCurve} from './baseline-fitting'
import moment from 'moment'

const forecastCustomDate = async (
  inputs: any,
  data: any,
  cumulativeEarnedValuesAsPercentages: any,
  calendar?: Calendar
): Promise<{
  tableData: (string[] | moment.Moment[])[]
  nbOfRemainingMonths: number
}> => {
  if (!data?.forecast_settings?.custom_end_date) return {tableData: [], nbOfRemainingMonths: 0}
  const cumulativePlannedValuesAsPercentages = getCustomCurve('cumulativePlannedValue', data).map(
    (item) => (+item / data.budget_at_completion.amount) * 100
  )
  const forecastLength =
    getDiffBetweenTwoDates(
      data.start_date.$date,
      data.forecast_settings.custom_end_date.$date,
      'month'
    ) + 1

  if (data.curve_settings?.tangent && data.curve_settings?.offset) {
    inputs.mu = data.curve_settings?.tangent
    inputs.sig = data.curve_settings?.offset
  } else {
    const {axis, tangent} = await standardizeCurve({
      associated_calendar: calendar,
      color: '',
      duration: cumulativePlannedValuesAsPercentages.length,
      name: 'test',
      start_date: new Date(data.start_date.$date),
      total: 100,
      values: cumulativePlannedValuesAsPercentages,
      unit: 'currency',
    })
    inputs.mu = tangent
    inputs.sig = axis
  }

  let outputs = calculateSigmoidOutputs(
    {
      mu: inputs.mu,
      sig: inputs.sig,
      nbOfMonthsPassed: forecastLength,
    },
    false
  )

  if (calendar) {
    const {reelDays, totalDays} = await getReelDays(
      new Date(data.start_date.$date),
      outputs.tableData[1].length,
      calendar
    )
    const capacity = getCapacity(reelDays, totalDays)
    outputs = applyCalendarToCurve(capacity, outputs)
  }

  outputs = dampenSigmoidProportion(cumulativeEarnedValuesAsPercentages, outputs as any)

  let forecast = outputs.tableData[1].slice(cumulativeEarnedValuesAsPercentages.length)
  const cumulativeEv = [...cumulativeEarnedValuesAsPercentages, ...forecast]
  return {
    tableData: [
      Array.from({length: forecastLength}).map((_el, idx) =>
        moment(inputs.inputDataDate).add(idx + 1, 'month')
      ),
      cumulativeEv.map((item) => item + ''),
      getPeriodicFromCumulative(cumulativeEv).map((item) => item + ''),
    ],
    nbOfRemainingMonths: forecastLength - cumulativeEarnedValuesAsPercentages.length,
  }
}

export {forecastCustomDate}
