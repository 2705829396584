export default function DraggableIcon() {
  return (
    <div
      style={{height: '100%'}}
      className='d-flex align-items-center justify-content-center opacity-100'
    >
      <i className='bi bi-grip-vertical fs-1'></i>
    </div>
  )
}
