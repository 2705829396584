import axios, {AxiosResponse} from 'axios'

import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'

const API_URL = process.env.REACT_APP_API_URL
authHelper.setupAxios(axios)
export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/users`
export const LOGIN_URL = `${API_URL}/auth/login`
export const REGISTER_URL = `${API_URL}/auth/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot_password`
export const REQUEST_CHECK_MAIL = `${API_URL}/auth/mail-verify`
export const REQUEST_VALIDATE_OTP = `${API_URL}/auth/validate-otp`
export const REQUEST_SEND_OTP = `${API_URL}/auth/send-otp`
export const REQUEST_RESET_PASSWORD = `${API_URL}/auth/reset-password`
export const LOGIN_WITH_GOOGLE = `${API_URL}/auth/google`
export const LOGOUT = `${API_URL}/auth/logout`
export const REFRESH = `${API_URL}/auth/refresh`

// Server should return AuthModel
export async function login(email: string, password: string) {
  const res: any = await axios.post<any>(LOGIN_URL, {
    email,
    password,
  })
  const auth: any = {
    otp_token: res.data.otp_token || undefined,
    api_token: res.data.access_token || '',
    refreshToken: res.data.refresh_token || '',
  }
  return auth
}

// Server should return AuthModel
export async function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string
) {
  const auth: AuthModel = {
    api_token: '',
    refreshToken: '',
  }
  const res = await axios.post(REGISTER_URL, {
    email,
    full_name: `${firstname} ${lastname}`,
    password,
  })

  auth.api_token = res.data?.otp_token || ''
  return auth
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}
export function verifyOTP(token: string) {
  return axios.post(REQUEST_CHECK_MAIL, {
    validation_token: token,
  })
}

export function getUserByToken(token: string) {
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    headers: {Authorization: 'Bearer ' + token},
  })
}

export function updateUser(token: string | undefined, userData: Partial<UserModel>) {
  return axios.patch<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, userData, {})
}

export function getUserImageByToken(userId: string, token: string) {
  return axios.get<UserModel>(`${GET_USER_BY_ACCESSTOKEN_URL}/${userId}/image`, {
    headers: {Authorization: 'Bearer ' + token},
  })
}

export function validateOTP(otpToken: string, otp: string) {
  return axios.post(REQUEST_VALIDATE_OTP, {
    otp_token: otpToken,
    otp,
  })
}

export function sendOTP(email: string, type?: string) {
  return axios.post(REQUEST_SEND_OTP, {
    email,
    type: type ? type : 'mail-verification',
  })
}

export function resetPassword(validation_token: string, new_password?: string) {
  return axios.post(REQUEST_RESET_PASSWORD, {
    validation_token,
    new_password,
  })
}
export function loginWithGoogle(google_token: string) {
  return axios.post(LOGIN_WITH_GOOGLE, {
    google_token,
  })
}
export function logoutFn(refresh_token: string) {
  return axios.post(LOGOUT, {
    refresh_token,
  })
}

export function updateProfilePicture(image: any, userId: string) {
  const formData = new FormData()
  formData.append('image', image)
  return axios
    .post(`${GET_USER_BY_ACCESSTOKEN_URL}/${userId}/image`, formData)
    .then((response: AxiosResponse<any>) => response.data)
}

export const getUserImageLink = (userId: string) =>
  `${GET_USER_BY_ACCESSTOKEN_URL}/${userId}/image?${new Date().getTime()}`

export function refreshToken() {
  return axios.post(
    REFRESH,
    {
      refresh_token: authHelper.getAuth()?.refreshToken,
    },
    {
      headers: {Authorization: 'Bearer ' + authHelper.getAuth()?.api_token},
    }
  )
}
