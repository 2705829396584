import {FormikProps} from 'formik'
import {getFormattedUnit} from '../../../../utils/funcs'
import {findSym} from '../../../../helpers/func'
import {EacFormula} from '../../core/_models'
import clsx from 'clsx'
import {eacFormulaOptions} from './_utils'
import {useProject} from '../../core/ProjectContext'

type Props = {
  formik: FormikProps<any>
}

export default function EacFormulaSelection({formik}: Props) {
  const {displayVersion} = useProject()
  return (
    <div className='mb-8'>
      <div className='row'>
        <div className='fs-6 fw-semibold mt-2 mb-3'>Estimated at completion formula</div>
      </div>
      {eacFormulaOptions.map((option) => (
        <div key={option.label} className='row'>
          <label
            onClick={() => formik.setFieldValue('eacFormula', option.value)}
            className='d-flex align-items-center col-12 col-lg-3'
          >
            <input
              type='radio'
              readOnly
              checked={formik.values.eacFormula === option.value}
              className='form-check-input'
            />
            <span className='btn btn-sm btn-color-muted btn-active btn-active-primary'>
              {option.label}
            </span>
          </label>
          {option.value === EacFormula['AC + (Bottom UP Estimated Cost)'] && (
            <div className='col-12 col-lg-9 row'>
              <div className='col-12 col-lg-2'>
                <div
                  className={clsx('fs-6 fw-semibold mt-2 mb-3', {
                    'text-muted':
                      formik.values.eacFormula !== EacFormula['AC + (Bottom UP Estimated Cost)'],
                  })}
                >
                  Bottom UP Estimated Cost
                </div>
              </div>
              <div className='col-xl-10 fv-row'>
                <div className='input-group mb-3'>
                  <span className='input-group-text'>
                    {getFormattedUnit(displayVersion?.budget_at_completion.unit || '')}{' '}
                    {findSym(displayVersion?.budget_at_completion.currency || '')}
                  </span>

                  <input
                    type='text'
                    disabled={
                      formik.values.eacFormula !== EacFormula['AC + (Bottom UP Estimated Cost)']
                    }
                    className={clsx('form-control', {
                      'is-invalid': !!formik.errors.bottom_up_ec && formik.touched.bottom_up_ec,
                    })}
                    {...formik.getFieldProps('bottom_up_ec')}
                  />
                </div>
                {formik.errors.bottom_up_ec && formik.touched.bottom_up_ec && (
                  <span className='text-danger'>
                    <>{formik.errors.bottom_up_ec}</>
                  </span>
                )}
              </div>
            </div>
          )}
          {option.value === EacFormula['Custom EAC'] && (
            <div className='col-9 row'>
              <div className='col-xl-2'>
                <div
                  className={clsx('fs-6 fw-semibold mt-2 mb-3', {
                    'text-muted':
                      formik.values.eacFormula !== EacFormula['AC + (Bottom UP Estimated Cost)'],
                  })}
                >
                  EAC
                </div>
              </div>
              <div className='col-xl-10 fv-row'>
                <div className='input-group mb-3'>
                  <span className='input-group-text'>
                    {getFormattedUnit('thousand')} {findSym('usd')}
                  </span>
                  <input
                    type='text'
                    placeholder=''
                    disabled={formik.values.eacFormula !== EacFormula['Custom EAC']}
                    className={clsx('form-control', {
                      'is-invalid': !!formik.errors.custom_eac && formik.touched.custom_eac,
                    })}
                    {...formik.getFieldProps('custom_eac')}
                  />
                </div>
                {formik.errors.custom_eac && formik.touched.custom_eac && (
                  <span className='text-danger'>
                    <>{formik.errors.custom_eac}</>
                  </span>
                )}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  )
}
