import * as xlsx from 'xlsx'
import XLSX from "xlsx-color";
import {getLabels} from '../../../../helpers/DateFormatter'
import FileSaver from 'file-saver'
import { getDiffrentBettwenDate } from "../../../../utils/data-transformarion/date-utils";

export const downloadCustomCurveTemplate = (start_date: Date, duration: number) => {
  const labels = getLabels(start_date.getTime(), duration, 'month')
  const workbook = xlsx.utils.book_new()
  const worksheet = xlsx.utils.aoa_to_sheet([[...labels]])
  xlsx.utils.book_append_sheet(workbook, worksheet, 'Sheet 1')
  const excelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'})
  const dataBlob = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })
  FileSaver(dataBlob, 'custom_curve_template.xlsx')
}

export const downloadCustomRebaseLine = (
  start_date: Date,
  duration: number,
  dataObject: any[],
  end_date: Date
) => {
  const labels = getLabels(start_date.getTime(), duration, 'month')
  const headerRow = [...labels]
  const monthDifference = getDiffrentBettwenDate(end_date.getTime(), start_date.getTime() , 'monthly')
  const fillStyle = {
    fill: {
      patternType: "solid",
      fgColor: { rgb: "FFFF00" }
    }
  };
  const secondRow = headerRow.map((header) => {
    const matchingData = dataObject.find((item) => item.header === header)
    return matchingData ? matchingData.data : ''
  })

  const workbook = XLSX.utils.book_new()
  const worksheet = XLSX.utils.aoa_to_sheet([headerRow, secondRow])

  for (let i = 0; i <= monthDifference && i < headerRow.length; i++) {
    const cellAddress = XLSX.utils.encode_cell({ r: 0, c: i }); // Targeting the first row
    const cellAddress2 = XLSX.utils.encode_cell({ r: 1, c: i }); // Targeting the second row
    worksheet[cellAddress].s = fillStyle;
    worksheet[cellAddress2].s = fillStyle;
  }
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1')

  const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'})
  const dataBlob = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })

  FileSaver(dataBlob, 'custom_curve_template.xlsx')
}
