import { Link, useParams } from 'react-router-dom'
import FolderItem from './FolderItem'
import { useWbs } from '../_queries'
import { KTSVG } from '../../../../_metronic/helpers'
import { useEffect, useState } from 'react'
import CreateFolderItem from './CreateFolderItem'
import { Wbs } from '../_models'
import { showError } from '../../../utils/funcs'
import EmptyValuesCard from '../../../components/emptyValuesCard'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import PreviousItem from './PreviousItem'
import { useProject } from '../../projects/core/ProjectContext';
import WpData from '../../wp/components/WpData'
import { KTCard } from '../../../../_metronic/helpers'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { addWbsToStore } from '../treedata'
import { getWbs } from '../_requests'
import { setCurrentId } from '../CurrentFolder'
export default function Folders() {
  const { id } = useParams()
  const currentId = useSelector((state:RootState) => state.CurrentFolder.id);
  const [newFolder, setNewFolder] = useState<boolean>(false)
  const { displayVersion: dataa, project } = useProject();
  const { data, isLoading, refetch } = useWbs(currentId, undefined, showError)

  const [folders, setFolders] = useState<Wbs[]>([]);

  const dispatch = useDispatch();
  const knownWbs = useSelector((state: RootState) => state.treedata.knownWbs);
  useEffect(()=>{

    dispatch(setCurrentId(project?.current_wbs))
    console.log(currentId)
  },[project?.current_wbs])
  useEffect(() => {
    const fetchSubwbs = async () => {
      if (data?.subwbs) {
        const subwbsData = await Promise.all(data.subwbs.map(async (item) => {
          let subWbsItem = knownWbs[item.$oid];
          if (!subWbsItem) {
            subWbsItem = await getWbs(item.$oid);
            dispatch(addWbsToStore(subWbsItem));
          }
          return subWbsItem;
        }));
        setFolders(subwbsData);
      }
    };
 
    fetchSubwbs();
  }, [data,id,project?.current_wbs]);

  return (
    <>
      {/* to do : changing versions should change the current wbs */}
      <header className='my-3 d-flex justify-content-between'>
      <span className='badge badge-lg badge-primary'>
        <div className='d-flex justify-content-center align-items-center' onClick={() => { dispatch(setCurrentId(project?.current_wbs)) }}>
          <KTSVG path='/media/icons/duotune/general/gen001.svg' className='text-white' />
          <span className='ms-2 text-white'>home</span>
        </div>
        <div className='d-flex align-items-center'>
          {data?.hierarchy?.map((item) => (
            <span key={item._id.$oid} className='d-flex align-items-center'>
              <i className='bi bi-chevron-right text-white'></i>
              <div className='text-white ms-2' onClick={() => { dispatch(setCurrentId(item._id.$oid)) }}>
                {item.name}
              </div>
            </span>
          ))}
        </div>
      </span>
        <button
          onClick={() => setNewFolder((prev) => !prev)}
          type='button'
          className='btn btn-light btn-icon btn-sm'
        >
          <KTSVG
            path={`/media/icons/duotune/files/fil0${newFolder ? '15' : 13}.svg`}
            className='svg-icon-muted svg-icon-2x'
          />
        </button>
      </header>
      <DndProvider backend={HTML5Backend}>
        <div className='row'>
          {!!id && !!data && !!data?.subwbs.length && data.parent_wbs && (
            <PreviousItem projectId={project?._id.$oid} refetch={refetch} folder={data} />
          )}
          {!data && isLoading && (
            <div className='d-flex justify-content-center'>
              <div
                className='spinner-border text-primary mt-5'
                role='status'
                style={{ width: '3rem', height: '3rem' }}
              >
                <span className='sr-only'>Loading...</span>
              </div>
            </div>
          )}
          {folders.map((item) => (
            <FolderItem key={item._id.$oid} projectId={project?._id.$oid} folder={item} refetch={refetch} />
          ))}
          {newFolder && (
            <CreateFolderItem setNewFolder={setNewFolder} parentId={id} refetch={refetch} />
          )}
        </div>
      </DndProvider>
    </>
  )
}
