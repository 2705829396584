import {FC, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {sendProject} from '../../../core/_requests'
import {showError, sweetAlert} from '../../../../../utils/funcs'
interface Props {
  show: boolean
  handleClose: () => void
  projectId: string
}
const SendProjectModal: FC<Props> = ({show, handleClose, projectId}) => {
  const [email, setEmail] = useState('')
  const sendProjectReq = () => {
    const req = {email: email}
    sendProject(req, projectId)
      .then((data) => {
        showAlert()
      })
      .catch(showError)
  }
  const showAlert = () => {
    sweetAlert({
      title: 'Success',
      text: 'Project Send Successfully',
      icon: 'success',
      confirmButtonText: 'OK',
    })
  }
  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-content rounded modal-content-centered mw-900px'
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div className='modal-header pb-0 border-0 justify-content-end'>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>
      <div className='modal-body py-lg-10 px-lg-10'>
        <div className='mb-13 text-center'>
          <h1 className='mb-3'>{'Send Project'}</h1>
          <div className='w-100'>
            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span className='required'>Subject</span>
              </label>
              <input
                type='email'
                placeholder=''
                name='email'
                className='form-control '
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
              />
            </div>
          </div>
          <div className='d-flex flex-stack pt-10'>
            <div>
              <button
                type='button'
                className='btn btn-lg btn-primary'
                data-kt-stepper-action='submit'
                onClick={() => {
                  sendProjectReq()
                }}
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
export default SendProjectModal
