import {useFormik} from 'formik'
import {useState} from 'react'
import {Modal} from 'react-bootstrap'
import * as Yup from 'yup'
import {sweetAlert} from '../../../../utils/funcs'
import {sendSimulation} from '../../core/_requests'
type Props = {
  show: boolean
  onHide: () => void
  simulationId: string | undefined
}

export default function SendSimulationModal({show, onHide, simulationId}: Props) {
  const [loading, setLoading] = useState(false)
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required('Email is required').email('Email is invalid'),
    }),
    onSubmit: (values) => {
      setLoading(true)
      sendSimulation(simulationId, values.email)
        .then((res) => {
          sweetAlert({
            text: 'Simulation sent successfully',
            title: 'Success',
            icon: 'success',
          })
        })
        .catch((err) =>
          sweetAlert({
            text: err.response.data.message || 'Something went wrong',
            title: 'Error',
            icon: 'error',
          })
        )
        .finally(() => {
          setLoading(false)
          onHide()
          formik.resetForm()
        })
    },
  })
  return (
    <Modal size='lg' show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Send Simulation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
            <span className='required'>Email</span>
          </label>
          <input
            type='email'
            placeholder='Email'
            {...formik.getFieldProps('email')}
            className='form-control'
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={() => formik.submitForm()}
          className='btn btn-primary'
          disabled={!formik.dirty || !formik.isValid || loading}
        >
          {loading ? (
            <span className='indicator-progress' style={{display: 'block'}}>
              <span className='spinner-border spinner-border-sm align-middle'></span>
            </span>
          ) : (
            'Send'
          )}
        </button>
      </Modal.Footer>
    </Modal>
  )
}
