/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useMemo, useState} from 'react'
import {CreateActionPlan} from './CreateActionPlan'
import _ from 'lodash'
import '../data/Data.scss'
import EditActionPlan from './EditActionPlan'
import {ProjectObject} from '../../core/_models'
import ActionPlanHeader from './ActionPlanHeader'
import {useSortBy, useTable} from 'react-table'
import {HeaderList, IActionPlan} from './IActionPlan'
import { getActionPlan, getActionPlanbywbs, getActionPlans } from './requests'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store'

type Props = {
  className: string
  project: ProjectObject
  statusFilter: string[]
  keyword: string
  comment: any
  showActions?: boolean
  actionplans:any
}

const ActionPlanTable: React.FC<Props> = ({
  className,
  project,
  statusFilter,
  keyword,
  comment,
  showActions,
  actionplans
}) => {
  const [actionPlans, setActionPlans] = useState<any>([])
  const currentwbs=useSelector((state:RootState)=>state.currentwbs.value)
  const added=useSelector((state:RootState)=>state.added.value)
  const [showEditModal, setShowEditModal] = useState(false)
  console.log(currentwbs)
 
  const columns = useMemo(() => HeaderList, [])
  const data = useMemo(() => actionplans, [actionplans])

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable(
    {
      columns,
      data,
    },
    useSortBy
  )
  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table
            {...getTableProps()}
            className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bold dataTable no-footer'
          >
            {/* begin::Table head */}
            <thead className='fs-7 text-gray-400 text-uppercase'>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, index) => (
                    <ActionPlanHeader
                      key={column.Header?.toLocaleString()}
                      isSorted={column.isSorted}
                      isSortedDesc={column.isSortedDesc}
                      title={column.Header as string}
                      width={index === headerGroup.headers.length - 1 ? '50' : '90'}
                      props={column.getHeaderProps(column.getSortByToggleProps)}
                    />
                  ))}
                </tr>
              ))}
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody {...getTableBodyProps()}>
              {rows
                 .filter((item: any) => {
                  // Check if comment is empty or null
                  if (!comment || comment === '') {
                    return true;
                  }
                
                  // Ensure item.original and item.original.associated_comment are defined and check if associated_comment matches the comment._id.$oid
                  if (item?.original?.associated_comment) {
                    return item.original.associated_comment.$oid === comment?._id?.$oid;
                  }
                
                  return false;
                })
                 .filter((item) =>
                   (item.original as IActionPlan)?.subject
                     .toLowerCase()
                     .includes(keyword.toLowerCase())
                 )
                 .filter(
                  (item) =>
                  statusFilter.includes((item.original as IActionPlan).status) ||
                  !statusFilter.length
                 )
                .map((row, index) => {
                  prepareRow(row)
                  return (
                    <EditActionPlan
                      dateFormat={project.date_format}
                      item={row.original as IActionPlan}
                      projectId={project._id.$oid}
                      items={data}
                      key={index}
                      showActions={showActions}
                    />
                  )
                })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      <CreateActionPlan
        project={project}
        handleClose={() => {
          setShowEditModal(false)
        }}
        show={showEditModal}
      />
      {/* begin::Body */}
    </div>
  )
}

export {ActionPlanTable}
