import {Field, FieldArray, FormikProvider, useFormik} from 'formik'
import {useProject} from '../../core/ProjectContext'
import {getCustomCurve} from '../overview/Project'
import {useState} from 'react'
import {IBudget, getFormattedBudget, showError} from '../../../../utils/funcs'
import {getPeriodicFromCumulative} from './prepareDataTable'
import * as Yup from 'yup'
import {greaterThanOrEqual} from '../../core/ProjectHelpers'
import {patchVersion} from '../../core/_requests'

type Props = {labels: string[]}

export default function WorstCastBaseline({labels}: Props) {
  const {displayVersion, setOriginalVersion, version, setVersion} = useProject()

  const formik = useFormik({
    initialValues: {wostCaseBaseline: getCustomCurve('worstCaseBaseline', displayVersion)},
    validationSchema: Yup.object().shape({
      wostCaseBaseline: Yup.array()
        .test('number', 'Planned Values must be numbers', (value, context) => {
          return value?.every((el) => !isNaN(+el)) || false
        })
        .test('pvTest', 'Planned values must be cumulative', (value, context) => {
          return value?.every(greaterThanOrEqual) || false
        })
        .test(
          'last_value',
          'Last value must be equal to budget at completion',
          (value, context) => {
            return +value?.[value.length - 1] === displayVersion?.budget_at_completion?.amount
          }
        ),
    }),
    enableReinitialize: true,
    onSubmit: () => {
      setLoading(true)
      patchVersion(
        {
          custom_curve: displayVersion?.custom_curve?.map((item) =>
            item.name === 'worstCaseBaseline'
              ? {...item, values: formik.values.wostCaseBaseline}
              : item
          ),
        },
        displayVersion?._id.$oid
      )
        .then((res) => {
          setVersion((prev) => (prev ? {...prev, custom_curve: res.custom_curve} : prev))
          setOriginalVersion((prev) => (prev ? {...prev, custom_curve: res.custom_curve} : prev))
          setIsEdit(false)
        })
        .catch(showError)
        .finally(() => setLoading(false))
    },
  })
  const [loading, setLoading] = useState<boolean>(false)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  return (
    <FormikProvider value={formik}>
      <div className='d-flex flex-wrap flex-stack align-items-end'>
        <h3 className=''>Worst Case Planned Value</h3>
        <button
          onClick={() => {
            if (isEdit) {
              formik.submitForm()
            } else setIsEdit((prev) => !prev)
          }}
          type='button'
          className=' btn btn-sm btn-primary'
          disabled={(isEdit && (!formik.isValid || !formik.dirty)) || loading}
        >
          {loading ? (
            <span className='indicator-progress' style={{display: 'block'}}>
              <span className='spinner-border spinner-border-sm align-middle'></span>
            </span>
          ) : isEdit ? (
            'Save'
          ) : (
            'Edit'
          )}
        </button>
      </div>
      <div className='card card-xxl-stretch mb-5 mb-xl-10'>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bolder text-muted'>
                  <th className='min-w-150px'></th>
                  {formik.values.wostCaseBaseline.map((item: any, index: number) => (
                    <th
                      key={index}
                      className='min-w-120px'
                      style={{maxWidth: '13ch', minWidth: '13ch'}}
                    >
                      {labels[index]}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <a className='text-primary fw-bolder text-hover-primary fs-6'>Cumulative</a>
                  </td>
                  <FieldArray
                    name='wostCaseBaseline'
                    render={(arrayHelpers) => (
                      <>
                        {formik.values.wostCaseBaseline &&
                          formik.values.wostCaseBaseline.map((value: any, index: number) => (
                            <td className='position-relative' key={index}>
                              {isEdit ? (
                                <Field
                                  name={`wostCaseBaseline.${index}`}
                                  className="text-dark fw-bold d-block fs-7 text-danger 'border-bottom border-primary"
                                />
                              ) : (
                                <span className='fw-bold d-block fs-7 text-dark'>
                                  {getFormattedBudget({
                                    ...displayVersion?.budget_at_completion,
                                    amount: value,
                                    floatFormater: displayVersion?.float_formatter,
                                  } as IBudget)}
                                </span>
                              )}
                            </td>
                          ))}
                      </>
                    )}
                  />
                </tr>
                <tr>
                  <td>
                    <a className='text-primary fw-bolder text-hover-primary fs-6'>Periodic</a>
                  </td>
                  {displayVersion?.output?.plannedValues?.original?.periodic
                    ? displayVersion?.output?.plannedValues?.original.periodic.map(
                        (item: any, index: number) => (
                          <td key={index}>
                            <input
                              className='text-dark fw-bold d-block fs-7'
                              value={getFormattedBudget({
                                ...displayVersion?.budget_at_completion,
                                amount: item,
                                floatFormater: displayVersion.float_formatter,
                              } as IBudget)}
                              disabled
                            />
                          </td>
                        )
                      )
                    : getPeriodicFromCumulative(
                        formik.values.wostCaseBaseline.filter(
                          (value: any) => !isNaN(value) && value !== ''
                        )
                      ).map((item: any, index: number) => (
                        <td key={index}>
                          <input
                            className='text-dark fw-bold d-block fs-7'
                            value={getFormattedBudget({
                              ...displayVersion?.budget_at_completion,
                              amount: item,
                              floatFormater: displayVersion?.float_formatter,
                            } as IBudget)}
                            disabled
                          />
                        </td>
                      ))}
                </tr>
              </tbody>
            </table>
          </div>
          {formik.errors.wostCaseBaseline && (
            <h4 className='text-danger'>{formik.errors.wostCaseBaseline.toString()}</h4>
          )}
        </div>
      </div>
    </FormikProvider>
  )
}
