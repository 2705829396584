import clsx from 'clsx'
import {useFormik} from 'formik'
import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {findSym} from '../../../helpers/func'
import {getFormattedUnit, sweetAlert} from '../../../utils/funcs'
import * as Yup from 'yup'
import CustomSelect from '../../../components/CustomSelect'
import {currencyData} from '../../../../_metronic/helpers'
import {patchSimulation} from '../core/_requests'
import {useSimulationsByIdData} from '../core/_queries'

interface Props {
  show: boolean
  closeModal: () => void
  data: any
}

const unitOptions = [
  {value: 'billion', label: 'Billion'},
  {value: 'million', label: 'Million'},
  {value: 'thousand', label: 'Thousand'},
  {value: 'unit', label: 'Unit'},
]

export default function TotalPriceModal({data, show, closeModal}: Props) {
  const [loading, setLoading] = useState<boolean>(false)
  const [currency, setCurrency] = useState<any>([])
  const {refetch} = useSimulationsByIdData(data._id.$oid || '')
  useEffect(() => {
    const localCurrency: any = []
    Object.values(currencyData[0]).forEach((item) => {
      localCurrency.push({label: `${item.name_plural} (${item.code})`, value: item.code})
    })
    setCurrency(localCurrency)
  }, [])

  const formik = useFormik({
    initialValues: {
      budgetAtCompletion: data?.budget_at_completion?.amount || 0,
      currency: data?.budget_at_completion?.currency.toUpperCase() || 'USD',
      unit: data?.budget_at_completion?.unit || 'thousand',
      hours: data?.total_hour || 0,
    },
    onSubmit: (values) => {
      setLoading(true)
      patchSimulation(
        {
          budget_at_completion: {
            amount: values.budgetAtCompletion,
            currency: values.currency.toLowerCase(),
            unit: values.unit,
          },
          total_hour: values.hours,
        },
        data._id.$oid
      )
        .then((res) => {
          closeModal()
          refetch()
        })
        .catch((err) =>
          sweetAlert({
            title: 'Error',
            text: err.message || 'Something went wrong!',
            icon: 'error',
            confirmButtonText: 'Close',
          })
        )
        .finally(() => setLoading(false))
    },
    validationSchema: Yup.object().shape({
      budgetAtCompletion: Yup.number()
        .required('Budget Required')
        .min(1, 'Budget must be positive!'),
      hours: Yup.number().required('Hour Number Required').min(0, 'Hour Number must be positive!'),
    }),
  })
  return (
    <Modal aria-labelledby='contained-modal-title-vcenter' centered show={show} onHide={closeModal}>
      <Modal.Header>
        <Modal.Title>Adjust Chart Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body className='mx-10'>
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
            <span className='required'>Currency</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title=''
            ></i>
          </label>
          {currency.length && (
            <CustomSelect
              name='currency'
              maxHeight='200px'
              value={currency.find((item: any) => item.value === formik.values.currency)}
              options={currency}
              onChange={(d: any) => {
                formik.setFieldValue('currency', d.value)
              }}
            />
          )}
          {formik.touched.currency && formik.errors.currency && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.currency.toString()}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
            <span className='required'>Unit</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title=''
            ></i>
          </label>

          <CustomSelect
            name='unit'
            options={unitOptions}
            value={unitOptions.find((item) => item.value === formik.values.unit)}
            onChange={(d: any) => {
              formik.setFieldValue('unit', d.value)
            }}
          />
          {formik.touched.unit && formik.errors.unit && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.unit.toString()}</span>
              </div>
            </div>
          )}
        </div>
        <div className='input-group'>
          <span className='input-group-text'>
            {getFormattedUnit(formik.values.unit)} {findSym(formik.values.currency || '')}
          </span>
          {/*end::Svg Icon*/}

          <input
            type='number'
            placeholder=''
            className={clsx(
              'form-control form-control-lg ',
              {
                'is-invalid': formik.touched.budgetAtCompletion && formik.errors.budgetAtCompletion,
              },
              {
                'is-valid': formik.touched.budgetAtCompletion && !formik.errors.budgetAtCompletion,
              }
            )}
            {...formik.getFieldProps('budgetAtCompletion')}
          />
        </div>
        {formik.touched.budgetAtCompletion && formik.errors.budgetAtCompletion && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.budgetAtCompletion.toString()}</span>
            </div>
          </div>
        )}
        <div className='fv-row my-10'>
          <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
            <span className='required'>Number of Hours</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Specify your unique app name'
            ></i>
          </label>
          <input
            type='text'
            {...formik.getFieldProps('hours')}
            placeholder=''
            className={clsx(
              'form-control ',
              {'is-invalid': formik.touched.hours && formik.errors.hours},
              {
                'is-valid': formik.touched.hours && !formik.errors.hours,
              }
            )}
          />
          {formik.touched.hours && formik.errors.hours && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.hours.toString()}</span>
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type='button'
          className='btn btn-primary'
          id='kt_project_settings_submit'
          onClick={formik.submitForm}
          disabled={loading}
        >
          {!loading && <span className='indicator-label'>Save</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </Modal.Footer>
    </Modal>
  )
}
