import SideFolders from './SideFolders'
import Projects from './Projects'
import {useState, useEffect} from 'react'
import {DndProvider} from 'react-dnd'
import {HTML5Backend} from 'react-dnd-html5-backend'
import {ProjectFilter, ProjectObject} from '../_models'
import {useProjectsData} from '../_queries'
import {Pagination} from '../../components/pagination/Pagination'
import {ToolbarProjectCustom} from '../ToolbaProject'
import clsx from 'clsx'
import {KTSVG, useDebounce} from '../../../../../_metronic/helpers'
import {DataNode} from 'antd/es/tree'
import {constructHierarchy} from './_helpers'
import {findNodeById} from '../../../eps/components/TreeView/_helpers'
import { useAuth } from '../../../auth'
import FreeProjectSelectModal from '../../../../components/FreeProjectSelect'

export default function ProjectsWithFolders() {
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(8)
  const [filter, setFilter] = useState<ProjectFilter>(ProjectFilter.ALL)
  const [selectedFolder, setSelectedFolder] = useState<string>('root')
  const [showModal, setShowModal] = useState<boolean>(false)
  const [availableProjects, setAvailableProjects] = useState<ProjectObject[]>([])
  const { currentPlanLevel } = useAuth()
  const [openProjectSelectModal, setOpenProjectSelectModal] = useState<boolean>(false)
  const [hierarchy, setHierarchy] = useState<{name: string; key: string; parent_folder?: string}[]>(
    [{name: 'root', key: 'root'}]
  )
  const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedTerm = useDebounce(searchTerm, 200)
  const [treeData, setTreeData] = useState<DataNode[]>([])
  const {data, isLoading, refetch} = useProjectsData({
    page,
    perPage,
    filter,
    parent_folder: selectedFolder,
    name: debouncedTerm,
  })

  useEffect(() => {
    if (data && data.items) {
      const visibleProjects = data.items.filter((project: any) => project.is_visible);
      if(currentPlanLevel === 1 && visibleProjects.length > 3){
        setOpenProjectSelectModal(true)
      }else{
        setOpenProjectSelectModal(false)
        setAvailableProjects(visibleProjects);
      }
    }
  }, [data]);

  const [isModalOpen, setIsModalOpen] = useState(true);

    const handleConfirm = () => {
        setIsModalOpen(false);
    };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className='d-flex justify-content-between py-3'>
        <div className='d-flex align-items-center position-relative my-1 '>
          <KTSVG
            path='/media/icons/duotune/general/gen021.svg'
            className='svg-icon-1 position-absolute ms-6'
          />
          <input
            type='text'
            onChange={(e) => setSearchTerm(e.target.value)}
            value={searchTerm}
            data-kt-user-table-filter='search'
            className='form-control form-control w-250px ps-14'
            placeholder='Search project'
          />
        </div>
        <div></div>
        <ToolbarProjectCustom
          filter={filter}
          setFilter={setFilter}
          showCreateAppModalExternal={showModal}
          setShowCreateAppModalExternal={setShowModal}
        />
      </div>
      <ol className='breadcrumb text-muted fs-6 fw-bold d-flex ps-2'>
        {hierarchy.map((item, index) => (
          <li
            key={item.key}
            className={clsx('breadcrumb-item pe-3', {
              'text-muted': index === hierarchy.length - 1,
            })}
          >
            {index === hierarchy.length - 1 && !!index ? (
              <>
                {!index && (
                  <KTSVG
                    path='/media/icons/duotune/general/gen001.svg'
                    className='svg-icon svg-icon-2 text-muted me-1'
                  />
                )}
                {item.name}
              </>
            ) : (
              <a
                onClick={() => {
                  const node = findNodeById(treeData, item.key)
                  if (node) setHierarchy(constructHierarchy(node as any, treeData).hierarchy)
                  setSelectedFolder(item.key)
                }}
                href='#'
                className='pe-3'
              >
                {!index && (
                  <KTSVG
                    path='/media/icons/duotune/general/gen001.svg'
                    className='svg-icon svg-icon-2 text-primary me-1'
                  />
                )}
                {!index ? 'Home' : item.name}
              </a>
            )}
          </li>
        ))}
      </ol>
      <main className='row mt-5 px-0 mx-0 g-3'>
        {openProjectSelectModal && <FreeProjectSelectModal isOpen={isModalOpen} onConfirm={handleConfirm} projects={data}/>}
        <aside className='col-12 col-xl-2'>
          <SideFolders
            refetch={refetch}
            selectedFolder={selectedFolder}
            setSelectedFolder={(folder: string) => {
              setPage(1)
              setSelectedFolder(folder)
            }}
            setHierarchy={setHierarchy}
            setTreeData={setTreeData}
            treeData={treeData}
          />
        </aside>
        <section className='col-12 col-xl-10'>
          <Projects
            data={currentPlanLevel === 1 ? availableProjects : data}
            filter={filter}
            isLoading={isLoading}
            refetch={refetch}
            selectedFolder={selectedFolder}
          />
        </section>
      </main>
      <Pagination
        pagination={data?.meta}
        isLoading={isLoading}
        updateState={(datsa: any) => {
          setPage(datsa.page)
        }}
      />
    </DndProvider>
  )
}
