import * as Yup from 'yup'
import {getCSSVariableValue} from '../../../../../_metronic/assets/ts/_utils'
import {EacFormula, ForecastFormula, ProjectObject} from '../../core/_models'
import {getCustomCurve} from './Project'
import dayjs from 'dayjs'

export type FTEFormModel = {
  total_hours: string
  full_capacity: string
  planned_color: string
  real_color: string
  forecast_color: string
  to_complete_color: string
}

export const InitFTE: FTEFormModel = {
  total_hours: '',
  full_capacity: '',
  planned_color: '#008FFB',
  real_color: '#FEB019',
  forecast_color: '#00E396',
  to_complete_color: '#775DD0',
}

export const FTEValidationSchema = Yup.object().shape({
  total_hours: Yup.number()
    .typeError('Total Hours is a number')
    .required('Required')
    .positive('Must be positive'),
  full_capacity: Yup.number()
    .typeError('Full Capactity is a number')
    .required('Required')
    .positive('Must be positive'),
})

export type MilestonesSettings = {
  plannedColor: string
  achievedColor: string
  forecastColor: string
}

export const InitMilestonesSettings: MilestonesSettings = {
  plannedColor: getCSSVariableValue('--kt-primary'),
  achievedColor: getCSSVariableValue('--kt-success'),
  forecastColor: getCSSVariableValue('--kt-warning'),
}

export type DashboardSettings = {
  WbsRealEndDateColor: string
  WbsRealEndDateThickness: number
  WbsEstimatedEndDateColor: string
  WbsEstimatedEndDateThickness: number
  WpRealEndDateColor: string
  WpRealEndDateThickness: number
  WpEstimatedEndDateColor: string
  WpEstimatedEndDateThickness: number
}

export const InitDashboardSettings: DashboardSettings = {
  WbsRealEndDateColor: getCSSVariableValue('--kt-primary'),
  WbsRealEndDateThickness: 10,  // Default thickness value
  WbsEstimatedEndDateColor: getCSSVariableValue('--kt-success'),
  WbsEstimatedEndDateThickness: 10,  
  WpRealEndDateColor: getCSSVariableValue('--kt-warning'),
  WpRealEndDateThickness: 10,  
  WpEstimatedEndDateColor: getCSSVariableValue('--kt-info'),
  WpEstimatedEndDateThickness: 10,  
}


export type ForecastSettingsFormType = {
  eacFormula: number
  forecastFormula: number
  plannedColor: string
  actualCostColor: string
  earnedValueColor: string
  bottom_up_ec: string
  custom_eac: string
  max_capacity: string
  custom_end_date?: Date
  total_hours: string
  full_capacity: string
}

export type ForecastSettingsFormWithName = ForecastSettingsFormType & {
  name: string
  dateUnit: string
  floatFormatter: any
}

export const InitForecastSettings: ForecastSettingsFormType = {
  eacFormula: 0,
  forecastFormula: 0,
  plannedColor: getCSSVariableValue('--kt-primary'),
  actualCostColor: getCSSVariableValue('--kt-success'),
  earnedValueColor: getCSSVariableValue('--kt-warning'),
  bottom_up_ec: '',
  custom_eac: '',
  max_capacity: '',
  total_hours: '',
  full_capacity: '',
}

export const getInitForecastSettings = (displayVersion: ProjectObject | undefined) => {
  if (!displayVersion) return InitForecastSettings
  return {
    eacFormula: displayVersion?.forecast_settings?.eac_formula || 0,
    forecastFormula: displayVersion?.forecast_settings?.forecast_formula || 0,
    bottom_up_ec:
      (displayVersion?.forecast_settings?.bottom_up_ec.find(
        (item) => item.label === displayVersion.output?.dataDateLabel
      )?.value || '') + '',
    custom_eac: (displayVersion?.forecast_settings?.custom_eac || '') + '',
    max_capacity: (displayVersion?.forecast_settings?.max_capacity || '') + '',
    plannedColor:
      (displayVersion?.colors?.s_cruve_colors &&
        displayVersion?.colors?.s_cruve_colors?.length &&
        displayVersion?.colors?.s_cruve_colors[0]) ||
      getCSSVariableValue('--kt-primary'),
    earnedValueColor:
      (displayVersion?.colors?.s_cruve_colors &&
        displayVersion?.colors?.s_cruve_colors?.length &&
        displayVersion?.colors?.s_cruve_colors[1]) ||
      getCSSVariableValue('--kt-warning'),
    actualCostColor:
      (displayVersion?.colors?.s_cruve_colors &&
        displayVersion?.colors?.s_cruve_colors?.length &&
        displayVersion?.colors?.s_cruve_colors[2]) ||
      getCSSVariableValue('--kt-success'),
    full_capacity: displayVersion?.full_capacity ? displayVersion?.full_capacity + '' : '',
    total_hours: displayVersion?.total_hours ? displayVersion?.total_hours + '' : '',
    custom_end_date: displayVersion?.forecast_settings?.custom_end_date
      ? new Date(displayVersion?.forecast_settings?.custom_end_date.$date)
      : undefined,
  }
}

export const getForecastSettingsValidationSchema = (displayVersion: ProjectObject | undefined) =>
  displayVersion
    ? Yup.object().shape({
        eacFormula: Yup.number().oneOf(
          Array(6)
            .fill(0)
            .map((item, index) => index)
        ),
        forecastFormula: Yup.number()
          .oneOf(
            Array(9)
              .fill(0)
              .map((item, index) => index)
          )
          .test(
            'project_finish_date',
            "Data date can't be bigger than project finish date when using this method",
            (value) => {
              return (
                value !== ForecastFormula['Project Finish Date'] ||
                !dayjs(displayVersion?.data_date.$date).isAfter(
                  displayVersion?.end_date.$date,
                  'month'
                )
              )
            }
          ),

        bottom_up_ec: Yup.number()
          .typeError('Must be a number')
          .positive('Must be positive')
          .when('eacFormula', {
            is: EacFormula['AC + (Bottom UP Estimated Cost)'],
            then: (schema) => schema.required('Field Required'),
          }),
        custom_eac: Yup.number()
          .typeError('Must be a number')
          .when('eacFormula', {
            is: EacFormula['Custom EAC'],
            then: (schema) =>
              schema
                .required('Field Required')
                .min(
                  getCustomCurve('cumulativeActualCost', displayVersion).at(-1),
                  'Must be greater than the last actual cost'
                ),
          }),
        max_capacity: Yup.number()
          .typeError('Must be a number')
          .positive('Must be positive')
          .when('forecastFormula', {
            is: ForecastFormula['Max FTE'],
            then: (schema) => schema.required('Field Required'),
          }),
        total_hours: Yup.number()
          .typeError('Must be a number')
          .positive('Must be positive')
          .when('forecastFormula', {
            is: ForecastFormula['Max FTE'],
            then: (schema) => schema.required('Field Required'),
          }),
        full_capacity: Yup.number()
          .typeError('Must be a number')
          .positive('Must be positive')
          .when('forecastFormula', {
            is: ForecastFormula['Max FTE'],
            then: (schema) => schema.required('Field Required'),
          }),
        custom_end_date: Yup.date()
          .typeError('Must be a date')
          .when('forecastFormula', {
            is: ForecastFormula['Custom Finish Date'],
            then: (schema) =>
              schema
                .required('Field Required')
                .min(
                  dayjs(displayVersion.data_date.$date).add(1, 'month').toDate(),
                  'Must be in the future'
                ),
          }),
      })
    : ForecastSettingsValidationSchema

export const ForecastSettingsValidationSchema = Yup.object().shape({
  eacFormula: Yup.number().oneOf(
    Array(6)
      .fill(0)
      .map((item, index) => index)
  ),
  forecastFormula: Yup.number().oneOf(
    Array(8)
      .fill(0)
      .map((item, index) => index)
  ),
  bottom_up_ec: Yup.number()
    .typeError('Must be a number')
    .positive('Must be positive')
    .when('eacFormula', {
      is: EacFormula['AC + (Bottom UP Estimated Cost)'],
      then: (schema) => schema.required('Field Required'),
    }),
  custom_eac: Yup.number()
    .typeError('Must be a number')
    .when('eacFormula', {
      is: EacFormula['Custom EAC'],
      then: (schema) => schema.required('Field Required'),
    }),
  max_capacity: Yup.number()
    .typeError('Must be a number')
    .positive('Must be positive')
    .when('forecastFormula', {
      is: ForecastFormula['Max FTE'],
      then: (schema) => schema.required('Field Required'),
    }),
  custom_end_date: Yup.date()
    .typeError('Must be a date')
    .when('forecastFormula', {
      is: ForecastFormula['Custom Finish Date'],
      then: (schema) => schema.required('Field Required'),
    }),
})

export const legendLabels: string[] = [
  'PV cum',
  'PV(m)',
  'EV cum',
  'Forecast EV cum',
  'EV p',
  'Forecast EV p',
  'AC cum',
  'Forecast AC cum',
  'AC p',
  'Forecast AC p',
  'Latest PV',
  'BAC',
]

export const labels: {id: string; dashed: boolean; line: boolean}[] = [
  {id: 'PV(cum)', dashed: false, line: true},
  {id: 'PV(m)', dashed: false, line: false},
  {id: 'EV(cum)', dashed: false, line: true},
  {id: 'EV(forecast)', dashed: true, line: true},
  {id: 'EV(m)', dashed: false, line: false},
  {id: 'EV(m forecast)', dashed: true, line: false},
  {id: 'AC(cum)', dashed: false, line: true},
  {id: 'AC(forcast)', dashed: true, line: true},
  {id: 'AC(m)', dashed: false, line: false},
  {id: 'AC(forecast m)', dashed: true, line: false},
  {id: 'Latest PV', dashed: true, line: true},
  {id: 'BAC', dashed: true, line: true},
]
