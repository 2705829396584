import { Tree } from 'antd';
import type { DataNode, EventDataNode } from 'antd/es/tree';
import { getWbs } from '../../_requests';
import { updateTreeData } from './_helpers';
import { useTree } from './DashboardChartTreeContext';
import SwitcherIcon from './icons/SwitcherIcon';
import { Row } from 'react-bootstrap';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { addWbsToStore } from '../../treedata';
import { RootState } from '../../../../store';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useProject } from '../../../projects/core/ProjectContext';
import ChartTreeFolderItem from './ChartTreeFolderItem';
import { useLocation } from 'react-router-dom';
import { getVersionById } from '../../../projects/core/_requests';
import { setVersionData } from '../../treeVersionData';

interface CustomDataNode {
  key: string;
  title: JSX.Element;
  isLeaf: boolean;
  children: any[] | undefined;
}

interface Props {
  handleSelectTreeNode: (version_id: string, project_id: string) => void
}

const DashboardChartTreeView = ({ handleSelectTreeNode }: Props) => {
  const { expandedKeys, setExpandedKeys, setTreeData, treeData } = useTree();
  const dispatch = useDispatch();
  const { project } = useProject();
  const location = useLocation();
  const loadingNodesRef = useRef<Set<string>>(new Set());
  const [localLoadingNodes, setLocalLoadingNodes] = useState<Set<string>>(new Set());
  // Memoize selectors
  const {
    knownWbs,
    projectversiondata,
    isTreeLoaded
  } = useSelector((state: RootState) => ({
    knownWbs: state.treedata.knownWbs,
    projectversiondata: state.versiondata.data,
    isTreeLoaded: state.treedata.isTreeLoaded
  }), shallowEqual);

  useEffect(() => {
    localStorage.setItem('expandedKeys', JSON.stringify(expandedKeys));
  }, [expandedKeys]);

  // Memoize tree data processing
  const processTreeNode = useCallback(async (wbsId: string): Promise<CustomDataNode | void> => {
    let knownWbsItem = knownWbs[wbsId];

    if (!knownWbsItem) {
      const response = await getWbs(wbsId);
      if (response?._id && response?.name && response?.subwbs) {
        dispatch(addWbsToStore(response));
        knownWbsItem = response;
      }
    }

    if (knownWbsItem?.data) {
      const childrenPromises = knownWbsItem.subwbs.map(subWbs =>
        processTreeNode(subWbs.$oid)
      );
      const children = (await Promise.all(childrenPromises)).filter(Boolean);

      return {
        key: knownWbsItem._id.$oid,
        title: <ChartTreeFolderItem folder={knownWbsItem} />,
        isLeaf: children.length === 0,
        children: children.length > 0 ? children : undefined
      };
    }
  }, [knownWbs, dispatch]);

  // Debounced load data function
  const loadData = useCallback(async (treeNode: EventDataNode<DataNode>) => {
    const nodeKey = treeNode.key.toString();

    if (loadingNodesRef.current.has(nodeKey)) return;

    loadingNodesRef.current.add(nodeKey);
    setLocalLoadingNodes(prev => new Set(prev).add(nodeKey));

    try {
      const node = await processTreeNode(nodeKey);
      if (node) {
        setTreeData(prev => updateTreeData(prev, nodeKey, node.children || []));
      }
    } finally {
      loadingNodesRef.current.delete(nodeKey);
      setLocalLoadingNodes(prev => {
        const next = new Set(prev);
        next.delete(nodeKey);
        return next;
      });
    }
  }, [processTreeNode]);

  // Memoized expand handler
  const handleExpand = useCallback(async (
    newExpandedKeys: React.Key[],
    info: { node: EventDataNode<DataNode>; expanded: boolean }
  ) => {
    const { node, expanded } = info;
    const nodeKey = node.key.toString();

    if (expanded && !expandedKeys.includes(nodeKey)) {
      await loadData(node);
    }

    setExpandedKeys(expanded
      ? [...expandedKeys, nodeKey]
      : expandedKeys.filter(key => key !== nodeKey)
    );
  }, [expandedKeys, loadData]);

  // Optimized click handler
  const handleClick = useCallback(async (node: EventDataNode<DataNode>) => {
    const nodeKey = node.key.toString();
    let wbs = knownWbs[nodeKey]

    if (!knownWbs[nodeKey]) {
      wbs = await getWbs(nodeKey);
      dispatch(addWbsToStore(wbs));
    }

    if (wbs?.parent_project?.$oid && wbs?.data?.$oid) {
      handleSelectTreeNode(wbs.data.$oid, wbs.parent_project.$oid);
    }
  }, [knownWbs, handleSelectTreeNode, dispatch]);

  return (
    <>
      <div>
        <Row>
          <Tree
            style={{ overflowX: 'scroll' }}
            selectable={false}
            switcherIcon={SwitcherIcon}
            onExpand={handleExpand}
            expandedKeys={expandedKeys}
            loadData={loadData}
            treeData={treeData}
            onClick={(event, node) => handleClick(node)}
          />
        </Row>
      </div>
    </>
  );
};

export default React.memo(DashboardChartTreeView);
