import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Form } from 'react-bootstrap'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { greaterThanOrEqual } from '../../../../../app/modules/projects/core/ProjectHelpers';
import { sweetAlert } from '../../../../../app/utils/funcs';
import { KTSVG } from '../../../../../_metronic/helpers';
import UploadField from '../../../../../app/components/uploadField';
import { getDiffrentBettwenDate } from '../../../../../app/utils/data-transformarion/date-utils';
import { downloadCustomCurveTemplate, downloadCustomRebaseLine } from '../../../../../app/modules/library/ProjectCurve/Modals/utils';
import { getLabels } from '../../../../../app/helpers/DateFormatter';
import CustomSelect from '../../../../../app/components/CustomSelect';
import { useCurves } from '../../../../../app/modules/library/_queries';
import { Curve } from '../../../../../app/modules/library/_models';
import dayjs from 'dayjs';
import { calculateSigmoidOutputs } from '../../../../../app/helpers/func';
import { applyCalendarToCurve, getCapacity, getReelDays } from '../../../../../app/modules/library/ProjectCurve/Context/ProjectCurveHelpers';

export enum ImportType {
    XLSX = 'XLSX',
    CURVE = 'curve',
}

interface Props {
    show: boolean;
    handleClose: () => void;
    formik: any;
}

const UploadPVACEVModal = ({ show, handleClose, formik }: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const { data, isLoading } = useCurves({ page: 1, per_page: 1000 })
    const [importType, setImportType] = useState<ImportType>(ImportType.XLSX)
    const [curves, setCurves] = useState<Curve[]>([])

    const uploadFormik = useFormik({
        initialValues: {
            cumulativePlannedValue: [],
            cumulativeEarnedValue: [],
            cumulativeActualCost: [],
            budgetAtCompletion: 1,
        },
        validationSchema: Yup.object().shape({
            cumulativePlannedValue: Yup.array()
                .test('number', 'Planned values must be numbers', (value) => value?.every((el) => !isNaN(+el)) || false)
                .test('cum', 'Planned values must be cumulative', (value) => value?.every(greaterThanOrEqual) || false),
            cumulativeEarnedValue: Yup.array()
                .test('number', 'Earned values must be numbers', (value) => value?.every((el) => !isNaN(+el)) || false)
                .test('cum', 'Earned values must be cumulative', (value) => value?.every(greaterThanOrEqual) || false),
            cumulativeActualCost: Yup.array()
                .test('number', 'Actual cost values must be numbers', (value) => value?.every((el) => !isNaN(+el)) || false)
                .test('cum', 'Actual cost values must be cumulative', (value) => value?.every(greaterThanOrEqual) || false),
            budgetAtCompletion: Yup.number().min(1, 'Budget at completion must be greater than 0'),
        }),
        onSubmit: async (values) => {
            try {
                setLoading(true);

                // Update Step5DataSection formik values with uploaded data
                formik.setFieldValue('plannedValue', values.cumulativePlannedValue);
                formik.setFieldValue('earnedValue', values.cumulativeEarnedValue);
                formik.setFieldValue('actualCost', values.cumulativeActualCost);

                sweetAlert({
                    text: 'Data uploaded successfully',
                    icon: 'success',
                    title: 'Success',
                });
                handleClose();
            } catch (error: any) {
                sweetAlert({
                    text: error.message || 'Something went wrong',
                    icon: 'error',
                    title: 'Error',
                });
            } finally {
                setLoading(false);
            }
        },
    });


    useEffect(() => {
        const localCurves: any = []
        data?.items
            .filter(
                (item) =>
                    dayjs(item.start_date.$date)
                        .add(item.duration - 1, 'months')
                        .isSame(formik.values.end_date.getTime(), 'month') &&
                    dayjs(item.start_date.$date).isSame(formik.values.start_date.getTime(), 'month')
            )
            .forEach((item) => {
                localCurves.push({
                    label: item.name,
                    value: {
                        name: item?.name,
                        tangent: item?.tangent,
                        offset: item.axis,
                        duration: item.duration,
                        budget_at_completion: item.budget_at_completion.amount || undefined,
                    },
                })
            })
        setCurves(localCurves)
    }, [isLoading, formik])

    const handleChange = async (curve: {
        tangent: number
        offset: number
        duration: number
        name: string
    }) => {
        let tableData = calculateSigmoidOutputs(
            {
                projectDurationUnit: 'month',
                mu: curve.tangent,
                sig: curve.offset,
                nbOfMonthsPassed: curve.duration,
            },
            false
        ).tableData

        if (formik.values.associated_calendar) {
            const { reelDays, totalDays } = await getReelDays(
                formik.values.start_date,
                curve.duration,
                formik.values.associated_calendar
            )
            const capacity = getCapacity(reelDays, totalDays)
            tableData = applyCalendarToCurve(capacity, {
                tableData,
                isInitialState: false,
                highlightIdx: -1,
            }).tableData
        }

        const pvTable = tableData[1].map(
            (el: number) =>
                +((el * (uploadFormik.values.budgetAtCompletion > 0 ? uploadFormik.values.budgetAtCompletion : 1)) / 100).toFixed(
                    2
                )
        )
        formik.setFieldValue('savedCurve', {
            name: curve.name,
            offset: curve.offset,
            tangent: curve.tangent,
        })
        uploadFormik.setFieldTouched('cumulativePlannedValue', true);
        uploadFormik.setFieldValue('cumulativePlannedValue', pvTable);
    }


    const handleDownloadTemplate = (type: 'PV' | 'EV' | 'AC') => {
        const startDate = new Date(formik.values.start_date);
        const endDate = new Date(formik.values.end_date);
        const dataDate = new Date(formik.values.data_date);

        let templateStartDate, duration, dataObject: any[];

        if (type === 'PV') {
            templateStartDate = startDate;
            duration = getDiffrentBettwenDate(endDate.getTime(), startDate.getTime(), 'monthly') + 1;
            dataObject = [
                { header: 'Date', data: getLabels(startDate.getTime(), duration, 'month') },
                { header: 'Planned Value', data: Array(duration).fill(0) },
            ];
        } else {
            templateStartDate = dataDate;
            duration = getDiffrentBettwenDate(endDate.getTime(), dataDate.getTime(), 'monthly') + 1;
            dataObject = [
                { header: 'Date', data: getLabels(dataDate.getTime(), duration, 'month') },
                { header: type === 'EV' ? 'Earned Value' : 'Actual Cost', data: Array(duration).fill(0) },
            ];
        }

        dataObject = Array.from({ length: duration }, (_, index) => ({
            header: dataObject[0].data[index],
            data: dataObject[1].data[index],
        }));

        downloadCustomRebaseLine(
            templateStartDate,
            duration,
            dataObject,
            endDate
        );
    };
    return (
        <Modal
            id="upload_pv_ev_ac_modal"
            tabIndex={-1}
            aria-hidden="true"
            dialogClassName="modal-content rounded modal-content-centered mw-900px"
            show={show}
            onHide={() => {
                uploadFormik.resetForm();
                handleClose();
            }}
            backdrop={true}
        >


            <div className="modal-header pb-0 border-0 justify-content-end">
                <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={handleClose}>
                    <KTSVG className="svg-icon-1" path="/media/icons/duotune/arrows/arr061.svg" />
                </div>
            </div>
            <div className="mb-13 text-center">
                <h1 className="mb-3">Upload PV, EV, and AC Data</h1>
            </div>

            <div className='row justify-content-center'>
                <label className='col-12 col-xl-8 fs-6 fw-bolder text-dark mb-7d-flex align-items-center fs-5 fw-semibold mb-4'>
                    <Form.Group className='d-flex py-5 justify-content-between'>
                        <Form.Check
                            type='radio'
                            label='Import Excel file'
                            value={ImportType.XLSX}
                            name='radio-group'
                            onChange={(e) => setImportType(e.target.value as ImportType)}
                            defaultChecked={true}
                        />
                        <Form.Check
                            type='radio'
                            label='Import Virtual Baseline'
                            value={ImportType.CURVE}
                            name='radio-group'
                            onChange={(e) => setImportType(e.target.value as ImportType)}
                        />
                    </Form.Group>
                </label>
            </div>
            <div className="modal-body py-lg-10 px-lg-10">

                <form onSubmit={uploadFormik.handleSubmit}>

                    {importType === ImportType.XLSX ? (
                        <>
                            <div className="fv-row mb-10">
                                <label className="fs-6 fw-bolder text-dark mb-4 d-flex justify-content-between align-items-center">
                                    <span>Cumulative Planned Value (PV)</span>
                                    <a href="#" className="link-primary" onClick={() => handleDownloadTemplate('PV')}>
                                        Download Template
                                    </a>
                                </label>
                                <UploadField
                                    index={0}
                                    durationUnit=""
                                    setFileContent={(table: any) => {
                                        uploadFormik.setFieldTouched('cumulativePlannedValue', true);
                                        uploadFormik.setFieldValue('cumulativePlannedValue', table);
                                    }}
                                />
                                {uploadFormik.errors.cumulativePlannedValue && uploadFormik.touched.cumulativePlannedValue && (
                                    <div className="text-danger mt-2">{uploadFormik.errors.cumulativePlannedValue}</div>
                                )}
                            </div>
                        </>
                    ) : (
                        <>
                            <div className='fv-row'>
                                <label className='fs-6 fw-bolder text-dark mb-7d-flex align-items-center fs-5 fw-semibold mb-4'>
                                    <div className='text-muted fw-semibold '>
                                        Or you can use a saved curve as base line
                                    </div>
                                </label>
                            </div>
                            <div className='fv-row mb-10'>
                                <label className='fs-6 fw-bolder text-dark mb-7d-flex align-items-center fs-5 fw-semibold mb-4'>
                                    <span className='required'>Curve Library</span>
                                </label>
                                <CustomSelect
                                    maxHeight='200px'
                                    name='savedSimulation'
                                    options={curves}
                                    onChange={(d: any) => handleChange(d.value as any)}
                                />
                                {/* Budget at Completion Field */}

                                <div className="fv-row mb-10">

                                    <label className="fs-6 fw-bolder text-dark mb-4">Budget at Completion</label>

                                    <input

                                        type="number"

                                        className="form-control"

                                        name="budgetAtCompletion"

                                        value={uploadFormik.values.budgetAtCompletion}

                                        onChange={(e) => {
                                            const value = Number(e.target.value);
                                            uploadFormik.setFieldTouched('budgetAtCompletion', true);
                                            uploadFormik.setFieldValue('budgetAtCompletion', value);

                                        }}

                                    />

                                    {uploadFormik.errors.budgetAtCompletion && uploadFormik.touched.budgetAtCompletion && (

                                        <div className="text-danger mt-2">{uploadFormik.errors.budgetAtCompletion}</div>

                                    )}

                                </div>
                                <div className='alert alert-light d-flex align-items-center p-3 my-5'>
                                    <KTSVG
                                        className='svg-icon svg-icon-2hx svg-icon-secondary-dark me-3'
                                        path='/media/icons/duotune/general/gen045.svg'
                                    />
                                    <span className='text-muted'>
                                        Project start date, duration and calendar need to match the selected curve.
                                    </span>
                                </div>
                            </div>
                        </>
                    )}






                    <div className="fv-row mb-10">
                        <label className="fs-6 fw-bolder text-dark mb-4 d-flex justify-content-between align-items-center">
                            <span>Cumulative Earned Value (EV)</span>
                            <a href="#" className="link-primary" onClick={() => handleDownloadTemplate('EV')}>
                                Download Template
                            </a>
                        </label>
                        <UploadField
                            index={1}
                            durationUnit=""
                            setFileContent={(table: any) => {
                                uploadFormik.setFieldTouched('cumulativeEarnedValue', true);
                                uploadFormik.setFieldValue('cumulativeEarnedValue', table);
                            }}
                        />
                        {uploadFormik.errors.cumulativeEarnedValue && uploadFormik.touched.cumulativeEarnedValue && (
                            <div className="text-danger mt-2">{uploadFormik.errors.cumulativeEarnedValue}</div>
                        )}
                    </div>

                    <div className="fv-row mb-10">
                        <label className="fs-6 fw-bolder text-dark mb-4 d-flex justify-content-between align-items-center">
                            <span>Cumulative Actual Cost (AC)</span>
                            <a href="#" className="link-primary" onClick={() => handleDownloadTemplate('AC')}>
                                Download Template
                            </a>
                        </label>
                        <UploadField
                            index={2}
                            durationUnit=""
                            setFileContent={(table: any) => {
                                uploadFormik.setFieldTouched('cumulativeActualCost', true);
                                uploadFormik.setFieldValue('cumulativeActualCost', table);
                            }}
                        />
                        {uploadFormik.errors.cumulativeActualCost && uploadFormik.touched.cumulativeActualCost && (
                            <div className="text-danger mt-2">{uploadFormik.errors.cumulativeActualCost}</div>
                        )}
                    </div>

                    <div className="d-flex flex-stack pt-10">
                        <div>
                            <button
                                type="submit"
                                className="btn btn-lg btn-primary"
                                disabled={!uploadFormik.isValid || !uploadFormik.dirty || loading}
                            >
                                {loading ? (
                                    <span className="indicator-progress" style={{ display: 'block' }}>
                                        Please wait...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                ) : (
                                    <span className="indicator-label">Upload Data</span>
                                )}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default UploadPVACEVModal;
