/* eslint-disable jsx-a11y/anchor-is-valid */
import { formatFloatFromString, getFormattedBudget, IBudget } from '../../../../utils/funcs'
import { getCustomCurve, ProjectDetailsTypes } from './Project'
import moment from 'moment'
import { getPeriodicFromCumulative } from '../data/prepareDataTable'
import { getFormattedDate } from '../../../../helpers/DateFormatter'
import Collapse from 'react-bootstrap/Collapse'
import { KTSVG } from '../../../../../_metronic/helpers'
import clsx from 'clsx'
import { formatNumber } from '../../../../utils/formatter'
import { formatScheduleVariance } from './_helpers'
import { ReactNode } from 'react'

type Props = {
  data: any
  widgetTitle: string
  className: string
  color: string
  projectData: any
  widgetType: string
  collapsed: boolean
  toggleCollapse: () => void
  isRebaseLined?: boolean
}

const ProjectWidget: React.FC<Props> = ({
  data,
  widgetTitle,
  className,
  color,
  projectData,
  isRebaseLined,
  widgetType,
  collapsed,
  toggleCollapse,
}) => {
  const getAverageOfSlicedTable = (Tabs: any, nbrUnite: number) => {
    if (nbrUnite < 1) return 0
    return (
      Tabs.slice(0, nbrUnite).reduce(
        (partialSum: any, a: any) => !isNaN(Number(a)) && partialSum + Number(a),
        0
      ) / nbrUnite
    ).toFixed(2)
  }
  const convertDateUnite = (delayUnit: string) => {
    switch (delayUnit) {
      case 'monthly':
        return 'Months'
      case 'daily':
        return 'Days'
      case 'yearly':
        return 'Years'
      default:
        break
    }
  }
  const getDiffrentBettwenDate = (endDate: number, startDate: number, unit: string) => {
    const date1 = moment(startDate)
    const date2 = moment(endDate)
    switch (unit) {
      case 'monthly':
        return date2.diff(date1, 'months')
      case 'daily':
        return date2.diff(date1, 'days')
      case 'yearly':
        return date2.diff(date1, 'years')
      default:
        break
    }
  }
  const dataDateIndex =
    getDiffrentBettwenDate(data.data_date.$date, data.start_date.$date, data.period_count.type) || 0

  const spiDiff = parseFloat(
    (data?.output?.SPICum?.at(-1) - data?.output?.SPICum?.at(-2)).toFixed(data.float_formatter || 0)
  )

  const cpiDiff = parseFloat(
    (data?.output?.cpi?.cumulative.at(-1) - data?.output?.cpi?.cumulative.at(-2)).toFixed(
      data.float_formatter || 0
    )
  )

  const showPVPeriodic = (): ReactNode => {
    return isRebaseLined ? '--' : data?.output?.CardsData?.ActualProjectData?.PV?.Periodic
      ? formatFloatFromString(
        data?.output.CardsData.ActualProjectData.PV.Periodic,
        data.float_formatter || 0
      )
      : getFormattedBudget({
        ...data?.budget_at_completion,
        amount: getPeriodicFromCumulative(
          getCustomCurve('cumulativePlannedValue', data)
        )[dataDateIndex || 0],
        floatFormater: data.float_formatter,
      } as IBudget) || '-'
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body p-0'>
        {/* begin::Header */}
        <div className={`px-9 pt-7 card-rounded h-150px w-100  bg-${color}`}>
          {/* begin::Heading */}
          <div className=''>
            <h3 className='text-white fw-bold fs-2 text-center '>{widgetTitle.toUpperCase()}</h3>
          </div>
          {/* end::Heading */}
        </div>
        {/* end::Header */}
        {/* begin::Items */}
        <div
          className='card-rounded mx-3  px-5 py-9  position-relative z-index-1 bg-body'
          style={{ marginTop: '-80px' }}
        >
          {widgetType === ProjectDetailsTypes.PROJECT_INFORMATION && (
            <>
              <div className='d-flex align-items-center '>
                {/* begin::Description */}
                <span className='text-gray-600 fw-semibold fs-7'>Project Name</span>
                {/* end::Description */}
              </div>
              <div className='d-flex align-items-center'>
                {/* begin::Description */}
                <h6 className={`fs-12 fw-semibold text-${color}`}>{projectData.name}</h6>
                {/* end::Description */}
              </div>
              {/* end::Item */}
              {/* begin::Item */}
              <div className='d-flex align-items-center '>
                {/* begin::Description */}
                <span className='text-gray-600 fw-semibold fs-7'>Reference number</span>
                {/* end::Description */}
              </div>
              <div className='d-flex align-items-center'>
                {/* begin::Description */}
                <h6 className={`fs-12 fw-semibold text-gray-800`}>
                  {projectData.reference_number}
                </h6>
                {/* end::Description */}
              </div>
              {/* end::Item */}
              {/* begin::Item */}
              <Collapse className='p-0 m-0' in={!collapsed}>
                <div className='wrapper'>
                  <div>
                    <hr className='h-25 w-50 m-auto p-3 mt-3' />
                    {/* end::Item */}
                    <div className='d-flex align-items-center '>
                      {/* begin::Description */}
                      <span className='text-gray-600 fw-semibold fs-7'>Budget At Complation</span>
                      {/* end::Description */}
                    </div>
                    <div className='d-flex align-items-center'>
                      {/* begin::Description */}
                      <h6 className={`fs-12 fw-semibold text-${color}`}>
                        {' '}
                        {getFormattedBudget({
                          ...data?.budget_at_completion,
                          amount: data.budget_at_completion.amount,
                          floatFormater: data.float_formatter,
                        } as IBudget)}
                      </h6>
                      {/* end::Description */}
                    </div>
                    {/* end::Item */}
                    {/* begin::Item */}
                    <div className='d-flex align-items-center '>
                      {/* begin::Description */}
                      <span className='text-gray-600 fw-semibold fs-7'>Management Reserve</span>
                      {/* end::Description */}
                    </div>
                    <div className='d-flex align-items-center'>
                      {/* begin::Description */}
                      <h6 className={`fs-12 fw-semibold text-gray-800 `}>
                        {getFormattedBudget(
                          {
                            ...data?.budget_at_completion,
                            amount: data?.reserve?.amount,
                            floatFormater: data.float_formatter,
                          } as IBudget,
                          true
                        )}
                      </h6>
                      {/* end::Description */}
                    </div>
                    {/* end::Item */}
                    <hr className='h-25 w-50 m-auto p-3 mt-3' />
                    {/* end::Item */}
                    {/* begin::Item */}
                    <div className='d-flex align-items-center '>
                      {/* begin::Description */}
                      <span className='text-gray-600 fw-semibold fs-7'>Start Date</span>
                      {/* end::Description */}
                    </div>
                    <div className='d-flex align-items-center'>
                      {/* begin::Description */}
                      <h6 className={`fs-12 fw-semibold text-gray-800 `}>
                        {' '}
                        {getFormattedDate(
                          data?.start_date?.$date,
                          data?.period_count?.type,
                          data?.date_format
                        )}
                      </h6>
                      {/* end::Description */}
                    </div>
                    {/* end::Item */}
                    {/* begin::Item */}
                    <div className='d-flex align-items-center '>
                      {/* begin::Description */}
                      <span className='text-gray-600 fw-semibold fs-7'>Planned Finish Date</span>
                      {/* end::Description */}
                    </div>
                    <div className='d-flex align-items-center'>
                      {/* begin::Description */}
                      <h6 className={`fs-12 fw-semibold text-${color}`}>
                        {' '}
                        {getFormattedDate(
                          data?.end_date?.$date,
                          data?.period_count?.type,
                          data?.date_format
                        )}
                      </h6>
                      {/* end::Description */}
                    </div>
                    {/* end::Item */}
                    {/* end::Item */}
                    <div className='d-flex align-items-center '>
                      {/* begin::Description */}
                      <span className='text-gray-600 fw-semibold fs-7'>Planned Duration</span>
                      {/* end::Description */}
                    </div>
                    <div className='d-flex align-items-center'>
                      {/* begin::Description */}
                      <h6 className={`fs-12 fw-semibold text-gray-800 `}>
                        {(getDiffrentBettwenDate(
                          data.end_date.$date,
                          data.start_date.$date,
                          data.delay_tolerance.type
                        ) || 0) + 1}{' '}
                        {convertDateUnite(data.delay_tolerance.type)}
                      </h6>
                      {/* end::Description */}
                    </div>
                    {/* end::Item */}
                    {/* begin::Item */}
                    <div className='d-flex align-items-center '>
                      {/* begin::Description */}
                      <span className='text-gray-600 fw-semibold fs-7'>Delay tolerance</span>
                      {/* end::Description */}
                    </div>
                    <div className='d-flex align-items-center'>
                      {/* begin::Description */}
                      <h6 className={`fs-12 fw-semibold text-gray-800`}>
                        {data.delay_tolerance.count} {convertDateUnite(data.delay_tolerance.type)}
                      </h6>
                      {/* end::Description */}
                    </div>
                  </div>
                </div>
              </Collapse>
              {/* end::Item */}
            </>
          )}
          {widgetType === ProjectDetailsTypes.ACTUAL_PROJECT_DATA && (
            <>
              <div className='d-flex align-items-center '>
                {/* begin::Description */}
                <span className='fs-5 text-gray-800 fw-semibold '>
                  PV <span className='fs-7 text-gray-600'>(Planned Value)</span>
                </span>
                {/* end::Description */}
              </div>
              <div className='d-flex align-items-center'>
                {/* begin::Description */}
                <h1 className='col-6 text-gray-400 fw-semibold fs-7'>Cumulative</h1>
                <h6 className={`fs-25 col-6 font-weight-bold text-end text-${color}`}>
                  {data?.output?.CardsData?.ActualProjectData?.PV?.Cumulative
                    ? formatFloatFromString(
                      data?.output?.CardsData?.ActualProjectData?.PV?.Cumulative,
                      data.float_formatter || 0
                    )
                    : getFormattedBudget({
                      ...data?.budget_at_completion,
                      amount: getCustomCurve('cumulativePlannedValue', data)[dataDateIndex || 0],
                      floatFormater: data.float_formatter,
                    } as IBudget)}
                </h6>
                {/* end::Description */}
              </div>
              <Collapse in={!collapsed}>
                <div className='wrapper'>
                  <div>
                    <div className='d-flex align-items-center'>
                      {/* begin::Description */}
                      <h1 className='col-6 text-gray-400 fw-semibold fs-7'>Periodic</h1>
                      <h6 className={`fs-25 col-6 font-weight-bold text-gray-800 text-end`}>
                        {showPVPeriodic()}
                      </h6>
                      {/* end::Description */}
                    </div>
                    <div className='d-flex align-items-center'>
                      {/* begin::Description */}
                      <h1 className='col-6 text-gray-400 fw-semibold fs-7'>Average</h1>
                      <h6 className={`fs-25 col-6 font-weight-bold text-gray-800 text-end`}>
                        {data?.output?.CardsData?.ActualProjectData?.PV?.Average
                          ? formatFloatFromString(
                            data?.output.CardsData.ActualProjectData.PV.Average,
                            data.float_formatter || 0
                          )
                          : getFormattedBudget({
                            ...data?.budget_at_completion,
                            amount: getAverageOfSlicedTable(
                              getPeriodicFromCumulative(
                                getCustomCurve('cumulativePlannedValue', data)
                              ),
                              dataDateIndex || 0
                            ),
                            floatFormater: data.float_formatter,
                          } as IBudget) || '-'}
                      </h6>
                      {/* end::Description */}
                    </div>
                  </div>
                </div>
              </Collapse>
              <hr className='h-25 w-50 m-auto p-3 mt-3' />
              <div className='d-flex align-items-center '>
                {/* begin::Description */}
                <span className='fs-5 text-gray-800 fw-semibold '>
                  EV <span className='fs-7 text-gray-600'>(Earned Value)</span>
                </span>
                {/* end::Description */}
              </div>
              <div className='d-flex align-items-center'>
                {/* begin::Description */}
                <h1 className='col-6 text-gray-400 fw-semibold fs-7'>Cumulative</h1>
                <h6 className={`fs-25 col-6 font-weight-bold text-${color} text-end`}>
                  {data?.output?.CardsData?.ActualProjectData?.EV?.Cumulative
                    ? formatFloatFromString(
                      data?.output?.CardsData?.ActualProjectData?.EV?.Cumulative,
                      data.float_formatter || 0
                    )
                    : getFormattedBudget({
                      ...data?.budget_at_completion,
                      amount: getCustomCurve('cumulativeEarnedValue', data)[dataDateIndex || 0],
                      floatFormater: data.float_formatter,
                    } as IBudget)}
                </h6>
                {/* end::Description */}
              </div>
              <Collapse in={!collapsed}>
                <div className='wrapper'>
                  <div>
                    <div className='d-flex align-items-center'>
                      {/* begin::Description */}
                      <h1 className='col-6 text-gray-400 fw-semibold fs-7'>Periodic</h1>
                      <h6 className={`fs-25 col-6 font-weight-bold text-end text-gray-800`}>
                        {data?.output?.CardsData?.ActualProjectData?.EV?.Periodic
                          ? formatFloatFromString(
                            data?.output.CardsData.ActualProjectData.EV.Periodic,
                            data.float_formatter || 0
                          )
                          : getFormattedBudget({
                            ...data?.budget_at_completion,
                            amount: getPeriodicFromCumulative(
                              getCustomCurve('cumulativeEarnedValue', data)
                            )[dataDateIndex || 0],
                            floatFormater: data.float_formatter,
                          } as IBudget) || '-'}
                      </h6>
                      {/* end::Description */}
                    </div>
                    <div className='d-flex align-items-center'>
                      {/* begin::Description */}
                      <h1 className='col-6 text-gray-400 fw-semibold fs-7'>Average</h1>
                      <h6 className={`fs-25 col-6 font-weight-bold text-end text-gray-800`}>
                        {data?.output?.CardsData?.ActualProjectData?.EV?.Average
                          ? formatFloatFromString(
                            data?.output.CardsData.ActualProjectData.EV.Average,
                            data.float_formatter || 0
                          )
                          : getFormattedBudget({
                            ...data?.budget_at_completion,
                            amount: getAverageOfSlicedTable(
                              getPeriodicFromCumulative(
                                getCustomCurve('cumulativeEarnedValue', data)
                              ),
                              dataDateIndex || 0
                            ),
                            floatFormater: data.float_formatter,
                          } as IBudget) || '-'}
                      </h6>
                      {/* end::Description */}
                    </div>
                  </div>
                </div>
              </Collapse>
              <hr className='h-25 w-50 m-auto p-3 mt-3' />
              <div className='d-flex align-items-center '>
                {/* begin::Description */}
                <span className='fs-5 text-gray-800 fw-semibold '>
                  AC <span className='fs-7 text-gray-600'>(Actual Cost)</span>
                </span>

                {/* end::Description */}
              </div>
              <div className='d-flex align-items-center'>
                {/* begin::Description */}
                <h1 className='col-6 text-gray-400 fw-semibold fs-7'>Cumulative</h1>
                <h6 className={`fs-25 col-6 font-weight-bold text-end text-${color}`}>
                  {data?.output?.CardsData?.ActualProjectData?.AC?.Cumulative
                    ? formatFloatFromString(
                      data?.output.CardsData.ActualProjectData.AC.Cumulative,
                      data.float_formatter || 0
                    )
                    : getFormattedBudget({
                      ...data?.budget_at_completion,
                      amount: getCustomCurve('cumulativeActualCost', data)[dataDateIndex || 0],
                      floatFormater: data.float_formatter,
                    } as IBudget)}
                </h6>
                {/* end::Description */}
              </div>
              <Collapse in={!collapsed}>
                <div className='wrapper'>
                  <div>
                    <div className='d-flex align-items-center'>
                      {/* begin::Description */}
                      <h1 className='col-6 text-gray-400 fw-semibold fs-7'>Periodic</h1>
                      <h6 className={`fs-25 col-6 font-weight-bold text-end text-gray-800`}>
                        {data?.output?.CardsData?.ActualProjectData?.AC?.Periodic
                          ? formatFloatFromString(
                            data?.output.CardsData.ActualProjectData.AC.Periodic,
                            data.float_formatter || 0
                          )
                          : getFormattedBudget({
                            ...data?.budget_at_completion,
                            amount: getPeriodicFromCumulative(
                              getCustomCurve('cumulativeActualCost', data)
                            )[dataDateIndex || 0],
                            floatFormater: data.float_formatter,
                          } as IBudget) || '-'}
                      </h6>
                      {/* end::Description */}
                    </div>
                    <div className='d-flex align-items-center'>
                      {/* begin::Description */}
                      <h1 className='col-6 text-gray-400 fw-semibold fs-7'>Average</h1>
                      <h6 className={`fs-25 col-6 font-weight-bold text-gray-800 text-end`}>
                        {data?.output?.CardsData?.ActualProjectData?.PV?.Average
                          ? formatFloatFromString(
                            data?.output.CardsData.ActualProjectData.PV.Average,
                            data.float_formatter || 0
                          )
                          : getFormattedBudget({
                            ...data?.budget_at_completion,
                            amount: getAverageOfSlicedTable(
                              getPeriodicFromCumulative(
                                getCustomCurve('cumulativePlannedValue', data)
                              ),
                              dataDateIndex || 0
                            ),
                            floatFormater: data.float_formatter,
                          } as IBudget) || '-'}
                      </h6>
                      {/* end::Description */}
                    </div>
                  </div>
                </div>
              </Collapse>
            </>
          )}
          {widgetType === ProjectDetailsTypes.COST_PERFORMENCE && (
            <>
              <div className='d-flex align-items-center '>
                {/* begin::Description */}

                <span className='fs-5 text-gray-800 fw-semibold '>
                  CPI <span className='fs-7 text-gray-600'>(Cost Performance Index)</span>
                </span>
                {/* end::Description */}
              </div>
              <div className='d-flex align-items-center'>
                {/* begin::Description */}
                <h1 className='col-6 text-gray-400 fw-semibold fs-7'>Cumulative</h1>
                <span className='d-flex align-items-center gap-3 justify-content-end fs-25 col-6 font-weight-bold text-end'>
                  {data?.output?.cpi?.cumulative?.length >= 2 && (
                    <div
                      className='d-flex rotate active opacity-50'
                      data-toggle='tooltip'
                      title='CPI Variance between data date and the previous date'
                    >
                      {cpiDiff ? (
                        <KTSVG
                          className={clsx('svg-icon svg-icon-2 mb-2', {
                            'text-danger rotate-n90': cpiDiff < 0,
                            'text-success rotate-90': cpiDiff > 0,
                            'text-muted': !cpiDiff,
                          })}
                          path='/media/icons/duotune/arrows/arr026.svg'
                        />
                      ) : (
                        <div
                          style={{ height: '1.5rem', width: '1.5rem' }}
                          className='bg-secondary mb-2 me-2'
                        ></div>
                      )}
                      <h6
                        className={clsx({
                          'text-danger': cpiDiff < 0,
                          'text-success': cpiDiff >= 0,
                          'text-muted': !cpiDiff,
                        })}
                      >
                        {formatNumber(cpiDiff, data?.float_formatter || 0, '.')}
                      </h6>
                      <div className='d-flex flex-row gap-2 align-items-center mb-2 ms-2'>
                        <div
                          className='bg-secondary rounded'
                          style={{ height: '15px', width: '3px' }}
                        />
                      </div>
                    </div>
                  )}
                  <h6
                    className={clsx({
                      'text-danger': data?.output?.CardsData?.CostPerformance?.CPI?.Cumulative < 1,
                      'text-success':
                        data?.output?.CardsData?.CostPerformance?.CPI?.Cumulative >= 1,
                    })}
                  >
                    {data?.output?.CardsData?.CostPerformance?.CPI?.Cumulative
                      ? formatFloatFromString(
                        data?.output.CardsData.CostPerformance.CPI.Cumulative,
                        data.float_formatter || 0
                      )
                      : '-'}
                  </h6>
                </span>
                {/* end::Description */}
              </div>
              <Collapse in={!collapsed}>
                <div className='wrapper'>
                  <div>
                    <div className='d-flex align-items-center'>
                      {/* begin::Description */}
                      <h1 className='col-6 text-gray-400 fw-semibold fs-7'>Periodic</h1>
                      <h6 className={`fs-25 col-6 font-weight-bold text-gray-800 text-end`}>
                        {data?.output?.CardsData?.CostPerformance?.CPI?.Periodic
                          ? formatFloatFromString(
                            data?.output.CardsData.CostPerformance.CPI.Periodic,
                            data.float_formatter || 0
                          )
                          : ''}
                      </h6>
                      {/* end::Description */}
                    </div>
                    <div className='d-flex align-items-center'>
                      {/* begin::Description */}
                      <h1 className='col-6 text-gray-400 fw-semibold fs-7'>Average</h1>
                      <h6 className={`fs-25 col-6 font-weight-bold text-gray-800 text-end`}>
                        {data?.output?.CardsData?.CostPerformance?.CPI?.Average
                          ? formatFloatFromString(
                            data?.output.CardsData.CostPerformance.CPI.Average,
                            data.float_formatter || 0
                          )
                          : '-'}
                      </h6>
                      {/* end::Description */}
                    </div>
                  </div>
                </div>
              </Collapse>

              <hr className='h-25 w-50 m-auto p-3 mt-3' />
              <div className='d-flex align-items-center '>
                {/* begin::Description */}

                <span className='fs-5 text-gray-800 fw-semibold '>
                  CV <span className='fs-7 text-gray-600'>(Cost Variance)</span>
                </span>
                {/* end::Description */}
              </div>
              <div className='d-flex align-items-center'>
                {/* begin::Description */}
                <h1 className='col-6 text-gray-400 fw-semibold fs-7'>Cumulative</h1>
                <h6
                  className={clsx('fs-25 col-6 font-weight-bold text-end', {
                    'text-danger': +data?.output?.CVCum?.at(-1) < 0,
                    'text-success': +data?.output?.CVCum?.at(-1) >= 0,
                  })}
                >
                  {data?.output?.CardsData?.CostPerformance?.CV?.Cumulative
                    ? formatFloatFromString(
                      data?.output.CardsData.CostPerformance.CV.Cumulative,
                      data.float_formatter || 0
                    )
                    : '-'}
                </h6>
                {/* end::Description */}
              </div>
              <Collapse in={!collapsed}>
                <div className='wrapper'>
                  <div>
                    <div className='d-flex align-items-center'>
                      {/* begin::Description */}
                      <h1 className='col-6 text-gray-400 fw-semibold fs-7'>Periodic</h1>
                      <h6 className={`fs-25 col-6 font-weight-bold text-gray-800 text-end`}>
                        {data?.output?.CardsData?.CostPerformance?.CV?.Periodic
                          ? formatFloatFromString(
                            data?.output.CardsData.CostPerformance.CV.Periodic,
                            data.float_formatter || 0
                          )
                          : '-'}
                      </h6>
                      {/* end::Description */}
                    </div>
                    <div className='d-flex align-items-center'>
                      {/* begin::Description */}
                      <h1 className='col-6 text-gray-400 fw-semibold fs-7'>Average</h1>
                      <h6 className={`fs-25 col-6 font-weight-bold text-gray-800 text-end`}>
                        {data?.output?.CardsData?.CostPerformance?.CV?.Average
                          ? formatFloatFromString(
                            data?.output.CardsData.CostPerformance.CV.Average,
                            data.float_formatter || 0
                          )
                          : '-'}
                      </h6>
                      {/* end::Description */}
                    </div>
                  </div>
                </div>
              </Collapse>
              <hr className='h-25 w-50 m-auto p-3 mt-3' />
              <div className='d-flex align-items-center '>
                {/* begin::Description */}
                <h1 className={`fs-7 fw-semibold  text-${color}`}>forecast</h1>
                {/* end::Description */}
              </div>
              <div className='d-flex align-items-center '>
                {/* begin::Description */}

                <span className='fs-5 text-gray-800 fw-semibold '>
                  EAC <span className='fs-7 text-gray-600'>(Estimated At Completion)</span>
                </span>
                {/* end::Description */}
              </div>
              <div className='d-flex justify-content-between align-self-start'>
                {/* begin::Description */}
                <h6 className={`fs-12 align-self-start font-weight-bold text-${color}`}>
                  {data?.output?.CardsData?.CostPerformance?.EAC?.Value
                    ? formatFloatFromString(
                      data?.output.CardsData.CostPerformance.EAC.Value,
                      data.float_formatter || 0
                    )
                    : '-'}
                </h6>
                <span
                  className={`fs-12 align-self-end font-weight-bold text-end  text-${data?.output?.CardsData?.CostPerformance?.EAC?.sign === 'positive'
                    ? 'danger'
                    : 'success'
                    }`}
                >
                  {data?.output?.CardsData?.CostPerformance?.EAC?.Difference
                    ? data?.output.CardsData.CostPerformance.EAC.sign !== 'null' &&
                    formatFloatFromString(
                      data?.output.CardsData.CostPerformance.EAC.Difference,
                      data.float_formatter || 0
                    )
                    : '-'}
                  <br></br>
                  <span className='fs-12 align-self-end font-weight-bold text-gray-400 text-end '>
                    {data?.output?.CardsData?.CostPerformance?.EAC?.sign
                      ? data?.output.CardsData.CostPerformance.EAC.sign === 'positive'
                        ? 'over budget'
                        : data?.output.CardsData.CostPerformance.EAC.sign === 'null'
                          ? 'on budget'
                          : 'under budget'
                      : ''}
                  </span>
                </span>

                {/* end::Description */}
              </div>

              <Collapse in={!collapsed}>
                <div className='wrapper'>
                  <div>
                    <hr className='h-25 w-50 m-auto p-3 mt-3' />
                    <div className='d-flex align-items-center '>
                      {/* begin::Description */}
                      <h1 className={`fs-7 fw-semibold  text-${color}`}>forecast</h1>
                      {/* end::Description */}
                    </div>
                    <div className='d-flex align-items-center '>
                      {/* begin::Description */}
                      <span className='fs-5 text-gray-800 fw-semibold '>
                        ETC <span className='fs-7 text-gray-600'>(Estimated to Complete)</span>
                      </span>

                      {/* end::Description */}
                    </div>
                    <div className='d-flex align-items-center'>
                      {/* begin::Description */}
                      <h6
                        className={`fs-12 align-self-end font-weight-bold text-end  text-${color}`}
                      >
                        {data?.output?.CardsData?.CostPerformance?.ETC?.value
                          ? formatFloatFromString(
                            data?.output.CardsData.CostPerformance.ETC.value,
                            data.float_formatter || 0
                          )
                          : '-'}
                      </h6>
                      {/* end::Description */}
                    </div>
                  </div>
                </div>
              </Collapse>
            </>
          )}
          {widgetType === ProjectDetailsTypes.SCHEDULE_PERFORMANCE && (
            <>
              <div className='d-flex align-items-center '>
                {/* begin::Description */}
                <span className='fs-5 text-gray-800 fw-semibold '>
                  SPI <span className='fs-7 text-gray-600'>(Schedule Performance Index)</span>
                </span>
                {/* end::Description */}
              </div>
              <div className='d-flex align-items-center'>
                {/* begin::Description */}
                <h1 className='col-6 text-gray-400 fw-semibold fs-7'>Cumulative</h1>
                <span className='d-flex align-items-center gap-3 justify-content-end fs-25 col-6 font-weight-bold text-end rotate active '>
                  {data?.output?.cpi?.cumulative?.length >= 2 && (
                    <div
                      className='d-flex rotate active opacity-50'
                      title='SPI Variance between data date and the previous date'
                      data-toggle='tooltip'
                    >
                      {spiDiff ? (
                        <KTSVG
                          className={clsx('svg-icon svg-icon-2 mb-2', {
                            'text-danger rotate-n90': spiDiff < 0,
                            'text-success rotate-90': spiDiff > 0,
                            'text-muted': !spiDiff,
                          })}
                          path='/media/icons/duotune/arrows/arr026.svg'
                        />
                      ) : (
                        <div
                          style={{ height: '1.5rem', width: '1.5rem' }}
                          className='bg-secondary mb-2 me-1'
                        />
                      )}
                      <h6
                        className={clsx({
                          'text-danger': spiDiff < 0,
                          'text-success': spiDiff >= 0,
                          'text-muted': !spiDiff,
                        })}
                      >
                        {formatNumber(spiDiff, data?.float_formatter || 0, '.')}
                      </h6>
                      <div className='d-flex flex-row gap-2 align-items-center mb-2 ms-2'>
                        <div
                          className='bg-secondary rounded'
                          style={{ height: '15px', width: '3px' }}
                        />
                      </div>
                    </div>
                  )}
                  <h6
                    className={clsx({
                      'text-danger': data?.output?.SPICum?.at(-1) < 1,
                      'text-success': data?.output?.SPICum?.at(-1) >= 1,
                    })}
                  >
                    {data?.output?.SPICum
                      ? formatNumber(
                        data?.output?.SPICum[data?.output?.SPICum?.length - 1],
                        data?.float_formatter || 0,
                        '.'
                      )
                      : '-'}
                  </h6>
                </span>
                {/* end::Description */}
              </div>
              <Collapse in={!collapsed}>
                <div className='wrapper'>
                  <div>
                    <div className='d-flex align-items-center'>
                      {/* begin::Description */}
                      <h1 className='col-6 text-gray-400 fw-semibold fs-7'>Periodic</h1>
                      <h6 className={`fs-25 col-6 font-weight-bold text-gray-800 text-end`}>
                        {isRebaseLined ? '--' : data?.output?.SPIPeriodic
                          ? formatNumber(
                            data?.output?.SPIPeriodic[data?.output?.SPIPeriodic?.length - 1],
                            data.float_formatter || 0,
                            '.'
                          )
                          : '-'}
                      </h6>
                      {/* end::Description */}
                    </div>
                    <div className='d-flex align-items-center'>
                      {/* begin::Description */}
                      <h1 className='col-6 text-gray-400 fw-semibold fs-7'>Average</h1>
                      <h6 className={`fs-25 col-6 font-weight-bold text-gray-800 text-end`}>
                        {data?.output?.SPIPeriodic
                          ? formatNumber(
                            data?.output?.SPIPeriodic?.reduce(
                              (acc: number, curr: number) => acc + curr,
                              0
                            ) / data?.output?.SPIPeriodic.length,
                            data.float_formatter || 0,
                            '.'
                          )
                          : '-'}
                      </h6>
                      {/* end::Description */}
                    </div>
                  </div>
                </div>
              </Collapse>
              <hr className='h-25 w-50 m-auto p-3 mt-3' />
              <div className='d-flex align-items-center '>
                {/* begin::Description */}
                <span className='fs-5 text-gray-800 fw-semibold '>
                  SVt <span className='fs-7 text-gray-600'>(Schedule variance -time)</span>
                </span>
                {/* end::Description */}
              </div>
              <div className='d-flex align-items-center'>
                {/* begin::Description */}
                <h1 className='col-6 text-gray-400 fw-semibold fs-7'>Cumulative</h1>
                <h6
                  className={clsx('fs-25 col-6 font-weight-bold text-${color} text-end', {
                    'text-danger': data?.output?.SVtCum?.at(-1) < 0,
                    'text-success': data?.output?.SVtCum?.at(-1) >= 0,
                  })}
                >
                  {isRebaseLined ? '--' : data?.output?.CardsData?.SchedulePerformance?.SVT?.cumulative
                    ? formatScheduleVariance(data?.output?.SVtCum?.at(-1))
                    : '-'}
                </h6>
                {/* end::Description */}
              </div>
              <Collapse in={!collapsed}>
                <div className='wrapper'>
                  <div>
                    <div className='d-flex align-items-center'>
                      {/* begin::Description */}
                      <h1 className='col-6 text-gray-400 fw-semibold fs-7'>Periodic</h1>
                      <h6 className={`fs-25 col-6 font-weight-bold text-gray-800 text-end`}>
                        {isRebaseLined ? '--' : data?.output?.CardsData?.SchedulePerformance?.SVT?.periodic
                          ? formatScheduleVariance(data?.output?.SVtPeriod?.at(-1))
                          : '-'}
                      </h6>
                      {/* end::Description */}
                    </div>
                    <div className='d-flex align-items-center'>
                      {/* begin::Description */}
                      <h1 className='col-6 text-gray-400 fw-semibold fs-7'>Average</h1>
                      <h6 className={`fs-25 col-6 font-weight-bold text-gray-800 text-end`}>
                        {data?.output?.CardsData?.SchedulePerformance?.SVT?.average
                          ? formatScheduleVariance(
                            data?.output.SVtPeriod.reduce((a: any, b: any) => +a + b, 0) /
                            data?.output?.SVtPeriod.length
                          )
                          : '-'}
                      </h6>
                      {/* end::Description */}
                    </div>
                  </div>
                </div>
              </Collapse>
              <hr className='h-25 w-50 m-auto p-3 mt-3' />
              <div className='d-flex align-items-center '>
                {/* begin::Description */}
                <h1 className={`fs-7 fw-semibold  text-${color}`}>forecast</h1>
                {/* end::Description */}
              </div>
              <div className='d-flex align-items-center '>
                {/* begin::Description */}
                <span className='fs-5 text-gray-800 fw-semibold '>
                  EED <span className='fs-7 text-gray-600'>(Estimated End Date)</span>
                </span>

                {/* end::Description */}
              </div>
              <div className='d-flex justify-content-between align-self-start'>
                {/* begin::Description */}
                <h6 className={`fs-12 align-self-start font-weight-bold text-${color}`}>
                  {data?.output?.CardsData?.SchedulePerformance?.EED?.Value
                    ? data?.output.CardsData.SchedulePerformance.EED.Value
                    : '-'}
                </h6>
                <span
                  className={`fs-12 align-self-end font-weight-bold text-end  text-${data?.output?.CardsData?.SchedulePerformance?.EED?.sign > 0
                    ? 'danger'
                    : 'success'
                    }`}
                >
                  {data?.output?.CardsData?.SchedulePerformance?.EED?.Difference
                    ? data?.output?.CardsData?.SchedulePerformance?.EED?.sign !== 0 &&
                    data?.output.CardsData.SchedulePerformance.EED.Difference
                    : '-'}
                  <br></br>
                  <span className='fs-12 align-self-end font-weight-bold text-gray-400 text-end '>
                    {data?.output?.CardsData?.SchedulePerformance?.EED?.sign > 0
                      ? 'behind schedule'
                      : data?.output?.CardsData?.SchedulePerformance?.EED?.sign === 0
                        ? 'on schedule'
                        : 'ahead of schedule'}
                  </span>
                </span>
                {/* end::Description */}
              </div>
              <Collapse in={!collapsed}>
                <div className='wrapper'>
                  <div>
                    <hr className='h-25 w-50 m-auto p-3 mt-3' />
                    <div className='d-flex align-items-center '>
                      {/* begin::Description */}
                      <h1 className={`fs-7 fw-semibold  text-${color}`}>forecast</h1>
                      {/* end::Description */}
                    </div>
                    <div className='d-flex align-items-center '>
                      {/* begin::Description */}
                      <span className='fs-5 text-gray-800 fw-semibold '>
                        ERD{' '}
                        <span className='fs-7 text-gray-600'>(Estimated Remaining Duration)</span>
                      </span>

                      {/* end::Description */}
                    </div>
                    <div className='d-flex justify-content-between align-self-start'>
                      {/* begin::Description */}
                      <h6 className={`fs-12 align-self-end font-weight-bold text-${color}`}>
                        {data?.output?.CardsData?.SchedulePerformance?.ERD
                          ? data?.output.CardsData.SchedulePerformance.ERD
                          : '-'}
                      </h6>
                      {/* end::Description */}
                    </div>
                  </div>
                </div>
              </Collapse>
            </>
          )}
        </div>
        {/* end::Items */}
      </div>
      {false && widgetType === ProjectDetailsTypes.COST_PERFORMENCE && (
        <div
          className='position-absolute bottom-0 start-50 z-index-2 d-none d-xl-inline-flex gap-2 align-items-center'
          style={{ transform: 'translateX(-50%) translateY(70%)' }}
        >
          <button
            onClick={toggleCollapse}
            className='d-none d-xl-inline-flex btn btn-icon btn-light p-0 rotate active'
          >
            <KTSVG
              path='/media/icons/duotune/arrows/arr021.svg'
              className={clsx('svg-icon-muted svg-icon', {
                'rotate-270': collapsed,
                'rotate-90': !collapsed,
              })}
            />
          </button>
        </div>
      )}
      {/* end::Body */}
    </div>
  )
}

export { ProjectWidget }
