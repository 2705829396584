import { useEffect, useRef, useState } from 'react';
import { Tree } from 'react-organizational-chart';
import HierarchyFolderItem from './HierarchyFolderItem';
import HierarchyItem from './HierarchyItem';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { KTSVG } from '../../../../../_metronic/helpers';
import { useProject } from '../../../projects/core/ProjectContext';
import { useDispatch, useSelector } from 'react-redux';
import { updatedata, addWbsToStore, clearTreeData } from '../../treedata';
import { getWbs } from '../../_requests';
import { loading, loaded } from '../../treeStates';
import { Wbs } from '../../_models';
import { RootState } from '../../../../store';


export type FolderNode = {
  label: string;
  children?: FolderNode[];
  id: string;
};

const buildFolderTree = async (folder: Wbs, knownWbs: Record<string, Wbs>, dispatch: any): Promise<FolderNode> => {
  const children = await Promise.all(
    folder.subwbs.map(async (item) => {
      let response = knownWbs[item.$oid];
      if (!response) {
        response = await getWbs(item.$oid);
        dispatch(addWbsToStore(response));
        knownWbs = { ...knownWbs, [item.$oid]: response };  // Create a new object with the new WBS data
      }
      return await buildFolderTree(response, knownWbs, dispatch);
    })
  );
  return {
    label: folder.name,
    children,
    id: folder._id.$oid,
  };
};


export default function HierarchyView() {
  const [folderTree, setFolderTree] = useState<FolderNode[]>([]);
  const [rootExpanded, setRootExpanded] = useState<boolean>(true);
  const [allExpanded, setAllExpanded] = useState(false);
  const [allCollapsed, setAllCollapsed] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { project } = useProject();
  const dispatch = useDispatch();
  const treeData = useSelector((state: RootState) => state.treedata.value);
  const knownWbs = useSelector((state: RootState) => state.treedata.knownWbs);
  const knownWbsRef = useRef(knownWbs);

  useEffect(() => {
    dispatch(clearTreeData()); // Reset treeData to empty

    const fetchTreeData = async () => {
      if (project?.current_wbs) {
        dispatch(loading());
        try {
          let data = knownWbsRef.current[project.current_wbs];
          if (!data) {
            data = await getWbs(project.current_wbs);
            dispatch(addWbsToStore(data));
            knownWbsRef.current = { ...knownWbsRef.current, [project.current_wbs]: data }; // Update the mutable reference
          }
          const tree = await buildFolderTree(data, knownWbsRef.current, dispatch);
          dispatch(updatedata(tree));
        } catch (error) {
          console.error('Failed to fetch tree data', error);
        } finally {
          dispatch(loaded());
        }
      }
    };
    fetchTreeData();
  }, [dispatch, project?.current_wbs]);


  useEffect(() => {
    if (treeData) {
      setIsLoading(false);
      setFolderTree([treeData]);
    } else {
      setIsLoading(true);
    }
  }, [treeData]);

  useEffect(() => {
    if (allExpanded) setRootExpanded(true);
    if (allCollapsed) setRootExpanded(false);
  }, [allExpanded, allCollapsed]);

  useEffect(() => {
    const resetItems = document.querySelectorAll('.backup-no-end');
    resetItems.forEach((item) => item.classList.remove('backup-no-end'));
    const liElement = document.querySelector('.no-end');
    if (liElement) {
      const ulElement = liElement.closest('ul');
      if (ulElement) {
        ulElement.classList.add('backup-no-end');
      }
    }
  }, [rootExpanded]);

  if (isLoading)
    return (
      <div className='d-flex justify-content-center'>
        <div
          className='spinner-border text-primary mt-5'
          role='status'
          style={{ width: '3rem', height: '3rem' }}
        >
          <span className='sr-only'>Loading...</span>
        </div>
      </div>
    );

  return (
    <TransformWrapper>
      {({ zoomIn, zoomOut, resetTransform, centerView }) => (
        <>
          <div className='d-flex justify-content-end mb-3 gap-2'>
            <button
              className='btn btn-light btn-icon btn-sm me-2 rotate active'
              onClick={() => {
                setAllExpanded(false)
                setAllCollapsed(true)
              }}
              type='button'
              style={{ transition: 'all 0.2s ease-in-out' }}
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr021.svg'
                className='svg-icon-muted text-hover-primary svg-icon-1 rotate-90'
              />
            </button>
            <button
              className='btn btn-light btn-icon btn-sm me-2 rotate active'
              onClick={() => {
                setAllCollapsed(false)
                setAllExpanded(true)
              }}
              type='button'
              style={{ transition: 'all 0.2s ease-in-out' }}
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr021.svg'
                className='svg-icon-muted text-hover-primary svg-icon-1 rotate-n90'
              />
            </button>
            <button onClick={() => zoomIn()} className='btn btn-sm btn-secondary'>
              <KTSVG path='/media/icons/duotune/general/gen035.svg' />
              zoom in
            </button>
            <button onClick={() => zoomOut()} className='btn btn-sm btn-secondary'>
              <KTSVG path='/media/icons/duotune/general/gen036.svg' />
              zoom out
            </button>
            <button
              onClick={() => {
                centerView()
                resetTransform()
              }}
              className='btn btn-sm btn-secondary'
            >
              <KTSVG path='/media/icons/duotune/general/gen034.svg' /> reset
            </button>
          </div>

          <TransformComponent wrapperClass='w-100' contentClass='d-block w-100'>
            {/* <div style={{width: '200px', height: '200px', backgroundColor: 'green'}}></div> */}
            <div className='d-flex justify-content-center' style={{ transform: 'scale(0.8)' }}>
              <Tree
                lineColor='var(--kt-dark)'
                lineWidth='2px'
                lineBorderRadius='10px'
                lineHeight='25px'
                label={
                  <HierarchyItem
                    resetExpanded={() => {
                      setAllExpanded(false)
                      setAllCollapsed(false)
                    }}
                    allExpanded={allExpanded}
                    allCollapsed={!allExpanded}
                    resetView={() => centerView()}
                    expanded={rootExpanded}
                    setExpanded={setRootExpanded}
                    node={{ id: 'root', label: 'ROOT', children: folderTree }}
                  />
                }
              >
                {folderTree.map((item) => (
                  <HierarchyFolderItem
                    className={rootExpanded ? '' : 'invisible no-end'}
                    allCollapsed={allCollapsed}
                    allExpanded={allExpanded}
                    resetExpanded={() => {
                      setAllExpanded(false)
                      setAllCollapsed(false)
                    }}
                    resetView={() => centerView()}
                    key={item.id}
                    node={item}
                  />
                ))}
              </Tree>
            </div>
          </TransformComponent>
        </>
      )}
    </TransformWrapper>

  )
}
