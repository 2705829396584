import {Button} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import Input from '../../../components/Input/Input'
import {axisOptions, tangentOptions} from '../../library/utils'
import ProjectNotStartedForm from './ProjectNotStarted/ProjectNotStartedForm'
import ProjectStartedForm from './ProjectStarted/ProjectStartedForm'
import {useSimulationContext} from './SimulationContext'

type Props = {}

export default function SimulationForm({}: Props) {
  const {formik, setModals} = useSimulationContext()
  const intl = useIntl()

  return (
    <div className='col-xxl-4 mb-5 mb-xl-10'>
      <form noValidate className='form'>
        <div className='card mb-5 mb-xl-10'>
          <div className='card-body p-9'>
            <h2>Simulation Data</h2>
            {formik?.values.is_start ? <ProjectStartedForm /> : <ProjectNotStartedForm />}
          </div>
        </div>
        <div className='card mb-5 mb-xl-10'>
          <div className='card-body p-9'>
            <label className='col-lg-12 d-flex justify-content-between col-form-label  fw-bold'>
              <h1 className='fs-3'>Curve settings</h1>
              <Button
                onClick={() => setModals((prev) => ({...prev, saveSettings: true}))}
                variant='secondary'
                size='sm'
                className='d-flex align-items-start fw-bold'
              >
                Save Settings
                <i className='bi bi-save ms-2'></i>
              </Button>
            </label>
            <div className='d-flex gap-5'>
              <Button
                variant='secondary'
                size='sm'
                className='d-flex align-items-start  fw-bold'
                onClick={() => setModals((prev) => ({...prev, curveSettings: true}))}
              >
                Import Profile
                <i className='bi bi-box-arrow-up-right ms-2'></i>
              </Button>
            </div>
            <Input
              labelText={intl.formatMessage({id: 'SIMULATION.TANGENT'})}
              formikProps={formik}
              name={'curve_settings.tangent'}
              nameOfSelectList='curve_settings.tangent'
              options={tangentOptions}
              formikSelectProps={formik}
              min={0.1}
              max={5}
              placeholder={``}
              type='range'
              value={formik?.values.curve_settings.tangent}
              error={formik?.errors.curve_settings?.tangent as string}
              isTouched={formik?.touched.curve_settings?.tangent as boolean}
            />
            <Input
              labelText={intl.formatMessage({id: 'SIMULATION.OFFSET'})}
              formikProps={formik}
              name={'curve_settings.offset'}
              min={-5}
              max={10}
              nameOfSelectList='curve_settings.offset'
              options={axisOptions}
              formikSelectProps={formik}
              placeholder={``}
              type='range'
              value={formik?.values.curve_settings.offset}
              error={formik?.errors.curve_settings?.offset as string}
              isTouched={formik?.touched.curve_settings?.offset as boolean}
            />

            {/* {!(typeOfCalculate === TypeOfCalculate.FIXED_END_DATE) && (
              <button
                className={`btn btn-primary btn-lg col-12 ${
                  formik?.values.period_count.count ? '' : 'disabled'
                }`}
                type='button'
                onClick={displayEstimated}
              >
                Display Estimated Chart
              </button>
            )} */}
          </div>
        </div>
      </form>
    </div>
  )
}
