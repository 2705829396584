export enum WidgetID {
  SUMMARY = 'summary',
  MILESTONES = 'milestones',
  S_CURVE = 's_curve',
  VARIANCES = 'variances',
  RESOURCES = 'resources',
  ACTION_PLAN = 'action_plan',
  DASHBOARD = 'dashboard',
}

export const mockWidgetOrder: WidgetOrder[] = Object.keys(WidgetID).map((item, index) => ({
  show: true,
  widget: WidgetID[item as keyof typeof WidgetID],
}))

export type WidgetOrder = {
  show: boolean
  widget: WidgetID
}

export const widgetOrderNames = {
  [WidgetID.SUMMARY]: {name: 'Summary', icon: '/media/icons/duotune/abstract/abs014.svg'},
  [WidgetID.MILESTONES]: {name: 'Milestones', icon: '/media/icons/duotune/abstract/abs005.svg'},
  [WidgetID.S_CURVE]: {name: 'S-Curve', icon: '/media/icons/duotune/graphs/gra011.svg'},
  [WidgetID.VARIANCES]: {name: 'Variances', icon: '/media/icons/duotune/abstract/abs046.svg'},
  [WidgetID.RESOURCES]: {name: 'Resources', icon: '/media/icons/duotune/communication/com006.svg'},
  [WidgetID.ACTION_PLAN]: {
    name: 'Action Plan / Comments',
    icon: '/media/icons/duotune/communication/com006.svg',
  },
  [WidgetID.DASHBOARD]: {name: 'Dashboard', icon: '/media/icons/duotune/abstract/abs027.svg'},
}
