import { Tree } from 'antd';
import type { DataNode, EventDataNode } from 'antd/es/tree';
import { getWbs, patchWbs } from '../../_requests';
import TreeDataWbsItem from './TreeDataWbsItem';
import { appendTreeData, deleteTreeNode, updateTreeData } from './_helpers';
import { useTree } from './TreeContext';
import SwitcherIcon from './icons/SwitcherIcon';
import DraggableIcon from './icons/DraggableIcon';
import { showError, sweetAlert } from '../../../../utils/funcs';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { addWbsToStore } from '../../../wbs/treedata';
import React, { useEffect } from 'react';
import { useProject } from '../../../projects/core/ProjectContext';
import { getVersionById } from '../../../projects/core/_requests';
import { setVersionData } from '../../../wbs/treeVersionData';
import { ProjectObject } from '../../../projects/core/_models';

type WbsType = {
  _id: { $oid: string };
  name: string;
  parent_wbs?: { $oid: string };
  subwbs: { $oid: string }[];
  rank: number;
  user: { $oid: string };
  milestones: any[];
  hierarchy: any[];
  data?: { $oid: string }
};

interface TreeNodeProps {
  wbs: WbsType
}

interface Props {
  currentStep?: number
}

export default function TreeDataView({ currentStep }: Props) {
  const { expandedKeys, setExpandedKeys, setTreeData, treeData, isLoading, setSelectedWp, setSelectedWpData, selectedWp } = useTree();
  const { project } = useProject();
  const dispatch = useDispatch()
  const knownWbs = useSelector((state: RootState) => state.treedata.knownWbs)
  const isStep5WpDataChanged = useSelector((state: RootState) => state.treedata.isStep5WpDataChanged)
  const projectversiondata = useSelector((state: RootState) => state.versiondata.data)

  const loadExpandAll = async () => {

    const allKeys: string[] = [];

    const fetchWbsRecursively = async (wbsId: string): Promise<DataNode> => {
      let wbsItem = knownWbs[wbsId];
      if (!wbsItem) {
        const response = await getWbs(wbsId);
        dispatch(addWbsToStore(response));
        wbsItem = response;
      }

      allKeys.push(wbsItem._id.$oid);

      const childrenData: DataNode[] = await Promise.all(
        wbsItem.subwbs.map(async (subWbs) => {
          return await fetchWbsRecursively(subWbs.$oid);
        })
      );

      return {
        key: wbsItem._id.$oid,
        title: <TreeDataWbsItem wbs={wbsItem} />,
        children: childrenData,
      };
    };

    if (project?.default_wbs) {
      const rootNode = await fetchWbsRecursively(project.default_wbs._id.$oid);
      setTreeData([rootNode]);
      setExpandedKeys(allKeys);
    }
  }

  const loadData = async (treeNode: EventDataNode<DataNode>) => {
    let childWbs = knownWbs[treeNode.key.toString()]
    if (!childWbs) {
      childWbs = await getWbs(treeNode.key.toString());
      dispatch(addWbsToStore(childWbs));
    }

    const childData: DataNode[] = await Promise.all(childWbs.subwbs.map(async (item) => {
      let response = knownWbs[item.$oid];
      if (!response) {
        response = await getWbs(item.$oid);
        dispatch(addWbsToStore(response));
      }

      return {
        key: response._id.$oid,
        name: response.name,
        title: <TreeDataWbsItem wbs={response} />,
        switcherIcon: response.subwbs.length ? undefined : <></>,
      }
    })
    )
    const sortedChildData = childData.slice().sort((a, b) => {
      const getRank = (node: DataNode) => {
        if (React.isValidElement(node.title) && node.title.props?.wbs?.rank !== undefined) {
          return node.title.props.wbs.rank
        }
        return Infinity; // Assign a default high rank if undefined
      };

      const rankA = getRank(a);
      const rankB = getRank(b);

      return rankA - rankB; // Ascending order
    });


    setTreeData((origin) => {
      return updateTreeData(origin, treeNode.key.toString(), sortedChildData)
    })
  };

  const findParentKey = (key: string, nodes: DataNode[]): string | null => {
    for (const node of nodes) {
      const nodeWbs = node.title && typeof node.title === 'object' && 'props' in node.title
        ? (node.title.props as { wbs: WbsType }).wbs
        : null;

      if (nodeWbs?.subwbs.some(subwbs => subwbs.$oid === key)) {
        return node.key.toString();
      }

      if (node.children) {
        const parentKey = findParentKey(key, node.children);
        if (parentKey) return parentKey;
      }
    }
    return null;
  };

  useEffect(() => {
    if (currentStep && currentStep === 5)
      loadExpandAll();
  }, [currentStep]);

  const handleClick = async (node: EventDataNode<DataNode>) => {
    let wp = knownWbs[node.key];
    if (!wp) {
      wp = await getWbs(node.key);
      dispatch(addWbsToStore(wp));
    }

    if (!wp.subwbs || wp.subwbs.length === 0) {
      let wpData: any
      if (wp.data) {
        wpData = projectversiondata[wp.data.$oid];
        if (!wpData)
          wpData = await getVersionById(wp.data.$oid);
        dispatch(setVersionData({ id: wpData._id.$oid, data: wpData }));
      }
      if (isStep5WpDataChanged && selectedWp) {
        sweetAlert({
          title: 'Data will be lost',
          text: 'Are you sure you want to leave?',
          icon: 'warning',
          confirmButtonText: 'Yes',
          showCancelButton: true,
        }).then((res) => {
          if (res.isConfirmed) {
            setSelectedWp(wp);
            setSelectedWpData(wpData);
          }
        })
      } else {
        setSelectedWp(wp);
        setSelectedWpData(wpData);
      }
    }
  };



  if (isLoading || !treeData.length)
    return (
      <div className='d-flex justify-content-center'>
        <div
          className='spinner-border text-primary mt-5'
          role='status'
          style={{ width: '3rem', height: '3rem' }}
        >
          <span className='sr-only'>Loading...</span>
        </div>
      </div>
    );

  return (
    <>
      <div className='card mt-5 py-2 px-5'>
        <Tree
          style={{ overflowX: 'scroll' }}
          selectable={false}
          switcherIcon={SwitcherIcon}
          onExpand={(expandedKeys, { expanded, node }) =>
            setExpandedKeys((prev) =>
              !expanded ? prev.filter((item) => item !== node.key) : [...prev, node.key.toString()]
            )
          }
          onClick={(event, node) => handleClick(node)}
          expandedKeys={expandedKeys}
          loadData={loadData}
          treeData={treeData}
        />
      </div>
    </>
  );
}
