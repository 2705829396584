import { DataNode } from 'antd/es/tree';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { KTSVG } from '../../../../../_metronic/helpers';
import { showError, sweetAlert } from '../../../../utils/funcs';
import { Wbs } from '../../_models';
import { deleteWbs, getWbs, getWbss, patchWbs } from '../../_requests';
import { WbsDropdown } from './WbsDropdown';
import { useTree } from './TreeContext';
import TreeCreateWbsItem from './TreeCreateWbsItem';
import { appendTreeData, deleteTreeNode, findNodeById } from './_helpers';
import { useProject } from '../../../projects/core/ProjectContext';
import { isntrename } from '../../../wbs/treeStates';
import { useDispatch, useSelector } from 'react-redux';
import { addWbsToStore } from '../../../wbs/treedata';
import { RootState } from '../../../../store';

type Props = {
  wbs: Wbs;
};

export default function TreeWbsItem({ wbs: data }: Props) {
  const { setExpandedKeys, setTreeData, treeData } = useTree();
  const { project } = useProject();
  const [hovered, setHovered] = useState<boolean>(false);
  const [isRenaming, setIsRenaming] = useState(false);
  const inputRef = useRef(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [upDisabled, setUpDisabled] = useState<boolean>(false);
  const [downDisabled, setDownDisabled] = useState<boolean>(false);
  const [missing, setMissing] = useState<Boolean>(false);
  const [wbs, setWbs] = useState<Wbs>(data);
  const knownWbs = useSelector((state: RootState) => state.treedata.knownWbs);
  const dispatch = useDispatch();

  useEffect(() => {
    if (knownWbs[wbs?._id?.$oid]) {
      setWbs(knownWbs[wbs._id.$oid]);
    }
  }, [knownWbs]);

  const formik = useFormik({
    initialValues: { name: wbs.name },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required('Name is required')
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols'),
    }),
    onSubmit: (values) => {
      setLoading(true);
      patchWbs(wbs._id.$oid, values)
        .then((res) => {
          setTreeData((prev) =>
            appendTreeData(
              deleteTreeNode(prev, wbs._id.$oid),
              wbs.parent_wbs?.$oid || 'root',
              [{ key: res._id.$oid, title: <TreeWbsItem wbs={res} /> }]
            )
          );
          dispatch(addWbsToStore(res));
        })
        .catch(showError)
        .finally(() => {
          setLoading(false);
          setIsRenaming(false);
          dispatch(isntrename());
        });
    },
  });

  const handleNewWbs = async () => {
    if (findNodeById(treeData, 'new_child' + (wbs?._id?.$oid))) return;
    if (
      wbs.subwbs.length &&
      !findNodeById(treeData, wbs.subwbs[0]._id?.$oid || (wbs.subwbs[0] as any).$oid)
    ) {
      let res = knownWbs[wbs._id?.$oid];
      if (!res) {
        res = await getWbs(wbs._id?.$oid);
        dispatch(addWbsToStore(res));
      }

      const subWbsData = await Promise.all(
        res.subwbs.map(async (item: { $oid: string }) => {
          let subWbsItem = knownWbs[item.$oid];
          if (!subWbsItem) {
            subWbsItem = await getWbs(item.$oid);
            dispatch(addWbsToStore(subWbsItem));
          }

          return {
            key: subWbsItem._id.$oid,
            title: <TreeWbsItem wbs={subWbsItem} />,
          };
        })
      );

      setTreeData((prev) =>
        appendTreeData(prev, wbs._id?.$oid, subWbsData)
      );
    }

    setExpandedKeys((prev) => {
      const found = prev.find((item) => item === wbs?._id?.$oid);
      if (found) return prev;
      return [...prev, wbs._id?.$oid];
    });
    setTreeData((prev) => {
      const filteredOrigin = deleteTreeNode(prev, 'new wbs');
      return appendTreeData(filteredOrigin, wbs?._id?.$oid || 'root', [
        {
          key: 'new_child' + (wbs?._id?.$oid || 'root'),
          title: <TreeCreateWbsItem parentId={wbs?._id?.$oid || 'root'} />,
          switcherIcon: <></>,
        },
      ]);
    });
  };

  const handleDeleteWbs = async () => {
    sweetAlert({
      title: 'Delete WBS',
      text: 'Are you sure you want to delete this WBS?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteWbs(wbs._id.$oid)
          .then(async () => {
            if (wbs.parent_wbs?.$oid) {
              let parent_wbs = knownWbs[wbs.parent_wbs?.$oid];
              if (!parent_wbs) {
                parent_wbs = await getWbs(wbs.parent_wbs?.$oid);
                dispatch(addWbsToStore(parent_wbs));
              }
              const patched_wbs = await patchWbs(wbs.parent_wbs?.$oid, {
                subwbs: parent_wbs.subwbs
                  .filter((item: { $oid: string }) => item.$oid !== wbs._id.$oid)
                  .map((item: { $oid: string }) => item.$oid),
              });

              dispatch(addWbsToStore(patched_wbs));
            }
            setTreeData((prev) => deleteTreeNode(prev, wbs._id.$oid));
          })
          .catch(showError);
      }
    });
  };

  const handleReorder = (rank: -1 | 1) => {
    setLoading(true);
    patchWbs(wbs._id.$oid, { rank })
      .then(async () => {
        const wbss = await getWbss();
        const rootWbss: Wbs[] = wbss.filter(
          (item) => item.parent_wbs?.$oid === project?.default_wbs?._id?.$oid
        );

        const getChildren = (wbs: Wbs): DataNode => {
          if (wbs.subwbs.length === 0)
            return {
              key: wbs._id?.$oid ? wbs._id.$oid : project?.default_wbs?._id?.$oid,
              title: <TreeWbsItem wbs={wbs} />,
              children: [],
            };
          return {
            key: wbs._id?.$oid ? wbs._id.$oid : project?.default_wbs?._id?.$oid,
            title: <TreeWbsItem wbs={wbs} />,
            children: wbss
              .filter((item) => item.parent_wbs?.$oid === (wbs._id?.$oid ? wbs._id.$oid : project?.default_wbs?._id?.$oid))
              .map((item) => getChildren(item)),
          };
        };

        setTreeData([
          {
            key: project?.default_wbs?._id?.$oid,
            title: treeData[0].title,
            children: rootWbss.map(getChildren),
          },
        ]);
        setLoading(false);
      })
      .catch((err) => {
        showError(err);
        setLoading(false);
      });
  };

  const isReactElement = (
    element: any
  ): element is React.ReactElement => {
    return element !== null && typeof element === 'object' && 'props' in element
  }

  useEffect(() => {
    if (!wbs._id) return;
    const foundNode = findNodeById(
      treeData,
      wbs.parent_wbs?.$oid ? wbs.parent_wbs.$oid : project?.default_wbs?._id?.$oid
    );

    const ranks =
      foundNode?.children
        ?.filter((item) => typeof item.key === 'string' && !item.key.startsWith('new_child'))
        ?.map((item) => {
          if (isReactElement(item.title)) {
            return item.title.props.wbs.rank;
          }
          return undefined;
        })
        .filter((rank) => rank !== undefined) || [];

    const maxRank = Math.max(...ranks);
    const minRank = Math.min(...ranks);

    if (wbs.rank === undefined) return;

    setUpDisabled(wbs.rank === minRank);
    setDownDisabled(wbs.rank === maxRank);
  }, [treeData, wbs._id, wbs.rank]);

  useEffect(() => {
    if (!wbs?.subwbs || wbs?.subwbs?.length === 0) {
      if (wbs?.data) {
        setMissing(false);
      } else {
        setMissing(true);
      }
    }
  }, [wbs]);

  return (
    <WbsDropdown
      wbs={wbs}
      formik={formik}
      setRenaming={() => {
        setIsRenaming(true);
        const node = inputRef.current;
        if (node) (node as any).focus();
      }}
    >
      <div
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        className="d-inline-flex justify-content-start align-items-center"
      >
        <KTSVG
          className={`svg-icon-2 me-2 ${wbs?.subwbs?.length > 0
              ? 'svg-icon-primary'
              : missing
                ? 'svg-icon-warning'
                : 'svg-icon-success'
            }`}
          path={
            wbs?.subwbs?.length > 0
              ? '/media/icons/duotune/graphs/git04.svg'
              : '/media/icons/duotune/files/fil003.svg'
          }
        />

        {isRenaming ? (
          <>
            <input
              ref={inputRef}
              type="text"
              placeholder="New Wbs"
              className="form-control form-control-flush ps-0"
              {...formik.getFieldProps('name')}
            />
            {/* Confirm Rename */}
            <button
              onClick={(e) => {
                e.stopPropagation();
                formik.handleSubmit();
              }}
              type="button"
              style={{ transition: 'all 0.2s ease-in-out' }}
              className={clsx('btn btn-icon btn-sm me-2')}
            >
              {loading ? (
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              ) : (
                <KTSVG
                  path="/media/icons/duotune/arrows/arr012.svg"
                  className="svg-icon-dark text-hover-success svg-icon-3"
                />
              )}
            </button>

            {/* Cancel Rename */}
            <button
              onClick={(e) => {
                e.stopPropagation();
                setIsRenaming(false);
              }}
              type="button"
              style={{ transition: 'all 0.2s ease-in-out' }}
              className={clsx('btn btn-icon btn-sm')}
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr011.svg"
                className="svg-icon-dark text-hover-danger svg-icon-3"
              />
            </button>
          </>
        ) : (
          <span className="fw-bold me-2">{wbs.name}</span>
        )}

        {!isRenaming && (
          <>
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleNewWbs();
              }}
              type="button"
              style={{ transition: 'all 0.2s ease-in-out' }}
              className={clsx('btn btn-icon btn-sm', {
                'opacity-0': !hovered,
              })}
            >
              <KTSVG
                path="/media/icons/duotune/files/fil013.svg"
                className="svg-icon-muted text-hover-primary svg-icon-3"
              />
            </button>
            {!!wbs._id?.$oid && (
              <>
                <button
                  disabled={loading || upDisabled}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleReorder(1);
                  }}
                  id="up_wbs"
                  type="button"
                  style={{ transition: 'all 0.2s ease-in-out' }}
                  className={clsx('btn btn-icon btn-sm', {
                    'opacity-0': !hovered,
                  })}
                >
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr003.svg"
                    className="svg-icon-muted text-hover-primary svg-icon-3"
                  />
                </button>
                <button
                  disabled={loading || downDisabled}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleReorder(-1);
                  }}
                  id="down_wbs"
                  type="button"
                  style={{ transition: 'all 0.2s ease-in-out' }}
                  className={clsx('btn btn-icon btn-sm', {
                    'opacity-0': !hovered,
                  })}
                >
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr004.svg"
                    className="svg-icon-muted text-hover-primary svg-icon-3"
                  />
                </button>
                {/* Rename Button */}
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsRenaming(true);
                  }}
                  type="button"
                  style={{ transition: 'all 0.2s ease-in-out' }}
                  className={clsx('btn btn-icon btn-sm', {
                    'opacity-0': !hovered,
                  })}
                >
                  <i className="bi bi-pencil-fill fs-7 text-hover-primary"></i>
                </button>

                {/* Delete Button */}
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteWbs();
                  }}
                  type="button"
                  style={{ transition: 'all 0.2s ease-in-out' }}
                  className={clsx('btn btn-icon btn-sm', {
                    'opacity-0': !hovered,
                  })}
                >
                  <KTSVG
                    path="/media/icons/duotune/general/gen027.svg"
                    className="svg-icon-muted text-hover-primary svg-icon-3"
                  />
                </button>
              </>
            )}
          </>
        )}
      </div>
    </WbsDropdown>
  );
}

