import { Outlet, useParams, useNavigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { ProjectShareDetailsHeader } from './components/pagination/header/ProjectShareDetailsHeader'
import { useProjectShareDetailsById } from './core/_queries'
import { useAuth } from '../auth'
import { useProject } from './core/ProjectContext'
import { useState } from 'react'
import { Error405 } from '../errors/components/Error405'
import { PasswordPage } from './PasswordPage'
import { getProtectedShareProjectDetailsById } from './core/_requests'
import { Overview } from './components/overview/Overview'
import { useDispatch } from 'react-redux'
import { setVersionData } from '../wbs/treeVersionData'
import { addWbsToStore } from '../wbs/treedata'
import ShowLoadingModal from './components/pagination/header/ShowLoadingModal'
import { TreeProvider } from '../wbs/components/TreeView/SnapshotShareTreeContext'


const projectDetailsBreadCrumbs: Array<PageLink> = [
  {
    title: 'Project',
    path: '/projects/overview',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ProjectSharePage = () => {

  const dispatch = useDispatch()
  const {
    setProject,
    setOriginalVersion,
    displayVersion,
    project,
    loading: dataLoading,
  } = useProject()
  const { id } = useParams()
  const [showPasswordPage, setShowPasswordPage] = useState<boolean>(false)

  const { areActionsDisabled } = useAuth()
  const [error405, setError405] = useState<boolean>(false)
  const { isLoading } = useProjectShareDetailsById(
    id || '',
    (newData) => {
      console.log({ newData })
      //find the period where the share version is present
      setProject({ ...newData, current_wbs: newData?.share_wbs?.$oid, widget_order: newData.share_widget_order })
      setOriginalVersion(newData.share_version)
      if (newData.generated_wbs_data && newData.generated_wbs_data.length > 0)
        newData.generated_wbs_data.map((item: any) => {
          dispatch(setVersionData({ id: item._id.$oid, data: item }));
        })

      if (newData.generated_wbs_list && newData.generated_wbs_list.length > 0)
        newData.generated_wbs_list.map((item: any) => {
          dispatch(addWbsToStore(item));
        })
    },
    undefined,
    (err: any) => {
      // Navigate to error page if the error is 405
      if (err?.response?.status === 405) {
        setError405(true)
      }
      else if (err?.response?.status === 400) {
        setProject(err?.response)
        setShowPasswordPage(true)

      }
    }
  )


  const onSubmitPassword = (password: string) => {
    if (id) {
      return getProtectedShareProjectDetailsById(id, password)
        .then((newData: any) => {
          setProject({ ...newData, current_wbs: newData?.share_wbs?.$oid, widget_order: newData.share_widget_order })
          setOriginalVersion(newData.share_version)
          setShowPasswordPage(false)
        })

    }
  }



  if (error405) return <Error405 />
  if (showPasswordPage) return <PasswordPage onSubmitPassword={onSubmitPassword} />


  if (isLoading || !displayVersion || !project)
    return (
      <div className='d-flex justify-content-center'>

      </div>
    )
  return (
    <TreeProvider>
      <ShowLoadingModal />
      <ProjectShareDetailsHeader share_type='live'/>
      <fieldset disabled={areActionsDisabled}>
        {dataLoading ? (
          <div className='d-flex justify-content-center'>

          </div>
        ) : (
          <Outlet />
        )}
      </fieldset>
      <PageTitle breadcrumbs={projectDetailsBreadCrumbs}>Overview</PageTitle>
      <Overview isShared={true} share_type='live'/>
    </TreeProvider>
  )
}

export default ProjectSharePage
