import {Dispatch, SetStateAction, createContext, useContext, useState} from 'react'
import {WithChildren} from '../../../_metronic/helpers'
import {FC} from 'react'
import {io} from 'socket.io-client'
import {useAuth} from '../auth'
import {ToastContainer, cssTransition, toast} from 'react-toastify'
import NotificationInner from './NotificationInner'
import 'animate.css/animate.min.css'
import {NotificationEvent} from './_models'
import {ThemeModeComponent} from '../../../_metronic/assets/ts/layout'

type NotificationContextProps = {
  notifications: NotificationEvent[]
  setNotifications: Dispatch<SetStateAction<NotificationEvent[]>>
}

const NotificationContext = createContext<NotificationContextProps>({
  notifications: [],
  setNotifications: () => {},
})

const useNotification = () => useContext(NotificationContext)

const bounce = cssTransition({
  enter: 'animate__animated animate__fadeInDown animate__faster',
  exit: 'animate__animated animate__fadeOutUp animate__faster',
})

const NotificationProvider: FC<WithChildren> = ({children}) => {
  const {auth, currentUser} = useAuth()
  const URL = process.env.REACT_APP_API_URL
  const socket = io(URL || '', {
    extraHeaders: {
      Authorization: `Bearer ${auth?.api_token}`,
    },
  })
  const [notifications, setNotifications] = useState(currentUser?.socket?.all_events || [])

  const triggerNotification = (data: NotificationEvent) => {
    toast(<NotificationInner event={data} />)
  }

  socket.on('connect', () => console.log('connected'))
  socket.on('events', (data: NotificationEvent) => {
    triggerNotification(data)
    if (data.all_events) setNotifications(data.all_events)
  })
  socket.on('events_missed', (data: any) => console.log('missed', data))
  socket.on('disconnect', () => console.log('disconnected'))

  return (
    <NotificationContext.Provider value={{notifications, setNotifications}}>
      <ToastContainer
        hideProgressBar
        transition={bounce}
        autoClose={false}
        theme={
          ThemeModeComponent.getMode() === 'system'
            ? 'dark'
            : (ThemeModeComponent.getMode() as 'light' | 'dark')
        }
      />
      {children}
    </NotificationContext.Provider>
  )
}

export {useNotification, NotificationProvider}
