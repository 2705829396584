import ApexCharts from 'apexcharts'
import clsx from 'clsx'
import _ from 'lodash'
import moment from 'moment'
import {useEffect, useRef, useState} from 'react'
import {getCSS, getCSSVariableValue} from '../../../../../_metronic/assets/ts/_utils'
import {useThemeMode} from '../../../../../_metronic/partials'
import {getFirstDayOfMonth} from '../../../../helpers/func'
import {getFormattedDate} from '../../../../utils/funcs'
import CustomLegends from '../../../projects/components/overview/CustomLegends'
import {CustomCurve} from '../../core/_models'
import {useSimulationContext} from '../SimulationContext'
import {
  RECORD_TO_IGNORE,
  getChartOptions,
  getNotStartedResourceChartOptions,
  getStartedResourceChartOptions,
} from './_helpers'
import {ChartCustomCurve} from './_models'
import styles from './chart.module.scss'

type Props = {}

const startedIds = [
  {id: 'Real FTE', dashed: false, line: false},
  {id: 'Average Real FTE', dashed: false, line: true},
  {id: 'Forecast FTE', dashed: true, line: false},
  {id: 'Average Forecast FTE', dashed: true, line: true},
]

const notStartedIds = [
  {id: 'FTE', dashed: false, line: false},
  {id: 'Average FTE', dashed: false, line: true},
]

export default function SimulationChart({}: Props) {
  const chartRef = useRef(null)
  const {formik, outputs, chartUnit, setModals} = useSimulationContext()
  const {mode} = useThemeMode()
  const [showCumulative, setShowCumulative] = useState<boolean>(true)
  const [showPeriodic, setShowPeriodic] = useState<boolean>(true)
  const [fteLegends, setFteLegends] = useState(Array(formik?.values.is_start ? 4 : 2).fill(true))
  const [chart, setChart] = useState<ApexCharts | undefined>(undefined)
  const type = formik?.values.period_count.type === 'monthly' ? 'months' : 'days'
  const [customCurve, setCustomCurve] = useState<ChartCustomCurve[]>(
    formik?.values.custom_curve.map((item: CustomCurve) => ({
      ...item,
      show: true,
      id: _.uniqueId(),
    })) || []
  )

  useEffect(
    () => setFteLegends(Array(formik?.values.is_start ? 4 : 2).fill(true)),
    [formik?.values.is_start]
  )

  const toggleFteSeries = (index: number) => {
    if (index + 1 <= fteLegends.length) {
      const ids = formik?.values.is_start ? startedIds : notStartedIds
      return setFteLegends((prev) => {
        const intermediate = [...prev]
        intermediate[index] = !intermediate[index]
        if (intermediate[index]) chart?.showSeries(ids[index].id)
        else chart?.hideSeries(ids[index].id)
        return intermediate
      })
    }

    if (index + 1 > fteLegends.length + customCurve.length) {
      const item = customCurve[index - (fteLegends.length + customCurve.length)]
      return setCustomCurve((oldCurves: ChartCustomCurve[]) => {
        let intermediate = [...oldCurves]
        let selectedCurveIndex = intermediate.findIndex((curve) => curve.id === item.id)
        let selectedCurve = intermediate[selectedCurveIndex]
        intermediate[selectedCurveIndex] = {
          ...selectedCurve,
          showAverage: !selectedCurve.showAverage,
        }
        return intermediate
      })
    }

    if (index + 1 > fteLegends.length) {
      const item = customCurve[index - fteLegends.length]
      return setCustomCurve((oldCurves: ChartCustomCurve[]) => {
        let intermediate = [...oldCurves]
        let selectedCurveIndex = intermediate.findIndex((curve) => curve.id === item.id)
        let selectedCurve = intermediate[selectedCurveIndex]
        intermediate[selectedCurveIndex] = {
          ...selectedCurve,
          show: !selectedCurve.show,
        }
        return intermediate
      })
    }
  }

  const getData = () => {
    const firstDayOfCurrentMonth = getFirstDayOfMonth(new Date())
    let labels, dataset1, dataset2
    if (!formik?.values.is_start) {
      if (outputs.isInitialState) {
        labels = Array.from({length: 17}).map(
          (_el, idx) => `${type === 'days' ? 'Day' : 'Month'} ${idx + 1}`
        )
        dataset1 = []
        dataset2 = []
      } else {
        labels = Array.from({length: outputs?.tableData[1]?.length})?.map(
          (_el, idx) => `${type === 'days' ? 'Day' : 'Month'} ${idx + 1}`
        )

        dataset1 = outputs.tableData[1]
        dataset2 = outputs.tableData[2]
      }
    } else {
      if (outputs.isInitialState) {
        labels = Array.from({length: 17})
          .map((_el, idx) =>
            moment(firstDayOfCurrentMonth)
              .add(idx - 8, 'months')
              .format('MMM YYYY')
          )
          .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
        dataset1 = []
        dataset2 = []
      } else {
        if (outputs?.tableData?.length > 0) {
          const locale = localStorage.getItem('i18nextLng') || 'en'
          labels = outputs?.tableData[0]
            .map((el: any) =>
              el === '–'
                ? el
                : moment(el)
                    .locale(locale)
                    .format(`${type === 'days' ? 'D-' : ''}MMM-YYYY`)
            )
            .map((str: string) => str.charAt(0).toUpperCase() + str.slice(1))
          dataset1 = outputs?.tableData[1]
          dataset2 = outputs?.tableData[2]
        }
      }
    }

    return {dataset1, dataset2, labels}
  }

  const refreshMode = (dataset: any[], dataset2: any[], labels: any[]) => {
    if (!chartRef.current) {
      return
    }
    const height = parseInt(getCSS(chartRef.current, 'height'))
    const options =
      chartUnit.suffix === 'FTE'
        ? formik?.values.is_start
          ? getStartedResourceChartOptions(
              height,
              chartUnit,
              showCumulative ? dataset : [],
              showPeriodic ? dataset2 : [],
              labels,
              customCurve,
              outputs.highlightIdx
            )
          : getNotStartedResourceChartOptions(
              height,
              chartUnit,
              showCumulative ? dataset : [],
              showPeriodic ? dataset2 : [],
              labels,
              customCurve,
              outputs.highlightIdx
            )
        : getChartOptions(
            height,
            chartUnit,
            showCumulative ? dataset : [],
            showPeriodic ? dataset2 : [],
            labels,
            customCurve,
            outputs.highlightIdx
          )
    const chart = new ApexCharts(chartRef.current, options)
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    if (!outputs.tableData) return
    const {dataset1, dataset2, labels} = getData()
    const chart = refreshMode(dataset1, dataset2, labels)
    setChart(chart)
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, outputs, chartUnit, showCumulative, showPeriodic, customCurve])

  useEffect(() => {
    setCustomCurve((prev) => {
      let newCustomCurve: ChartCustomCurve[] = []
      formik?.values.custom_curve.forEach((curve: CustomCurve) => {
        const foundItem = prev.find(
          (item: ChartCustomCurve) => curve.name === item.name && curve.color === item.color
        )
        newCustomCurve.push({
          ...curve,
          id: foundItem?.id || _.uniqueId(),
          show: foundItem?.show || true,
          showAverage: foundItem?.showAverage || true,
        })
      })
      return newCustomCurve
    })
  }, [formik?.values])

  return (
    <div className='card'>
      {/* begin::Body */}
      <div className='row justify-content-md-end my-5 mx-5'>
        <div className='col-2'>
          <i
            onClick={() => setModals((prev) => ({...prev, totalPrice: true}))}
            className='bi bi-gear d-flex text-hover-primary justify-content-end cursor-pointer'
            style={{fontSize: '2rem', transition: 'all 0.2s ease-in-out'}}
          ></i>
        </div>
      </div>
      <div className='card-body'>
        {/* Custom Legend */}
        {chartUnit.suffix !== 'FTE' ? (
          <div className='d-flex justify-content-center gap-5 flex-wrap'>
            <div
              onClick={() => setShowPeriodic((prev) => !prev)}
              className='d-flex flex-row gap-2 align-items-center cursor-pointer'
            >
              <div
                style={{height: '15px', width: '15px', backgroundColor: '#14D193'}}
                className='rounded-circle'
              />
              <span className={clsx('fs-6', {'text-muted': !showPeriodic})}>Periodic Progress</span>
            </div>
            <div
              onClick={() => setShowCumulative((prev) => !prev)}
              className='d-flex flex-row gap-2 align-items-center cursor-pointer'
            >
              <div
                style={{
                  height: '15px',
                  width: '15px',
                  backgroundColor: '#0B69FF',
                }}
                className='rounded-circle'
              />
              <span className={clsx('fs-6', {'text-muted': !showCumulative})}>
                Cumulative Progress
              </span>
            </div>
            {customCurve
              ?.filter((curve: ChartCustomCurve) => curve.name !== RECORD_TO_IGNORE)
              .map((item: ChartCustomCurve) => (
                <div
                  key={item.id}
                  onClick={() =>
                    setCustomCurve((oldCurves: ChartCustomCurve[]) => {
                      let intermediate = [...oldCurves]
                      let selectedCurveIndex = intermediate.findIndex(
                        (curve) => curve.id === item.id
                      )
                      let selectedCurve = intermediate[selectedCurveIndex]
                      intermediate[selectedCurveIndex] = {
                        ...selectedCurve,
                        show: !selectedCurve.show,
                      }
                      return intermediate
                    })
                  }
                  className='d-flex flex-row gap-2 align-items-center cursor-pointer'
                >
                  <div
                    style={{height: '15px', width: '15px', backgroundColor: item.color}}
                    className='rounded-circle'
                  />
                  <span className={clsx('fs-6', {'text-muted': !item.show})}>{item.name}</span>
                </div>
              ))}
          </div>
        ) : !!formik?.values.is_start ? (
          <CustomLegends
            colors={[
              getCSSVariableValue('--kt-primary'),
              getCSSVariableValue('--kt-primary'),
              getCSSVariableValue('--kt-info'),
              getCSSVariableValue('--kt-info'),
              ...customCurve.map((curve: ChartCustomCurve) => curve.color),
              ...customCurve.map((curve: ChartCustomCurve) => curve.color),
            ]}
            labelsStyles={[
              ...startedIds,
              ...customCurve.map((item) => ({id: item.name, dashed: false, line: false})),
              ...customCurve.map((item) => ({id: item.name, dashed: false, line: true})),
            ]}
            legendLabels={[
              'Real',
              'Average Real',
              'Forecast',
              'Average Forecast',
              ...customCurve.map((curve: ChartCustomCurve) => curve.name),
              ...customCurve.map((curve: ChartCustomCurve) => curve.name + ' Average'),
            ]}
            legends={[
              ...fteLegends,
              ...customCurve
                .filter((item) => item.name !== RECORD_TO_IGNORE)
                .map((curve: ChartCustomCurve) => curve.show),
              ...customCurve
                .filter((item) => item.name !== RECORD_TO_IGNORE)
                .map((curve: ChartCustomCurve) => curve.showAverage),
            ]}
            toggleLegend={toggleFteSeries}
          />
        ) : (
          <CustomLegends
            colors={[
              getCSSVariableValue('--kt-primary'),
              getCSSVariableValue('--kt-primary'),
              ...customCurve.map((curve: ChartCustomCurve) => curve.color),
              ...customCurve.map((curve: ChartCustomCurve) => curve.color),
            ]}
            labelsStyles={[
              ...notStartedIds,
              ...customCurve.map((item) => ({id: item.name, dashed: false, line: false})),
              ...customCurve.map((item) => ({
                id: item.name + ' Average',
                dashed: false,
                line: true,
              })),
            ]}
            legendLabels={[
              'FTE',
              'Average FTE',
              ...customCurve.map((curve) => curve.name),
              ...customCurve.map((curve) => curve.name + ' Average'),
            ]}
            legends={[
              ...fteLegends,
              ...customCurve
                .filter((item) => item.name !== RECORD_TO_IGNORE)
                .map((curve: ChartCustomCurve) => curve.show),
              ...customCurve
                .filter((item) => item.name !== RECORD_TO_IGNORE)
                .map((curve: ChartCustomCurve) => curve.showAverage),
            ]}
            toggleLegend={toggleFteSeries}
          />
        )}
        <div className='d-flex flex-row w-100 justify-content-center'></div>
        {/* begin::Chart */}
        <div ref={chartRef} id='kt_charts_widget_3_chart' style={{height: '400px'}}></div>
        {/* end::Chart */}
        <div className={`${styles.helper} d-flex flex-row w-100 justify-content-between ala`}>
          <div>
            {formik?.values.is_start && (
              <div className='d-flex gap-5'>
                <p className={`${styles.resultCard} `}>
                  {'Number of remaining'}{' '}
                  {formik.values.period_count.type === 'daily' ? ' Days' : ' Months '}
                  <br />
                  <div className={styles.resultNumber}>
                    {outputs.nbOfRemainingMonths
                      ? `${outputs.nbOfRemainingMonths} 
                    
                        
                       `
                      : '-'}
                  </div>
                  {/* // formik.values.period_count.type === 'daily' ? 'days' : 'months' } */}
                </p>

                <p className={`${styles.resultCard}`}>
                  {'Estimated end date'}
                  <br />
                  <div className={styles.resultNumber}>
                    {outputs?.estimatedEndDate
                      ? getFormattedDate(
                          moment(outputs.estimatedEndDate).toDate().getTime(),
                          'dd/mm/yyyy'
                        )
                      : '-'}
                  </div>
                </p>
              </div>
            )}
          </div>
          <div className='d-flex flex-column'>
            <button
              type='button'
              className='btn btn-lg btn-primary'
              data-kt-stepper-action='next'
              onClick={() => setModals((prev) => ({...prev, customCurve: true}))}
            >
              Manage Custom Curves
            </button>
          </div>
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}
