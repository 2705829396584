import TableSection from '../../../components/Table/TableSection'
import {CustomCurve} from '../core/_models'
import SimulationChartContainer from './Chart/SimulationChartContainer'
import {useSimulationContext} from './SimulationContext'
import SimulationForm from './SimulationForm'
import SimulationHeader from './header/SimulationHeader'
import SaveSettingsModal from './modals/SaveSettingsModal'
import AddCustomCurveModal from './newModals/AddCustomCurveModal'
import CurveSettingsModal from './newModals/CurveSettingsModal'
import CustomCurveModal from './newModals/CustomCurveModal'
import TotalPriceModal from './newModals/TotalPriceModal'
import UpdateCustomCurveModal from './newModals/UpdateCustomCurve'

type Props = {}

export default function Simulation({}: Props) {
  const {loading, chartUnit, outputs, formik, modals, setModals, selectedCurve, setSelectedCurve} =
    useSimulationContext()

  return (
    <>
      {loading ? (
        <div className='d-flex justify-content-center'>
          <div
            className='spinner-border text-primary mt-5'
            role='status'
            style={{width: '3rem', height: '3rem'}}
          >
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <SimulationHeader />
          <div className='row'>
            <SimulationForm />
            <SimulationChartContainer />
          </div>
          {!!outputs && (
            <div className='card-body py-3'>
              <TableSection
                chartUnit={chartUnit}
                outputs={outputs}
                isForSigmoid={!formik?.values?.is_start}
                timeUnit={formik?.values.period_count.type === 'monthly' ? 'months' : 'days'}
              />
            </div>
          )}
        </>
      )}
      <CurveSettingsModal
        formik={formik}
        show={modals.curveSettings}
        onHide={() => setModals((prev) => ({...prev, curveSettings: !prev.curveSettings}))}
      />
      <SaveSettingsModal
        tangent={formik?.values.curve_settings?.tangent || 0}
        offset={formik?.values.curve_settings?.offset || 0}
        show={modals.saveSettings}
        onHide={() => setModals((prev) => ({...prev, saveSettings: !prev.saveSettings}))}
      />
      <CustomCurveModal
        setShowAdd={() => setModals((prev) => ({...prev, addCustomCurve: true}))}
        show={modals.customCurve}
        onHide={() => setModals((prev) => ({...prev, customCurve: false}))}
        selected={selectedCurve}
        selectCustomCurve={setSelectedCurve}
        updateCurve={(curve: CustomCurve) =>
          setModals((prev) => ({...prev, updateCustomCurve: curve}))
        }
      />
      <AddCustomCurveModal
        sigmoid={!formik?.values.is_start}
        timeUnit={formik?.values.period_count.type === 'monthly' ? 'months' : 'days'}
        outputs={outputs}
        showParent={() => setModals((prev) => ({...prev, customCurve: !prev.customCurve}))}
        show={modals.addCustomCurve}
        onHide={() => setModals((prev) => ({...prev, addCustomCurve: false}))}
        selectCustomCurve={setSelectedCurve}
      />
      <UpdateCustomCurveModal
        sigmoid={!formik?.values.is_start}
        timeUnit={formik?.values.period_count.type === 'monthly' ? 'months' : 'days'}
        outputs={outputs}
        showParent={() => setModals((prev) => ({...prev, customCurve: !prev.customCurve}))}
        show={modals.updateCustomCurve}
        onHide={() => setModals((prev) => ({...prev, updateCustomCurve: undefined}))}
      />
      <TotalPriceModal
        closeModal={() => setModals((prev) => ({...prev, totalPrice: false}))}
        data={formik?.values}
        show={modals.totalPrice}
      />
    </>
  )
}
