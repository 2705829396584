import React from 'react'

interface PlanProps {
  header: string;
  price: number;
  trial_type: string;
  features: string[];
  not_available_features: string[];
  outline: boolean;
  buttonLabel: string;
  buttonAction: (header: string, trial_type: string) => void;
}

const Plan: React.FC<PlanProps> = ({
  header,
  price,
  trial_type,
  features,
  not_available_features,
  outline,
  buttonLabel,
  buttonAction,
}) => {
  return (
    <div className="card mb-4 shadow-sm zoomDiv">
      <div className="card-header justify-content-center" style={{ paddingTop: '2em' }}>
        <h4 className="my-0 font-weight-normal">{header}</h4>
      </div>
      <div className="card-body">
        <h1 className="card-title pricing-card-title">
          {`€${price}`}
          <small className="text-muted"> / Month </small>
        </h1>
        <small className='text-muted'>{`or €${price*10} / Year`}</small>
        <ul className="list-unstyled mt-3 mb-4">
          {features.map((feature, i) => (
            <li key={i} style={{ textAlign: 'left' }}>
              <i className="fa-solid fa-check" style={{ color: 'green' }}></i>
              <span> {feature}</span>
            </li>
          ))}
          {not_available_features.map((feature, i) => (
            <li key={i} style={{ textAlign: 'left' }}>
              <i className="fa-solid fa-xmark" style={{ color: 'red' }}></i>
              <span style={{ opacity: 0.5 }}> {feature}</span>
            </li>
          ))}
        </ul>
        <button
          className={`btn btn-lg btn-block ${outline ? 'btn-outline-primary' : 'btn-primary'}`}
          type="button"
          onClick={() => buttonAction(header, trial_type)}
        >
          {buttonLabel}
        </button>
      </div>
    </div>
  )
}

export default Plan