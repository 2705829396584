import { useEffect, useRef, useState } from 'react'
import { useProject } from '../../../core/ProjectContext'
import { getResourceOptions } from './_helpers'
import ApexCharts from 'apexcharts'
import { Collapse } from 'react-bootstrap'
import { KTSVG } from '../../../../../../_metronic/helpers'
import clsx from 'clsx'
import FTEModal from '../modals/FTEModal'
import EmptyValuesCard from '../../../../../components/emptyValuesCard'
import { useThemeMode } from '../../../../../../_metronic/partials'
import CustomLegends from '../CustomLegends'
import { getResourceColors, resourceLabels, resourceLegendLabels } from './_models'
import { useLang } from '../../../../../../_metronic/i18n/Metronici18n'

type Props = {
  isShared?: boolean;
}

export default function ResourceChart({ isShared }: Props) {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const chartInstance = useRef<ApexCharts | null>(null)
  const [legends, setLegends] = useState<boolean[]>(Array(resourceLabels.length).fill(true))
  const [expanded, setExpanded] = useState<boolean>(
    localStorage.getItem('resourcesExpanded') !== 'false'
  )
  const [showFteSettings, setShowFteSettings] = useState<boolean>(false)
  const { displayVersion } = useProject()
  const { mode } = useThemeMode()
  const lang = useLang()

  const toggleExpanded = () => {
    setExpanded((prev) => {
      localStorage.setItem('resourcesExpanded', (!prev).toString())
      return !prev
    })
  }

  useEffect(() => {
    const initChart = async () => {
      // Clean up existing chart
      if (chartInstance.current) {
        chartInstance.current.destroy()
        chartInstance.current = null
      }

      // Only create new chart if we have data and the element exists
      if (displayVersion?.full_capacity &&
        displayVersion?.total_hours > 0 &&
        chartRef.current) {
        try {
          // Get chart options and ensure they're properly structured
          const options = getResourceOptions(displayVersion, lang)

          // Debug: Log the options to check their structure
          console.log('Chart options:', JSON.parse(JSON.stringify(options)))

          // Create a fresh copy of the options to prevent any object extensibility issues
          const sanitizedOptions = JSON.parse(JSON.stringify(options))

          // Ensure series data is an array
          if (!Array.isArray(sanitizedOptions.series)) {
            sanitizedOptions.series = []
          }

          // Initialize chart with sanitized options
          chartInstance.current = new ApexCharts(chartRef.current, sanitizedOptions)

          // Wait for chart to render
          await chartInstance.current.render()

          // Debug: Log success
          console.log('Chart rendered successfully')
        } catch (error) {
          console.error('Error details:', {
            error,
            options: getResourceOptions(displayVersion, lang),
            displayVersion,
            chartRef: chartRef.current
          })
        }
      }
    }

    initChart()

    return () => {
      if (chartInstance.current) {
        try {
          chartInstance.current.destroy()
        } catch (error) {
          console.error('Error destroying chart:', error)
        }
        chartInstance.current = null
      }
    }
  }, [displayVersion, lang, mode])

  const toggleSeries = (index: number) => {
    if (!chartInstance.current) return

    setLegends((prev) => {
      const newLegends = [...prev]
      newLegends[index] = !newLegends[index]

      try {
        if (!newLegends[index]) {
          chartInstance.current?.hideSeries(resourceLabels[index].id)
        } else {
          chartInstance.current?.showSeries(resourceLabels[index].id)
        }
      } catch (error) {
        console.error('Error toggling series:', error)
      }

      return newLegends
    })
  }

  // Rest of the component remains the same...
  return (
    <>
      {(displayVersion?.full_capacity && displayVersion?.total_hours > 0 || !isShared) && (
        <>
          <div className='my-3 px-5 align-items-center d-flex justify-content-between'>
            <div className='d-inline-flex gap-2'>
              <span>
                <button
                  onClick={toggleExpanded}
                  className='btn btn-light p-2 d-inline-flex align-items-center justify-content-center rotate active z-index-2'
                >
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr021.svg'
                    className={clsx('svg-icon-muted svg-icon svg-icon-sm me-0', {
                      'rotate-270': !expanded,
                      'rotate-90': expanded,
                    })}
                  />
                </button>
              </span>
              <span className='card-label fw-bold fs-3 mb-1'>Resources</span>
            </div>
            <div className='d-flex align-items-center gap-2'>
              {!isShared && (
                <i
                  onClick={() => setShowFteSettings(true)}
                  className='bi bi-gear d-flex text-hover-primary justify-content-end cursor-pointer ms-2'
                  onMouseOver={(e) => (e.currentTarget.style.opacity = '0.75')}
                  onMouseOut={(e) => (e.currentTarget.style.opacity = '1')}
                  style={{ fontSize: '2rem', transition: 'all 0.2s ease-in-out' }}
                />
              )}
              <FTEModal onHide={() => setShowFteSettings(false)} show={showFteSettings} />
            </div>
          </div>
          <Collapse in={expanded}>
            <div>
              {displayVersion?.full_capacity && displayVersion?.total_hours ? (
                <>
                  <div ref={chartRef}></div>
                  <CustomLegends
                    colors={
                      displayVersion.colors?.resource_colors?.length
                        ? getResourceColors(displayVersion.colors.resource_colors)
                        : [
                          '#008FFB',
                          '#008FFB',
                          '#FEB019',
                          '#FEB019',
                          '#775DD0',
                          '#775DD0',
                          '#00E396',
                          '#00E396',
                        ]
                    }
                    labelsStyles={resourceLabels}
                    legendLabels={resourceLegendLabels}
                    legends={legends}
                    toggleLegend={toggleSeries}
                  />
                  <div className='w-100 pb-5' />
                </>
              ) : (
                <EmptyValuesCard
                  text='Fill FTE Data First'
                  title='Incomplete Data'
                  buttonText='Complete Data'
                  setShowCreateAppModal={() => setShowFteSettings(true)}
                  className='py-5'
                />
              )}
            </div>
          </Collapse>
        </>
      )}
    </>
  )
}