/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../helpers'

type Props = {
  className: string
  image: string
  title: string
  time: string
  description: string
  projectName?: string
  projectId?: string
}

const StatisticsWidget1: React.FC<Props> = ({
  className,
  image,
  title,
  time,
  description,
  projectName,
  projectId,
}) => {
  return (
    <div
      className={`card bgi-no-repeat ${className}`}
      style={{
        backgroundPosition: 'right top',
        backgroundSize: '30% auto',
        backgroundImage: `url(${toAbsoluteUrl('/media/svg/shapes/' + image)})`,
      }}
    >
      {/* begin::Body */}
      <div className='card-body'>
        <Link
          to={'/project/' + projectId + '/action-plan'}
          className='card-title fw-bold text-muted text-hover-primary fs-4'
        >
          {title}
        </Link>

        <div className='fw-bold text-gray-600 my-6'>{projectName}</div>
        <div className='fw-bold text-primary my-6'>{time}</div>
        <p
          className='text-dark-75 fw-semibold fs-5 m-0'
          dangerouslySetInnerHTML={{__html: description}}
        ></p>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {StatisticsWidget1}
