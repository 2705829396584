import {FormikProps} from 'formik'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {WidgetOrder, widgetOrderNames} from './_models'

type Props = {
  item: WidgetOrder
  formik: FormikProps<{widgetOrder: WidgetOrder[]}>
}

export default function WidgetItem({item, formik}: Props) {
  const handleMoveUp = () => {
    const index = formik.values.widgetOrder.findIndex((i) => i.widget === item.widget)
    if (index === 0) return
    let temp = formik.values.widgetOrder[index - 1]
    const tempArray = formik.values.widgetOrder
    tempArray[index - 1] = item
    tempArray[index] = temp
    formik.setFieldValue('widgetOrder', tempArray)
  }

  const handleMoveDown = () => {
    const index = formik.values.widgetOrder.findIndex((i) => i.widget === item.widget)
    if (index === formik.values.widgetOrder.length - 1) return
    let temp = formik.values.widgetOrder[index + 1]
    const tempArray = formik.values.widgetOrder
    tempArray[index + 1] = item
    tempArray[index] = temp
    formik.setFieldValue('widgetOrder', tempArray)
  }

  const handleToggleShow = () => {
    const newArray = formik.values.widgetOrder.map((i) =>
      i.widget === item.widget ? {...i, show: !i.show} : i
    )
    formik.setFieldValue('widgetOrder', newArray)
  }
  return (
    <div className='d-flex align-items-center py-1 px-2 rounded bg-hover-light-primary'>
      {!!widgetOrderNames[item.widget]?.icon && (
        <KTSVG path={widgetOrderNames[item.widget].icon} className='me-3 svg-icon-1' />
      )}
      <input
        className='form-check-input'
        type='checkbox'
        checked={item.show}
        id='flexCheckDefault'
        onChange={handleToggleShow}
      />
      <button
        disabled={formik.values.widgetOrder[0].widget === item.widget}
        id='up_folder'
        type='button'
        style={{transition: 'all 0.2s ease-in-out'}}
        className='btn btn-icon btn-sm'
        onClick={handleMoveUp}
      >
        <KTSVG
          path='/media/icons/duotune/arrows/arr003.svg'
          className='svg-icon-muted text-hover-primary svg-icon-3'
        />
      </button>
      <button
        disabled={formik.values.widgetOrder.at(-1)?.widget === item.widget}
        id='down_folder'
        type='button'
        style={{transition: 'all 0.2s ease-in-out'}}
        className='btn btn-icon btn-sm'
        onClick={handleMoveDown}
      >
        <KTSVG
          path='/media/icons/duotune/arrows/arr004.svg'
          className='svg-icon-muted text-hover-primary svg-icon-3'
        />
      </button>
      <span className='fs-5 fw-bold'>{widgetOrderNames[item.widget]?.name}</span>
    </div>
  )
}
