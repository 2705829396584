import dayjs from 'dayjs'
import {patchVersion} from '../../core/_requests'
import {Milestone} from './_models'

const getMilestoneBadgeColor = (achieved: string) => (achieved==="achieved" ? 'badge-success' : 'badge-warning')
const prepareMilestoneDates = (milestones: any[], dataDate?: Date | number) => {
  return milestones.map((item:any) => {
    let foundItem;
    if (dataDate) {
      // Check if real_dates is an array and find the item
      if (Array.isArray(item.real_dates)) {
        foundItem = item.real_dates.find((realDateItem:any) => dayjs(realDateItem.data_date).isSame(dataDate, 'month'));
      } else {
        // Handle case when real_dates is not an array
        foundItem = dayjs(item.real_dates?.data_date).isSame(dataDate, 'month') ? item.real_dates : undefined;
      }
    }

    return {
      ...item,
      baseline_date: new Date((item.baseline_date as any).$date),
      real_date: foundItem && foundItem.real_date ? new Date(foundItem.real_date) : undefined,
      achieved: foundItem?.achieved,
      hidden: foundItem?.hidden,
    }
  });
};


const prepareMilestoneForDisplay = (milestones: Milestone[], dataDate?: Date | number) => {
  return milestones.map((item: any) => {
    let foundItem;
    if (dataDate) {
      // Check if real_dates is an array and find the item
      if (Array.isArray(item.real_dates)) {
        foundItem = item.real_dates.find((realDateItem: any) => dayjs(realDateItem.data_date).isSame(dataDate, 'month'));
      } else {
        // Handle case when real_dates is not an array
        foundItem = dayjs(item.real_dates?.data_date).isSame(dataDate, 'month') ? item.real_dates : undefined;
      }
    }

    let anteriorItem;
    let posteriorItem;
    let itemToReplace;

    if (foundItem) {
      itemToReplace = {
        baseline_date: new Date((item.baseline_date as any).$date),
        real_date: foundItem.real_date ? new Date(foundItem.real_date) : undefined,
        achieved: foundItem.achieved,
        hidden: foundItem.hidden,
      };
    }

    if (!foundItem) {
      if (Array.isArray(item.real_dates)) {
        anteriorItem = item.real_dates
          .filter((realDateItem: any) => dayjs(dataDate).isAfter(realDateItem.data_date))
          .sort((a: { data_date: string | number | Date }, b: { data_date: string | number | Date }) => new Date(a.data_date).getTime() - new Date(b.data_date).getTime())[0];
      } else {
        anteriorItem = dayjs(dataDate).isAfter(item.real_dates?.data_date) ? item.real_dates : undefined;
      }
    }

    if (anteriorItem) {
      if (anteriorItem.achieved || (!anteriorItem.achieved && dayjs(anteriorItem.real_date).isAfter(dataDate, 'month'))) {
        itemToReplace = {
          baseline_date: new Date((item.baseline_date as any).$date),
          real_date: anteriorItem.real_date ? new Date(anteriorItem.real_date) : undefined,
          achieved: anteriorItem.achieved,
          hidden: false,
        };
      } else {
        itemToReplace = {
          baseline_date: new Date((item.baseline_date as any).$date),
          real_date: dayjs(dataDate).add(1, 'M').toDate(),
          hidden: false,
        };
      }
    }

    if (!foundItem && !anteriorItem) {
      if (Array.isArray(item.real_dates)) {
        posteriorItem = item.real_dates
          .filter((realDateItem: any) => dayjs(dataDate).isBefore(realDateItem.data_date))
          .sort((a: { data_date: string | number | Date }, b: { data_date: string | number | Date }) => new Date(a.data_date).getTime() - new Date(b.data_date).getTime())[0];
      } else {
        posteriorItem = dayjs(dataDate).isBefore(item.real_dates?.data_date) ? item.real_dates : undefined;
      }
    }

    if (posteriorItem) {
      if (posteriorItem.achieved && dayjs(posteriorItem.real_date).isAfter(dataDate)) {
        itemToReplace = {
          baseline_date: new Date((item.baseline_date as any).$date),
          real_date: posteriorItem.real_date ? new Date(posteriorItem.real_date) : undefined,
          achieved: false,
          hidden: false,
        };
      } else {
        itemToReplace = {
          baseline_date: new Date((item.baseline_date as any).$date),
          real_date: posteriorItem.real_date ? new Date(posteriorItem.real_date) : undefined,
          achieved: posteriorItem.achieved,
          hidden: false,
        };
      }
    }

    return {
      ...item,
      baseline_date: new Date((item.baseline_date as any).$date),
      real_date: itemToReplace && itemToReplace.real_date ? new Date(itemToReplace.real_date) : undefined,
      achieved: itemToReplace?.achieved,
      hidden: itemToReplace?.hidden || false,
    };
  });
};


const handleDeleteMilestone = (milestones: Milestone[], milestoneId: string, versionId: string) =>
  patchVersion(
    {
      milestones: prepareMilestoneDates(
        milestones.filter((milestone) => milestone._id.$oid !== milestoneId)
      ),
    },
    versionId
  )

const handleEditMilestone = (milestones: Milestone[], milestone: Milestone, versionId: string) =>
  patchVersion(
    {
      milestones: prepareMilestoneDates(milestones)
        .map((item:any) => (item.id === milestone._id.$oid ? milestone : item))
        .map((item:any) => ({...item, real_date: undefined})),
    },
    versionId
  )

const createMilestoneObject = (values: Milestone, dataDate: Date) => ({
  ...values,
  real_date: undefined,
  real_dates: [
    {
      data_date: dataDate,
      real_date: dayjs(values.baseline_date).isAfter(dataDate)
        ? values.baseline_date
        : dayjs(dataDate).add(1, 'M'),
      achieved: false,
    },
  ],
})

// const createEditMilestoneObject = (
//   milestone: Milestone,
//   dataDate: Date | number,
//   values: CreateMilestone
// ): Milestone => {
//   const newRealDates = milestone.real_dates.filter(
//     (item:any) => !dayjs(dataDate).isSame(item.data_date, 'month')
//   )

//   const editedMilestoneObject = {
//     ...values,
//     real_date: undefined,
//     real_dates: [
//       ...newRealDates,
//       {
//         data_date: new Date(dataDate),
//         real_date: values.real_dates,
//         achieved: values.achieved,
//         hidden: values.hidden,
//       },
//     ],
//   }
//   delete editedMilestoneObject.hidden
//    return editedMilestoneObject
// }

export {
  prepareMilestoneDates,
  prepareMilestoneForDisplay,
  getMilestoneBadgeColor,
  handleDeleteMilestone,
  handleEditMilestone,
  createMilestoneObject,
  // createEditMilestoneObject,
}
