import moment from 'moment'
import {format} from 'date-fns'
import {findSym} from '../helpers/func'
import {sweetAlert} from './funcs'
import {calculateCumulativeFromPeriodic} from '../helpers/optimizers'
import {Calendar} from '../modules/calendars/_models'
import {getForecast} from './forecast'


export type DateFormatType = 'mm/dd/yyyy' | 'dd/mm/yyyy'
export type BugetUnitType = 'billion' | 'million' | 'thousand' | 'unit'

export interface IBudget {
  amount: number
  currency: string
  unit: BugetUnitType
}

const amountFirstLetters = {
  billion: 'B',
  million: 'M',
  thousand: 'K',
  unit: '',
}

// <amount><unit> <code>,  e.g.: 1K USD
export const getFormattedBudget = (budgetObj: IBudget) => {
  const {amount, currency, unit} = budgetObj

  const amountFirstLetter = amountFirstLetters[unit as keyof typeof amountFirstLetters]

  return `${amount} ${amountFirstLetter}${findSym(currency)}`
}
export const getFormattedUnit = (unit: string) => {
  return amountFirstLetters[unit as keyof typeof amountFirstLetters]
}

export const getFormattedDate = (
  dateTimestamp: number,
  dateFormat: DateFormatType = 'dd/mm/yyyy'
) => format(new Date(dateTimestamp), dateFormat === 'mm/dd/yyyy' ? 'MM/dd/yyyy' : 'dd/MM/yyyy')

export const getAverageOfSlicedTable = (Tabs: any, nbrUnite: number) => {
  return (
    Tabs.slice(0, nbrUnite).reduce(
      (partialSum: any, a: any) => !isNaN(Number(a)) && partialSum + Number(a),
      0
    ) / nbrUnite
  )
}

export const getDiffrentBettwenDate = (endDate: number, startDate: number, unit: string) => {
  const date1 = moment(startDate)
  const date2 = moment(endDate)
  switch (unit) {
    case 'monthly':
      return date2.diff(date1, 'months')
    case 'daily':
      return Math.round(date2.diff(date1, 'days', true))
    case 'yearly':
      return date2.diff(date1, 'years')
    default:
      return 0
  }
}

export const sameMonthAndYear = (dataDate : number | Date,latestRebaseLineDate : number | Date) : boolean=>{
  const d1 = new Date(dataDate);
  const d2 = new Date(latestRebaseLineDate);
  return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth();
}


// ===================== function to calculate all the values for a project and returns it in an object =====================
export const getProjectEED = (
  data: any,
  calendar?: Calendar,
  sector?: string,
) => {
  return new Promise(async (resolve, reject) => {
    type DurationUnitType = 'year' | 'month' | 'day'



    // ===================== get formated date based on duration unit  =====================

    const getFormattedDateFromMomentObj = (m: any, durationUnit: DurationUnitType) =>
      m.format(durationUnit === 'day' ? 'D MMM YYYY' : 'MMM YYYY')

    // =====================  end  =====================

    // ===================== Get the data tables from the API =====================
    const cumulativePlannedValues: number[] = Object.values(data.custom_curve[0].values)
    const cumulativeEarnedValues: number[] = Object.values(data.custom_curve[1].values)
    const cumulativeActualCosts: number[] = Object.values(data.custom_curve[2].values)

    // =====================  end  =====================

    // ===================== caclulate the periodic values table from cumulative values =====================
    const getPeriodicFromCumulativeValues = (value: any, index: any, cumulativeValues: any) => {
      if (index === 0) return value
      return value - cumulativeValues[index - 1]
    }

    // =====================  end  =====================

    // ===================== caclulate the periodic table for PV =====================


    // =====================  end  =====================

    // ===================== caclulate the periodic table for EV =====================
    const periodicEarnedValues = cumulativeEarnedValues.map(
      (value, index, cumulativeEarnedValues) =>
        getPeriodicFromCumulativeValues(value, index, cumulativeEarnedValues)
    )

    // =====================  end  =====================

    // ===================== caclulate the periodic table for AC =====================
    const periodicActualCosts = cumulativeActualCosts.map((value, index, cumulativeActualCosts) =>
      getPeriodicFromCumulativeValues(value, index, cumulativeActualCosts)
    )

    // =====================  end  =====================

    // ===================== function to convert the tables from cost to percentages =====================
    const convertCostsToPercentages = (costs: any, budgetAtCompletion: any) =>
      costs.map((cost: any) => (cost / budgetAtCompletion) * 100)

    // =====================  end  =====================

    // ===================== function to convert the tables from percentages to cost =====================
    const convertPercentagesToCosts = (percentages: any, budgetAtCompletion: any) =>
      percentages.map((percentage: any) => (percentage / 100) * budgetAtCompletion)

    const removePercent = (pourcentages: any) => pourcentages.map((item: any) => item.split('%')[0])
    // =====================  end  =====================

    // ===================== convert the cumulative EV table from cost to percentages =====================
    const cumulativeEarnedValuesAsPercentages = convertCostsToPercentages(
      cumulativeEarnedValues,
      data.budget_at_completion.amount
    )

    // =====================  end  =====================

    // ===================== convert the cumulative AC table from cost to percentages =====================
    /* eslint-disable @typescript-eslint/no-unused-vars */

    // check if passed date units is < 2
    const projectLength = getDiffrentBettwenDate(
      data.data_date.$date,
      data.start_date.$date,
      data.period_count.type
    )

    // =====================  end  =====================

    //!!todo ===================== needs to double check this function for optimization   =====================

    // =====================  end  =====================

    // ===================== Format timestamp to ISO string and slice up to end index =====================

    const getFomattedDate = (timestamp: number, endISOIndex: number = 10) =>
      new Date(timestamp).toISOString().slice(0, endISOIndex)

    // =====================  end  =====================

    //===================== Calculate the difference between two timestamps in specified duration unit =====================

    const getDiffBetweenTwoDates = (
      startTimestamp: number,
      endTimestamp: number,
      durationUnit: DurationUnitType
    ) =>
      Math.round(
        moment(new Date(endTimestamp)).diff(new Date(startTimestamp), `${durationUnit}s`, true)
      )

    // =====================  end  =====================

    // ===================== Extract and format input data from provided data object =====================

    const getInputs = (data: any) => {
      const inputs: any = {
        projectDurationUnit: null,
        inputDataDate: getFomattedDate(data.data_date.$date),
        enteredEndDate: null,
        donePercentage:
          cumulativeEarnedValuesAsPercentages[cumulativeEarnedValuesAsPercentages.length - 1],
        isCalculatingRemainingTodo: false,
        nbOfTimeUnitPassed: null,
        mu: null,
        sig: null,
      }

      switch (data.period_count.type) {
        case 'yearly':
        case 'monthly':
          inputs.projectDurationUnit = 'month'
          inputs.enteredEndDate = getFomattedDate(data.end_date.$date, 7)
          break
        default:
          inputs.projectDurationUnit = 'day'
          inputs.enteredEndDate = getFomattedDate(data.end_date.$date)
      }

      inputs.nbOfTimeUnitPassed = getDiffBetweenTwoDates(
        data.start_date.$date,
        data.data_date.$date,
        inputs.projectDurationUnit
      )

      return inputs
    }
    let inputs = getInputs(data)

    // =====================  calculate Earned Schedule  =====================

    /* calculate earned schedule takes the periodic planned values and the periodic
    earned values and and the nbOfTimeUnitPassed returns the earned schedule it looks
    for each index inferior to nbOfTimeUnitPassed and if earned value is equal to planned
    value it returns the index else it still search pvi as pvi<EV<PVI+1 and returns the index of PVI+1
    */

    // my own version of ES
    function calculateEarnedSchedule(plannedValues: any, earnedValues: any, dataDate: any) {
      const numMonths = plannedValues.length

      // Special case: dataDate is 0
      if (dataDate === 0) {
        return +(earnedValues[0] / plannedValues[0]).toString()
      }

      const foundIndex = plannedValues.findIndex((item: any) => item === earnedValues[dataDate])
      if (foundIndex !== -1) return foundIndex + 1

      // Special case: on schedule
      let index = 0
      if (earnedValues[dataDate]?.toFixed(2) === plannedValues[dataDate]?.toFixed(2))
        return +dataDate + 1
      else {
        //look for pvi and pvi+1
        for (let pviIndex = 0; pviIndex < numMonths; pviIndex++) {
          if (
            plannedValues[pviIndex] <= earnedValues[dataDate] &&
            earnedValues[dataDate] < plannedValues[pviIndex + 1]
          ) {
            index = pviIndex
            break
          }
        }
        if (plannedValues[index + 1] === plannedValues[index]) return index

        return (
          index +
          1 +
          (earnedValues[dataDate] - plannedValues[index]) /
            (plannedValues[index + 1] - plannedValues[index])
        )
      }
    }

    // =====================  end  =====================

    // =====================  generate a table of earned schedule for each month  =====================

    const EarnedScheduleTable = (
      cumulativePlannedValues: any,
      cumulativeEarnedValues: any,
      nbOfTimeUnitPassed: any
    ) => {
      let ES = []
      for (let i = 0; i < nbOfTimeUnitPassed; i++) {
        ES.push(calculateEarnedSchedule(cumulativePlannedValues, cumulativeEarnedValues, i))
      }
      return ES
    }
    const Estable = EarnedScheduleTable(
      cumulativePlannedValues,
      cumulativeEarnedValues,
      inputs.nbOfTimeUnitPassed + 1
    )

    const calculateSPIt = (Estable: any) => {
      let SPIt = []
      for (let i = 0; i < Estable.length; i++) {
        SPIt.push((Estable[i] < 0 ? 1 : Estable[i]) / (i + 1))
      }
      return SPIt
    }

    const SPIt = calculateSPIt(Estable)

    let EarnedValue = await getForecast(
      inputs,
      data,
      cumulativeEarnedValuesAsPercentages,
      SPIt,
      Estable,
      calendar,
      sector
    )

    const earnedValuesForcasts = {
      cumulative: convertPercentagesToCosts(
        removePercent(EarnedValue.tableData[1]),
        data.budget_at_completion.amount
      ),
      periodic: convertPercentagesToCosts(
        removePercent(EarnedValue.tableData[2]),
        data.budget_at_completion.amount
      ),
    }

    // =====================  end  =====================

    // ===================== get the future part of the forecast for EV  =====================

    const getFutureForcastsValues = (el: number, index: number, isPeriodic: boolean = false) => {
      return index < inputs.nbOfTimeUnitPassed
        ? null
        : index === inputs.nbOfTimeUnitPassed
        ? isPeriodic
          ? periodicEarnedValues[index]
          : cumulativeEarnedValues[index]
        : +el.toString()
    }

    const earnedValuesFutureForcasts = {
      cumulative: earnedValuesForcasts.cumulative.map((el: number, index: number) =>
        getFutureForcastsValues(el, index, false)
      ),
      periodic: earnedValuesForcasts.periodic.map((el: number, index: number) =>
        getFutureForcastsValues(el, index, true)
      ),
    }

    // =====================  end  =====================

    // ===================== get the mixed values  for EV (original + Forecast)  =====================

    const getMixedValues = (el: number | string, index: number, values: any[]) => {
      if (index <= inputs.nbOfTimeUnitPassed) {
        const val = values[index]

        return (typeof val === 'undefined' || val === null || (typeof val === 'string' && val === '')) ? '' : +val.toString()
      }

      return +(el as number).toString()
    }

    const earnedValuesMixed = {
      cumulative: earnedValuesForcasts.cumulative.map((el: number | string, index: number) =>
        getMixedValues(el, index, cumulativeEarnedValues)
      ),
      periodic: earnedValuesForcasts.periodic.map((el: number | string, index: number) =>
        getMixedValues(el, index, periodicEarnedValues)
      ),
    }

    earnedValuesMixed.periodic = earnedValuesMixed.cumulative.map((element: any, index: number) =>
      Math.max(getPeriodicFromCumulativeValues(element, index, earnedValuesMixed.cumulative), 0)
    )

    earnedValuesFutureForcasts.periodic = earnedValuesFutureForcasts.cumulative.map(
      (element: any, index: number) => (!element ? element : earnedValuesMixed.periodic[index])
    )

    // =====================  end  =====================

    // =====================  Datelabels object  =====================

    const dataDateLabel = getFormattedDateFromMomentObj(
      moment(data.data_date.$date),
      inputs.projectDurationUnit
    )

    // =====================  end  =====================

    // =====================  get the object for delay tolerance start and end date =====================



    // =====================  end  =====================

    // =====================  get the object for budget reserve =====================


    // =====================  end  =====================

    // =====================  calculate periodic and culative CPI  =====================
    const calculateCpi = (earnedValues: any, actualCosts: any, floatFormater: number) => {
      const CpiTable: number[] = []

      const minLength = Math.min(earnedValues.length, actualCosts.length)
      for (let i = 0; i < minLength; i++) {
        let cpi = earnedValues[i] / actualCosts[i]
        if (!earnedValues[i] && !actualCosts[i]) cpi = 1
        CpiTable.push(cpi)
      }
      return CpiTable
    }

    const cumulativeCpi = calculateCpi(
      cumulativeEarnedValues,
      cumulativeActualCosts,
      data.float_formatter
    )

    // =====================  end  =====================

    // ===================== SPI =======================

    const calculateSpiCum = () => {
      return cumulativeEarnedValues.map((item: any, index) => {
        if (!item) return 0
        return (
          item /
          ((cumulativePlannedValues as number[])[index] ||
            (cumulativePlannedValues.at(-1) as number))
        )
      })
    }


    const SPICum = calculateSpiCum()

    // =====================  end  =====================

    // =====================  calculate EAC  =====================

    const calculateBuecEac = (cpi: number[], bac: number) => {
      const actualBuec = data.forecast_settings?.bottom_up_ec?.find(
        (item: any) => item.label === dataDateLabel
      )
      if (!actualBuec) return undefined
      const eac = cumulativeActualCosts.at(-1) + actualBuec.value

      return Array(cpi.length).fill(eac)
    }

    const calculateBacEvEa = (cpi: number[], bac: number) => {
      return cpi.map(
        (item, index) => +cumulativeActualCosts[index] + (bac - +cumulativeEarnedValues[index])
      )
    }

    const calculateBacEvSpi = (cpi: number[], bac: number) => {
      return cpi.map(
        (item, index) =>
          +cumulativeActualCosts[index] +
          (bac - +cumulativeEarnedValues[index]) / (cpi[cpi.length - 1] * SPICum[SPICum.length - 1])
      )
    }

    const calculateForcedBac = (cpi: number[], bac: number) => {
      return cpi.map((item, index) => bac)
    }

    const calculateCustomEac = (cpi: number[], bac: number) => {
      return Array(cpi.length).fill(data.forecast_settings?.custom_eac)
    }

    const calculateEac = (cpi: number[], bac: number, floatFormater: number) => {
      if (data.forecast_settings?.eac_formula === 1)
        return calculateBuecEac(cpi, bac) || cpi.map((value) => (bac / value).toString())

      if (data.forecast_settings?.eac_formula === 2) return calculateBacEvEa(cpi, bac)

      if (data.forecast_settings?.eac_formula === 3) return calculateBacEvSpi(cpi, bac)

      if (data.forecast_settings?.eac_formula === 4) {
        if ((cumulativeActualCosts.at(-1) || 0) >= bac) {
          sweetAlert({
            icon: 'error',
            title: 'Error',
            text: 'Actual cost is bigger than budget at completion, please review the forecast method.\n Falling back to BAC/CPI',
          })
          return cpi.map((value) => (bac / value).toString())
        }
        return calculateForcedBac(cpi, bac)
      }

      if (data.forecast_settings?.eac_formula === 5 && data.forecast_settings?.custom_eac) {
        if ((cumulativeActualCosts.at(-1) || 0) >= data.forecast_settings?.custom_eac) {
          sweetAlert({
            icon: 'error',
            title: 'Error',
            text: 'Actual cost is bigger than custom EAC, please review the forecast method.\n Falling back to BAC/CPI',
          })
          return cpi.map((value) => (bac / value).toString())
        }
        return calculateCustomEac(cpi, bac) || cpi.map((value) => (bac / value).toString())
      }

      return cpi.map((value) => (bac / value).toString())
    }

    const EAC = calculateEac(cumulativeCpi, data.budget_at_completion.amount, data.float_formatter)

    // =====================  end  =====================

    // ===================== get the future part of the forecast for EAC =====================

    const tcpi =
      (data.budget_at_completion.amount -
        cumulativeEarnedValues[cumulativeEarnedValues.length - 1]) /
      (EAC.at(-1) - cumulativeActualCosts[cumulativeActualCosts.length - 1])

    const acutalCostFutureForecast: {cumulative: any; periodic: any} = {
      cumulative: undefined,
      periodic: earnedValuesFutureForcasts.periodic.map((el: any, index: number) =>
        el !== null && el !== undefined && index === inputs.nbOfTimeUnitPassed
          ? periodicActualCosts.at(inputs.nbOfTimeUnitPassed)
          : el !== null && el !== undefined && index !== inputs.nbOfTimeUnitPassed
          ? +el / tcpi
          : null
      ),
    }

    const actualCostMixed: {cumulative: any; periodic: any} = {
      cumulative: undefined,
      periodic: acutalCostFutureForecast.periodic.map((el: number | string, index: number) =>
        getMixedValues(+el, index, periodicActualCosts)
      ),
    }

    actualCostMixed.cumulative = calculateCumulativeFromPeriodic(actualCostMixed.periodic)

    acutalCostFutureForecast.cumulative = acutalCostFutureForecast.periodic.map(
      (item: any, index: number) =>
        item !== null && item !== undefined ? actualCostMixed.cumulative[index] : null
    )

    // =====================  end  =====================


    // =====================  Calculate EED  =====================

    const EED = {
            Value: projectLength >= 0 && getFormattedDateFromMomentObj(
              moment(data.start_date.$date)
                .add(
                  EarnedValue.tableData[1].length - 1,
                  data.period_count.type === 'monthly' ? 'month' : 'days'
                ),
              data.period_count.type === 'daily' ? 'day' : 'month'
            ),
        }



    // =====================  end  =====================

    resolve({
      EED: EED,
    })
  })
}
