import { DataNode } from 'antd/es/tree';
import { Dispatch, FC, SetStateAction, createContext, useContext, useEffect, useState } from 'react';
import { WithChildren } from '../../../../../_metronic/helpers';
import { useProject } from '../../../projects/core/ProjectContext';
import { getWbs } from '../../_requests';
import { useDispatch, useSelector } from 'react-redux';
import { addWbsToStore } from '../../treedata';
import { RootState } from '../../../../store';
import ChartTreeFolderItem from './ChartTreeFolderItem';

type TreeViewProps = {
  treeData: DataNode[];
  setTreeData: Dispatch<SetStateAction<DataNode[]>>;
  expandedKeys: string[];
  setExpandedKeys: Dispatch<SetStateAction<string[]>>;
  isLoading: boolean;
  setDisplayNodeNumber: Dispatch<SetStateAction<boolean>>;
  displayNodeNumber: boolean;
};

const initTreeViewProps: TreeViewProps = {
  treeData: [],
  setTreeData: () => { },
  expandedKeys: [],
  setExpandedKeys: () => { },
  isLoading: true,
  setDisplayNodeNumber: () => { },
  displayNodeNumber: false,
};

const TreeContext = createContext<TreeViewProps>(initTreeViewProps);

const useTree = () => useContext(TreeContext);

const TreeProvider: FC<WithChildren> = ({ children }) => {
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [treeData, setTreeData] = useState<DataNode[]>([]);
  const { project } = useProject();
  const [isLoading, setIsLoading] = useState(true);
  const [displayNodeNumber, setDisplayNodeNumber] = useState(false);

  const dispatch = useDispatch();
  const knownWbs = useSelector((state: RootState) => state.treedata.knownWbs);
  const isTreeLoaded = useSelector((state: RootState) => state.treedata.isTreeLoaded);

  useEffect(() => {
    const loadAllWbs = async (wbsId: string): Promise<DataNode | void> => {
      let knownWbsItem = knownWbs[wbsId];

      if (knownWbsItem) {

        const childrenData: any[] = await Promise.all(
          knownWbsItem.subwbs.map(async (subWbs) => {
            return await loadAllWbs(subWbs.$oid);
          })
        );
        
        return {
          key: knownWbsItem._id.$oid,
          title: <ChartTreeFolderItem folder={knownWbsItem} />,
          children: childrenData.filter(item => item),
        };
      }
    };

    const initializeTree = async () => {
        const rootNode = await loadAllWbs(project?.share_wbs.$oid);
        if(rootNode) {
          setTreeData([rootNode]);
          setExpandedKeys((keys) => [...keys, rootNode.key.toString()]);
        }
      setIsLoading(false);
    };

    if (project?.share_wbs) {
      initializeTree();
    }

  }, [project?.share_wbs]);

  return (
    <TreeContext.Provider value={{ expandedKeys, isLoading, setExpandedKeys, setTreeData, treeData, displayNodeNumber, setDisplayNodeNumber }}>
      {children}
    </TreeContext.Provider>
  );
};

export { TreeProvider, useTree };
