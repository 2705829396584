/* eslint-disable jsx-a11y/anchor-is-valid */
import {Dispatch, FC, SetStateAction, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {Dropdown} from 'react-bootstrap'
import {deleteProject, getProjectImageLink, patchProject} from '../_requests'
import {getFormattedDate, showError, sweetAlert} from '../../../../utils/funcs'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {CustomBorderlessToggle} from '../ProjectHelpers'
import {ProjectModel} from '../_models'
import {useDrag} from 'react-dnd'

type Props = {
  project: ProjectModel
  refetch: any
  setMoveProject: Dispatch<SetStateAction<ProjectModel | undefined>>
  setSendProject: Dispatch<SetStateAction<ProjectModel | undefined>>
}

const ProjectCard: FC<Props> = ({project, setMoveProject, setSendProject, refetch = () => {}}) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const icon = '/media/logos/default-small.svg'

  const handleDelete = () => {
    setLoading(true)
    deleteProject(project._id.$oid)
      .then((res) => refetch())
      .catch(showError)
      .finally(() => setLoading(false))
  }

  const showConfirm = () => {
    sweetAlert({
      title: 'Are you sure?',
      text: 'You will not be able to recover this project!',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      showLoaderOnConfirm: true,
      icon: 'warning',
      preConfirm: () => handleDelete(),
      allowOutsideClick: () => !loading,
    }).then((res) => {
      if (res.isConfirmed) {
        sweetAlert({
          title: 'Deleted!',
          text: 'Project has been deleted.',
          icon: 'success',
        }).then(() => navigate('/projects'))
      }
    })
  }

  const toggleFeatured = (item: ProjectModel) => {
    patchProject({is_featured: !item.is_featured}, item._id.$oid)
      .then(() => refetch())
      .catch(showError)
  }

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'pending':
        return 'danger'
      case 'ongoing':
        return 'primary'
      case 'done':
        return 'success'
      default:
        return 'primary'
    }
  }

  const [{isDragging}, drag] = useDrag(() => ({
    type: 'project',
    collect: (monitor) => ({isDragging: !!monitor.isDragging()}),
    item: project,
  }))

  return (
    <div ref={drag} key={project._id.$oid} className='col-md-6 col-xl-6 mb-5 d-flex'>
      <div
        onClick={() => navigate(`/project/${project._id.$oid}`)}
        className='card border border-2 border-gray-300 border-hover cursor-pointer'
        style={{width: '100%'}}
      >
        {/* <div onClick={(e) => e.stopPropagation()}>
        <SendProjectModal
          show={showSendModal}
          handleClose={() => setShowSendModal(false)}
          projectId={project._id.$oid}
        />
      </div> */}
        <div className='card-header border-0 pt-9'>
          <div className='card-title m-0'>
            <div className='symbol symbol-50px w-50px bg-light'>
              <img
                src={!project.image ? toAbsoluteUrl(icon) : getProjectImageLink(project._id.$oid)}
                onError={(e) => ((e.target as HTMLImageElement).src = toAbsoluteUrl(icon))}
                alt='card2'
                className='p-3'
              />
            </div>
          </div>
          <div className='card-toolbar'>
            <span
              className={`badge badge-light-${getStatusColor(
                project.status
              )} fw-bolder me-auto px-4 py-3`}
            >
              {project.status}
            </span>
          </div>
        </div>

        <div className='card-body p-9'>
          <div className='d-flex justify-content-between w-100'>
            <div>
              <div className='fs-3 fw-bolder text-dark'>{project.name}</div>
              <p
                className='text-gray-400 fw-bold fs-5 mt-1 mb-7 overflow-hidden'
                style={{height: '2rem'}}
              >
                {project.description}
              </p>
            </div>
            <div
              onClick={(e: any) => {
                e.stopPropagation()
                if (toggleFeatured) toggleFeatured(project)
              }}
            >
              <KTSVG
                path='/media/icons/duotune/abstract/abs024.svg'
                className={`svg-icon-2x text-hover-warning ${
                  project.is_featured && 'text-warning'
                }`}
              />
            </div>
          </div>
          <div className='d-flex justify-content-between'>
            <div className='d-flex flex-wrap mb-5'>
              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3'>
                <div className='fs-6 text-gray-800 fw-bolder'>
                  {getFormattedDate(project.updated_at?.$date || 0)}
                </div>
                <div className='fw-bold text-gray-400'>Last Update</div>
              </div>
              {project?.default_version?.budget_at_completion?.amount + '' || '' ? (
                <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3'>
                  <div className='fs-6 text-gray-800 fw-bolder'>
                    {project?.default_version?.budget_at_completion?.amount + '' || ''}
                  </div>
                  <div className='fw-bold text-gray-400'>Budget at completion</div>
                </div>
              ) : (
                ''
              )}
            </div>
            <Dropdown onClick={(e) => e.stopPropagation()} style={{alignSelf: 'flex-end'}}>
              <Dropdown.Toggle as={CustomBorderlessToggle}></Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={showConfirm}>Delete Project</Dropdown.Item>
                <Dropdown.Item onClick={() => setSendProject(project)}>Send Project</Dropdown.Item>
                <Dropdown.Item onClick={() => setMoveProject(project)}>Move Project</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  )
}

export {ProjectCard}
