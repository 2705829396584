import { useNavigate } from 'react-router-dom'
import { Wbs } from '../_models'
import { KTSVG } from '../../../../_metronic/helpers'
import { Dropdown } from 'react-bootstrap'
import { CustomBorderlessToggle } from '../../projects/core/ProjectHelpers'
import { useState } from 'react'
import { deleteWbs, patchWbs } from '../_requests'
import { showError, showSuccess } from '../../../utils/funcs'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useDrag, useDrop } from 'react-dnd'
import clsx from 'clsx'
import MoveItem from './MoveItem'
import { addWbsToStore } from '../../wbs/treedata'
import { useDispatch } from 'react-redux'

type Props = { wbs: Wbs; refetch: any }

export default function WbsItem({ wbs, refetch }: Props) {
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const [isRenaming, setIsRenaming] = useState<boolean>(false)
  const [showMoving, setShowMoving] = useState<boolean>(false)
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: { name: wbs.name },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required('Name is required')
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols'),
    }),
    onSubmit: (values) => {
      if (!formik.dirty) return setIsRenaming(false)
      setLoading(true)
      patchWbs(wbs._id.$oid, { name: values.name })
        .then((res) => {
          dispatch(addWbsToStore(res))
          refetch()
          setIsRenaming(false)
        })
        .catch(showError)
        .finally(() => setLoading(false))
    },
  })

  const handleDelete = () => {
    setLoading(true)
    deleteWbs(wbs._id.$oid)
      .then(() => {
        refetch()
        showSuccess('Wbs deleted successfully')
      })
      .catch(showError)
      .finally(() => setLoading(false))
  }

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'wbs',
    collect: (monitor) => ({ isDragging: !!monitor.isDragging() }),
    item: wbs,
  }))

  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'wbs',
    collect: (monitor) => ({ isOver: !!monitor.isOver() }),
    drop: (item: Wbs) => {
      if (item._id.$oid === wbs._id.$oid) return
      patchWbs(item._id.$oid, {
        parent_wbs: wbs._id.$oid,
      })
        .then((res) => {
          dispatch(addWbsToStore(res))
          refetch()
        })
        .catch(showError)
    },
  }))

  return (
    <>
      <form
        ref={drag}
        onSubmit={formik.handleSubmit}
        onClick={() => !isRenaming && navigate('/enterprise/eps/wbss/' + wbs._id.$oid)}
        key={wbs._id.$oid}
        className={clsx('mb-5 col-md-4 col-xl-3', {
          'opacity-50': isDragging,
        })}
      >
        <div
          ref={drop}
          style={{ height: '200px' }}
          className='card border border-2 border-gray-300 border-hover cursor-pointer d-flex justify-content-center align-items-center pt-10'
        >
          <div className='symbol symbol-50px w-50px bg-light'>
            <KTSVG
              path={
                isOver && !isDragging
                  ? '/media/icons/duotune/arrows/arr044.svg'
                  : '/media/icons/duotune/graphs/git04.svg'
              }
              className='svg-icon-muted svg-icon-3hx text-primary'
            />
          </div>
          {isRenaming ? (
            <input
              autoComplete='off'
              placeholder='New Wbs'
              id='name'
              {...formik.getFieldProps('name')}
              className='form-control mt-3 py-1 px-2 border  w-75'
            />
          ) : (
            <p className='mt-3 fw-bold'>{wbs.name}</p>
          )}
          <div className='me-2' style={{ justifySelf: 'flex-end', alignSelf: 'flex-end' }}>
            {loading ? (
              <button type='button' disabled={true} className='btn btn-icon btn-sm text-muted'>
                <span className='indicator-progress d-inline-flex'>
                  <span className='spinner-border spinner-border-sm align-middle'></span>
                </span>
              </button>
            ) : isRenaming ? (
              <button
                disabled={loading}
                type='submit'
                className='btn btn-primary btn-icon btn-sm mt-3'
              >
                <KTSVG
                  className='svg-icon-muted svg-icon-3'
                  path='/media/icons/duotune/arrows/arr012.svg'
                />
              </button>
            ) : (
              <Dropdown onClick={(e) => e.stopPropagation()}>
                <Dropdown.Toggle as={CustomBorderlessToggle}></Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item disabled={loading} onClick={handleDelete}>
                    Delete Wbs
                  </Dropdown.Item>
                  <Dropdown.Item disabled={loading} onClick={() => setIsRenaming(true)}>
                    Rename Wbs
                  </Dropdown.Item>
                  <Dropdown.Item disabled={loading} onClick={() => setShowMoving(true)}>
                    Move Wbs
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        </div>
      </form>
      <MoveItem wbs={wbs} onHide={() => setShowMoving(false)} show={showMoving} />
    </>
  )
}
