import {FC, useState} from 'react'

import moment from 'moment'
import styles from './Header.module.scss'
import {useAuth} from '../../../../../../app/modules/auth'
import {getSubscriptionStatus} from '../../../../../../app/helpers/func'

import useHandleUpgrade from './hooks/useHandleUpgrade'
import {close} from '../../../../../../app/assets'
interface SubscriptionNotifierProps {}

const SubscriptionNotifier: FC<SubscriptionNotifierProps> = () => {
  const {currentUser, logout} = useAuth()
  const handleUpgrade = useHandleUpgrade()
  const getDisplayedData = (): any => {
    const subscriptionStatus = getSubscriptionStatus(currentUser)

    const remainingDays = currentUser?.current_subscription
      ? moment(moment(new Date(currentUser.current_subscription.period_end * 1000))).diff(
          new Date(),
          'days'
        ) + 1
      : null

    switch (subscriptionStatus) {
      case 'Free Plan':
        return [styles.freePlan, `You are using the free plan`, 'Upgrade', handleUpgrade]
      // TODO : Eventually remove the no subscription
      // case 'No subscription':
      // return [styles.subscriptionEnded, `No subscription found`, 'Choose a plan', handleUpgrade]
      case 'Trial mode':
        return [styles.trialMode, `Expires in ${remainingDays} days`, 'Upgrade', handleUpgrade]
      case 'Trial mode ended with no active subscription':
        return [
          styles.trialModeEndedWithNoActiveSubscription,
          'Free trial expired',
          'Upgrade',
          handleUpgrade,
        ]
      case 'Subscription ended':
        return [styles.subscriptionEnded, 'Subscription expired', 'Renew', handleUpgrade]
      case 'Subscription is active but will expire in less than 14 days':
        return [
          styles.subscriptionIsActiveButWillExpireInLessThan14Days,
          `Expires in ${remainingDays} days`,
          'Enable auto-renewal',
          handleUpgrade,
        ]
      case 'Auto-renew deactivated':
        return [styles.autoRenewDeactivated, 'Auto-renewal disabled', 'Activate', handleUpgrade]
      default:
        return [styles.hidden, undefined, undefined, undefined]
    }
  }

  const [customClassName, paragraphText, btnText, handleBtnClick] = getDisplayedData()
  if (!paragraphText) return null
  return (
    <div
      id='subscription-notifier'
      className={`${styles.subscriptionNotifier} ${customClassName} d-flex align-items-center justify-content-center`}
    >
      <p className='mb-0'>{paragraphText}</p>

      <button onClick={handleBtnClick}>{btnText}</button>

      {/* {paragraphText === 'Free trial expired' ||
      paragraphText === 'Subscription expired' ||
      paragraphText === 'No subscription found' ? null : (
        <img
          className={styles.close}
          src={close}
          alt='Close Subscription Notifier'
          onClick={() => {}}
        />
      )} */}
    </div>
  )
}

export default SubscriptionNotifier
