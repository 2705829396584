import {useQuery} from 'react-query'
import {CalendarPagination} from './_models'
import {getCalendar, getCalendars} from './_requests'
import {showError} from '../../utils/funcs'
import {useNavigate} from 'react-router-dom'

const useCalendars = (calendarData: CalendarPagination) =>
  useQuery(['calendarData', calendarData], () => getCalendars(calendarData))

const useCalendar = (calendarId: string) => {
  const navigate = useNavigate()
  return useQuery(['calendarId', calendarId], () => getCalendar(calendarId), {
    onError: (err) => showError(err).then(() => navigate('/enterprise/calendars')),
    retry: 1,
  })
}

export {useCalendars, useCalendar}
