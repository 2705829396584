import {KTSVG} from '../../../../../../_metronic/helpers'
import {Folder} from '../../../../eps/_models'
import clsx from 'clsx'

type Props = {
  folder: Folder
}

export default function SelectTreeItem({folder}: Props) {
  return (
    <div className={clsx('d-inline-flex justify-content-start align-items-center')}>
      <KTSVG
        path='/media/icons/duotune/files/fil012.svg'
        className='svg-icon-primary svg-icon-2 me-2'
      />
      <span className='fw-bold me-2'>{folder.name}</span>
    </div>
  )
}
