import {useEffect, useState} from 'react'
import {ListsWidget1} from '../../../_metronic/partials/widgets'
import {Project} from '../../modules/projects/core/_models'
import {Simulation} from '../../modules/simulations/core/_models'
import {MenuComponent} from '../../../_metronic/assets/ts/components'

interface Props {
  projectData: Project | undefined
  simulationData: Simulation | undefined
  className: string
}

export default function Latest({projectData, simulationData, className}: Props) {
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])
  const [filter, setFilter] = useState<number>(5)
  let normalizedProjects: any[]
  let normalizedSimulations: any[]
  if (projectData)
    normalizedProjects = projectData?.items.map((item) => {
      return {
        id: item._id.$oid,
        type: 'project',
        updated_at: item.updated_at,
        name: item.name,
        status: item.status,
      }
    })
  else normalizedProjects = []
  if (simulationData)
    normalizedSimulations = simulationData?.items.map((item) => {
      return {
        id: item?._id?.$oid,
        type: 'simulation',
        updated_at: item.updated_at,
        name: item.name,
        status: item.is_start ? 'On Going' : 'Not Started',
      }
    })
  else normalizedSimulations = []
  const items = [...normalizedProjects, ...normalizedSimulations]
  const sortedItems = items
    .sort((a, b) => {
      const dateA = new Date(a.updated_at.$date)
      const dateB = new Date(b.updated_at.$date)
      if (dateA < dateB) return 1
      if (dateA === dateB) return 1
      return -1
    })
    .filter((item, index) => index < filter)
  return (
    <>
      <ListsWidget1
        className={className}
        items={sortedItems}
        filter={filter}
        setFilter={setFilter}
      />
    </>
  )
}
