import { getMonthDifference } from "../../../../utils/data-transformarion/date-utils"

enum ProjectDetailsTypes {
  PROJECT_INFORMATION = 'INFO',
  ACTUAL_PROJECT_DATA = 'APD',
  COST_PERFORMENCE = 'CP',
  SCHEDULE_PERFORMANCE = 'SP',
}
enum CandleChartType {
  SV = 'scheduleVariance',
  CV = 'CV',
}
const getCustomCurve = (
  type:
    | 'cumulativePlannedValue'
    | 'cumulativeEarnedValue'
    | 'cumulativeActualCost'
    | 'worstCaseBaseline',
  data: any
): any[] => {
  return data?.custom_curve
    .find((item: any) => item.name === type)
    ?.values?.filter((value: any) => !isNaN(value) && value !== '')
}

const getBAC = (start_date:any,end_date:any,budget_reserve:any,data:any) => {
  const diff = getMonthDifference(start_date,end_date)
  const plannedValue = getCustomCurve('cumulativePlannedValue', data)
  const BudgetCompelation = plannedValue[plannedValue.length - 1]
  if(data?.rebase_line?.length > 0){
    const ProjectVersionDiff = getMonthDifference(start_date,data?.rebase_line[0]?.data_date)
    let i = ProjectVersionDiff;
    let j = 1;
    const plannedValueRebaseline = getCustomCurve('cumulativePlannedValue', data?.rebase_line[0])
    const budgetAtCompletionRebaseline = plannedValueRebaseline[plannedValueRebaseline.length - 1]
    let BAC = new Array(diff+1).fill(budgetAtCompletionRebaseline);
  
      do{
        if(j==data?.rebase_line?.length){
          BAC[i] = BudgetCompelation;
          i++;
        }else{
          let diffrence = getMonthDifference(data?.rebase_line[j-1]?.data_date,data?.rebase_line[j]?.data_date)
          const plannedValueRebaseline = getCustomCurve('cumulativePlannedValue', data?.rebase_line[j])
          const budgetAtCompletionRebaseline = plannedValueRebaseline[plannedValueRebaseline.length - 1]
          for(let k = 0 ; k<diffrence;k++){
            BAC[i] = budgetAtCompletionRebaseline;
            i++;
          }
          j++;
        }
      }while(i < diff+1);
      return BAC
  }else 
  return new Array(diff+1).fill(BudgetCompelation)

}

const getBiggestChartValue = (output: any, customCurve?: any, reserve?: any) => {
  const maxTable: number[] = []
  maxTable.push(
    output?.plannedValues?.original?.cumulative[
      output?.plannedValues?.original?.cumulative.length - 1
    ]
  )
  maxTable.push(
    output?.earnedValues?.mixed?.cumulative[output?.earnedValues?.mixed?.cumulative.length - 1]
  )
  maxTable.push(
    output?.earnedValues?.forecast?.cumulative[
      output?.earnedValues?.forecast?.cumulative.length - 1
    ]
  )
  maxTable.push(
    output?.earnedValues?.original?.cumulative[
      output?.earnedValues?.original?.cumulative.length - 1
    ]
  )
  maxTable.push(
    output?.actualCosts?.mixed?.cumulative[output?.actualCosts?.mixed?.cumulative.length - 1]
  )

  maxTable.push(+output?.budgetReserve?.reserve)
  if (customCurve)
    maxTable.push(
      reserve ? customCurve[customCurve.length - 1] + reserve : customCurve[customCurve.length - 1]
    )
  return Math.max(...maxTable.filter((item) => !!item && !isNaN(item)))
}

export {ProjectDetailsTypes, CandleChartType, getCustomCurve, getBiggestChartValue , getBAC }
