import React from 'react'

interface ButtonProps {
  type: 'button' | 'submit'
  loading: boolean
  text: string
  classNames?: string
  disabled?: boolean
}

function Button({ loading, text, type, classNames, disabled }: ButtonProps) {
  return (
    <div className='d-grid gap-2'>
      <button type={type} className={classNames || 'btn btn-primary'} disabled={disabled}>
        {!loading && text}
        {loading && (
          <span className='indicator-progress' style={{ display: 'block' }}>
            Please wait...{' '}
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        )}
      </button>
    </div>
  )
}

export default Button
