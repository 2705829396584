import { Modal } from 'react-bootstrap';
import { TreeProvider } from '../../../../wbs/components/TreeView/TreeContext';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import TreeView from '../../../../wbs/components/TreeView/TreeView';



type Props = {
  show: boolean;
  onHide: () => void;
};

export default function ShowWbsModal({
  onHide,
  show
}: Props) {

  const showLoading = useSelector((state: RootState) => state.treeLoader.show);

  return (
    <>
      <Modal centered onHide={onHide} show={show && !showLoading} backdrop="static">

        <Modal.Header closeButton>
          <Modal.Title>WBS</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <TreeProvider>
            <TreeView isProjectHeader={true} />
          </TreeProvider>
        </Modal.Body>

      </Modal>
    </>

  );
}
