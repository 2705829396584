import clsx from 'clsx'
import {useFormik} from 'formik'
import {Modal} from 'react-bootstrap'
import * as Yup from 'yup'
import {sweetAlert} from '../../../../utils/funcs'
import {useAuth} from '../../../auth'
import {updateUser} from '../../../auth/core/_requests'

interface Props {
  show: boolean
  onHide: () => void
  offset?: number
  tangent?: number
}

const RECORD_TO_IGNORE = 'IGNORE_THIS_RECORD_6DBE8ADAD7F9'

export default function SaveSettingsModal({show, onHide, offset, tangent}: Props) {
  const {currentUser, setCurrentUser} = useAuth()
  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Setting name is required'),
    }),
    onSubmit: (values) => handleSave(),
  })
  const handleSave = () => {
    let curveSettings = currentUser?.curve_settings?.filter((c) => c.name !== RECORD_TO_IGNORE)
    curveSettings?.push({name: formik.values.name, tangent: tangent || 0, offset: offset || 0})
    updateUser(undefined, {curve_settings: curveSettings})
      .then((res) => {
        setCurrentUser(res.data)
        formik.resetForm()
        onHide()
      })
      .catch((err) => {
        sweetAlert({
          text: err.response.data.message || 'Something went wrong',
          title: 'Error',
          icon: 'error',
        })
      })
  }
  return (
    <Modal centered show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>Save Curve Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
            <span className='required'>Name</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Specify your unique setting name'
            ></i>
          </label>
          <input
            type='text'
            {...formik.getFieldProps('name')}
            placeholder=''
            className={clsx(
              'form-control ',
              {'is-invalid': formik.touched.name && formik.errors.name},
              {
                'is-valid': formik.touched.name && !formik.errors.name,
              }
            )}
          />
          {formik.touched.name && formik.errors.name && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.name}</span>
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type='button'
          onClick={formik.submitForm}
          disabled={!formik.isValid}
          className='btn btn-primary'
        >
          Save
        </button>
      </Modal.Footer>
    </Modal>
  )
}
