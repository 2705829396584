import clsx from 'clsx'
import {useLayout} from '../../../_metronic/layout/core'
import {KTSVG} from '../../../_metronic/helpers'
import {handleDownloadTemplate} from './ExportHelpers'

type Props = {
  data: any
  name: string
}

export default function ExportCustomData({data, name}: Props) {
  const {config} = useLayout()
  const daterangepickerButtonClass = config.app?.toolbar?.fixed?.desktop
    ? 'btn-light'
    : 'bg-body btn-color-gray-700 btn-active-color-primary'

  const handleExport = () => {
    handleDownloadTemplate(data, `${name}.xlsx`)
  }
  return (
    <div className='m-0'>
      <a
        onClick={handleExport}
        href='#'
        className={clsx('btn btn-sm btn-flex fw-bold', daterangepickerButtonClass)}
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG
          path='/media/icons/duotune/arrows/arr044.svg'
          className='svg-icon-6 svg-icon-muted me-1'
        />
        Export
      </a>
    </div>
  )
}
