import { Modal } from 'react-bootstrap';
import { TreeProvider } from '../../../../wbs/components/TreeView/ShareTreeContext';
import SharedTreeView from '../../../../wbs/components/TreeView/SharedTreeView';



type Props = {
  show: boolean;
  onHide: () => void;
  share_type: string
};

export default function ShowSharedWbsModal({
  onHide,
  show,
  share_type
}: Props) {

  return (
    <>
      <Modal centered onHide={onHide} show={show} backdrop="static">

        <Modal.Header closeButton>
          <Modal.Title>WBS</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <TreeProvider>
            <SharedTreeView share_type={share_type}/>
          </TreeProvider>
        </Modal.Body>

      </Modal>
    </>

  );
}
