import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { sweetAlert } from '../utils/funcs';
import { updateProjectVisibility } from '../modules/projects/core/_requests';
import { useNavigate } from 'react-router-dom'

interface Project {
    id: string;
    name: string;
    description: string;
}

interface FreeProjectSelectModalProps {
    isOpen: boolean;
    onConfirm: () => void;
    projects: any;
}

const FreeProjectSelectModal: React.FC<FreeProjectSelectModalProps> = ({ isOpen, onConfirm, projects }) => {
    const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
    const [mappedProjects, setMappedProjects] = useState<Project[]>([]);
    const navigate = useNavigate()


    useEffect(() => {
        if (projects && projects?.items) {
            const formattedProjects = projects.items.map((project: any) => ({
                id: project._id.$oid,
                name: project.name,
                description: project.description || 'No description available',
            }));
            setMappedProjects(formattedProjects);
        }
    }, [projects]);

    const handleCheckboxChange = (projectId: string) => {
        setSelectedProjects((prevSelected) =>
            prevSelected.includes(projectId)
                ? prevSelected.filter((id) => id !== projectId)
                : [...prevSelected, projectId].slice(0, 3)
        );
    };

    const handleConfirm = async () => {
        const projectsToHide = mappedProjects
            .filter((project) => !selectedProjects.includes(project.id))
            .map((project) => project.id);

        if (selectedProjects.length === 0) {
            sweetAlert({
                title: 'No projects selected',
                text: 'You will lose access to all projects unless you buy a subscription. Do you want to proceed?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Continue',
                cancelButtonText: 'Back',
            }).then((result) => {
                if (result.isConfirmed) {
                    onConfirm();
                    projectsToHide.forEach(async (projectId) => {
                        await updateProjectVisibility(projectId, false);
                    });
                }
            }).finally(() => {
                navigate('/projects')
            }
            );
        } else {
            sweetAlert({
                title: 'Confirm Your Selection',
                text: "Are you sure you want to proceed? You won't be able to retrieve the other projects unless you upgrade to a subscription plan.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Continue',
                cancelButtonText: 'Back',
            }).then((result) => {
                if(result.isConfirmed){
                    onConfirm();
                    projectsToHide.forEach(async (projectId) => {
                        await updateProjectVisibility(projectId, false);
                    });
                }
            });
        }
    };

    return (
        <Modal show={isOpen} onHide={() => {}} size="lg" backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Please select projects to keep before moving to free plan</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>As a free plan user, you can only manipulate the projects you select from this list.</p>
                <div style={{ maxHeight: '400px', overflowY: 'scroll', padding: '10px' }}>
                    {mappedProjects.map((project) => (
                        <div
                            key={project.id}
                            style={{
                                border: '1px solid #ddd',
                                borderRadius: '5px',
                                padding: '10px',
                                marginBottom: '10px',
                                backgroundColor: selectedProjects.includes(project.id) ? '#469CF0' : '#fff',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <Form.Group controlId={`project-${project.id}`} style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <Form.Check
                                    type="checkbox"
                                    checked={selectedProjects.includes(project.id)}
                                    onChange={() => handleCheckboxChange(project.id)}
                                    style={{ marginRight: '10px' }}
                                />
                                <div>
                                    <strong style={{ fontSize: '1.2em' }}>{project.name}</strong>
                                    <p>{project.description}</p>
                                </div>
                            </Form.Group>
                        </div>
                    ))}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => onConfirm()}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleConfirm}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default FreeProjectSelectModal;
