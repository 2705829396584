import { FC, useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { useProject } from '../../core/ProjectContext'
import { WidgetID, WidgetOrder, mockWidgetOrder } from '../settings/WidgetOrder/_models'
import ChartWithAnnotations from './ChartWithAnnotations'
import Summary from './Summary'
import Variances from './Variances'
import MilestonesChart from './milestones/MilestonesChart'
import ResourceChart from './resources/ResourceChart'
import { getMonthDifference, sameMonthAndYear } from '../../../../utils/data-transformarion/date-utils'
import Dashboard from './Dashboard'
import ActionPlanComments from './ActionPlanComments'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store'


interface OverviewProps {
  isShared?: boolean;
  share_type?: string;
}

export const Overview: FC<OverviewProps> = ({ isShared = false , share_type='live'}) => {
  const { displayVersion: data, project: projectData, isRebaseLined } = useProject()

  const order =
    projectData?.widget_order && projectData.widget_order.length
      ? projectData.widget_order
      : mockWidgetOrder

  const knownWbs = useSelector((state: RootState) => state.treedata.knownWbs);
  const [isWp, setIsWp] = useState<boolean>(true)




  useEffect(() => {
    const last_opened_wbs = knownWbs[projectData?.current_wbs]?.last_opened_wbs?.$oid ? knownWbs[projectData?.current_wbs]?.last_opened_wbs?.$oid : projectData?.current_wbs
    if (last_opened_wbs) {
      const latestOpenWbs = knownWbs[last_opened_wbs]
      setIsWp(latestOpenWbs && latestOpenWbs.subwbs && latestOpenWbs.subwbs.length === 0)
    }
  }, [projectData?.current_wbs, knownWbs])

  const rebaseLineIndex = () => {
    if (data?.rebase_line?.length! > 0) {
      let startDate: any = data?.start_date?.$date!
      const arr = new Array(data?.rebase_line?.length! - 1)
      data?.rebase_line?.map((item, index) => {
        let diff = getMonthDifference(startDate, item?.data_date);
        startDate = new Date(item?.data_date);
        arr[index] = diff
        return item;
      })
      return arr
    }
    return []
  }

  const getAssociatedComponent = (item: WidgetOrder) => {
    if (!item.show) return <></>
    switch (item.widget) {
      case WidgetID.SUMMARY:
        return <Summary key={item.widget} isShared={isShared} />
      case WidgetID.MILESTONES:
        return <MilestonesChart key={item.widget} isShared={isShared} />
      case WidgetID.S_CURVE:
        return (
          <div key={item.widget}>
            {projectData && (
              <div className=''>
                <ChartWithAnnotations
                  isShared={isShared}
                  isWp={isWp}
                  isRebaseLined={isRebaseLined ? sameMonthAndYear(data?.data_date?.$date!, data?.rebase_line[data?.rebase_line?.length - 1]?.data_date!) : false}
                  rebaseLineIndex={rebaseLineIndex()}
                  data={data}
                  projectData={data?.output} />
              </div>
            )}
          </div>
        )
      case WidgetID.VARIANCES:
        return <Variances key={item.widget} isShared={isShared} />
      case WidgetID.RESOURCES:
        return <ResourceChart key={item.widget} isShared={isShared} />
      case WidgetID.ACTION_PLAN:
        return <ActionPlanComments key={item.widget} isShared={isShared} showActions={false} />
      case WidgetID.DASHBOARD:
        return <Dashboard key={item.widget} isShared={isShared} share_type={share_type}/>
      default:
        return <></>
    }
  }

  return (
    <div className='pt-5'>
      <Card>
        <Card.Body>
          {order.map((item) => getAssociatedComponent(item))}
        </Card.Body>
      </Card>
    </div>
  )
}