type Props = {
  label: string
  required?: boolean
  formikProps: any
  touched: boolean | undefined
  error: string | undefined
  placeholder?: string
  value: string
}

export default function ColorInputWithLabel({
  error,
  formikProps,
  label,
  touched,
  required,
  placeholder,
  value,
}: Props) {
  return (
    <div className='fv-row mb-10'>
      <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
        <span className='required'>{label}</span>
        <i
          className='fas fa-exclamation-circle ms-2 fs-7'
          data-bs-toggle='tooltip'
          title='Color of your custom curve'
        ></i>
      </label>
      <div className='d-flex gap-5 align-items-center'>
        <label htmlFor={label} className=''>
          <i className='bi bi-eyedropper fs-1 cursor-pointer'></i>
        </label>
        <label
          htmlFor={label}
          className='rounded cursor-pointer'
          style={{width: '30px', height: '30px', backgroundColor: value}}
        ></label>
        <p className='fs-5 m-0'>{value}</p>
      </div>
      <input hidden id={label} type='color' {...formikProps} />
      {touched && error && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{String(error)}</span>
          </div>
        </div>
      )}
    </div>
  )
}
