/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import { downloadCustomCurveXlsxTemplate, findSym } from '../../../../../helpers/func';
import UploadField from '../../../../../components/uploadField';
import { initialTable } from '../../../../../../_metronic/partials/modals/project-stepper/IProjectModels';
import moment from 'moment';
import { isEmpty } from 'lodash';
import {
  downloadCustomCurveTemplate,
  downloadCustomRebaseLine,
} from '../../../../library/ProjectCurve/Modals/utils';
import { getDiffrentBettwenDate } from '../../../../../utils/data-transformarion/date-utils';

interface SecondStepProps {
  formik: any;
  plannedValuesObject: any;
  isRemainingToDo?: boolean;
}

const SecondStep = ({ formik, plannedValuesObject, isRemainingToDo = false }: SecondStepProps) => {
  const handleDownloadTemplate = (startDate: Date, endDate: Date) => {
    const duration = getDiffrentBettwenDate(endDate.getTime(), startDate.getTime(), formik.values.date_unit) + 1;
    downloadCustomRebaseLine(
      startDate,
      duration,
      plannedValuesObject,
      startDate
    );
  };

  return (
    <div data-kt-stepper-element='content'>
      <div className='w-100'>
        <div className='fv-row mt-5'>
          <label className='fs-6 fw-bolder text-dark mb-7 d-flex align-items-center fs-5 fw-semibold mb-4'>
            <span>New Cumulative Planned Value</span>
            <div className='text-muted fw-semibold'>
              You can download the generated XLSX file
              <a
                href='#'
                className='link-primary'
                onClick={() => {
                  const startDate = isRemainingToDo
                    ? new Date(formik.values.rebaseLine_dataDate)
                    : new Date(formik.values.start_date);
                  handleDownloadTemplate(startDate, formik.values.end_date);
                }}
              >
                {' '}
                Download Template
              </a>
            </div>
          </label>
          <UploadField
            index={0}
            durationUnit=''
            setFileContent={async (value: any) => {
              formik.setFieldValue('new_cumulativePlannedValue', value);
              setTimeout(() => formik.setFieldTouched('new_cumulativePlannedValue', true));
            }}
            disabled={!isEmpty(formik?.values.savedSimulation)}
          />
        </div>
        {formik.errors.new_cumulativePlannedValue && formik.touched.new_cumulativePlannedValue && (
          <div className='row mt-2'>
            <h4 className='text-danger'>{formik.errors.new_cumulativePlannedValue}</h4>
          </div>
        )}
        <div className='fv-row mt-5'>
          <label className='fs-6 fw-bolder text-dark mb-7 d-flex align-items-center fs-5 fw-semibold mb-4'>
            <span>New Latest Cumulative Planned Value</span>
            <div className='text-muted fw-semibold'>
              You can download the generated XLSX file
              <a
                href='#'
                className='link-primary'
                onClick={() => {
                  handleDownloadTemplate(new Date(formik.values.rebaseLine_dataDate), formik.values.end_date);
                }}
              >
                {' '}
                Download Template
              </a>
            </div>
          </label>
          <UploadField
            index={0}
            durationUnit=''
            setFileContent={async (value: any) => {
              formik.setFieldValue('worstCaseBaseline', value)
              setTimeout(() => formik.setFieldTouched('worstCaseBaseline', true))
            }}
            disabled={!isEmpty(formik?.values.savedSimulation)}
          />
        </div>
        {formik.errors.new_latest_cumulativePlannedValue && formik.touched.new_latest_cumulativePlannedValue && (
          <div className='row mt-2'>
            <h4 className='text-danger'>{formik.errors.new_latest_cumulativePlannedValue}</h4>
          </div>
        )}
      </div>
    </div>
  );
};

export { SecondStep };
