import clsx from 'clsx'
import { useState } from 'react'
import { Collapse } from 'react-bootstrap'
import { KTSVG } from '../../../../../_metronic/helpers'
import { ActionPlan } from '../action-plan/ActionPlan'
import Comments from '../comments/Comments'

type Props = {
  showActions?: boolean;
  isShared?: boolean;
}

export default function ActionPlanComments({ showActions, isShared }: Props) {
  const [acExpanded, setAcExpanded] = useState(true)
  return (
    <>
      <div className='my-3 d-flex justify-content-between px-5'>
        <div className='d-inline-flex gap-2'>
          <span>
            <button
              onClick={() => setAcExpanded((prev) => !prev)}
              className='btn btn-light p-2 d-inline-flex align-items-center justify-content-center rotate active z-index-2'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr021.svg'
                className={clsx('svg-icon-muted svg-icon svg-icon-sm me-0', {
                  'rotate-270': !acExpanded,
                  'rotate-90': acExpanded,
                })}
              />
            </button>
          </span>
          <span className='card-label fw-bold fs-3'>Action Plan / Comments</span>
        </div>
      </div>
      <Collapse in={acExpanded}>
        <div>
          <ActionPlan showActions={showActions} isShared={isShared} />
          <Comments showActions={showActions} isShared={isShared} />
        </div>
      </Collapse>
    </>
  )
}