import {useEffect, useState} from 'react'
import HierarchyItem from './HierarchyItem'
import {FolderNode} from './HierarchyView'
import {TreeNode} from 'react-organizational-chart'

type Props = {
  node: FolderNode
  resetView: () => void
  allExpanded: boolean
  allCollapsed: boolean
  resetExpanded: () => void
  className?: string
}

export default function HierarchyFolderItem({
  node,
  resetView,
  allCollapsed,
  allExpanded,
  resetExpanded,
  className = '',
}: Props) {
  const [expanded, setExpanded] = useState<boolean>(true)
  useEffect(() => {
    if (allExpanded) setExpanded(true)
    if (allCollapsed) setExpanded(false)
  }, [allExpanded, allCollapsed])

  useEffect(() => {
    // Find the ul element with a child li having the "no-end" class
    const resetItems = document.querySelectorAll('.backup-no-end')
    resetItems.forEach((item) => item.classList.remove('backup-no-end'))
    const liElement = document.querySelector('.no-end')
    if (liElement) {
      const ulElement = liElement.closest('ul')
      if (ulElement) {
        // Do something with the ulElement
        ulElement.classList.add('backup-no-end')
      }
    }
  }, [expanded])

  return (
    <TreeNode
      className={className}
      label={
        <HierarchyItem
          resetExpanded={resetExpanded}
          allCollapsed={allCollapsed}
          allExpanded={allExpanded}
          resetView={resetView}
          expanded={expanded}
          setExpanded={setExpanded}
          node={node}
        />
      }
    >
      {node.children?.map((item) => (
        <HierarchyFolderItem
          className={expanded ? '' : 'invisible no-end'}
          resetExpanded={resetExpanded}
          allCollapsed={allCollapsed}
          allExpanded={allExpanded}
          key={item.id}
          node={item}
          resetView={resetView}
        />
      ))}
    </TreeNode>
  )
}
