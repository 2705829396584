import {Modal} from 'react-bootstrap'
import MilestoneSettingsForm from './MilestoneSettingsForm'

type Props = {
  show: boolean
  onHide: () => void
}

export default function MilestoneSettingsModal({onHide, show}: Props) {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>Milestone Settings</Modal.Title>
      </Modal.Header>
      <MilestoneSettingsForm onHide={onHide} />
    </Modal>
  )
}
