import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

type PaywallModalProps = {
  show: boolean;
  onHide: () => void;
  title: string;
  bodyText: string;
};

const PaywallModal: React.FC<PaywallModalProps> = ({ show, onHide, title, bodyText }) => {
  const navigate = useNavigate()
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{bodyText}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={() => navigate('/profile/billing')}>
          Upgrade Plan
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PaywallModal;
