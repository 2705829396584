import React, { useState, useEffect } from 'react';
import '../wpStyles/TableStyle.scss';
import { checkCompleteData } from '../../projects/core/ProjectHelpers';
import { useLocation } from 'react-router-dom'
import '../../projects/components/data/Data.scss'
import { DateFormatType } from '../../../utils/funcs';
import '../../projects/components/settings/Date.scss'
import { getLabels, getMaxArrayLength, normalizeDateUnit } from '../../../helpers/DateFormatter';
import EarnedValueTable from '../../projects/components/data/EarnedValueTable';
import PlannedValueTable from '../../projects/components/data/PlannedValueTable';
import ActualCostTable from '../../projects/components/data/ActualCostTable';
import DateInputs from '../../projects/components/data/DateInputs';
import CompleteData from '../../projects/components/data/CompleteData';
import { getCustomCurve } from '../../projects/components/overview/Project';
import WorstCastBaseline from '../../projects/components/data/WorstCastBaseline';

interface WpDataProps {
  data: any;
  setData: React.Dispatch<any>
  wpId?: string;
  handleUpdateWpDataDate?: any
}


const WpData: React.FC<WpDataProps> = ({ data, setData, handleUpdateWpDataDate, wpId }) => {

  const generatedLabelsLength = getMaxArrayLength(
    data?.output?.plannedValues?.original.cumulative,
    data?.output?.earnedValues?.mixed?.cumulative,
    data?.output?.actualCosts?.mixed?.cumulative,
    getCustomCurve('cumulativePlannedValue', data)
  )

  const normalizedDateUnit = normalizeDateUnit(data?.period_count?.type)

  const labels = getLabels(
    data?.start_date?.$date || 0,
    generatedLabelsLength ||
    data?.custom_curve?.find((item: any) => item.name === 'cumulativePlannedValue')?.values
      .length ||
    0,
    normalizedDateUnit,
    data?.date_format as DateFormatType
  )

  const dataDateIndex = labels.findIndex(
    (value: any) =>
      value ===
      getLabels(
        data?.data_date?.$date || 0,
        1,
        normalizedDateUnit,
        data?.date_format as DateFormatType
      )[0]
  )


  return (
    <div>
      <div style={{ overflowX: 'auto', maxWidth: '900px' }}>
        <div className="container">
          <DateInputs wpId={wpId} data={data} setProjectversion={setData} handleUpdateWpDataDate={handleUpdateWpDataDate} />
        </div>
      </div>
      {checkCompleteData(new Date(data?.data_date.$date || 0), data) && (
        <>
          <PlannedValueTable wpId={wpId} data={data} labels={labels} setData={setData} />
          <EarnedValueTable wpId={wpId} data={data} dataDateIndex={dataDateIndex} labels={labels} setData={setData} />
          <ActualCostTable wpId={wpId} data={data} dataDateIndex={dataDateIndex} labels={labels} setData={setData} />
          {!!getCustomCurve('worstCaseBaseline', data) &&
            !!getCustomCurve('worstCaseBaseline', data).length && (
              <WorstCastBaseline labels={labels} />
            )}
        </>
      )}
    </div>
  );
};

export default WpData;

