/**
 * Calculates the percentage of a value with respect to a total.
 * @param value The numerator in the percentage calculation.
 * @param total The denominator in the percentage calculation.
 * @returns The percentage of the value with respect to the total.
 */
export const getPercentage = (value: number, total: number) => {
    return (value * 100) / total
  }

/**
 * Converts a list of cost values to percentages of a total budget.
 * Each cost is expressed as a percentage of the total budget, helping to understand costs relative to the budget.
 * @param costs Array of cost values to convert.
 * @param budgetAtCompletion The total budget amount against which costs are compared.
 * @returns An array of percentages representing each cost as a fraction of the total budget.
 */
export const  convertCostsToPercentages = (costs: any, budgetAtCompletion: any) =>
    costs.map((cost: any) => (cost / budgetAtCompletion) * 100)

  // =====================  end  ===================== //
  /**
 * Converts a list of percentage values to cost values based on a total budget.
 * Each percentage is multiplied by the total budget and divided by 100 to convert it into a cost value.
 * @param percentages Array of percentage values to be converted to costs.
 * @param budgetAtCompletion The total budget used as the base to calculate costs from percentages.
 * @returns An array of cost values calculated from percentages of the total budget.
 */

export const convertPercentagesToCosts = (percentages: any, budgetAtCompletion: any) =>
    percentages.map((percentage: any) => (percentage / 100) * budgetAtCompletion)

/**
* Removes the percentage symbol ('%') from each string in an array of percentage strings and returns just the numerical part.
* Useful for converting formatted percentage strings into plain numeric strings that can be used in calculations.
* @param percentages Array of string percentages (e.g., ["50%", "30%"]) to be cleaned.
* @returns An array of strings representing the numerical part of each percentage, stripped of the '%' character.
*/
export const removePercent = (pourcentages: any) => pourcentages.map((item: any) => item.split('%')[0])
  // =====================  end  =====================//

